import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Input, Pagination, Row, Spin, Typography } from 'antd';

import { useBusiness, useDebounce } from '@aduvi/hooks';

import { getDocumentations } from 'store/features/documentation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { DocumentationCard } from './DocumentationCard';

export const Documentation = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { documentations, loading } = useAppSelector((state) => state.documentation);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchKey = useDebounce(searchValue) || undefined;

  const filteredDocumentations = useMemo(() => {
    return documentations?.data?.filter((docRelation) => docRelation?.documentation?.title || docRelation?.documentation?.content);
  }, [documentations]);

  const handlePageChange = (page: number, pageSize?: number) => {
    onGetDocumentations(page, pageSize ?? 10);
  };

  const onGetDocumentations = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id) return;
      dispatch(
        getDocumentations({
          business_id: selectedBusiness.id,
          params: {
            page,
            size,
            search: debouncedSearchKey,
          },
        }),
      );
    },
    [dispatch, debouncedSearchKey, selectedBusiness?.id],
  );

  useEffect(() => {
    onGetDocumentations(1, 10);
  }, [debouncedSearchKey, selectedBusiness?.id]);

  return (
    <Spin spinning={loading}>
      <Row style={{ maxHeight: '470px', minHeight: '457px', overflowY: 'auto' }} className='p-20'>
        <Col span={24}>
          <Row justify='center' className='mb-10 mt-10'>
            <Typography.Text>{translate('support.documentation.searchDocumentation')}</Typography.Text>
          </Row>
        </Col>

        <Col span={24} className='mb-20'>
          <Row justify='center'>
            <Input.Search
              allowClear
              style={{ maxWidth: '400px' }}
              placeholder={translate('support.documentation.howDoI')}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Row>
        </Col>

        {loading && (
          <Col span={24} className='mb-20'>
            <Row justify='center'>
              <Typography.Text>{translate('general.loading')}</Typography.Text>
            </Row>
          </Col>
        )}

        {!loading && filteredDocumentations && filteredDocumentations.length > 0 && (
          <>
            <Row gutter={[16, 16]} className='w-full'>
              {filteredDocumentations.map((docRelation) => (
                <Col span={12} key={docRelation.id}>
                  <DocumentationCard title={docRelation?.documentation?.title} description={docRelation?.documentation?.content} />
                </Col>
              ))}
            </Row>

            <Row justify='center' className='mt-20'>
              <Pagination
                current={documentations?.current_page}
                pageSize={documentations?.per_page}
                total={documentations?.total}
                onChange={handlePageChange}
              />
            </Row>
          </>
        )}

        {!loading && (!filteredDocumentations || filteredDocumentations.length === 0) && (
          <Col span={24}>
            <Row justify='center'>
              <Typography.Text>{translate('support.documentation.noResults')}</Typography.Text>
            </Row>
          </Col>
        )}
      </Row>
    </Spin>
  );
};
