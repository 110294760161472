import { useMemo } from 'react';

import { Form, FormInstance } from 'antd';

import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';
import { isValidUrl } from '@aduvi/utils/helper';

const backgroundImage: Record<string, string> = {
  'assets/images/theme-1.png': require('assets/images/theme-1.png'),
  'assets/images/theme-2.png': require('assets/images/theme-2.png'),
  'assets/images/theme-3.png': require('assets/images/theme-3.png'),
  'assets/images/theme-4.png': require('assets/images/theme-4.png'),
};

export const useDesign = ({ form }: { form: FormInstance }) => {
  const widgetStyle = Form.useWatch('widget_style', form);

  const widgetBackgroundStyle = useMemo(() => {
    return {
      background: form.getFieldsValue()?.widget_style?.[0]?.widget_background,
      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
    };
  }, [widgetStyle]);

  const backgroundStyle = useMemo(() => {
    return {
      background:
        form.getFieldsValue()?.widget_style?.[0]?.background_mode === 'colour'
          ? form.getFieldsValue()?.widget_style?.[0]?.background_color
          : isValidUrl(form.getFieldsValue()?.widget_style?.[0]?.background_image)
          ? `url(${form.getFieldsValue()?.widget_style?.[0]?.background_image}) center/cover no-repeat`
          : `url(${backgroundImage[form.getFieldsValue()?.widget_style?.[0]?.background_image]}) center/cover no-repeat`,
      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
    };
  }, [widgetStyle]);

  const selectedLayout = form.getFieldValue(['widget_style', 0, 'layout'] || EWidgetLayout.COLUMNS);
  const columnCount = form.getFieldValue(['widget_style', 0, 'columns']) || 3;

  const limitResults = form.getFieldValue(['widget_style', 0, 'limit_results']) || 'show_all';
  const resultsPerPage = form.getFieldValue(['widget_style', 0, 'results_per_page']) || 2;

  const cardNavigationType = form.getFieldValue(['widget_style', 0, 'cards']) || EWidgetCards.MANUAL;

  const columnSpan = useMemo(() => {
    switch (columnCount) {
      case 2:
        return 12;
      case 3:
        return 8;
      case 4:
        return 6;
      case 5:
        return [5, 4];
      default:
        return 8;
    }
  }, [columnCount]);

  return { widgetBackgroundStyle, backgroundStyle, backgroundImage, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan };
};
