import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Modal, Space, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';
import './EventsDrawer.scss';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { Details } from '@aduvi/components/Entity/components/drawer-tabs/details/Details';
import { Files } from '@aduvi/components/Entity/components/drawer-tabs/files/Files';
import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm, mapEntityFieldsToPayload } from '@aduvi/components/Entity/helper';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useIsMobile, useUserPermissionCheck } from '@aduvi/hooks';
import { EEntityType } from '@aduvi/types/entity';
import { EventsDrawerTabs } from '@aduvi/types/event';

import { createEntity, editEntity, getEntity, setSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useManagementFields } from '../../../../@aduvi/hooks/useManagementFields';
import { EPersonalizedViewOrigin, IEntityField } from '../../../../@aduvi/types';

import { Agenda } from './agenda/Agenda';
import { Exhibitors } from './exhibitors/Exhibitors';
import { Forms } from './forms/Forms';
import { Settings } from './settings/Settings';

export const EventsDrawer = ({ onClose, open }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const {
    entities: { creating, loadingEntity, updating, selectedEntity },
    entityTypes: { data: entityTypes },
  } = useAppSelector((state) => state.entity);

  const { fields, formOnlyFields } = useManagementFields({ entityType: entityTypes?.EVENT, origin: EPersonalizedViewOrigin.EVENTS });

  const hasExhibitorsViewPermissions = useUserPermissionCheck(PERMISSIONS.EXHIBITORS.VIEW);

  const [tab, setTab] = useState<string>(EventsDrawerTabs.DETAILS);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [exhibitorsChanged, setExhibitorsChanged] = useState(false);

  const entityType = useMemo(() => {
    return entityTypes.EVENT;
  }, []);

  Form.useWatch('header_custom_fields', form);

  const tabItems = useMemo(() => {
    return drawerTabItems(false, isExpanded, hasExhibitorsViewPermissions, form, fields, formOnlyFields, translate);
  }, [selectedBusiness?.id, isExpanded, hasExhibitorsViewPermissions]);

  const updateSingleEvent = () => {
    if (!selectedBusiness?.id || !selectedEntity?.entity_type_id || !selectedEntity?.id || !entityType?.id) return;

    dispatch(
      editEntity({
        id: selectedEntity.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(form.getFieldValue('custom_fields'), fields, selectedEntity),
      }),
    )
      .unwrap()
      .then(onClose)
      .catch(() => {});
  };

  const createSingleEvent = () => {
    if (!selectedBusiness?.id || !entityTypes?.[EEntityType.EVENT]?.id) return;
    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityTypes?.[EEntityType.EVENT]?.id,
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), fields),
      }),
    )
      .unwrap()
      .then(onClose)
      .catch(() => {});
  };

  const submitFormHandler = () => {
    selectedEntity?.id ? updateSingleEvent() : createSingleEvent();
  };

  const closeDrawer = () => {
    if (exhibitorsChanged) {
      Modal.confirm({
        title: translate('assets.eventsDrawer.exhibitors.confirm'),
        content: translate('assets.eventsDrawer.exhibitors.contentMessage'),
        cancelText: translate('assets.eventsDrawer.exhibitors.cancelText'),
        onOk: () => {
          setExhibitorsChanged(false);
        },
        onCancel: () => {
          exitDrawer();
        },
      });
    } else {
      exitDrawer();
    }
  };

  const exitDrawer = () => {
    form.resetFields();
    setExhibitorsChanged(false);
    dispatch(setSelectedEntity(undefined));
    onClose();
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !selectedEntity?.id || !selectedEntity?.entity_type_id) return;

    dispatch(
      getEntity({
        businessId: selectedBusiness?.id,
        entityId: selectedEntity?.id,
        entityTypeId: selectedEntity?.entity_type_id,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        form.setFieldValue('custom_fields', mapEntityFieldsToForm(data, [...fields, ...formOnlyFields]));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, selectedEntity?.entity_type_id, selectedEntity?.id]);

  useEffect(() => {
    if (isMobile) setIsExpanded(false);
  }, [isMobile]);

  return (
    <Drawer
      className='event-drawer-wrapper'
      title={selectedEntity ? translate('assets.eventsDrawer.editEvent') : translate('assets.eventsDrawer.createEvent')}
      open={open}
      width={isExpanded ? '95%' : 720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={creating || updating}
            loading={creating || updating}
            requiredPermission={Math.random() ? PERMISSIONS.EVENTS.EDIT : PERMISSIONS.EVENTS.CREATE}
            disabledButton
            type='primary'
            onClick={submitFormHandler}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingEntity}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Col span={isExpanded ? 18 : 24}>
            <Tabs defaultActiveKey={EventsDrawerTabs.DETAILS} activeKey={tab} onChange={setTab} items={tabItems} />
          </Col>
        </Form>
      </Spin>
    </Drawer>
  );
};

const drawerTabItems = (
  disabled: boolean,
  isExpanded: boolean,
  disableExhibitors: boolean | undefined,
  form: FormInstance,
  fields: IEntityField[],
  formOnlyFields: IEntityField[],
  translate: (key: string) => string,
) => {
  return [
    {
      key: EventsDrawerTabs.DETAILS,
      label: translate('assets.eventsDrawer.details.title'),
      children: <Details fields={fields} formOnlyFields={formOnlyFields} form={form} />,
    },
    {
      key: EventsDrawerTabs.AGENDA,
      label: translate('assets.eventsDrawer.agenda.title'),
      children: <Agenda />,
      disabled,
    },
    {
      key: EventsDrawerTabs.EXHIBITORS,
      label: translate('assets.eventsDrawer.exhibitors.title'),
      children: <Exhibitors />,
      disabled: disabled || !disableExhibitors,
    },
    {
      key: EventsDrawerTabs.FORMS,
      label: translate('assets.eventsDrawer.forms.title'),
      children: <Forms />,
      disabled,
    },
    {
      key: EventsDrawerTabs.SETTINGS,
      label: translate('assets.eventsDrawer.settings.title'),
      children: <Settings expanded={isExpanded} form={form} />,
    },
    {
      key: EventsDrawerTabs.FILES,
      label: translate('assets.eventsDrawer.files.title'),
      children: <Files />,
    },
  ];
};
