import history from '@history';
import axios from 'axios';

import { Constants, OmittedNotificationMessages } from '@aduvi/constants';
import globalRouter from '@aduvi/routes/globalRouter';
import { getPlatformDomain } from '@aduvi/utils/helper';
import { Notification } from '@aduvi/utils/notification';

import { logout } from 'store/features/auth-slice';
import store from 'store/store';

import { api } from './api';

const instance = axios.create({
  baseURL: api.apiUrl,
});

instance.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = 'en'; // get language from i18;
  const token = localStorage.getItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers['Origin-Local'] = getPlatformDomain();

  return config;
});

instance.interceptors.response.use(
  async (response) => {
    if (response.data?.message) {
      Notification.success({ title: 'Success', description: response.data.message.success || response.data.message });
    }

    return response.data;
  },
  async (error) => {
    const status = error.response.status;

    if (status === 401) {
      localStorage.removeItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);
      history.replace('/auth/login');
      store.dispatch(logout());
      Notification.error({ title: 'Unauthorized action!', description: error?.response?.data?.message });
    } else if (status === 403) {
      Notification.error({ title: 'Forbidden!', description: `You don't have permission to access this resource.` });
      globalRouter?.navigate?.('/');
    } else {
      const endpointUrl: string = (error?.config?.url as string).replace(Constants.Regex.UUID, '{id}');

      if (!Object.prototype.hasOwnProperty.call(OmittedNotificationMessages, endpointUrl)) {
        handleErrorNotifications(status, error?.response?.data);
      }
    }

    return Promise.reject({ status, ...error?.response?.data });
  },
);

const handleErrorNotifications = (status: number, error: { message: string; data: { [key: string]: string[] } }) => {
  const message = error?.message || '';

  switch (status) {
    case 400:
      if (error?.data) {
        Object.values(error.data)?.map((value) => {
          Notification.error({ title: 'Bad Request!', description: value?.join('\n') });
        });
      } else {
        Notification.error({ title: 'Bad Request!', description: message });
      }
      break;
    case 404:
      Notification.error({ title: 'Not Found!', description: message });
      break;
    case 412:
      Notification.error({ title: 'Precondition Failed', description: message });
      break;
    case 409:
    case 422:
      Notification.error({ title: 'Conflict', description: message });
      break;
    case 500:
      Notification.error({ title: 'Something went wrong!', description: message || 'Internal Server Error!' });
      break;
    default:
      Notification.error({ title: 'Something went wrong!', description: 'Unknown issue' });
      break;
  }
};

export default instance;
