export enum EAvailabilityEntity {
  USER = 'USER',
  SERVICE_UNIT = 'SERVICE_UNIT',
  PRODUCT = 'PRODUCT',
}

export interface IGetEntitiesBlackoutDatesPayload {
  business_id: string;
  entity_type: EAvailabilityEntity;
  start_date: string;
  end_date: string;
}
export interface IGetSingleEntityBlackoutDatesResponse {
  unitable_id: string;
  start_date: string;
  end_date: string;
}
[];

export interface IGetSingleEntityBlackoutDatesPayload {
  business_id: string;
  entity_type: EAvailabilityEntity;
  entity_id: string;
  start_date: string;
  end_date: string;
}

export enum EUnitableType {
  USER = 'App\\Models\\PartnerUser',
  SERVICE_UNIT = 'App\\Models\\BusinessServiceUnit',
  PRODUCT = 'App\\Models\\BusinessProduct',
}

export interface ICreateSingleEntityBlackoutDatesPayload {
  business_id: string;
  body: {
    dates_to_delete: {
      unitable_id: string;
      start_date: string;
      end_date: string;
    }[];
    units: {
      unitable_type: EUnitableType;
      unitable_id: string;
      start_date: string;
      end_date: string;
    }[];
    note?: string;
  };
}
