import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Drawer as AntdDrawer, DrawerProps } from 'antd';

import { useActiveTheme } from '@aduvi/hooks';

export const Drawer: React.FC<DrawerProps> = (props) => {
  const activeTheme = useActiveTheme();

  const customHeaderStyle: React.CSSProperties = {
    background: activeTheme?.menuBg,
    color: activeTheme?.menuActiveText,
    ...props.headerStyle,
  };

  const customBodyStyle: React.CSSProperties = {
    ...props.bodyStyle,
    background: activeTheme?.bodyBg,
  };

  return (
    <AntdDrawer
      {...props}
      headerStyle={customHeaderStyle}
      bodyStyle={customBodyStyle}
      closeIcon={<CloseOutlined style={{ color: activeTheme?.menuText }} />}>
      {props.children}
    </AntdDrawer>
  );
};
