import { useNavigate } from 'react-router-dom';
import { FullScreenProposalPreview } from 'views/proposals/FullScreenProposalPreview';

import { Layout as ANTD_LAYOUT } from 'antd';

import { AppLayoutLoading } from '@aduvi/components/AppLayoutLoading/AppLayoutLoading';
import { useAppLoad, useResetScroll } from '@aduvi/hooks';
import { EAuthorized } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

import { FormFiller } from '../../views/forms/FormFiller';

import { AuthorizedRoutes } from './authorized-routes/AuthorizedRoutes';
import { ClientPortalRouter } from './client-portal-routes/ClientPortalRouter';
import { EmbedRoutes } from './embed-routes/EmbedRoutes';
import { NonAuthorizedRoutes } from './non-authorized-routes/NonAuthorizedRoutes';
import globalRouter from './globalRouter';

export const MainRouter = () => {
  if (window.location.pathname.startsWith('/forms/')) return <FormFiller />;
  if (window.location.pathname.includes('/embed/')) return <EmbedRoutes />;

  if (window.location.pathname.includes('/portal/'))
    return (
      <ANTD_LAYOUT>
        <ClientPortalRouter />
      </ANTD_LAYOUT>
    );

  if (window.location.pathname.includes('/pr/')) return <FullScreenProposalPreview />;

  const { isAuthorized, user } = useAppSelector((state) => state.auth);

  useAppLoad();
  useResetScroll();

  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  if (!user.loading && isAuthorized === EAuthorized.AUTHORIZED && user.user) {
    return <AuthorizedRoutes />;
  } else if (!user.loading && isAuthorized === EAuthorized.UNAUTHORIZED) {
    return <NonAuthorizedRoutes />;
  } else {
    return <AppLayoutLoading />;
  }
};
