export enum EFieldDataValueType {
  FIELD_DATA_TEXTS = 'field_data_texts',
  FIELD_DATA_LONG_TEXTS = 'field_data_long_texts',
  FIELD_DATA_TIMES = 'field_data_times',
  FIELD_DATA_DATE_TIMES = 'field_data_date_times',
  FIELD_DATA_EMAILS = 'field_data_emails',
  FIELD_DATA_PHONES = 'field_data_phones',
  FIELD_DATA_URLS = 'field_data_urls',
  FIELD_DATA_ADDRESSES = 'field_data_addresses',
  FIELD_DATA_LISTS = 'field_data_lists',
  FIELD_DATA_NUMBERS = 'field_data_numbers',
  FIELD_DATA_SEQUENTIAL_IDS = 'field_data_sequential_ids',
  FIELD_DATA_FILES = 'field_data_files',
  FIELD_DATA_IMAGES = 'field_data_images',
  FIELD_DATA_STAR_RATINGS = 'field_data_star_ratings',
  FIELD_DATA_SONGS = 'field_data_songs',
  FIELD_DATA_SIGNATURES = 'field_data_signatures',

  FIELD_DATA_REFERENCE = 'field_data_references', // Reference must stay every time in the end, because we are removing it from the base fields
}

export enum EEntityFieldListType {
  LIST = 'LIST',
  CHECK_BOX = 'CHECK_BOX',
  STATUS = 'STATUS',
}

export enum EImageExtensions {
  'JPG' = 'JPG',
  'JPEG' = 'JPEG',
  'PNG' = 'PNG',
}

export enum EFileExtensions {
  'PDF' = 'PDF',
  'CSV' = 'CSV',
  'DOC' = 'DOC',
  'DOCX' = 'DOCX',
  'XLS' = 'XLS',
  'XLSX' = 'XLSX',
}

export enum EFieldsVisibility {
  ALL = 'ALL',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum EReferenceEntityType {
  TICKET = 'TICKET',
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
  TEAM = 'TEAM',
  TEAM_USER = 'TEAM_USER',
  JOB = 'JOB',
  CONTACT = 'CONTACT',
  EVENT = 'EVENT',
  EXHIBITOR = 'EXHIBITOR',
  VENUE = 'VENUE',
  ORDER = 'ORDER',
  TASK = 'TASK',
  CUSTOM = 'CUSTOM',
  CONTRACT = 'CONTRACT',
  CONTRACT_TEMPLATE = 'CONTRACT_TEMPLATE',
  SHIFT = 'SHIFT',
}

export enum ETaskStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum EListValueType {
  STATUS = 'Status',
  PRIORITY = 'Priority',
}

export interface IUploadFile {
  url: string | null;
  size?: string;
  extension?: string;
}

export interface IEntityFieldInitialState {
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
  selectedField?: IEntityField;
  loadingField: boolean;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export interface ICreateEntityFieldPayload {
  businessId: string;
  entityTypeId: string;
  body: {
    business_id: string;
    entity_type_id: string;
    title: string;
    value_type: EFieldDataValueType;
    visibility: EFieldsVisibility;
    multiple: boolean;
    form_only: boolean;
    list_type: EEntityFieldListType;
    reference_entity_type_id: string;
    reference_entity_type: EReferenceEntityType;
    list_values?: {
      id?: string;
      color: string;
      is_default: boolean;
      weight: number;
      value: string;
      value_type: string;
    }[];
  };
}

export interface IEntityField {
  business_id: string;
  core: boolean;
  created_at: string;
  default_value: string;
  deleted_at: string;
  entity_type_id: string;
  hidden: boolean;
  form_only: boolean;
  id: string;
  multiple: boolean;
  place_holder: string;
  list_values: {
    color: string;
    created_at: string;
    deleted_at: string;
    field_id: string;
    id: string;
    is_archived: boolean;
    is_default: boolean;
    updated_at: string;
    value: string;
    value_type: string;
    weight: number;
  }[];
  required: boolean;
  list_type: EEntityFieldListType;
  reference_entity_type_id: string;
  reference_entity_type: EReferenceEntityType;
  settings: {
    allowed_extensions: EFileExtensions[];
    maximum_upload_size: string;
    size_unit: string;
    format: string;
    capture_end_date: string;
    date_granularity: string[];
    min_date: string;
    max_date: string;
  };
  title: string;
  updated_at: string;
  value_type: EFieldDataValueType;
  visibility: EFieldsVisibility;
  weight: number;
}

export interface IReorderEntityFieldsPayload {
  businessId: string;
  entityTypeId: string;
  fields: {
    field_id: string;
    weight: number;
  }[];
}

export interface IDeleteEntityFieldPayload {
  businessId: string;
  entityTypeId: string;
  fieldId: string;
}

export const ManagementFields = [
  {
    name: EFieldDataValueType.FIELD_DATA_TEXTS,
  },
  {
    name: EFieldDataValueType.FIELD_DATA_LONG_TEXTS,
  },
  {
    name: EFieldDataValueType.FIELD_DATA_TIMES,
    format: 'DD/MM/YYYY',
  },
  {
    name: EFieldDataValueType.FIELD_DATA_DATE_TIMES,
    format: 'dd/mm',
  },
  {
    name: EFieldDataValueType.FIELD_DATA_EMAILS,
  },
  {
    name: EFieldDataValueType.FIELD_DATA_PHONES,
  },
  {
    name: EFieldDataValueType.FIELD_DATA_URLS,
  },
  {
    name: EEntityFieldListType.CHECK_BOX,
    type: EFieldDataValueType.FIELD_DATA_LISTS,
    list_type: EEntityFieldListType.CHECK_BOX,
  },
  {
    name: EEntityFieldListType.LIST,
    type: EFieldDataValueType.FIELD_DATA_LISTS,
    list_type: EEntityFieldListType.LIST,
  },
  { name: EFieldDataValueType.FIELD_DATA_ADDRESSES },
  { name: EFieldDataValueType.FIELD_DATA_NUMBERS },
  { name: EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS },
  { name: EFieldDataValueType.FIELD_DATA_FILES },
  { name: EFieldDataValueType.FIELD_DATA_IMAGES },
  { name: EFieldDataValueType.FIELD_DATA_STAR_RATINGS },
  { name: EFieldDataValueType.FIELD_DATA_SONGS },
  { name: EFieldDataValueType.FIELD_DATA_SIGNATURES },
];
