import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

export const CreateProposal = () => {
  const { t: translate } = useTranslation();

  return (
    <Form.Item name={'proposal_theme'} label={translate('academy.proposalTheme')}>
      <Select placeholder={translate('academy.basicQuote')} />
    </Form.Item>
  );
};
