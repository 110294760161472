import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';

import { Button, Col, Form, FormInstance, Input, message, Row, Switch, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { generateIframeCode } from '@aduvi/utils/widgets-helper';

export const Embed = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const { widgetId } = useParams();

  const handleCopy = () => {
    const embedCodeValue = form.getFieldValue('embed_code');
    if (embedCodeValue) {
      navigator.clipboard
        .writeText(embedCodeValue)
        .then(() => {
          message.success(translate('widgets.copiedSuccessfully'));
        })
        .catch(() => {
          message.error(translate('widgets.copyFailed'));
        });
    } else {
      message.warning(translate('widgets.noEmbedCodeToCopy'));
    }
  };
  const brand = selectedBusiness?.brandings?.find((brand) => brand?.name === 'App');

  const embedCode = useMemo(() => {
    if (selectedBusiness?.id && widgetId) {
      return brand?.vanity_domain ? generateIframeCode(selectedBusiness.id, widgetId, 'packages') : '';
    }
    return '';
  }, [selectedBusiness?.id, widgetId, brand?.vanity_domain]);

  useEffect(() => {
    if (embedCode) {
      form.setFieldValue('embed_code', embedCode);
    }
  }, [embedCode, form]);

  return (
    <Col span={10} className=' mb-20'>
      <Typography.Text type='secondary'>{translate('widgets.embedWidget')}</Typography.Text>
      <Row className='mt-15'>
        <Form.Item name='remove_background_styling' valuePropName='checked'>
          <Switch />
        </Form.Item>
        <Typography.Text className='mt-5 ml-10'>{translate('widgets.removeBackgroundStyling')}</Typography.Text>
      </Row>
      <Form.Item name='embed_code' label={translate('widgets.embedCode')}>
        <Input.TextArea readOnly rows={4} placeholder='<div id=”...”></div><script>....</script>' />
      </Form.Item>
      <Row justify={'end'}>
        <Button type='link' onClick={handleCopy}>
          <CopyOutlined /> {translate('widgets.copyWidgetCode')}
        </Button>
      </Row>
    </Col>
  );
};
