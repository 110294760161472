import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const personalizedViewOrigin = EPersonalizedViewOrigin.CONTACTS;

export const Contacts = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes.data.CONTACT} personalizedViewOrigin={personalizedViewOrigin} />;
};
