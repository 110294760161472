import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

export const GoTo = () => {
  const { t: translate } = useTranslation();

  return (
    <Form.Item name={'go_to'} label={translate('automations.goTo')}>
      <Select placeholder={translate('automations.pleaseSelect')} />
    </Form.Item>
  );
};
