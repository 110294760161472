export const PlantATreeIcon = () => {
  return (
    <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ fontSize: '18px' }}>
      <path
        d='M16.1063 7.71504V7.21875L15.4777 6.5625L10.5676 1.42734L9.20352 0L7.8394 1.42734L2.92939 6.5625L2.30075 7.21875V7.71504V8.53125H3.67719H3.77169H4.27297L2.30075 10.5L1.64334 11.1562L0.985929 11.8125V12.8051V13.125H2.30075H2.46099H2.95815H3.28686L1.64334 15.0938L0.546289 16.4062L-0.000181198 17.0625V18.375H1.97204H8.21741V20.0156V21H10.1896V20.0156V18.375H16.435H18.4072V17.0625L17.8608 16.4062L16.7637 15.0938L15.1202 13.125H15.4489H15.9461H16.1063H17.4211V12.8051V11.8125L16.7637 11.1562L16.1063 10.5L14.1341 8.53125H14.6354H14.7299H16.1063V7.71504ZM10.1896 16.4062V8.85938V7.875H8.21741V8.85938V16.4062H3.11429L4.803 14.3842L5.91649 13.0512V11.1562H4.43321L5.66996 9.92168L7.2313 8.36309V6.5625H5.65352L9.20352 2.85059L12.7535 6.5625H11.1757V8.36309L12.7412 9.92578L13.9738 11.1562H12.4906V13.0512L13.604 14.3883L15.2928 16.4062H10.1896Z'
        fill='#B37FEB'
      />
    </svg>
  );
};
