import './Projects.scss';

import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const personalizedViewOrigin = EPersonalizedViewOrigin.PROJECTS;

export const Projects = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes?.data?.PROJECT} personalizedViewOrigin={personalizedViewOrigin} />;
};
