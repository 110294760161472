import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EllipsisOutlined, RightOutlined, SwitcherOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Col, Divider, Dropdown, Form, FormInstance, Input, Row, Switch, Typography } from 'antd';

import { EClientPortalComponentType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const CustomBlock = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [blockContent, setBlockContent] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const title = useWidgetTitle(
    'clientPortal.customBlock.title',
    'custom_blocks',
    selectedClientPortal?.client_portal_settings?.custom_blocks?.title,
    form,
  );

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              destroyPopupOnHide
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item initialValue={title} labelCol={{ span: 24 }} name={['client_portal_settings', 'custom_blocks', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' name={['client_portal_settings', 'custom_blocks', 'half_width']}>
                      <Switch
                        size='small'
                        defaultValue={selectedClientPortal?.client_portal_settings?.custom_blocks?.half_width}
                        style={{ margin: 6 }}
                      />
                    </Form.Item>
                    <Typography.Text>{translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 400 }}>
                        <Typography.Paragraph className='mb-10' type='secondary'>
                          {translate('clientPortal.customBlock.customBlockContent')}
                        </Typography.Paragraph>

                        <Form.Item name={['client_portal_settings', 'customBlock', 'block_content']}>
                          <Input.TextArea onChange={(e) => setBlockContent(e.target.value)} style={{ height: 120, resize: 'none' }} />
                        </Form.Item>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <SwitcherOutlined /> {translate('clientPortal.customBlock.blockContent')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.CUSTOM_BLOCKS, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='b-radius-10'>
      <Row>
        <Col span={24}>
          <div dangerouslySetInnerHTML={{ __html: blockContent }} />
        </Col>
      </Row>
    </Card>
  );
};
