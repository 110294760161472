import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarOutlined, CloseOutlined, FormOutlined, ShopOutlined, ShoppingCartOutlined, ShoppingOutlined, TeamOutlined } from '@ant-design/icons';

import { Col, Input, Menu, Row } from 'antd';
import './WidgetMenu.scss';

import { Button } from '@aduvi/components/Button/Button';
import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';
import { useActiveTheme } from '@aduvi/hooks';
import { useIsMobile } from '@aduvi/hooks/useIsMobile';

export const WidgetMenu = ({ onClose }: { onClose?: () => void }) => {
  const isMobile = useIsMobile();
  const activeTheme = useActiveTheme();

  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();

  const [selectedPath, setSelectedPath] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const widgetMenuItemsConfig = [
    { label: translate('widgets.business'), key: 'business', path: '/widgets/business', icon: <ShopOutlined /> },
    { label: translate('widgets.usersAndTeams'), key: 'users-teams', path: '/widgets/users-teams', icon: <TeamOutlined /> },
    { label: translate('widgets.services'), key: 'services', path: '/widgets/services', icon: <ShoppingOutlined /> },
    { label: translate('widgets.products'), key: 'products', path: '/widgets/products', icon: <ShoppingCartOutlined /> },
    { label: translate('widgets.eventTicketing'), key: 'event-ticketing', path: '/widgets/event-ticketing', icon: <CalendarOutlined /> },
    { label: translate('widgets.forms'), key: 'forms', path: '/widgets/forms', icon: <FormOutlined /> },
  ];

  const onNavigate = (path: string) => {
    navigate(path);
    setSelectedPath(path);
    onClose?.();
  };

  const buildMenu = (menu: { label: string; key: string; path: string; icon: React.ReactNode }) => {
    return {
      key: menu.path,
      label: menu.label,
      icon: menu.icon,
      onClick: () => onNavigate(menu.path),
    };
  };

  const filteredWidgetMenuItems = useMemo(() => {
    return widgetMenuItemsConfig
      .filter((item) => !item.label || item.label.toLowerCase().includes(searchTerm.toLowerCase()))
      .map((item) => buildMenu(item));
  }, [searchTerm]);

  useEffect(() => {
    setSelectedPath(location?.pathname);
  }, [location.pathname]);

  return (
    <Col className='widget-menu-wrapper'>
      <Row justify='space-between'>
        <Title className='fs-24'>{translate('widgets.title')}</Title>
        {isMobile && <Button icon={<CloseOutlined style={{ color: 'gray' }} />} type='link' onClick={onClose} />}
      </Row>

      <Input.Search
        enterButton
        className='mt-10'
        placeholder={translate('widgets.searchWidgetsPlaceholder')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
      />

      {filteredWidgetMenuItems.length > 0 && (
        <>
          <Paragraph className='widget-group-title' style={{ color: activeTheme?.menuText }}>
            {translate('widgets.apps')}
          </Paragraph>
          <Menu mode='vertical' selectedKeys={[selectedPath]} items={filteredWidgetMenuItems} style={{ border: '0px', background: 'transparent' }} />
        </>
      )}

      {!filteredWidgetMenuItems.length && (
        <Paragraph className='widget-group-title' style={{ color: activeTheme?.menuText }}>
          {translate('widgets.noItems')}
        </Paragraph>
      )}
    </Col>
  );
};
