import { ProjectContacts } from 'views/projects/project-tabs/contact/Contact';

import { Col, Empty, Row } from 'antd';

import { ContactCard } from '@aduvi/components/ContactCard/ContactCard';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { EEntityType } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

export const Contacts = ({ origin }: { origin?: EPersonalizedViewOrigin }) => {
  const {
    entities: { selectedEntity },
    entityTypes: { data },
  } = useAppSelector((state) => state.entity);

  const contactFields = selectedEntity?.custom_fields.filter(
    (item) => item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type_id === data[EEntityType.CONTACT]?.id,
  );

  return origin && origin !== EPersonalizedViewOrigin.PROJECTS ? (
    <>
      {contactFields?.length && contactFields.filter((item) => item.field_data.length).length ? (
        <Row>
          {contactFields.map((item) => {
            return item.field_data.map((field) => (
              <Col span={12} key={field.id}>
                <ContactCard key={item.id} contactFields={field} isEventContact={true} />
              </Col>
            ));
          })}
        </Row>
      ) : (
        <Col>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      )}
    </>
  ) : (
    <ProjectContacts />
  );
};
