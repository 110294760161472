import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DashboardOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Button, Dropdown, MenuProps, Popconfirm, Row } from 'antd';

import { CustomTableCell } from '@aduvi/components/PersonalizedViewComponents/CustomTableCell/CustomTableCell';
import { deleteEntityPermission } from '@aduvi/constants/entity-type';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, ICustomFieldSpecificStyle, ICustomFieldViewStyle } from '@aduvi/types';
import { EEntityType, IEntityWithFields, IUseEntity, IUseTableColumns } from '@aduvi/types/entity';
import { EFormType } from '@aduvi/types/form';
import { getDefaultPersonalizedViewColumns } from '@aduvi/utils/custom-fields-helper';

import { getClientPortals } from 'store/features/client-portal-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';

import { useManagementFields } from '../../hooks/useManagementFields';
import { CustomFieldValue } from '../CustomField/CustomFieldValue/CustomFieldValue';

export const useEntitiesTableColumns = ({ columns, origin, entityType, fields, onEdit, onDelete, onTableRowAction }: IUseTableColumns) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { entities } = useAppSelector((state) => state.entity);

  const selectedBusiness = useBusiness();
  const referenceEntityIds = fields
    ?.filter((field) => field?.reference_entity_type)
    .map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );

  const getEntitiesDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('leads.edit'),
      onClick: onEdit,
    },
    {
      key: '1',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('leads.delete')}
          disabled={!useUserPermissionCheck(entityType?.name && deleteEntityPermission[entityType?.name])}
          description={translate('leads.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            return onDelete();
          }}>
          {translate('leads.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
      disabled: !useUserPermissionCheck(entityType?.name && deleteEntityPermission[entityType?.name]),
    },
    ...(entityType?.name === EEntityType.JOB || entityType?.name === EEntityType.ORDER
      ? [
          {
            key: '2',
            icon: <DashboardOutlined />,
            label: translate('clientPortal.viewInClientPortal'),
            onClick: (e: { domEvent?: { stopPropagation: () => void } }) => {
              e?.domEvent?.stopPropagation();

              return navigate(`/portal/portal/${entityType.name.toLowerCase()}/${entities.selectedEntity?.id}`);
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getClientPortals(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  return [
    ...(columns?.map((column) => {
      const isReferenceEntity = referenceEntities?.data?.some(
        (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
      );

      return {
        title: !isReferenceEntity
          ? column?.name || column?.id
          : ` ${referenceEntities?.data?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
              ?.entity_name} ${referenceEntities?.data
              ?.find((entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)))
              ?.custom_fields?.find((field) => column?.field_id?.includes(field?.id))?.title}`,
        render: (_: unknown, record: IEntityWithFields) => {
          return {
            children: column?.field_id?.map((fieldId, index) => {
              const isReferenceEntity = referenceEntities?.data?.some(
                (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
              );
              const referenceEntity = referenceEntities?.data?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId));
              const referenceEntityField = referenceEntities?.data
                ?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId))
                ?.custom_fields?.find((customField) => customField?.id === fieldId);

              const applyCellStyles = (record: IEntityWithFields, fieldId: string) => {
                if (!record?.custom_fields) return {};
                const field = record.custom_fields.find((field) => field?.id === fieldId);

                return Array.isArray(field?.field_data) &&
                  field?.field_data &&
                  field?.field_data?.length > 0 &&
                  field.field_data?.some((data) => data.value !== null)
                  ? { ...(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style }
                  : {};
              };

              return (
                <CustomTableCell
                  key={index}
                  style={{
                    minWidth: '200px',
                    ...applyCellStyles(record, fieldId),
                  }}>
                  <CustomFieldValue
                    key={index}
                    style={{
                      ...applyCellStyles(record, fieldId),
                    }}
                    field={
                      isReferenceEntity
                        ? record?.custom_fields
                            ?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                            ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === referenceEntityField?.id)
                        : record?.custom_fields?.find((field) => field?.id === fieldId)
                    }
                    record={
                      isReferenceEntity
                        ? (record?.custom_fields?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                            ?.field_data?.[0] as unknown as IEntityWithFields)
                        : record
                    }
                    managementFields={fields}
                    origin={origin}
                    entityType={entityType}
                  />
                </CustomTableCell>
              );
            }),
          };
        },
      };
    }) || []),
    {
      key: 'action',
      render: (_: unknown, record: IEntityWithFields) => (
        <Row className='w-full' justify='end' onClick={(e) => e?.stopPropagation()}>
          <Dropdown menu={{ items: getEntitiesDropdownItems }} trigger={['click']}>
            <Button
              onClick={(e) => {
                e?.stopPropagation();
                return onTableRowAction(record);
              }}
              icon={<EllipsisOutlined />}
              type='text'
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
};

export const useEntity = ({ entityType, origin }: IUseEntity) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { fields, formOnlyFields } = useManagementFields({ entityType, origin });

  const { selectedView } = useAppSelector((state) => state?.personalizedViews);

  const [editRowLayoutDrawer, setEditRowLayoutDrawer] = useState(false);
  const [showEntityDrawer, setShowEntityDrawer] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [entityColumns, setEntityColumns] = useState<ICustomFieldViewStyle[]>([]);

  const formType: Record<EPersonalizedViewOrigin, EFormType | string> = {
    BOOKINGS: EFormType.BOOKINGS_FORM,
    [EPersonalizedViewOrigin.LEADS]: EFormType.LEADS_FORM,
    [EPersonalizedViewOrigin.FORMS]: EFormType.CONTACTS_FORM,
    [EPersonalizedViewOrigin.EVENTS]: '',
    [EPersonalizedViewOrigin.ORDERS]: '',
    [EPersonalizedViewOrigin.CONTACTS]: '',
    [EPersonalizedViewOrigin.VENUES]: '',
    [EPersonalizedViewOrigin.EXHIBITORS]: '',
    [EPersonalizedViewOrigin.TASKS]: '',
    [EPersonalizedViewOrigin.PROJECTS]: '',
  };

  const initialColumns: ICustomFieldViewStyle[] = useMemo(() => {
    return getDefaultPersonalizedViewColumns(fields);
  }, [fields]);

  useEffect(() => {
    if (!selectedView?.style) return setEntityColumns(initialColumns);

    const columns = JSON.parse(selectedView?.style);
    if (columns) {
      return setEntityColumns(columns);
    } else {
      setEntityColumns(initialColumns);
    }
  }, [selectedView?.style, initialColumns]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(getEntityFields({ businessId: selectedBusiness?.id, entityTypeId: entityType?.id })).catch(() => {});
    }
  }, [selectedBusiness?.id, entityType?.id]);

  useEffect(() => {
    if (selectedView?.style) {
      setEntityColumns(JSON.parse(selectedView?.style));
    }
  }, [selectedView?.style]);

  return {
    dispatch,
    selectedBusiness,
    editRowLayoutDrawer,
    showEntityDrawer,
    entityColumns,
    showFormModal,
    initialColumns,
    fields,
    formOnlyFields,
    formType: formType[origin],
    setShowFormModal,
    setEntityColumns,
    setShowEntityDrawer,
    setEditRowLayoutDrawer,
  };
};
