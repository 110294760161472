import { useEffect } from 'react';

import { Constants } from '@aduvi/constants';
import { EAuthorized } from '@aduvi/types';

import { getBusiness, getClientData, setAuthorization } from 'store/features/client-auth-slice';
import { getClientPortalById } from 'store/features/client-portal-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useClientPortalLoad = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized: isAuthorizedUser, user: userAuth } = useAppSelector((state) => state.auth);

  const getUser = (businessId: string) => {
    const token = localStorage.getItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);
    if (!token) {
      return dispatch(setAuthorization(EAuthorized.UNAUTHORIZED));
    }

    if (isAuthorizedUser && userAuth.user) {
      return dispatch(setAuthorization(EAuthorized.AUTHORIZED));
    }

    dispatch(getClientData({ businessId }))
      ?.unwrap()
      .then((data) => {
        if (data) {
          dispatch(setAuthorization(EAuthorized.AUTHORIZED));
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    dispatch(getBusiness())
      ?.unwrap()
      .then((res) => {
        getUser(res?.data?.id);
        dispatch(getClientPortalById({ businessId: res?.data?.id }));
      })
      .catch(() => {});
  }, []);
};
