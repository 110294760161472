import { useMemo } from 'react';

import './UserProfileMenu.scss';

import { Constants } from '@aduvi/constants';
import { profileAccountMenu, profileExploreMenu } from '@aduvi/constants/navigation-config';
import { useIsBusinessOwner, useSelectedPlan, useUser, useUserBusinessPermissions } from '@aduvi/hooks';
import { ThemeAdapter } from '@aduvi/patterns/adapter/ThemeAdapter';
import { IBusiness } from '@aduvi/types/business';
import { hasUserPermission, isAppIncludedInPlan } from '@aduvi/utils/helper';

import { logout, resetAuthState } from 'store/features/auth-slice';
import { setSelectedBusiness } from 'store/features/business-slice';
import { setActiveTheme } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

export const useUserProfileMenu = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const isBusinessOwner = useIsBusinessOwner();
  const userBusinessPermissions = useUserBusinessPermissions();

  const { selectedPlan, planApps } = useSelectedPlan();

  const activateBusinessTheme = (business: IBusiness) => {
    if (user) {
      const newActiveTheme = new ThemeAdapter(user, business);
      dispatch(setActiveTheme(newActiveTheme.getActiveTheme()));
    }
  };

  const onBusinessChange = (business: IBusiness) => {
    const brand = business?.brandings?.find((brand) => brand?.name === 'App');

    if (brand) {
      const url = brand?.vanity_domain;
      if (url) {
        window.location.replace(`${url}/dashboard`);
      }
    }
    dispatch(setSelectedBusiness(business));
    activateBusinessTheme(business);
  };

  const onLogout = () => {
    localStorage.removeItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);
    localStorage.removeItem(Constants.LocalStorage.Authorization.REFRESH_TOKEN);
    dispatch(resetAuthState());
    dispatch(logout());
  };

  const allowedProfileAccountMenu = useMemo(() => {
    return profileAccountMenu.filter(
      (item) =>
        hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission) && isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key),
    );
  }, [selectedPlan?.data?.id]);

  const allowedProfileExploreMenu = useMemo(() => {
    return profileExploreMenu.filter(
      (item) =>
        hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission) && isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key),
    );
  }, [selectedPlan?.data?.id]);

  return {
    allowedProfileAccountMenu,
    allowedProfileExploreMenu,
    selectedPlan,
    planApps,
    isBusinessOwner,
    userBusinessPermissions,
    onLogout,
    onBusinessChange,
    activateBusinessTheme,
  };
};
