import { WarningFilled } from '@ant-design/icons';

import { Col, Row, Select, SelectProps } from 'antd';

import { ETicketPriority } from '@aduvi/types/ticket';

export const priorityOptions = [
  {
    value: ETicketPriority.MEDIUM,
    label: (
      <Col>
        <WarningFilled style={{ color: 'orange' }} /> Medium
      </Col>
    ),
  },
  {
    value: ETicketPriority.LOW,
    label: (
      <Col>
        <WarningFilled style={{ color: 'green' }} /> Low
      </Col>
    ),
  },
  {
    value: ETicketPriority.URGENT,
    label: (
      <Col>
        <WarningFilled /> Urgent
      </Col>
    ),
  },
  {
    value: ETicketPriority.HIGH,
    label: (
      <Col>
        <WarningFilled style={{ color: 'red' }} /> High
      </Col>
    ),
  },
];

export const SelectPriority = (props: SelectProps) => {
  return (
    <Row>
      <Select {...props} options={priorityOptions}></Select>
    </Row>
  );
};
