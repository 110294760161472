import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Slider } from 'antd';
import { SliderRangeProps } from 'antd/es/slider';

interface IProps extends SliderRangeProps {
  minTime: number;
  maxTime: number;
  initialStartTime: number;
  initialEndTime: number;
  step: number;
}

export const ShiftSlider = ({ initialEndTime, initialStartTime, maxTime, minTime, step, onChange, ...props }: IProps) => {
  const [selectedRange, setSelectedRange] = useState<[number, number]>([initialStartTime, initialEndTime]);

  const formatTime = (minutes: number) => dayjs().startOf('day').add(minutes, 'minute').format('HH:mm');

  const handleRangeChange = (value: number[]) => {
    setSelectedRange(() => [value[0], value[1]]);
    onChange?.(value);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} className='ml-10 mr-10'>
        <Slider
          {...props}
          min={minTime}
          max={maxTime}
          step={step}
          tooltip={{
            formatter: (value) => (value ? formatTime(value) : undefined),
          }}
          value={selectedRange}
          style={{ flex: 1 }}
          onChange={handleRangeChange}
        />
      </div>
    </div>
  );
};
