import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInstance } from 'antd';

import { IColumnType } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType } from '@aduvi/types';

export const removeWidget = (key: EClientPortalComponentType, form?: FormInstance) => {
  const updatedWidgets = form?.getFieldValue(['client_portal_settings', 'layout'])?.map((item: IColumnType) => {
    if (item?.index !== key) return item;

    return { ...item, visible: false };
  });

  form?.setFieldValue(['client_portal_settings', 'layout'], updatedWidgets);
};

export const useWidgetTitle = (translationKey: string, widgetIndex: string, widgetSettings?: string, form?: FormInstance) => {
  const { t: translate } = useTranslation();

  return useMemo(() => {
    if (form?.getFieldValue(['client_portal_settings', widgetIndex, 'title'])) {
      return form?.getFieldValue(['client_portal_settings', widgetIndex, 'title']);
    }
    if (widgetSettings) {
      return widgetSettings;
    }

    return translate(translationKey);
  }, [form?.getFieldValue(['client_portal_settings', widgetIndex, 'title'])]);
};
