/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { MentionList } from './MentionList';

export const useSuggestion = () => {
  return {
    items: () => {
      return [];
    },
    char: '{',
    render: () => {
      let component: any;
      let popup: any;

      return {
        onExit() {
          popup[0].destroy();
          component.destroy();
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return component.ref?.onKeyDown(props);
        },

        onStart: (props: any) => {
          component = new ReactRenderer(MentionList, {
            editor: props.editor,
            props,
          });

          popup = tippy('body', {
            appendTo: () => document.body,
            content: component.element,
            getReferenceClientRect: props.clientRect,
            interactive: true,
            placement: 'bottom-start',
            showOnCreate: true,
            trigger: 'manual',
          });
        },

        onUpdate(props: any) {
          component.updateProps(props);

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },
      };
    },
  };
};
