import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Avatar, Button, Col, List, Row } from 'antd';
import './History.scss';

import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { EAutomationNodeAction, ENodeStatus, INodeHistory } from '@aduvi/types/automation';
import { toHumanReadable } from '@aduvi/utils/helper';

import { getAutomationHistory, setSelectedAutomationHistory } from 'store/features/automation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const History = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const { selectedAutomation, selectedAutomationHistory, loadingAutomationHistory } = useAppSelector((state) => state.automation);

  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);

  const getNodeHistoryTitle = (nodeHistory: INodeHistory) => {
    const node = selectedAutomation?.nodes.find((n) => n.id === nodeHistory?.node_id);
    if (!node) return translate('automations.unknownNodeAction');

    let actionText: string | undefined = '';
    switch (node.action) {
      case EAutomationNodeAction.SEND_EMAIL:
        actionText = 'Email';
        break;
      case EAutomationNodeAction.SEND_SMS:
        actionText = 'SMS';
        break;
      default:
        actionText = node?.action?.replace('_', ' ').toLowerCase();
    }

    let title = node?.payload?.name || node?.title;

    let recipient = '';
    const response = nodeHistory?.response?.response;

    recipient = translate('automations.unknownRecipient');

    if (response?.to) {
      recipient = response.to;
    }

    return (
      <>
        {actionText} “{title}” sent to <span style={{ color: activeTheme?.buttonBg }}>{recipient}</span>
      </>
    );
  };

  const getNodeHistoryDescription = (nodeHistory: INodeHistory) => {
    const executedAt = nodeHistory?.created_at;
    const formattedDate = dayjs(executedAt).format('h:mma Do MMMM YYYY');
    const relativeTimeStr = dayjs(executedAt).fromNow();
    const statusColor =
      nodeHistory?.status === ENodeStatus.COMPLETED ? '#52C41A' : nodeHistory?.status === ENodeStatus.FAILED ? '#FF4D4F' : 'inherit';

    return (
      <>
        <span style={{ color: statusColor }}>{toHumanReadable(nodeHistory?.status)}</span> - {formattedDate} ({relativeTimeStr})
      </>
    );
  };

  const nodeHistories = selectedAutomationHistory?.data.flatMap((history) => history?.node_histories) || [];

  const onGetAutomationHistory = useCallback(
    (page = 1, size = 5) => {
      if (!selectedBusiness?.id || !selectedAutomation?.id) return;
      dispatch(getAutomationHistory({ businessId: selectedBusiness.id, automationId: selectedAutomation.id, params: { page, size } }))
        .unwrap()
        .then((data) => {
          setSelectedAutomationHistory(data);
        })
        .catch(() => {});
    },
    [dispatch, selectedBusiness?.id, selectedAutomation?.id],
  );

  useEffect(() => {
    onGetAutomationHistory(1, 5);
  }, [selectedBusiness?.id, selectedAutomation?.id]);

  return (
    <Row justify='center' className='automation-history-container'>
      <Col xs={24} sm={20} md={16} lg={12} xl={10}>
        <List
          className='history-list'
          loading={loadingAutomationHistory}
          dataSource={nodeHistories}
          pagination={{
            position: 'bottom',
            align: 'start',
            total: selectedAutomationHistory?.total,
            pageSize: selectedAutomationHistory?.per_page,
            current: selectedAutomationHistory?.current_page,
            onChange: (page, pageSize) => {
              onGetAutomationHistory(page, pageSize);
            },
          }}
          renderItem={(nodeHistory) => (
            <List.Item
              key={nodeHistory.id}
              className='history-list-item'
              actions={[
                <Button key={`resend-${nodeHistory.id}`} type='link'>
                  {translate('automations.resend')}
                </Button>,
              ]}>
              <List.Item.Meta avatar={<Avatar />} title={getNodeHistoryTitle(nodeHistory)} description={getNodeHistoryDescription(nodeHistory)} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};
