import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleFilled, LockOutlined, PlayCircleTwoTone } from '@ant-design/icons';

import { Col, List, Row, Space, Spin, Typography } from 'antd';

import { Title } from '@aduvi/components/Title/Title';
import { useBusiness } from '@aduvi/hooks';
import { AcademyGroupedByLevel, IAcademy } from '@aduvi/types/academy';

import { useGetAcademyAchievementsQuery, useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

import { groupAcademiesByLevel } from '../helper';

export const AcademyMenu = () => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { id } = useParams();

  const { data: academies, isLoading: loading } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const { data: academyAchievement } = useGetAcademyAchievementsQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const groupedByLevel: AcademyGroupedByLevel | undefined = useMemo(() => groupAcademiesByLevel(academies?.data), [academies?.data]);

  const allAcademiesSorted = useMemo(() => {
    if (!groupedByLevel) return [];
    const levels = Object.keys(groupedByLevel).sort((a, b) => Number(a) - Number(b));
    return levels.reduce((acc: IAcademy[], levelKey) => {
      const items = groupedByLevel[Number(levelKey)];
      return acc.concat(items);
    }, []);
  }, [groupedByLevel]);

  const isAcademyPassed = (academyId: string) => {
    return academyAchievement?.data?.some((ach) => ach.academy_id === academyId && ach.passed) || false;
  };

  const canAccessAcademy = (academyId: string) => {
    const currentIndex = allAcademiesSorted.findIndex((a) => a.id === academyId);

    if (currentIndex === 0) return true;

    const previousAcademy = allAcademiesSorted[currentIndex - 1];
    return isAcademyPassed(previousAcademy.id);
  };

  return (
    <Spin spinning={loading}>
      <Row className='p-30'>
        <Space direction='vertical'>
          <Typography.Title className='fw-500 fs-24'>{translate('academy.title')}</Typography.Title>
          <Typography.Text>{translate('academy.description')}</Typography.Text>

          <Col>
            {groupedByLevel &&
              Object.keys(groupedByLevel)
                ?.sort((a, b) => Number(a) - Number(b))
                ?.map((level) => (
                  <div key={level} style={{ marginBottom: '20px' }}>
                    <Title level={3} className='mt-10 mb-0'>
                      {translate('academy.level')} {level}
                    </Title>
                    <List
                      dataSource={groupedByLevel[Number(level)]}
                      renderItem={(item, index) => {
                        const passed = isAcademyPassed(item.id);
                        const accessible = canAccessAcademy(item.id);
                        const handleClick = () => {
                          if (accessible) {
                            navigate(`./${item.id}`);
                          }
                        };

                        return (
                          <List.Item
                            key={index}
                            style={{ cursor: accessible ? 'pointer' : 'not-allowed', opacity: accessible ? 1 : 0.5 }}
                            onClick={handleClick}>
                            {passed ? (
                              <>
                                <Typography.Text key={index} delete={true} type='secondary'>
                                  {item?.name}
                                </Typography.Text>
                                <CheckCircleFilled style={{ color: '#52C41A' }} />
                              </>
                            ) : item.id === id ? (
                              <>
                                <Typography.Text>{item?.name}</Typography.Text>
                                <PlayCircleTwoTone />
                              </>
                            ) : accessible ? (
                              <Typography.Text>{item?.name}</Typography.Text>
                            ) : (
                              <>
                                <Typography.Text>{item?.name}</Typography.Text>
                                <LockOutlined />
                              </>
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                ))}
          </Col>
        </Space>
      </Row>
    </Spin>
  );
};
