import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IClientPortal, IClientPortalState, ICreateClientPortalPayload } from '@aduvi/types/client-portal';

import * as ClientPortalService from 'store/services/client-portal.service';

const initialState: IClientPortalState = {
  clientPortals: undefined,
  selectedClientPortal: undefined,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
};

export const createClientPortal = createAsyncThunk(
  'client-portal/create-client-portal',
  async (payload: ICreateClientPortalPayload, { rejectWithValue }) => {
    try {
      return await ClientPortalService.createClientPortal(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateClientPortal = createAsyncThunk(
  'client-portal/update-client-portal',
  async ({ clientPortalId, payload }: { clientPortalId: string; payload: ICreateClientPortalPayload }, { rejectWithValue }) => {
    try {
      return await ClientPortalService.updateClientPortal(clientPortalId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getClientPortals = createAsyncThunk('client-portal/get-client-portals', async (business_id: string, { rejectWithValue }) => {
  try {
    return await ClientPortalService.getClientPortals(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getClientPortalById = createAsyncThunk(
  'client-portal/get-client-portal-by-id',
  async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
    try {
      return await ClientPortalService.getClientPortalById(businessId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteClientPortal = createAsyncThunk(
  'client-portal/delete-client-portal',
  async (
    { businessId, clientPortalId, clientPortalName }: { businessId: string; clientPortalId: string; clientPortalName: string },
    { rejectWithValue },
  ) => {
    try {
      await ClientPortalService.deleteClientPortal({ businessId, clientPortalId, clientPortalName });
      return clientPortalId;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const clientPortalSlice = createSlice({
  name: 'clientPortal',
  initialState,
  reducers: {
    setSelectedClientPortal(state, action: PayloadAction<IClientPortal | undefined>) {
      state.selectedClientPortal = action.payload ? action.payload : undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientPortals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientPortals.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.clientPortals = payload.data;
      })
      .addCase(getClientPortals.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getClientPortalById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClientPortalById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedClientPortal = payload.data;
      })
      .addCase(getClientPortalById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createClientPortal.pending, (state) => {
        state.creating = true;
      })
      .addCase(createClientPortal.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.clientPortals) {
          state.clientPortals = [...state.clientPortals, payload.data];
          return;
        }
        state.clientPortals = [payload.data];
      })
      .addCase(createClientPortal.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateClientPortal.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateClientPortal.fulfilled, (state, { payload }) => {
        state.updating = false;

        if (state.clientPortals) {
          state.clientPortals = state.clientPortals?.map((item) => (item?.id === payload.data?.id ? payload.data : item));
        }
        state.selectedClientPortal = payload.data;
      })
      .addCase(updateClientPortal.rejected, (state) => {
        state.updating = false;
      })

      .addCase(deleteClientPortal.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteClientPortal.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.clientPortals) {
          state.clientPortals = state.clientPortals.filter((portal) => portal.id !== meta.arg.clientPortalId);
        }
        state.selectedClientPortal = undefined;
      })
      .addCase(deleteClientPortal.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const clientPortalReducer = clientPortalSlice.reducer;
export const { setSelectedClientPortal } = clientPortalSlice.actions;
