import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import {
  ICreateShiftSettingsPayload,
  ICreateShiftSettingsResponse,
  IPosition,
  IReorderPositionPayload,
  IShiftPayload,
  IShiftResponse,
  IShiftSchedulerSettings,
  IUpdateShiftSettingsPayload,
  IUpdateShiftSettingsResponse,
} from '@aduvi/types/shift-scheduler';

export const getShiftSchedulerSettings = async (businessId: string): Promise<IResponse<IShiftSchedulerSettings>> => {
  return await axios.get(`/businesses/${businessId}/shift-scheduler/settings`);
};

export const updateShiftSchedulerSettings = async (payload: IUpdateShiftSettingsPayload): Promise<IResponse<IUpdateShiftSettingsResponse>> => {
  return await axios.put(`/businesses/${payload.business_id}/shift-scheduler/settings/${payload.setting_id}`, payload);
};

export const createShiftSchedulerSettings = async (payload: ICreateShiftSettingsPayload): Promise<IResponse<ICreateShiftSettingsResponse>> => {
  return await axios.post(`businesses/${payload.business_id}/shift-scheduler/settings`, payload);
};

export const deleteShiftSchedulerSettings = async (businessId: string, settingId: string): Promise<IResponse<IShiftSchedulerSettings>> => {
  return await axios.delete(`bussinesses/${businessId}/shift-scheduler/settings/${settingId}`);
};

export const upsertShift = async (payload: IShiftPayload): Promise<IResponse<IShiftResponse[]>> => {
  return await axios.put(`businesses/${payload.business_id}/entity/${payload.entity_id}/shift-scheduler`, payload);
};

export const reorderPositions = async (payload: IReorderPositionPayload): Promise<IResponse<IPosition[]>> => {
  return await axios.put(`businesses/${payload.business_id}/shift-scheduler/positions/re-order`);
};

export const deleteSettingsShiftPosition = async (businessId: string, positionId: string) => {
  return await axios.delete(`businesses/${businessId}/shift-scheduler/positions/${positionId}`);
};

export const deleteSettingsShiftWage = async (businessId: string, wageId: string) => {
  return await axios.delete(`businesses/${businessId}/shift-scheduler/wages/${wageId}`);
};
