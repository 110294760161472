import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Button, Card, Col, Form, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';

export const Design = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  const [isMobileView, setIsMobileView] = useState(false);

  const { widgetBackgroundStyle, backgroundStyle } = useDesign({ form });

  return (
    <Row className='forms-design-tab-wrapper' style={backgroundStyle}>
      <Col span={14}>
        <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
          <Typography.Title
            level={3}
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
              color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
            }}>
            {translate('widgets.bookOnline')}
          </Typography.Title>

          <Form
            form={form}
            layout='vertical'
            style={{
              maxWidth: '400px',
              margin: '0 auto',
              fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
              color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
            }}>
            <Form.Item
              label={
                <label
                  style={{
                    fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                    color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                  }}>
                  Event Date
                </label>
              }
              name='event_date'
              style={{
                fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
              }}
              rules={[{ required: true, message: 'Please select your event date!' }]}>
              <DatePicker className='w-full' />
            </Form.Item>

            <Form.Item
              label={
                <label
                  style={{
                    fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                    color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                  }}>
                  Event Type
                </label>
              }
              name='event_type'
              rules={[{ required: true, message: 'Please select your event type!' }]}>
              <Select>
                <Select.Option value='wedding'>Wedding</Select.Option>
                <Select.Option value='birthday'>Birthday</Select.Option>
                <Select.Option value='corporate'>Corporate Event</Select.Option>
                <Select.Option value='other'>Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item className='flex-center'>
              <Button type='primary' htmlType='submit'>
                {translate('widgets.checkAvailability')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={1} className='icons-container'>
        <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
      </Col>
    </Row>
  );
};
