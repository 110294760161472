import { useTranslation } from 'react-i18next';

import { Form, FormInstance } from 'antd';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';

export const DateSelection = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  Form.useWatch('selected_date');

  return (
    <Form.Item className='w-full' name='selected_date' label={translate('automations.selectDate')}>
      <DatePicker className='w-full' value={form.getFieldValue('selected_date')} />
    </Form.Item>
  );
};
