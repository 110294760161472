import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Typography } from 'antd';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { useBusiness } from '@aduvi/hooks';
import { ICustomFieldViewStyle, IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';

const Card = ({ card, managementFields }: { card: IEntityWithFields; managementFields: IEntityField[] }) => {
  const selectedBusiness = useBusiness();
  const { selectedView } = useAppSelector((state) => state.personalizedViews);
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: card?.id,
  });
  const referenceEntityIds = managementFields
    ?.filter((field) => field?.reference_entity_type)
    ?.map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );
  const viewColumns = useMemo(() => {
    if (!selectedView) return;

    return (JSON.parse(selectedView?.style) as ICustomFieldViewStyle[])?.map((item) => {
      const isReferenceEntity = referenceEntities?.data?.some((entity) => entity?.custom_fields?.some((field) => item?.field_id?.[0] === field?.id));
      const referenceEntity = referenceEntities?.data?.find((entity) => entity?.custom_fields?.find((field) => field?.id === item?.field_id?.[0]));
      const fieldId = item?.field_id?.[0];
      const referenceEntityField = referenceEntities?.data
        ?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId))
        ?.custom_fields?.find((customField) => customField?.id === fieldId);
      return {
        id: item?.id,
        field: isReferenceEntity
          ? card?.custom_fields
              ?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
              ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === referenceEntityField?.id)
          : card?.custom_fields?.find((field) => field?.id === fieldId),
        style: item?.style,
      };
    });
  }, [selectedView, card]);

  const style = {
    margin: '10px',
    opacity: 1,
    color: '#333',
    background: '#F9FCFF',
    borderRadius: '5px',
    overflow: 'hidden',
    transform: CSS.Transform.toString(transform),
    boxShadow: `0px 2px 8px 0px ${isDragging ? '#5194D880' : '#5194D826'}`,
    rotate: isDragging ? '-5deg' : '0deg',
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style} key={card?.id}>
      {viewColumns?.map((viewColumn, index) => {
        const isReferenceEntity = referenceEntities?.data?.some(
          (entity) => entity?.custom_fields?.some((field) => viewColumn?.field?.id === field?.id),
        );
        const referenceEntity = referenceEntities?.data?.find(
          (entity) => entity?.custom_fields?.find((field) => field?.id === viewColumn?.field?.id),
        );

        return (
          <Typography.Paragraph className='mb-0' key={index} style={{ ...viewColumn?.style, padding: '6px 10px' }}>
            <CustomFieldValue
              field={viewColumn?.field}
              record={
                isReferenceEntity
                  ? (card?.custom_fields?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                      ?.field_data?.[0] as unknown as IEntityWithFields)
                  : card
              }
            />
          </Typography.Paragraph>
        );
      })}
    </div>
  );
};

export default Card;
