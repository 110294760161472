import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance, Input, Typography } from 'antd';

import { CKEditor } from '@aduvi/components/CKEditor/CKEditor';

export const Configure = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  return (
    <Col span={10} className='mb-20'>
      <Typography.Text type='secondary'>{translate('widgets.widgetSettings')}</Typography.Text>
      <Form.Item name='title' label={translate('widgets.widgetName')} className='mt-20'>
        <Input className='w-full' />
      </Form.Item>
      <Form.Item name='description' label={translate('widgets.description')}>
        <CKEditor
          value={form.getFieldValue('description')}
          onChange={(value) => form.setFieldValue('description', value)}
          placeholder={translate('widgets.descriptionPlaceholder')}
        />
      </Form.Item>
    </Col>
  );
};
