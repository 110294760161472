import { RowProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import { EAfterFormSubmitType, EFontFamily, EFormFormat, EFormLayout, ERedirectTo, IFormSettings } from '@aduvi/types/form';

export enum EShoppingBasketPosition {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export const INITIAL_FORM_CONFIG: IFormSettings = {
  name: '',
  form_url: '',
  brand_id: '',
  brand: undefined,
  thank_you_page_message: '',
  embed_widget_code: '',
  embed_widget_remove_styling: false,
  embed_form_code: '',
  form_submit_automation: [],
  embed_form_remove_styling: false,
  form_data_lead: false,
  style: {
    form_style: [
      {
        background_mode: 'colour',
        background_color: '#1370c6',
        background_image: '',
        form_background: '#ffffff',
        font_family: EFontFamily.POPPINS,
        primary_font_color: '#000000',
        secondary_font_color: '#000000',
        layout: EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
        format: EFormFormat.SINGLE_PAGE,
        progress_bar: false,
        logo: '',
        shopping_basket: false,
        shopping_basket_position: EShoppingBasketPosition.BOTTOM_RIGHT,
      },
    ],
    page_breaks: [],
  },
};

export const BACKGROUND_IMAGE: Record<string, string> = {
  'assets/images/theme-1.png': require('assets/images/theme-1.png'),
  'assets/images/theme-2.png': require('assets/images/theme-2.png'),
  'assets/images/theme-3.png': require('assets/images/theme-3.png'),
  'assets/images/theme-4.png': require('assets/images/theme-4.png'),
};

export const LAYOUT_ALIGNMENT: { [key: string]: RowProps['justify'] } = {
  [EFormLayout.BACKGROUND_FULL_CONTENT_LEFT]: 'start',
  [EFormLayout.BACKGROUND_FULL_CONTENT_CENTER]: 'center',
  [EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT]: 'end',
};

export const LEAD_STATUS_OPTIONS: DefaultOptionType[] = [
  { label: 'Complete Form', value: 'complete_form' },
  { label: 'Incomplete Form', value: 'incomplete_form' },
];

export const FORM_AFTER_SUBMIT_OPTIONS = [
  { title: 'forms.forms.signContract', type: EAfterFormSubmitType.SIGN_CONTRACT, value: '' },
  { title: 'forms.forms.payment', type: EAfterFormSubmitType.PAYMENT, value: '' },
  { title: 'forms.forms.setStatus', type: EAfterFormSubmitType.SET_STATUS_TO, value: '' },
  { title: 'forms.forms.redirect', type: EAfterFormSubmitType.REDIRECT, value: '', url: '', redirect_type: ERedirectTo.EXTERNAL_URL },
];
