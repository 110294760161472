import Mention from '@tiptap/extension-mention';

export const AutomationMention = Mention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => ({
          'data-id': attributes.id,
        }),
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => ({
          'data-label': attributes.label,
        }),
      },
      triggerId: {
        default: null,
        parseHTML: (element) => element.getAttribute('trigger-id'),
        renderHTML: (attributes) => ({
          'trigger-id': attributes.triggerId,
        }),
      },
      node_id: {
        default: null,
        parseHTML: (element) => element.getAttribute('node-id'),
        renderHTML: (attributes) => ({
          'node-id': attributes.node_id,
        }),
      },
    };
  },

  renderHTML(props) {
    const { node } = props;
    return [
      'span',
      {
        'data-linked-resource-type': 'automationmentioninfo', // Updated to the new type
        'data-id': node.attrs.id,
        'data-label': node.attrs.label,
        'trigger-id': node.attrs.triggerId, // New attribute
        'node-id': node.attrs.node_id, // New attribute
        class: 'mention',
      },
      `${node.attrs.label}`,
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-linked-resource-type="automationmentioninfo"]', // Updated to match the new type
        getAttrs: (element) => ({
          id: element.getAttribute('data-id'),
          label: element.getAttribute('data-label'),
          triggerId: element.getAttribute('trigger-id'), // New attribute parsing
        }),
      },
    ];
  },
});
