import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Col, Spin } from 'antd';

import { useBusinessId } from '@aduvi/hooks';
import { EPaymentGateways } from '@aduvi/types/payments-method';
import { Notification } from '@aduvi/utils/notification';

import { getUserData } from 'store/features/auth-slice';
import { squareAuthorizationCodeCallback, stripeAuthorizationCodeCallback } from 'store/features/payment-gateway-settings-slice';
import { useAppDispatch } from 'store/hooks';

export const PaymentConnect = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusinessId = useBusinessId();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const provider = searchParams.get('state');
  const error = searchParams.get('error');

  useEffect(() => {
    if (provider === EPaymentGateways.SQUARE) {
      if (error) {
        Notification.error({ title: translate('payments.paymentDetailsDescription.squareConnectionFailed') });
        navigate('/settings/payments');
        return;
      } else {
        if (!code) return;

        dispatch(
          squareAuthorizationCodeCallback({
            business_id: selectedBusinessId,
            code,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(getUserData());
            Notification.success({ title: translate('payments.paymentDetailsDescription.squareConnectionSuccess') });
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);
            navigate('/settings/payments');
          });
      }
    } else {
      if (!code) return;

      dispatch(
        stripeAuthorizationCodeCallback({
          business_id: selectedBusinessId,
          code,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(getUserData());
          Notification.success({ title: translate('payments.paymentDetailsDescription.stripeConnectionSuccess') });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          navigate('/settings/payments');
        });
    }
  }, [selectedBusinessId, code]);

  return <Col style={{ textAlign: 'center', marginTop: '50px' }}>{isLoading && <Spin size='large' />}</Col>;
};
