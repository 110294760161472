import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

import { Button, Col, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import { useBusiness } from '@aduvi/hooks';

import { useAppSelector } from 'store/hooks';

export const Share = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  return (
    <Col span={10} className='share-tab-wrapper mb-20'>
      <Form.Item name='form_url' label={translate('forms.forms.formUrl')}>
        <Input addonBefore={window.location.host + '/forms/'} />
      </Form.Item>
      <Row justify={'end'}>
        <Button
          type='link'
          onClick={() => navigator.clipboard.writeText(`${window.location.host}/forms/${selectedBusiness?.id}/${form.getFieldValue('form_url')}`)}>
          <CopyOutlined /> {translate('forms.forms.copyFormLink')}
        </Button>
      </Row>
      <Divider />
      <Typography.Text type='secondary'>{translate('forms.forms.embedWidget')}</Typography.Text>
      <Row justify={'space-between'} className='mt-15'>
        <Typography.Text className='mt-5'>{translate('forms.forms.removeBackgroundStyling')}</Typography.Text>
        <Form.Item name='embed_wiget_remove_styling'>
          <Switch defaultValue={selectedEntityForm?.embed_widget_remove_styling} />
        </Form.Item>
      </Row>
      <Form.Item name='embed_wiget_code' label={translate('forms.forms.embedCode')}>
        <Input.TextArea rows={4} placeholder='<div id=”...”></div><script>....</script>' defaultValue={selectedEntityForm?.embed_widget_code} />
      </Form.Item>
      <Row justify={'end'}>
        <Button type='link'>
          <CopyOutlined /> {translate('forms.forms.copyWidgetCode')}
        </Button>
      </Row>
      <Divider />
      <Typography.Text type='secondary'>{translate('forms.forms.embedWholeForm')}</Typography.Text>
      <Row justify={'space-between'} className='mt-15'>
        <Typography.Text className='mt-5'>{translate('forms.forms.removeBackgroundStyling')}</Typography.Text>
        <Form.Item name='embed_form_remove_styling'>
          <Switch defaultValue={selectedEntityForm?.embed_form_remove_styling} />
        </Form.Item>
      </Row>
      <Form.Item name='embed_form_code' label={translate('forms.forms.embedCode')}>
        <Input.TextArea rows={4} placeholder='<div id=”...”></div><script>....</script>' defaultValue={selectedEntityForm?.embed_form_code} />
      </Form.Item>
      <Row justify={'end'}>
        <Button type='link'>
          <CopyOutlined /> {translate('forms.forms.copyFormCode')}
        </Button>
      </Row>
    </Col>
  );
};
