import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, DeleteFilled } from '@ant-design/icons';

import { Button, Checkbox, Col, Divider, Empty, Form, Input, InputNumber, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import './Availability.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { SelectTeam } from '@aduvi/components/SelectTeam/SelectTeam';
import { useBusinessId } from '@aduvi/hooks';
import { EAvailabilityEntity, IBusinessUser, IUser } from '@aduvi/types';
import { EAvailabilityType } from '@aduvi/types/services';

import { getBusinessUsers } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AvailabilityCalendar } from './AvailabilityCalendar';

export const Availability = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const { t: translate } = useTranslation();

  const [teamId, setTeamId] = useState<string | undefined>(form.getFieldValue('business_team_id') || undefined);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(form.getFieldValue('users') || []);
  const [availabilityType, setAvailabilityType] = useState<EAvailabilityType>(form.getFieldValue('availability_type') ?? EAvailabilityType.UNITS);
  const [selectedEntityType, setSelectedEntityType] = useState<EAvailabilityEntity | undefined>(undefined);
  const [selectedEntityId, setSelectedEntityId] = useState<string | undefined>(undefined);

  const { users, loading } = useAppSelector((state) => state.user);
  const { selectedService } = useAppSelector((state) => state.services);

  // TODO check this function VK
  const onGetBusinessTeamUsers = useCallback(
    (page = 1, size = 10) => {
      dispatch(
        getBusinessUsers({
          business_id: selectedBusinessId,
          params: {
            page,
            size,
            team_ids: teamId,
          },
        }),
      )
        .unwrap()
        .then(({ data }) => {
          const users = selectedService?.business_team_id === teamId ? selectedService?.users : data;
          setSelectedUsers(users ?? []);
          form.setFieldValue('users', users);
        });
    },
    [teamId, selectedBusinessId],
  );

  const onUserChange = (enabled: boolean, user: IBusinessUser) => {
    let editedUsers = selectedUsers;
    if (enabled) {
      editedUsers = [...editedUsers, user];
    } else {
      editedUsers = editedUsers.filter((item) => item.id !== user.id);
    }
    setSelectedUsers(editedUsers);
    form.setFieldValue('users', editedUsers);
  };

  useEffect(() => {
    if (teamId) onGetBusinessTeamUsers();
  }, [teamId, selectedBusinessId]);

  return (
    <>
      <Col span={24} className='services-availability-wrapper mt-15'>
        <Form.Item
          name='availability_type'
          label={translate('assets.serviceAvailability.availabilityType')}
          rules={[{ required: true, message: translate('assets.serviceAvailability.availabilityValidation') }]}
          initialValue={availabilityType}>
          <Select onSelect={(value) => setAvailabilityType(value)} defaultValue={availabilityType}>
            <Select.Option value={EAvailabilityType.UNITS}>{translate('assets.serviceAvailability.units')}</Select.Option>
            <Select.Option value={EAvailabilityType.USERS}>{translate('assets.serviceAvailability.users')}</Select.Option>
            <Select.Option value={EAvailabilityType.UNITS_USERS}>{translate('assets.serviceAvailability.units_users')}</Select.Option>
          </Select>
        </Form.Item>

        {(availabilityType === EAvailabilityType.UNITS || availabilityType === EAvailabilityType.UNITS_USERS) && (
          <Row className='w-full mt-20'>
            <Row justify={'space-between'} className='w-full'>
              <Paragraph>{translate('assets.serviceAvailability.units')}</Paragraph>
              <Paragraph style={{ marginRight: '13%' }}>{translate('assets.serviceAvailability.enabled')}</Paragraph>
            </Row>
            <Form.List name={'units'}>
              {(subFields, subOpt) => (
                <>
                  {subFields.map((subField) => (
                    <Row key={subField.key} className='units-users-list-item w-full' align={'middle'} justify={'space-between'}>
                      <Form.Item
                        name={[subField.name, 'name']}
                        style={{ width: '80%' }}
                        rules={[{ required: true, message: translate('assets.serviceAvailability.name_fill') }]}>
                        <Input placeholder={translate('assets.serviceDetails.name')} style={{ borderRadius: '4px' }} />
                      </Form.Item>
                      <Form.Item
                        valuePropName='checked'
                        name={[subField.name, 'enabled']}
                        initialValue={form.getFieldValue(['units', subField.key, 'enabled']) || true}>
                        <Checkbox defaultChecked={form.getFieldValue(['units', subField.key, 'enabled']) || false} />
                      </Form.Item>
                      <Col className='icon-wrapper'>
                        <CalendarOutlined
                          style={{
                            color: !form.getFieldValue('units')[subField.key]?.id ? 'gray' : 'inherit',
                            cursor: !form.getFieldValue('units')[subField.key]?.id ? 'not-allowed' : 'pointer',
                          }}
                          disabled={!form.getFieldValue('units')[subField.key]?.id}
                          onClick={() => {
                            if (form.getFieldValue('units')[subField.key]?.id) {
                              setSelectedEntityType(EAvailabilityEntity.SERVICE_UNIT);
                              setSelectedEntityId(form.getFieldValue('units')[subField.key]?.id);
                              setOpenCalendar(true);
                            }
                          }}
                        />
                      </Col>
                      <Col className='icon-wrapper'>
                        <DeleteFilled
                          onClick={() => {
                            subOpt.remove(subField.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Col className='mt-15 w-full flex-end'>
                    <Button type='default' onClick={() => subOpt.add()}>
                      + {translate('assets.serviceAvailability.addUnit')}
                    </Button>
                  </Col>
                </>
              )}
            </Form.List>

            <Divider style={{ marginTop: '40px' }} />
          </Row>
        )}

        {(availabilityType === EAvailabilityType.USERS || availabilityType === EAvailabilityType.UNITS_USERS) && (
          <>
            <Form.Item name={'business_team_id'} label={translate('userManagement.details.teams')}>
              <SelectTeam
                className='w-full'
                placeholder={translate('assets.serviceAvailability.select_team')}
                onChange={(value) => setTeamId(value)}
              />
            </Form.Item>
            <Spin spinning={loading}>
              <Form.Item name={'users'} className='w-full mt-20' getValueFromEvent={() => selectedUsers}>
                {teamId && users?.data.length && !loading ? (
                  users.data.map((subField, index) => (
                    <>
                      {index === 0 && (
                        <Row justify={'space-between'} className='w-full'>
                          <Paragraph>{translate('assets.serviceAvailability.teamMembers')}</Paragraph>
                          <Paragraph style={{ marginRight: '10%' }}>{translate('assets.serviceAvailability.enabled')}</Paragraph>
                        </Row>
                      )}
                      <Row key={subField.id} className='units-users-list-item w-full' align={'middle'} justify={'space-between'}>
                        <Col style={{ width: '80%' }}>
                          <span className='ml-10'>
                            {subField?.first_name} {subField?.last_name}
                          </span>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={selectedUsers.some((item) => item.id === subField.id)}
                            onChange={(e) => onUserChange(e.target.checked, subField)}
                          />
                        </Col>
                        <Col className='icon-wrapper'>
                          <CalendarOutlined
                            onClick={() => {
                              setSelectedEntityType(EAvailabilityEntity.USER);
                              setSelectedEntityId(subField.id);
                              setOpenCalendar(true);
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ))
                ) : (
                  <Empty className='w-full' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Form.Item>
              <Form.Item
                className='mt-20'
                name='min_user_required'
                initialValue={1}
                getValueFromEvent={({ target }) => {
                  if (!target || !target?.value) return null;
                  return Number(target?.value);
                }}>
                <Row align={'middle'}>
                  {translate('assets.serviceAvailability.thisServiceRequiresText')}{' '}
                  <InputNumber type='number' min={1} max={10} defaultValue={1} className='input-number mr-10 ml-10 ' />{' '}
                  {translate('assets.serviceAvailability.toBeConsideredText')}
                </Row>
              </Form.Item>
            </Spin>
          </>
        )}
      </Col>
      <AvailabilityCalendar open={openCalendar} setOpen={setOpenCalendar} entityType={selectedEntityType} entityId={selectedEntityId} />
    </>
  );
};
