import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Active, closestCenter, DndContext, KeyboardSensor, Over, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ContactDetails } from 'views/contacts/contact-card/ContactDetails';
import { ContactsRows } from 'views/contacts/contacts-rows/ContactsRows';

import { Col, Input, Row, Spin } from 'antd';

import { useDebounce } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin, ICustomFieldViewStyle, IKeyValueEditedEntityCustomFields } from '@aduvi/types';
import { IEntityType, IEntityWithFields } from '@aduvi/types/entity';
import { onDragEndCustomFields } from '@aduvi/utils/custom-fields-helper';

import { deleteEntity, editEntity, setCurrentPage, setSelectedEntity } from 'store/features/entity-slice';
import { setSelectedEntityForm } from 'store/features/form-slice';
import { setHoveredPipelineFilterId, setShowPersonalizedViewDrawer } from 'store/features/personalized-views-slice';
import { useAppSelector } from 'store/hooks';

import { DroppableRow } from '../PersonalizedViewComponents/DroppableRow/DroppableRow';
import { EditableCard } from '../PersonalizedViewComponents/EditableTable/EditableCard';
import { EditableTable } from '../PersonalizedViewComponents/EditableTable/EditableTable';
import { EditRowLayoutDrawer } from '../PersonalizedViewComponents/EditRowLayoutDrawer/EditRowLayoutDrawer';
import { PersonalizedViewTabs } from '../PersonalizedViewComponents/PersonalizedViewTabs/PersonalizedViewTabs';
import { AddFormModal } from '../PersonalizedViewComponents/PredefinedViews/Forms/modal/AddFormModal';
import { usePredefinedViews } from '../PersonalizedViewComponents/PredefinedViews/usePredefinedViews';

import { EntityHeaderActions } from './components/EntityHeaderActions';
import { UpsertEntityDrawer } from './components/UpsertEntityDrawer';
import { UpsertJobDrawer } from './components/UpsertJobDrawer';
import { useEntitiesTableColumns, useEntity } from './Entity.hooks';
import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm } from './helper';

interface IProps {
  entityType?: IEntityType;
  personalizedViewOrigin: EPersonalizedViewOrigin;
  isConfirmed?: boolean;
}

export const Entity = ({ entityType, personalizedViewOrigin, isConfirmed = false }: IProps) => {
  const { t: translate } = useTranslation();
  const {
    editRowLayoutDrawer,
    selectedBusiness,
    showFormModal,
    initialColumns,
    entityColumns,
    showEntityDrawer,
    fields,
    formOnlyFields,
    formType,
    dispatch,
    setEditRowLayoutDrawer,
    setShowFormModal,
    setEntityColumns,
    setShowEntityDrawer,
  } = useEntity({ entityType, isConfirmed, origin: personalizedViewOrigin });

  const { entities } = useAppSelector((state) => state.entity);
  const { selectedViewId, selectedView, hoveredPipelineFilterId } = useAppSelector((state) => state.personalizedViews);

  const [searchKey, setSearchKey] = useState('');
  const debouncedSearchKey = useDebounce(searchKey);

  const sortableSensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const onEntityDelete = () => {
    if (!entities.selectedEntity?.id || !selectedBusiness?.id || !entityType?.id) return;

    dispatch(
      deleteEntity({
        businessId: selectedBusiness?.id,
        entityTypeId: entityType?.id,
        entityId: entities.selectedEntity?.id,
        entityTypeName: entityType.name,
      }),
    );
  };

  const removeColumn = (column: string) => {
    if (!column.includes('column_')) {
      return setEntityColumns((prev) => prev?.filter((item) => item.id !== column));
    }
  };

  const changeColumnOfVerticalField = (activeColumn: string, overColumn: string) => {
    const activeColumnParts = activeColumn?.split('_');
    const activeFieldId = activeColumnParts[2];
    const activeColumnId = activeColumnParts[1];
    const overColumnParts = overColumn?.split('_');
    const overColumnId = overColumnParts[1];
    const entityColumn = entityColumns?.find((entityColumn) => entityColumn?.id === overColumnId);
    const activefieldIndex = entityColumn?.field_id?.findIndex((fieldId) => fieldId === activeFieldId);
    const overfieldIndex = entityColumn?.field_id?.findIndex((fieldId) => fieldId === overColumnParts[2]);
    const activeTableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === activeColumnId);
    const overTableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === overColumnId);

    if (activeColumnId === overColumnId) {
      const newFieldIDS = arrayMove(entityColumn?.field_id!, activefieldIndex!, overfieldIndex!);
      if (entityColumn) {
        entityColumn.field_id = newFieldIDS.filter((id) => !id?.includes('column_'));
        const updatedColumns = [...entityColumns];
        updatedColumns[overTableColumnIndex] = entityColumn;

        return setEntityColumns(updatedColumns);
      }
    }
    if (activeTableColumnIndex >= 0) {
      const updatedColumns = [...entityColumns];
      const activeTableColumn = { ...updatedColumns?.[activeTableColumnIndex] };

      if (activeTableColumn?.field_id) {
        if (updatedColumns?.[overTableColumnIndex]?.field_id?.includes(activeFieldId)) {
          return;
        }
        activeTableColumn.field_id = activeTableColumn?.field_id?.filter((field) => field !== activeFieldId);
        updatedColumns[activeTableColumnIndex] = activeTableColumn;
      }

      if (overTableColumnIndex >= 0) {
        const overTableColumn = { ...updatedColumns[overTableColumnIndex] };

        if (overTableColumn?.field_id) {
          if (!overTableColumn?.field_id?.includes(activeFieldId)) {
            overTableColumn.field_id = [...overTableColumn?.field_id, activeFieldId].filter((field) => !field.includes('column_'));
          }

          updatedColumns[overTableColumnIndex] = overTableColumn;
        }
      }

      setEntityColumns(updatedColumns);
    }
  };

  const removeVerticalField = (column: string) => {
    const columnParts = column?.split('_');
    const fieldId = columnParts?.[2];
    const columnId = columnParts?.[1];

    const tableColumnIndex = entityColumns?.findIndex((entityColumn) => entityColumn?.id === columnId);

    if (tableColumnIndex >= 0) {
      const tableColumn = entityColumns?.[tableColumnIndex];

      if (tableColumn?.field_id) {
        const updatedFieldIds = tableColumn?.field_id?.filter((field) => field !== fieldId);
        const updatedTableColumn = { ...tableColumn, field_id: updatedFieldIds };

        const updatedColumns = [...entityColumns];
        updatedColumns[tableColumnIndex] = updatedTableColumn;

        setEntityColumns(updatedColumns);
      }
    }
  };

  const onDragEnd = (over: Over | null, active: Active) => {
    if (over?.id === 'droppableContainer') {
      if (active?.id?.toString().includes('column_')) {
        removeVerticalField(active?.id?.toString());
      }
      if (entityColumns?.length <= 2) return;

      removeColumn(active?.id?.toString());
    }

    if (!over || !over?.data?.current || over?.id === active?.id) return;

    setEntityColumns(onDragEndCustomFields(over, active, entityColumns, fields) || entityColumns);

    if (active?.id?.toString().includes('column_') && over?.id?.toString().includes('column_')) {
      changeColumnOfVerticalField(String(active?.id), String(over?.id));
    }
  };

  const onEntityPipelineReorder = (over: Over | null, active: Active) => {
    if (!selectedBusiness?.id || !active?.id || over?.id !== 'pipeline-filter' || !hoveredPipelineFilterId) return;

    const entity = entities?.data?.find((item) => item?.id === active?.id);

    if (!entity) return;

    const data = mapEntityFieldsToForm(entity, fields);

    const isAllowedMultiple = entity?.custom_fields?.find((item) => item.id === selectedView?.status_field)?.multiple;

    const reorderedCustomFields = data?.map((item) => {
      if (selectedView?.status_field && item?.[selectedView?.status_field]) {
        const pipelineStatusesIds = [...(item[String(selectedView?.status_field)] as Array<string>), hoveredPipelineFilterId];
        return { [String(selectedView?.status_field)]: isAllowedMultiple ? Array.from(new Set(pipelineStatusesIds)) : [hoveredPipelineFilterId] };
      }
      return item;
    });

    if (!selectedBusiness?.id || !entityType?.id || !entity?.id) return;

    dispatch(
      editEntity({
        id: entity?.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(reorderedCustomFields as unknown as IKeyValueEditedEntityCustomFields[], fields, entity),
      }),
    )
      .unwrap()
      .then()
      .catch(() => {});
  };

  const handleUpdateColumns = (column: ICustomFieldViewStyle) => {
    const selectedColumnIndex = entityColumns?.findIndex((item) => item.id === column.id);

    if (selectedColumnIndex !== -1) {
      const updatedColumns = [...entityColumns];
      updatedColumns[selectedColumnIndex] = column;
      setEntityColumns(updatedColumns);
    }
  };

  const onRowClick = (entity: IEntityWithFields) => {
    dispatch(setSelectedEntity(entity));
    setShowEntityDrawer(true);
  };

  const onKanbanHandleDrag = (id: string, entity: IEntityWithFields, overColumnId: string, columnsFieldId?: string) => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    const updatedEntity = mapEntityFieldsToForm(entity, fields)
      .map((item) => {
        if (item && columnsFieldId && Object.keys(item).at(0) === columnsFieldId) {
          return {
            [columnsFieldId]: [overColumnId],
          };
        }
        return item;
      })
      .filter((item) => item);

    if (!updatedEntity) return;

    dispatch(
      editEntity({
        id: entity.id,
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(updatedEntity as IKeyValueEditedEntityCustomFields[], fields, entity),
      }),
    );
  };

  const entityTableColumns = useEntitiesTableColumns({
    columns: entityColumns,
    origin: personalizedViewOrigin,
    entityType,
    fields,
    onEdit: () => setShowEntityDrawer(true),
    onDelete: onEntityDelete,
    onTableRowAction: (entity) => dispatch(setSelectedEntity(entity)),
  });

  const views = useMemo(() => {
    return usePredefinedViews({
      selectedEntity: entityType,
      columns: entityTableColumns,
      data: entities.data,
      selectedView,
      origin: personalizedViewOrigin,
      entityType,
      onRowClick,
      onKanbanHandleDrag,
      setShowEntityDrawer,
    });
  }, [selectedView, selectedView?.style, entities, entityTableColumns]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, [selectedView?.view_display]);

  return (
    <DndContext
      sensors={sortableSensors}
      collisionDetection={closestCenter}
      onDragStart={() => dispatch(setHoveredPipelineFilterId(undefined))}
      onDragEnd={({ over, active }) => {
        if (editRowLayoutDrawer) onDragEnd(over, active);

        onEntityPipelineReorder(over, active);
      }}>
      <div className={`leads-wrapper ${editRowLayoutDrawer && 'locked-scroll'}`}>
        {editRowLayoutDrawer && <div className='overlay'></div>}

        <Row className='w-full' align='middle'>
          <Col span={12} xs={24} sm={24} md={12} lg={12} className='w-full'>
            <PersonalizedViewTabs entityType={entityType} origin={personalizedViewOrigin} style={entityColumns} />
          </Col>

          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Row justify={{ sm: 'start', xs: 'start', md: 'start', lg: 'end' }}>
              <EntityHeaderActions
                personalizedViewOrigin={personalizedViewOrigin}
                key={selectedViewId}
                entityType={entityType}
                onEditRowLayout={() => setEditRowLayoutDrawer(true)}
                onConfigureView={() => dispatch(setShowPersonalizedViewDrawer(true))}
                onCreateEntity={() => {
                  dispatch(setSelectedEntity(undefined));
                  setShowEntityDrawer(true);
                }}
                onCreateForm={() => {
                  dispatch(setSelectedEntityForm(undefined));
                  setShowFormModal(true);
                }}
              />
            </Row>
          </Col>
        </Row>

        {editRowLayoutDrawer && (
          <SortableContext items={entityColumns} strategy={verticalListSortingStrategy} {...sortableKeyboardCoordinates}>
            <>
              {(selectedView?.view_display === EPersonalizedView.TABLE ||
                selectedView?.view_display === EPersonalizedView.PIPELINE ||
                selectedView?.view_display === EPersonalizedView.ADDRESS_BOOK) && (
                <DroppableRow id='droppable-row'>
                  <EditableTable
                    managementFields={fields}
                    viewColumns={entityColumns}
                    data={entities.data[0]}
                    updateColumns={handleUpdateColumns}
                    viewType={selectedView?.view_display}
                    removeColumn={removeColumn}
                  />
                </DroppableRow>
              )}
              {selectedView?.view_display === EPersonalizedView.KANBAN && (
                <DroppableRow id='droppable-row' type='CARD'>
                  <EditableCard
                    managementFields={fields}
                    viewColumns={entityColumns}
                    data={entities?.data?.[0]}
                    updateColumns={handleUpdateColumns}
                    removeColumn={removeColumn}
                  />
                </DroppableRow>
              )}
              {selectedView?.view_display === EPersonalizedView.MAP && (
                <DroppableRow id='droppable-row' type='CARD'>
                  <EditableCard
                    managementFields={fields}
                    viewColumns={entityColumns}
                    data={entities?.data?.[0]}
                    updateColumns={handleUpdateColumns}
                    removeColumn={removeColumn}
                  />
                </DroppableRow>
              )}
            </>
          </SortableContext>
        )}
        {selectedView?.view_display === EPersonalizedView.ADDRESS_BOOK ? (
          <Row gutter={24} justify={'space-between'} style={{ marginTop: '5px' }}>
            <Col lg={12} span={12} xs={24} md={18} style={{ flexGrow: 1 }}>
              <Input.Search className='w-full' placeholder={translate('contacts.searchContacts')} onChange={(e) => setSearchKey(e.target.value)} />
              <Spin spinning={entities.loading}>
                <ContactsRows
                  contactColumns={entityColumns}
                  searchKey={debouncedSearchKey}
                  onClick={(contact) => dispatch(setSelectedEntity(contact))}
                  onEdit={(contact) => {
                    dispatch(setSelectedEntity(contact));
                    setShowEntityDrawer(true);
                  }}
                  onDelete={(contact) => {
                    dispatch(setSelectedEntity(contact));
                    onEntityDelete();
                  }}
                  data={entities.data}
                />
              </Spin>
            </Col>

            <Col style={{ position: 'sticky', height: 'fit-content', top: 0 }} lg={12} span={12} xs={24} md={12}>
              {entities.selectedEntity && <ContactDetails contact={entities.selectedEntity} />}
            </Col>
          </Row>
        ) : (
          <Spin className='w-full' spinning={entities.loading || entities.creating}>
            {views[selectedView?.view_display as string]}
          </Spin>
        )}
      </div>
      {editRowLayoutDrawer && (
        <EditRowLayoutDrawer
          origin={personalizedViewOrigin}
          columns={entityColumns}
          data={entities.data}
          managementFields={fields}
          onClose={() => setEditRowLayoutDrawer(false)}
          onCancel={() => {
            setEditRowLayoutDrawer(false);
            if (!selectedView || !selectedView?.style) return setEntityColumns(initialColumns);
            setEntityColumns(JSON.parse(selectedView?.style));
          }}
        />
      )}
      {showFormModal && <AddFormModal entityType={entityType} formType={formType} onCancel={() => setShowFormModal(false)} />}
      {showEntityDrawer &&
        (personalizedViewOrigin === EPersonalizedViewOrigin.EVENTS ||
        personalizedViewOrigin === EPersonalizedViewOrigin.LEADS ||
        personalizedViewOrigin === EPersonalizedViewOrigin.BOOKINGS ? (
          <UpsertJobDrawer
            entityType={entityType}
            fields={fields}
            formOnlyFields={formOnlyFields}
            open={showEntityDrawer}
            onClose={() => setShowEntityDrawer(false)}
            origin={personalizedViewOrigin}
          />
        ) : (
          <UpsertEntityDrawer
            entityType={entityType}
            fields={fields}
            formOnlyFields={formOnlyFields}
            open={showEntityDrawer}
            onClose={() => setShowEntityDrawer(false)}
            origin={personalizedViewOrigin}
          />
        ))}
    </DndContext>
  );
};
