import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';

import { Button, Card, Col, Form, FormInstance, FormListFieldData, Input, Radio, Row, Select, Typography } from 'antd';
import './LimitVisibility.scss';

import { useActiveTheme } from '@aduvi/hooks';

import { DatePicker } from '../DatePicker/DatePicker';

interface Entity {
  label: string;
  entityType: 'date' | 'number' | 'text';
}

interface OperatorOptions {
  date: string[];
  number: string[];
  text: string[];
}

const dummy_entities: Entity[] = [
  {
    label: 'Order Date',
    entityType: 'date',
  },
  {
    label: 'Quantity',
    entityType: 'number',
  },
  {
    label: 'Customer Name',
    entityType: 'text',
  },
  {
    label: 'Total Cost',
    entityType: 'number',
  },
  {
    label: 'Product Description',
    entityType: 'text',
  },
  {
    label: 'Delivery Date',
    entityType: 'date',
  },
];

const operatorOptions: OperatorOptions = {
  date: ['Is', 'Is not', 'Is before', 'Is after', 'Is between', 'Is not between'],
  number: ['Is', 'Is not', 'Is greater than', 'Is less than'],
  text: ['Is', 'Is not', 'Starts with', 'Does not start with', 'Ends with', 'Does not end with', 'Contains', 'Does not contain'],
};

export const LimitVisibility = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const activeTheme = useActiveTheme();

  Form.useWatch([], form);

  const getEntityType = (field: FormListFieldData, subField: FormListFieldData) => {
    const selectedEntity = form.getFieldValue(['limit_visibility', field?.name, 'conditions', subField?.name, 'entity']);

    const selectedEntityType = dummy_entities.find((e) => e.label === selectedEntity);

    return selectedEntityType?.entityType;
  };

  return (
    <Col span={24} className='limit-visibility-wrapper mt-15 '>
      <Form.List name='limit_visibility'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                {index > 0 && (
                  <Row justify='center' className='my-15'>
                    <Form.Item name={[index - 1, 'condition_set_operator']} noStyle>
                      <Radio.Group className='p-5 and-or-radio'>
                        <Radio value='and'>
                          <Typography.Text className='fw-bold'>{translate('limitVisibility.and')}</Typography.Text>
                        </Radio>
                        <Radio value='or'>
                          <Typography.Text className='fw-bold'>{translate('limitVisibility.or')}</Typography.Text>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                )}
                <Card
                  key={field.key}
                  title={
                    <Row>
                      <Form.Item name={[field.name, 'match']} initialValue='any' noStyle>
                        <Radio.Group name='match' defaultValue='any' size='middle'>
                          <Radio value='any'>
                            <Typography.Text className='fw-400'>
                              {translate('limitVisibility.match')} <b>{translate('limitVisibility.any')} </b>
                              {translate('limitVisibility.ofTheBelow')}
                            </Typography.Text>
                          </Radio>
                          <Radio value='all'>
                            <Typography.Text className='fw-400'>
                              {translate('limitVisibility.match')} <b>{translate('limitVisibility.all')} </b>
                              {translate('limitVisibility.ofTheBelow')}
                            </Typography.Text>
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Row>
                  }
                  extra={<DeleteFilled className='delete-icon' onClick={() => remove(field.name)} />}>
                  <Form.List name={[field.name, 'conditions']}>
                    {(subFields, { add: addSub, remove: removeSub }) => (
                      <>
                        {subFields.map((subField, indx) => (
                          <Row key={subField.key} className='condition-row pl-20 py-10 mx-0 w-full' gutter={4} align={'middle'}>
                            <Col span={1}>
                              <Typography.Text>{translate('limitVisibility.if')}</Typography.Text>
                            </Col>
                            <Col span={5}>
                              <Form.Item noStyle name={[subField.name, 'entity']} rules={[{ required: true, message: 'Please select an entity' }]}>
                                <Select placeholder={translate('limitVisibility.placeholder.entity')} className='w-full'>
                                  {dummy_entities.map((entity) => (
                                    <Select.Option key={entity.label} value={entity.label}>
                                      {entity.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                noStyle
                                name={[subField.name, 'operator']}
                                rules={[{ required: true, message: 'Please select an operator' }]}>
                                <Select
                                  placeholder={translate('limitVisibility.placeholder.operator')}
                                  disabled={!form.getFieldValue(['limit_visibility', index, 'conditions', indx])}
                                  className='w-full'>
                                  {(operatorOptions[getEntityType(field, subField) as keyof OperatorOptions] || []).map((operator) => (
                                    <Select.Option key={operator} value={operator}>
                                      {operator}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>

                            {getEntityType(field, subField) === 'date' ? (
                              ['Is between', 'Is not between'].includes(
                                form.getFieldValue(['limit_visibility', index, 'conditions', indx, 'operator']),
                              ) ? (
                                <>
                                  <Col span={5}>
                                    <Form.Item name={[subField.name, 'start_date']} noStyle>
                                      <DatePicker
                                        className='w-full'
                                        disabled={!form.getFieldValue(['limit_visibility', index, 'conditions', indx, 'operator'])}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={5}>
                                    <Form.Item name={[subField.name, 'end_date']} noStyle>
                                      <DatePicker
                                        className='w-full'
                                        disabled={!form.getFieldValue(['limit_visibility', index, 'conditions', indx, 'operator'])}
                                      />
                                    </Form.Item>
                                  </Col>
                                </>
                              ) : (
                                <Col span={11}>
                                  <Form.Item name={[subField.name, 'date']} noStyle>
                                    <DatePicker
                                      className='w-full'
                                      disabled={!form.getFieldValue(['limit_visibility', index, 'conditions', indx, 'operator'])}
                                    />
                                  </Form.Item>
                                </Col>
                              )
                            ) : (
                              <Col span={11}>
                                <Form.Item name={[subField.name, 'value']} noStyle>
                                  <Input disabled={!form.getFieldValue(['limit_visibility', index, 'conditions', indx, 'operator'])} />
                                </Form.Item>
                              </Col>
                            )}

                            <Col span={1}>
                              <DeleteFilled className='pl-10 delete-icon' onClick={() => removeSub(subField.name)} />
                            </Col>
                          </Row>
                        ))}
                        <Button
                          type='link'
                          className='flex align-center'
                          onClick={() => addSub()}
                          block
                          icon={<PlusCircleFilled style={{ color: `${activeTheme?.menuActiveText}` }} />}>
                          {translate('limitVisibility.addNewCondition')}
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              </React.Fragment>
            ))}
            <Button
              type='link'
              className='flex align-center p-0'
              onClick={() => add()}
              block
              icon={<PlusCircleFilled style={{ color: `${activeTheme?.menuActiveText}` }} />}>
              {translate('limitVisibility.addNewConditionSet')}
            </Button>
          </>
        )}
      </Form.List>
    </Col>
  );
};
