import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IProduct, IResponse, IService } from '@aduvi/types';
import { IEntities, IEntityWithFields } from '@aduvi/types/entity';
import {
  ICloneEntityFormPayload,
  ICreateEntityByFormUrl,
  ICreateEntityFormPayload,
  IDeleteEntityFormPayload,
  IEntityForm,
  IGetFormByIdPayload,
  ISignEntityContractPayload,
  IUpsertFormCustomFields,
} from '@aduvi/types/form';

export const getFormsByEntityType = async ({
  business_id,
  entity_type_id,
  form_type,
}: {
  business_id: string;
  entity_type_id: string;
  form_type: string;
}): Promise<IResponse<IEntityForm[]>> => {
  return await axios.get(`/businesses/${business_id}/entity-types/${entity_type_id}/forms?form_type=${form_type}`);
};

export const getFormById = async ({ business_id, entity_type_id, form_id }: IGetFormByIdPayload): Promise<IResponse<IEntityForm>> => {
  return await axios.get(`/businesses/${business_id}/entity-types/${entity_type_id}/forms/${form_id}`);
};

export const createEntityForm = async (payload: ICreateEntityFormPayload, form_type: string): Promise<IResponse<IEntityForm>> => {
  return await axios.post(`/businesses/${payload.business_id}/entity-types/${payload.entity_type_id}/forms?form_type=${form_type}`, payload);
};

export const updateEntityForm = async (payload: IEntityForm): Promise<IResponse<IEntityForm>> => {
  return await axios.put(`/businesses/${payload.business_id}/entity-types/${payload.entity_type_id}/forms/${payload.id}`, payload);
};

export const updateEntityFormCustomFields = async (payload: IUpsertFormCustomFields): Promise<IResponse<IEntityForm>> => {
  return await axios.post(
    `/businesses/${payload.business_id}/entity-types/${payload.entity_type_id}/forms/${payload.form_id}/custom-fields`,
    payload,
  );
};

export const deleteEntityForm = async ({
  business_id,
  entity_type_id,
  form_id,
  form_name,
}: IDeleteEntityFormPayload): Promise<IResponse<IEntityForm>> => {
  return await axios.delete(`/businesses/${business_id}/entity-types/${entity_type_id}/forms/${form_id}?title=${form_name}`);
};

export const cloneEntityForm = async ({ business_id, entity_type_id, form_id }: ICloneEntityFormPayload): Promise<IResponse<IEntityForm>> => {
  return await axios.post(`/businesses/${business_id}/entity-types/${entity_type_id}/forms/${form_id}/clone`);
};

export const getFormByUrl = async (formUrl: string): Promise<IResponse<IEntityForm>> => {
  return await axios.get(`/forms/${formUrl}`);
};

export const createEntityFromFormUrl = async (payload: ICreateEntityByFormUrl): Promise<IResponse<IEntityWithFields>> => {
  return await axios.post(`/forms/${payload.formUrl}`, payload.body);
};

export const getPublicServices = async (businessId: string): Promise<IPaginatedResponse<IService>> => {
  return await axios.get(`/public/businesses/${businessId}/services`);
};

export const getPublicProducts = async (businessId: string): Promise<IPaginatedResponse<IProduct>> => {
  return await axios.get(`/public/businesses/${businessId}/products`);
};

export const getPublicEntities = async (businessId: string, entityTypeId: string): Promise<IResponse<IEntities>> => {
  return await axios.get(`/public/businesses/${businessId}/entity-types/${entityTypeId}/entities`);
};

export const signEntityContract = async (payload: ISignEntityContractPayload) => {
  return await axios.post(`/forms/${payload.formUrl}/${payload.entityId}/sign-contract`, payload?.body);
};
