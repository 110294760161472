import { Trans, useTranslation } from 'react-i18next';

import { useActiveTheme } from '@aduvi/hooks';
import { BusinessActivityTypes, EAuditTypes, IActivityTextProps } from '@aduvi/types/audit';

const getActivityTypes = (type: EAuditTypes) => {
  return BusinessActivityTypes[type];
};

export const BusinessActivityText = ({ event, model, fields, newValues, oldValues, user }: IActivityTextProps) => {
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const RenderActivityText = ({ event }: { event: EAuditTypes }) => {
    if (event === EAuditTypes.UPDATED) {
      return (
        <>
          <Trans
            t={translate}
            components={{
              model: <b style={{ color: activeTheme?.buttonBg }} />,
              field: <span style={{ color: activeTheme?.buttonBg }} />,
              user: <span style={{ color: activeTheme?.buttonBg }} />,
            }}
            i18nKey={getActivityTypes(event)}
            values={{ model, fields, newValues, oldValues, user }}></Trans>
        </>
      );
    } else if ([EAuditTypes.DELETED, EAuditTypes.SOFT_DELETED, EAuditTypes.CREATED].includes(event)) {
      return (
        <Trans
          t={translate}
          components={{
            model: <b style={{ color: activeTheme?.buttonBg }} />,
            user: <span style={{ color: activeTheme?.buttonBg }} />,
          }}
          i18nKey={getActivityTypes(event)}
          values={{ model, user }}></Trans>
      );
    }

    return (
      <Trans t={translate} components={{ user: <span className='activity-fields' /> }} i18nKey={getActivityTypes(event)} values={{ user }}></Trans>
    );
  };

  return <RenderActivityText event={event} />;
};
