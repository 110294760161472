import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Col, Form, Input, Space, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/es/upload';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { FileUpload } from '@aduvi/components/UploadFile/FileUpload';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { useActiveTheme, useBusinessId } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { getFolderPath } from '@aduvi/utils/files-helper';

import { createFile, updateFile } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllFolders } from 'store/selectors/get-folders';

export const UpsertFileDrawer = ({ onClose, open }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const activeTheme = useActiveTheme();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  Form.useWatch('folder_path', form);
  Form.useWatch('size', form);

  const [uploadedFile, setFile] = useState<RcFile | null>(null);

  const { selectedFile, selectedFolder, files } = useAppSelector((state) => state.file);

  const allFolders = useAppSelector(selectAllFolders);

  const closeDrawer = () => {
    form.resetFields();
    onClose();
  };

  const onUpdateFile = () => {
    const { name, description } = form.getFieldsValue();

    const payload = {
      name,
      description,
      is_public: false,
      folder_id: selectedFolder?.id as string,
    };

    dispatch(updateFile({ businessId, fileId: selectedFile?.id as string, payload }))
      .unwrap()
      .then(() => closeDrawer())
      .catch(() => {});
  };

  const onCreateFile = () => {
    const formData = new FormData();
    const { name, description, file_type, size } = form.getFieldsValue();

    if (uploadedFile) formData.append('file', uploadedFile);

    formData.append('path', form.getFieldValue('folder_path'));
    formData.append('name', name || '');
    formData.append('description', description || '');
    formData.append('file_type', file_type);
    formData.append('size', size);
    formData.append('is_public', 'false');
    formData.append('folder_id', selectedFolder?.id || '');

    dispatch(createFile({ businessId, payload: formData }))
      .unwrap()
      .then(() => closeDrawer())
      .catch(() => {});
  };

  const handleSubmitFile = () => {
    if (!businessId || !selectedFolder?.id) return;

    if (selectedFile && selectedFile.id) {
      return onUpdateFile();
    }

    onCreateFile();
  };

  useEffect(() => {
    form.setFieldsValue(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFolder) {
      const path = getFolderPath(allFolders, selectedFolder.id);
      form.setFieldsValue({ folder_path: path });
    }
  }, [selectedFolder, allFolders]);

  return (
    <Drawer
      className='event-drawer-wrapper'
      title={selectedFile ? translate('file.editFile') : translate('file.createFile')}
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={files.creating || files.updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button loading={files.creating || files.updating} onClick={() => handleSubmitFile()} type='primary'>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Form form={form} layout='vertical' requiredMark={false}>
        <Col span={24} className='p-20'>
          <Form.Item name='name' label={translate('file.name')} rules={[{ required: true, message: translate('file.nameValidation') }]}>
            <Input placeholder={translate('file.namePlaceholder')} />
          </Form.Item>
          <Form.Item name='file_type' hidden />
          <Form.Item name='size' hidden />
          <Form.Item name='folder_path' hidden />
          {selectedFile ? (
            <></>
          ) : (
            <Form.Item label={translate('file.replaceFile')} name='file' labelCol={{ span: 24 }}>
              <FileUpload
                name='file'
                folderName={form.getFieldValue('folderPath')}
                skipUploadToServer={true}
                onFileUploaded={(file) => {
                  setFile(file);
                  form.setFieldsValue({
                    file_type: file.name?.split('.').pop()?.toUpperCase(),
                    size: file?.size,
                  });
                }}
                onUploadChange={(file) => {
                  const filename = file?.url?.split('/').pop();
                  const extension = filename ? filename.split('.').pop()?.toUpperCase() : '';
                  form.setFieldsValue({
                    file: file?.url,
                    file_type: extension,
                    size: file?.size,
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item label={translate('file.description')} name='description'>
            <Tiptap
              content={form.getFieldValue('description')}
              placeholder={translate('file.descriptionPlaceholder')}
              onUpdate={(value) => form.setFieldValue('description', value.getHTML())}
            />
          </Form.Item>
          <Typography.Paragraph className='mb-5'>{translate('file.whoHasAccess')}</Typography.Paragraph>
          <Typography.Paragraph type='secondary'>{translate('file.availability', { item: form.getFieldValue('folderPath') })}</Typography.Paragraph>
          <Typography.Paragraph className='mb-5'>{translate('file.publicAccess')}</Typography.Paragraph>
          <Typography.Paragraph type='secondary'>{translate('file.no')}</Typography.Paragraph>
          <Typography.Paragraph className='mb-5'>{translate('file.size')}</Typography.Paragraph>
          <Typography.Paragraph type='secondary'>
            {form.getFieldValue('size') ? `${Number(form.getFieldValue('size')).toFixed(3)} MB` : ''}
          </Typography.Paragraph>

          <Typography.Paragraph className='mb-5'>{translate('file.created')}</Typography.Paragraph>
          <Typography.Paragraph type='secondary'>
            {selectedFile?.created_at ? dayjs(selectedFile?.created_at || '').format('MMM Do YYYY') : ''}
          </Typography.Paragraph>
          <Typography.Paragraph className='mb-5'>{translate('file.location')}</Typography.Paragraph>
          <Typography.Paragraph style={{ color: activeTheme?.menuActiveText }}>
            {<FolderOutlined />} {form.getFieldValue('folder_path')}
          </Typography.Paragraph>
        </Col>
      </Form>
    </Drawer>
  );
};
