// src/columns/productColumns.ts
import { useTranslation } from 'react-i18next';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { useBusiness } from '@aduvi/hooks';
import { ITaxRate } from '@aduvi/types';

export const useProductColumns = () => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  return [
    {
      title: translate('clientPortal.invoice.product').toUpperCase(),
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: translate('clientPortal.invoice.qty').toUpperCase(),
      dataIndex: 'qty',
      key: 'qty',
      width: 50,
    },
    {
      title: translate('clientPortal.invoice.price').toUpperCase(),
      dataIndex: 'price',
      key: 'price',
      render: (data: string) => <PriceDisplay price={Number(data)} currencySign={selectedBusiness?.currency?.format} />,
    },
    {
      title: translate('clientPortal.invoice.taxRate').toUpperCase(),
      dataIndex: 'tax',
      key: 'tax',
      width: 250,
      render: (data: ITaxRate[]) => data?.map((tax) => ` ${Number(tax.tax_percentage).toFixed(2)}%`),
    },
    {
      title: translate('clientPortal.invoice.total').toUpperCase(),
      dataIndex: 'total',
      key: 'total',
      render: (data: string) => <PriceDisplay price={Number(data)} currencySign={selectedBusiness?.currency?.format} />,
    },
  ];
};
