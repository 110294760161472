import axios from '@aduvi/config/axios';
import { ICreateEntityFieldPayload, IDeleteEntityFieldPayload, IEntityField, IReorderEntityFieldsPayload, IResponse } from '@aduvi/types';

export const getEntityFields = async (businessId: string, entityTypeId: string): Promise<IResponse<IEntityField[]>> => {
  return await axios.get(`/businesses/${businessId}/entity-types/${entityTypeId}/fields`);
};

export const createEntityField = async (payload: ICreateEntityFieldPayload): Promise<IResponse<IEntityField>> => {
  return await axios.post(`/businesses/${payload.businessId}/entity-types/${payload.entityTypeId}/fields`, payload.body);
};

export const updateEntityField = async (payload: ICreateEntityFieldPayload, fieldId: string): Promise<IResponse<IEntityField>> => {
  return await axios.put(`/businesses/${payload.businessId}/entity-types/${payload.entityTypeId}/fields/${fieldId}`, payload.body);
};

export const reorderEntityFields = async (payload: IReorderEntityFieldsPayload): Promise<IResponse<IEntityField[]>> => {
  return await axios.put(`/businesses/${payload.businessId}/entity-types/${payload.entityTypeId}/fields/re-order`, { fields: payload.fields });
};

export const deleteEntityFields = async (payload: IDeleteEntityFieldPayload): Promise<IResponse<IEntityField[]>> => {
  return await axios.delete(`/businesses/${payload.businessId}/entity-types/${payload.entityTypeId}/fields/${payload.fieldId}`);
};

export const getFormOnlyEntityFields = async (businessId: string, entityTypeId: string): Promise<IResponse<IEntityField[]>> => {
  return await axios.get(`/businesses/${businessId}/entity-types/${entityTypeId}/fields/form-only/fields`);
};
