export const LayoutIconTwo = ({ active = false }: { active?: boolean }) => {
  return (
    <svg width='60' height='62' viewBox='0 0 60 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_182295_24914)'>
        <rect width='60' height='60' rx='2' fill='#D9D9D9' fillOpacity='0.17' />
        <rect x='0.5' y='0.5' width='59' height='59' rx='1.5' stroke={active ? '#1890FF' : '#D9D9D9'} />
        <rect x='13' y='15.5' width='34' height='44' rx='1.5' fill='white' stroke={active ? '#1890FF' : '#D9D9D9'} />
        <rect x='17.5' y='22' width='23' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.25' />
        <rect x='17.5' y='30' width='20.2619' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.25' />
        <rect x='17.5' y='30' width='20.2619' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.35' />
        <rect x='17.5' y='38' width='23' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.3' />
        <rect x='17.5' y='46' width='18.0714' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.25' />
        <rect x='17.5' y='46' width='18.0714' height='5' rx='2' fill='#D9D9D9' fillOpacity='0.25' />
      </g>
      <defs>
        <filter id='filter0_d_182295_24914' x='0' y='0' width='60' height='62' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.016 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_182295_24914' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_182295_24914' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
