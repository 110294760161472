import { Select, SelectProps } from 'antd';

import { useAppSelector } from 'store/hooks';

export const SelectCountry = (props: SelectProps) => {
  const { countries, loading } = useAppSelector((state) => state.common.countries);

  return (
    <Select
      {...props}
      showSearch
      disabled={loading}
      loading={loading}
      filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
      {countries?.map((country, index) => (
        <Select.Option key={index} value={country.id}>
          {country.name}
        </Select.Option>
      ))}
    </Select>
  );
};
