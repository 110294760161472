import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { Avatar, Card, Carousel, Col, Row, Spin, Typography } from 'antd';
import './UserEmbed.scss';

import { IBusinessUser } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getBusinessTeams } from 'store/features/business-slice';
import { getBusinessUsers } from 'store/features/user-slice';
import { getWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWidgetStyles } from '../../hooks/useWidgetStyles';

interface TeamsMap {
  [key: string]: IBusinessUser[];
}

export const UserEmbed = () => {
  const dispatch = useAppDispatch();
  const { widgetId } = useParams();

  const { users, loading } = useAppSelector((state) => state.user);
  const { selectedWidget } = useAppSelector((state) => state.widget);

  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const selectedTeamsOrUsers = useMemo(() => {
    return selectedWidget?.widget_style?.[0]?.selected_teams_or_users || [];
  }, [selectedWidget]);

  const displayMode = useMemo(() => {
    return selectedWidget?.widget_style[0]?.display_mode || 'show_all';
  }, [selectedWidget]);

  const { visibleFields, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useWidgetStyles(selectedWidget);

  const displayedUsers = useMemo(() => {
    let filteredUsers = users?.data || [];

    if (displayMode === 'show_specific' && selectedTeamsOrUsers.length > 0) {
      const teamIds = selectedTeamsOrUsers.filter((id: string) => id.startsWith('team_')).map((id: string) => id.replace('team_', ''));

      const userIds = selectedTeamsOrUsers.filter((id: string) => id.startsWith('user_')).map((id: string) => id.replace('user_', ''));

      if (teamIds.length > 0) {
        filteredUsers = filteredUsers.filter((user) => user.teams?.some((team) => teamIds.includes(team.id.toString())));
      }

      if (userIds.length > 0) {
        filteredUsers = filteredUsers.filter((user) => userIds.includes(user.id.toString()));
      }
    }

    if (limitResults === 'show_all') {
      return filteredUsers;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return filteredUsers.slice(0, resultsPerPage || 10);
    }
    return filteredUsers;
  }, [users?.data, displayMode, selectedTeamsOrUsers, selectedWidget, resultsPerPage]);

  const renderUsers = () => {
    if (selectedWidget?.widget_style?.[0]?.group_by_team) {
      const teamsMap: TeamsMap = {};

      displayedUsers.forEach((user) => {
        user.teams?.forEach((team) => {
          if (!teamsMap[team.name]) {
            teamsMap[team.name] = [];
          }
          teamsMap[team.name].push(user);
        });
      });

      return Object.entries(teamsMap).map(([teamName, teamUsers]) => (
        <div key={teamName}>
          <Typography.Title level={4}>{teamName}</Typography.Title>
          <Row gutter={[20, 20]}>
            {teamUsers?.map((member, index) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8}
                lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                {renderUserCard(member)}
              </Col>
            ))}
          </Row>
        </div>
      ));
    } else {
      return (
        <Row gutter={[20, 20]}>
          {displayedUsers?.map((member, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
              {renderUserCard(member)}
            </Col>
          ))}
        </Row>
      );
    }
  };

  const renderUserCard = (member: IBusinessUser) => (
    <div className='team-user-card'>
      {selectedLayout === EWidgetLayout.ROWS ? (
        <Row gutter={[20, 20]} align='middle' className='users-card-row'>
          {visibleFields.includes('picture') && (
            <Col xs={24} sm={6} className='team-user-image'>
              <Avatar size={150} src={member.profile_picture} alt={member.first_name} />
            </Col>
          )}
          <Col xs={24} sm={18} className='users-details'>
            {visibleFields.includes('name') && (
              <Typography.Title
                level={4}
                style={{
                  fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                  color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                }}>
                {member.first_name} {member.last_name}
              </Typography.Title>
            )}
            {visibleFields.includes('job_title') && (
              <Typography.Text
                type='secondary'
                style={{
                  fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                  color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                }}>
                {member?.business?.position}
              </Typography.Text>
            )}
            <div className={`contact-info ${selectedLayout === EWidgetLayout.ROWS ? 'rows-layout' : 'columns-layout'}`}>
              {selectedWidget?.widget_style?.[0]?.email_phone_as_icons ? (
                <div className='icon-row'>
                  {visibleFields.includes('email') && member.email && (
                    <MailOutlined
                      style={{
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}
                    />
                  )}
                  {visibleFields.includes('phone') && member.phone_number && (
                    <PhoneOutlined
                      style={{
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}
                    />
                  )}
                </div>
              ) : (
                <>
                  {visibleFields.includes('email') && member.email && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {member.email}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('phone') && member.phone_number && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {member.phone_number}
                    </Typography.Paragraph>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {visibleFields.includes('picture') && <Avatar size={150} src={member.profile_picture} alt={member.first_name} />}
          {visibleFields.includes('name') && (
            <Typography.Title
              level={4}
              style={{
                fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                color: selectedWidget?.widget_style?.[0]?.primary_font_color,
              }}>
              {member.first_name} {member.last_name}
            </Typography.Title>
          )}
          {visibleFields.includes('job_title') && (
            <Typography.Text
              type='secondary'
              style={{
                fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                color: selectedWidget?.widget_style?.[0]?.primary_font_color,
              }}>
              {member?.business?.position}
            </Typography.Text>
          )}
          <div className={`contact-info ${(selectedLayout as EWidgetLayout) === EWidgetLayout.ROWS ? 'rows-layout' : 'columns-layout'}`}>
            {selectedWidget?.widget_style?.[0]?.email_phone_as_icons ? (
              <div className='icon-row'>
                {visibleFields.includes('email') && member.email && (
                  <MailOutlined
                    style={{
                      color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                    }}
                  />
                )}
                {visibleFields.includes('phone') && member.phone_number && (
                  <PhoneOutlined
                    style={{
                      color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                {visibleFields.includes('email') && member.email && (
                  <Typography.Paragraph
                    style={{
                      fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                      color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                    }}>
                    {member.email}
                  </Typography.Paragraph>
                )}
                {visibleFields.includes('phone') && member.phone_number && (
                  <Typography.Paragraph
                    style={{
                      fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                      color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                    }}>
                    {member.phone_number}
                  </Typography.Paragraph>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );

  const onGetBusinessUsers = useCallback((page = 1, size = 10) => {
    if (!urlBusinessId) return;
    dispatch(
      getBusinessUsers({
        business_id: urlBusinessId,
        params: {
          page,
          size,
          team_ids: '',
          search: '',
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (!urlBusinessId) return;

    dispatch(getBusinessTeams({ businessId: urlBusinessId }));
  }, [dispatch]);

  useEffect(() => {
    onGetBusinessUsers();
  }, []);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Spin spinning={loading}>
      <Row className='design-tab-wrapper'>
        <Col span={24}>
          <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
            {selectedLayout === EWidgetLayout.CARDS ? (
              <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
                {displayedUsers?.map((member, index) => (
                  <div key={index} className='team-member-card'>
                    {renderUserCard(member)}
                  </div>
                ))}
              </Carousel>
            ) : (
              renderUsers()
            )}
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};
