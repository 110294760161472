import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { SelectUser } from '@aduvi/components/SelectUser/SelectUser';
import { useBusinessId } from '@aduvi/hooks';
import { EFilePermissionTypes } from '@aduvi/types/file';

import { shareFile, shareFolder } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  onClose: () => void;
  isFile: boolean;
}

export const ShareModal = ({ onClose, isFile }: IProps) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { folders, selectedFolder, selectedFile, files } = useAppSelector((state) => state.file);

  const handleShare = useCallback(() => {
    if (!businessId) return;
    const { user_ids, permission_type } = form.getFieldsValue();

    const payload = { user_ids, permission_type };

    if (selectedFile && isFile) {
      dispatch(shareFile({ businessId, fileId: selectedFile.id, payload }))
        .unwrap()
        .then(() => onClose())
        .catch(() => {});
    } else if (selectedFolder) {
      dispatch(shareFolder({ businessId, folderId: selectedFolder.id, payload }))
        .unwrap()
        .then(() => onClose())
        .catch(() => {});
    }
  }, [businessId, form, selectedFolder, isFile]);

  return (
    <Modal
      open={true}
      title={isFile ? `${translate('file.share')} ${selectedFile?.name}` : `${translate('file.share')} ${selectedFolder?.name}`}
      onOk={handleShare}
      onCancel={onClose}
      destroyOnClose={true}
      footer={[
        <Button disabled={folders.sharing || files.sharing} key='back' onClick={onClose}>
          {translate('file.cancel')}
        </Button>,
        <Button loading={folders.sharing || files.sharing} key='submit' type='primary' onClick={handleShare}>
          {translate('file.share')}
        </Button>,
      ]}>
      <Form form={form} layout='vertical'>
        <Form.Item name='user_ids' rules={[{ required: true, message: translate('file.userRequired') }]}>
          <SelectUser mode='multiple' placeholder={translate('file.addPeople')} className='w-full' />
        </Form.Item>
        <Form.Item
          name='permission_type'
          label={translate('file.permissions')}
          rules={[{ required: true, message: translate('file.permissionTypeRequired') }]}>
          <Select showSearch filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
            <Select.Option value={EFilePermissionTypes.READ_ONLY}>{translate('file.readOnly')}</Select.Option>
            <Select.Option value={EFilePermissionTypes.READ_WRITE}>{translate('file.readWrite')}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
