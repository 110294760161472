import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

import { EFieldDataValueType, IEntityField } from '@aduvi/types';
import { EDateFormat } from '@aduvi/types/automation';
import { toHumanReadable } from '@aduvi/utils/helper';

export const FormatDate = ({ entityFields }: { entityFields?: IEntityField[] }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item name={['data_source', 'fields']} label={translate('automations.sourceData')}>
        <Select
          mode='multiple'
          placeholder={translate('automations.pleaseSelect')}
          options={entityFields
            ?.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
            .map((item) => ({ label: item.title, value: item.id }))}
        />
      </Form.Item>
      <Form.Item name={['format', 'formatter']} label={translate('automations.newFormat')}>
        <Select
          placeholder={translate('automations.pleaseSelect')}
          options={Object.values(EDateFormat).map((value) => ({ label: toHumanReadable(value), value }))}
        />
      </Form.Item>
    </>
  );
};
