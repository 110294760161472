import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const Venues = () => {
  const personalizedViewOrigin = EPersonalizedViewOrigin.VENUES;

  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes.data.VENUE} personalizedViewOrigin={personalizedViewOrigin} />;
};
