import axios from '@aduvi/config/axios';
import { IChatMessage, ICreateMessagePayload, IResponse } from '@aduvi/types';
import { IAssignUserResponse, IChannel, ICreateChannelPayload } from '@aduvi/types/conversation';

export const createChannel = async (businessId: string, payload: ICreateChannelPayload): Promise<IResponse<IChannel>> => {
  return await axios.post(`/businesses/${businessId}/conversation/channels`, payload);
};

export const getChannels = async (businessId: string): Promise<IResponse<IChannel[]>> => {
  return await axios.get(`/businesses/${businessId}/conversation/channels`);
};

export const getChannelById = async (businessId: string, channelId: string): Promise<IResponse<IChannel>> => {
  return await axios.get(`/businesses/${businessId}/conversation/channels/${channelId}`);
};

export const editChannel = async (businessId: string, channelId: string, payload: ICreateChannelPayload): Promise<IResponse<IChannel>> => {
  return await axios.put(`/businesses/${businessId}/conversation/channels/${channelId}`, payload);
};

export const deleteChannel = async (businessId: string, channelId: string, channelName: string): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/conversation/channels/${channelId}?title=${channelName}`);
};

export const assignUserToChannel = async (businessId: string, channelId: string, userId: string): Promise<IResponse<IAssignUserResponse>> => {
  return await axios.post(`/businesses/${businessId}/conversation/channels/${channelId}/users`, { user_id: userId });
};

export const getChannelMessages = async (businessId: string, channelId: string): Promise<IResponse<IChatMessage[]>> => {
  return await axios.get(`/businesses/${businessId}/conversation/channels/${channelId}/messages`);
};

export const createMessage = async (businessId: string, channelId: string, payload: ICreateMessagePayload): Promise<IResponse<IChatMessage>> => {
  return await axios.post(`/businesses/${businessId}/conversation/channels/${channelId}/messages`, payload);
};

export const createReply = async (
  businessId: string,
  channelId: string,
  messageId: string,
  payload: ICreateMessagePayload,
): Promise<IResponse<IChatMessage>> => {
  return await axios.post(`/businesses/${businessId}/conversation/channels/${channelId}/messages/${messageId}`, payload);
};

export const deleteMessage = async (businessId: string, channelId: string, messageId: string): Promise<IResponse<IChatMessage>> => {
  return await axios.delete(`/businesses/${businessId}/conversation/channels/${channelId}/messages/${messageId}`);
};
