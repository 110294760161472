import { useTranslation } from 'react-i18next';

import { Collapse, Form, Input, Select } from 'antd';

export const PushoverNotification = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item name={'user_api_key'} label={translate('automations.userApiKey')}>
        <Input placeholder={translate('automations.pasteyourApiKey')} />
      </Form.Item>
      <Form.Item name={'notification_title'} label={translate('automations.notificationTitle')}>
        <Input />
      </Form.Item>
      <Form.Item name={'notification_message'} label={translate('automations.notificationMessage')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name={'priority'} label={translate('automations.priority')}>
        <Select placeholder={translate('automations.normal')} />
      </Form.Item>
      <Collapse
        collapsible='header'
        defaultActiveKey={['1']}
        items={[
          {
            key: '1',
            label: translate('automations.moreSettings'),
            children: (
              <>
                <Form.Item name={['more_settings', 'device']} label={translate('automations.deviceOptional')}>
                  <Input placeholder={translate('automations.specifyDeviceIds')} />
                </Form.Item>
                <Form.Item name={['more_settings', 'url']} label={translate('automations.urlOptional')}>
                  <Input placeholder={translate('automations.includeUrlWithNotification')} />
                </Form.Item>
                <Form.Item name={['more_settings', 'url_text']} label={translate('automations.urlTextOptional')}>
                  <Input placeholder={translate('automations.titleForYourUrl')} />
                </Form.Item>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
