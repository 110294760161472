import React, { useState } from 'react';
import { MenuUnfoldOutlined } from '@ant-design/icons';

import { Col, Row } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { CommonSubMenu } from '@aduvi/components/CommonSubMenu/CommonSubMenu';
import { useIsMobile } from '@aduvi/hooks';
import { IFile } from '@aduvi/types/file';

import { LibraryManagement } from './library-management/LibraryManagement';
import { LibraryMenu } from './library-menu/LibraryMenu';

export const Libraries = ({ onSelectionChange }: { onSelectionChange?: (files: IFile[]) => void }) => {
  const isMobile = useIsMobile();

  const [showLibraryMenu, setShowLibraryMenu] = useState(true);

  const showLibrary = isMobile && showLibraryMenu;

  if (showLibrary) return <LibraryMenu />;

  return (
    <Row className='h-full'>
      {!isMobile && (
        <CommonSubMenu style={{ borderRight: '1px solid #e7e9f5', boxShadow: 'none' }}>
          <LibraryMenu />
        </CommonSubMenu>
      )}

      {isMobile && <Button className='mb-15' icon={<MenuUnfoldOutlined className='fs-24' />} type='text' onClick={() => setShowLibraryMenu(true)} />}

      <Col xs={24} md={17} xl={19} xxl={20}>
        <LibraryManagement onSelectionChange={onSelectionChange} />
      </Col>
    </Row>
  );
};
