import { useTranslation } from 'react-i18next';

import { Button, Divider, Row } from 'antd';
import { FormInstance } from 'antd/lib';

import { useBusiness, useUser } from '@aduvi/hooks';

import { sendSupportMessage, updateTicket } from 'store/features/ticket-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { extractFileNameFromUrl } from '../../helper';
import { SelectPriority } from '../SelectPriority';

export const TicketFooterModal = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const user = useUser();
  const { t: translate } = useTranslation();

  const { selectedTicket, sending, updating } = useAppSelector((state) => state.ticket);

  const onPriorityChange = (value: string) => {
    if (!selectedTicket?.id || !selectedBusiness?.id || !user?.id) return;

    dispatch(updateTicket({ ticket_id: selectedTicket?.id, body: { business_id: selectedBusiness?.id, user_id: user?.id, priority: value } }));
  };

  const onSendUpdate = () => {
    const message = form.getFieldValue('message');

    if (!selectedTicket?.id || !selectedBusiness?.id || !message?.length) return;

    const body: { message: string; attachments: { filename: string; url: string }[] } = {
      message,
      attachments: [],
    };

    if (form.getFieldValue('attachments')) {
      body.attachments.push({
        url: form.getFieldValue('attachments'),
        filename: extractFileNameFromUrl(form.getFieldValue('attachments')),
      });
    }

    if (message?.length) {
      dispatch(
        sendSupportMessage({
          ticket_id: selectedTicket?.id,
          business_id: selectedBusiness?.id,
          body,
        }),
      )
        .unwrap()
        .then(() => {
          form.resetFields();
        })
        .catch(() => {});
    }
  };

  return (
    <Row justify={'space-between'}>
      <Divider />
      <SelectPriority
        style={{ width: '125px' }}
        className='w-full'
        defaultValue={selectedTicket?.priority}
        loading={updating}
        disabled={updating}
        onChange={onPriorityChange}
      />
      <Button type='primary' onClick={onSendUpdate} loading={sending} disabled={sending}>
        {translate('support.contact.sendUpdate')}
      </Button>
    </Row>
  );
};
