import React, { ReactNode, useCallback, useState } from 'react';

import { Row } from 'antd';

export const HorizontalScrollView = ({ children }: { children: ReactNode }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  }, []);

  const onMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - startX) * 3;
      e.currentTarget.scrollLeft = scrollLeft - walk;
    },
    [isDragging, startX, scrollLeft],
  );
  return (
    <Row
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        marginTop: 20,
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}>
      {children}
    </Row>
  );
};
