import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined, EllipsisOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { Card as AntCard, Dropdown, Popconfirm, Row, Typography } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useUserPermissionCheck } from '@aduvi/hooks';

interface ICardProps {
  id: string;
  title: string;
  description?: string;
  action?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundMode?: 'colour' | 'image';
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onClick?: () => void;
}

export const Card = ({
  id,
  action,
  description,
  title,
  backgroundColor,
  backgroundMode,
  backgroundImage,

  onDelete,
  onDuplicate,
  onEdit,
  onClick,
}: ICardProps) => {
  const { t: translate } = useTranslation();

  const background = {
    background: backgroundMode === 'colour' ? backgroundColor : backgroundImage ? `url(${backgroundImage})  center/cover no-repeat` : backgroundColor,
  };

  return (
    <AntCard
      onClick={onClick}
      hoverable
      key={id}
      cover={
        <Row justify='center' align='bottom' className='w-full flex' style={{ height: 180, ...background }}>
          <img alt='image' width={'80%'} height={'80%'} src={require('assets/images/contract-cover.png')} />
        </Row>
      }>
      <AntCard.Meta
        title={title}
        description={
          <>
            <Typography.Text>{description || ''}</Typography.Text>
            <Row justify='space-between' align='middle' className='mt-15' onClick={(e) => e.stopPropagation()}>
              <Typography.Paragraph type='secondary'>{action}</Typography.Paragraph>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      icon: <SettingOutlined />,
                      label: translate('settings.contracts.edit'),
                      onClick: onEdit,
                    },
                    {
                      key: '2',
                      icon: <CopyOutlined />,
                      label: translate('settings.contracts.duplicate'),
                      onClick: onDuplicate,
                    },
                    {
                      key: '3',
                      icon: <DeleteOutlined />,
                      label: (
                        <Popconfirm
                          title={translate('buttons.delete')}
                          description={translate('common.deleteConfirmation')}
                          icon={<QuestionCircleOutlined />}
                          disabled={!useUserPermissionCheck(PERMISSIONS.CONTRACTS.DELETE)}
                          onCancel={(e) => e?.stopPropagation()}
                          onConfirm={(e) => {
                            e?.stopPropagation();
                            onDelete();
                          }}>
                          {translate('leads.delete')}
                        </Popconfirm>
                      ),
                      onClick: (e) => e?.domEvent?.stopPropagation(),
                      disabled: !useUserPermissionCheck(PERMISSIONS.CONTRACTS.DELETE),
                    },
                  ],
                }}
                trigger={['click']}
                overlayStyle={{ borderRadius: '8px' }}>
                <EllipsisOutlined className='fs-20' />
              </Dropdown>
            </Row>
          </>
        }
      />
    </AntCard>
  );
};
