import { useMemo } from 'react';

import { EWidgetCards, EWidgetLayout, IWidget } from '@aduvi/types/widget';

export const useWidgetStyles = (selectedWidget?: IWidget) => {
  return useMemo(() => {
    const visibleFields = selectedWidget?.widget_style[0]?.visible_fields || [];
    const selectedLayout = selectedWidget?.widget_style[0]?.layout || EWidgetLayout.COLUMNS;
    const columnCount = selectedWidget?.widget_style[0]?.columns || 3;
    const limitResults = selectedWidget?.widget_style[0]?.limit_results || 'show_all';
    const resultsPerPage = selectedWidget?.widget_style[0]?.results_per_page || 2;
    const cardNavigationType = selectedWidget?.widget_style[0]?.cards || EWidgetCards.MANUAL;

    const columnSpan = (() => {
      switch (columnCount) {
        case 2:
          return 12;
        case 3:
          return 8;
        case 4:
          return 6;
        case 5:
          return [5, 4];
        default:
          return 8;
      }
    })();

    return {
      visibleFields,
      selectedLayout,
      columnCount,
      limitResults,
      resultsPerPage,
      cardNavigationType,
      columnSpan,
    };
  }, [selectedWidget]);
};
