import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EWidgetApps, EWidgetSubApps, ICreateWidget, IWidget } from '@aduvi/types/widget';

import { createWidget, deleteWidget, getWidgets, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { WidgetCard } from '../@components/WidgetCard/WidgetCard';
import { widgetStyle } from '../constants/constants';

const staticTemplates: IWidget[] = [
  {
    id: '1',
    title: 'Events',
    description: 'Display key information about the events you offer',
    apps: EWidgetApps.EVENT_TICKETING,
    is_template: true,
    widget_style: [
      {
        ...widgetStyle,
        visible_fields: ['Picture', 'Title', 'Date', 'Price', 'Description', 'Button'],
      },
    ],
  },
  {
    id: '2',
    title: 'Exhibitors',
    description: 'Display key information about the exhibitors you offer',
    apps: EWidgetApps.EVENT_TICKETING,
    sub_app: EWidgetSubApps.EXHIBITORS,
    is_template: true,
    widget_style: [
      {
        ...widgetStyle,
        visible_fields: ['Logo', 'Name', 'Website', 'Description'],
      },
    ],
  },
  {
    id: '3',
    title: 'Agendas',
    description: 'Display key information about the agendas you offer',
    apps: EWidgetApps.EVENT_TICKETING,
    sub_app: EWidgetSubApps.AGENDA,
    is_template: true,
    widget_style: [
      {
        ...widgetStyle,
        visible_fields: ['Image', 'Title', 'Name', 'Date & Time', 'Description'],
      },
    ],
  },
];

export const EventTicketing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { widgets, loading, updating, creating } = useAppSelector((state) => state.widget);

  const handleDuplicate = (widget: IWidget) => {
    if (!selectedBusiness?.id) return;

    const duplicatedWidget: ICreateWidget = {
      business_id: selectedBusiness?.id,
      title: `${widget?.title} (Copy)`,
      apps: widget.apps,
      sub_app: widget?.sub_app,
      description: widget?.description,
      widget_style: widget?.widget_style?.map((block) => ({ ...block })),
    };

    dispatch(createWidget(duplicatedWidget));
  };

  const allWidgets = [...staticTemplates, ...widgets];

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getWidgets({ businessId: selectedBusiness.id, app: EWidgetApps.EVENT_TICKETING }));
  }, [selectedBusiness?.id, dispatch]);

  return (
    <Spin spinning={loading || updating || creating}>
      <Row gutter={[24, 24]} className='w-full'>
        {allWidgets?.map((widget, index) => (
          <Col className='mb-15' key={index} span={12} xs={24} sm={12} lg={8}>
            <WidgetCard
              id={widget?.id}
              title={widget?.title}
              description={widget?.description}
              action={widget.is_template ? 'Default' : 'Custom'}
              backgroundColor={widget?.widget_style?.[0]?.background_color}
              backgroundImage={widget?.widget_style?.[0]?.background_image}
              onConfigure={() => {
                if (widget.apps !== EWidgetApps.EVENT_TICKETING) return;

                if (widget.sub_app === EWidgetSubApps.EXHIBITORS) {
                  if (!widget.is_template) {
                    dispatch(setSelectedWidget(widget));
                    navigate(`/widgets/exhibitors-configure/${widget.id}`);
                    return;
                  }
                  navigate('/widgets/exhibitors-configure');
                  return;
                }

                if (widget.sub_app === EWidgetSubApps.AGENDA) {
                  if (!widget.is_template) {
                    dispatch(setSelectedWidget(widget));
                    navigate(`/widgets/agenda-configure/${widget.id}`);
                    return;
                  }
                  navigate('/widgets/agenda-configure');
                  return;
                }

                if (!widget.is_template) {
                  dispatch(setSelectedWidget(widget));
                  navigate(`/widgets/events-configure/${widget.id}`);
                  return;
                }

                navigate('/widgets/events-configure');
              }}
              onDuplicate={() => handleDuplicate(widget)}
              onDelete={() => {
                if (!selectedBusiness?.id || widget.is_template) return;
                dispatch(deleteWidget({ business_id: selectedBusiness.id, widget_id: widget?.id }));
              }}
              onClick={() => {
                if (widget.apps !== EWidgetApps.EVENT_TICKETING) return;

                if (widget.sub_app === EWidgetSubApps.EXHIBITORS) {
                  if (!widget.is_template) {
                    dispatch(setSelectedWidget(widget));
                    navigate(`/widgets/exhibitors-configure/${widget.id}`);
                    return;
                  }
                  navigate('/widgets/exhibitors-configure');
                  return;
                }

                if (widget.sub_app === EWidgetSubApps.AGENDA) {
                  if (!widget.is_template) {
                    dispatch(setSelectedWidget(widget));
                    navigate(`/widgets/agenda-configure/${widget.id}`);
                    return;
                  }
                  navigate('/widgets/agenda-configure');
                  return;
                }

                if (!widget.is_template) {
                  dispatch(setSelectedWidget(widget));
                  navigate(`/widgets/events-configure/${widget.id}`);
                  return;
                }

                navigate('/widgets/events-configure');
              }}
            />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
