import React from 'react';

import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { useIsBusinessOwner, useUserBusinessPermissions } from '@aduvi/hooks';
import { hasUserPermission } from '@aduvi/utils/helper';

interface IProps extends ButtonProps {
  permissions?: string[];
  requiredPermission?: string | string[];
  disabledButton?: boolean;
}

export const Button = ({ requiredPermission, disabledButton, children, ...rest }: IProps) => {
  if (!requiredPermission) return <AntdButton {...rest}>{children}</AntdButton>;

  const userBusinessPermissions = useUserBusinessPermissions();
  const isBusinessOwner = useIsBusinessOwner();

  if (requiredPermission) {
    const hasPermission = hasUserPermission(isBusinessOwner, userBusinessPermissions, requiredPermission);

    if (!hasPermission) {
      return disabledButton ? (
        <AntdButton {...rest} disabled={true}>
          {children}
        </AntdButton>
      ) : null;
    }
  }

  return <AntdButton {...rest}>{children}</AntdButton>;
};
