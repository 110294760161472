import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Space, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { EServiceDrawerTabs } from '@aduvi/types/services';
import { getFormErrors } from '@aduvi/utils/helper';

import { createService, setSelectedService, updateService } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Availability } from './availability/Availability';
import { Details } from './details/Details';

export const ServicesDrawer = ({ onClose, open }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EServiceDrawerTabs.DETAILS);

  const { updating, creating, selectedService, loadingService } = useAppSelector((state) => state.services);

  const updateSingleService = () => {
    if (!selectedBusiness?.id || !selectedService?.id) return;

    dispatch(
      updateService({
        business_id: selectedBusiness?.id,
        service_id: selectedService.id,
        body: form.getFieldsValue(),
      }),
    )
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch(() => {});
  };

  const createSingleService = () => {
    if (!selectedBusiness?.id) return;

    dispatch(createService({ business_id: selectedBusiness?.id, body: form.getFieldsValue() }))
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const submitFormHandler = () => {
    selectedService?.id ? updateSingleService() : createSingleService();
  };

  const closeDrawer = () => {
    form.resetFields();
    dispatch(setSelectedService(undefined));
    onClose();
  };

  useEffect(() => {
    if (!selectedService) return;

    form.setFieldsValue(selectedService);
  }, [selectedService]);

  return (
    <Drawer
      title={selectedService?.name || translate('assets.serviceDrawer.createService')}
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={creating || updating}
            loading={creating || updating}
            requiredPermission={PERMISSIONS.SERVICES.EDIT}
            disabledButton
            type='primary'
            onClick={submitFormHandler}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingService}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Tabs
            defaultActiveKey={EServiceDrawerTabs.DETAILS}
            activeKey={tab}
            onChange={setTab}
            items={[
              {
                key: EServiceDrawerTabs.DETAILS,
                label: translate('assets.serviceDrawer.details'),
                children: <Details form={form} />,
              },
              {
                key: EServiceDrawerTabs.AVAILABILITY,
                label: translate('assets.serviceDrawer.availability'),
                children: <Availability form={form} />,
              },
            ]}
          />
        </Form>
      </Spin>
    </Drawer>
  );
};
