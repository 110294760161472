import React, { useMemo } from 'react';

import { Col, Form, FormInstance } from 'antd';

import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

interface IProps {
  form: FormInstance;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
}

export const Details = ({ form, fields, formOnlyFields }: IProps) => {
  const { entities } = useAppSelector((state) => state.entity);

  const entityFormFields = useMemo(() => {
    if (!entities?.selectedEntity?.custom_fields) return [];

    return entities?.selectedEntity?.custom_fields?.map((item) => (item?.form_only ? item.id : null))?.filter((item) => item);
  }, [entities?.selectedEntity]);

  const entityFormManagementFields = useMemo(() => {
    if (!formOnlyFields) return [];

    return formOnlyFields?.filter((field) => entityFormFields?.includes(field?.id));
  }, [entityFormFields, formOnlyFields]);

  const filteredFields = [...fields, ...entityFormManagementFields].filter((item) => item.reference_entity_type !== EReferenceEntityType.SHIFT);

  return (
    <Col span={24} className='mt-15'>
      <Form.Item name='custom_fields'>
        {filteredFields.map((item, index) => (
          <CustomField field={item} form={form} key={item.id} index={index} />
        ))}
      </Form.Item>
    </Col>
  );
};
