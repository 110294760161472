import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Select, Typography } from 'antd';

import { EFormProductDisplayType } from '@aduvi/types/form';

import { useAppSelector } from 'store/hooks';

import { IBookingFormFieldProps } from '../helper';

interface IProps {
  selectedField: IBookingFormFieldProps | undefined;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const ProductManagementFieldsSettings = ({ selectedField, setFields }: IProps) => {
  const { t: translate } = useTranslation();

  const {
    productCategories: { data, loading },
  } = useAppSelector((state) => state.products);

  return (
    <Row className='flex-direction-column' style={{ minWidth: '120px' }}>
      <Typography.Text type='secondary' className='mb-5'>
        {translate('forms.forms.category')}
      </Typography.Text>
      <Select
        size='small'
        placeholder='Electronics'
        loading={loading}
        disabled={loading}
        options={data.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        value={selectedField?.field_instance?.display_settings?.product_category_id}
        onChange={(value) =>
          setFields((prev) =>
            prev.map((item) => {
              if (selectedField?.field_id === item.field_id) {
                return {
                  ...item,
                  field_instance: {
                    ...item.field_instance,
                    display_settings: {
                      ...item.field_instance.display_settings,
                      product_category_id: value,
                    },
                  },
                };
              }
              return item;
            }),
          )
        }
      />
      <Typography.Text type='secondary' className='mt-15 mb-5'>
        {translate('forms.forms.displayType')}
      </Typography.Text>
      <Select
        size='small'
        placeholder='Grid'
        options={Object.values(EFormProductDisplayType).map((item) => ({
          label: translate(`forms.forms.productDisplayType.${item}`),
          value: item,
        }))}
        value={selectedField?.field_instance?.display_settings?.product_display_type}
        onChange={(value) =>
          setFields((prev) =>
            prev.map((item) => {
              if (selectedField?.field_id === item.field_id) {
                return {
                  ...item,
                  field_instance: {
                    ...item.field_instance,
                    display_settings: {
                      ...item.field_instance.display_settings,
                      product_display_type: value,
                    },
                  },
                };
              }
              return item;
            }),
          )
        }
      />
    </Row>
  );
};
