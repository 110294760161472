export function getLastIsSectionDiv(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const isSectionDivs = doc.querySelectorAll('div.is-section');

  if (isSectionDivs.length > 1) {
    isSectionDivs.forEach((div) => {
      if (div.classList.contains('initial-div-class')) {
        div.remove();
      }
    });
  }

  isSectionDivs.forEach((div) => {
    div.classList.add('initial-div-class');
  });

  return doc.body.innerHTML;
}
