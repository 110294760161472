import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IResponse } from '@aduvi/types';
import { IEntities, IEntityTypeSettings, IEntityWithFields, IUpdateEntityTypeSettings } from '@aduvi/types/entity';

import { apiSlice } from '../api/apiSlice';

export const entityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicEntities: builder.query<IResponse<IEntities>, { businessId?: string; entityTypeId?: string }>({
      query: ({ businessId, entityTypeId }) => ({
        url: `public/businesses/${businessId}/entity-types/${entityTypeId}/entities`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.PUBLIC_ENTITY }],
    }),
    getEntities: builder.query<IResponse<IEntities>, { businessId?: string; entityTypeId?: string }>({
      query: ({ businessId, entityTypeId }) => ({
        url: `/businesses/${businessId}/entity-types/${entityTypeId}/entities`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.ENTITY }],
    }),
    getEntityTypeSettings: builder.query<IResponse<IEntityTypeSettings>, { businessId?: string; entityTypeId?: string }>({
      query: ({ businessId, entityTypeId }) => ({
        url: `/businesses/${businessId}/entity-types/${entityTypeId}/settings`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
    }),
    updateEntityTypeSettings: builder.mutation({
      query: (payload: IUpdateEntityTypeSettings) => ({
        url: `/businesses/${payload?.business_id}/entity-types/${payload.entity_type_id}/settings`,
        method: 'PUT',
        data: payload,
      }),
    }),
    getContactEntities: builder.query<
      IResponse<IEntityWithFields[]>,
      { businessId?: string; entityTypeId?: string; entityId?: string; referencedEntityTypeId?: string; status: 'CONFIRMED' | 'UNCONFIRMED' }
    >({
      query: ({ businessId, entityTypeId, entityId, referencedEntityTypeId, status }) => ({
        url: `businesses/${businessId}/entity-types/${entityTypeId}/entity/${entityId}/contact-entities/${referencedEntityTypeId}?status=${status}`,
        method: 'GET',
      }),

      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.NEVER,
    }),
  }),
});

export const {
  useGetEntityTypeSettingsQuery,
  useUpdateEntityTypeSettingsMutation,
  useGetPublicEntitiesQuery,
  useGetEntitiesQuery,
  useGetContactEntitiesQuery,
} = entityApiSlice;
