import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Edge, Node } from 'reactflow';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Col, Drawer, Form, Input, Space, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { IEntityField } from '@aduvi/types';
import { EAutomationNodeAction, ENodeType, IAutomationMention } from '@aduvi/types/automation';
import { checkPositionRelativeToMiddle, htmlToText, transformMentions } from '@aduvi/utils/helper';

import { setAutomationMentions } from 'store/features/automation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { convertRuleSetsToEngineRules } from '../json-rules-engine-helper';

import { useNodeSettingsByActionType } from './helper';

interface IProps {
  open: boolean;
  selectedNode?: Node;
  edges: Edge[];
  nodes: Node[];
  entityFields?: IEntityField[];
  isFetchingFields: boolean;
  onClose: () => void;
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
}

export const NodeSettingsDrawer = ({ open, selectedNode, edges, nodes, isFetchingFields, entityFields, onClose, setNodes, setEdges }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { mentions: automationMentions } = useAppSelector((state) => state.automation);

  const nodeSettingsByActionType = useNodeSettingsByActionType(form, automationMentions, entityFields);

  const onDrawerClose = () => {
    const payload = {
      ...form.getFieldsValue(),
      prompt_message: form.getFieldValue('prompt_message') ? transformMentions(form.getFieldValue('prompt_message')) : undefined,
      message: form.getFieldValue('message') ? htmlToText(transformMentions(form.getFieldValue('message'))) : undefined,
    };

    const triggerId = nodes.find((item) => item.data.nodeType === ENodeType.TRIGGER)?.id;

    let mentions: IAutomationMention[] = [];
    if (form.getFieldValue(['data_source', 'fields'])?.length > 0) {
      mentions = form.getFieldValue(['data_source', 'fields'])?.map((item: string) => {
        const field = entityFields?.find((singleField) => singleField?.id === item);
        return {
          id: item,
          node_id: selectedNode?.id,
          field_type: field?.value_type,
          name: field?.title,
          triggerId,
        };
      });
    }

    if (selectedNode?.data?.action === EAutomationNodeAction.CHAT_GPT_ACTION) {
      mentions = [
        {
          id: 'chat_gpt',
          node_id: selectedNode?.id,
          name: selectedNode?.data?.label,
        },
      ];
    }

    if (selectedNode?.data?.action === EAutomationNodeAction.WEBHOOK) {
      mentions = form.getFieldValue(['keys'])?.map((item: string) => {
        return {
          id: item,
          node_id: selectedNode?.id,
          name: item,
        };
      });
    }

    if (selectedNode?.data?.action === EAutomationNodeAction.IF_ELSE) {
      const existingBranches = edges.filter((item) => item.source === selectedNode?.id).map((item) => item.target);

      const reorderedExistingBranches = form.getFieldValue('branches')[0].id === existingBranches[0] ? existingBranches : existingBranches.reverse();

      const branchesLength = [...form.getFieldValue('branches'), { id: reorderedExistingBranches.slice(-1)[0] }].length;

      const branches = [...form.getFieldValue('branches'), { id: reorderedExistingBranches.slice(-1)[0] }]?.map(
        (item: { id: string }, index: number) => {
          return {
            id: item.id,
            type: 'branch',
            position: {
              x: selectedNode?.position?.x + checkPositionRelativeToMiddle(index + 1, form.getFieldValue('branches').length + 1),
              y: selectedNode?.position?.y + 200,
            },
            data: {
              label: branchesLength === 2 ? (index === 0 ? 'TRUE' : 'FALSE') : index === 0 ? 'IF' : index === branchesLength - 1 ? 'ELSE' : 'ELSE IF',
              nodeType: ENodeType.BRANCH,
              triggerable_type: undefined,
              triggerable_id: undefined,
              action:
                index === 0
                  ? EAutomationNodeAction.IF_BRANCH
                  : index === branchesLength - 1
                  ? EAutomationNodeAction.ELSE_BRANCH
                  : EAutomationNodeAction.ELSE_IF_BRANCH,
              payload: undefined,
            },
          };
        },
      );

      const branchesIds = branches.map((item) => item.id);

      setNodes(
        [...nodes.filter((item) => !branchesIds.includes(item.id)).filter((item) => !existingBranches.includes(item.id)), ...branches].map((item) => {
          if (selectedNode?.id === item.id) {
            return {
              ...item,
              data: { ...item.data, payload: form.getFieldsValue(), rules: convertRuleSetsToEngineRules(form.getFieldValue('branches')) },
            };
          }
          return item;
        }),
      );

      setEdges([
        ...edges.filter((item) => item.source !== selectedNode?.id),
        ...branchesIds.map((item) => ({
          id: `${selectedNode?.id}->${item}`,
          source: selectedNode?.id,
          target: item,
          type: 'default',
          style: { stroke: '#69C0FF', strokeWidth: 2 },
        })),
      ]);

      form.resetFields();
      onClose();
      return;
    }

    setNodes((prev) =>
      prev.map((item) => {
        if (selectedNode?.id === item.id) {
          return { ...item, data: { ...item.data, payload } };
        }
        return item;
      }),
    );

    if (mentions) {
      dispatch(setAutomationMentions([...automationMentions, ...mentions]));
    }

    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue(selectedNode?.data?.payload);
  }, []);

  return (
    <Drawer
      title={selectedNode?.data.label}
      open={open}
      width={720}
      styles={{ body: { ...DRAWER_BODY_STYLE, background: '#F9FCFF' } }}
      onClose={onDrawerClose}
      extra={
        <Space>
          <Button icon={<CloseOutlined />} type='text' onClick={onDrawerClose} />
        </Space>
      }>
      <Spin spinning={isFetchingFields}>
        <Form form={form} layout='vertical' requiredMark={false} className='mt-20'>
          <Col span={24}>
            <Form.Item name={'name'} label={translate('automations.actionName')} initialValue={selectedNode?.data?.label}>
              <Input />
            </Form.Item>
            {nodeSettingsByActionType[selectedNode?.data.action as EAutomationNodeAction]}
          </Col>
        </Form>
      </Spin>
    </Drawer>
  );
};
