import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance, Input, Select } from 'antd';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusinessId, useUserPermissionCheck } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { normalToKebabCase } from '@aduvi/utils/helper';

import { getCoupons } from 'store/features/discount-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Details = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const { t: translate } = useTranslation();
  const {
    entities: { selectedTicket },
  } = useAppSelector((state) => state.entity);

  const { coupons } = useAppSelector((state) => state.discounts);

  const hasPermissionToViewCoupons = useUserPermissionCheck(PERMISSIONS.COUPONS.VIEW);

  const onGetCoupons = useCallback(
    (page = 1, size = 100) => {
      if (!selectedBusinessId || !hasPermissionToViewCoupons) return;
      dispatch(getCoupons({ business_id: selectedBusinessId, params: { page, size } }));
    },
    [selectedBusinessId],
  );

  useEffect(() => {
    onGetCoupons();
  }, [selectedBusinessId]);

  return (
    <Col span={24} className='mt-15'>
      <Form.Item
        name='name'
        label={translate('assets.ticketDrawer.details.name')}
        rules={[{ required: true, message: translate('assets.ticketDrawer.details.validation.name') }]}>
        <Input
          placeholder={translate('assets.ticketDrawer.details.placeholder.name')}
          onChange={(e) => form.setFieldValue('sku', normalToKebabCase(e.target.value))}
        />
      </Form.Item>

      <Form.Item label={translate('assets.ticketDrawer.details.image')} name='image'>
        <UploadImage name='image' onUploadChange={(value) => form.setFieldValue('image', value?.url)} url={selectedTicket?.image} />
      </Form.Item>

      <Form.Item name='description' label={translate('assets.ticketDrawer.details.description')}>
        <Tiptap
          content={form.getFieldValue('description')}
          placeholder={translate('assets.ticketDrawer.details.placeholder.description')}
          onUpdate={(content) => form.setFieldValue('description', content.getHTML())}
        />
      </Form.Item>

      <Form.Item
        name='sku'
        label={translate('assets.ticketDrawer.details.sku')}
        rules={[{ required: true, message: translate('assets.ticketDrawer.details.validation.sku') }]}>
        <Input />
      </Form.Item>
      <Form.Item label={translate('assets.ticketDrawer.details.coupons')} name={'coupons'}>
        <Select
          options={coupons?.data ? coupons?.data.map((coupon) => ({ label: coupon.name, value: coupon.id })) : []}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          mode='multiple'
          className='w-full'
          placeholder={translate('assets.ticketDrawer.details.placeholder.coupons')}
          tokenSeparators={['Enter']}></Select>
      </Form.Item>
    </Col>
  );
};
