import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { IClientPortal, ICreateClientPortalPayload } from '@aduvi/types/client-portal';

export const createClientPortal = async (payload: ICreateClientPortalPayload): Promise<IResponse<IClientPortal>> => {
  return await axios.post(`/businesses/${payload.business_id}/client-portals`, payload);
};

export const updateClientPortal = async (clientPortalId: string, payload: ICreateClientPortalPayload): Promise<IResponse<IClientPortal>> => {
  return await axios.put(`/businesses/${payload.business_id}/client-portals/${clientPortalId}`, payload);
};

export const getClientPortals = async (business_id: string): Promise<IResponse<IClientPortal[]>> => {
  return await axios.get(`/businesses/${business_id}/client-portals`);
};

export const getClientPortalById = async (businessId: string): Promise<IResponse<IClientPortal>> => {
  return await axios.get(`public/businesses/${businessId}/client-portals`);
};

export const deleteClientPortal = async ({
  businessId,
  clientPortalId,
  clientPortalName,
}: {
  businessId: string;
  clientPortalId: string;
  clientPortalName: string;
}): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/client-portals/${clientPortalId}?title=${clientPortalName}`);
};
