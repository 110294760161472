import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { SelectEntityByReference } from '@aduvi/components/SelectEntityByReference/SelectEntityByReference';
import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EPersonalizedViewOrigin } from '@aduvi/types';
import { EEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { bulkSendEmail } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  origin: EPersonalizedViewOrigin;
  data: IEntityWithFields[];
  selectedRowKeys: string[];
  entityTypeId: string;
  setSelectedRowKeys: (value: React.SetStateAction<React.Key[]>) => void;
  setShowEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmailModal = ({ data, selectedRowKeys, origin, entityTypeId, setSelectedRowKeys, setShowEmailModal }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();

  Form.useWatch('email_content', form);

  const { sendingEmail } = useAppSelector((state) => state.entity);

  const {
    entityTypes: { data: entityTypesData },
  } = useAppSelector((state) => state.entity);

  const onCloseModal = () => {
    setShowEmailModal(false);
  };
  const sendEmail = () => {
    const content = form.getFieldValue('email_content') ?? '';

    if (!selectedBusiness?.id) return;

    form.validateFields().then(({ entity_ids, subject }) => {
      dispatch(
        bulkSendEmail({
          businessId: selectedBusiness?.id,
          entityTypeId,
          body: { entity_ids, subject, content },
        }),
      )
        .then(() => {
          setShowEmailModal(false);
          setSelectedRowKeys([]);
        })
        .catch(() => {});
    });
  };

  useEffect(() => {
    if (origin === EPersonalizedViewOrigin.CONTACTS) {
      const selectedContacts = data.filter((item) => selectedRowKeys.includes(item.id) && item.id).flatMap((contact) => contact.id);

      const uniqueIds = Array.from(new Set(selectedContacts));
      form.setFieldsValue({ entity_ids: uniqueIds });
    } else {
      const selectedContacts = data
        ?.filter((item) => selectedRowKeys.includes(item.id))
        .flatMap(
          (entity) =>
            entity?.custom_fields
              .filter((field) => field.reference_entity_type_id === entityTypesData[EEntityType.CONTACT]?.id && field?.field_data?.length)
              .flatMap((field) => field?.field_data?.map((fieldData) => fieldData.id)),
        );

      const uniqueIds = Array.from(new Set(selectedContacts));
      form.setFieldsValue({ entity_ids: uniqueIds });
    }
  }, [origin]);

  return (
    <Modal
      className='email-modal-wrapper'
      styles={{ content: { borderRadius: 8 } }}
      open={true}
      onCancel={onCloseModal}
      width={500}
      title={translate('personalizedView.emailModal.newEmail')}
      footer={[
        <Button
          key='submit'
          onClick={sendEmail}
          loading={sendingEmail}
          disabled={sendingEmail}
          style={{ backgroundColor: activeTheme?.buttonBg, color: activeTheme?.buttonText }}>
          {translate('personalizedView.emailModal.send')}
        </Button>,
      ]}
      centered>
      <Divider className='mt-0' />
      <Form className='mt-30' form={form} layout='vertical' name='email_modal'>
        <Form.Item name='entity_ids' label={translate('personalizedView.emailModal.to')}>
          <SelectEntityByReference
            mode='multiple'
            referenceEntityTypeId={entityTypesData[EEntityType.CONTACT]?.id}
            onAutoSelect={(data) => {
              const createdEntityId = data.id;

              const currentValue = form?.getFieldValue('entity_ids');
              form.setFieldValue('entity_ids', currentValue?.length ? [...currentValue, createdEntityId] : [createdEntityId]);
            }}
          />
        </Form.Item>

        <Form.Item name='subject' label={translate('personalizedView.emailModal.subject')}>
          <Input />
        </Form.Item>

        <Form.Item name='email_content' label={translate('personalizedView.emailModal.message')}>
          <Tiptap content={form.getFieldValue('email_content')} onUpdate={(value) => form.setFieldValue('email_content', value.getHTML())} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
