import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { mapEntityFieldsToPayload } from '@aduvi/components/Entity/helper';
import { CreateInlineEntityModal } from '@aduvi/components/InlineEntity/CreateInlineEntityModal';
import { useBusiness } from '@aduvi/hooks';
import { IEntityField } from '@aduvi/types';
import { EEntityType, IFieldData } from '@aduvi/types/entity';

import { createEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as FieldsService from 'store/services/fields.service';

import { TaskRow } from './@components/TaskRow';

export const Tasks = () => {
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const [showModal, setShowModal] = useState(false);
  const [taskManagementFields, setTaskManagementFields] = useState<IEntityField[] | undefined>();
  const [loading, setIsLoading] = useState(false);

  const {
    entityTypes,
    entities: { selectedEntity, creating },
  } = useAppSelector((state) => state.entity);

  const entityTypeId = entityTypes.data[EEntityType.TASK]?.id;

  const taskEntities = useMemo(() => {
    let uniqueTasks: IFieldData[] = [];

    const tasks = selectedEntity?.custom_fields
      .filter((item) => item.reference_entity_type_id === entityTypeId)
      .flatMap((field) => field.field_data.flatMap((data) => data));

    tasks?.forEach((item) => {
      if (uniqueTasks.some((task) => task.id === item.id)) return;
      uniqueTasks.push(item);
    });

    return uniqueTasks;
  }, [selectedEntity]);

  const onCreateTask = () => {
    if (!selectedBusiness?.id || !entityTypeId || !taskManagementFields) return;

    // TODO Create endpoint once we assign a entity to a entity to return different response.
    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityTypeId,
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), taskManagementFields),
        skip_inserting_on_state: true,
      }),
    )
      .unwrap()
      .then(() => {
        form.resetFields();
        setShowModal(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    setIsLoading(true);

    if (!selectedBusiness?.id || !entityTypeId) {
      setIsLoading(false);
      return;
    }

    try {
      FieldsService.getEntityFields(selectedBusiness?.id, entityTypeId).then((res) => {
        setTaskManagementFields(res.data);
        setIsLoading(false);
      });
    } catch (err) {
      setIsLoading(false);
      return;
    }
  }, [selectedBusiness?.id, entityTypeId]);

  return (
    <span>
      <Row className='pb-20'>
        <Button style={{ width: '131px' }} onClick={() => setShowModal(true)}>
          {translate('entity.task.addTask')}
        </Button>
      </Row>
      {taskEntities?.map((entity, index) => (
        <Row key={index}>
          <TaskRow entity={entity} managementFields={taskManagementFields} entityTypeId={entityTypeId} loading={loading} />
        </Row>
      ))}
      {showModal && (
        <CreateInlineEntityModal
          form={form}
          creating={creating}
          entityTitle={EEntityType.TASK}
          entityTypeId={entityTypeId as string}
          createNewEntity={onCreateTask}
          setIsModalVisible={setShowModal}
        />
      )}
    </span>
  );
};
