import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IResponse, ITaxRate } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const taxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaxRates: builder.query<IResponse<ITaxRate[]>, { business_id?: string }>({
      query: ({ business_id }) => ({
        url: `/businesses/${business_id}/tax/rates`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.TAX }],
    }),
  }),
});

export const { useGetTaxRatesQuery } = taxApiSlice;
