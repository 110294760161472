import { useEffect, useState } from 'react';

export const useCountdownTimer = (initialTimerValue: number) => {
  const [timer, setTimer] = useState(initialTimerValue);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return { timer, setTimer };
};
