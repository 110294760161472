import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Button, Card, Carousel, Col, Form, Input, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { useBusiness } from '@aduvi/hooks';
import { EFormType } from '@aduvi/types/form';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';
import { toHumanReadable } from '@aduvi/utils/helper';

import { getFormsByEntity } from 'store/features/form-slice';
import { getServices, resetServicesState } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const { Option } = Select;

const packageFields = ['Picture', 'Title', 'Price', 'Description', 'Duration', 'Included Products', 'Button'];

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const [isMobileView, setIsMobileView] = useState(false);
  const { t: translate } = useTranslation();

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });
  const { services } = useAppSelector((state) => state.services);
  const { entityForms } = useAppSelector((state) => state.forms);
  const { entityTypes } = useAppSelector((state) => state.entity);

  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || [];

  const allPackages = useMemo(() => services?.data.map((service) => service.packages).flat(), [services?.data]);

  const filteredPackages = useMemo(() => {
    if (form.getFieldValue(['widget_style', 0, 'selected_service']) === 'All') {
      return allPackages;
    } else {
      return allPackages?.filter((pkg) => pkg.business_service_id === form.getFieldValue(['widget_style', 0, 'selected_service']));
    }
  }, [allPackages, form.getFieldValue(['widget_style', 0, 'selected_service'])]);

  const sortedPackages = useMemo(() => {
    if (!filteredPackages) return [];

    const packagesCopy = [...filteredPackages];

    switch (form.getFieldValue(['widget_style', 0, 'sorting_option'])) {
      case 'Price':
        packagesCopy.sort((a, b) => parseFloat(a.flat_price || '0') - parseFloat(b.flat_price || '0'));
        break;
      case 'Service':
        packagesCopy.sort((a, b) => {
          const serviceA = services?.data.find((s) => s.id === a.business_service_id)?.name || '';
          const serviceB = services?.data.find((s) => s.id === b.business_service_id)?.name || '';
          return serviceA.localeCompare(serviceB);
        });
        break;
      case 'Title':
      default:
        packagesCopy.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }

    return packagesCopy;
  }, [filteredPackages, form.getFieldValue(['widget_style', 0, 'sorting_option']), services?.data]);

  const displayedPackages = useMemo(() => {
    if (!sortedPackages) return [];

    if (limitResults === 'show_all') {
      return sortedPackages;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return sortedPackages.slice(0, resultsPerPage);
    }
    return sortedPackages;
  }, [sortedPackages, limitResults, resultsPerPage]);

  const entitySettings = (
    <div>
      <Typography.Text type='secondary'>{translate('widgets.service')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'selected_service']} initialValue={'All'}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'selected_service'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'selected_service'], value);
          }}>
          <Option value='All'>All</Option>
          {services?.data.map((service) => (
            <Option key={service.id} value={service.id}>
              {service.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.sort')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'sorting_option']}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'sorting_option'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'sorting_option'], value);
          }}>
          <Option value='Price'>{translate('widgets.price')}</Option>
          <Option value='Service'>{translate('widgets.service')}</Option>
          <Option value='Title'>{translate('widgets.titleText')}</Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonAction')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'button_action_form']}>
        <Select
          placeholder='Select a form'
          value={form.getFieldValue(['widget_style', 0, 'button_action_form'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'button_action_form'], value);
            const selectedForm = entityForms?.find((f) => f.id === value);
            form.setFieldValue(['widget_style', 0, 'form_name'], selectedForm?.name || '');
          }}>
          {entityForms?.map((form) => (
            <Option key={form.id} value={form.id}>
              Go to {form.name} form
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name={['widget_style', 0, 'form_name']} hidden={true} />

      <Typography.Text type='secondary'>{translate('widgets.buttonText')}</Typography.Text>
      <Form.Item name={['widget_style', 0, 'button_text']}>
        <Input
          value={form.getFieldValue(['widget_style', 0, 'button_text'])}
          onChange={(e) => {
            form.setFieldValue(['widget_style', 0, 'button_text'], e.target.value);
          }}
        />
      </Form.Item>
    </div>
  );

  const handleButtonClick = () => {
    if (form.getFieldValue(['widget_style', 0, 'button_action_form'])) {
      const selectedForm = entityForms?.find((f) => f.id === form.getFieldValue(['widget_style', 0, 'button_action_form']));
      window.open(`/fo/${form.getFieldValue(['widget_style', 0, 'button_action_form'])}/${selectedForm?.name.toLocaleLowerCase()}`, '_blank');
    }
  };

  const onGetBusinessServices = useCallback(() => {
    if (!selectedBusiness?.id) return;
    dispatch(resetServicesState());
    dispatch(
      getServices({
        business_id: selectedBusiness?.id,
        params: {
          page: 1,
          size: 50,
          search: '',
        },
      }),
    );
  }, [selectedBusiness?.id, dispatch]);

  useEffect(() => {
    onGetBusinessServices();
  }, [selectedBusiness?.id, onGetBusinessServices]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes?.data?.JOB?.id) return;
    dispatch(getFormsByEntity({ business_id: selectedBusiness.id, entity_type_id: entityTypes?.data?.JOB?.id, formType: EFormType.LEADS_FORM }));
  }, [selectedBusiness?.id, entityTypes?.data?.JOB?.id, dispatch]);

  return (
    <Row className='packages-design-tab-wrapper' style={backgroundStyle}>
      <Col span={14}>
        <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {displayedPackages?.map((pkg, index) => (
                <div key={index} className='package-card'>
                  {visibleFields.includes('Picture') && pkg.image && (
                    <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />
                  )}
                  {visibleFields.includes('Title') && pkg.name && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {pkg.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && pkg.flat_price && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        fontSize: '18px',
                      }}>
                      ${pkg.flat_price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && pkg.description && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Duration') && pkg.included_duration && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        fontWeight: 'bold',
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                    <div>
                      <Typography.Text
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          fontWeight: 'bold',
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        Included Products:
                      </Typography.Text>
                      <ul>
                        {pkg.products.map((product, idx) => (
                          <li key={idx}>
                            <Typography.Text
                              style={{
                                fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                                color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                              }}>
                              {product.name}
                            </Typography.Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: form.getFieldValue(['widget_style', 0, 'button_background']),
                        color: form.getFieldValue(['widget_style', 0, 'button_font']),
                      }}>
                      {form.getFieldValue(['widget_style', 0, 'button_text'])}
                    </Button>
                  )}
                </div>
              ))}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]} justify='center'>
              {displayedPackages?.map((pkg, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                  <div className='package-card'>
                    {visibleFields.includes('Picture') && pkg.image && (
                      <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />
                    )}
                    {visibleFields.includes('Title') && pkg.name && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {pkg.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && pkg.flat_price && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          fontSize: '18px',
                        }}>
                        ${pkg.flat_price}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && pkg.description && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Duration') && pkg.included_duration && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          fontWeight: 'bold',
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                      <div>
                        <Typography.Text
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            fontWeight: 'bold',
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          Included Products:
                        </Typography.Text>
                        <ul>
                          {pkg.products.map((product, idx) => (
                            <li key={idx}>
                              <Typography.Text
                                style={{
                                  fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                                  color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                                }}>
                                {product.name}
                              </Typography.Text>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={handleButtonClick}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            displayedPackages?.map((pkg, index) => (
              <Row key={index} gutter={[20, 20]} align='middle' className='package-card-row'>
                {visibleFields.includes('Picture') && (
                  <Col xs={24} sm={6} className='package-image'>
                    {pkg.image && <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />}
                  </Col>
                )}
                <Col xs={24} sm={18} className='package-details'>
                  {visibleFields.includes('Title') && pkg.name && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {pkg.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && pkg.flat_price && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        fontSize: '18px',
                      }}>
                      ${pkg.flat_price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && pkg.description && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Duration') && pkg.included_duration && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        fontWeight: 'bold',
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                    <div>
                      <Typography.Text
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          fontWeight: 'bold',
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        Included Products:
                      </Typography.Text>
                      <ul>
                        {pkg.products.map((product, idx) => (
                          <li key={idx}>
                            <Typography.Text
                              style={{
                                fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                                color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                              }}>
                              {product.name}
                            </Typography.Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: form.getFieldValue(['widget_style', 0, 'button_background']),
                        color: form.getFieldValue(['widget_style', 0, 'button_font']),
                      }}>
                      {form.getFieldValue(['widget_style', 0, 'button_text'])}
                    </Button>
                  )}
                </Col>
              </Row>
            ))
          )}
        </Card>
      </Col>
      <Col span={1} className='icons-container'>
        <RightSidebar
          form={form}
          onMobileView={() => setIsMobileView((prevState) => !prevState)}
          isMobileView={isMobileView}
          fields={packageFields}
          entitySettings={entitySettings}
        />
      </Col>
    </Row>
  );
};
