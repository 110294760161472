import { useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { Form, Row } from 'antd';
import { Divider, Input, Select, SelectProps, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { PERMISSIONS } from '@aduvi/constants';
import { useUserPermissionCheck } from '@aduvi/hooks';

import { createProductCategory, getProductCategories } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface SelectProductCategoryProps extends SelectProps {
  business_id?: string;
}

export const SelectProductCategory = ({ business_id, ...props }: SelectProductCategoryProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { productCategories } = useAppSelector((state) => state.products);

  const hasPermissionToFetchProductCategories = useUserPermissionCheck(PERMISSIONS.PRODUCTS.CATEGORIES);

  const addItem = () => {
    if (!business_id) return;

    form
      .validateFields()
      .then(({ name }) => {
        dispatch(createProductCategory({ business_id, name }))
          .unwrap()
          .then(() => {
            form.resetFields();
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!business_id || !hasPermissionToFetchProductCategories) return;
    dispatch(getProductCategories(business_id));
  }, [dispatch, business_id]);

  return (
    <Select
      {...props}
      loading={productCategories.loading}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Form form={form}>
            <Divider style={{ margin: 0 }} />
            <Row align='middle'>
              <Space style={{ padding: '0 4px 4px' }}>
                <Form.Item
                  className='mt-20'
                  name='name'
                  rules={[{ required: true, message: translate('components.selectProductCategory.nameValidation') }]}>
                  <Input placeholder={translate('assets.productsDrawer.enterItem')} />
                </Form.Item>
                <Button
                  loading={productCategories.creating}
                  disabled={productCategories.creating}
                  requiredPermission={PERMISSIONS.PRODUCTS.CATEGORIES}
                  disabledButton
                  icon={<PlusOutlined />}
                  onClick={addItem}>
                  {translate('assets.productsDrawer.addItem')}
                </Button>
              </Space>
            </Row>
          </Form>
        </>
      )}
      options={productCategories?.data?.map((item) => ({ label: item.name, value: item.id }))}
    />
  );
};
