import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { SelectOrder } from '@aduvi/components/SelectOrder/SelectOrder';
import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EOrderType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

interface IProps {
  origin: EPersonalizedViewOrigin;
  form: FormInstance;
  entityType?: IEntityType;
}

const Table = ({ form, origin, entityType }: IProps) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const textFields = useMemo(
    () =>
      fields
        .filter((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)
        .map((field) => ({
          label: field.title,
          value: field.id,
        })),
    [fields],
  );

  useEffect(() => {
    form.setFieldsValue({
      default_date_values: form.getFieldValue('default_date_values') || (textFields.length > 0 ? textFields[0].value : null),
      default_sort_order: form.getFieldValue('default_sort_order') || EOrderType.ASC,
    });
  }, []);

  return (
    <Col className='w-full'>
      <label>{translate('leads.defaultSort')}</label>
      <Row className='w-full mt-10' justify={'space-between'}>
        <Form.Item name='default_date_values' style={{ width: '49%' }}>
          <Select options={fields.map((field) => ({ label: field?.title, value: field?.id }))} placeholder={translate('leads.eventDate')} />
        </Form.Item>
        <Form.Item name='default_sort_order' style={{ width: '49%' }}>
          <SelectOrder placeholder={translate('leads.ascending')} />
        </Form.Item>
      </Row>
    </Col>
  );
};

export default Table;
