import React from 'react';

import { Typography } from 'antd';

import { useActiveTheme } from '@aduvi/hooks';

interface IProps {
  text: string;
  highlight?: string;
}
export const HighlightText = ({ text, highlight }: IProps) => {
  const activeTheme = useActiveTheme();

  if (!highlight || !highlight.trim()) {
    return <Typography.Text>{text}</Typography.Text>;
  }

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <Typography.Text className='color-blue-6' style={{ color: activeTheme?.menuItemText }} key={index}>
            {part}
          </Typography.Text>
        ) : (
          part
        ),
      )}
    </>
  );
};
