import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

import { Avatar, Card, Col, Dropdown, Row, Typography } from 'antd';
import { CardProps } from 'antd/es/card';
import Link from 'antd/es/typography/Link';

import { useAvatarApi } from '@aduvi/hooks/useAvatarApi';
import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityWithFields, IFieldData } from '@aduvi/types/entity';

import { deleteEntity, setSelectedEntity } from 'store/features/entity-slice';
import { useAppSelector } from 'store/hooks';
import { useAppDispatch } from 'store/hooks';

import { UpsertEntityDrawer } from '../Entity/components/UpsertEntityDrawer';
import { EmailModal } from '../PersonalizedViewComponents/PredefinedViews/Table/EmailModal';

interface IExistingContact {
  name?: string;
  image?: string;
  phone?: string;
  email?: string;
}
interface IProps extends Omit<CardProps, 'id'> {
  contactFields?: IFieldData;
  isEventContact?: boolean;
  existingContact?: IExistingContact;
  contact?: IEntityWithFields;
}
export const ContactCard = ({ existingContact, contactFields, contact, isEventContact = false, ...props }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { entityTypes } = useAppSelector((state) => state.entity);

  const nameField = contactFields?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS);
  const imageField = contactFields?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES);
  const phoneField = contactFields?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_PHONES);
  const emailField = contactFields?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_EMAILS);

  const existingName = existingContact?.name ? existingContact.name : nameField ? nameField?.field_data?.[0]?.value : '';
  const existingImage = existingContact?.image ? existingContact.image : imageField ? imageField?.field_data?.[0]?.url : '';
  const existingPhone = existingContact?.phone ? existingContact.phone : phoneField ? phoneField?.field_data?.[0]?.value : '';
  const existingEmail = existingContact?.email ? existingContact.email : emailField ? String(emailField?.field_data?.[0]?.value) : '';
  const { name, imageUrl } = useAvatarApi({ email: existingEmail, existingImage });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showEntityDrawer, setShowEntityDrawer] = useState(false);
  const { fields, formOnlyFields } = useManagementFields({ entityType: entityTypes.data.CONTACT, origin: EPersonalizedViewOrigin.CONTACTS });
  return (
    <>
      <Card
        {...props}
        bordered={false}
        actions={[
          <MailOutlined key='mail' onClick={() => setShowEmailModal(true)} />,

          existingPhone ? (
            <Link href={`tel:${existingPhone}`} key='mail'>
              <PhoneOutlined />
            </Link>
          ) : (
            <PhoneOutlined />
          ),
          !isEventContact ? (
            <Dropdown
              key='ellipsis'
              menu={{
                items: [
                  {
                    label: translate('components.contactCard.edit'),
                    key: 'edit',
                    onClick: () => {
                      dispatch(setSelectedEntity(contact));
                      setShowEntityDrawer(true);
                    },
                  },
                  {
                    label: translate('components.contactCard.delete'),
                    key: 'delete',
                    onClick: () => {
                      if (!contact?.business_id || !contact?.entity_type_id || !contact?.id || !entityTypes?.data?.CONTACT?.name) return;
                      dispatch(
                        deleteEntity({
                          businessId: contact?.business_id,
                          entityTypeId: contact?.entity_type_id,
                          entityId: contact?.id,
                          entityTypeName: entityTypes?.data?.CONTACT?.name,
                        }),
                      )
                        .unwrap()
                        .then(() => {
                          dispatch(setSelectedEntity(undefined));
                        })
                        .catch(() => {});
                    },
                  },
                ],
              }}>
              <EllipsisOutlined />
            </Dropdown>
          ) : (
            <EllipsisOutlined />
          ),
        ]}>
        <Row wrap={false}>
          <Col style={{ flexGrow: 1 }}>
            <Typography.Title className='mb-0' style={{ fontWeight: 400, textTransform: 'capitalize' }} level={5}>
              {existingName || name}
            </Typography.Title>
            {isEventContact && (
              <Typography.Paragraph className='fs-12' type='secondary'>
                {translate('components.contactCard.primaryContact')}
              </Typography.Paragraph>
            )}
            {existingEmail && (
              <Typography.Paragraph className='contact-email mb-0 mt-10' copyable type='secondary'>
                {existingEmail}
              </Typography.Paragraph>
            )}
            {existingPhone && (
              <Typography.Paragraph className='contact-phone mb-0' copyable type='secondary'>
                {existingPhone}
              </Typography.Paragraph>
            )}
          </Col>
          <Col>
            <Avatar size={100} src={existingImage || imageUrl || undefined}>
              {String(existingName)?.toUpperCase()}
            </Avatar>
          </Col>
        </Row>
      </Card>
      {showEntityDrawer && contact?.entity_type_id && contact?.custom_fields && (
        <UpsertEntityDrawer
          entityType={entityTypes.data.CONTACT}
          fields={fields}
          formOnlyFields={formOnlyFields}
          open={showEntityDrawer}
          onClose={() => setShowEntityDrawer(false)}
          origin={EPersonalizedViewOrigin.CONTACTS}
        />
      )}
      {showEmailModal && contact && (
        <EmailModal
          data={contact ? [contact] : []}
          selectedRowKeys={[contact.id]}
          entityTypeId={contact?.entity_type_id}
          setShowEmailModal={setShowEmailModal}
          setSelectedRowKeys={() => {}}
          origin={EPersonalizedViewOrigin.CONTACTS}
        />
      )}
    </>
  );
};
