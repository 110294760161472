import PhoneInput from 'react-phone-input-2';

import { Col, Form, Input, Row } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { PageTitle } from '@aduvi/components/PageTitle/PageTitle';
import { SelectCountry } from '@aduvi/components/SelectCountry/SelectCountry';
import { PERMISSIONS } from '@aduvi/constants';
import { getFormErrors } from '@aduvi/utils/helper';

import { editBusiness } from 'store/features/business-slice';

import { useSettingsDetails, useSettingsDetailsActions, useSettingsDetailsState } from './SettingsDetails.hooks';

export const SettingsDetails = () => {
  const { dispatch, translate, form } = useSettingsDetailsActions();
  const { selectedBusiness, editing } = useSettingsDetailsState();
  useSettingsDetails(form);

  const onSave = () => {
    const payload = {
      ...selectedBusiness,
      ...form.getFieldsValue(),
    };
    dispatch(editBusiness(payload))
      .unwrap()
      .then(() => {})
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  return (
    <Row>
      <Col span={24}>
        <Row>
          <PageTitle className='aduvi-page-title mb-10'>{translate('settings.details.title')}</PageTitle>
        </Row>
        <Row>
          <Col xs={24} md={12}>
            <Form layout={'vertical'} form={form} onFinish={onSave}>
              <Form.Item className='mb-10' label={translate('settings.details.name')} name='name'>
                <Input placeholder='John Doe' />
              </Form.Item>
              <Form.Item className='mb-10' label={translate('settings.details.email')} name='email'>
                <Input placeholder='johndoe@example.com' />
              </Form.Item>
              <Form.Item className='mb-10' label={translate('settings.details.country')} name='country_id'>
                <SelectCountry />
              </Form.Item>
              <Form.Item className='mb-10' label={translate('settings.details.phone')} name='phone_number'>
                <PhoneInput inputStyle={{ width: '100%' }} country={'gb'} key={selectedBusiness?.id} value={selectedBusiness?.phone_number} />
              </Form.Item>
              <Form.Item className='mb-10' label={translate('settings.details.website')} name='website'>
                <Input addonBefore='https://' placeholder='example.com' />
              </Form.Item>

              <Form.Item>
                <Button
                  className='mt-10'
                  loading={editing}
                  disabled={editing}
                  disabledButton
                  requiredPermission={PERMISSIONS.BUSINESS.EDIT}
                  type='primary'
                  htmlType='submit'>
                  {translate('settings.details.save')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
