import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Checkbox, Col, Dropdown, MenuProps, Popconfirm, Row } from 'antd';
import './ContactsRow.scss';

import { Button } from '@aduvi/components/Button/Button';
import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { PERMISSIONS } from '@aduvi/constants';
import { useUserPermissionCheck } from '@aduvi/hooks';
import { ICustomFieldViewStyle } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

interface IProps {
  contactColumns: ICustomFieldViewStyle[];
  contact: IEntityWithFields;
  onClick: (contact: IEntityWithFields) => void;
  onEdit: (data: IEntityWithFields) => void;
  onDelete: (data: IEntityWithFields) => void;
}

export const SingleContact = ({ contactColumns, contact, onEdit, onClick, onDelete }: IProps) => {
  const { t: translate } = useTranslation();

  const getContactDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('contacts.edit'),
      onClick: () => onEdit(contact),
    },
    {
      key: '2',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('contacts.delete')}
          disabled={!useUserPermissionCheck(PERMISSIONS.CONTACTS.DELETE)}
          description={translate('contacts.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            onDelete?.(contact);
          }}>
          {translate('contacts.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.CONTACTS.DELETE),
    },
  ];

  const baseSpan = Math.floor(22 / contactColumns.length);
  let remainingSpan = 22 - baseSpan * contactColumns.length;

  return (
    <Row
      key={contact.id}
      wrap={false}
      align='middle'
      justify={'space-between'}
      className='single-contact-wrapper pl-20 pr-10 mb-20 cursor-pointer'
      onClick={(e) => {
        e.stopPropagation();
        onClick(contact);
      }}>
      <Col span={1}>
        <Checkbox />
      </Col>
      {contactColumns.map((column, index) => {
        let span = baseSpan;
        if (remainingSpan > 0) {
          span += 1;
          remainingSpan -= 1;
        }
        return (
          <Col key={index} className='single-column' span={span}>
            <span style={column.style}>
              {column?.field_id?.map((field) => (
                <CustomFieldValue key={field} field={contact.custom_fields.find((customField) => customField.id === field)} record={contact} />
              ))}
            </span>
          </Col>
        );
      })}

      <Col span={1} className='ml-5' onClick={(e) => e.stopPropagation()}>
        <Dropdown menu={{ items: getContactDropdownItems }} trigger={['click']}>
          <Button
            icon={<EllipsisOutlined />}
            type='text'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};
