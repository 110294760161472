import React from 'react';
import { FolderOutlined } from '@ant-design/icons';

import { IFolder, ITreeData } from '@aduvi/types/file';

export const getFolderPath = (folders: IFolder[], folderId: string, path: string[] = []): string => {
  const folder = folders.find((folder) => folder.id === folderId);
  if (!folder) return path.reverse().join('/');
  path.push(folder?.name);
  if (folder?.parent_id) {
    return getFolderPath(folders, folder.parent_id, path);
  }
  return path.reverse().join('/');
};

const imageExtension = ['PNG', 'JPG', 'JPEG'];

export const getFilePropertiesByFileType = (fileType: string) => {
  return {
    backgroundColor: getColorByFileType(fileType),
    isImage: imageExtension?.includes(fileType),
  };
};

export const getColorByFileType = (extension: string): string | undefined => {
  const colors: { [key: string]: string } = {
    DOC: '#7CC0FF',
    DOCX: '#7CC0FF',
    PDF: '#DD4244',
    XLS: '#28AB75',
    XLSX: '#28AB75',
  };
  return colors[extension.toUpperCase()] || undefined;
};

export const extractFileName = (url: string | undefined) => {
  const filename = url ? url.split('/').pop() || '' : '';
  return filename.replace(/^\d+.*?_/, '');
};

export const findFolderById = (folders: IFolder[], id: React.Key): IFolder | undefined => {
  for (const folder of folders) {
    if (folder.id === id) return folder;

    if (folder.subfolders) {
      const found = findFolderById(folder.subfolders, id);
      if (found) return found;
    }
  }
  return undefined;
};

export const folderMatchesSearch = (folder: IFolder, searchTerm: string): boolean => {
  const searchLower = searchTerm.toLowerCase();
  if (folder.name.toLowerCase().includes(searchLower)) {
    return true;
  }
  if (folder.subfolders) {
    return folder.subfolders.some((subFolder) => folderMatchesSearch(subFolder, searchTerm));
  }
  return false;
};

export const flattenFolders = (folders: IFolder[] | undefined, allFolders: IFolder[] = []): IFolder[] => {
  if (!folders) return allFolders;
  folders.forEach((folder) => {
    allFolders.push(folder);
    if (folder.subfolders && folder.subfolders.length) {
      flattenFolders(folder.subfolders, allFolders);
    }
  });
  return allFolders;
};

export const generateTreeData = (folders: IFolder[]): ITreeData[] => {
  return folders.map((folder) => ({
    title: folder.name,
    key: folder.id,
    icon: <FolderOutlined />,
    children: folder.subfolders ? generateTreeData(folder.subfolders) : [],
  }));
};
