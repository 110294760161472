import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { Button, Calendar, Col, Input, Modal, Row, Spin, Typography } from 'antd';
import './AvailabilityCalendar.scss';

import { useBusiness } from '@aduvi/hooks';
import { EAvailabilityEntity, EUnitableType } from '@aduvi/types';
import { convertDatesToRanges, convertRangesToDates } from '@aduvi/utils/helper';

import { createSingleEntityBlackoutDates, getSingleEntityBlackoutDates } from 'store/features/availability-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import AllEntitiesAvailableCalendar from './AllEntitiesAvailableCalendar';
interface IAvailabilityCalendarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  entityType?: EAvailabilityEntity;
  entityId?: string;
}

export const AvailabilityCalendar = ({ open, setOpen, entityType, entityId }: IAvailabilityCalendarProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const now = dayjs();

  const { singleUnitBlackoutDates, loading } = useAppSelector((state) => state?.availability);

  const [datesToAdd, setDatesToAdd] = useState<{ start: string; end: string }[]>([]);
  const [datesToDelete, setDatesToDelete] = useState<{ start: string; end: string }[]>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showAllUnits, setShowAllUnits] = useState<boolean>(false);
  const [addingDates, setAddingDates] = useState<boolean>(true);
  const [initialUnavailableRanges, setInitialUnavailableRanges] = useState<{ start: string; end: string }[]>([]);
  const [unavailableDates, setUnavailableDates] = useState<Dayjs[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState<Dayjs | null>(null);
  const [selectedDates, setSelectedDates] = useState<Dayjs[]>([]);
  const [numberOfMonths, setNumberOfMonths] = useState(6);

  const months = Array.from({ length: numberOfMonths }, (_, i) => ({
    value: now?.add(i, 'month'),
    id: i,
  }));

  const handleMouseDown = (date: Dayjs) => {
    setIsDragging(true);
    setDragStart(date);
    setSelectedDates([date]);
    if (unavailableDates?.find((ud) => ud?.isSame(date, 'day'))) {
      setAddingDates(false);
    } else {
      setAddingDates(true);
    }
  };

  const handleMouseEnter = (date: Dayjs) => {
    if (isDragging && dragStart) {
      const datesInRectangle = getDatesInRectangle(dragStart, date);
      setSelectedDates(datesInRectangle);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStart(null);
    if (selectedDates?.length > 0) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmationModalOk = () => {
    setConfirmModalOpen(false);
    setUnavailableDates((prev) => {
      let newUnavailableDates = [...prev];
      if (addingDates) {
        selectedDates?.forEach((date) => {
          if (!newUnavailableDates?.find((d) => d?.isSame(date, 'day'))) {
            newUnavailableDates.push(date);
          }
        });
      } else {
        newUnavailableDates = newUnavailableDates?.filter((date) => !selectedDates?.find((selectedDate) => selectedDate?.isSame(date, 'day')));
      }
      setSelectedDates([]);
      // Detect changes after updating unavailable dates
      detectChanges(newUnavailableDates);
      return newUnavailableDates;
    });
  };

  const handleConfirmationModalCancel = () => {
    setConfirmModalOpen(false);
    setSelectedDates([]);
  };

  const getDatesInRectangle = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
    const dates = [];
    const start = startDate?.isBefore(endDate) ? startDate : endDate;
    const end = startDate?.isAfter(endDate) ? startDate : endDate;
    let currentDate = start?.startOf('day');
    while (currentDate?.isBefore(end?.endOf('day')) || currentDate?.isSame(end, 'day')) {
      dates.push(currentDate);
      currentDate = currentDate?.add(1, 'day');
    }
    return dates;
  };

  const handleWeekdayClick = (month: Dayjs, weekday: number) => {
    const startOfMonth = month?.startOf('month');
    const endOfMonth = month?.endOf('month');
    const dates = [];
    let current = startOfMonth;
    while (current?.isBefore(endOfMonth) || current?.isSame(endOfMonth, 'day')) {
      if (current?.day() === weekday) {
        dates.push(current?.clone());
      }
      current = current?.add(1, 'day');
    }
    if (dates?.length === 0) return;
    const allUnavailable = dates?.every((date) => unavailableDates?.some((ud) => ud?.isSame(date, 'day')));
    setAddingDates(!allUnavailable);
    setSelectedDates(dates);
    setConfirmModalOpen(true);
  };
  const detectChanges = (currentUnavailableDates: Dayjs[]) => {
    const currentRanges = convertDatesToRanges(currentUnavailableDates);
    const rangesToDelete: { start: string; end: string }[] = [];
    const rangesToAdd: { start: string; end: string }[] = [];
    const rangesAreEqual = (range1: { start: string; end: string }, range2: { start: string; end: string }) => {
      return range1?.start === range2?.start && range1?.end === range2?.end;
    };
    // Identify ranges to delete
    initialUnavailableRanges?.forEach((initialRange) => {
      const existsInCurrentRanges = currentRanges?.some((currentRange) => rangesAreEqual(currentRange, initialRange));
      if (!existsInCurrentRanges) {
        rangesToDelete.push(initialRange);
      }
    });
    // Identify ranges to add
    currentRanges?.forEach((currentRange) => {
      const existsInInitialRanges = initialUnavailableRanges?.some((initialRange) => rangesAreEqual(currentRange, initialRange));
      if (!existsInInitialRanges) {
        rangesToAdd.push(currentRange);
      }
    });
    setDatesToAdd(rangesToAdd);
    setDatesToDelete(rangesToDelete);
  };
  const handleSave = () => {
    if (!selectedBusiness?.id || !entityId || !entityType) return;
    dispatch(
      createSingleEntityBlackoutDates({
        business_id: selectedBusiness?.id,
        body: {
          dates_to_delete: datesToDelete?.map((date) => ({
            unitable_id: entityId,
            start_date: dayjs(date?.start)?.format('YYYY-MM-DD'),
            end_date: dayjs(date?.end)?.format('YYYY-MM-DD'),
          })),
          units: datesToAdd?.map((date) => ({
            unitable_type: EUnitableType[entityType],
            unitable_id: entityId,
            start_date: dayjs(date?.start)?.format('YYYY-MM-DD'),
            end_date: dayjs(date?.end)?.format('YYYY-MM-DD'),
          })),
        },
      }),
    )?.then(() => {
      setInitialUnavailableRanges(convertDatesToRanges(unavailableDates));
      setOpen(false);
    });
  };

  useEffect(() => {
    if (!singleUnitBlackoutDates) return;
    const dates = convertRangesToDates(singleUnitBlackoutDates || []);
    setUnavailableDates(dates);
    setInitialUnavailableRanges(singleUnitBlackoutDates);
  }, [singleUnitBlackoutDates]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityId || !entityType) return;
    dispatch(
      getSingleEntityBlackoutDates({
        business_id: selectedBusiness?.id,
        entity_type: entityType,
        entity_id: entityId,
        start_date: dayjs()?.startOf('month')?.format('YYYY-MM-DD'),
        end_date: dayjs()?.add(numberOfMonths, 'month')?.endOf('month')?.format('YYYY-MM-DD'),
      }),
    );
  }, [selectedBusiness?.id, entityId, entityType, numberOfMonths]);

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isDragging) {
        handleMouseUp();
      }
    };
    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging, selectedDates]);
  return (
    <>
      <Modal
        className='availability-modal'
        title={translate('availability.title')}
        centered
        footer={() => (
          <Row justify='space-between' align='middle'>
            <Button onClick={() => setShowAllUnits(true)}>{translate('availability.viewAllUnits')}</Button>
            <Button type='primary' onClick={handleSave}>
              {translate('availability.save')}
            </Button>
          </Row>
        )}
        open={open}
        onCancel={() => setOpen(false)}
        width={1200}>
        <Spin className='w-full' spinning={loading}>
          <Row wrap={true} gutter={[20, 20]} style={{ overflow: 'auto' }}>
            {months?.map((month) => (
              <Col span={8} key={month?.id}>
                <div className='calendar-with-buttons'>
                  <Calendar
                    mode='month'
                    value={month?.value}
                    className='availability-calendar'
                    headerRender={() => (
                      <div>
                        <Typography.Title
                          className='availability-calendar-title text-center cursor-pointer'
                          level={5}
                          onClick={() => {
                            const daysInMonth = month?.value?.daysInMonth();
                            const startOfMonth = month?.value?.startOf('month');
                            const newDates = Array.from({ length: daysInMonth }, (_, i) => {
                              return startOfMonth?.clone()?.add(i, 'days');
                            });
                            if (unavailableDates?.find((ud) => ud?.isSame(newDates[0], 'day'))) {
                              setAddingDates(false);
                            } else {
                              setAddingDates(true);
                            }
                            setSelectedDates(newDates);
                            setConfirmModalOpen(true);
                          }}>
                          {month?.value?.format('MMMM YYYY')}
                        </Typography.Title>
                        <Row justify='space-around' gutter={4} className='weekday-buttons'>
                          {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']?.map((dayAbbr, index) => (
                            <Button
                              key={index}
                              size='small'
                              type='link'
                              onClick={() => handleWeekdayClick(month?.value, index)}
                              className='weekday-button'>
                              {dayAbbr}
                            </Button>
                          ))}
                        </Row>
                      </div>
                    )}
                    fullscreen={false}
                    fullCellRender={(value) => {
                      const isCurrentMonth = value?.month() === month?.value?.month();
                      const isBooked = unavailableDates?.some((date) => date?.isSame(value, 'day'));
                      const isSelected = selectedDates?.some((date) => date?.isSame(value, 'day'));
                      return (
                        <div style={{ padding: '3px' }} onMouseDown={() => handleMouseDown(value)} onMouseEnter={() => handleMouseEnter(value)}>
                          <div
                            className='availability-calendar-cell'
                            style={{
                              backgroundColor: isSelected ? '#85a5ff' : isCurrentMonth ? (isBooked ? '#FF4D4F' : '#E6F7FF') : '#fff',
                              color: isCurrentMonth ? (isBooked ? 'white' : '#1890FF') : '#00000073',
                              borderRadius: '4px',
                              textAlign: 'center',
                              lineHeight: '24px',
                              cursor: 'pointer',
                            }}>
                            {value?.date()}
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <Row justify='center'>
            <Col>
              <Button className='mt-10' onClick={() => setNumberOfMonths((prev) => prev + 6)}>
                {translate('availability.loadMore')}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <AllEntitiesAvailableCalendar open={showAllUnits} entityType={entityType} setOpen={setShowAllUnits} setMainModalOpen={setOpen} />
      <Modal open={confirmModalOpen} onCancel={handleConfirmationModalCancel} onOk={handleConfirmationModalOk} destroyOnClose={true}>
        <Typography.Title level={5}>{translate('availability.confirmDates')}</Typography.Title>
        <Typography.Paragraph>{!addingDates ? translate('availability.removeDates') : translate('availability.addDates')}</Typography.Paragraph>
        <Input.TextArea
          className='removed-dates'
          disabled
          value={convertDatesToRanges(selectedDates)
            ?.map((range) => `${dayjs(range?.start)?.format('MMMM D, YYYY')} - ${dayjs(range?.end)?.format('MMMM D, YYYY')}`)
            ?.join('\n')}
          style={{
            backgroundColor: !addingDates ? '#fff2f0' : '#f6ffed',
            color: !addingDates ? '#ff4d4f' : '#52c41a',
          }}
          rows={Math.min(selectedDates?.length, 10)}
        />
      </Modal>
    </>
  );
};
