import { useDroppable } from '@dnd-kit/core';

export const DroppableArea = ({ id, children }: { id: string; children: JSX.Element }) => {
  const { isOver, setNodeRef } = useDroppable({ id });

  return (
    <div ref={setNodeRef} style={{ opacity: isOver ? 0.5 : 1 }}>
      {children}
    </div>
  );
};
