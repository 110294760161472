import { Card, Col, Typography } from 'antd';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';

interface IProps {
  title: string;
  amount: number;
}
export const PaymentSuggestionCard = ({ title, amount }: IProps) => {
  return (
    <Col className='payment-suggestion text-center'>
      <Card bordered={false} onClick={() => {}}>
        <Typography.Paragraph type='secondary' className='mb-0'>
          {title}
        </Typography.Paragraph>
        <Typography.Title level={3} className='m-0'>
          <PriceDisplay price={amount.toFixed(2)} />
        </Typography.Title>
      </Card>
    </Col>
  );
};
