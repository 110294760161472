import axios from '@aduvi/config/axios';
import { IRepData, IRepPayload, IRepResponse, IRepSettings, IResponse, IWithdrawal, IWithdrawalPayload } from '@aduvi/types';

export const getReps = async (businessId: string): Promise<IResponse<IRepResponse[]>> => {
  return await axios.get(`/businesses/${businessId}/rep-link`);
};

export const getRepById = async (businessId: string, repLinkId: string): Promise<IResponse<IRepData>> => {
  return await axios.get(`/businesses/${businessId}/rep-link/${repLinkId}`);
};

export const createRep = async (businessId: string, payload: IRepPayload): Promise<IResponse<IRepData>> => {
  return await axios.post(`/businesses/${businessId}/rep-link`, payload);
};

export const updateRep = async (businessId: string, repLinkId: string, payload: IRepPayload): Promise<IResponse<IRepData>> => {
  return await axios.put(`/businesses/${businessId}/rep-link/${repLinkId}`, payload);
};

export const deleteRep = async (businessId: string, repLinkId: string): Promise<IResponse<IRepData>> => {
  return await axios.delete(`/businesses/${businessId}/rep-link/${repLinkId}`);
};

export const getRepSettings = async (businessId: string): Promise<IResponse<IRepSettings>> => {
  return await axios.get(`/businesses/${businessId}/rep-link/settings`);
};

export const updateRepSettings = async (businessId: string, payload: IRepSettings, method: 'put' | 'post'): Promise<IResponse<IRepSettings>> => {
  const url = method === 'put' ? `/businesses/${businessId}/rep-link/settings/${payload.id}` : `/businesses/${businessId}/rep-link/settings`;
  return await axios({ method, url, data: payload });
};

export const createWithdrawal = async (businessId: string, repLinkId: string, payload: IWithdrawalPayload): Promise<IResponse<IWithdrawal>> => {
  return await axios.post(`/businesses/${businessId}/rep-link/withdrawal/${repLinkId}`, payload);
};

export const getWithdrawals = async (businessId: string): Promise<IResponse<IWithdrawal[]>> => {
  return await axios.get(`/businesses/${businessId}/rep-link/withdrawals`);
};

export const markWithdrawalAsPaid = async (businessId: string, withdrawalId: string): Promise<IResponse<IWithdrawal>> => {
  return await axios.post(`/businesses/${businessId}/rep-link/withdrawal/${withdrawalId}/mark-as-paid`);
};
