import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAcademy, IAcademyState, IEvaluateQuiz } from '@aduvi/types/academy';

const initialState: IAcademyState = {
  academies: undefined,
  selectedAcademy: undefined,
  loading: false,
  evaluating: undefined,
  academyAchievement: undefined,
  evaluatedData: undefined,
};

export const academySlice = createSlice({
  name: 'academy',
  initialState,
  reducers: {
    setSelectedAcademy: (state, { payload }: PayloadAction<IAcademy | undefined>) => {
      state.selectedAcademy = payload;
    },
    setEvaluatedData: (state, { payload }: PayloadAction<IEvaluateQuiz | undefined>) => {
      state.evaluatedData = payload;
    },
  },
});

export const { setSelectedAcademy, setEvaluatedData } = academySlice.actions;

export const academyReducer = academySlice.reducer;
