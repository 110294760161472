import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IBuilderBlock,
  IBuilderBlockPayload,
  IBuilderCategory,
  IBuilderCategoryPayload,
  IBuilderTemplate,
  IBuilderTemplatesPayload,
  IProposal,
  IProposalPayload,
} from '@aduvi/types';

import * as ProposalsService from 'store/services/proposals.service';

interface IInitialState {
  proposals: {
    loading: boolean;
    proposals: IProposal[];
    selectedProposal?: IProposal;
    loadingProposal: boolean;
    currentProposalState: {
      templateId: string;
      html: string;
      mainCss: string;
      sectionCss: string;
    };
  };
  templates: {
    templates: IBuilderTemplate[];
    templateCategories: IBuilderCategory[];
    loading: boolean;
    selectedTemplate?: IBuilderTemplate;
    currentTemplateState: {
      html: string;
      mainCss: string;
      sectionCss: string;
    };
  };
  builder_blocks: {
    data: IBuilderBlock[];
    selectedBlock?: IBuilderBlock;
    loading: boolean;
    creating: boolean;
    loadingBlock: boolean;
  };
}

const initialState: IInitialState = {
  proposals: {
    loading: false,
    proposals: [],
    selectedProposal: undefined,
    loadingProposal: false,
    currentProposalState: {
      templateId: '',
      html: '',
      mainCss: '',
      sectionCss: '',
    },
  },
  templates: {
    templates: [],
    templateCategories: [],
    loading: false,
    selectedTemplate: undefined,
    currentTemplateState: {
      html: '',
      mainCss: '',
      sectionCss: '',
    },
  },
  builder_blocks: {
    data: [],
    selectedBlock: undefined,
    loading: false,
    creating: false,
    loadingBlock: false,
  },
};

export const getBuilderCategories = createAsyncThunk('builder-categories/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await ProposalsService.getBuilderCategories(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBuilderCategory = createAsyncThunk('builder-categories/create', async (params: IBuilderCategoryPayload, { rejectWithValue }) => {
  try {
    return await ProposalsService.createBuilderCategory(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBuilderTemplates = createAsyncThunk('builder-templates/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await ProposalsService.getBuilderTemplates(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBuilderTemplates = createAsyncThunk('builder-templates/create', async (payload: IBuilderTemplatesPayload, { rejectWithValue }) => {
  try {
    return await ProposalsService.createBuilderTemplates(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editBuilderTemplates = createAsyncThunk(
  'builder-templates/edit',
  async (
    payload: {
      templateId: string;
      body: IBuilderTemplatesPayload;
    },
    { rejectWithValue },
  ) => {
    try {
      return await ProposalsService.editBuilderTemplates(payload.templateId, payload.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const cloneBuilderTemplates = createAsyncThunk(
  'builder-templates/clone',
  async (payload: { templateId: string; businessId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.cloneBuilderTemplates(payload.businessId, payload.templateId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBuilderTemplateById = createAsyncThunk(
  'builder-templates/get-by-id',
  async (payload: { businessId: string; builderTemplateId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.getBuilderTemplateById(payload.businessId, payload.builderTemplateId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBuilderTemplate = createAsyncThunk(
  'builder-templates/delete',
  async (payload: { businessId: string; builderTemplateId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.deleteBuilderTemplate(payload.businessId, payload.builderTemplateId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createProposal = createAsyncThunk('proposals/create', async (payload: IProposalPayload, { rejectWithValue }) => {
  try {
    return await ProposalsService.createProposal(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProposals = createAsyncThunk('proposals/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await ProposalsService.getProposals(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteProposal = createAsyncThunk(
  'proposals/delete',
  async (payload: { businessId: string; proposalId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.deleteProposal(payload.businessId, payload.proposalId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getProposalById = createAsyncThunk(
  'proposals/get-by-id',
  async (payload: { businessId: string; proposalId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.getProposalById(payload.businessId, payload.proposalId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editProposal = createAsyncThunk(
  'proposals/edit',
  async (payload: { proposalId: string; payload: IProposalPayload }, { rejectWithValue }) => {
    try {
      return await ProposalsService.editProposal(payload.proposalId, payload.payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBuilderBlocks = createAsyncThunk('builder-block/get', async (payload: { business_id: string }, { rejectWithValue }) => {
  try {
    return await ProposalsService.getBuilderBlocks(payload.business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBuilderBlock = createAsyncThunk('builder-block/create', async (payload: IBuilderBlockPayload, { rejectWithValue }) => {
  try {
    return await ProposalsService.createBuilderBlock(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBuilderBlockById = createAsyncThunk(
  'builder-block/get-by-id',
  async (payload: { businessId: string; blockId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.getBuilderBlockById(payload.businessId, payload.blockId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editBuilderBlock = createAsyncThunk(
  'builder-block/edit',
  async (payload: { builderBlockId: string; body: IBuilderBlockPayload }, { rejectWithValue }) => {
    try {
      return await ProposalsService.editBuilderBlock(payload.builderBlockId, payload.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBuilderBlock = createAsyncThunk(
  'builder-block/delete',
  async (payload: { businessId: string; blockId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.deleteBuilderBlock(payload.businessId, payload.blockId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPublicProposal = createAsyncThunk(
  'proposal/get-public',
  async (payload: { businessId: string; proposalId: string }, { rejectWithValue }) => {
    try {
      return await ProposalsService.getPublicProposal(payload.businessId, payload.proposalId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const proposalsSlice = createSlice({
  name: 'proposals',
  initialState,
  reducers: {
    setSelectedProposal: (state, { payload }: PayloadAction<IProposal | undefined>) => {
      state.proposals.selectedProposal = payload;
    },
    setCurrentTemplateState: (state, { payload }: PayloadAction<IInitialState['templates']['currentTemplateState']>) => {
      state.templates.currentTemplateState = payload;
    },
    setCurrentProposalState: (state, { payload }: PayloadAction<IInitialState['proposals']['currentProposalState']>) => {
      state.proposals.currentProposalState = payload;
    },
    setSelectedTemplate: (state, { payload }: PayloadAction<IBuilderTemplate | undefined>) => {
      state.templates.selectedTemplate = payload;
    },
    setSelectedBuilderBlock: (state, { payload }: PayloadAction<IBuilderBlock | undefined>) => {
      state.builder_blocks.selectedBlock = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBuilderCategories.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(getBuilderCategories.fulfilled, (state, { payload }) => {
        state.templates.loading = false;
        state.templates.templateCategories = payload.data;
      })
      .addCase(getBuilderCategories.rejected, (state) => {
        state.templates.loading = false;
      })
      .addCase(createBuilderCategory.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(createBuilderCategory.fulfilled, (state, { payload }) => {
        state.templates.loading = false;
        state.templates.templateCategories = [...state.templates.templateCategories, payload.data];
      })
      .addCase(createBuilderCategory.rejected, (state) => {
        state.templates.loading = false;
      })
      .addCase(getBuilderTemplates.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(getBuilderTemplates.fulfilled, (state, { payload }) => {
        state.templates.loading = false;
        state.templates.templates = payload.data;
      })
      .addCase(getBuilderTemplates.rejected, (state) => {
        state.templates.loading = false;
      })
      .addCase(createBuilderTemplates.pending, () => {})
      .addCase(createBuilderTemplates.fulfilled, (state, { payload }) => {
        state.templates.templates = [...state.templates.templates, payload.data];
      })
      .addCase(createBuilderTemplates.rejected, () => {})

      .addCase(editBuilderTemplates.pending, () => {})
      .addCase(editBuilderTemplates.fulfilled, (state, { payload, meta: { arg } }) => {
        state.templates.templates = state.templates.templates.map((item) => (item.id === arg.templateId ? payload.data : item));
      })
      .addCase(editBuilderTemplates.rejected, () => {})

      .addCase(cloneBuilderTemplates.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(cloneBuilderTemplates.fulfilled, (state, { payload }) => {
        state.templates.loading = false;
        state.templates.templates = [...state.templates.templates, payload.data];
      })
      .addCase(cloneBuilderTemplates.rejected, (state) => {
        state.templates.loading = false;
      })

      .addCase(getBuilderTemplateById.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(getBuilderTemplateById.fulfilled, (state, { payload }) => {
        state.templates.loading = false;
        state.templates.selectedTemplate = payload.data;
      })
      .addCase(getBuilderTemplateById.rejected, (state) => {
        state.templates.loading = false;
      })
      .addCase(deleteBuilderTemplate.pending, (state) => {
        state.templates.loading = true;
      })
      .addCase(deleteBuilderTemplate.fulfilled, (state, { meta }) => {
        state.templates.loading = false;
        state.templates.templates = state.templates.templates.filter((item) => item.id !== meta.arg?.builderTemplateId);
      })
      .addCase(deleteBuilderTemplate.rejected, (state) => {
        state.templates.loading = false;
      })
      .addCase(getProposals.pending, (state) => {
        state.proposals.loading = true;
      })
      .addCase(getProposals.fulfilled, (state, { payload }) => {
        state.proposals.loading = false;
        state.proposals.proposals = payload.data;
      })
      .addCase(getProposals.rejected, (state) => {
        state.proposals.loading = false;
      })
      .addCase(createProposal.rejected, () => {})
      .addCase(createProposal.pending, () => {})
      .addCase(createProposal.fulfilled, (state, { payload }) => {
        state.proposals.proposals = [...state.proposals.proposals, payload.data];
      })
      .addCase(getProposalById.rejected, () => {})
      .addCase(getProposalById.pending, () => {})
      .addCase(getProposalById.fulfilled, (state, { payload }) => {
        state.proposals.selectedProposal = payload.data;
      })
      .addCase(editProposal.pending, () => {})
      .addCase(editProposal.fulfilled, (state, { payload, meta: { arg } }) => {
        state.proposals.proposals = state.proposals.proposals.map((item) => (item.id === arg.proposalId ? payload.data : item));
      })
      .addCase(editProposal.rejected, () => {})
      .addCase(deleteProposal.rejected, (state) => {
        state.proposals.loading = false;
      })
      .addCase(deleteProposal.pending, (state) => {
        state.proposals.loading = true;
      })
      .addCase(deleteProposal.fulfilled, (state, { meta }) => {
        state.proposals.loading = false;
        state.proposals.proposals = state.proposals.proposals.filter((item) => item.id !== meta.arg.proposalId);
      })
      .addCase(getBuilderBlocks.rejected, (state) => {
        state.builder_blocks.loading = false;
      })
      .addCase(getBuilderBlocks.pending, (state) => {
        state.builder_blocks.loading = true;
      })
      .addCase(getBuilderBlocks.fulfilled, (state, { payload }) => {
        state.builder_blocks.loading = false;
        state.builder_blocks.data = payload.data;
      })
      .addCase(createBuilderBlock.rejected, (state) => {
        state.builder_blocks.creating = false;
      })
      .addCase(createBuilderBlock.pending, (state) => {
        state.builder_blocks.creating = true;
      })
      .addCase(createBuilderBlock.fulfilled, (state, { payload }) => {
        state.builder_blocks.creating = false;
        state.builder_blocks.data = [...state.builder_blocks.data, payload.data];
      })
      .addCase(getBuilderBlockById.rejected, (state) => {
        state.builder_blocks.loadingBlock = false;
      })
      .addCase(getBuilderBlockById.pending, (state) => {
        state.builder_blocks.loadingBlock = true;
      })
      .addCase(getBuilderBlockById.fulfilled, (state, { payload }) => {
        state.builder_blocks.loadingBlock = false;
        state.builder_blocks.selectedBlock = payload.data;
      })
      .addCase(deleteBuilderBlock.rejected, (state) => {
        state.builder_blocks.loading = false;
      })
      .addCase(deleteBuilderBlock.pending, (state) => {
        state.builder_blocks.loading = true;
      })
      .addCase(deleteBuilderBlock.fulfilled, (state, { meta }) => {
        state.builder_blocks.loading = false;
        state.builder_blocks.data = state.builder_blocks.data.filter((item) => item.id !== meta.arg.blockId);
      })
      .addCase(editBuilderBlock.pending, () => {})
      .addCase(editBuilderBlock.fulfilled, (state, { payload, meta: { arg } }) => {
        state.builder_blocks.data = state.builder_blocks.data.map((item) => (item.id === arg.builderBlockId ? payload.data : item));
      })
      .addCase(editBuilderBlock.rejected, () => {})
      .addCase(getPublicProposal.pending, (state) => {
        state.proposals.loadingProposal = true;
      })
      .addCase(getPublicProposal.fulfilled, (state) => {
        state.proposals.loadingProposal = false;
      })
      .addCase(getPublicProposal.rejected, (state) => {
        state.proposals.loadingProposal = false;
      });
  },
});

export const proposalsReducer = proposalsSlice.reducer;
export const { setSelectedProposal, setCurrentTemplateState, setCurrentProposalState, setSelectedTemplate, setSelectedBuilderBlock } =
  proposalsSlice.actions;
