import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';
import './QuantityStock.scss';

export const QuantityStock = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  const ticketQuantity = Form.useWatch('allow_multiple_tickets', form);
  const tresholdNotification = Form.useWatch('low_stock_notification', form);

  return (
    <Col span={24} className='mt-15 w-full quantity-stock-wrapper'>
      <Form.Item name='quantity_available' label={translate('assets.ticketDrawer.quantityStock.avaliable')}>
        <Input placeholder={translate('assets.ticketDrawer.quantityStock.placeholder.avaliable')} />
      </Form.Item>

      <Form.Item name='allow_multiple_tickets' valuePropName='checked'>
        <Checkbox className='mb-15'>{translate('assets.ticketDrawer.quantityStock.allowQuantity')}</Checkbox>
      </Form.Item>

      {ticketQuantity && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='min_quantity'
              rules={[{ required: true, message: translate('assets.ticketDrawer.quantityStock.validation.minQuantity') }]}
              label={translate('assets.ticketDrawer.quantityStock.minQuantity')}>
              <Input placeholder={translate('assets.ticketDrawer.quantityStock.placeholder.minQuantity')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='max_quantity'
              rules={[{ required: true, message: translate('assets.ticketDrawer.quantityStock.validation.maxQuantity') }]}
              label={translate('assets.ticketDrawer.quantityStock.maxQuantity')}>
              <Input placeholder={translate('assets.ticketDrawer.quantityStock.placeholder.maxQuantity')} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Form.Item name='low_stock_notification' valuePropName='checked'>
          <Checkbox className='mb-15'>{translate('assets.ticketDrawer.quantityStock.sendNotification')}</Checkbox>
        </Form.Item>
      </Row>

      {tresholdNotification && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='stock_threshold'
              rules={[{ required: true, message: translate('assets.ticketDrawer.quantityStock.validation.stockThreshold') }]}
              label={translate('assets.ticketDrawer.quantityStock.stockThreshold')}>
              <Input placeholder={translate('assets.ticketDrawer.quantityStock.placeholder.stockThreshold')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={translate('assets.ticketDrawer.quantityStock.notificationRecipients')} name='notification_recipients'>
              <Select
                mode='tags'
                className='w-full'
                placeholder={translate('assets.ticketDrawer.quantityStock.placeholder.notificationRecipients')}
                tokenSeparators={['Enter']}></Select>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Col>
  );
};
