import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

import { Avatar, Button, Col, List, Row } from 'antd';
import './Queue.scss';

import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { EAutomationNodeAction, ENodeStatus, INodeHistory } from '@aduvi/types/automation';
import { toHumanReadable } from '@aduvi/utils/helper';

import { getAutomationQueue, setSelectedAutomationQueue } from 'store/features/automation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Queue = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  const { selectedAutomation, selectedAutomationQueue, loadingAutomationQueue } = useAppSelector((state) => state.automation);

  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);

  const getNodeQueueTitle = (nodeHistory: INodeHistory) => {
    const node = selectedAutomation?.nodes.find((n) => n.id === nodeHistory?.node_id);
    if (!node) return translate('automations.unknownNodeAction');

    let actionText: string | undefined = '';
    switch (node.action) {
      case EAutomationNodeAction.SEND_EMAIL:
        actionText = 'Email';
        break;
      case EAutomationNodeAction.SEND_SMS:
        actionText = 'SMS';
        break;
      default:
        actionText = node?.action?.replace('_', ' ').toLowerCase();
    }

    let title = node?.payload?.name || node?.title;

    let recipient = '';
    const response = nodeHistory?.response?.response;

    recipient = translate('automations.unknownRecipient');

    if (response?.to) {
      recipient = response.to;
    }

    return (
      <>
        {actionText} “{title}” pending for <span style={{ color: activeTheme?.buttonBg }}>{recipient}</span>
      </>
    );
  };

  const getNodeQueueDescription = (nodeHistory: INodeHistory) => {
    const scheduledAt = nodeHistory?.created_at;
    const formattedDate = dayjs(scheduledAt).format('h:mma Do MMMM YYYY');
    const relativeTimeStr = dayjs(scheduledAt).fromNow();
    const statusColor =
      nodeHistory?.status === ENodeStatus.COMPLETED ? '#52C41A' : nodeHistory?.status === ENodeStatus.FAILED ? '#FF4D4F' : 'inherit';

    return (
      <>
        <span style={{ color: statusColor }}>{toHumanReadable(nodeHistory?.status)}</span> - {formattedDate} ({relativeTimeStr})
      </>
    );
  };

  const activeNodeHistories = selectedAutomationQueue?.data.flatMap((history) => history?.node_histories) || [];

  const onGetAutomationQueue = useCallback(
    (page = 1, size = 5) => {
      if (!selectedBusiness?.id || !selectedAutomation?.id) return;
      dispatch(
        getAutomationQueue({
          businessId: selectedBusiness.id,
          automationId: selectedAutomation.id,
          params: { page, size },
        }),
      )
        .unwrap()
        .then((data) => {
          dispatch(setSelectedAutomationQueue(data));
        })
        .catch(() => {});
    },
    [dispatch, selectedBusiness?.id, selectedAutomation?.id],
  );

  useEffect(() => {
    onGetAutomationQueue(1, 5);
  }, [onGetAutomationQueue]);

  return (
    <Row justify='center' className='automation-queue-container'>
      <Col xs={24} sm={20} md={16} lg={12} xl={10}>
        <List
          className='queue-list'
          loading={loadingAutomationQueue}
          dataSource={activeNodeHistories}
          pagination={{
            position: 'bottom',
            align: 'start',
            total: selectedAutomationQueue?.total,
            pageSize: selectedAutomationQueue?.per_page,
            current: selectedAutomationQueue?.current_page,
            onChange: (page, pageSize) => {
              onGetAutomationQueue(page, pageSize);
            },
          }}
          renderItem={(nodeHistory) => (
            <List.Item
              key={nodeHistory.id}
              className='queue-list-item'
              actions={[
                <Button key={`reschedule-${nodeHistory.id}`} type='link'>
                  {translate('automations.reschedule')}
                </Button>,
                <Button key={`disable-enable-${nodeHistory.id}`} type='link' onClick={() => {}}></Button>,
              ]}>
              <List.Item.Meta avatar={<Avatar />} title={getNodeQueueTitle(nodeHistory)} description={getNodeQueueDescription(nodeHistory)} />
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};
