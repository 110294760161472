import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import type { DatePickerProps } from 'antd';
import { DatePicker, Form } from 'antd';

import { IEntityField } from '@aduvi/types';

import { generateFormat, parseDate } from './helper';

interface IProps {
  field: IEntityField;
  index: number;
  value?: Dayjs | null;
  datePickerProps?: Omit<DatePickerProps<Dayjs>, 'picker' | 'showTime' | 'format' | 'disabledDate' | 'value' | 'onChange' | 'startDate' | 'endDate'>;
  onChange?: DatePickerProps<Dayjs>['onChange'];
}

const { RangePicker } = DatePicker;

export const CustomDateTime = ({ field, index, value, onChange, datePickerProps = {} }: IProps) => {
  const { t: translate } = useTranslation();

  const { settings } = field;
  const format = settings?.format || generateFormat(settings?.date_granularity || []);

  const minDate = parseDate(settings?.min_date);
  const maxDate = parseDate(settings?.max_date);

  const disabledDate = (current: Dayjs) => {
    if (!current) return false;
    if (minDate && current.isBefore(minDate, 'day')) {
      return true;
    }
    if (maxDate && current.isAfter(maxDate, 'day')) {
      return true;
    }
    return false;
  };

  const dateGranularity = settings?.date_granularity || [];

  let picker: 'date' | 'time' | 'month' | 'year' = 'date';
  let showTime: boolean | {} = false;
  let defaultPickerDate: Dayjs | undefined;

  if (dateGranularity.includes('year') && dateGranularity.includes('month') && !dateGranularity.includes('day')) {
    picker = 'month';
    defaultPickerDate = dayjs().date(1);
  } else if (dateGranularity.includes('year') && dateGranularity.length === 1) {
    picker = 'year';
    defaultPickerDate = dayjs().month(0).date(1);
  } else if (dateGranularity.includes('month') && dateGranularity.length === 1) {
    picker = 'month';
    defaultPickerDate = dayjs().date(1);
  } else {
    const timeUnits = ['hour', 'minutes', 'seconds'];
    const includesTimeUnits = timeUnits.some((unit) => dateGranularity.includes(unit));

    if (includesTimeUnits) {
      showTime = {
        showHour: dateGranularity.includes('hour'),
        showMinute: dateGranularity.includes('minutes'),
        showSecond: dateGranularity.includes('seconds'),
        defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
      };
    }
  }

  const handleChange: DatePickerProps<Dayjs>['onChange'] = (date) => {
    if (onChange) {
      onChange(date, date ? date.format(format) : '');
    }
  };

  if (settings?.capture_end_date) {
    return (
      <Form.Item
        name={['custom_fields', index, field.id]}
        className='w-full'
        getValueProps={(value) => {
          const startDate = value?.value ? dayjs(value.value) : null;
          const endDate = value?.end_date_time ? dayjs(value.end_date_time) : null;
          return { value: [startDate, endDate] };
        }}
        getValueFromEvent={(dates) => {
          if (dates && dates.length === 2) {
            return { value: dates[0], end_date_time: dates[1] };
          }
          return { value: null, end_date_time: null };
        }}>
        <RangePicker
          picker={picker}
          showTime={showTime}
          format={format}
          disabledDate={disabledDate}
          defaultPickerValue={defaultPickerDate}
          placeholder={[translate('components.customFieldsDrawer.startDate'), translate('components.customFieldsDrawer.endDate')]}
          className='w-full'
        />
      </Form.Item>
    );
  } else {
    return (
      <Form.Item>
        <DatePicker
          {...datePickerProps}
          value={value ? (dayjs.isDayjs(dayjs(value)) ? dayjs(value) : null) : null}
          onChange={handleChange}
          picker={picker}
          showTime={showTime}
          format={format}
          disabledDate={disabledDate}
          defaultPickerValue={defaultPickerDate}
          className='w-full'
        />
      </Form.Item>
    );
  }
};
