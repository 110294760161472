import { useProject } from 'views/projects/hooks/useProject';

import { Col, Row } from 'antd';

import { ContactCard } from '@aduvi/components/ContactCard/ContactCard';

export const ProjectContacts = () => {
  const { contactFields } = useProject();
  return (
    <Row gutter={[20, 20]} className='contact-container'>
      {contactFields?.map(
        (contact) =>
          contact?.field_data?.map((field) => (
            <Col span={24} key={field?.id}>
              <ContactCard key={contact?.id} contactFields={field} isEventContact={true} />
            </Col>
          )),
      )}
    </Row>
  );
};
