import { EQuoteEntityType, ILineItem, ITaxRate, QuoteTableDataType } from '@aduvi/types';

export const mapLineItemsToProducts = (lineItems: ILineItem[] | undefined, taxRates: ITaxRate[]) => {
  return (
    lineItems
      ?.filter((item) => item.product_id && item.product)
      .map((item) => ({
        id: item?.product?.id as string,
        name: item?.product!.name,
        quantity: Number(item?.quantity),
        price: Number(item?.price),
        tax_rate: taxRates?.length ? [taxRates[0].id] : [],
      })) || []
  );
};

export const mapLineItemsToPackages = (lineItems: ILineItem[] | undefined, taxRates: ITaxRate[]) => {
  return (
    lineItems
      ?.filter((item) => item.package_id && item.package)
      .map((item) => ({
        id: item?.package?.id as string,
        name: item?.package!.name,
        quantity: Number(item?.quantity),
        price: Number(item?.price) || Number(item?.package?.base_price),
        tax_rate: taxRates?.length ? [taxRates[0].id] : [],
      })) || []
  );
};

export const mapProductsToLineItems = (products: QuoteTableDataType[]) => {
  return products.map((product) => ({
    entity_id: product?.id?.toString(),
    entity_type: EQuoteEntityType.PRODUCT,
    name: product?.name,
    quantity: product?.quantity,
    tax_rates: product?.tax_rate,
    price: product?.price,
  }));
};

export const mapPackagesToLineItems = (packages: QuoteTableDataType[]) => {
  return packages.map((packageItem) => ({
    entity_id: packageItem?.id?.toString(),
    entity_type: EQuoteEntityType.PACKAGE,
    name: packageItem?.name,
    quantity: packageItem?.quantity,
    tax_rates: packageItem?.tax_rate,
    price: packageItem?.price,
  }));
};
