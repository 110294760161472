import { useTranslation } from 'react-i18next';

import { Button, Col, Form, InputNumber, Row } from 'antd';

import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';

export const PlantATree = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Row style={{ borderRadius: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
        <Col span={8} style={{ color: 'white', background: '#163A22', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} />
        <Col span={16} style={{ padding: '32px' }}>
          <Title level={4}>thebilliontreesproject.org</Title>
          <Paragraph>{translate('automations.plantATreeParagraph')}</Paragraph>
          <Row className='w-full' justify={'center'} align={'middle'} gutter={18}>
            <Button size='large' type='primary' className='mr-10' ghost style={{ color: '#163A22', width: '120px', border: '1px solid #163A22' }}>
              {translate('buttons.login')}
            </Button>
            <Button size='large' className='ml-10' style={{ background: '#163A22', color: 'white', width: '120px', border: '1px solid #163A22' }}>
              {translate('buttons.register')}
            </Button>
          </Row>
        </Col>
      </Row>
      <div className='mt-30'>{translate('automations.numberOfTreesToPlant')}</div>
      <Row gutter={18} className='mt-10'>
        <Col span={12}>
          <Form.Item name={'number_of_trees_to_plant'}>
            <InputNumber className='w-full' placeholder='2' />
          </Form.Item>
        </Col>
        <Col span={12} style={{ marginTop: '4px' }}>
          <Paragraph> x $0.50 ({translate('automations.peerTree')}) = $1.50</Paragraph>
        </Col>
      </Row>
    </>
  );
};
