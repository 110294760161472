import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Avatar, Card, Carousel, Col, Form, Row, Select, Spin, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { IBusinessUser } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getBusinessTeams } from 'store/features/business-slice';
import { getBusinessUsers } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const userFields = ['name', 'email', 'phone', 'picture', 'job_title'];
const { Option } = Select;

interface TeamsMap {
  [key: string]: IBusinessUser[];
}

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const [isMobileView, setIsMobileView] = useState(false);
  const { selectedBusiness } = useAppSelector((state) => state.business);

  const { users, loading } = useAppSelector((state) => state.user);
  const { businessTeams } = useAppSelector((state) => state.business);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || [];

  const displayedUsers = useMemo(() => {
    let filteredUsers = users?.data || [];

    if (
      form.getFieldValue(['widget_style', 0, 'display_mode']) === 'show_specific' &&
      form.getFieldValue(['widget_style', 0, 'selected_teams_or_users'])?.length > 0
    ) {
      const teamIds = form
        .getFieldValue(['widget_style', 0, 'selected_teams_or_users'])
        .filter((id: string) => id.startsWith('team_'))
        .map((id: string) => id.replace('team_', ''));

      const userIds = form
        .getFieldValue(['widget_style', 0, 'selected_teams_or_users'])
        .filter((id: string) => id.startsWith('user_'))
        .map((id: string) => id.replace('user_', ''));

      if (teamIds.length > 0) {
        filteredUsers = filteredUsers.filter((user) => user.teams?.some((team) => teamIds.includes(team.id.toString())));
      }

      if (userIds.length > 0) {
        filteredUsers = filteredUsers.filter((user) => userIds.includes(user.id.toString()));
      }
    }

    if (limitResults === 'show_all') {
      return filteredUsers;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return filteredUsers.slice(0, resultsPerPage);
    }
    return filteredUsers;
  }, [
    users?.data,
    form.getFieldValue(['widget_style', 0, 'display_mode']),
    form.getFieldValue(['widget_style', 0, 'selected_teams_or_users']),
    limitResults,
    resultsPerPage,
  ]);

  const entitySettings = (
    <div>
      <Typography.Text type='secondary'>{translate('widgets.users')}</Typography.Text>
      <Form.Item label='' name={['widget_style', 0, 'display_mode']} initialValue='show_all'>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'display_mode'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'display_mode'], value);
            form.setFieldValue(['widget_style', 0, 'selected_teams_or_users'], []);
          }}>
          <Option value='show_all'>Show All</Option>
          <Option value='show_specific'>Show Specific</Option>
        </Select>
      </Form.Item>

      {form.getFieldValue(['widget_style', 0, 'display_mode']) === 'show_specific' && (
        <Form.Item label='' name={['widget_style', 0, 'selected_teams_or_users']}>
          <Select
            mode='multiple'
            placeholder='Select teams or users'
            value={form.getFieldValue(['widget_style', 0, 'selected_teams_or_users'])}
            onChange={(value) => {
              form.setFieldValue(['widget_style', 0, 'selected_teams_or_users'], value);
            }}
            className='w-full'>
            {businessTeams?.teams.map((team) => (
              <Option key={`team_${team.id}`} value={`team_${team.id}`}>
                {team.name}
              </Option>
            ))}
            {users?.data.map((user) => (
              <Option key={`user_${user.id}`} value={`user_${user.id}`}>
                {user.first_name} {user.last_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Typography.Text type='secondary'>{translate('widgets.options')}</Typography.Text>
      {(visibleFields.includes('email') || visibleFields.includes('phone')) && (
        <Form.Item name={['widget_style', 0, 'email_phone_as_icons']} valuePropName='checked' className='mb-5'>
          <Switch
            checked={form.getFieldValue(['widget_style', 0, 'email_phone_as_icons'])}
            onChange={(checked) => {
              form.setFieldValue(['widget_style', 0, 'email_phone_as_icons'], checked);
            }}
          />
          <span style={{ marginLeft: 8 }}>{translate('widgets.emailAndPhoneAsIcons')}</span>
        </Form.Item>
      )}

      <Form.Item name={['widget_style', 0, 'group_by_team']} valuePropName='checked'>
        <Switch
          checked={form.getFieldValue(['widget_style', 0, 'group_by_team'])}
          onChange={(checked) => {
            form.setFieldValue(['widget_style', 0, 'group_by_team'], checked);
          }}
        />
        <span style={{ marginLeft: 8 }}>{translate('widgets.groupUsersByTeam')}</span>
      </Form.Item>
    </div>
  );

  const renderUsers = () => {
    if (form.getFieldValue(['widget_style', 0, 'group_by_team'])) {
      const teamsMap: TeamsMap = {};

      displayedUsers.forEach((user) => {
        user.teams?.forEach((team) => {
          if (!teamsMap[team.name]) {
            teamsMap[team.name] = [];
          }
          teamsMap[team.name].push(user);
        });
      });

      return Object.entries(teamsMap).map(([teamName, teamUsers]) => (
        <div key={teamName}>
          <Typography.Title level={4}>{teamName}</Typography.Title>
          <Row gutter={[20, 20]}>
            {teamUsers?.map((member, index) => (
              <Col
                key={index}
                xs={24}
                sm={12}
                md={8}
                lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                {renderUserCard(member)}
              </Col>
            ))}
          </Row>
        </div>
      ));
    } else {
      return (
        <Row gutter={[20, 20]}>
          {displayedUsers?.map((member, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
              {renderUserCard(member)}
            </Col>
          ))}
        </Row>
      );
    }
  };

  const renderUserCard = (member: IBusinessUser) => (
    <div className='team-user-card'>
      {selectedLayout === EWidgetLayout.ROWS ? (
        <Row gutter={[20, 20]} align='middle' className='users-card-row'>
          {visibleFields.includes('picture') && (
            <Col xs={24} sm={6} className='team-user-image'>
              <Avatar size={150} src={member.profile_picture} alt={member.first_name} />
            </Col>
          )}
          <Col xs={24} sm={18} className='users-details'>
            {visibleFields.includes('name') && (
              <Typography.Title
                level={4}
                style={{
                  fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                  color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                }}>
                {member.first_name} {member.last_name}
              </Typography.Title>
            )}
            {visibleFields.includes('job_title') && (
              <Typography.Text
                type='secondary'
                style={{
                  fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                  color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                }}>
                {member?.business?.position}
              </Typography.Text>
            )}
            <div className={`contact-info ${selectedLayout === EWidgetLayout.ROWS ? 'rows-layout' : 'columns-layout'}`}>
              {form.getFieldValue(['widget_style', 0, 'email_phone_as_icons']) ? (
                <div className='icon-row'>
                  {visibleFields.includes('email') && member.email && <MailOutlined />}
                  {visibleFields.includes('phone') && member.phone_number && <PhoneOutlined />}
                </div>
              ) : (
                <>
                  {visibleFields.includes('email') && member.email && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {member.email}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('phone') && member.phone_number && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {member.phone_number}
                    </Typography.Paragraph>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {visibleFields.includes('picture') && <Avatar size={150} src={member.profile_picture} alt={member.first_name} />}
          {visibleFields.includes('name') && (
            <Typography.Title
              level={4}
              style={{
                fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
              }}>
              {member.first_name} {member.last_name}
            </Typography.Title>
          )}
          {visibleFields.includes('job_title') && (
            <Typography.Text
              type='secondary'
              style={{
                fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
              }}>
              {member?.business?.position}
            </Typography.Text>
          )}
          <div className={`contact-info ${selectedLayout === EWidgetLayout.ROWS ? 'rows-layout' : 'columns-layout'}`}>
            {form.getFieldValue(['widget_style', 0, 'email_phone_as_icons']) ? (
              <div className='icon-row'>
                {visibleFields.includes('email') && member.email && (
                  <MailOutlined
                    style={{
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}
                  />
                )}
                {visibleFields.includes('phone') && member.phone_number && (
                  <PhoneOutlined
                    style={{
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                {visibleFields.includes('email') && member.email && (
                  <Typography.Paragraph
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {member.email}
                  </Typography.Paragraph>
                )}
                {visibleFields.includes('phone') && member.phone_number && (
                  <Typography.Paragraph
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {member.phone_number}
                  </Typography.Paragraph>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    if (!form.getFieldValue(['widget_style', 0, 'layout'])) {
      form.setFieldValue(['widget_style', 0, 'layout'], EWidgetLayout.COLUMNS);
    }

    dispatch(getBusinessTeams({ businessId: selectedBusiness?.id }));
  }, [dispatch, selectedBusiness?.id]);

  const onGetBusinessUsers = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness) return;
      dispatch(
        getBusinessUsers({
          business_id: selectedBusiness?.id,
          params: {
            page,
            size,
            team_ids: '',
            search: '',
          },
        }),
      );
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    onGetBusinessUsers();
  }, [selectedBusiness?.id]);

  return (
    <Spin spinning={loading}>
      <Row className='design-tab-wrapper' style={backgroundStyle}>
        <Col span={14}>
          <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
            {selectedLayout === EWidgetLayout.CARDS ? (
              <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
                {displayedUsers?.map((member, index) => (
                  <div key={index} className='team-member-card'>
                    {renderUserCard(member)}
                  </div>
                ))}
              </Carousel>
            ) : (
              renderUsers()
            )}
          </Card>
        </Col>
        <Col span={1} className='icons-container'>
          <RightSidebar
            form={form}
            onMobileView={() => setIsMobileView((prevState) => !prevState)}
            isMobileView={isMobileView}
            fields={userFields}
            entitySettings={entitySettings}
          />
        </Col>
      </Row>
    </Spin>
  );
};
