import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

import { Button, Divider, Flex, Form, Modal, Rate, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusiness } from '@aduvi/hooks';

import { rateTicket, reOpenTicket, setSelectedTicket } from 'store/features/ticket-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ChatModal } from '../@components/ticket-chat-modal/ChatModal';
import { TicketHeaderModal } from '../@components/ticket-header-modal/TicketHeaderModal';

interface IProps {
  setIsTicketConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}

const customIcons: Record<number, React.ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

export const TicketConfirmationModal = ({ setIsTicketConfirmationModalOpen }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { selectedTicket, updating } = useAppSelector((state) => state.ticket);

  const reOpenSupportTicket = () => {
    if (!selectedBusiness?.id || !selectedTicket?.id) return;
    dispatch(reOpenTicket({ business_id: selectedBusiness?.id, ticket_id: selectedTicket?.id }));
  };

  const onRateSupportTicket = () => {
    if (!selectedBusiness?.id || !selectedTicket?.id) return;

    dispatch(rateTicket({ business_id: selectedBusiness?.id, ticket_id: selectedTicket?.id, rate: form.getFieldValue('rate') }));
  };

  const onClose = () => {
    setSelectedTicket(undefined);
    setIsTicketConfirmationModalOpen(false);
  };

  return (
    <Modal
      width={800}
      open={true}
      styles={{ content: { borderRadius: 10 } }}
      bodyStyle={{ height: 550 }}
      centered
      title={<TicketHeaderModal />}
      footer={
        <Row justify={'end'}>
          <Divider />
          <Button onClick={reOpenSupportTicket} type='primary' loading={updating}>
            {translate('support.contact.reOpenTicket')}
          </Button>
        </Row>
      }
      onCancel={onClose}>
      <div className='w-full'>
        <ChatModal style={{ maxHeight: '400px', minHeight: '400px', overflowY: 'auto' }} />

        <Row style={{ flexDirection: 'column', alignItems: 'center' }} className='w-full mt-20' gutter={[20, 20]}>
          <Typography.Text className='w-full' style={{ backgroundColor: '#F6FFED', color: '#52C41A', textAlign: 'center' }}>
            {translate('support.contact.tickedMarkedResolved')}
          </Typography.Text>

          <Typography.Text> {translate('support.contact.howDidWeDo')}</Typography.Text>
          <Form.Item name={'rate'}>
            <Flex gap='middle' vertical>
              <Rate
                character={({ index = 0 }) => customIcons[index + 1]}
                onChange={(value) => {
                  form.setFieldValue('rate', value);
                  onRateSupportTicket();
                }}
                defaultValue={selectedTicket?.rating}
              />
            </Flex>
          </Form.Item>
        </Row>
      </div>
    </Modal>
  );
};
