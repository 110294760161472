import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { IPaginatedResponse } from '@aduvi/types';
import {
  ICreateProductPayload,
  IDeleteProductPayload,
  IEditProductPayload,
  IGetProductByIdPayload,
  IGetProductsPayload,
  IProduct,
  IProductState,
} from '@aduvi/types/products';

import { apiSlice } from 'store/api/apiSlice';
import * as ProductServices from 'store/services/product.service';

const initialState: IProductState = {
  products: undefined,
  productCategories: {
    data: [],
    creating: false,
    loading: false,
  },
  selectedProduct: undefined,
  loadingProduct: false,
  loading: false,
  creating: false,
  updating: false,
};

export const createProduct = createAsyncThunk('product/create', async (params: ICreateProductPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.PRODUCT }]));
    return await ProductServices.createProduct(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProducts = createAsyncThunk('product/get-all', async (params: IGetProductsPayload, { rejectWithValue }) => {
  try {
    return await ProductServices.getProducts(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProductCategories = createAsyncThunk('product/get-categories', async (business_id: string, { rejectWithValue }) => {
  try {
    return await ProductServices.getProductCategories(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createProductCategory = createAsyncThunk(
  'product/create-product-category',
  async (params: { business_id: string; name: string }, { rejectWithValue }) => {
    try {
      return await ProductServices.createProductCategory(params.business_id, params.name);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editProduct = createAsyncThunk('product/edit', async (params: IEditProductPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.PRODUCT }]));
    return await ProductServices.editProduct(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProductById = createAsyncThunk('product/get-productById', async (params: IGetProductByIdPayload, { rejectWithValue }) => {
  try {
    return await ProductServices.getProductById(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteProduct = createAsyncThunk('product/delete', async (params: IDeleteProductPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.PRODUCT }]));
    return await ProductServices.deleteProduct(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const cloneProduct = createAsyncThunk('product/clone', async (params: { businessId: string; productId: string }, { rejectWithValue }) => {
  try {
    return await ProductServices.cloneProduct(params.businessId, params.productId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetProductState: () => initialState,
    setSelectedProduct: (state, action: PayloadAction<IProduct | undefined>) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProduct.pending, (state) => {
        state.creating = true;
      })
      .addCase(createProduct.fulfilled, (state, { payload }) => {
        if (state.products) {
          state.products.data = [...state.products.data, payload.data];
          state.products.total = state.products.total + 1;
        }
        state.creating = false;
      })
      .addCase(createProduct.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.loading = false;

        if (payload.from === state?.products?.from) state.products = undefined;

        state.products = { ...payload, data: [...(state.products?.data || []), ...payload.data] } as IPaginatedResponse<IProduct>;
      })
      .addCase(getProducts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProductById.pending, (state) => {
        state.loadingProduct = true;
      })
      .addCase(getProductById.fulfilled, (state, { payload }) => {
        state.loadingProduct = false;
        state.selectedProduct = payload.data;
      })
      .addCase(getProductById.rejected, (state) => {
        state.loadingProduct = false;
      })
      .addCase(getProductCategories.pending, (state) => {
        state.productCategories.loading = true;
      })
      .addCase(getProductCategories.fulfilled, (state, { payload }) => {
        state.productCategories.data = payload.data;
        state.productCategories.loading = false;
      })
      .addCase(getProductCategories.rejected, (state) => {
        state.productCategories.loading = false;
      })

      .addCase(createProductCategory.pending, (state) => {
        state.productCategories.creating = true;
      })
      .addCase(createProductCategory.fulfilled, (state, { payload }) => {
        state.productCategories.data = [...state.productCategories.data, payload.data];
        state.productCategories.creating = false;
      })
      .addCase(createProductCategory.rejected, (state) => {
        state.productCategories.creating = false;
      })
      .addCase(editProduct.pending, (state) => {
        state.updating = true;
      })
      .addCase(editProduct.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.products) {
          state.products.data = state.products?.data.map((item) => {
            return item.id === payload.data.id ? payload.data : item;
          });
        }
      })
      .addCase(editProduct.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loadingProduct = true;
      })
      .addCase(deleteProduct.fulfilled, (state, { meta }) => {
        if (state.products) {
          state.products = {
            ...state.products,
            data: state.products.data.filter((products) => products.id !== meta.arg.product_id),
          };
        }
        state.loadingProduct = false;
      })
      .addCase(deleteProduct.rejected, (state) => {
        state.loadingProduct = false;
      })
      .addCase(cloneProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.products) {
          state.products = {
            ...state.products,
            data: [...state.products.data, payload.data],
          };
        }
      })
      .addCase(cloneProduct.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const productReducer = productSlice.reducer;
export const { resetProductState, setSelectedProduct } = productSlice.actions;
