import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useSettingsDetailsActions = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  return { dispatch, form, translate };
};

export const useSettingsDetailsState = () => {
  const { selectedBusiness, editing } = useAppSelector((state) => state.business);

  return { selectedBusiness, editing };
};

export const useSettingsDetails = (form: FormInstance) => {
  const { selectedBusiness } = useSettingsDetailsState();

  useEffect(() => {
    if (!selectedBusiness) return;

    form.setFieldsValue({ ...selectedBusiness });
  }, [selectedBusiness?.id]);
};
