import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Select } from 'antd';
import Input from 'antd/es/input/Input';

import { EImageExtensions } from '@aduvi/types';

export const ImageSettings = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        settings: {
          allowed_extensions: [],
          maximum_upload_size: '',
        },
      }}>
      <Form.Item
        className='mt-20'
        name={['settings', 'allowed_extensions']}
        label={translate('components.customFieldsDrawer.allowedExtensions')}
        rules={[{ required: true }]}>
        <Select
          mode='multiple'
          placeholder={translate('components.customFieldsDrawer.extensions')}
          options={Object.values(EImageExtensions)?.map((extension) => ({ label: extension, value: extension }))}
          onChange={(value) => {
            const currentSettings = form.getFieldValue('settings') || {};
            form.setFieldValue('settings', {
              ...currentSettings,
              allowed_extensions: value,
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={['settings', 'maximum_upload_size']}
        label={translate('components.customFieldsDrawer.uploadSizeLabel')}
        rules={[{ required: true }]}>
        <Input
          placeholder={translate('components.customFieldsDrawer.uploadSize')}
          onChange={(e) => {
            const currentSettings = form.getFieldValue('settings') || {};
            form.setFieldValue('settings', {
              ...currentSettings,
              maximum_upload_size: e.target.value,
            });
          }}
        />
      </Form.Item>
    </Form>
  );
};
