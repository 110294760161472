import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Row } from 'antd';

import { useAppSelector } from 'store/hooks';

interface MentionListProps {
  command: (item: { id: string; label: string; triggerId: string; node_id: string }) => void;
  query: string;
}

export const MentionList = forwardRef((props: MentionListProps, ref) => {
  const { mentions } = useAppSelector((state) => state.automation);
  const [filteredMentions, setFilteredMentions] = useState(mentions);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = filteredMentions[index];

    if (item) {
      props.command({
        id: `${item.id}`,
        label: `${item.name}`,
        triggerId: item.triggerId || '',
        node_id: item.node_id,
      });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + filteredMentions.length - 1) % filteredMentions.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % filteredMentions.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: { key: string } }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    const filtered = mentions.filter((item) => item.name.toLowerCase().includes(props.query.toLowerCase()));
    setFilteredMentions(filtered);
  }, [props.query, mentions]);

  useEffect(() => setSelectedIndex(0), [filteredMentions.length]);

  return (
    <div className='items'>
      {filteredMentions?.length ? (
        <>
          {filteredMentions.map((item, index) => (
            <Row className={`item ${index === selectedIndex ? 'is-selected' : ''}`} key={index} onClick={() => selectItem(index)}>
              {`${item.name}`}
            </Row>
          ))}
        </>
      ) : (
        <div className='item'>No result</div>
      )}
    </div>
  );
});

MentionList.displayName = 'MentionList';
