import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusiness } from '@aduvi/hooks';

import { getBuilderTemplates } from 'store/features/proposals-slice';
import { useAppDispatch } from 'store/hooks';

import { Design } from './tabs/Design';
import { ProposalPreviewModal } from './ProposalPreviewModal';

export const FullScreenProposal = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const [isPreviewProposalModalOpen, setIsPreviewProposalModalOpen] = useState(false);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getBuilderTemplates(selectedBusiness?.id));
  }, [dispatch, selectedBusiness?.id]);

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='booking-form-wrapper'>
        <Col span={24} className='header'>
          <Row justify={'space-between'} className='pl-15 pr-15 pt-15' style={{ height: '54px' }}>
            <Breadcrumb
              className='ml-10'
              items={[
                { title: translate('proposals.proposals'), onClick: () => navigate('/proposals'), className: 'cursor-pointer' },
                {
                  title: 'Templates',
                },
              ]}
            />
          </Row>
        </Col>
        <Design onProposalClick={() => setIsPreviewProposalModalOpen(true)} />
      </Col>
      {isPreviewProposalModalOpen ? (
        <ProposalPreviewModal open={isPreviewProposalModalOpen} title={'Basic Quote'} onClose={() => setIsPreviewProposalModalOpen(false)} />
      ) : null}
    </Form>
  );
};
