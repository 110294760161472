import { ReactNode, useMemo } from 'react';

import { InputNumber, InputNumberProps } from 'antd';

import { useBusiness, useBusinessCurrency } from '@aduvi/hooks';
import { ECurrencySignPosition } from '@aduvi/types';

export const InputPrice = (props: InputNumberProps & { addon?: ReactNode }) => {
  const selectedBusiness = useBusiness();
  const businessCurrency = useBusinessCurrency();

  const addonProps = useMemo(() => {
    if (selectedBusiness?.currency_sign_position === ECurrencySignPosition.BEFORE) {
      return { addonBefore: props.addon || businessCurrency?.format };
    }
    return { addonAfter: props.addon || businessCurrency?.format };
  }, [selectedBusiness?.currency_sign_position, businessCurrency?.format, props.addon]);

  return <InputNumber {...props} {...addonProps}></InputNumber>;
};
