import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IBusinessInitialState } from '@aduvi/types/preset-business';

import * as PresetBusinesses from 'store/services/preset-businesses';

const initialState: IBusinessInitialState = {
  data: [],
  loading: false,
  error: undefined,
};

export const getPresetBusinesses = createAsyncThunk('businesses/presetBusinesses', async (_, { rejectWithValue }) => {
  try {
    return await PresetBusinesses.getPresetBusinesses();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const presetBusinessesSlice = createSlice({
  name: 'presetBusinesses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPresetBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresetBusinesses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(getPresetBusinesses.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      });
  },
});

export const presetBusinessesReducer = presetBusinessesSlice.reducer;
