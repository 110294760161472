export const dummyData = [
  {
    id: '1',
    image: 'https://www.pixelstalk.net/wp-content/uploads/images6/5K-Wallpaper-HD-Free-download.jpg',
    title: 'Beautiful Mountain',
    description: 'A breathtaking view of mountains under the clear sky.',
  },
  {
    id: '2',
    image: 'https://pixexid.com/api/download/image/4k-ultra-hd-wallpaper-with-abstract-liquid-metallic-design-rqruw5xy.jpeg',
    title: 'Starry Night',
    description: 'A serene night sky full of stars in the middle of nowhere.',
  },
  {
    id: '3',
    image: 'https://i.pinimg.com/originals/6a/00/3e/6a003e75cd4031433fd761586e169025.jpg',
    title: 'Sunset at the Beach',
    description: 'A beautiful sunset on a tropical beach with orange and purple hues.',
  },
  {
    id: '4',
    image: 'https://asset.gecdesigns.com/img/wallpapers/beautiful-fantasy-wallpaper-ultra-hd-wallpaper-4k-sr10012418-1706506236698-cover.webp',
    title: 'Forest Path',
    description: 'A scenic path through a dense, misty forest in the morning.',
  },
  {
    id: '5',
    image:
      'https://images.unsplash.com/photo-1542281286-9e0a16bb7366?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDk2NDR8MHwxfGFsbHwxfHx8fHx8fHwxNjk1OTczMDI0fA&ixlib=rb-1.2.1&q=80&w=1080',
    title: 'City Skyline',
    description: 'A stunning view of a modern city skyline at dusk, with vibrant lights.',
  },
  {
    id: '6',
    image: 'https://c4.wallpaperflare.com/wallpaper/764/505/66/baby-groot-4k-hd-superheroes-wallpaper-preview.jpg',
    title: 'The Animal',
    description: 'This animall lives in the jungle and is very fast when he runs.',
  },
];
