import { Card, Col, Empty } from 'antd';
import Meta from 'antd/es/card/Meta';

import { IAcademy } from '@aduvi/types/academy';

export const AcademyCard = ({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: string;
  academy: IAcademy;
  setSupportModalVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '300px',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <Col>
      <h3 style={contentStyle}>
        <Card
          style={{ width: 250, height: 300, cursor: 'pointer' }}
          bodyStyle={{ maxHeight: '150px', minHeight: '150px', overflowX: 'auto' }}
          cover={image ? <img alt='example' src={image} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
          <Meta title={title} description={<div dangerouslySetInnerHTML={{ __html: description }} />} />
        </Card>
      </h3>
    </Col>
  );
};
