import { UIEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Select } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IPackage } from '@aduvi/types';

import { getServices, resetServicesState } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  addPackage: (item: IPackage) => void;
  existingPackageIds?: string[];
}

export const PackagesList = ({ addPackage, existingPackageIds }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { services, loading: loadingServices } = useAppSelector((state) => state.services);
  const hasPermissionToViewServices = useUserPermissionCheck(PERMISSIONS.SERVICES.VIEW);

  const packages = useMemo(() => services?.data.map((item) => item.packages).flat(), [services?.data]);

  const handleScrollServices = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;

      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (!services?.current_page || !services.next_page_url) return;
        onGetServices(services?.current_page + 1, 10);
      }
    },
    [services],
  );

  const onGetServices = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id || !hasPermissionToViewServices) return;
      dispatch(getServices({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    dispatch(resetServicesState());
    onGetServices(1, 50);
  }, [selectedBusiness?.id]);

  return (
    <Select
      bordered={false}
      value={translate('quotes.addPackage')}
      suffixIcon={null}
      className='w-full mt-10 mb-10'
      loading={loadingServices}
      onPopupScroll={handleScrollServices}>
      {packages
        ?.filter((item) => !existingPackageIds || !existingPackageIds?.includes(item.id))
        .map((item, index) => (
          <Select.Option key={item?.id} style={{ borderBottom: index !== packages.length - 1 ? '1px dashed #5194D870' : '0px' }}>
            <Row justify={'space-between'} onClick={() => addPackage(item)}>
              <Col>{item.name}</Col>
              <Col>${item.flat_price ?? item.base_price}</Col>
            </Row>
          </Select.Option>
        ))}
    </Select>
  );
};
