import { useAppSelector } from 'store/hooks';

export const usePartnerLayout = () => {
  const { partnerLayout } = useAppSelector((state) => state.common);

  return partnerLayout;
};

export const useActiveTheme = () => {
  const { activeTheme } = useAppSelector((state) => state.common);

  return activeTheme;
};
