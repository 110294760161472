import axios from '@aduvi/config/axios';
import {
  ICreateTaxRatePayload,
  ICreateTaxSettingsPayload,
  IDeleteTaxRatePayload,
  IResponse,
  ITaxRate,
  ITaxSettings,
  IUpdateTaxRatePayload,
  IUpdateTaxSettingPayload,
} from '@aduvi/types';
export const getTaxRates = async (business_id: string): Promise<IResponse<ITaxRate[]>> => {
  return await axios.get(`/businesses/${business_id}/tax/rates`);
};
export const createTaxRate = async (payload: ICreateTaxRatePayload): Promise<IResponse<ITaxRate>> => {
  return await axios.post(`/businesses/${payload.business_id}/tax/rates`, payload.body);
};
export const updateTaxRate = async (payload: IUpdateTaxRatePayload): Promise<IResponse<ITaxRate>> => {
  return await axios.put(`/businesses/${payload.business_id}/tax/rates/${payload.tax_rate_id}`, payload.body);
};
export const deleteTaxRate = async (payload: IDeleteTaxRatePayload): Promise<void> => {
  axios.delete(`/businesses/${payload.business_id}/tax/rates/${payload.tax_rate_id}?title=${payload.tax_name}`);
};
export const getTaxSettings = async (business_id: string): Promise<IResponse<ITaxSettings>> => {
  return await axios.get(`/businesses/${business_id}/tax/settings`);
};
export const createTaxSettings = async (payload: ICreateTaxSettingsPayload): Promise<IResponse<ITaxSettings>> => {
  return await axios.post(`/businesses/${payload.business_id}/tax/settings`, payload.body);
};
export const updateTaxSettings = async (payload: IUpdateTaxSettingPayload): Promise<IResponse<ITaxSettings>> => {
  return await axios.put(`/businesses/${payload.business_id}/tax/settings/${payload.tax_settings_id}`, payload.body);
};
