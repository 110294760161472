export const CodeIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 36 29' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ marginRight: '7px' }}>
      <path
        d='M22.4494 0.0610875C21.735 -0.15983 20.9812 0.236688 20.7619 0.956085L12.6619 27.2396C12.4425 27.9589 12.8362 28.718 13.5506 28.9389C14.265 29.1598 15.0187 28.7633 15.2381 28.0439L23.3381 1.76045C23.5575 1.04105 23.1637 0.282005 22.4494 0.0610875ZM26.4656 6.77357C25.9537 7.32303 25.9819 8.18404 26.5275 8.69385L32.6756 14.5L26.5275 20.3062C25.9819 20.8216 25.9537 21.6826 26.4656 22.2264C26.9775 22.7702 27.8325 22.8042 28.3725 22.2887L35.5725 15.4913C35.8425 15.2364 36 14.8739 36 14.5C36 14.1261 35.8481 13.7636 35.5725 13.5087L28.3725 6.71126C27.8269 6.19578 26.9719 6.22411 26.4656 6.77357ZM9.53438 6.77357C9.0225 6.22411 8.1675 6.19578 7.6275 6.71126L0.4275 13.5087C0.151875 13.7636 0 14.1261 0 14.5C0 14.8739 0.151875 15.2364 0.4275 15.4913L7.6275 22.2887C8.17313 22.8042 9.02812 22.7759 9.53438 22.2264C10.0406 21.677 10.0181 20.816 9.4725 20.3062L3.32437 14.5L9.4725 8.69385C10.0181 8.17837 10.0462 7.31736 9.53438 6.77357Z'
        fill='black'
      />
    </svg>
  );
};
