import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const personalizedViewOrigin = EPersonalizedViewOrigin.ORDERS;

export const Orders = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes.data.ORDER} personalizedViewOrigin={personalizedViewOrigin} />;
};
