import { createSelector } from '@reduxjs/toolkit';

import { IFolder } from '@aduvi/types/file';
import { flattenFolders, folderMatchesSearch } from '@aduvi/utils/files-helper';

import { RootState } from '../store';

const selectFolderData = (state: RootState) => state.file.folders.data;

export const selectAllFolders = createSelector([selectFolderData], (folders): IFolder[] => {
  return flattenFolders(folders);
});

export const selectFoldersBySearchKey = createSelector([selectFolderData], (folders) => (searchKey: string | null) => {
  return folders?.filter((folder) => {
    return !searchKey || folderMatchesSearch(folder, searchKey);
  });
});
