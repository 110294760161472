import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Divider, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Paragraph } from '@aduvi/components/Paragraph';
import { useBusiness } from '@aduvi/hooks';

import { createBuilderTemplates } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { SelectTemplateCategory } from './SelectTemplateCategory';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CreateTemplateModal = ({ open, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { entityTypes } = useAppSelector((state) => state.entity);

  const entityTypesFiltered = [entityTypes.data.JOB, entityTypes.data.ORDER, entityTypes.data.PROJECT];

  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        if (!selectedBusiness?.id) return;
        dispatch(
          createBuilderTemplates({
            business_id: selectedBusiness?.id,
            body: {
              ...form.getFieldsValue(),
              name: form.getFieldValue('name'),
              builder_category_ids: form.getFieldValue('builder_category_ids'),
              type: 'PROPOSAL',
              screenshot: {
                image_url: '',
              },
              file_reference: {
                file_name: '',
                file_url: `<div class="is-section is-section-100 is-box type-system-ui last-box min-height-60 scroll-target initial-div-class">
    <div class="is-overlay">

    </div>
    <div class="is-container v2 size-18 leading-14 is-content-1300">
        <div class="row">
            <div class="column">
                <h1 class="leading-11 size-88 text-center">Start building...</h1>
            </div>
        </div>
    </div>
</div>
<link data-name="contentstyle" data-class="type-system-ui" href="assets/styles/type-system-ui.css" rel="stylesheet">`, // to be changed
              },
            },
          }),
        )
          .unwrap()
          .then((response) => {
            navigate(`/template-builder?templateId=${response.data?.id}`);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <Modal open={open} title='Create template' okText='Create' onClose={onClose} onOk={onSubmit} onCancel={onClose}>
      <Form form={form} requiredMark={false} layout='vertical' className='mt-30'>
        <Form.Item name={'name'} label='Template name' rules={[{ required: true, message: 'Field is required!' }]}>
          <Input />
        </Form.Item>

        <Form.Item name={'target_entity_type_id'} label='Target entity' rules={[{ required: true, message: 'Field is required!' }]}>
          <Select
            options={entityTypesFiltered.map((item) => ({ label: translate(`components.customFieldsDrawer.type.${item?.name}`), value: item?.id }))}
          />
        </Form.Item>
        <Form.Item name={'description'} label='Description' rules={[{ required: true, message: 'Field is required!' }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item name={'builder_category_ids'} label='Category' rules={[{ required: true, message: 'Field is required!' }]}>
          <SelectTemplateCategory mode='multiple' />
        </Form.Item>
        <Divider />
        <Paragraph style={{ fontSize: '12px', fontWeight: 400, color: '#9B9B9B' }}>Display settings</Paragraph>
        <Form.Item name={'page_title'} label='Page title' rules={[{ required: true, message: 'Field is required!' }]}>
          <Input />
        </Form.Item>
        <Divider />
      </Form>
    </Modal>
  );
};
