import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { Typography } from 'antd';
import './Time.scss';

import { useActiveTheme, useUser } from '@aduvi/hooks';

const pickTextColorBasedOnBgColorSimple = (bgColor: string, lightColor: string, darkColor: string): string => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

const calculateBgColorAtPosition = (element: HTMLElement, gradientStart: string, gradientEnd: string): string => {
  const rect = element.getBoundingClientRect();
  const gradientHeight = window.innerHeight;
  const gradientPosition = rect.top + rect.height / 2;
  const gradientPercentage = gradientPosition / gradientHeight;

  const interpolate = (start: number, end: number, factor: number) => Math.round(start + (end - start) * factor);

  const startColor = gradientStart.slice(1);
  const endColor = gradientEnd.slice(1);

  const startR = parseInt(startColor.slice(0, 2), 16);
  const startG = parseInt(startColor.slice(2, 4), 16);
  const startB = parseInt(startColor.slice(4, 6), 16);

  const endR = parseInt(endColor.slice(0, 2), 16);
  const endG = parseInt(endColor.slice(2, 4), 16);
  const endB = parseInt(endColor.slice(4, 6), 16);

  const r = interpolate(startR, endR, gradientPercentage);
  const g = interpolate(startG, endG, gradientPercentage);
  const b = interpolate(startB, endB, gradientPercentage);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

export const Time = () => {
  const user = useUser();
  const activeTheme = useActiveTheme();
  const { t } = useTranslation();

  const [currentTime, setCurrentTime] = useState(dayjs());
  const [greetingMessage, setGreetingMessage] = useState<string>('');
  const [textColor, setTextColor] = useState<string>('#000');
  const timeRef = useRef<HTMLDivElement>(null);

  const updateGreetingMessage = (time: Dayjs) => {
    const hour = time.hour();
    const greetingMessage =
      hour >= 0 && hour < 12
        ? t('dashboard.widgets.time.morning')
        : hour >= 12 && hour < 18
        ? t('dashboard.widgets.time.afternoon')
        : t('dashboard.widgets.time.evening');

    setGreetingMessage(greetingMessage);
  };

  useEffect(() => {
    const now = dayjs();
    setCurrentTime(now);
    updateGreetingMessage(now);
    const intervalId = setInterval(() => {
      const now = dayjs();
      setCurrentTime(now);
      updateGreetingMessage(now);
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const updateTextColor = () => {
      if (timeRef.current) {
        const bgColor = calculateBgColorAtPosition(timeRef.current, activeTheme?.bodyBg || '#ffffff', activeTheme?.dashboardBg || '#000000');
        const calculatedTextColor = pickTextColorBasedOnBgColorSimple(bgColor, '#FFF', '#000');
        setTextColor(calculatedTextColor);
      }
    };

    const observer = new IntersectionObserver(updateTextColor, { threshold: [0, 1] });
    if (timeRef.current) {
      observer.observe(timeRef.current);
    }

    window.addEventListener('resize', updateTextColor);

    return () => {
      if (timeRef.current) {
        observer.unobserve(timeRef.current);
      }
      window.removeEventListener('resize', updateTextColor);
    };
  }, [activeTheme]);

  return (
    <div className='currentTime-wrapper' ref={timeRef}>
      <Typography.Paragraph style={{ color: textColor }} className='currentTime'>
        {currentTime.format('HH:mm a')}
      </Typography.Paragraph>
      <Typography.Text style={{ color: textColor }}>{`${greetingMessage}, ${user?.first_name || 'User'}`}</Typography.Text>
    </div>
  );
};

export default Time;
