import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';
import './Files.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { EFieldDataValueType } from '@aduvi/types';
import { ICustomField } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

export const Files = () => {
  const { user } = useAppSelector((state) => state.clientPortalAuth);
  const { jobId } = useParams();

  return (
    <Row className='files-wrapper' justify={'center'} align={'top'}>
      <Col span={12}>
        {jobId ? (
          <FileSection
            title={String(user?.jobs?.selectedJob?.custom_fields.find((field) => field.title === 'Event Name')?.field_data?.[0]?.value) || ''}
            files={
              user?.jobs?.selectedJob?.custom_fields.filter(
                (field) =>
                  (field?.value_type === EFieldDataValueType.FIELD_DATA_FILES || field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES) &&
                  field?.field_data?.[0]?.url,
              ) || []
            }
          />
        ) : (
          <FileSection
            title={String(user?.orders?.selectedOrder?.custom_fields?.find((field) => field?.title === 'Order Id')?.field_data?.[0]?.value) || ''}
            files={
              user?.orders?.selectedOrder?.custom_fields?.filter(
                (field) => field?.value_type === EFieldDataValueType.FIELD_DATA_FILES && field?.field_data[0]?.url,
              ) || []
            }
          />
        )}
      </Col>
    </Row>
  );
};

const FileSection = ({ title, files }: { title?: string; files: ICustomField[] }) => {
  const { t: translate } = useTranslation();

  return (
    <Row className='file mb-10'>
      <Col span={24}>
        <Typography.Title level={title === 'Event Name' ? 4 : 5}>{title}</Typography.Title>
        {files.length > 0 ? (
          files?.map((file) => (
            <Row key={file?.id} className=' mb-10' justify='space-between' align='middle'>
              <Col>{file?.title}</Col>
              <Col>
                <CustomFieldValue field={file} />
              </Col>
            </Row>
          ))
        ) : (
          <Typography.Paragraph className='fs-10' type='secondary'>
            {translate('clientPortal.noFiles')}
          </Typography.Paragraph>
        )}
      </Col>
    </Row>
  );
};
