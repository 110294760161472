import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Drawer, Form, Space, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { ContentBoxEditor } from '@aduvi/components/ContentBoxEditor/ContentBoxEditor';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';

import { editBuilderBlock, getBuilderBlockById, getBuilderBlocks } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Configure } from './tabs/Configure';

export const BlocksEditorModal = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const {
    builder_blocks: { selectedBlock, loading, loadingBlock },
  } = useAppSelector((state) => state.proposals);

  const [showDrawer, setShowDrawer] = useState(false);
  const [settingsTabKey, setSettingsTabKey] = useState('configure');

  const tabItems = [
    {
      key: 'configure',
      label: 'Configure',
      children: <Configure />,
    },
  ];

  const onSave = (html: string, thumbnail: string) => {
    if (!selectedBusiness?.id || !selectedBlock?.id) return;

    dispatch(
      editBuilderBlock({
        builderBlockId: selectedBlock?.id,
        body: {
          business_id: selectedBusiness?.id,
          body: {
            block_name: form.getFieldValue('block_name'),
            builder_category_ids: form.getFieldValue('builder_category_ids'),
            file_reference: {
              type: 'html',
              content: html,
              image: thumbnail,
            },
          },
        },
      }),
    );
  };

  const queryParams = new URLSearchParams(location.search);
  const blockId = queryParams.get('blockId');

  useEffect(() => {
    if (!selectedBusiness?.id || !blockId) return;
    dispatch(getBuilderBlockById({ businessId: selectedBusiness?.id, blockId }))
      .unwrap()
      .then((response) => {
        form.setFieldsValue({
          ...response.data,
          builder_category_ids: response.data?.categories.map((item) => item.id),
        });
      })
      .catch(() => {});
  }, [location, selectedBusiness?.id, blockId]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getBuilderBlocks({ business_id: selectedBusiness?.id }))
      .unwrap()
      .then((data) => localStorage.setItem('my_builder_blocks', JSON.stringify(data.data)));
  }, [dispatch, selectedBusiness?.id]);

  if (loading || loadingBlock || Boolean(blockId && !selectedBlock?.file_reference?.content))
    return <Spin spinning className='w-full mt-30' size='large' />;

  return (
    <>
      <ContentBoxEditor
        onBack={() => {
          navigate(-1);
        }}
        onSave={onSave}
        value={selectedBlock?.file_reference?.content}
        onTabChange={setSettingsTabKey}
        onDrawerOpen={() => setShowDrawer(true)}
        isBuilderBlock
        hideActivity
      />
      <Drawer
        title={'Settings'}
        open={showDrawer}
        width={720}
        styles={{ body: { ...DRAWER_BODY_STYLE, background: '#F9FCFF' } }}
        onClose={() => setShowDrawer(false)}
        extra={
          <Space>
            <Button icon={<CloseOutlined />} type='text' onClick={() => setShowDrawer(false)} />
          </Space>
        }>
        <Spin spinning={false}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Tabs activeKey={settingsTabKey} items={tabItems} onChange={setSettingsTabKey} />
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};
