import { useState } from 'react';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';
import { agendas } from 'views/widgets/constants/constants';

import { Card, Carousel, Col, Row, Tag, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

export const Design = ({ form }: { form: FormInstance }) => {
  const [isMobileView, setIsMobileView] = useState(false);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  return (
    <Row className='agenda-design-tab-wrapper' style={backgroundStyle}>
      <Col span={14}>
        <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {agendas?.map((agenda, index) => (
                <div key={index} className='agenda-card'>
                  <img alt={agenda.presenter} src={agenda.image} width={100} height={100} style={{ borderRadius: '50%', marginBottom: '10px' }} />
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {agenda.title}
                  </Typography.Title>
                  <Typography.Paragraph
                    className='fs-16 mb-0'
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {agenda.presenter}
                  </Typography.Paragraph>
                  <Tag
                    color='default'
                    className='my-10'
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {agenda.time}
                  </Tag>
                  <Typography.Paragraph
                    type='secondary'
                    style={{
                      fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                      color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                    }}>
                    {agenda.description}
                  </Typography.Paragraph>
                </div>
              ))}
            </Carousel>
          ) : (
            <Row gutter={[20, 20]}>
              {agendas?.map((agenda, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                  <div className='agenda-card'>
                    <img alt={agenda.presenter} src={agenda.image} width={100} height={100} style={{ borderRadius: '50%', marginBottom: '10px' }} />
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {agenda.title}
                    </Typography.Title>
                    <Typography.Paragraph
                      className='fs-16 mb-0'
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {agenda.presenter}
                    </Typography.Paragraph>
                    <Tag
                      color='default'
                      className='my-10'
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {agenda.time}
                    </Tag>
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                        color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                      }}>
                      {agenda.description}
                    </Typography.Paragraph>
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </Card>
      </Col>
      <Col span={1} className='icons-container'>
        <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
      </Col>
    </Row>
  );
};
