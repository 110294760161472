import React, { useState } from 'react';

import { Button, Col, Image, Row, Select, Spin } from 'antd';

import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { generateQuantityOptions } from '@aduvi/utils/helper';

interface IProps {
  products?: IPaginatedResponse<IProduct>;
  selectedProducts: string[];
  onProductSelect: (id: string) => void;
}

export const RowDisplayType = ({ products, selectedProducts, onProductSelect }: IProps) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (value: number, productId: string) => {
    if (value === 0) {
      onProductSelect(productId);
      setQuantity(1);
      return;
    }
    setQuantity(value);
  };

  return (
    <Spin spinning={!products}>
      <div className='pr-10 pl-10' style={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }}>
        {products?.data?.map((item, index) => (
          <Col span={24} key={index} className='package-wrapper cursor-pointer'>
            <Row gutter={18}>
              <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                <Image src={item.image} preview={false} />
              </Col>
              <Col span={10}>
                <div className='title pl-10'>{item.name}</div>
                <div className='title price-tag pl-10'>
                  ${selectedProducts.includes(item.id) ? Number(item.price || item.upsell_price) * quantity : item.price}
                </div>
                <div className='mt-10 pl-10' dangerouslySetInnerHTML={{ __html: item.description! }} />
              </Col>
              <Col span={8}>
                <Row justify={'end'} align={'bottom'} className='h-full'>
                  <Button
                    type='primary'
                    shape='round'
                    onClick={() => onProductSelect(item.id)}
                    style={{ backgroundColor: selectedProducts.includes(item.id) ? '#52c41a' : '#000' }}
                    className='mr-10'>
                    {selectedProducts.includes(item.id) ? 'Added' : 'Add To Cart'}
                  </Button>
                  {selectedProducts.includes(item.id) ? (
                    <Select defaultValue={1} onChange={(value) => handleQuantityChange(value, item.id)} style={{ width: 60 }}>
                      {generateQuantityOptions(Number(item.quantity_available), item.min_quantity, item.max_quantity).map((qty) => (
                        <Select.Option key={qty} value={qty}>
                          {qty}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    </Spin>
  );
};
