import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, Input, Row } from 'antd';

import { formatWithOrdinal } from '@aduvi/components/CustomField/helper';
import { PREDEFINED_DATE_FORMATS } from '@aduvi/constants';
import { ICustomFieldViewStyle } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';

export const DateFormat = ({
  updateStyleForFieldId,
  fieldData,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
  fieldData?: IFieldData;
}) => {
  const { t: translate } = useTranslation();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDateFormatChange = (format: string) => {
    updateStyleForFieldId({ dateFormat: format });
    setDropdownVisible(false);
  };

  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            {PREDEFINED_DATE_FORMATS.map((format) => {
              return (
                <Button type='text' key={format} onClick={() => handleDateFormatChange(format)} className='date-format-item cursor-pointer text-left'>
                  {formatWithOrdinal(String(fieldData?.value), format)}
                </Button>
              );
            })}
            <div className='flex mt-5'>
              <Input type='text' placeholder='Custom' onChange={(e) => updateStyleForFieldId({ dateFormat: e.target.value })} />
              <InfoCircleOutlined className='fs-20 mx-10 color-gray-5' />
            </div>
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<CalendarOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('formatFields.dateFormat')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
