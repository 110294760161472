import { useTranslation } from 'react-i18next';

import { Avatar, Empty, List, Spin } from 'antd';
import './Activity.scss';

import { useUser } from '@aduvi/hooks';
import { useRelativeTime } from '@aduvi/hooks/useLocalRelativeTime';
import { IPaginatedResponse } from '@aduvi/types';
import { EActivityType, IActivity } from '@aduvi/types/audit';

import { BusinessActivityText } from './BusinessActivityText';
import { UserActivityText } from './UserActivityText';

interface IProps {
  activities?: IPaginatedResponse<IActivity>;
  loading: boolean;
  onGetActivities: (page: number, pageSize: number) => void;
  activityType: EActivityType;
}

export const ActivityList = ({ activities, loading, onGetActivities, activityType }: IProps) => {
  const currentUser = useUser();
  const formatRelativeTime = useRelativeTime();

  const { t: translate } = useTranslation();

  return (
    <Spin className='mt-30' spinning={loading}>
      {activities?.data.length && activities?.data.length > 0 ? (
        <List
          className='mb-30 activity-wrapper '
          itemLayout='horizontal'
          pagination={{
            position: 'bottom',
            align: 'center',
            total: activities?.total,
            pageSize: activities?.per_page,
            current: activities.current_page,
            onChange: (page, pageSize) => {
              onGetActivities(page, pageSize);
            },
          }}
          dataSource={activities.data}
          renderItem={({ model, fields, newValues, oldValues, event, createdAt, user, userId }) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    activityType === EActivityType.BUSINESS ? (
                      <Avatar src={user.profilePicture ? user.profilePicture : undefined} size={40}>
                        {user.firstName?.charAt(0)?.toUpperCase() ?? user.email?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    ) : (
                      <Avatar src={currentUser?.profile_picture ? currentUser?.profile_picture : undefined} size={40}>
                        {currentUser?.first_name?.charAt(0)?.toUpperCase() ?? currentUser?.email?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    )
                  }
                  description={formatRelativeTime(createdAt)}
                  title={
                    activityType === EActivityType.USER || (activityType === EActivityType.BUSINESS && userId === currentUser?.id) ? (
                      <UserActivityText
                        model={model}
                        fields={fields}
                        newValues={newValues}
                        oldValues={oldValues}
                        event={event}
                        activityType={activityType}
                      />
                    ) : (
                      <BusinessActivityText
                        model={model}
                        fields={fields}
                        newValues={newValues}
                        oldValues={oldValues}
                        event={event}
                        user={`${user.firstName} ${user.lastName}`}
                        activityType={activityType}
                      />
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      ) : null}
      {!loading && !activities?.data.length && <Empty description={translate('profile.activity.noActivity')} />}
    </Spin>
  );
};
