import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { SelectOrder } from '@aduvi/components/SelectOrder/SelectOrder';
import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EFieldsVisibility, EOrderType } from '@aduvi/types';
import { EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

const inputEntityFields = [
  EFieldDataValueType.FIELD_DATA_EMAILS,
  EFieldDataValueType.FIELD_DATA_LONG_TEXTS,
  EFieldDataValueType.FIELD_DATA_NUMBERS,
  EFieldDataValueType.FIELD_DATA_PHONES,
  EFieldDataValueType.FIELD_DATA_TEXTS,
  EFieldDataValueType.FIELD_DATA_URLS,
];

export const Pipeline = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const statusFieldOptions = fields.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LISTS);

  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState(statusFieldOptions?.[0]?.id);

  const defaultStatusOptions = useMemo(() => {
    const selectedOption = statusFieldOptions.find((item) => item.id === selectedCustomFieldId);

    return selectedOption?.list_values
      ?.filter((item) => {
        if (entityType?.splittable) {
          if (
            selectedOption?.list_values?.some(
              (item) => [EFieldsVisibility.CONFIRMED, EFieldsVisibility.UNCONFIRMED]?.includes(item?.value_type as EFieldsVisibility),
            )
          ) {
            return [EPersonalizedViewOrigin.BOOKINGS]?.includes(origin)
              ? item?.value_type === EFieldsVisibility.CONFIRMED
              : item?.value_type === EFieldsVisibility.UNCONFIRMED;
          }
        }

        return !item.is_archived;
      })
      .map((option) => ({ label: option?.value, value: option?.id }));
  }, [selectedCustomFieldId, origin]);

  const inputFields = fields
    .filter((item) => inputEntityFields.includes(item.value_type))
    ?.map((field) => ({ label: field?.title, value: field?.id }));

  useEffect(() => {
    form.setFieldsValue({
      status_field: form.getFieldValue('status_field') || statusFieldOptions?.[0]?.id,
      default_status: form.getFieldValue('default_status') || defaultStatusOptions?.[0]?.value,
      default_date_values: form.getFieldValue('default_date_values') || inputFields?.[0]?.value,
      default_sort_order: form.getFieldValue('default_sort_order') || EOrderType.ASC,
    });
  }, []);

  useEffect(() => {
    if (selectedCustomFieldId) {
      form.setFieldValue('default_status', form.getFieldValue('default_status') || defaultStatusOptions?.[0]?.value);
    }
  }, [selectedCustomFieldId]);

  return (
    <Col>
      <Form.Item name='status_field' label={translate('leads.pipeline.statusField')}>
        <Select
          options={statusFieldOptions.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
          onChange={(value) => {
            setSelectedCustomFieldId(value);
          }}
          placeholder={translate('leads.jobStatus')}
        />
      </Form.Item>

      <Form.Item name='default_status' label={translate('leads.pipeline.defaultStatus')}>
        <Select options={defaultStatusOptions} placeholder={translate('leads.pipeline.newLead')} />
      </Form.Item>
      <label>{translate('leads.defaultSort')}</label>
      <Row className='w-full mt-10' justify={'space-between'}>
        <Form.Item name='default_date_values' style={{ width: '49%' }}>
          <Select placeholder={translate('leads.eventDate')} options={fields.map((field) => ({ label: field?.title, value: field?.id }))} />
        </Form.Item>
        <Form.Item name='default_sort_order' style={{ width: '49%' }}>
          <SelectOrder placeholder={translate('leads.ascending')} />
        </Form.Item>
      </Row>
    </Col>
  );
};
