import { Select, SelectProps } from 'antd';

import { useBusiness } from '@aduvi/hooks';

import { useGetTaxRatesQuery } from 'store/rtk-slice/tax-slice';

export const SelectTax = (props: SelectProps & { team_id?: string }) => {
  const selectedBusiness = useBusiness();

  const { data: rates, isFetching } = useGetTaxRatesQuery({ business_id: selectedBusiness?.id }, { skip: !selectedBusiness?.id });

  return (
    <Select
      {...props}
      showSearch
      disabled={isFetching}
      loading={isFetching}
      filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
      {rates?.data?.map((tax, index) => (
        <Select.Option key={index} value={tax.id}>
          {tax.tax_name}
        </Select.Option>
      ))}
    </Select>
  );
};
