import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { Button, Col, Form, List, Row, Select, Space } from 'antd';
import './LimitVisibility.scss';

export const LimitVisibility = () => {
  const { t: translate } = useTranslation();
  const [conditions, setConditions] = useState([{}]);

  const addCondition = () => {
    setConditions([...conditions, {}]);
  };

  const removeCondition = (index: number) => {
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
  };

  return (
    <Col span={24} className='limit-visibility-wrapper mt-15'>
      <Form.Item name='conditions' label={translate('assets.ticketDrawer.limitVisibility.conditions')}>
        <List
          dataSource={conditions}
          renderItem={(item, index) => (
            <List.Item
              className='bg-color-white'
              key={index}
              actions={[<DeleteFilled className='color-gray-8' key='deleteIcon' onClick={() => removeCondition(index)} />]}>
              <Space direction='vertical' className='w-full'>
                <Row className='pl-10'>
                  <Col span={7}>
                    <Select placeholder={translate('assets.ticketDrawer.limitVisibility.placeholder.availability')}></Select>
                  </Col>
                  <Col className='flex-center' span={1}>
                    {translate('assets.ticketDrawer.limitVisibility.if')}
                  </Col>
                  <Col span={16}>
                    <Select placeholder={translate('assets.ticketDrawer.limitVisibility.placeholder.jobType')}></Select>
                  </Col>
                </Row>
                <Row className='pl-30' gutter={18}>
                  <Col span={8}>
                    <Select placeholder={translate('assets.ticketDrawer.limitVisibility.placeholder.conditional')}></Select>
                  </Col>
                  <Col span={16}>
                    <Select placeholder={translate('assets.ticketDrawer.limitVisibility.placeholder.dateRange')}></Select>
                  </Col>
                </Row>
              </Space>
            </List.Item>
          )}
        />
      </Form.Item>
      <Row justify='end'>
        <Button icon={<PlusOutlined />} onClick={addCondition}>
          {translate('assets.ticketDrawer.limitVisibility.addCondition')}
        </Button>
      </Row>
    </Col>
  );
};
