import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Button, Card, Carousel, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { useBusiness } from '@aduvi/hooks';
import { IService } from '@aduvi/types';
import { EFormType } from '@aduvi/types/form';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getFormsByEntity } from 'store/features/form-slice';
import { getServices, resetServicesState } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const serviceFields = ['Picture', 'Title', 'Price', 'Description', 'Button'];

const { Option } = Select;

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [isMobileView, setIsMobileView] = useState(false);
  const { t: translate } = useTranslation();

  const { services, loading } = useAppSelector((state) => state.services);
  const { entityForms } = useAppSelector((state) => state.forms);
  const { entityTypes } = useAppSelector((state) => state.entity);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || [];

  const displayedServices = useMemo(() => {
    if (limitResults === 'show_all') {
      return services?.data;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return services?.data.slice(0, resultsPerPage);
    }
    return services?.data;
  }, [limitResults, resultsPerPage, services?.data]);

  const entitySettings = (
    <div>
      <Typography.Text type='secondary'>{translate('widgets.priceDisplay')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'price_display']}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'price_display'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'price_display'], value);
          }}>
          <Option value='From'>From</Option>
          <Option value='Range'>Range</Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonAction')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'button_action_form']}>
        <Select
          placeholder='Select a form'
          value={form.getFieldValue(['widget_style', 0, 'button_action_form'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'button_action_form'], value);
            const selectedForm = entityForms?.find((f) => f.id === value);
            form.setFieldValue(['widget_style', 0, 'form_name'], selectedForm?.name || '');
          }}>
          {entityForms?.map((form) => (
            <Option key={form.id} value={form.id}>
              Go to {form.name} form
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name={['widget_style', 0, 'form_name']} hidden={true} />

      <Typography.Text type='secondary'>{translate('widgets.buttonText')}</Typography.Text>
      <Form.Item name={['widget_style', 0, 'button_text']}>
        <Input
          value={form.getFieldValue(['widget_style', 0, 'button_text'])}
          onChange={(e) => {
            form.setFieldValue(['widget_style', 0, 'button_text'], e.target.value);
          }}
        />
      </Form.Item>
    </div>
  );

  const getPriceDisplay = (service: IService) => {
    const packages = service.packages || [];

    const publicPackages = packages.filter((pkg) => pkg.visibility === '1');

    if (publicPackages.length === 0) return null;

    const prices = publicPackages.map((pkg) => parseFloat(pkg.base_price || pkg.flat_price));

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    if (form.getFieldValue(['widget_style', 0, 'price_display']) === 'From') {
      return `From $${minPrice}`;
    } else if (form.getFieldValue(['widget_style', 0, 'price_display']) === 'Range') {
      return `$${minPrice} - $${maxPrice}`;
    }
    return null;
  };

  const handleButtonClick = () => {
    if (form.getFieldValue(['widget_style', 0, 'button_action_form'])) {
      const selectedForm = entityForms?.find((f) => f.id === form.getFieldValue(['widget_style', 0, 'button_action_form']));
      window.open(`/fo/${form.getFieldValue(['widget_style', 0, 'button_action_form'])}/${selectedForm?.name.toLocaleLowerCase()}`, '_blank');
    }
  };

  const onGetBusinessServices = useCallback(() => {
    if (!selectedBusiness?.id) return;
    dispatch(resetServicesState());
    dispatch(
      getServices({
        business_id: selectedBusiness?.id,
        params: {
          page: 1,
          size: 50,
          search: '',
        },
      }),
    );
  }, [selectedBusiness?.id, dispatch]);

  useEffect(() => {
    onGetBusinessServices();
  }, [selectedBusiness?.id, onGetBusinessServices]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes?.data?.JOB?.id) return;
    dispatch(getFormsByEntity({ business_id: selectedBusiness.id, entity_type_id: entityTypes?.data?.JOB?.id, formType: EFormType.LEADS_FORM }));
  }, [selectedBusiness?.id, dispatch]);

  return (
    <Spin spinning={loading}>
      <Row className='services-design-tab-wrapper' style={backgroundStyle}>
        <Col span={14}>
          <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
            {selectedLayout === EWidgetLayout.CARDS ? (
              <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
                {displayedServices?.map((service, index) => (
                  <div key={index} className='services-card'>
                    {visibleFields.includes('Picture') && <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {service.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {getPriceDisplay(service)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick()}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </div>
                ))}
              </Carousel>
            ) : selectedLayout === EWidgetLayout.COLUMNS ? (
              <Row gutter={[20, 20]}>
                {displayedServices?.map((service, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan}
                    className='services-card'>
                    {visibleFields.includes('Picture') && <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {service.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {getPriceDisplay(service)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick()}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </Col>
                ))}
              </Row>
            ) : (
              displayedServices?.map((service, index) => (
                <Row key={index} gutter={[20, 20]} align='middle' className='services-card-row'>
                  {visibleFields.includes('Picture') && (
                    <Col xs={24} sm={6} className='service-image'>
                      <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />
                    </Col>
                  )}
                  <Col xs={24} sm={18} className='service-details'>
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {service.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {getPriceDisplay(service)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick()}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </Col>
                </Row>
              ))
            )}
          </Card>
        </Col>
        <Col span={1} className='icons-container'>
          <RightSidebar
            form={form}
            onMobileView={() => setIsMobileView((prevState) => !prevState)}
            isMobileView={isMobileView}
            fields={serviceFields}
            entitySettings={entitySettings}
          />
        </Col>
      </Row>
    </Spin>
  );
};
