import { ReactNode, useEffect, useRef, useState } from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import { useTranslation } from 'react-i18next';
import { DesktopOutlined, FontSizeOutlined, LayoutOutlined, MobileOutlined, PictureOutlined, SettingOutlined } from '@ant-design/icons';
import { LayoutIconCards } from 'views/widgets/assets/LayoutIconCard';
import { LayoutIconColumns } from 'views/widgets/assets/LayoutIconColumns';
import { LayoutIconRows } from 'views/widgets/assets/LayoutIconRows';

import { Button, Col, Divider, Form, FormInstance, Input, Popover, Radio, RadioChangeEvent, Row, Select, Switch, Typography } from 'antd';
import './RightSidebar.scss';

import { ColorPicker as AntColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { GradientColorPicker } from '@aduvi/components/ColorPicker/GradientColorPicker';
import { Title } from '@aduvi/components/Title';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { FONT_SELECTION } from '@aduvi/constants/client-portal-constants';
import { useActiveTheme } from '@aduvi/hooks';
import { EWidgetCards, EWidgetColumns, EWidgetLayout } from '@aduvi/types/widget';
import { determinePickerColor, isValidUrl, toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

interface IProps {
  form: FormInstance;
  onMobileView: () => void;
  isMobileView: boolean;
  fields?: string[];
  entitySettings?: ReactNode;
}

export const RightSidebar = ({ form, onMobileView, isMobileView, fields, entitySettings }: IProps) => {
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();
  const isInitializedRef = useRef(false);

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [selectedLayout, setSelectedLayout] = useState(form.getFieldValue(['widget_style', 0, 'layout']) || EWidgetLayout.COLUMNS);
  const [limitResultsValue, setLimitResultsValue] = useState(form.getFieldValue(['widget_style', 0, 'limit_results']) || 'show_all');
  const [backgroundMode, setBackgroundMode] = useState('colour');

  Form.useWatch('widget_style', form);

  const selectedFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || [];

  const limitResults = [
    { id: 'show_all', name: translate('widgets.showAll') },
    { id: 'fixed_number', name: translate('widgets.fixedNumber') },
    { id: 'paginated', name: translate('widgets.paginated') },
  ];

  useEffect(() => {
    if (isInitializedRef.current) return;

    if (
      (!form.getFieldValue(['widget_style', 0, 'visible_fields']) || form.getFieldValue(['widget_style', 0, 'visible_fields'])?.length === 0) &&
      fields &&
      fields?.length > 0
    ) {
      form.setFieldValue(['widget_style', 0, 'visible_fields'], fields);
      isInitializedRef.current = true;
    }
  }, [form, fields]);

  const handleToggle = (field: string, checked: boolean) => {
    let updatedFields = [...selectedFields];
    if (checked) {
      updatedFields.push(field);
    } else {
      updatedFields = updatedFields.filter((f) => f !== field);
    }
    form.setFieldValue(['widget_style', 0, 'visible_fields'], updatedFields);
  };

  const handleLayoutChange = (e: RadioChangeEvent) => {
    setSelectedLayout(e.target.value);
    form.setFieldValue(['widget_style', 0, 'layout'], e.target.value);
  };

  const handleColumnChange = (columnValue: number) => {
    form.setFieldValue(['widget_style', 0, 'columns'], columnValue);
  };

  const handleCardChange = (cardValue: number) => {
    form.setFieldValue(['widget_style', 0, 'cards'], cardValue);
  };

  const handleLimitResultsChange = (value: string) => {
    setLimitResultsValue(value);
    form.setFieldValue(['widget_style', 0, 'limit_results'], value);
  };

  const filteredLimitResults =
    selectedLayout === EWidgetLayout.CARDS ? limitResults.filter((result) => result.id === 'fixed_number' || result.id === 'show_all') : limitResults;

  return (
    <Form.Item name={['widget_style']} className='right-sidebar-wrapper'>
      <Popover
        trigger={'click'}
        overlayClassName='fields-popover'
        content={
          <>
            <Typography.Text type='secondary'>{translate('widgets.fields')}</Typography.Text>
            <div>
              {fields?.map((field) => (
                <div key={field} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Switch checked={selectedFields.includes(field)} onChange={(checked) => handleToggle(field, checked)} className='mr-10' />
                  <span>{toHumanReadable(field)}</span>
                </div>
              ))}
            </div>
            {entitySettings && (
              <>
                <Divider className='mb-10' />
                {entitySettings}
              </>
            )}
          </>
        }
        placement='leftTop'>
        <SettingOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        content={
          <>
            <Form.Item name={['widget_style', 0, 'background_mode']} initialValue='colour'>
              <Radio.Group
                onChange={(event) => {
                  setBackgroundMode(event.target.value);
                  form.setFieldValue(['widget_style', 0, 'background_mode'], event.target.value);
                }}
                className='flex-center mb-10'
                value={backgroundMode}
                defaultValue={form.getFieldValue(['widget_style', 0, 'background_mode'])}>
                <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='colour'>
                  {translate('forms.forms.colour')}
                </Radio.Button>
                <Radio.Button style={{ width: '100px', textAlign: 'center' }} value='image'>
                  {translate('forms.forms.image')}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {backgroundMode === 'colour' ? (
              <Form.Item name={['widget_style', 0, 'background_color']} className='flex-center' getValueFromEvent={determinePickerColor}>
                <ColorPicker onChange={() => {}} width={230} height={150} />
              </Form.Item>
            ) : (
              <>
                <Form.Item name={['widget_style', 0, 'background_image']}>
                  <UploadImage
                    name={'image'}
                    onUploadChange={(value) => form.setFieldValue(['widget_style', 0, 'background_image'], value?.url)}
                    text={translate('forms.forms.uploadYourOwn')}
                    onPreview={(value) => form.setFieldValue(['widget_style', 0, 'background_image'], value.thumbUrl)}
                    fileList={
                      isValidUrl(form.getFieldValue(['widget_style', 0, 'background_image']))
                        ? [
                            {
                              name: form.getFieldValue(['widget_style', 0, 'background_image']).split('/uploads/')[1],
                              url: form.getFieldValue(['widget_style', 0, 'background_image']),
                              uid: '-1',
                              status: 'done',
                            },
                          ]
                        : []
                    }
                  />
                  <Typography.Text type='secondary'>{translate('forms.forms.selectFromLibrary')}</Typography.Text>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Col
                      style={{
                        border:
                          form.getFieldValue(['widget_style', 0, 'background_image']) === `assets/images/theme-${index + 1}.png`
                            ? `2px dashed ${selectedClientPortal?.brand?.brand_color || '#7cc0ff'} `
                            : '',
                      }}
                      key={index}
                      className='mt-10 cursor-pointer'
                      onClick={() => form.setFieldValue(['widget_style', 0, 'background_image'], `assets/images/theme-${index + 1}.png`)}>
                      <img src={require(`assets/images/theme-${index + 1}.png`)} />
                    </Col>
                  ))}
                </Form.Item>
              </>
            )}
          </>
        }
        placement='leftTop'>
        <PictureOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        content={
          <div className='form-layout-popover'>
            <Typography.Text type='secondary'>{translate('forms.forms.layout')}</Typography.Text>
            <Form.Item name={['widget_style', 0, 'layout']} initialValue={EWidgetLayout.COLUMNS}>
              <Radio.Group className='w-full' value={selectedLayout} onChange={handleLayoutChange}>
                <Row justify={'space-between'}>
                  <Radio.Button value={EWidgetLayout.COLUMNS} className='layout-button '>
                    <LayoutIconColumns active={form.getFieldValue(['widget_style', 0, 'layout']) === EWidgetLayout.COLUMNS} />
                  </Radio.Button>
                  <Radio.Button value={EWidgetLayout.ROWS} className='layout-button'>
                    <LayoutIconRows active={form.getFieldValue(['widget_style', 0, 'layout']) === EWidgetLayout.ROWS} />
                  </Radio.Button>
                  <Radio.Button value={EWidgetLayout.CARDS} className='layout-button'>
                    <LayoutIconCards active={form.getFieldValue(['widget_style', 0, 'layout']) === EWidgetLayout.CARDS} />
                  </Radio.Button>
                </Row>
              </Radio.Group>
            </Form.Item>
            {selectedLayout === EWidgetLayout.COLUMNS && (
              <>
                <Typography.Text type='secondary'>{translate('widgets.columns')}</Typography.Text>
                <Form.Item name={['widget_style', 0, 'columns']}>
                  <Radio.Group
                    className='w-full'
                    onChange={(e) => handleColumnChange(e.target.value)}
                    value={form.getFieldValue(['widget_style', 0, 'columns'])}
                    style={{ textAlign: 'center' }}>
                    <Row>
                      <Col span={6}>
                        <Radio.Button value={2} className='w-full'>
                          {EWidgetColumns.TWO}
                        </Radio.Button>
                      </Col>
                      <Col span={6}>
                        <Radio.Button value={3} className='w-full'>
                          {EWidgetColumns.THREE}
                        </Radio.Button>
                      </Col>
                      <Col span={6}>
                        <Radio.Button value={4} className='w-full'>
                          {EWidgetColumns.FOUR}
                        </Radio.Button>
                      </Col>
                      <Col span={6}>
                        <Radio.Button value={5} className='w-full'>
                          {EWidgetColumns.FIVE}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            {selectedLayout === EWidgetLayout.CARDS && (
              <>
                <Typography.Text type='secondary'>{translate('widgets.cardNavigation')}</Typography.Text>
                <Form.Item name={['widget_style', 0, 'cards']}>
                  <Radio.Group
                    className='w-full'
                    onChange={(e) => handleCardChange(e.target.value)}
                    value={form.getFieldValue(['widget_style', 0, 'cards'])}
                    style={{ textAlign: 'center' }}>
                    <Row>
                      <Col span={12}>
                        <Radio.Button value={EWidgetCards.MANUAL} className='w-full'>
                          {toHumanReadable(EWidgetCards.MANUAL)}
                        </Radio.Button>
                      </Col>
                      <Col span={12}>
                        <Radio.Button value={EWidgetCards.ANIMATED} className='w-full'>
                          {toHumanReadable(EWidgetCards.ANIMATED)}
                        </Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            <Typography.Text type='secondary'>{translate('widgets.limitResults')}</Typography.Text>
            <Form.Item name={['widget_style', 0, 'limit_results']}>
              <Select
                showSearch
                value={limitResultsValue}
                onChange={handleLimitResultsChange}
                filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
                {filteredLimitResults?.map((result, index) => (
                  <Select.Option key={index} value={result.id}>
                    {result?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {(limitResultsValue === 'fixed_number' || limitResultsValue === 'paginated') && (
              <>
                <Typography.Text type='secondary'>{translate('widgets.resultsPerPage')}</Typography.Text>
                <Form.Item name={['widget_style', 0, 'results_per_page']}>
                  <Input />
                </Form.Item>
              </>
            )}
          </div>
        }
        placement='leftTop'>
        <LayoutOutlined className='icon' />
      </Popover>

      <Popover
        trigger={'click'}
        overlayClassName='colour-popover'
        content={
          <Col style={{ width: 200 }}>
            <Title className='popover-font-title pb-5'>{translate('settings.contracts.editContract.colours')}</Title>

            <Row gutter={8} align={'middle'} className='mb-10'>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'primary_font_color']}
                  initialValue={'#000000'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('settings.contracts.editContract.primaryFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'secondary_font_color']}
                  initialValue={'#000000'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <AntColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('settings.contracts.editContract.secondaryFont')}</Typography.Text>
              </Col>
            </Row>

            <Row gutter={8} className='mb-10' align={'middle'}>
              <Col>
                <Form.Item
                  name={['widget_style', 0, 'widget_background']}
                  initialValue={'#ffffff'}
                  getValueFromEvent={determinePickerColor}
                  className='mb-0'>
                  <GradientColorPicker />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{translate('widgets.widgetBackground')}</Typography.Text>
              </Col>
            </Row>

            {fields?.includes('Button') && (
              <>
                <Row gutter={8} className='mb-10' align={'middle'}>
                  <Col>
                    <Form.Item
                      name={['widget_style', 0, 'button_font']}
                      initialValue={'#ffffff'}
                      getValueFromEvent={determinePickerColor}
                      className='mb-0'>
                      <GradientColorPicker />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Typography.Text>{translate('widgets.buttonFont')}</Typography.Text>
                  </Col>
                </Row>

                <Row gutter={8} className='mb-10' align={'middle'}>
                  <Col>
                    <Form.Item
                      name={['widget_style', 0, 'button_background']}
                      initialValue={'#1890ff'}
                      getValueFromEvent={determinePickerColor}
                      className='mb-0'>
                      <GradientColorPicker />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Typography.Text>{translate('widgets.buttonBackground')}</Typography.Text>
                  </Col>
                </Row>
              </>
            )}

            <Divider className='w-full' />

            <Title className='popover-font-title pb-5 pl-5'>{translate('settings.contracts.editContract.font')}</Title>

            {FONT_SELECTION.map((font) => (
              <Row key={font} gutter={8} className='mb-5' align={'middle'}>
                <Col>
                  <Form.Item name={['widget_style', 0, 'font_family']} initialValue={'Roboto'} className='mb-0'>
                    <Button
                      className='pl-5'
                      type='link'
                      style={{
                        fontFamily: font,
                        color: font === form.getFieldValue(['widget_style', 0, 'font_family']) ? activeTheme?.menuActiveText : activeTheme?.menuText,
                      }}
                      onClick={() => {
                        form.setFieldValue(['widget_style', 0, 'font_family'], font);
                      }}>
                      {font}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Col>
        }
        placement='leftTop'>
        <FontSizeOutlined className='icon' />
      </Popover>

      {isMobileView ? <DesktopOutlined className='icon' onClick={onMobileView} /> : <MobileOutlined className='icon' onClick={onMobileView} />}
    </Form.Item>
  );
};
