import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined, EllipsisOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { Card, Col, Dropdown, MenuProps, Popconfirm, Row, Spin, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';

import { Button } from '@aduvi/components/Button/Button';
import { Paragraph } from '@aduvi/components/Paragraph';
import { useBusiness } from '@aduvi/hooks';

import { cloneBuilderTemplates, deleteBuilderTemplate, getBuilderTemplates, setCurrentTemplateState } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { dummy_templates } from './helper';

export const Templates = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const {
    templates: { templates, loading },
  } = useAppSelector((state) => state.proposals);

  const onDelete = (id: string) => {
    if (!selectedBusiness?.id) return;

    dispatch(deleteBuilderTemplate({ businessId: selectedBusiness?.id, builderTemplateId: id }));
  };

  const dropdownItems = (id: string): MenuProps['items'] => [
    {
      key: '1',
      icon: <SettingOutlined />,
      label: translate('file.edit'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        dispatch(setCurrentTemplateState({ html: '', mainCss: '', sectionCss: '' }));
        navigate(`/template-builder?templateId=${id}`);
      },
    },

    {
      key: '2',
      icon: <CopyOutlined />,
      label: translate('file.duplicate'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        if (!selectedBusiness?.id || !id) return;
        dispatch(cloneBuilderTemplates({ businessId: selectedBusiness?.id, templateId: id }));
      },
    },
    {
      key: '3',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('buttons.delete')}
          description={translate('common.deleteConfirmation')}
          icon={<QuestionCircleOutlined />}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
          onConfirm={(e) => {
            e?.stopPropagation();
            onDelete(id);
          }}>
          {translate('file.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
      // disabled: !useUserPermissionCheck(PERMISSIONS.FILES.DELETE),
    },
  ];

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getBuilderTemplates(selectedBusiness?.id));
  }, [dispatch, selectedBusiness?.id]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[18, 18]} className='mt-30'>
        {templates.map((item) => (
          <Col key={item.id} span={6} style={{ borderRadius: '5px' }}>
            <Card
              hoverable
              style={{ borderRadius: '5px' }}
              cover={
                <img
                  alt='example'
                  src={item.screenshot?.image_url ?? dummy_templates[0].image}
                  style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(setCurrentTemplateState({ html: '', mainCss: '', sectionCss: '' }));
                navigate(`/template-builder?templateId=${item.id}`);
              }}>
              <Meta title={item.name} />
              <Paragraph className='mt-10'>{item.description}</Paragraph>
              <Row justify={'space-between'}>
                <Col>
                  <Typography.Text type='secondary'>Custom Template</Typography.Text>
                </Col>
                <Col>
                  <Dropdown menu={{ items: dropdownItems(item.id) }} trigger={['click']}>
                    <Button
                      onClick={(e) => {
                        e?.stopPropagation();
                      }}
                      icon={<EllipsisOutlined />}
                      type='text'
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
