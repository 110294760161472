import { HTMLAttributes } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';

interface ShoppingBasketProps extends HTMLAttributes<HTMLDivElement> {
  amount: number;
}

export const ShoppingBasket = ({ amount, ...restProps }: ShoppingBasketProps) => {
  return (
    <div {...restProps} className={`d-inline-block py-10 px-20 ${restProps.className}`}>
      <ShoppingCartOutlined className='mr-10' /> <PriceDisplay price={amount} />
    </div>
  );
};
