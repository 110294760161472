import { useTranslation } from 'react-i18next';

import { Form, Input, Select } from 'antd';

import { EMailChimpAction } from '@aduvi/types/automation';

export const Mailerlite = () => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Form.Item name={'api_key'} label={translate('automations.apiKey')}>
        <Input placeholder={translate('automations.pasteyourApiKey')} />
      </Form.Item>
      <Form.Item name={'list'} label={translate('automations.list')}>
        <Select showSearch placeholder={translate('automations.enterYourApiKeyForList')} />
      </Form.Item>
      <Form.Item name={'action'} label={translate('automations.action')}>
        <Select
          placeholder={translate('automations.pleaseSelect')}
          options={Object.values(EMailChimpAction).map((value) => ({ label: value, value }))}
        />
      </Form.Item>
    </>
  );
};
