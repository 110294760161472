import { useMemo } from 'react';
import { ForgotPassword } from 'views/settings/client-portal/@components/client-portal/authentication/ForgotPassword/ForgotPassword';
import { LoggedUser } from 'views/settings/client-portal/@components/client-portal/authentication/LoggedUser/LoggedUser';
import { LoginComponent } from 'views/settings/client-portal/@components/client-portal/authentication/Login/Login';
import { ResetPassword } from 'views/settings/client-portal/@components/client-portal/authentication/ResetPassword/ResetPassword';

import { Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { initialClientPortalSettings } from '@aduvi/constants/client-portal-constants';
import { EClientPortalAuthComponent } from '@aduvi/types/client-portal';

import { useAppSelector } from 'store/hooks';

import { MagicLinkLogin } from './MagicLinkLogin';

const ClientPortalAuthWrapper = ({ componentToRender }: { componentToRender: EClientPortalAuthComponent }) => {
  const [form] = useForm();

  const { selectedClientPortal, loading } = useAppSelector((state) => state.clientPortal);

  const authComponents = useMemo(() => {
    return {
      [EClientPortalAuthComponent.LOGIN]: <LoginComponent form={form} />,
      [EClientPortalAuthComponent.RESET_PASSWORD]: <ResetPassword />,
      [EClientPortalAuthComponent.FORGOT_PASSWORD]: <ForgotPassword />,
      [EClientPortalAuthComponent.LOGGED_USER]: <LoggedUser />,
      [EClientPortalAuthComponent.MAGIC_LINK_LOGIN]: <MagicLinkLogin />,
    };
  }, []);

  return (
    <>
      {!loading && selectedClientPortal && (
        <Row
          align={'middle'}
          justify={'center'}
          className='h-100vh'
          style={{
            background:
              selectedClientPortal?.login_settings?.login_style?.[0]?.background_color ||
              selectedClientPortal?.brand?.brand_color ||
              initialClientPortalSettings?.login_settings?.login_style?.[0]?.background_color ||
              '#7cc0ff',
          }}>
          {authComponents[componentToRender]}
        </Row>
      )}
    </>
  );
};

export default ClientPortalAuthWrapper;
