import { IPaginatedResponse, IPaginationParams } from './common';
import { IEntityType } from './entity';
import { IProduct } from './products';
import { ITaxRate } from './tax';
import { IUser } from './user';

export enum EServiceDrawerTabs {
  DETAILS = 'details',
  AVAILABILITY = 'availability',
}

export enum EPackagesDrawerTabs {
  DETAILS = 'details',
  PRICING = 'pricing',
  LIMIT_VISIBILITY = 'limit_visibility',
}

export enum EAvailabilityType {
  UNITS = 'UNITS',
  USERS = 'USERS',
  UNITS_USERS = 'UNITS_USERS',
}

export enum ETimeUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
}

export interface IGetServicesPayload {
  business_id: string;
  params: IPaginationParams;
}

export interface IPackageTaxRate {
  id: string;
  entity_id: string;
  entity_type: IEntityType;
  tax_rate_id: string;
  business_id: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  tax_rate: ITaxRate;
}

export interface IPackage {
  id: string;
  key: string;
  name: string;
  image: string;
  tax_rate_ids: string[];
  description: string;
  visibility: string;
  pricing_type: string;
  flat_price: string;
  base_price: string;
  included_duration: number;
  duration_type: string;
  allow_extend: boolean;
  time_based_pricing: {
    additional_duration: number;
    price: number;
  }[];
  order_number: number;
  business_service_id: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  products: IProduct[];
  tax_rates?: IPackageTaxRate[];
}

export interface IService {
  id: string;
  key: string;
  name: string;
  business_id: string;
  business_team_id: string;
  image?: string;
  description?: string;
  availability?: string;
  packages: IPackage[];
  users: IUser[];
  order_number: number;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  is_archived: boolean;
  units: IUnits[];
  setup_time_value?: number;
  setup_time_type?: ETimeUnit;
  pack_down_time_value?: number;
  pack_down_time_type?: ETimeUnit;
}

export interface IServicesState {
  services?: IPaginatedResponse<IService>;
  selectedService: IService | undefined;
  selectedPackage: IPackage | undefined;
  loadingService: boolean;
  loadingPackage: boolean;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export interface IUpdateServicePayload {
  business_id: string;
  service_id: string;
  body: Partial<IService>;
}

export interface ICreateServicePayload {
  business_id: string;
  body: {
    name: string;
    image: string;
    description: string;
    availability_type: string;
    users: IUser[];
    units: {
      name: string;
      enabled: boolean;
    }[];
    setup_time_value?: number;
    setup_time_type?: ETimeUnit;
    pack_down_time_value?: number;
    pack_down_time_type?: ETimeUnit;
  };
}

export interface ICreatePackagePayload {
  business_id: string;
  service_id: string;
  body: IPackage;
}

export interface IUpdatePackagePayload extends ICreatePackagePayload {
  package_id: string;
}

export interface IDeletePackagePayload {
  business_id: string;
  service_id: string;
  package_id: string;
  package_name?: string;
}

export interface IGetPackageByIdPayload extends IDeletePackagePayload {}

export interface IReorderPackagesPayload {
  business_id: string;
  service_id: string;
  body: {
    packages: IPackage[];
  };
}

export interface IReorderServicesPayload {
  business_id: string;
  body: {
    services: IService[];
  };
}

export interface IUnits {
  business_service_id: string;
  created_at: string;
  deleted_at: string;
  enabled: boolean;
  id: string;
  name: string;
  updated_at: string;
}
