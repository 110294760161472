import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RightOutlined, ScissorOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, InputNumber, Row, Typography } from 'antd';

import { EPersonalizedView, ICustomFieldSpecificStyle, ICustomFieldViewStyle } from '@aduvi/types';

export const TextLengthTrim = ({
  updateStyleForFieldId,
  column,
  fieldId,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
  column: ICustomFieldViewStyle;
  viewType?: EPersonalizedView;
  fieldId?: string;
}) => {
  const { t: translate } = useTranslation();

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleTextLengthChange = (length: number) => {
    updateStyleForFieldId({ maxLength: length });
  };

  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            <Typography.Text className='color-gray-5'>Set your desired text length.</Typography.Text>
            <InputNumber
              min={1}
              className='mt-5 w-full'
              value={(column as unknown as ICustomFieldSpecificStyle)?.[fieldId as string]?.style?.maxLength || 1}
              onChange={(value) => handleTextLengthChange(value || 0)}
            />
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<ScissorOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('formatFields.textLength')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
