import axios from '@aduvi/config/axios';
import {
  IBusiness,
  IForgotPasswordPayload,
  IForgotPasswordResponse,
  ILoginPayload,
  ILoginResponse,
  IResetPasswordPayload,
  IResponse,
  IValidateAndResetPasswordResponse,
  IValidateTokenPayload,
} from '@aduvi/types';
import { IClientJob, IClientJobs, IClientOrder, IClientOrders, ISendMagicLinkResponse } from '@aduvi/types/client-portal';
import { IEntityWithFields } from '@aduvi/types/entity';
export const getBusiness = async (): Promise<IResponse<IBusiness>> => {
  return await axios.get(`/businesses`);
};

export const login = async (payload: { businessId: string; body: ILoginPayload }): Promise<IResponse<ILoginResponse>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/auth/login`, payload.body, { params: { auth_type: payload.body.auth_type } });

export const sendMagicLink = async (payload: {
  businessId: string;
  clientPortalId: string;
  body: { email: string };
}): Promise<IResponse<ISendMagicLinkResponse>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/${payload.clientPortalId}/auth/magic-link/send`, payload.body);

export const loginWithMagicLink = async (payload: {
  businessId: string;
  clientPortalId: string;
  body: { token: string };
}): Promise<IResponse<IEntityWithFields & { access_token: string }>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/${payload.clientPortalId}/auth/magic-link/login`, payload.body);

export const forgetPassword = async (payload: {
  businessId: string;
  clientPortalId: string;
  body: IForgotPasswordPayload;
}): Promise<IResponse<IForgotPasswordResponse>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/${payload.clientPortalId}/auth/forget-password`, payload.body);

export const validateToken = async (payload: {
  businessId: string;
  clientPortalId: string;
  body: IValidateTokenPayload;
}): Promise<IResponse<IValidateAndResetPasswordResponse>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/${payload.clientPortalId}/auth/validate-token`, payload.body);

export const resetPassword = async (payload: {
  businessId: string;
  clientPortalId: string;
  body: IResetPasswordPayload;
}): Promise<IResponse<IValidateAndResetPasswordResponse>> =>
  axios.post(`/businesses/${payload.businessId}/client-portals/${payload.clientPortalId}/auth/reset-password`, payload.body);

export const getClientData = (payload: { businessId: string }): Promise<IResponse<IEntityWithFields>> => {
  return axios.get(`/businesses/${payload.businessId}/client-portals/auth/me`);
};

export const getClientJobs = async (businessId: string): Promise<IClientJobs> => {
  return await axios.get(`businesses/${businessId}/client-portals/jobs
  `);
};

export const getClientOrders = async (businessId: string): Promise<IClientOrders> => {
  return await axios.get(`businesses/${businessId}/client-portals/orders
  `);
};
export const getClientOrderById = async (businessId: string, orderId: string): Promise<IResponse<IClientOrder>> => {
  return await axios.get(`businesses/${businessId}/client-portals/order/${orderId}
  `);
};

export const getClientJobById = async (businessId: string, jobId: string): Promise<IResponse<IClientJob>> => {
  return await axios.get(`businesses/${businessId}/client-portals/job/${jobId}
  `);
};
