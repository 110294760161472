import { FormInstance } from 'antd';

import { EDefaultDateValue } from './custom-fields';
import { EFieldsVisibility } from './fields';

export enum EManagementFieldType {
  TEXT_ONE_LINE = 'TEXT_ONE_LINE',
  TEXT_MULTIPLE_LINE = 'TEXT_MULTIPLE_LINE',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  TELEPHONE = 'TELEPHONE',
  URL = 'URL',
  CONTACT = 'CONTACT',
  VENUE = 'VENUE',
  ADDRESS = 'ADDRESS',
  OPTIONS_LIST = 'OPTIONS_LIST',
  CHECK_BOX = 'CHECK_BOX',
  NUMBER = 'NUMBER',
  SEQUENTIAL_ID = 'SEQUENTIAL_ID',
  USERS = 'USERS',
  FILE = 'FILE',
  IMAGE = 'IMAGE',
  STAR_RATING = 'STAR_RATING',
  NAME = 'NAME',
  STATUS = 'STATUS',
  EXHIBITORS = 'EXHIBITORS',
  AGENDA = 'AGENDA',
  SERVICES = 'SERVICES',
}

export enum EManagementFieldListType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export enum EManagementFieldStatusOption {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum EAddressFieldOptions {
  NAME = 'Name',
  ADDRESS = 'Address',
  STATE_PROVINCE_COUNTY = 'State/Province/County',
  POSTAL_CODE = 'Postal Code',
  COUNTRY = 'Country',
}

export interface IManagementFieldDrawerProps {
  data?: IManagementField;
  title: string;
  isGlobalField?: boolean;
  isNewRecord: boolean;
  showDrawer: boolean;
  loadingAction: boolean;
  fieldType?: EManagementFieldType;
  showVisibility: boolean;
  permissionToEdit?: string;
  onCreate: (form: FormInstance) => void;
  onUpdate: (form: FormInstance) => void;
  onCancel: () => void;
}

export interface IManagementField {
  id: string;
  name: string;
  date: string;
  field_type: EManagementFieldType;
  visibility: EFieldsVisibility;
  allow_multiple: boolean;
  default_date_values: EDefaultDateValue;
  list_type: EManagementFieldListType;
  next_number: number;
  order_number: number;
  image: {
    allowed_extensions: string[];
    maximum_upload_size: string;
    size_unit: string;
  };
  file: {
    maximum_upload_size: string;
    size_unit: string;
    file_name_field_create: boolean;
    allowed_extensions: string[];
  };
  is_global: boolean;
  is_active: boolean;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  options: IManagementFieldOption[];
}

export interface IManagementFieldOption {
  color: string;
  created_at: string;
  deleted_at: string;
  id: string;
  is_archived: boolean;
  is_default: boolean;
  name: string;
  option_type: string;
  order_number: number;
  updated_at: string;
  value: string;
  value_type: string;
}

export interface ICreateManagementFieldPayload {
  business_id: string;
  body: IManagementField;
}

export interface IUpdateManagementFieldPayload extends ICreateManagementFieldPayload {
  id: string;
}

export interface IReorderManagementFieldsPayload {
  business_id: string;
  body: {
    fields: {
      id: string;
      order_number: number;
    }[];
  };
}
