import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { useBusiness, useUser } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';

import { createTicket } from 'store/features/ticket-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { SelectPriority } from '../@components/SelectPriority';
import { extractFileNameFromUrl } from '../helper';

export const CreateTicketModal = ({ setIsTicketModalOpen }: { setIsTicketModalOpen: (value: SetStateAction<boolean>) => void }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const user = useUser();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { creating } = useAppSelector((state) => state.ticket);

  const onCreateTicket = () => {
    if (!selectedBusiness?.id) return;

    const body = {
      ...form.getFieldsValue(),
      user_id: user?.id,
      business_id: selectedBusiness?.id,
      attachments: [],
    };
    if (form.getFieldValue('attachments')) {
      body.attachments.push({
        url: form.getFieldValue('attachments'),
        filename: extractFileNameFromUrl(form.getFieldValue('attachments')),
      });
    }

    dispatch(createTicket({ body }))
      .unwrap()
      .then(() => setIsTicketModalOpen(false))
      .catch(() => {});
  };

  return (
    <Modal
      width={500}
      open={true}
      styles={{ content: { borderRadius: 10 } }}
      bodyStyle={{ height: 500 }}
      centered
      title={
        <Row justify={'center'}>
          <Typography.Text>{translate('support.contact.submitTicket')}</Typography.Text>
        </Row>
      }
      footer={
        <Row justify={'end'}>
          <Divider />
          <Button onClick={onCreateTicket} loading={creating} disabled={creating} type='primary'>
            {translate('support.contact.createTicket')}
          </Button>
        </Row>
      }
      onCancel={() => setIsTicketModalOpen(false)}>
      <Row className='w-full mt-20'>
        <Form layout='vertical' className='w-full' form={form}>
          <Form.Item name='subject' label={translate('support.contact.subject')}>
            <Input />
          </Form.Item>

          <Form.Item name='priority' label={translate('support.contact.priority')}>
            <SelectPriority />
          </Form.Item>

          <Form.Item name='description' label={translate('support.contact.description')}>
            <Tiptap
              content={form.getFieldValue('description')}
              placeholder={translate('support.contact.ticketDescriptionPlaceholder')}
              onUpdate={(value) => form.setFieldValue('description', value.getHTML())}
            />
          </Form.Item>

          <Form.Item name='attachments' label={translate('support.contact.attachments')}>
            <UploadImage
              name={form.getFieldValue('attachments') && extractFileNameFromUrl(form.getFieldValue('attachments'))}
              onUploadChange={(value) => form.setFieldValue('attachments', value?.url)}
              listType='text'
              text={translate('support.contact.upload')}
            />
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};
