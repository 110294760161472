export enum EPaymentGateways {
  STRIPE = 'STRIPE',
  SQUARE = 'SQUARE',
  AUTHORIZE_NET = 'AUTHORIZE_NET',
  PAYFAST = 'PAYFAST',
  AFTERPAY = 'AFTERPAY',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  BANK_TRANSFER = 'BANK_TRANSFER',
  KLARNA = 'KLARNA',
  PAYPAL = 'PAYPAL',
}

export interface IPaymentGatewaySetting {
  id?: string;
  business_id: string;
  name: EPaymentGateways;
  enabled?: boolean;

  options?:
    | {
        label: string;
        instructions: string;
      }
    | string;
  public_key: string | null;
  secret_key: string | null;
  token: string | null;
  updated_at?: string;
  created_at?: string;
  deleted_at?: null;
}

export interface IPayPalLinksResponse {
  links: { href: string; rel: string }[];
}

export interface IPaymentGatewaySettingResponse {
  payment_setting: IPaymentGatewaySetting;
  onboarding_url: string | IPayPalLinksResponse; // stripe and paypal
}

export interface IUpdatePaymentGatewaySettings {
  id?: string;
  options?:
    | {
        label: string;
        instructions: string;
      }
    | string;
}

export interface IConnectPaymentGatewaySettingsState {
  data: IPaymentGatewaySetting[];
  connecting: boolean;
  disconnecting: boolean;
  loading: boolean;
  editing: boolean;
  paymentIntent: {
    loading: boolean;
    client_secret: string;
  };
}
