import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled } from '@ant-design/icons';
import axios from 'axios';

import { Alert, Button, Col, Collapse, Form, Input, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/lib';
import './ActionFormItems.scss';

import { EMethods } from '@aduvi/types/automation';
import { transormArrayToObject } from '@aduvi/utils/helper';

const axiosMethods = {
  GET: axios.get,
  CREATE: axios.post,
  UPDATE: axios.put,
  DELETE: axios.delete,
};

const axiosCallsWithParameters = ['GET', 'DELETE'];

const methodText: Record<EMethods, string> = {
  CREATE: 'create',
  DELETE: 'delete',
  GET: 'get',
  UPDATE: 'update',
};

export const Webhook = ({ form }: { form: FormInstance }) => {
  Form.useWatch('response', form);
  Form.useWatch('method', form);
  const { t: translate } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const onCallApi = () => {
    setIsLoading(true);
    setError(false);

    const params = axiosCallsWithParameters?.includes(form.getFieldValue('method'))
      ? form.getFieldValue('query_parameters')?.length
        ? {
            params: transormArrayToObject(form.getFieldValue('query_parameters').filter((item: { key: string; value: string }) => item)),
          }
        : undefined
      : form.getFieldValue('body')?.length
      ? transormArrayToObject(form.getFieldValue('body').filter((item: { key: string; value: string }) => item))
      : undefined;

    axiosMethods[form.getFieldValue('method') as EMethods](form.getFieldValue('webhook_url'), params)
      .then(({ data }) => {
        form.setFieldValue('response', data);
        form.setFieldValue('keys', undefined);
        setIsLoading(false);
      })
      .catch(() => {
        form.setFieldValue('response', undefined);
        form.setFieldValue('keys', undefined);
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (!axiosCallsWithParameters.includes(form.getFieldValue('method')) && !form.getFieldValue('body')?.length) {
      form.setFieldValue('body', [undefined]);
    }
    if (!form.getFieldValue('query_parameters')?.length) {
      form.setFieldValue('query_parameters', [undefined]);
    }
  }, [form.getFieldValue('method')]);

  return (
    <>
      <Form.Item name='webhook_url' label={'Webhook URL'}>
        <Input addonBefore='https://' placeholder='Bob Smith' />
      </Form.Item>
      <Form.Item name='method' label={translate('automations.method')} initialValue={EMethods.GET}>
        <Select placeholder={translate('automations.get')} options={Object.values(EMethods)?.map((value) => ({ label: value, value }))} />
      </Form.Item>
      <span className='action-form-items'>
        <Form.List name='query_parameters'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 ? translate('automations.queryParameteres') : ''} required={false} key={field.key}>
                  <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{ background: 'white', padding: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
                    <Col span={11}>
                      <Form.Item name={[index, 'key']}>
                        <Input placeholder={translate('automations.key')} />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item name={[index, 'value']}>
                        <Input placeholder={translate('automations.value')} />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <DeleteFilled
                        onClick={() => (index === 0 ? void 0 : remove(field.name))}
                        disabled={index === 0}
                        style={{ color: index === 0 ? '#cccccc' : '#595959' }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Row justify={'end'}>
                <Button className='mt-20' type='primary' ghost onClick={() => add()}>
                  {translate('automations.addParameter')}
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </span>
      {!axiosCallsWithParameters.includes(form.getFieldValue('method')) ? (
        <span className='action-form-items'>
          <Form.List name='body'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item label={index === 0 ? translate('automations.body') : ''} required={false} key={field.key}>
                    <Row
                      justify={'space-between'}
                      align={'middle'}
                      style={{ background: 'white', padding: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
                      <Col span={11}>
                        <Form.Item name={[index, 'key']}>
                          <Input placeholder={translate('automations.key')} />
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item name={[index, 'value']}>
                          <Input placeholder={translate('automations.value')} />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <DeleteFilled
                          onClick={() => (index === 0 ? void 0 : remove(field.name))}
                          disabled={index === 0}
                          style={{ color: index === 0 ? '#cccccc' : '#595959' }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row justify={'end'}>
                  <Button className='mt-20' type='primary' ghost onClick={() => add()}>
                    {translate('automations.addParameter')}
                  </Button>
                </Row>
              </>
            )}
          </Form.List>
        </span>
      ) : null}

      <Collapse
        collapsible='header'
        defaultActiveKey={['1']}
        className='mt-20'
        items={[
          {
            key: '1',
            label: translate('automations.moreSettings'),
            children: (
              <>
                <Form.Item name={['more_settings', 'authentication']} label={translate('automations.basicAuthentication')}>
                  <Input placeholder={translate('automations.usernameOrPassword')} />
                </Form.Item>
                <span className='action-form-items'>
                  <Form.List
                    name={['more_settings', 'headers']}
                    rules={[
                      {
                        validator: async (_, names) => {
                          if (!names || names.length < 2) {
                            return Promise.reject(new Error(translate('automations.atLeast2Passengers')));
                          }
                        },
                      },
                    ]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item label={index === 0 ? translate('automations.headers') : ''} required={false} key={field.key}>
                            <Row
                              justify={'space-between'}
                              align={'middle'}
                              style={{ background: 'white', padding: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
                              <Col span={11}>
                                <Form.Item name={[index, 'key']}>
                                  <Input placeholder={translate('automations.key')} />
                                </Form.Item>
                              </Col>
                              <Col span={11}>
                                <Form.Item name={[index, 'value']}>
                                  <Input placeholder={translate('automations.value')} />
                                </Form.Item>
                              </Col>
                              <Col span={1}>
                                <DeleteFilled onClick={() => remove(field.name)} style={{ color: '#595959' }} />
                              </Col>
                            </Row>
                          </Form.Item>
                        ))}
                        <Row justify={'end'}>
                          <Button className='mt-20' type='primary' ghost onClick={() => add()}>
                            {translate('automations.addHeader')}
                          </Button>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </span>
              </>
            ),
          },
        ]}
      />
      <Form.Item hidden name={'response'} />
      <Row justify={'end'} className='mt-20'>
        <Button type='primary' style={{ minWidth: '120px' }} loading={isLoading} disabled={isLoading} onClick={onCallApi}>
          {translate('buttons.submit')}
        </Button>
      </Row>
      {form.getFieldValue('response') ? (
        <Spin spinning={isLoading}>
          <span>{translate('automations.response')}</span>
          <div className='code-block-container'>
            <pre className='mt-5'>
              <code>{JSON?.stringify(form.getFieldValue('response'), null, 2)}</code>
            </pre>
          </div>
          <Form.Item name={'keys'} label={translate('automations.keys')} className='mt-20'>
            <Select
              mode='multiple'
              options={Object?.keys(form.getFieldValue('response'))?.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
        </Spin>
      ) : null}
      {error ? (
        <Alert
          message={translate('automations.dataFetchError')}
          description={translate('automations.dataFetchErrorDescription', {
            item: translate(`automations.dataFetchType.${methodText[form.getFieldValue('method') as EMethods]}`),
          })}
          type='error'
          showIcon
          className='mt-20'
        />
      ) : null}
    </>
  );
};
