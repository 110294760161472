import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IGetServicesPayload, IPaginatedResponse, IService } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<IPaginatedResponse<IService>, IGetServicesPayload>({
      query: ({ business_id, params }) => ({
        url: `/businesses/${business_id}/services`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.SERVICE }],
    }),
  }),
});

export const { useGetServicesQuery } = serviceApiSlice;
