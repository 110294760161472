import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface IProps {
  id: string;
  children: React.ReactNode;
}

export const DraggableTableViewColumnContent = ({ id, children }: IProps) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition, isOver } = useSortable({
    id,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        ...style,
        cursor: 'grab',
        paddingLeft: 10,
        borderBottom: isDragging || isOver ? '2px solid #70b6fc' : 'none',
        position: 'relative',
        zIndex: 5,
        rotate: isDragging ? '5deg' : '0deg',
      }}>
      <div>{children}</div>
    </div>
  );
};
