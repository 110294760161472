import { useDroppable } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import './Column.scss';

import { IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import Card from './Card';

export type ColumnType = {
  id: string;
  title: string;
  color: string;
  cards: IEntityWithFields[];
  columnSummaryField?: IEntityField;
  columnSummaryValue?: number;
  managementFields?: IEntityField[];
};

const Column = ({ id, title, cards, color, columnSummaryField, columnSummaryValue, managementFields }: ColumnType) => {
  const { setNodeRef, active } = useDroppable({ id });

  return (
    <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
      <div className='kanban-column-wrapper'>
        <div>
          <p className='column-title' style={{ background: color }}>
            {title} ({cards?.length})
          </p>
          <div className={`cards-wrapper ${active ? 'active' : ''}`} ref={setNodeRef}>
            {cards?.map((card: IEntityWithFields, index) => <Card key={index} card={card} managementFields={managementFields || []} />)}
          </div>
        </div>
        <p className='column-footer' style={{ background: color }}>
          {columnSummaryField ? `${columnSummaryField?.title} : ${columnSummaryValue || 0}` : ''}
        </p>
      </div>
    </SortableContext>
  );
};

export default Column;
