import { ADUVI_DEFAULT_PARTNER_LAYOUT } from '@aduvi/constants';
import { ILayoutResponse, IPartnerLayout } from '@aduvi/types';

export class PartnerLayoutAdapter {
  private layout: ILayoutResponse;

  constructor(layout: ILayoutResponse) {
    this.layout = layout;
  }

  get welcomeText(): string {
    return this.layout?.terminology?.[0]?.welcome || ADUVI_DEFAULT_PARTNER_LAYOUT.welcomeText;
  }

  get legalName(): string {
    return this.layout?.legal_name || ADUVI_DEFAULT_PARTNER_LAYOUT.legalName;
  }

  get authImage(): string {
    return this.layout.auth_page_img ? this.layout.auth_page_img : ADUVI_DEFAULT_PARTNER_LAYOUT.authPageImage;
  }

  get onboardingImage(): string {
    return this.layout.onboarding_page_img ? this.layout.onboarding_page_img : ADUVI_DEFAULT_PARTNER_LAYOUT.onboardingPageImg;
  }

  get logo(): string {
    return this.layout.logo ?? ADUVI_DEFAULT_PARTNER_LAYOUT.logo;
  }

  get getPartnerLayout(): IPartnerLayout {
    return {
      welcomeText: this.welcomeText,
      legalName: this.legalName,
      authImage: this.authImage,
      onboardingImage: this.onboardingImage,
      logo: this.logo,
    };
  }
}
