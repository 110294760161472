import axios from '@aduvi/config/axios';
import { IGetSingleEntityBlackoutDatesResponse, IResponse } from '@aduvi/types';
import { ICreateSingleEntityBlackoutDatesPayload, IGetEntitiesBlackoutDatesPayload, IGetSingleEntityBlackoutDatesPayload } from '@aduvi/types';

export const getEntitiesBlackoutDates = async (
  payload: IGetEntitiesBlackoutDatesPayload,
): Promise<IResponse<IGetSingleEntityBlackoutDatesResponse[]>> => {
  return await axios.get(
    `/businesses/${payload.business_id}/blackout-dates?type=${payload.entity_type}&start=${payload.start_date}&end=${payload.end_date}`,
  );
};

export const getSingleEntityBlackoutDates = async (
  payload: IGetSingleEntityBlackoutDatesPayload,
): Promise<IResponse<IGetSingleEntityBlackoutDatesResponse[]>> => {
  return await axios.get(
    `/businesses/${payload.business_id}/blackout-dates?type=${payload.entity_type}&start=${payload.start_date}&end=${payload.end_date}&id=${payload.entity_id}`,
  );
};

export const createSingleEntityBlackoutDates = async (payload: ICreateSingleEntityBlackoutDatesPayload): Promise<IResponse<string>> => {
  return await axios.post(`/businesses/${payload.business_id}/blackout-dates/create`, payload.body);
};
