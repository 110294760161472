import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Constants } from '@aduvi/constants';
import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { EAuthorized } from '@aduvi/types';

import { getUserData, setAuthorization } from 'store/features/auth-slice';
import { getCountries, getCurrencies, getLayoutByDomain } from 'store/features/common-slice';
import { getEntityTypes } from 'store/features/entity-slice';
import { useAppDispatch } from 'store/hooks';

export const useAppLoad = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const activeTheme = useActiveTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN);

    dispatch(getLayoutByDomain())
      .unwrap()
      .then(async () => {
        if (!token) return dispatch(setAuthorization(EAuthorized.UNAUTHORIZED));

        dispatch(getUserData())
          ?.unwrap()
          ?.then((res) => {
            if (res?.data?.businesses?.length === 0) {
              navigate('/onboarding');
            }
          })
          .catch(() => {});
        await dispatch(getCurrencies());
        await dispatch(getCountries());
        dispatch(setAuthorization(EAuthorized.AUTHORIZED));
        localStorage.removeItem('my_builder_blocks');
      })
      .catch(() => {});
  }, [dispatch]);

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    favicon.href = activeTheme?.icon as string;
    document.title = selectedBusiness?.name || '';

    if (!selectedBusiness?.id) return;

    dispatch(getEntityTypes(selectedBusiness.id));
  }, [selectedBusiness?.id, activeTheme?.icon]);
};
