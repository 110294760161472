import { useTranslation } from 'react-i18next';

import { Col, Form, InputNumber, Row, Select } from 'antd';

import { ETimeUnits } from '@aduvi/types/automation';

export const Wait = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <div className='mb-10'>{translate('automations.duration')}</div>
      <Row gutter={18} justify={'space-between'} align={'middle'}>
        <Col span={12}>
          <Form.Item name={['duration', 'time']}>
            <InputNumber className='w-full' placeholder='2' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['duration', 'range']}>
            <Select placeholder={translate('automations.minutes')} options={Object.values(ETimeUnits).map((value) => ({ label: value, value }))} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
