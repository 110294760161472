import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopOutlined, DownloadOutlined, DownOutlined, EyeOutlined, PlayCircleOutlined, SettingOutlined, StockOutlined } from '@ant-design/icons';
import ContentBox from '@innovastudio/contentbox';
import html2canvas from 'html2canvas';

import { Breadcrumb, Button, Dropdown, Row } from 'antd';
import '@innovastudio/contentbox/public/contentbox/contentbox.css';
import '@innovastudio/contentbuilder/public/contentbuilder/contentbuilder.css';
import './ContentBoxEditor.scss';
import 'antd/dist/reset.css';

import { uploadImage } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

import { getLastIsSectionDiv } from './helpers/getLastPage';
import { CodeIcon } from './CodeIcon';

let builder;

// eslint-disable-next-line react/prop-types
export function ContentBoxEditor({ value, onBack, hideActivity, isBuilderBlock, onSave, onTabChange, onDrawerOpen }) {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [topbarOpacity, setTopbarOpacity] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [editorScreenMode, setEditorScreenMode] = useState('desktop-lg');

  useEffect(() => {
    builder = new ContentBox({
      // More Settings
      canvas: true, // Enable the new canvas mode
      previewURL: 'preview.html',
      controlPanel: true, // Enable the new control panel
      iframeSrc: 'blank.html', // Enable resizable content area
      topSpace: true, // Needed when using custom toolbar
      iframeCentered: true,
      onStart: () => {
        setTopbarOpacity(1); // Shows custom topbar (by setting opacity to 1)
      },
      onChange: () => {
        if (isBuilderBlock) {
          builder.loadHtml(getLastIsSectionDiv(builder.html()));
        }
      },
      // Hide uneeded buttons (we'll add these buttons on the topbar)
      toggleDeviceButton: false,
      deviceButtons: false,

      // Default file/image picker
      imageSelect: '/assets.html',
      videoSelect: '/assets.html',
      audioSelect: '/assets.html',
      fileSelect: '/assets.html',
      mediaSelect: '/assets.html', // for image & video
      // You can replace the default file/image picker with your own asset/file manager application
      // or use Files.js Asset Manager: https://innovastudio.com/asset-manager

      // Template Sets
      templates: [
        {
          url: 'assets/templates-simple/templates.js',
          path: 'assets/templates-simple/',
          pathReplace: [],
          numbering: true,
          showNumberOnHover: true,
        },
        {
          url: 'assets/templates-quick/templates.js',
          path: 'assets/templates-quick/',
          pathReplace: [],
          numbering: true,
          showNumberOnHover: true,
        },
        {
          url: 'assets/templates-animated/templates.js',
          path: 'assets/templates-animated/',
          pathReplace: [],
          numbering: true,
          showNumberOnHover: true,
        },
      ],
      snippetCategories: [
        [125, 'Custom'],
        [120, 'Basic'],
        [118, 'Article'],
        [101, 'Headline'],
        [119, 'Buttons'],
        [102, 'Photos'],
        [103, 'Profile'],
        [116, 'Contact'],
        [104, 'Products, Services'],
        [105, 'Features'],
        [108, 'Skills'],
        [109, 'Achievements'],
        [106, 'Process'],
        [107, 'Pricing'],
        [110, 'Quotes'],
        [111, 'Partners'],
        [112, 'As Featured On'],
        [113, 'Page Not Found'],
        [114, 'Coming Soon'],
        [115, 'Help, FAQ'],
      ],
      defaultSnippetCategory: 125,
      slider: 'glide',
      navbar: true,
    });

    if (!isBuilderBlock) {
      builder.addButton({
        pos: 2,
        title: 'Animation',
        html: '<svg class="is-icon-flex" style="fill:rgba(0, 0, 0, 0.7);width:14px;height:14px;"><use xlink:href="#icon-wand"></use></svg>',
        onClick: () => {
          builder.openAnimationPanel();
        },
      });
    }

    builder.addButton({
      pos: 3,
      title: 'Timeline Editor',
      html: '<svg><use xlink:href="#icon-anim-timeline"></use></svg>',
      onClick: () => {
        builder.openAnimationTimeline();
      },
    });

    builder.addButton({
      pos: 5,
      title: 'Clear Content',
      html: '<svg class="is-icon-flex"><use xlink:href="#icon-eraser"></use></svg>',
      onClick: () => {
        builder.clear();
      },
    });

    let html =
      value !== '' && value !== undefined
        ? value
        : `<div class="is-section is-section-100 is-box type-system-ui last-box min-height-60 scroll-target">
    <div class="is-overlay">

    </div>
    <div class="is-container v2 size-18 leading-14 is-content-1300">
        <div class="row">
            <div class="column">
                <h1 class="leading-11 size-88 text-center">Start building...</h1>
            </div>
        </div>
    </div>
</div>`;

    let mainCss = '';
    let sectionCss = '';

    builder.loadHtml(html);

    builder.loadStyles(mainCss, sectionCss); // Load styles

    if (isBuilderBlock) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const element = doc.body.firstChild;

      element.classList.add('initial-div-class');

      const updatedHtmlString = element.outerHTML;

      builder.loadHtml(updatedHtmlString);
    }

    return () => {
      // cleanup
      builder.destroy();
      builder = null;
    };
  }, []);

  const items = [
    {
      key: '1',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<svg style="width:18px;height:18px; ${
              editorScreenMode === 'desktop-lg' ? 'color:#1890FF' : ''
            }"><use xlink:href="#icon-device-desktop"></use></svg>`,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('desktop-lg');
        setScreenMode('desktop-lg');
      },
    },
    {
      key: '2',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<svg style="width:18px;height:18px;${
              editorScreenMode === 'desktop' ? 'color:#1890FF' : ''
            }"><use xlink:href="#icon-device-laptop"></use></svg>`,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('desktop');
        setScreenMode('desktop');
      },
    },
    {
      key: '3',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<svg style="width:18px;height:18px;transform:rotate(-90deg); ${
              editorScreenMode === 'tablet-landscape' ? 'color:#1890FF' : ''
            }"><use xlink:href="#icon-device-tablet"></use></svg>`,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('tablet-landscape');
        setScreenMode('tablet-landscape');
      },
    },
    {
      key: '4',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<svg  style="width:18px;height:18px; ${
              editorScreenMode === 'tablet' ? 'color:#1890FF' : ''
            }"><use xlink:href="#icon-device-tablet"></use></svg>`,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('tablet');
        setScreenMode('tablet');
      },
    },
    {
      key: '5',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `<svg  style="width:18px;height:18px; ${
              editorScreenMode === 'mobile' ? 'color:#1890FF' : ''
            }"><use xlink:href="#icon-device-mobile"></use></svg>`,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('mobile');
        setScreenMode('mobile');
      },
    },
    {
      key: '6',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: `
    <svg  style="width:18px;height:18px; ${editorScreenMode === 'fullview' ? 'color:#1890FF' : ''}"><use xlink:href="#icon-fullview"></use></svg>
    `,
          }}
        />
      ),
      onClick: () => {
        setEditorScreenMode('fullview');
        setScreenMode('fullview');
      },
    },
  ];

  function back() {
    onBack(false);
  }

  async function save() {
    var html = builder.html();

    const iframe = document.getElementById('ifrBuilder');
    if (!iframe) {
      onSave(html, '');
    }
    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDocument) {
      onSave(html, '');
    }
    const element = iframeDocument.querySelector('.is-section');
    if (!element) {
      onSave(html, '');
    }

    try {
      const canvas = await html2canvas(element);
      canvas.toBlob(async (blob) => {
        if (blob) {
          const formData = new FormData();
          formData.append('file', blob);

          dispatch(uploadImage(formData))
            .unwrap()
            .then((res) => {
              onSave(html, res.data?.file_name);
            })
            ?.catch(() => {
              onSave(html, '');
            });
        }
      });
    } catch (error) {
      onSave(html, '');
    }
  }

  function publish() {}

  function undo() {
    if (!builder) return;
    builder.undo();
  }

  function redo() {
    if (!builder) return;
    builder.redo();
  }

  function setScreenMode(mode) {
    if (!builder) return;
    builder.setScreenMode(mode);
  }

  function download() {
    if (!builder) return;
    builder.download();
  }

  function viewHtml() {
    if (!builder) return;
    builder.viewHtml();
  }

  function preview() {
    if (!builder) return;

    let html = builder.html();
    localStorage.setItem('preview-html', html);
    let mainCss = builder.mainCss();
    localStorage.setItem('preview-maincss', mainCss);
    let sectionCss = builder.sectionCss();
    localStorage.setItem('preview-sectioncss', sectionCss);

    window.open('/preview.html', '_blank').focus();
  }

  function togglePanel() {
    if (!builder) return;
    builder.toggleEditPanel();
  }

  const menuItems = [
    {
      label: 'Preview',
      key: '1',
      icon: <EyeOutlined />,
      onClick: preview,
    },
    {
      label: 'Configure',
      key: '2',
      icon: <SettingOutlined />,
      onClick: () => {
        onTabChange('configure');
        onDrawerOpen();
      },
    },
    {
      label: 'Edit Code',
      key: '3',
      icon: <CodeIcon />,
      onClick: viewHtml,
    },
    !hideActivity
      ? {
          label: 'Activity',
          key: '4',
          icon: <StockOutlined />,
          onClick: () => {
            onTabChange('activity');
            onDrawerOpen();
          },
        }
      : undefined,
    {
      label: 'Download',
      key: '5',
      icon: <DownloadOutlined />,
      onClick: download,
    },
    {
      label: 'Send',
      key: '6',
      icon: <PlayCircleOutlined />,
    },
  ];

  const menuProps = {
    items: menuItems.filter((item) => item),
  };

  return (
    <>
      <Row justify={'space-between'} className='content-box-editor-wrapper'>
        <div className='builder-ui keep-selection custom-topbar' style={{ opacity: topbarOpacity }} data-tooltip>
          <div>
            <Breadcrumb
              items={[
                { title: translate('proposals.proposals'), onClick: back, className: 'cursor-pointer' },
                {
                  title: 'Templates',
                },
              ]}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='mr-5'>
            <button className='btn-undo' onClick={undo} title='Undo'>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
              <svg><use xlink:href="#icon-undo"></use></svg>
              `,
                }}
              />
            </button>

            <button className='btn-redo' onClick={redo} title='Redo'>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
              <svg><use xlink:href="#icon-redo"></use></svg>
              `,
                }}
              />
            </button>
            <button className='btn-togglepanel' data-button='togglepanel' onClick={togglePanel} title='Toggle Edit Panel'>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
              <svg><use xlink:href="#icon-pencil"></use></svg>
              `,
                }}
              />
            </button>

            <button onClick={() => setOpenDropdown((prev) => !prev)}>
              <Dropdown
                menu={{ items }}
                placement='bottom'
                className='w-full'
                trigger={['click']}
                open={openDropdown}
                onOpenChange={(open) => {
                  if (!open && openDropdown) setOpenDropdown(false);
                }}>
                <DesktopOutlined style={{ marginRight: '2px' }} />
              </Dropdown>
            </button>

            <Dropdown.Button
              menu={menuProps}
              onClick={preview}
              style={{ borderRadius: '0px', width: 'auto', marginLeft: '4px', marginRight: '8px' }}
              icon={<DownOutlined style={{ width: '12px', height: '12px', color: '#D9D9D9' }} />}>
              <EyeOutlined /> Preview
            </Dropdown.Button>

            <Button type='primary' onClick={save} style={{ color: 'white', backgroundColor: '#1890FF', marginRight: '8px' }}>
              Save
            </Button>

            <Button type='primary' onClick={publish} style={{ color: 'white', backgroundColor: '#1890FF' }}>
              Publish
            </Button>
          </div>
        </div>
      </Row>
    </>
  );
}
