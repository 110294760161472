import { IPartnerUser } from './user';

export enum ESupportModalTabs {
  TROUBLESHOOTER = 'Troubleshooter',
  DOCUMENTATION = 'Documentation',
  ACADEMY = 'Academy',
  LIVE_CHAT = 'Live Chat',
  CONTACT = 'Contact',
}

export enum ETicketStatus {
  RESOLVED = 'RESOLVED',
  OPEN = 'OPEN',
}

export enum ETicketPriority {
  HIGH = 'HIGH',
  URGENT = 'URGENT',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW ',
}

export interface ITicket {
  attachments: IAttachments[];
  business_id: string;
  created_at: string;
  deleted_at: string;
  description: string;
  id: string;
  priority: string;
  rating?: number;
  status: string;
  subject: string;
  updated_at: string;
  user_id: string;
  user?: {
    created_at: string;
    deleted_at: string;
    email: string;
    id: string;
    updated_at: string;
    partner_users: IPartnerUser[];
  };
}

export interface ICreateTicketPayload {
  body: {
    business_id: string;
    user_id: string;
    subject: string;
    priority: string;
    description: string;
    attachments?: [
      {
        filename: string;
        url: string;
      },
    ];
    rating?: number;
  };
}

export interface IEditTicketPayload {
  ticket_id: string;
  body: {
    business_id: string;
    user_id: string;
    priority: string;
  };
}

export interface ISendMessagePayload {
  business_id: string;
  ticket_id: string;
  body: {
    message: string;
    attachments?: {
      filename: string;
      url: string;
    }[];
  };
}

export interface IChat {
  attachments: IAttachments[];
  business_support_ticket_id?: string;
  created_at?: string;
  deleted_at?: string;
  id?: string;
  message: string;
  updated_at?: string;
  user_id?: string;
  user?: {
    created_at: string;
    deleted_at: string;
    email: string;
    id: string;
    updated_at: string;
    partner_users: IPartnerUser[];
    admin_users: IAdminUsers[];
  };
}

export interface IAttachments {
  fileName: string;
  url: string;
}

export interface IAdminUsers {
  created_at: string;
  deleted_at: string;
  first_name: string;
  id: string;
  language: string;
  last_name: string;
  phone_number: string;
  profile_picture: string;
  role: string;
  updated_at: string;
  user_id: string;
}

export interface IUpdateTicketResponse {
  business_id: string;
  id: string;
  priority: string;
  user_id: string;
}

export interface IReOpenTicketResponse {
  attachments: IAttachments;
  business_id: string;
  created_at: string;
  deleted_at: string;
  description: string;
  id: string;
  priority: string;
  rating: number;
  status: string;
  subject: string;
  updated_at: string;
  user_id: string;
}

export interface IRateTicketResponse {
  attachments: IAttachments;
  business_id: string;
  created_at: string;
  deleted_at: string;
  description: string;
  id: string;
  priority: string;
  rating: number;
  status: string;
  subject: string;
  updated_at: string;
  user_id: string;
}

export interface ITicketState {
  tickets: ITicket[];
  chat: IChat[];
  selectedTicket?: ITicket;
  creating: boolean;
  loading: boolean;
  updating: boolean;
  sending: boolean;
}
