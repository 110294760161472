import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICreateWidget, IDeleteWidgetPayload, IUpdateWidget, IWidget, IWidgetState } from '@aduvi/types/widget';

import * as Widget from 'store/services/widget.service';

const initialState: IWidgetState = {
  widgets: [],
  selectedWidget: undefined,
  loading: false,
  loadingWidget: false,
  creating: false,
  updating: false,
};

export const getWidgets = createAsyncThunk('widget/get-widgets', async (params: { businessId: string; app: string }, { rejectWithValue }) => {
  try {
    return await Widget.getWidgets(params.businessId, params.app);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getWidget = createAsyncThunk('widget/get-widget', async (params: { businessId: string; widgetId: string }, { rejectWithValue }) => {
  try {
    return await Widget.getWidget(params.businessId, params.widgetId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPublicWidget = createAsyncThunk(
  'widget/get-public-widget',
  async (params: { businessId: string; widgetId: string }, { rejectWithValue }) => {
    try {
      return await Widget.getPublicWidget(params.businessId, params.widgetId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWidget = createAsyncThunk('widget/create-widget', async (params: ICreateWidget, { rejectWithValue }) => {
  try {
    return await Widget.createWidget(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateWidget = createAsyncThunk('widget/update-widget', async (params: IUpdateWidget, { rejectWithValue }) => {
  try {
    return await Widget.updateWidget(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteWidget = createAsyncThunk('widget/delete-widget', async (params: IDeleteWidgetPayload, { rejectWithValue }) => {
  try {
    return await Widget.deleteWidget(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setSelectedWidget: (state, action: PayloadAction<IWidget | undefined>) => {
      state.selectedWidget = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWidgets.fulfilled, (state, action) => {
        state.loading = false;
        state.widgets = action.payload.data;
      })
      .addCase(getWidgets.rejected, (state) => {
        state.loading = false;
        state.widgets = [];
      })
      .addCase(getWidget.pending, (state) => {
        state.loadingWidget = true;
      })
      .addCase(getWidget.fulfilled, (state, action) => {
        state.loadingWidget = false;
        state.selectedWidget = action.payload.data;
      })
      .addCase(getWidget.rejected, (state) => {
        state.loadingWidget = false;
        state.selectedWidget = undefined;
      })
      .addCase(createWidget.pending, (state) => {
        state.creating = true;
      })
      .addCase(createWidget.fulfilled, (state, action) => {
        state.creating = false;
        state.widgets.push(action.payload.data);
        state.selectedWidget = action.payload.data;
      })
      .addCase(createWidget.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateWidget.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateWidget.fulfilled, (state, action) => {
        state.updating = false;
        const updatedWidget = action.payload.data;
        state.widgets = state.widgets.map((widget) => (widget.id === updatedWidget.id ? updatedWidget : widget));
        state.selectedWidget = updatedWidget;
      })
      .addCase(updateWidget.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteWidget.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteWidget.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.widgets) {
          state.widgets = state.widgets.filter((widget) => widget.id !== meta.arg.widget_id);
          state.selectedWidget = state.widgets?.[state?.widgets?.length - 1] || undefined;
        }
      })
      .addCase(deleteWidget.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const widgetReducer = widgetSlice.reducer;
export const { setSelectedWidget } = widgetSlice.actions;
