import { Col, Row, Typography } from 'antd';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { ICustomField } from '@aduvi/types/entity';

export const EntityRow = ({ field }: { field: ICustomField }) => {
  return (
    <Row justify={'space-between'} key={field?.id} className='order py-10'>
      <Col>
        <Typography.Paragraph className='mb-0 fs-14 fw-bold'>{field?.title}</Typography.Paragraph>
      </Col>
      <Col>
        <Typography.Paragraph className='mb-0 column-value'>
          <CustomFieldValue field={field} />
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};
