import axios from '@aduvi/config/axios';
import { ICreateTicketPayload, IDeleteTicketPayload, IGetTicketByIdPayload, IResponse, ITicket, IUpdateTicketPayload } from '@aduvi/types';
import { IBulkSendEmail, IEntityType, IEntityWithFields, IGetPaginatedEntities, IUpsertEntity } from '@aduvi/types/entity';

export const getEntityTypes = async (businessId: string): Promise<IResponse<IEntityType[]>> => {
  return await axios.get(`/businesses/${businessId}/entity-types`);
};

export const getCoreEntityTypes = async (): Promise<IResponse<IEntityType[]>> => {
  return await axios.get(`/core-entity-types`);
};

export const getEntities = async (
  businessId: string,
  entityTypeId: string,
  params?: {
    status?: string;
    page?: number;
    size?: number;
    view_display?: string;
    field_id?: string;
    start_date?: string;
    end_date?: string;
    list_value_id?: string;
    paginate?: boolean;
  },
): Promise<IResponse<IGetPaginatedEntities>> => {
  return await axios.get(`/businesses/${businessId}/entity-types/${entityTypeId}/entities`, { params });
};

export const createEntity = async (payload: IUpsertEntity): Promise<IResponse<IEntityWithFields>> => {
  return await axios.post(`/businesses/${payload.business_id}/entity-types/${payload.entity_type_id}/entity`, payload);
};

export const editEntity = async (payload: IUpsertEntity): Promise<IResponse<IEntityWithFields>> => {
  return await axios.put(`/businesses/${payload.business_id}/entity-types/${payload.entity_type_id}/entity/${payload.id}`, payload);
};

export const getEntity = async (businessId: string, entityTypeId: string, entityId: string): Promise<IResponse<IEntityWithFields>> => {
  return await axios.get(`/businesses/${businessId}/entity-types/${entityTypeId}/entity/${entityId}`);
};

export const deleteEntity = async (businessId: string, entityTypeId: string, entityId: string, entityTypeName: string) => {
  return await axios.delete(`/businesses/${businessId}/entity-types/${entityTypeId}/entity/${entityId}?title=${entityTypeName}`);
};

export const createTicket = async (payload: ICreateTicketPayload): Promise<IResponse<ITicket>> => {
  return await axios.post(`/businesses/${payload.business_id}/events/${payload.event_id}/tickets`, payload.body);
};

export const updateTicket = async (payload: IUpdateTicketPayload): Promise<IResponse<ITicket>> => {
  return await axios.put(`/businesses/${payload.business_id}/events/${payload.event_id}/tickets/${payload.ticket_id}`, payload.body);
};

export const getTicketById = async (payload: IGetTicketByIdPayload): Promise<IResponse<ITicket>> => {
  return await axios.get(`/businesses/${payload.business_id}/events/${payload.event_id}/tickets/${payload.ticket_id}`);
};

export const deleteTicket = async (payload: IDeleteTicketPayload): Promise<void> => {
  return await axios.delete(
    `/businesses/${payload.business_id}/events/${payload.event_id}/tickets/${payload.ticket_id}?title=${payload.ticket_name}`,
  );
};

export const cloneTicket = async (businessId: string, eventId: string, ticketId: string): Promise<IResponse<ITicket>> => {
  return await axios.post(`/businesses/${businessId}/events/${eventId}/tickets/${ticketId}/clone`);
};

export const bulkDelete = async (businessId: string, entityTypeId: string, entity_ids: string[], entityTypeName: string): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/entity-types/${entityTypeId}/entities?title=${entityTypeName}`, {
    params: { entity_ids },
    paramsSerializer: {
      indexes: false,
    },
  });
};

export const bulkDuplicate = async (businessId: string, entityTypeId: string, entity_ids: string[]): Promise<IResponse<IEntityWithFields>> => {
  return await axios.post(`/businesses/${businessId}/entity-types/${entityTypeId}/entities/clone`, { entity_ids });
};

export const bulkSendEmail = async (businessId: string, entityTypeId: string, payload: IBulkSendEmail): Promise<void> => {
  return axios.post(`/businesses/${businessId}/entity-types/${entityTypeId}/send-email-to-contacts`, payload);
};

export const exportEntities = async (businessId: string, entityTypeId: string): Promise<string> => {
  return axios.get(`/businesses/${businessId}/entity-types/${entityTypeId}/export`);
};
