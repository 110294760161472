import { MouseEvent as ReactMouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Checkbox, Col, Form, FormInstance, FormListFieldData, FormListOperation, Input, Popconfirm, Row } from 'antd';

import { ColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { IManagementFieldOption } from '@aduvi/types';
import { ICustomFieldOption } from '@aduvi/types';
import { determinePickerColor } from '@aduvi/utils/helper';

interface ISortableFormItem {
  id: number;
  field: FormListFieldData;
  subField: FormListFieldData;
  subOptions: FormListOperation;
  form: FormInstance;
}

export const SortableFormItem = ({ id, subField, subOptions, form, field }: ISortableFormItem) => {
  const { t: translate } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const archiveOption = (e: ReactMouseEvent<HTMLElement, MouseEvent> | undefined) => {
    e?.stopPropagation();

    const fieldToArchive = form.getFieldValue(['typed_options', field.key, 'options'])[subField.name];
    if (!fieldToArchive?.id) return subOptions.remove(subField.name);

    const options = form.getFieldValue(['typed_options', field.key, 'options']);
    form.setFieldValue(
      ['typed_options', 0, 'options'],
      options.map((option: ICustomFieldOption) => (fieldToArchive.id === option.id ? { ...option, is_archived: true } : option)),
    );
  };

  const handleDefaultChange = (checked: boolean) => {
    if (checked) {
      const options = form.getFieldValue(['typed_options', field.key, 'options']);
      const currentOption = options[subField.name];
      const currentValueType = currentOption?.value_type;

      const updatedOptions = options.map((option: IManagementFieldOption, index: number) =>
        option.value_type === currentValueType ? { ...option, is_default: index === subField.name } : option,
      );

      form.setFieldValue(['typed_options', field.key, 'options'], updatedOptions);
    }
  };

  if (form.getFieldValue(['typed_options', field.key, 'options'])[subField.name]?.is_archived) return <></>;

  return (
    <div style={style} {...attributes}>
      <Row className='units-users-list-item w-full' align={'middle'} justify={'space-between'}>
        <div ref={setNodeRef} {...listeners} style={{ cursor: 'grab' }}>
          <Col className='icon-wrapper'>
            <DragOutlined />
          </Col>
        </div>
        <Form.Item name={[subField.name, 'value']} style={{ width: '70%' }} rules={[{ required: true }]}>
          <Input placeholder={translate('settings.jobManagement.name')} style={{ borderRadius: '4px' }} onClick={(e) => e.stopPropagation()} />
        </Form.Item>
        <Form.Item valuePropName='checked' name={[subField.name, 'is_default']} initialValue={false}>
          <Checkbox defaultChecked={false} onChange={(e) => handleDefaultChange(e.target.checked)} />
        </Form.Item>
        <Form.Item name={[subField.name, 'color']} initialValue={'#1890ff'} getValueFromEvent={determinePickerColor}>
          <ColorPicker defaultValue={'#1890ff'} />
        </Form.Item>
        <Col className='icon-wrapper'>
          <Popconfirm
            title={translate('components.customFieldsDrawer.archiveFieldTitle')}
            description={translate('components.customFieldsDrawer.archiveFieldDescription')}
            onCancel={(e) => e?.stopPropagation()}
            onConfirm={(e) => archiveOption(e)}>
            <DeleteOutlined className='fs-22' />
          </Popconfirm>
        </Col>
      </Row>
    </div>
  );
};
