import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, FileImageOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Avatar, Button, Col, Dropdown, Image, MenuProps, Popconfirm, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './Products.scss';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useDebounce, useLongPress, useUserPermissionCheck } from '@aduvi/hooks';
import { IBusiness } from '@aduvi/types/business';
import { IProduct } from '@aduvi/types/products';
import { Notification } from '@aduvi/utils/notification';

import { cloneProduct, deleteProduct, getProducts, resetProductState, setSelectedProduct } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AssetsType } from '../Assets.hook';
import { StoreEmptyText } from '../services/services-table/helper';

import { ProductsDrawer } from './products-drawer/ProductsDrawer';

export const Products = ({ searchKey, currentTab }: { searchKey: string; currentTab?: AssetsType }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { handleMouseDown, handleMouseUp, checkLongPress } = useLongPress();

  const [showProductsDrawer, setShowProductsDrawer] = useState(false);

  const { products, loading, selectedProduct } = useAppSelector((state) => state.products);

  const columns = useTableColumns(setShowProductsDrawer, selectedProduct, selectedBusiness);
  const debouncedSearchKey = useDebounce(searchKey) || undefined;

  const onGetProducts = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id) return;
      dispatch(resetProductState());
      dispatch(
        getProducts({
          business_id: selectedBusiness?.id,
          params: {
            page,
            size,
            search: debouncedSearchKey,
          },
        }),
      );
    },
    [debouncedSearchKey, selectedBusiness?.id],
  );

  useEffect(() => {
    onGetProducts(1, 10);
  }, [debouncedSearchKey, selectedBusiness?.id]);

  return (
    <Row className='products-table-wrapper'>
      <Col xs={24} md={24}>
        <Table
          rowKey='id'
          className='w-full'
          rowClassName='cursor-pointer'
          scroll={{ x: 'calc(400px + 50%)' }}
          loading={loading}
          columns={columns}
          dataSource={products?.data}
          pagination={{
            total: products?.total,
            pageSize: products?.per_page,
            onChange: (page, pageSize) => {
              onGetProducts(page, pageSize);
            },
          }}
          onRow={(record) => ({
            onMouseDown: handleMouseDown,
            onMouseUp: handleMouseUp,
            onClick: (e) => {
              if (!checkLongPress()) {
                e.stopPropagation();
                e.preventDefault();
                dispatch(setSelectedProduct(record));
                setShowProductsDrawer(true);
              }
            },
          })}
          locale={{
            emptyText: <StoreEmptyText currentTab={currentTab} setShowDrawer={() => setShowProductsDrawer(true)} />,
          }}
        />
        {showProductsDrawer && <ProductsDrawer open={showProductsDrawer} onClose={() => setShowProductsDrawer(false)} />}
      </Col>
    </Row>
  );
};

const useTableColumns = (
  setShowProductsDrawer: (prop: boolean) => void,
  selectedProduct?: IProduct,
  selectedBusiness?: IBusiness,
): ColumnsType<IProduct> => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const userDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('userManagement.permissionColumn.edit'),
      onClick: () => setShowProductsDrawer(true),
    },
    {
      key: '1',
      label: translate('assets.services.clone'),
      icon: <CopyOutlined />,
      onClick: () => {
        if (selectedBusiness?.id && selectedProduct?.id) {
          dispatch(cloneProduct({ businessId: selectedBusiness?.id, productId: selectedProduct?.id }))
            .unwrap()
            .then(() => {
              Notification.success({
                title: translate('common.success'),
                description: translate('common.itemClonedSuccessfully', { item: translate('assets.products.product') }),
              });
            });
        }
      },
    },
    {
      key: '2',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('assets.productTable.deleteProduct')}
          description={translate('assets.productTable.description')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          disabled={!useUserPermissionCheck(PERMISSIONS.PRODUCTS.DELETE)}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedProduct || !selectedBusiness) return;
            dispatch(
              deleteProduct({
                product_id: selectedProduct?.id,
                business_id: selectedBusiness?.id,
                product_name: selectedProduct?.name,
              }),
            );
          }}>
          {translate('assets.productTable.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.PRODUCTS.DELETE),
    },
  ];

  return [
    {
      title: translate('assets.products.product'),
      width: '40%',
      render: (product: IProduct) => {
        if (product?.image) {
          return (
            <Row align={'middle'}>
              <Space size={5}>
                <Col onClick={(e) => e.stopPropagation()}>
                  <Image width={40} height={50} src={product?.image} />
                </Col>
                <Col>{product.name}</Col>
              </Space>
            </Row>
          );
        }

        return (
          <Space>
            <Col>
              <Avatar shape='square' style={{ width: 40, height: 50 }} icon={<FileImageOutlined />} />
            </Col>
            <Col>{product.name} </Col>
          </Space>
        );
      },
    },
    {
      title: translate('assets.products.type'),
      dataIndex: 'product_type',
    },
    {
      title: translate('assets.products.category'),
      dataIndex: ['category', 'name'],
    },
    {
      title: translate('assets.products.price'),
      render: (record: IProduct) => <PriceDisplay price={record?.price || 'N/A'} />,
    },
    {
      render: (_, record) => (
        <Row className='w-full' justify='end' onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={{ items: userDropdownItems }} trigger={['click']} overlayClassName='user-settings'>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setSelectedProduct(record));
              }}
              icon={<EllipsisOutlined />}
              type='text'
            />
          </Dropdown>
        </Row>
      ),
    },
  ];
};
