import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Modal, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusiness, useUser } from '@aduvi/hooks';
import { ISupportCallTrackingPayload } from '@aduvi/types/support-call-tracking';

import { createSupportCalls } from 'store/features/call-tracking-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  open: boolean;
  phoneNumber: string;
  onCancel: () => void;
}

export const RequestCallModal = ({ open, phoneNumber, onCancel }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const user = useUser();
  const selectedBusiness = useBusiness();

  const { loading } = useAppSelector((state) => state.supportCallTracking);

  const handleConfirm = async () => {
    if (!selectedBusiness?.id) return;
    await form
      .validateFields()
      .then(async (values) => {
        const payload: ISupportCallTrackingPayload = {
          business_id: selectedBusiness?.id,
          user_id: user?.id,
          phone_number: phoneNumber,
          call_request_reason: values.issue,
        };

        if (open) {
          await dispatch(createSupportCalls({ payload }));
        }
      })
      .then(() => {
        form.resetFields();
        onCancel();
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={<Typography.Text className='flex-center fs-16'> {translate('support.contact.callRequest')}</Typography.Text>}
      centered
      open={open}
      onCancel={onCancel}
      footer={[
        <Row key='buttons' className='w-full flex' justify='space-around'>
          <Button key='cancel'>{translate('support.contact.cancel')}</Button>,
          <Button key='confirm' type='primary' onClick={handleConfirm} loading={loading} disabled={loading}>
            {translate('support.contact.confirm')}
          </Button>
        </Row>,
      ]}>
      <div className='flex-center'>
        <Typography.Text className='fs-16'>
          {translate('support.contact.thanks')}
          <Typography.Text strong className='fs-16'>
            {` +${phoneNumber} `}
          </Typography.Text>
          {translate('support.contact.asap')}
        </Typography.Text>
      </div>
      <Form layout='vertical' form={form}>
        <Typography.Text className='flex-center pt-20 pb-10'>{translate('support.contact.tellUsReason')}</Typography.Text>
        <Form.Item name='issue'>
          <Input.TextArea style={{ height: '125px' }} placeholder={translate('support.contact.howCanWeHelp')} value={form.getFieldValue('issue')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
