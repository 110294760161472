import { useTranslation } from 'react-i18next';

import { Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { EFieldDataValueType, IEntityField } from '@aduvi/types';
import { ECurrencySign, ENumberFormat } from '@aduvi/types/automation';
import { toHumanReadable } from '@aduvi/utils/helper';

export const FormatNumber = ({ form, entityFields }: { form: FormInstance; entityFields?: IEntityField[] }) => {
  const { t: translate } = useTranslation();

  Form.useWatch(['format', 'formatter'], form);

  const formatsWithDecimal = [ENumberFormat.DECIMAL_PLACES, ENumberFormat.PERCENTAGE, ENumberFormat.CURRENCY, ENumberFormat.FRACTION];

  return (
    <>
      <Form.Item name={['data_source', 'fields']} label={translate('automations.sourceData')}>
        <Select
          mode='multiple'
          placeholder={translate('automations.pleaseSelect')}
          options={entityFields
            ?.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_NUMBERS)
            .map((item) => ({ label: item.title, value: item.id }))}
        />
      </Form.Item>
      <Form.Item name={['format', 'formatter']} label={translate('automations.newFormat')}>
        <Select
          placeholder={translate('automations.pleaseSelect')}
          options={Object.values(ENumberFormat)?.map((value) => ({ label: toHumanReadable(value), value }))}
        />
      </Form.Item>
      <Form.Item
        name={['format', 'decimal_places']}
        label={translate('automations.decimalPlaces')}
        hidden={!formatsWithDecimal.includes(form.getFieldValue(['format', 'formatter']))}>
        <InputNumber className='w-full' placeholder='2' />
      </Form.Item>
      <Form.Item
        name={['format', 'currency_sign']}
        label={translate('automations.currencySign')}
        hidden={form.getFieldValue(['format', 'formatter']) !== ENumberFormat.CURRENCY}>
        <Select className='w-full' placeholder='$' options={Object.values(ECurrencySign)?.map((value) => ({ label: value, value }))} />
      </Form.Item>
    </>
  );
};
