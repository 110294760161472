import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Row, Typography } from 'antd';
import './DocumentationCard.scss';

interface IProps {
  title?: string;
  description?: string;
}

export const DocumentationCard = ({ title, description }: IProps) => {
  const { t: translate } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Row className='mb-10 mr-20 p-10'>
      <Col span={24}>
        <Card bodyStyle={{ padding: 20 }} className='support-documentation'>
          <Row style={{ flexDirection: 'column' }}>
            <Typography.Text className='fs-16'>{title}</Typography.Text>
            <Typography.Paragraph type='secondary'>
              <div className={isExpanded ? '' : 'truncated-content'} dangerouslySetInnerHTML={{ __html: description || '' }} />
              {description && (
                <Typography.Link onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? translate('support.documentation.readLess') : translate('support.documentation.readMore')}
                </Typography.Link>
              )}
            </Typography.Paragraph>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
