import { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useDebounce, useUserPermissionCheck } from '@aduvi/hooks';

import { useGetBusinessUsersQuery } from 'store/rtk-slice/user-slice';

export const SelectUser = (props: SelectProps & { team_id?: string }) => {
  const selectedBusiness = useBusiness();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchKey = useDebounce(searchTerm, 300);

  const hasPermissionToViewUsers = useUserPermissionCheck(PERMISSIONS.USERS.VIEW);

  const { data: users, isFetching } = useGetBusinessUsersQuery(
    { business_id: selectedBusiness?.id as string, params: { page: 1, size: 10, search: debouncedSearchKey } },
    { skip: !selectedBusiness?.id || !hasPermissionToViewUsers },
  );

  return (
    <Select {...props} showSearch loading={isFetching} onSearch={(value) => setSearchTerm(value)} filterOption={false}>
      {users?.data.map((user, index) => (
        <Select.Option key={index} value={user.id}>
          {`${user.first_name} ${user.last_name}`}
        </Select.Option>
      ))}
    </Select>
  );
};
