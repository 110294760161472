import { CalendarOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Col } from 'antd';

import { IEntityWithFields, IFieldData } from '@aduvi/types/entity';
import { ETaskStatus } from '@aduvi/types/fields';
import { EFieldDataValueType } from '@aduvi/types/fields';
import { toHumanReadable } from '@aduvi/utils/helper';

import { StatusDone } from './StatusDone';

export const statusOptionColors: Record<string, string> = {
  '#b7eb8f': 'status-options-green',
  '#f0f0f0': 'status-options-gray',
  '#ffe58f': 'status-options-yellow',
};

export const prorityOptionColors: Record<string, string> = {
  '#ff7875': 'priority-options-red',
  '#b7eb8f': 'priority-options-green',
};

export const selectOptionsColor: Record<string, string> = {
  '#b7eb8f': 'list-options-green',
  '#f0f0f0': 'list-options-gray',
  '#ffe58f': 'list-options-yellow',
  '#ff7875': 'list-options-red',
};

export const TaskWarningIcon = ({ dueDate, record }: { dueDate: dayjs.Dayjs; record?: IFieldData | IEntityWithFields }) => {
  const now = dayjs();
  const due = dayjs(dueDate);
  const daysLeft = due.diff(now, 'day');

  const statusOptionsValue = record?.custom_fields
    .find((field) => field.value_type === EFieldDataValueType.FIELD_DATA_LISTS && field.title === 'Status')
    ?.field_data.map((data) => data.value)
    .toString();

  if (daysLeft === 0) {
    if (statusOptionsValue === toHumanReadable(ETaskStatus.DONE)) {
      return <StatusDone dueDate={dueDate} />;
    }
    return (
      <>
        <WarningOutlined style={{ color: 'red' }} />
        <Col>{dueDate.format('DD/MM')}</Col>
      </>
    );
  } else if (daysLeft < 0) {
    return (
      <>
        <WarningFilled style={{ color: 'red' }} />
        <Col style={{ color: 'red' }}>{dueDate.format('DD/MM')}</Col>
      </>
    );
  } else if (daysLeft < 7) {
    if (statusOptionsValue === toHumanReadable(ETaskStatus.DONE)) {
      return <StatusDone dueDate={dueDate} />;
    }
    return (
      <>
        <WarningOutlined style={{ color: 'orange' }} />
        <Col>{dueDate.format('DD/MM')}</Col>
      </>
    );
  } else if (daysLeft < 100) {
    if (statusOptionsValue === toHumanReadable(ETaskStatus.DONE)) {
      return <StatusDone dueDate={dueDate} />;
    }
    return (
      <>
        <CalendarOutlined />
        <Col>{dueDate.format('DD/MM')}</Col>
      </>
    );
  }

  return <></>;
};
