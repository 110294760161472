import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { Button, Checkbox, Col, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FormInstance } from 'antd/lib/form/Form';
import './Pricing.scss';

import { InputPrice } from '@aduvi/components/InputPrice/InputPrice';
import { SelectTax } from '@aduvi/components/SelectTax/SelectTax';
import { useActiveTheme } from '@aduvi/hooks';

export const Pricing = ({ form }: { form: FormInstance }) => {
  const durationTypes = useDurationTypes();
  const { t: translate } = useTranslation();
  const activeTheme = useActiveTheme();

  const [isFlatRatePricing, setIsFlatRatePricing] = useState(
    form.getFieldValue('pricing_type') ? form.getFieldValue('pricing_type') === 'FLAT' : 'FLAT',
  );

  const [showOptions, setShowOptions] = useState(form.getFieldValue('allow_extend') || false);

  return (
    <Col span={24} className='package-pricing-container mt-15'>
      <Row justify='center' className='mb-20'>
        <Typography.Text style={isFlatRatePricing ? { color: activeTheme?.buttonBg } : {}}>
          {translate('assets.packagePricing.flatRatePricing')}
        </Typography.Text>
        <Form.Item name={'pricing_type'} getValueFromEvent={(value: boolean) => (value ? 'TIME_BASED' : 'FLAT')} initialValue={'FLAT'}>
          <Switch className='ml-15 mr-15' checked={!isFlatRatePricing} onChange={(checked) => setIsFlatRatePricing(!checked)} />
        </Form.Item>
        <Typography.Text style={isFlatRatePricing ? {} : { color: activeTheme?.buttonBg }}>
          {translate('assets.packagePricing.timeBasedPricing')}
        </Typography.Text>
      </Row>

      {isFlatRatePricing ? (
        <>
          <Form.Item
            name='flat_price'
            label={translate('assets.packagePricing.price')}
            rules={[{ required: true, message: translate('assets.packagePricing.priceValidation') }]}>
            <InputPrice className='w-full' placeholder={translate('assets.packagePricing.pricePlaceholder')} />
          </Form.Item>
          <Form.Item name='tax_rate_ids' label={translate('settings.tax.taxRates')}>
            <SelectTax mode='multiple' className='w-full' />
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item className='mb-5'>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='base_price'
                  label={translate('assets.packagePricing.price')}
                  rules={[{ required: true, message: translate('assets.packagePricing.priceValidation') }]}>
                  <InputPrice className='w-full' placeholder={translate('assets.packagePricing.pricePlaceholder')} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name='included_duration'
                  label={translate('assets.packagePricing.includedDuration')}
                  rules={[{ required: true, message: translate('assets.packagePricing.amountDurationValidation') }]}>
                  <Input placeholder={translate('assets.packagePricing.amountDurationPlaceholder')} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name='duration_type'
                  label={translate('assets.packagePricing.durationType')}
                  rules={[{ required: true, message: translate('assets.packagePricing.typeDurationValidation') }]}>
                  <Select placeholder={translate('assets.packagePricing.typeDurationPlaceholder')} options={durationTypes} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name='tax_rate_ids' label={translate('settings.tax.taxRates')}>
              <SelectTax mode='multiple' className='w-full' />
            </Form.Item>
          </Form.Item>
          <Form.Item name='allow_extend' initialValue={false} getValueFromEvent={(value: CheckboxChangeEvent) => value.target.checked}>
            <Checkbox className='mb-15' defaultChecked={showOptions} value={showOptions} onChange={(e) => setShowOptions(e.target.checked)}>
              {translate('assets.packagePricing.allowCustomerToExtendDuration')}
            </Checkbox>
          </Form.Item>

          {showOptions && (
            <Form.List name='time_based_pricing'>
              {(fields, { add, remove }) => (
                <>
                  <Row className='w-full'>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Row align='middle' className='w-full' gutter={[16, 16]} key={key} justify='space-around'>
                        <Col span={9}>
                          <Form.Item
                            {...restField}
                            name={[name, 'additional_duration']}
                            rules={[{ required: true }]}
                            label={index === 0 ? translate('assets.packagePricing.additionalHours') : null}>
                            <Input placeholder={translate('assets.packagePricing.pricePerAdditionalHourPlaceholder')} />
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            {...restField}
                            name={[name, 'price']}
                            label={index === 0 ? translate('assets.packagePricing.price') : null}
                            rules={[{ required: true }]}>
                            <InputPrice className='w-full' placeholder={translate('assets.packagePricing.pricePerHourPlaceholder')} />
                          </Form.Item>
                        </Col>
                        <Col span={1} className='text-right'>
                          <Form.Item>
                            <DeleteFilled className={`delete-icon ${index === 0 && 'delete-first-icon'}`} onClick={() => remove(name)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                  <Row justify={'end'}>
                    <Button icon={<PlusOutlined />} type='primary' ghost onClick={() => add()}>
                      {translate('assets.packagePricing.addOption')}
                    </Button>
                  </Row>
                </>
              )}
            </Form.List>
          )}
        </>
      )}
    </Col>
  );
};

const useDurationTypes = () => {
  const { t: translate } = useTranslation();

  return [
    {
      label: translate('assets.packagePricing.minutes'),
      value: 'MINUTES',
    },
    {
      label: translate('assets.packagePricing.hours'),
      value: 'HOURS',
    },
    {
      label: translate('assets.packagePricing.days'),
      value: 'DAYS',
    },
    {
      label: translate('assets.packagePricing.weeks'),
      value: 'WEEKS',
    },
    {
      label: translate('assets.packagePricing.months'),
      value: 'MONTHS',
    },
  ];
};
