import { useMemo } from 'react';

import { FormInstance } from 'antd';

import { EFieldDataValueType, EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { EAutomationNodeAction, IAutomationMention } from '@aduvi/types/automation';

import { useAppSelector } from 'store/hooks';

import { ActiveCampaign } from './actions/ActiveCampaign';
import { ChangeFieldData } from './actions/ChangeFieldData';
import { ChatGPT } from './actions/ChatGPT';
import { CreateProposal } from './actions/CreateProposal';
import { CreateTask } from './actions/CreateTask';
import { DateSelection } from './actions/DateSelection';
import { Email } from './actions/Email';
import { FacebookPixel } from './actions/FacebookPixel';
import { FormatDate } from './actions/FormatDate';
import { FormatNumber } from './actions/FormatNumber';
import { FormSelection } from './actions/FormSelection';
import { GoogleAnalytics } from './actions/GoogleAnalytics';
import { GoTo } from './actions/GoTo';
import { IfElse } from './actions/IfElse';
import { Mailchimp } from './actions/Mailchimp';
import { Mailerlite } from './actions/Mailerlite';
import { PlantATree } from './actions/PlantATree';
import { PushoverNotification } from './actions/PushoverNotification';
import { SendConversationMessage } from './actions/SendConversationMessage';
import { SMS } from './actions/SMS';
import { Split } from './actions/Split';
import { StatusSelection } from './actions/StatusSelection';
import { Wait } from './actions/Wait';
import { Webhook } from './actions/Webhook';
import { WhatsApp } from './actions/WhatsApp';

export const useNodeSettingsByActionType = (
  form: FormInstance,
  automationMentions: IAutomationMention[],
  entityFields?: IEntityField[],
): Record<EAutomationNodeAction, JSX.Element> => {
  const { entityTypes } = useAppSelector((state) => state.entity);
  const TriggerEntityActions = useMemo(
    () => ({
      // TRIGGER ENTITY ACTIONS
      PAYMENT_FAILED: <></>,
      LEAD_CREATE: <></>,
      LEAD_UPDATE: <></>,
      LEAD_DELETE: <>DELETED</>,
      CONTACT_CREATE: <></>,
      CONTACT_UPDATE: <></>,
      CONTACT_DELETE: <></>,
      USER_CREATE: <></>,
      USER_UPDATE: <></>,
      USER_DELETE: <></>,
      VENUE_CREATE: <></>,
      VENUE_UPDATE: <></>,
      VENUE_DELETE: <></>,
      PROJECT_CREATE: <></>,
      PROJECT_UPDATE: <></>,
      PROJECT_DELETE: <></>,
      TASK_CREATE: <></>,
      TASK_UPDATE: <></>,
      TASK_DELETE: <></>,
      INVOICE_CREATE: <></>,
      INVOICE_UPDATE: <></>,
      INVOICE_DELETE: <></>,
      CONTRACT_CREATE: <></>,
      CONTRACT_UPDATE: <></>,
      CONTRACT_DELETE: <></>,
      PAYMENT_CREATE: <></>,
      PAYMENT_UPDATE: <></>,
      PAYMENT_DELETE: <></>,

      // TRIGGER BOOKING ACTIONS
      LEAD_CONVERTS_TO_BOOKING: <></>,
      BOOKING_CREATE: <></>,
      BOOKING_UPDATE: <></>,
      BOOKING_DELETE: <></>,
      BOOKING_FORM_IS_SUBMITTED: <></>,
      BOOKING_FORM_IS_ABANDONED: <></>,
      BOOKING_STATUS_IS_CHANGED: <></>,
      BEFORE_BOOKING_EVENT_DATE_STARTS: <></>,
      BOOKING_DATE_STARTS: <></>,
      BOOKING_DATE_ENDS: <></>,

      // TRIGGER PAYMENT ACTIONS
      PAYMENT_SUCCESS: <></>,
      PAYMENT_FAIL: <></>,
      GRATUITY_IS_ADDED: <></>,

      // TRIGGER INVOICE ACTIONS

      INVOICE_IS_PAID: <></>,
      INVOICE_IS_DUE: <></>,
      INVOICE_IS_OVERDUE: <></>,

      // TRIGGER TASK ACTIONS
      TASK_IS_COMPLETED: <></>,
      TASK_IS_DUE: <></>,
      TASK_IS_OVERDUE: <></>,

      // TRIGGER CONTRACT ACTIONS
      CONTRACT_IS_VIEWED: <></>,
      CONTRACT_IS_SIGNED: <></>,
      CONTRACT_IS_DELETED: <></>,

      // TRIGGER LEAD(JOB) ACTIONS
      LEAD_FORM_IS_SUBMITTED: <></>,
      LEAD_FORM_IS_ABANDONED: <></>,
      LEAD_STATUS_IS_CHANGED: <></>,
      BEFORE_LEAD_EVENT_DATE_STARTS: <></>,
      LEAD_EVENT_DATE_STARTS: <></>,
      LEAD_EVENT_DATE_ENDS: <></>,
      LEAD_DATE_FIELD_DUE: <></>,

      // TRIGGER UPSELL ACTIONS
      UPSELL_IS_CLAIMED: <></>,

      // TRIGGER CONTACT ACTIONS
      CONTACT_LOGGED_IN: <></>,
      CONTACT_REGISTERED: <></>,

      // TRIGGER USER SHIFT ACTIONS
      USER_REQUESTS_SHIFT: <></>,
      USER_DECLINES_SHIFT: <></>,
      USER_ACCEPTS_SHIFT: <></>,
      USER_ASSIGNED_TO_SHIFT: <></>,
      USER_IS_REMOVED_FROM_SHIFT: <></>,
      USER_SHIFT_CHANGES: <></>,

      // TRIGGER FIELD BASE ACTIONS
      ENTITY_FIELD_UPDATED: <></>,

      // LOGIC ACTIONS
      IF_ELSE: <IfElse form={form} entityFields={entityFields} />,
      IF_BRANCH: <></>,
      ELSE_IF_BRANCH: <></>,
      ELSE_BRANCH: <></>,
      SPLIT: <Split />,
      GO_TO: <GoTo />,
      FORMAT_NUMBER: <FormatNumber form={form} entityFields={entityFields} />,
      FORMAT_DATE: <FormatDate entityFields={entityFields} />,
      CHAT_GPT_ACTION: <ChatGPT form={form} entityFields={entityFields} />,
      WAIT: <Wait />,

      // ACTIONS: ACTIONS
      SEND_EMAIL: <Email form={form} />,
      SEND_SMS: <SMS form={form} />,
      SEND_WHATSAPP_MESSAGE: <WhatsApp form={form} />,
      WEBHOOK: <Webhook form={form} />,
      PUSHOVER_NOTIFICATION: <PushoverNotification />,
      MAILCHIMP: <Mailchimp />,
      ACTIVE_CAMPAIGN: <ActiveCampaign />,
      MAILER_LITE: <Mailerlite />,
      GOOGLE_ANALYTICS: <GoogleAnalytics />,
      FACEBOOK_PIXEL: <FacebookPixel />,
      PLANT_TREE: <PlantATree />,
      PHONE_CALL: <></>,
      VOICE_MAIL: <></>,

      // ACTIONS: INTERNAL
      CHANGE_FIELD_DATA: <ChangeFieldData />,
      SEND_CONVERSATION_MESSAGE: <SendConversationMessage form={form} />,
      CREATE_TASK: <CreateTask form={form} />,
      CREATE_PROPOSAL: <CreateProposal />,

      LEAD_STATUS_CHANGED: <StatusSelection form={form} entityType={entityTypes?.data?.JOB} origin={EPersonalizedViewOrigin.LEADS} />,
      LEAD_CONVERTED: <></>,
      LEAD_CONVERSION_FAILED: <></>,

      BOOKING_STATUS_CHANGED: <StatusSelection form={form} entityType={entityTypes?.data?.JOB} origin={EPersonalizedViewOrigin.BOOKINGS} />,
      BOOKING_STARTED: <DateSelection form={form} />,
      BOOKING_ENDED: <DateSelection form={form} />,

      ARTWORK_CREATE: <></>,
      ARTWORK_UPDATE: <></>,
      ARTWORK_COMMENT: <></>,
      ARTWORK_ACCEPTED: <></>,

      PROPOSAL_VIEWED: <></>,
      PROPOSAL_CREATE: <></>,
      PROPOSAL_ACCEPTED: <></>,
      PROPOSAL_ACCEPTANCE_FAILED: <></>,
      PROPOSAL_SENT: <></>,

      TASK_COMPLETED: <></>,
      TASK_DEADLINE_DUE: <DateSelection form={form} />,

      PAYMENT_MANUAL_CREATE: <></>,
      PAYMENT_REFUNDED: <></>,

      INVOICE_DUE: <></>,

      USER_SHIFT_ASSIGNED: <></>,
      USER_SHIFT_REMOVED: <></>,
      USER_SHIFT_ACCEPTED: <></>,
      USER_SHIFT_DECLINED: <></>,
      USER_SHIFT_STARTED: <DateSelection form={form} />,
      USER_SHIFT_ENDED: <DateSelection form={form} />,

      REP_WITHDRAWAL_REQUEST: <></>,
      REP_WITHDRAWAL_PAID: <></>,

      CONTRACT_SIGNED: <></>,

      FORM_SUBMITTED: <FormSelection form={form} />,
      FORM_ABANDONED: <FormSelection form={form} />,
      FORM_SUBMISSION_UPDATED: <FormSelection form={form} />,
      FORM_WIDGET_SUBMITTED: <FormSelection form={form} />,

      EVENT_STARTED: <DateSelection form={form} />,
      EVENT_ENDED: <DateSelection form={form} />,

      TEMPLATE_CHOSEN: <></>,
      TEMPLATE_CHOICE_DELETED: <></>,

      BACKDROP_CHOSEN: <></>,
      BACKDROP_CHOICE_DELETED: <></>,

      STARTSCREEN_CHOSEN: <></>,
      STARTSCREEN_CHOICE_DELETED: <></>,

      ORDER_CREATE: <></>,
      ORDER_UPDATE: <></>,
      ORDER_DELETE: <></>,
      ORDER_STATUS_CHANGED: <></>,

      MESSAGE_RECEIVED: <></>,

      DAILY: <></>,
      WEEKLY: <></>,
      MONTHLY: <></>,
      YEARLY: <></>,
    }),
    [entityTypes],
  );

  return TriggerEntityActions;
};

export const operatorOptions: Record<EFieldDataValueType, string[]> = {
  [EFieldDataValueType.FIELD_DATA_DATE_TIMES]: ['Is', 'Is not', 'Is before', 'Is after', 'Is between', 'Is not between'],
  [EFieldDataValueType.FIELD_DATA_NUMBERS]: ['Is', 'Is not', 'Is greater than', 'Is less than'],
  [EFieldDataValueType.FIELD_DATA_TEXTS]: [
    'Is',
    'Is not',
    'Starts with',
    'Does not start with',
    'Ends with',
    'Does not end with',
    'Contains',
    'Does not contain',
  ],
  [EFieldDataValueType.FIELD_DATA_LONG_TEXTS]: [
    'Is',
    'Is not',
    'Starts with',
    'Does not start with',
    'Ends with',
    'Does not end with',
    'Contains',
    'Does not contain',
  ],
  [EFieldDataValueType.FIELD_DATA_TIMES]: [],
  [EFieldDataValueType.FIELD_DATA_EMAILS]: [
    'Is',
    'Is not',
    'Starts with',
    'Does not start with',
    'Ends with',
    'Does not end with',
    'Contains',
    'Does not contain',
  ],
  [EFieldDataValueType.FIELD_DATA_PHONES]: [
    'Is',
    'Is not',
    'Starts with',
    'Does not start with',
    'Ends with',
    'Does not end with',
    'Contains',
    'Does not contain',
  ],
  [EFieldDataValueType.FIELD_DATA_URLS]: [
    'Is',
    'Is not',
    'Starts with',
    'Does not start with',
    'Ends with',
    'Does not end with',
    'Contains',
    'Does not contain',
  ],
  [EFieldDataValueType.FIELD_DATA_ADDRESSES]: [],
  [EFieldDataValueType.FIELD_DATA_LISTS]: [],
  [EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS]: ['Is', 'Is not', 'Is greater than', 'Is less than'],
  [EFieldDataValueType.FIELD_DATA_FILES]: [],
  [EFieldDataValueType.FIELD_DATA_IMAGES]: [],
  [EFieldDataValueType.FIELD_DATA_STAR_RATINGS]: ['Is', 'Is not', 'Is greater than', 'Is less than'],
  [EFieldDataValueType.FIELD_DATA_SONGS]: [],
  [EFieldDataValueType.FIELD_DATA_SIGNATURES]: [],
  [EFieldDataValueType.FIELD_DATA_REFERENCE]: [],
};
