import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import {
  IChat,
  ICreateTicketPayload,
  IEditTicketPayload,
  IRateTicketResponse,
  IReOpenTicketResponse,
  ISendMessagePayload,
  ITicket,
  IUpdateTicketResponse,
} from '@aduvi/types/ticket';

export const getAllTickets = async (business_id: string): Promise<IResponse<ITicket[]>> => {
  return await axios.get(`/businesses/${business_id}/support-ticket`);
};

export const createTicket = async (payload: ICreateTicketPayload): Promise<IResponse<ITicket>> => {
  return await axios.post(`/businesses/${payload.body.business_id}/support-ticket`, payload.body);
};

export const updateTicket = async (payload: IEditTicketPayload): Promise<IResponse<IUpdateTicketResponse>> => {
  return await axios.put(`/businesses/${payload.body.business_id}/support-ticket/${payload.ticket_id}`, payload.body);
};

export const getSupportMessages = async (business_id: string, ticket_id: string): Promise<IResponse<IChat[]>> => {
  return await axios.get(`/businesses/${business_id}/support-ticket/${ticket_id}/conversations/messages`);
};

export const sendSupportMessage = async (payload: ISendMessagePayload): Promise<IResponse<IChat>> => {
  return await axios.post(`/businesses/${payload.business_id}/support-ticket/${payload.ticket_id}/conversations/messages`, payload.body);
};

export const reOpenTicket = async (business_id: string, ticket_id: string): Promise<IResponse<IReOpenTicketResponse>> => {
  return await axios.put(`/businesses/${business_id}/support-ticket/${ticket_id}/reopen`);
};

export const rateTicket = async (business_id: string, ticket_id: string, rate: number): Promise<IResponse<IRateTicketResponse>> => {
  return await axios.post(`/businesses/${business_id}/support-ticket/${ticket_id}/rate`, { rating: rate });
};
