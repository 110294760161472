import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { ICreateWidget, IDeleteWidgetPayload, IUpdateWidget, IWidget } from '@aduvi/types/widget';

export const createWidget = async (params: ICreateWidget): Promise<IResponse<IWidget>> => {
  return await axios.post(`/businesses/${params.business_id}/widgets`, params);
};

export const getWidgets = async (businessId: string, app: string): Promise<IResponse<IWidget[]>> => {
  return await axios.get(`/businesses/${businessId}/widgets?app=${app}`);
};

export const getWidget = async (businessId: string, widgetId: string): Promise<IResponse<IWidget>> => {
  return await axios.get(`/businesses/${businessId}/widgets/${widgetId}`);
};

export const getPublicWidget = async (businessId: string, widgetId: string): Promise<IResponse<IWidget>> => {
  return await axios.get(`/public/businesses/${businessId}/widgets/${widgetId}`);
};

export const updateWidget = async (params: IUpdateWidget): Promise<IResponse<IWidget>> => {
  return await axios.put(`/businesses/${params.business_id}/widgets/${params.id}`, params);
};

export const deleteWidget = async (params: IDeleteWidgetPayload): Promise<void> => {
  return await axios.delete(`/businesses/${params.business_id}/widgets/${params.widget_id}`);
};
