import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import saveAs from 'file-saver';

import { Col, Dropdown, Popconfirm, Row, Spin, Typography } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusinessId, useUserPermissionCheck } from '@aduvi/hooks';
import { useRelativeTime } from '@aduvi/hooks/useLocalRelativeTime';
import { IFile } from '@aduvi/types/file';
import { getFilePropertiesByFileType } from '@aduvi/utils/files-helper';

import { cloneFile, deleteFile, downloadFile, setSelectedFile } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { UpsertFileDrawer } from '../file-drawer/UpsertFileDrawer';
import { ShareModal } from '../share-modal/ShareModal';

interface IProps {
  file: IFile;
  viewType: string;
  readOnly: boolean;
  isSelected: boolean;
  toggleSelect: () => void;
}

export const FileCard = ({ viewType, readOnly, toggleSelect, isSelected, file }: IProps) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const formatRelativeTime = useRelativeTime();

  const { t: translate } = useTranslation();

  const { files, selectedFile } = useAppSelector((state) => state.file);

  const [showFileDrawer, setShowFileDrawer] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const [isFile, setIsFile] = useState(false);

  const fileProperties = useMemo(() => {
    return getFilePropertiesByFileType(file?.url?.split('.')?.pop()?.toUpperCase() || '');
  }, [file?.url]);

  const onDownload = async () => {
    dispatch(setSelectedFile(file));
    const blob = await dispatch(downloadFile({ businessId, fileId: file?.id })).unwrap();
    saveAs(blob, file?.name || file?.id);
  };

  const onDuplicate = () => {
    dispatch(setSelectedFile(file));
    dispatch(cloneFile({ businessId, fileId: file?.id }));
  };

  const onDelete = () => {
    dispatch(setSelectedFile(file));
    dispatch(deleteFile({ businessId, fileId: file.id, fileName: file.name }));
  };

  const onEdit = () => {
    dispatch(setSelectedFile(file));
    setShowFileDrawer(true);
  };

  const onShare = () => {
    dispatch(setSelectedFile(file));
    setShowShareModal(true);
  };

  const Actions = () => {
    return (
      <Dropdown
        menu={{
          items: [
            {
              key: '1',
              icon: <InfoCircleOutlined />,
              label: translate('file.edit'),
              onClick: onEdit,
            },
            {
              key: '2',
              icon: <DownloadOutlined />,
              label: translate('file.download'),
              onClick: onDownload,
            },
            {
              key: '3',
              icon: <ShareAltOutlined />,
              label: translate('file.share'),
              onClick: () => {
                setIsFile(true);
                onShare();
              },
            },
            {
              key: '4',
              icon: <CopyOutlined />,
              label: translate('file.duplicate'),
              onClick: onDuplicate,
            },
            {
              key: '5',
              icon: <DeleteOutlined />,
              label: (
                <Popconfirm
                  title={translate('buttons.delete')}
                  description={translate('common.deleteConfirmation')}
                  icon={<QuestionCircleOutlined />}
                  disabled={!useUserPermissionCheck(PERMISSIONS.FILES.DELETE)}
                  onCancel={(e) => {
                    e?.stopPropagation();
                  }}
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    onDelete();
                  }}>
                  {translate('file.delete')}
                </Popconfirm>
              ),
              onClick: (e) => e?.domEvent?.stopPropagation(),
              disabled: !useUserPermissionCheck(PERMISSIONS.FILES.DELETE),
            },
          ],
        }}
        trigger={['click']}
        overlayStyle={{ borderRadius: '8px' }}>
        <EllipsisOutlined className='fs-20' />
      </Dropdown>
    );
  };

  return (
    <Spin spinning={selectedFile?.id === file?.id && (files.cloning || files.downloading || files.deleting)}>
      <Row
        wrap={viewType !== 'list'}
        justify={viewType === 'list' ? 'start' : 'center'}
        align={viewType === 'list' ? 'middle' : 'stretch'}
        gutter={viewType === 'grid' ? [0, 12] : 0}
        style={{
          background: isSelected ? '#e8f0fe' : 'transparent',
          boxShadow: '0px 2px 8px 0px #5194d826',
          flexDirection: viewType === 'grid' ? 'column' : 'row',
          justifyContent: 'space-between',
          borderRadius: '5px',
          overflow: 'hidden',
          height: viewType === 'list' ? '60px' : '',
          cursor: readOnly ? 'pointer' : 'default',
        }}
        onClick={() => readOnly && toggleSelect()}>
        {viewType === 'grid' ? (
          <Row className='w-full' justify={'end'}>
            <Col className='flex-center' style={{ backgroundColor: fileProperties?.backgroundColor, minHeight: 150, maxHeight: 150 }} span={24}>
              {fileProperties?.isImage ? (
                <img alt='image' height='100%' style={{ objectFit: 'scale-down', alignSelf: 'end' }} src={file?.url} />
              ) : (
                <img alt='image' height={'80%'} style={{ maxWidth: '80%', alignSelf: 'end' }} src={require('assets/images/contract-cover.png')} />
              )}
            </Col>
          </Row>
        ) : (
          <Col className='flex-center w-full h-full' style={{ backgroundColor: fileProperties?.backgroundColor }} span={2}>
            {fileProperties?.isImage ? (
              <img alt='image' width='100%' height='100%' style={{ objectFit: 'contain' }} src={file?.url} />
            ) : (
              <img
                alt='image'
                width='50%'
                height={'80%'}
                style={{ maxWidth: '100%', alignSelf: 'end' }}
                src={require('assets/images/contract-cover.png')}
              />
            )}
          </Col>
        )}
        <Col className='px-10 fw-bold' span={viewType === 'list' ? 6 : undefined}>
          {file?.name}
        </Col>
        <Col className='px-10' span={viewType === 'list' ? 10 : undefined}>
          {file?.description ? <div className='mb-0' dangerouslySetInnerHTML={{ __html: file?.description }} /> : <p>N/A</p>}
        </Col>

        <Col span={viewType === 'list' ? 4 : undefined}>
          <Row justify='space-between' align='middle' onClick={(e) => e.stopPropagation()}>
            <Col className={`${viewType === 'grid' ? 'px-10 mb-10' : 'px-10'}`}>
              <Typography.Text type='secondary' className='fs-12'>
                {translate('file.edited')} {file.updated_at ? formatRelativeTime(file.updated_at || '') : formatRelativeTime(file.created_at || '')}
              </Typography.Text>
            </Col>

            {viewType === 'grid' ? <Col className={`px-10 mb-10`}>{!readOnly && <Actions />}</Col> : <></>}
          </Row>
        </Col>

        {viewType === 'list' ? <Col span={2}>{!readOnly && <Actions />}</Col> : <></>}
      </Row>

      {showFileDrawer && <UpsertFileDrawer open={showFileDrawer} onClose={() => setShowFileDrawer(false)} />}

      {showShareModal ? <ShareModal onClose={() => setShowShareModal(false)} isFile={isFile} /> : <></>}
    </Spin>
  );
};
