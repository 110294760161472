import { useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';

import { WidgetCard } from '../@components/WidgetCard/WidgetCard';

const forms = {
  data: [
    {
      id: '1',
      title: 'Booking Form Widget',
      description: 'Display key information about the services you offer',
      summary: 'Bookings',
      style: [{ background_color: '#FF5733', background_image: '' }],
    },
    {
      id: '2',
      title: 'Lead Intake Widget',
      description: 'Display key information about the packages you offer for each service',
      summary: 'Leads',
      style: [{ background_color: '#33FF57', background_image: '' }],
    },
  ],
};

export const Forms = () => {
  const navigate = useNavigate();

  return (
    <Row gutter={[24, 24]} className='w-full'>
      {forms?.data.map((form, index) => (
        <Col className='mb-15' key={index} span={12} xs={24} sm={12} lg={8}>
          <WidgetCard
            id={form?.id}
            title={form?.title}
            description={form?.description}
            action={form?.summary || ''}
            backgroundColor={form?.style?.[0]?.background_color}
            backgroundImage={''}
            onConfigure={() => navigate('/widgets/forms-configure')}
            onDuplicate={() => {}}
            onDelete={() => {}}
          />
        </Col>
      ))}
    </Row>
  );
};
