import React from 'react';

export const TabletIcon = ({ color }: { color: string }) => {
  return (
    <svg width='18' height='18' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_189682_19900)'>
        <path
          d='M11.5 0H2.5C1.94844 0 1.5 0.448438 1.5 1V13C1.5 13.5516 1.94844 14 2.5 14H11.5C12.0516 14 12.5 13.5516 12.5 13V1C12.5 0.448438 12.0516 0 11.5 0ZM11.375 12.875H2.625V1.125H11.375V12.875ZM6.375 11.25C6.375 11.4158 6.44085 11.5747 6.55806 11.6919C6.67527 11.8092 6.83424 11.875 7 11.875C7.16576 11.875 7.32473 11.8092 7.44194 11.6919C7.55915 11.5747 7.625 11.4158 7.625 11.25C7.625 11.0842 7.55915 10.9253 7.44194 10.8081C7.32473 10.6908 7.16576 10.625 7 10.625C6.83424 10.625 6.67527 10.6908 6.55806 10.8081C6.44085 10.9253 6.375 11.0842 6.375 11.25Z'
          fill={color}
          fillOpacity='0.85'
        />
      </g>
      <defs>
        <clipPath id='clip0_189682_19900'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
