import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginOutlined, MobileOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import Tiptap from '@aduvi/Tiptap/Tiptap';
import { EAutomationNodeAction } from '@aduvi/types/automation';

import { useAppSelector } from 'store/hooks';

export const SMS = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  Form.useWatch('connection_id', form);

  const {
    connections: { loading, data },
  } = useAppSelector((state) => state.automation);

  const options = useMemo(() => data.filter((item) => item.type === EAutomationNodeAction.SEND_SMS), [data]);

  const selectedNumber = useMemo(
    () => options.find((item) => item.id === form.getFieldValue('connection_id')),
    [options, form.getFieldValue('connection_id')],
  );

  return (
    <>
      <Row style={{ boxShadow: '0px 2px 8px 0px #5194D826', borderRadius: '5px' }}>
        <Col span={2} style={{ background: '#FFF1F0', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} className='flex-center'>
          <MobileOutlined style={{ color: '#F5222D', fontSize: '17px' }} />
        </Col>
        <Col span={22} className='p-15 pb-5'>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Typography.Text>{translate('automations.smsAccount')}</Typography.Text>
            </Col>
            <Col>
              <Button type='link' block>
                {translate('automations.connectNew')} <LoginOutlined />
              </Button>
            </Col>
          </Row>
          <Form.Item name={'connection_id'} className='mt-5'>
            <Select loading={loading} options={options.map((item) => ({ label: item.provider, value: item.id }))} />
          </Form.Item>
          <Form.Item name={'sending_number'} className='mt-5' label={translate('automations.sendingNumber')}>
            <Select
              className='w-full'
              options={
                selectedNumber
                  ? [selectedNumber].map((item) => ({ label: item?.meta_data?.twilio_phone_number, value: item?.meta_data?.twilio_phone_number }))
                  : undefined
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'to'} label={translate('automations.to')} className='mt-20'>
        <Input placeholder={translate('automations.typeOrInsert')} />
      </Form.Item>
      <Form.Item name={'message'} label={translate('automations.message')}>
        <Tiptap content={form.getFieldValue('message')} onUpdate={(value) => form.setFieldValue('message', value.getHTML())} />
      </Form.Item>
    </>
  );
};
