import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';

import {
  ICloneEntityFormPayload,
  ICreateEntityByFormUrl,
  ICreateEntityFormPayload,
  IDeleteEntityFormPayload,
  IEntityForm,
  IEntityFormState,
  IGetFormByIdPayload,
  ISignEntityContractPayload,
  IUpsertFormCustomFields,
} from '@aduvi/types/form';

import * as FormServices from 'store/services/form.service';

const initialState: IEntityFormState = {
  entityForms: undefined,
  selectedEntityForm: undefined,
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  cloning: false,
  loadingEntity: false,
  creatingEntity: false,
  fields: [],
};

export const createEntityForm = createAsyncThunk(
  'forms/create-entity-form',
  async (params: { payload: ICreateEntityFormPayload; form_type: string }, { rejectWithValue }) => {
    try {
      return await FormServices.createEntityForm(params.payload, params.form_type);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateEntityForm = createAsyncThunk('forms/update-entity-form', async (payload: IEntityForm, { rejectWithValue }) => {
  try {
    return await FormServices.updateEntityForm(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEntityFormCustomFields = createAsyncThunk(
  'forms/update-entity-form-custom-fields',
  async (payload: IUpsertFormCustomFields, { rejectWithValue }) => {
    try {
      return await FormServices.updateEntityFormCustomFields(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteEntityForm = createAsyncThunk(
  'forms/delete-entity-form',
  async ({ business_id, entity_type_id, form_id, form_name }: IDeleteEntityFormPayload, { rejectWithValue }) => {
    try {
      return await FormServices.deleteEntityForm({ business_id, entity_type_id, form_id, form_name });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const cloneEntityForm = createAsyncThunk(
  'forms/clone-entity-form',
  async ({ business_id, entity_type_id, form_id }: ICloneEntityFormPayload, { rejectWithValue }) => {
    try {
      return await FormServices.cloneEntityForm({ business_id, entity_type_id, form_id });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFormsByEntity = createAsyncThunk(
  'forms/get-forms-by-entity',
  async ({ business_id, entity_type_id, formType }: { business_id: string; entity_type_id: string; formType: string }, { rejectWithValue }) => {
    try {
      return await FormServices.getFormsByEntityType({ business_id, entity_type_id, form_type: formType });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFormById = createAsyncThunk(
  'forms/get-entity-form-by-id',
  async ({ business_id, entity_type_id, form_id }: IGetFormByIdPayload, { rejectWithValue }) => {
    try {
      return await FormServices.getFormById({ business_id, entity_type_id, form_id });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFormByUrl = createAsyncThunk('forms/get-form-by-url', async (payload: { formUrl: string }, { rejectWithValue }) => {
  try {
    return await FormServices.getFormByUrl(payload.formUrl);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEntityFromFormUrl = createAsyncThunk(
  'forms/create-entity-from-form-url',
  async (payload: ICreateEntityByFormUrl, { rejectWithValue }) => {
    try {
      return await FormServices.createEntityFromFormUrl(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPublicProducts = createAsyncThunk('forms/get-public-products', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FormServices.getPublicProducts(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPublicServices = createAsyncThunk('forms/get-public-services', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FormServices.getPublicServices(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPublicEntities = createAsyncThunk(
  'forms/get-public-entities',
  async (
    payload: {
      businessId: string;
      entityTypeId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await FormServices.getPublicEntities(payload.businessId, payload.entityTypeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const signEntityContract = createAsyncThunk('forms/sign-entity-contract', async (payload: ISignEntityContractPayload, { rejectWithValue }) => {
  try {
    return await FormServices.signEntityContract(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const formSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setSelectedEntityForm(state, action: PayloadAction<IEntityForm | undefined>) {
      state.selectedEntityForm = action.payload ? action.payload : undefined;
    },
    setEntityForms(state, action: PayloadAction<IEntityForm[] | undefined>) {
      state.entityForms = action.payload ? action.payload : undefined;
    },
    setFields(state, action: PayloadAction<IBookingFormFieldProps[]>) {
      state.fields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFormsByEntity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormsByEntity.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.entityForms = payload.data;
      })
      .addCase(getFormsByEntity.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFormById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFormById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedEntityForm = payload.data;
      })
      .addCase(getFormById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createEntityForm.pending, (state) => {
        state.creating = true;
      })
      .addCase(createEntityForm.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.entityForms) {
          state.entityForms = [...state.entityForms, payload.data];
          state.selectedEntityForm = payload.data;
          return;
        }
        state.entityForms = [payload.data];
      })
      .addCase(createEntityForm.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateEntityForm.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateEntityForm.fulfilled, (state, { payload }) => {
        state.updating = false;

        if (state.entityForms) {
          state.entityForms = state.entityForms?.map((form) => (form?.id === payload.data?.id ? payload.data : form));
        }
        state.selectedEntityForm = payload.data;
      })
      .addCase(updateEntityForm.rejected, (state) => {
        state.updating = false;
      })
      .addCase(updateEntityFormCustomFields.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateEntityFormCustomFields.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.entityForms) {
          state.entityForms = state.entityForms?.map((form) => (form?.id === payload.data?.id ? payload.data : form));
        }
        state.selectedEntityForm = payload.data;
      })
      .addCase(updateEntityFormCustomFields.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteEntityForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEntityForm.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.entityForms) {
          state.entityForms = state.entityForms.filter((form) => form.id !== meta.arg.form_id);
        }
        state.selectedEntityForm = undefined;
      })
      .addCase(deleteEntityForm.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cloneEntityForm.pending, (state) => {
        state.cloning = true;
      })
      .addCase(cloneEntityForm.fulfilled, (state, { payload }) => {
        state.cloning = false;
        if (state.entityForms) {
          state.entityForms = [...state.entityForms, payload.data];
          state.selectedEntityForm = payload.data;
          return;
        }
        state.entityForms = [payload.data];
      })
      .addCase(cloneEntityForm.rejected, (state) => {
        state.cloning = false;
      })
      .addCase(getFormByUrl.pending, (state) => {
        state.loadingEntity = true;
      })
      .addCase(getFormByUrl.fulfilled, (state, { payload }) => {
        state.loadingEntity = false;
        state.selectedEntityForm = payload.data;
      })
      .addCase(getFormByUrl.rejected, (state) => {
        state.loadingEntity = false;
      })
      .addCase(createEntityFromFormUrl.pending, (state) => {
        state.creatingEntity = true;
      })
      .addCase(createEntityFromFormUrl.fulfilled, (state) => {
        state.creatingEntity = false;
      })
      .addCase(createEntityFromFormUrl.rejected, (state) => {
        state.creatingEntity = false;
      })
      .addCase(signEntityContract.pending, (state) => {
        state.loading = true;
      })
      .addCase(signEntityContract.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(signEntityContract.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const formReducer = formSlice.reducer;
export const { setSelectedEntityForm, setEntityForms } = formSlice.actions;
