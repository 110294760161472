export enum EDisplayType {
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
  EXCLUDED_HIDDEN = 'EXCLUDED_HIDDEN',
  EXCLUDED_MONETARY = 'EXCLUDED_MONETARY',
  EXCLUDED_SIMPLE = 'EXCLUDED_SIMPLE',
}

export interface ICreateTaxRatePayload {
  business_id: string;
  body: {
    tax_name: string;
    tax_percentage: number;
  };
}

export interface IUpdateTaxRatePayload extends ICreateTaxRatePayload {
  tax_rate_id: string;
}

export interface IDeleteTaxRatePayload {
  business_id: string;
  tax_rate_id: string;
  tax_name: string;
}

export interface ITaxRate {
  business_id: string;
  tax_name: string;
  tax_percentage: number;
  id: string;
  updated_at?: string;
  created_at?: string;
}

export interface ICreateTaxSettingsPayload {
  business_id: string;
  body: {
    display: EDisplayType;
    automatic_tax_rate_enabled: boolean;
    ein_validation_enabled: boolean;
  };
}

export interface IUpdateTaxSettingPayload extends ICreateTaxSettingsPayload {
  tax_settings_id: string;
}

export interface ITaxSettings {
  business_id: string;
  display: EDisplayType;
  automatic_tax_rate_enabled: boolean;
  ein_validation_enabled: boolean;
  id: string;
  updated_at?: string;
  created_at?: string;
}

export interface ITaxRateState {
  rates: {
    selectedRate?: ITaxRate;
    taxRates?: ITaxRate[];
    loading: boolean;
    creating: boolean;
    updating: boolean;
  };
  settings: {
    taxSettings?: ITaxSettings;
    loading: boolean;
    creating: boolean;
    updating: boolean;
  };
}
