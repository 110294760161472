import dayjs from 'dayjs';

import { Avatar, Col, Row } from 'antd';
import './TaskRow.scss';

import { IEntityField } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';

import { useTask } from '../Task.hook';

import { TaskWarningIcon } from './helper';

interface IProps {
  entity: IFieldData;
  managementFields: IEntityField[] | undefined;
  entityTypeId?: string;
  loading: boolean;
}

export const TaskRow = ({ entity, managementFields, entityTypeId, loading }: IProps) => {
  const { taskTitle, taskDeadline, taskStatus, taskPriority, userAvatar, userName } = useTask({
    managementFields,
    entity,
    entityTypeId,
    loading,
  });

  return (
    <Row className='w-full task-rows' style={{ height: 47, alignItems: 'center', borderBottom: '1px solid #f0f0f0' }}>
      <Col span={10}>{taskTitle}</Col>
      <Col span={2}>
        <Avatar shape='circle' size={'small'} src={userAvatar}>
          {userName}
        </Avatar>
      </Col>
      <Col span={3}>
        <Row style={{ gap: '3px' }}>
          {taskDeadline.map((date, index) => (
            <TaskWarningIcon key={index} dueDate={dayjs(date)} record={entity} />
          ))}
        </Row>
      </Col>
      <Col span={5} className='task-status'>
        {taskStatus}
      </Col>
      <Col span={4} className='task-priority'>
        {taskPriority}
      </Col>
    </Row>
  );
};
