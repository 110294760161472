import React from 'react';

import { Form, Input } from 'antd';

import { Paragraph } from '@aduvi/components/Paragraph';

import { SelectTemplateCategory } from '../../Templates/SelectTemplateCategory';

export const Configure = () => {
  return (
    <>
      <Paragraph style={{ fontSize: '12px', fontWeight: 400, color: '#9B9B9B' }} className='mt-20'>
        Display settings
      </Paragraph>
      <Form.Item name={'block_name'} label={'Page title'}>
        <Input className='w-full' />
      </Form.Item>
      <Form.Item name={'builder_category_ids'} label='Category' rules={[{ required: true, message: 'Field is required!' }]}>
        <SelectTemplateCategory mode='multiple' />
      </Form.Item>
    </>
  );
};
