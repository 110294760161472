import { Dispatch, SetStateAction, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { Button, Divider, Dropdown, Form, FormInstance, Row, Spin, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';

import { IEntityField } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

import Fields from './components/Fields';
import { bookingFormDropdownItems, IBookingFormFieldProps } from './helper';

interface IProps {
  fields: IBookingFormFieldProps[];
  entityFields: IEntityField[];
  form: FormInstance;
  isDragging: boolean;
  fileList: UploadFile[];
  entityTypeId?: string;
  setFileList: Dispatch<SetStateAction<UploadFile[]>>;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  beforeUpload: (file: RcFile) => boolean;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
  onCreateNewFieldDrawer: (index: number) => void;
  onCreateAsset: (assetType: IEntityType, index: number) => void;
}

export const BookingFormField = ({
  fields,
  entityFields,
  form,
  isDragging,
  fileList,
  entityTypeId,
  setFileList,
  setIsDragging,
  onCreateNewFieldDrawer,
  setFields,
  beforeUpload,
  onAddField,
  onCreateAsset,
}: IProps) => {
  const { entityTypes } = useAppSelector((state) => state.entity);
  const { selectedEntityForm } = useAppSelector((state) => state.forms);
  const { creating } = useAppSelector((state) => state.fields);

  const entityTypeHasQuotes = Boolean(Object.values(entityTypes.data).find((item) => item?.id === entityTypeId)?.has_quotes);

  useEffect(() => {
    if ((!form.getFieldValue(['form_style', 0, 'logo']) || fileList.length === 0) && selectedEntityForm?.brand?.logo) {
      form.setFieldValue(['form_style', 0, 'logo'], selectedEntityForm.brand.logo);
      const fileListItem: UploadFile = {
        uid: uuidv4(),
        name: 'logo',
        status: 'done',
        url: selectedEntityForm.brand.logo,
      };
      setFileList([fileListItem]);
    }
  }, [form, selectedEntityForm?.brand, setFileList]);

  return (
    <Spin spinning={creating}>
      <Row justify={'center'} className='mt-20'>
        <Form.Item name={['form_style', 0, 'logo']}>
          <Upload
            className='text-center'
            listType='picture-circle'
            showUploadList={{ showPreviewIcon: false }}
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
            beforeUpload={beforeUpload}>
            {fileList.length === 0 && <PlusOutlined />}
          </Upload>
        </Form.Item>
      </Row>
      <Row className='add-field-wrapper'>
        <Divider dashed orientation='center' className={fields.length === 0 ? '' : 'add-field-divider'}>
          <Dropdown
            overlayClassName='booking-form-dropdown'
            menu={{
              items: bookingFormDropdownItems(
                0,
                fields,
                entityFields,
                form,
                entityTypeHasQuotes,
                entityTypes.entityTypes,
                onAddField,
                onCreateNewFieldDrawer,
                onCreateAsset,
              ),
            }}
            trigger={['click']}
            placement='bottomCenter'>
            <Button icon={<PlusOutlined />} type='primary' style={{ borderRadius: '5px' }} size='small' />
          </Dropdown>
        </Divider>
      </Row>
      <Fields
        form={form}
        isDragging={isDragging}
        fields={fields}
        entityTypeHasQuotes={entityTypeHasQuotes}
        pageCounter={0}
        setPageCounter={() => void 0}
        setFields={setFields}
        setIsDragging={setIsDragging}
        onAddField={onAddField}
        onCreateNewFieldDrawer={onCreateNewFieldDrawer}
        onCreateAsset={onCreateAsset}
      />
    </Spin>
  );
};
