import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActiveTheme } from '@aduvi/hooks';
import { EAuditTypes, IActivityTextProps, UserActivityTypes } from '@aduvi/types/audit';

const getActivityTypes = (type: EAuditTypes) => {
  return UserActivityTypes[type];
};

export const UserActivityText = ({ event, model, fields, newValues, oldValues }: IActivityTextProps) => {
  const { t: translate } = useTranslation();
  const activeTheme = useActiveTheme();

  const renderActivityText = useCallback((event: EAuditTypes) => {
    if (event === EAuditTypes.UPDATED) {
      return (
        <Trans
          t={translate}
          components={{
            model: <b style={{ color: activeTheme?.buttonBg }} />,
            field: <span style={{ color: activeTheme?.buttonBg }} />,
          }}
          i18nKey={getActivityTypes(event)}
          values={{ model, fields, newValues, oldValues }}></Trans>
      );
    } else if ([EAuditTypes.DELETED, EAuditTypes.SOFT_DELETED, EAuditTypes.CREATED].includes(event)) {
      return (
        <Trans
          t={translate}
          components={{ model: <b style={{ color: activeTheme?.buttonBg, fontWeight: 'bold' }} /> }}
          i18nKey={getActivityTypes(event)}
          values={{ model }}></Trans>
      );
    } else {
      return translate(getActivityTypes(event));
    }
  }, []);
  return <>{renderActivityText(event)}</>;
};
