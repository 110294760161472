import { useEffect, useState } from 'react';

import { getUserAvatar } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

interface UserData {
  email: string;
  existingImage?: string;
  existingName?: string;
}

export const useAvatarApi = ({ email, existingImage, existingName }: UserData) => {
  const dispatch = useAppDispatch();

  const [userData, setUserData] = useState({ name: existingName, imageUrl: existingImage });

  useEffect(() => {
    if (existingName && existingImage) return;

    dispatch(getUserAvatar(email))
      .unwrap()
      .then((data) => {
        if (data && data.Valid && data.Success) {
          setUserData({ name: data.Name, imageUrl: data.Image });
        }
      })
      .catch(() => {});
  }, [email, existingImage, existingName]);

  return userData;
};
