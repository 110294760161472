import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import { Button, Card, Col, List, Row, Space, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { ETicketStatus } from '@aduvi/types/ticket';
import { toHumanReadable } from '@aduvi/utils/helper';

import { getAllTickets, getSupportMessages, setSelectedTicket } from 'store/features/ticket-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { RequestCallModal } from './requestCallModal/RequestCallModal';
import { CreateTicketModal } from './ticket/create-ticket-modal/CreateTicketModal';
import { getStatusStyles } from './ticket/helper';
import { TicketChatModal } from './ticket/ticket-chat-modal/TicketChatModal';
import { TicketConfirmationModal } from './ticket/ticket-confirmation-modal/TicketConfirmationModal';

export const ContactTab = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isTicketConfirmationModalOpen, setIsTicketConfirmationModalOpen] = useState(false);
  const [showCallReqModal, setShowCallReqModal] = useState<boolean>(false);

  const { tickets, loading, selectedTicket } = useAppSelector((state) => state.ticket);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getAllTickets(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (!selectedTicket?.id || !selectedBusiness?.id) return;

    dispatch(getSupportMessages({ business_id: selectedBusiness?.id, ticket_id: selectedTicket?.id }));
  }, [selectedBusiness?.id, selectedTicket?.id]);

  const handleSubmit = () => {
    setShowCallReqModal(true);
  };

  return (
    <Row className='p-10 mt-10' gutter={30}>
      <Col span={12}>
        <Space direction='vertical'>
          <Typography.Text className='fs-16'>{translate('support.contact.requestACallBack')}</Typography.Text>

          <Card style={{ width: 470, height: 170 }} bodyStyle={{ paddingTop: 20 }} className='mb-10'>
            <Row>
              <Space direction='vertical'>
                <Typography.Text className='fs-14'>{translate('support.contact.phoneNumber')}</Typography.Text>

                <Row justify={'space-between'}>
                  <Col>
                    <PhoneInput
                      inputStyle={{ borderRadius: '0' }}
                      country={'gb'}
                      value={phoneNumberValue}
                      onChange={(value: string) => setPhoneNumberValue(value)}
                    />
                  </Col>

                  <Col>
                    <Button type='primary' onClick={handleSubmit}>
                      {translate('support.contact.callMeBack')}
                    </Button>
                  </Col>
                </Row>
                <Typography.Text type='secondary'>
                  {translate('support.contact.enterYourPhoneNumber')}
                  <Typography.Text type='warning'> {translate('support.contact.currentWaitTime')}</Typography.Text>
                </Typography.Text>
              </Space>
            </Row>
          </Card>

          <Typography.Text className='fs-16'> {translate('support.contact.scheduleATrainingCall')}</Typography.Text>

          <Card style={{ width: 470, height: 170 }}>
            <Row justify={'space-between'} className='mb-15'>
              <Typography.Text className='fs-16'>{translate('support.contact.freeOnTraining')}</Typography.Text>
              <Button type='primary'>{translate('support.contact.checkAvailability')}</Button>
            </Row>

            <Typography.Text type='secondary'>{translate('support.contact.bookInTraining')}</Typography.Text>
          </Card>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction='vertical'>
          <Typography.Text className='fs-16'> {translate('support.contact.submitTicket')}</Typography.Text>

          <Card style={{ width: 470, height: 390 }} bodyStyle={{ padding: 8, paddingTop: 20 }}>
            <Col>
              <Row justify={'space-between'} className='mb-10'>
                <Typography.Text className='fs-16'>{translate('support.contact.createSupportTicket')}</Typography.Text>
                <Button type='primary' onClick={() => setIsTicketModalOpen(true)}>
                  {translate('support.contact.createTicket')}
                </Button>
              </Row>

              <Row className='mb-20'>
                <Typography.Text type='secondary'>{translate('support.contact.includeAttachments')}</Typography.Text>
              </Row>

              <Row className='mb-15'>
                <Typography.Text> {translate('support.contact.yourTickets')}</Typography.Text>
              </Row>

              <Row>
                <List
                  className='w-full'
                  itemLayout='horizontal'
                  dataSource={tickets}
                  loading={loading}
                  style={{ boxShadow: '0px 2px 8px 0px #5194D826', maxHeight: '170px', minHeight: '170px', overflowY: 'auto' }}
                  renderItem={(item, index) => (
                    <List.Item
                      key={index}
                      style={{ boxShadow: '0px -1px 0px 0px #F0F0F0 inset' }}
                      className='cursor-pointer'
                      onClick={() => {
                        dispatch(setSelectedTicket(item));
                        setIsTicketConfirmationModalOpen(true);
                      }}>
                      <Row justify={'space-between'} className='w-full p-5'>
                        <Col className='pl-20'>
                          <Typography.Text>{item?.subject}</Typography.Text>
                        </Col>
                        <Col className='pr-10'>
                          <Typography.Text style={getStatusStyles(item?.status)}>{toHumanReadable(item?.status)}</Typography.Text>
                        </Col>
                      </Row>
                    </List.Item>
                  )}
                />
              </Row>
            </Col>
          </Card>
        </Space>
      </Col>

      <RequestCallModal
        open={showCallReqModal}
        phoneNumber={phoneNumberValue}
        onCancel={() => {
          setShowCallReqModal(false);
          setPhoneNumberValue('');
        }}
      />

      {isTicketModalOpen && <CreateTicketModal setIsTicketModalOpen={setIsTicketModalOpen} />}
      {isTicketConfirmationModalOpen && selectedTicket?.status === ETicketStatus.RESOLVED ? (
        <TicketConfirmationModal setIsTicketConfirmationModalOpen={setIsTicketConfirmationModalOpen} />
      ) : (
        isTicketConfirmationModalOpen && <TicketChatModal setIsTicketConfirmationModalOpen={setIsTicketConfirmationModalOpen} />
      )}
    </Row>
  );
};
