import React, { useState } from 'react';
import { MobileOutlined } from '@ant-design/icons';

import { Button, Col, Modal, Row } from 'antd';
import './ProposalPreviewModal.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';

import { DesktopIcon } from './assets/DesktopIcon';
import { TabletIcon } from './assets/TabletIcon';

interface IProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

export const ProposalPreviewModal = ({ title, open, onClose }: IProps) => {
  const [selectedMode, setSelectedMode] = useState<'monitor' | 'tablet' | 'phone'>('monitor');

  return (
    <Modal
      className='proposal-preview-modal w-full pl-30 pr-20'
      title={
        <Row justify={'space-between'}>
          <span>{title + ' (Preview)'}</span>
          <Col>
            <Row>
              <Button type='primary'>Start building</Button>
              <Button
                ghost
                type='link'
                icon={<DesktopIcon color={selectedMode === 'monitor' ? '#1890FF' : 'black'} />}
                onClick={() => setSelectedMode('monitor')}
              />
              <Button
                ghost
                type='link'
                icon={<TabletIcon color={selectedMode === 'tablet' ? '#1890FF' : 'black'} />}
                onClick={() => setSelectedMode('tablet')}
              />
              <Button
                ghost
                type='link'
                icon={<MobileOutlined style={{ color: selectedMode === 'phone' ? '#1890FF' : 'black' }} />}
                onClick={() => setSelectedMode('phone')}
              />
            </Row>
          </Col>
          <Col />
        </Row>
      }
      open={open}
      footer={null}
      onCancel={onClose}
      onClose={onClose}>
      <Row align={'middle'} justify={'center'} className='middle-part'>
        <Title level={3} style={{ color: 'white' }}>
          Hi Joe, here&apos;s your proposal!
        </Title>
      </Row>
      <Row className='info-wrapper mt-30 pb-30' justify={'center'}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Col span={6} key={index} className='red-square'>
            <Title style={{ color: 'inherit' }}>About Us</Title>
            <Paragraph style={{ color: 'inherit' }}>We do everything we can to make your event a success</Paragraph>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};
