import { ArtworkList } from 'views/settings/client-portal/@components/client-portal/@components/Artwork/ArtworkList';
import { AssignedStaff } from 'views/settings/client-portal/@components/client-portal/@components/AssignedStaff/AssignedStaff';
import { CustomBlock } from 'views/settings/client-portal/@components/client-portal/@components/CustomBlock/CustomBlock';
import { JobList } from 'views/settings/client-portal/@components/client-portal/@components/JobList/JobList';
import { MakePayment } from 'views/settings/client-portal/@components/client-portal/@components/MakePayment/MakePayment';
import { OrderList } from 'views/settings/client-portal/@components/client-portal/@components/OrderList/OrderList';
import { OrderSummary } from 'views/settings/client-portal/@components/client-portal/@components/OrderSummary/OrderSummary';
import { Review } from 'views/settings/client-portal/@components/client-portal/@components/Review/Review';
import { Upsells } from 'views/settings/client-portal/@components/client-portal/@components/Upsells/Upsells';

import { FormInstance } from 'antd';

import { EClientPortalComponentType, IClientPortalSettings } from '@aduvi/types/client-portal';

export interface IColumnType {
  id: number;
  name: string;
  index: EClientPortalComponentType;
  visible: boolean;
}

export const COLUMNS_DATA: IColumnType[] = [
  { id: 0, name: '', index: EClientPortalComponentType.ORDER_LIST, visible: true },
  { id: 1, name: '', index: EClientPortalComponentType.ARTWORK, visible: true },
  { id: 2, name: '', index: EClientPortalComponentType.MAKE_PAYMENT, visible: true },

  { id: 3, name: '', index: EClientPortalComponentType.REVIEW, visible: true },
  { id: 4, name: '', index: EClientPortalComponentType.ORDER_SUMMARY, visible: true },
  { id: 5, name: '', index: EClientPortalComponentType.JOB_LIST, visible: true },
  { id: 6, name: '', index: EClientPortalComponentType.UPSELLS, visible: true },

  { id: 7, name: '', index: EClientPortalComponentType.CUSTOM_BLOCKS, visible: true },
  { id: 8, name: '', index: EClientPortalComponentType.ASSIGNED_STAFF, visible: true },
];

export const COLUMNS = {
  artwork: ArtworkList,
  assigned_staff: AssignedStaff,
  custom_blocks: CustomBlock,
  make_payment: MakePayment,
  order_list: OrderList,
  job_list: JobList,
  order_summary: OrderSummary,
  review: Review,
  upsells: Upsells,
};

export const getColumnComponent = ({ key, form }: { key: EClientPortalComponentType; form?: FormInstance }): React.ReactNode => {
  const Component = COLUMNS[key];
  return Component ? <Component readonly={false} form={form} /> : null;
};

export const getReadOnlyComponent = ({ key, form }: { key: EClientPortalComponentType; form?: FormInstance }): React.ReactNode => {
  const Component = COLUMNS[key];
  return Component ? <Component readonly={true} form={form} /> : null;
};

export const READONLY_COLUMNS: { [key: string]: React.ReactNode } = {
  artwork: <ArtworkList />,
  assigned_staff: <AssignedStaff />,
  custom_blocks: <CustomBlock />,
  make_payment: <MakePayment />,
  order_list: <OrderList />,
  job_list: <JobList />,
  order_summary: <OrderSummary />,
  review: <Review />,
  upsells: <Upsells />,
};
export const VISIBILITY_OPTIONS = [
  { id: 'always', text: 'clientPortal.options.always' },
  {
    id: '1h',
    text: 'clientPortal.options.hourAfterSubmission',
    value: 1,
  },
  {
    id: '2h',
    text: 'clientPortal.options.hourAfterSubmission',
    value: 2,
  },
  {
    id: '3h',
    text: 'clientPortal.options.hourAfterSubmission',
    value: 3,
  },
  {
    id: '12h',
    text: 'clientPortal.options.hourAfterSubmission',
    value: 12,
  },
  {
    id: '1d',
    text: 'clientPortal.options.dayAfterSubmission',
    value: 1,
  },
  {
    id: '2d',
    text: 'clientPortal.options.dayAfterSubmission',
    value: 2,
  },
  {
    id: '3d',
    text: 'clientPortal.options.dayAfterSubmission',
    value: 3,
  },
];
export const REVIEW_VISIBILITY_OPTIONS = [
  { id: 'always', text: 'clientPortal.options.always' },
  {
    id: '1h',
    text: 'clientPortal.options.hourAfterEvent',
    value: 1,
  },
  {
    id: '2h',
    text: 'clientPortal.options.hourAfterEvent',
    value: 2,
  },
  {
    id: '3h',
    text: 'clientPortal.options.hourAfterEvent',
    value: 3,
  },
  {
    id: '12h',
    text: 'clientPortal.options.hourAfterEvent',
    value: 12,
  },
  {
    id: '1d',
    text: 'clientPortal.options.dayAfterEvent',
    value: 1,
  },
  {
    id: '2d',
    text: 'clientPortal.options.dayAfterEvent',
    value: 2,
  },
  {
    id: '3d',
    text: 'clientPortal.options.dayAfterEvent',
    value: 3,
  },
];

export const UPSELL_PRODUCTS = [
  {
    id: 0,
    name: 'American Flag Backdrop',
    price: 100,
    salePrice: 75,
  },
  {
    id: 1,
    name: 'American Flag Backdrop',
    price: 100,
    salePrice: 75,
  },
  {
    id: 2,
    name: 'American Flag Backdrop',
    price: 100,
    salePrice: 75,
  },
];

export const ARTWORKS = [
  {
    id: 0,
    url: 'https://www.pragimtech.com/wp-content/uploads/2019/03/java-script.jpg',
    name: 'Template Design v1',
  },
  {
    id: 1,
    url: 'https://media.istockphoto.com/id/636761588/photo/used-brushes-on-an-artists-palette-of-colorful-oil-paint.jpg?s=612x612&w=0&k=20&c=38YQxVJVWnNfvGtlb7AXMx_ItyHZMEdmWenNkWNQ91g=',
    name: 'Template Design v2',
  },
  {
    id: 2,
    url: 'https://images.saatchiart.com/saatchi/714507/art/6797023/5866661-HSC00923-7.jpg',
    name: 'Template Design v3',
  },
];

export const PAYMENT_VISIBILITY_OPTIONS = [
  { id: 'always', value: 'clientPortal.options.always' },
  {
    id: '3m',
    value: 'clientPortal.options.threeMonthsBeforeEvent',
  },
  {
    id: '2m',
    value: 'clientPortal.options.twoMonthsBeforeEvent',
  },
  {
    id: '1m',
    value: 'clientPortal.options.oneMonthBeforeEvent',
  },
  {
    id: '3w',
    value: 'clientPortal.options.threeWeeksBeforeEvent',
  },
  {
    id: '2w',
    value: 'clientPortal.options.twoWeeksBeforeEvent',
  },
  {
    id: '1w',
    value: 'clientPortal.options.oneWeekBeforeEvent',
  },
];

export const ORDER_PRODUCTS = [
  { id: 0, name: 'Product 1', quantity: 3, price: 100 },
  { id: 1, name: 'Product 2', quantity: 2, price: 100 },
  { id: 2, name: 'Product 3', quantity: 2, price: 100 },
];

export const ORDER_PAYMENTS = [
  { id: 0, name: '05/11/2023-11:09 Credit Card Ending 4432', amount: 100, link: '#' },
  { id: 1, name: '05/11/2023-11:09 Credit Card Ending 4432', amount: 100, link: '#' },
];

export const BOOKING_LIST = [
  {
    id: 0,
    name: "Joe & Karen's Big Day",
    category: 'Wedding',
    time: '7pm -10pm',
    date: new Date(),
  },
  {
    id: 1,
    name: '2023 Christmas Party',
    category: 'Party',
    time: '7pm -10pm',
    date: new Date(),
  },
  {
    id: 2,
    name: "Karen's 50",
    category: 'Birthday',
    time: '7pm -10pm',
    date: new Date(),
  },
];

export const PRICE = 500;

export const FONT_SELECTION = ['Poppins', 'Roboto', 'Rubik', 'Lato', 'Work Sans', 'Open Sans', 'Oswald'];

export const initialClientPortalSettings: IClientPortalSettings = {
  login_settings: {
    description: 'Please login',
    login_style: [
      {
        primary_font_color: 'black',
        secondary_font_color: 'black',
        form_background: 'blue',
        font_family: 'serif',
        background_color: undefined,
      },
    ],
    login_form_type: 'email_magicLink',
  },
  client_portal_settings: {
    layout: COLUMNS_DATA,
    description: '',
    portal_layout: 1,
    portal_style: [
      { primary_font_color: 'black', secondary_font_color: 'black', form_background: 'black', font_family: 'black', background_color: undefined },
    ],
    artwork: {
      half_width: true,
      title: 'Artwork',
      file_options: {
        download: true,
        comment: true,
      },
    },
    assigned_staff: {
      half_width: true,
      title: 'Assigned Staff',
      contact_details: {
        phone: true,
        email: true,
      },
    },
    custom_blocks: {
      half_width: true,
      title: 'custom block',
      block_content: '',
    },

    make_payment: {
      title: 'Make Payment',
      half_width: true,
      suggested_amounts: {
        deposit: true,
        half_remaining: true,
        all_remaining: true,
        custom: true,
        minimal_amount: null,
      },
      visibility: {
        require_contract: true,
        date_logic: 'always',
      },
    },
    order_list: {
      title: 'Order List',
      half_width: true,
      hide_empty_fields: true,
      visible_fields: {
        1: true,
      },
      editable_fields: {
        2: true,
      },
    },
    job_list: {
      title: 'Job List',
      half_width: true,
      hide_empty_fields: true,
      visible_fields: {
        1: true,
      },
      editable_fields: {
        2: true,
      },
    },
    order_summary: {
      half_width: true,
      title: 'Order Summary',
      show_payments: true,
    },

    review: {
      half_width: true,
      title: 'Review',
      links: [],
    },

    upsells: {
      half_width: true,
      title: 'Upsells',
    },
  },
};
