import {
  IBusinessTeam,
  ICoupon,
  ICustomField,
  IManagementField,
  IPaginatedResponse,
  IPaginationParams,
  IUpsertCustomField,
  IUser,
  IVariationsType,
} from '@aduvi/types';

export enum EventType {
  ONLINE = 'ONLINE',
  PHYSICAL = 'PHYSICAL',
}

export enum EventsDrawerTabs {
  DETAILS = 'details',
  AGENDA = 'agenda',
  EXHIBITORS = 'exhibitors',
  FILES = 'files',
  FLOORPLAN = 'floorplan',
  SETTINGS = 'settings',
  FORMS = 'forms',
}

export enum TicketDrawerTabs {
  DETAILS = 'details',
  QUANTITY_STOCK = 'quantityStock',
  PRICING = 'pricing',
  VARIATIONS = 'variations',
  LIMIT_VISIBILITY = 'limitVisibility',
}

export interface IGetEventsPayload {
  business_id: string;
  params: IPaginationParams;
}

export interface ITicket {
  id: string;
  key: string;
  name: string;
  image: string;
  description: string;
  visibility: string;
  sold_tickets: number;
  price: string;
  sale_price: string;
  is_on_sale?: boolean;
  sale_end_date: string;
  sold: string;
  business_event_id: string;
  variations: IVariationsType[];
  coupons: ICoupon[];
}

export interface IExhibitorCustomField extends ICustomField {
  business_exhibitor_id: string;
}

export interface IExhibitor {
  id: string;
  name: string;
  logo: string;
  business_id: string;
  custom_fields: IExhibitorCustomField[];
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pivot: {
    business_event_id: string;
    business_exhibitor_id: string;
  };
  users: IUser[];
  teams: IBusinessTeam[];
}

export interface IAgenda {
  id: string;
  title: string;
  image: string;
  date_time: string;
  details: string;
}

export interface IEventCustomField extends ICustomField {
  business_event_id: string;
}

export interface IEvent {
  id: string;
  business_id: string;
  key: string;
  name: string;
  image: string;
  slug: string;
  type: EventType;
  location: {
    address_one: string;
    address_two: string;
    city: string;
    postal_code: string;
  };
  settings: {
    ticket_color: {
      attendees: string;
      exhibitors: string;
      staff: string;
    };
    reversed_image: string;
    email_description: string;
    terms_and_conditions_link?: string;
  };
  custom_fields: IEventCustomField[];
  tickets: ITicket[];
  agendas: IAgenda[];
  exhibitors: IExhibitor[];
  deleted_at: string;
  created_at: string;
  updated_at: string;
  floor_plan: string;
  users: IUser[];
  teams: IBusinessTeam[];
}

export interface IEventsState {
  events?: IPaginatedResponse<IEvent>;
  selectedEvent?: IEvent;
  selectedAgenda?: IAgenda;
  selectedExhibitor?: IExhibitor;
  loadingEvent: boolean;
  agendas: IAgenda[];
  exhibitors: IExhibitor[];
  eventCustomFields: IEventCustomFieldsState;
  exhibitorCustomFields: IExhibitorCustomFieldsState;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

interface IUpsertCustomFields extends IUpsertCustomField {
  event_field_id: string;
}

export interface IUpdateEventPayload {
  business_id: string;
  event_id: string;
  body: {
    name: string;
    image: string;
    start_date: string;
    end_date: string;
    type: EventType;
    custom_fields: IUpsertCustomFields[];
    exhibitors: string[];
  };
}

export interface ICreateEventPayload {
  business_id: string;
  body: {
    name: string;
    image: string;
    start_date: string;
    end_date: string;
    type: EventType;
    exhibitors: string[];
    custom_fields: IUpsertCustomFields[];
  };
}

export interface ICreateAgendaPayload {
  business_id: string;
  business_event_id: string;
  body: IAgenda;
}
export interface IDeleteAgendaPayload {
  business_id: string;
  business_event_id: string;
  agenda_id: string;
}

export interface ICreateExhibitorPayload {
  business_id: string;
  business_event_id: string;
  body: Omit<IExhibitor, 'pivot'>;
}

export interface IDeleteExhibitorPayload {
  business_id: string;
  business_event_id: string;
  exhibitor_id: string;
}
export interface ICreateTicketPayload {
  business_id: string;
  event_id: string;
  body: ITicket;
}

export interface IUpdateTicketPayload extends ICreateTicketPayload {
  ticket_id: string;
}

export interface IDeleteTicketPayload {
  business_id: string;
  event_id: string;
  ticket_id: string;
  ticket_name?: string;
}

export interface IGetTicketByIdPayload extends IDeleteTicketPayload {}

export interface IEventCustomFieldsState {
  eventFields: IManagementField[];
  loading: boolean;
  creating: boolean;
  updating: boolean;
  selectedEventField?: IManagementField;
}

export interface IExhibitorCustomFieldsState {
  exhibitorFields: IManagementField[];
  loading: boolean;
  creating: boolean;
  updating: boolean;
  selectedExhibitorField?: IManagementField;
}
