import { Avatar, List } from 'antd';

const data = [
  {
    title: 'Ridiculus tempus vitae lectus blandit vulputate dolor integer natoque augue.',
  },
  {
    title: 'Scelerisque ultrices tellus tellus sed mattis egestas purus ut vel.',
  },
  {
    title: 'Turpis fringilla pellentesque adipiscing neque sit.s',
  },
  {
    title: 'Massa viverra sed arcu scelerisque malesuada.',
  },
];

export const Activity = () => {
  return (
    <List
      // pagination={{ position, align }}
      className='mt-20'
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
            title={<p>{item.title}</p>}
            description='Ant Design, a design language for background applications, is refined by Ant UED Team'
          />
        </List.Item>
      )}
    />
  );
};
