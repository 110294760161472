import { useCallback, useRef, useState } from 'react';

export const useLongPress = (ms = 200) => {
  const longPressTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isLongPress, setIsLongPress] = useState(false);

  const handleMouseDown = useCallback(() => {
    setIsLongPress(false);
    longPressTimeout.current = setTimeout(() => {
      setIsLongPress(true);
    }, ms);
  }, [ms]);

  const handleMouseUp = useCallback(() => {
    if (longPressTimeout.current) {
      clearTimeout(longPressTimeout.current);
      longPressTimeout.current = null;
    }
  }, []);

  const checkLongPress = useCallback(() => {
    const wasLongPress = isLongPress;
    setIsLongPress(false);
    return wasLongPress;
  }, [isLongPress]);

  return { handleMouseDown, handleMouseUp, checkLongPress };
};
