import React from 'react';

import { Col, Divider, FormInstance } from 'antd';

import { AfterFormSubmit } from './@components/AfterFormSubmit';
import { FormSettings } from './@components/FormSettings';
import { ThankYouPageEditor } from './@components/ThankYouPageEditor';

export const Configure = ({ form }: { form: FormInstance }) => {
  return (
    <Col span={10} className='configure-tab-wrapper mb-20'>
      <FormSettings />

      <Divider />
      <AfterFormSubmit form={form} />

      <Divider />
      <ThankYouPageEditor form={form} />
    </Col>
  );
};
