import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EPersonalizedViewOrigin,
  ICreatePersonalizedViewPayload,
  IPersonalizedViewsState,
  IReorderPersonalizedViewPayload,
  IUpdatePersonalizedViewPayload,
} from '@aduvi/types/personalized-views';

import * as PersonalizedViewServices from 'store/services/personalized-view.service';

const initialState: IPersonalizedViewsState = {
  personalizedViews: [],
  creating: false,
  editing: false,
  loading: false,
  deleting: false,
  loadingView: false,
  selectedView: undefined,
  selectedViewId: undefined,
  showDrawer: false,
  hoveredPipelineFilterId: undefined,
};

export const getPersonalizedView = createAsyncThunk(
  'personalized-view/get',
  async (params: { businessId: string; origin: EPersonalizedViewOrigin }, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.getPersonalizedView(params.businessId, params.origin);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createPersonalizedView = createAsyncThunk(
  'personalized-view/create',
  async (params: ICreatePersonalizedViewPayload, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.createPersonalizedView(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPersonalizedViewById = createAsyncThunk(
  'personalized-view/get-by-id',
  async (params: { businessId: string; personalizedView: string }, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.getPersonalizedViewById(params.businessId, params.personalizedView);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editPersonalizedView = createAsyncThunk(
  'personalized-view/edit',
  async (params: IUpdatePersonalizedViewPayload, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.editPersonalizedView(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const reorderPersonalizedView = createAsyncThunk(
  'personalized-view/reorder',
  async (params: IReorderPersonalizedViewPayload, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.reorderPersonsalizedView(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deletePersonalizedView = createAsyncThunk(
  'personalized-view/delete',
  async (params: { businessId: string; personalizedViewId: string; name: string }, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.deletePersonalizedView(params.businessId, params.personalizedViewId, params.name);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const duplicatePersonalizedView = createAsyncThunk(
  'personalized-view/duplicate',
  async (params: { businessId: string; personalizedViewId: string }, { rejectWithValue }) => {
    try {
      return await PersonalizedViewServices.duplicatePersonalizedView(params.businessId, params.personalizedViewId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const personalizedViewsSlice = createSlice({
  name: 'personalized_views',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedPersonalizedView: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedViewId = payload;
      state.selectedView = state.personalizedViews?.find((item) => item.id === payload);
    },
    setShowPersonalizedViewDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.showDrawer = payload;
    },
    setHoveredPipelineFilterId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.hoveredPipelineFilterId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalizedView.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPersonalizedView.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPersonalizedView.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.personalizedViews = payload?.data?.sort((a, b) => a?.weight - b?.weight);
        state.selectedView = payload?.data?.[0];
        state.selectedViewId = payload?.data?.[0]?.id;
      })
      .addCase(createPersonalizedView.pending, (state) => {
        state.creating = true;
      })
      .addCase(createPersonalizedView.rejected, (state) => {
        state.creating = false;
      })
      .addCase(createPersonalizedView.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.personalizedViews = [...state.personalizedViews, payload.data];
        state.selectedView = payload.data;

        state.selectedViewId = payload.data.id;
      })
      .addCase(getPersonalizedViewById.pending, (state) => {
        state.loadingView = true;
      })
      .addCase(getPersonalizedViewById.rejected, (state) => {
        state.loadingView = false;
      })
      .addCase(getPersonalizedViewById.fulfilled, (state, { payload }) => {
        state.loadingView = false;
        state.selectedView = payload.data;
      })
      .addCase(editPersonalizedView.pending, (state) => {
        state.editing = true;
      })
      .addCase(editPersonalizedView.rejected, (state) => {
        state.editing = false;
      })
      .addCase(editPersonalizedView.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.personalizedViews = state.personalizedViews.map((item) => (item.id === payload.data.id ? payload.data : item));
        state.selectedView = payload.data;
      })
      .addCase(reorderPersonalizedView.pending, (state) => {
        state.editing = true;
      })
      .addCase(reorderPersonalizedView.rejected, (state) => {
        state.editing = false;
      })
      .addCase(reorderPersonalizedView.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.personalizedViews = payload.data.sort((a, b) => a?.weight - b?.weight);
      })
      .addCase(deletePersonalizedView.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deletePersonalizedView.rejected, (state) => {
        state.deleting = false;
      })
      .addCase(deletePersonalizedView.fulfilled, (state, { meta }) => {
        state.deleting = false;
        const index = state.personalizedViews.findIndex((item) => item.id === meta.arg.personalizedViewId);
        if (index >= 0) {
          const viewIndex = index !== 0 ? index - 1 : index + 1;
          state.selectedView = state.personalizedViews[viewIndex];
          state.selectedViewId = state.personalizedViews[viewIndex]?.id;
        }
        state.personalizedViews = state.personalizedViews.filter((item) => item.id !== meta.arg.personalizedViewId);
      })
      .addCase(duplicatePersonalizedView.pending, (state) => {
        state.creating = true;
      })
      .addCase(duplicatePersonalizedView.rejected, (state) => {
        state.creating = false;
      })
      .addCase(duplicatePersonalizedView.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.personalizedViews) state.personalizedViews = [...state.personalizedViews, payload.data];
        state.selectedView = payload.data;
        state.selectedViewId = payload.data.id;
      });
  },
});

export const personalizedViewsReducer = personalizedViewsSlice.reducer;
export const { setSelectedPersonalizedView, setShowPersonalizedViewDrawer, resetState, setHoveredPipelineFilterId } = personalizedViewsSlice.actions;
