import { useAppSelector } from 'store/hooks';

import { hasUserPermission } from '../utils/helper';

import { useBusiness } from './business';

export const useUser = () => {
  const { user } = useAppSelector((state) => state.auth);

  return user.user;
};

export const useUserBusinessPermissions = () => {
  const user = useUser();
  const selectedBusiness = useBusiness();

  return user?.businesses
    ?.find((business) => business.id === selectedBusiness?.id)
    ?.permissions?.map((permission) => `${permission.permission_group.name}.${permission.name}`);
};

export const useIsBusinessOwner = () => {
  const user = useUser();
  const selectedBusiness = useBusiness();

  return selectedBusiness?.user_id === user?.id;
};

export const useUserPermissionCheck = (permission: string | string[] | undefined) => {
  const isBusinessOwner = useIsBusinessOwner();
  const userBusinessPermissions = useUserBusinessPermissions();

  return hasUserPermission(isBusinessOwner, userBusinessPermissions, permission);
};
