import { ColumnsType } from 'antd/es/table';

import { EPersonalizedViewOrigin, IPersonalizedView } from '@aduvi/types';
import { IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { Calendar } from './Calendar/Calendar';
import { Forms } from './Forms/Forms';
import { Kanban } from './Kanban/Kanban';
import { Map } from './Map/Map';
import { Pipeline } from './Pipeline/Pipeline';
import { Table } from './Table/Table';
import { Timeline } from './Timeline/Timeline';

interface IUsePredefinedViews {
  data: IEntityWithFields[];
  columns: ColumnsType<IEntityWithFields>;
  selectedView?: IPersonalizedView;
  origin: EPersonalizedViewOrigin;
  selectedEntity?: IEntityType;
  entityType?: IEntityType;
  onRowClick?: (data: IEntityWithFields) => void;
  onKanbanHandleDrag: (id: string, entity: IEntityWithFields, overColumnId: string, columnsFieldId?: string) => void;
  setShowEntityDrawer?: (value: React.SetStateAction<boolean>) => void;
}

export const usePredefinedViews = ({
  columns,
  data,
  selectedView,
  origin,
  entityType,
  selectedEntity,
  onRowClick,
  onKanbanHandleDrag,
  setShowEntityDrawer,
}: IUsePredefinedViews): Record<string, JSX.Element> => {
  return {
    TABLE: (
      <Table
        data={data}
        columns={columns}
        origin={origin}
        onRowClick={onRowClick}
        entityType={entityType}
        setShowEntityDrawer={setShowEntityDrawer}
      />
    ),
    PIPELINE: (
      <Pipeline
        statusFieldId={selectedView?.status_field || ''}
        data={data}
        columns={columns}
        origin={origin}
        entityType={entityType}
        onRowClick={onRowClick}
      />
    ),
    MAP: <Map data={data} addressFieldId={selectedView?.address_field} />,
    KANBAN: (
      <Kanban
        onKanbanHandleDrag={onKanbanHandleDrag}
        data={data}
        origin={origin}
        entityType={entityType}
        viewId={selectedView?.id}
        columnsFieldId={selectedView?.status_field}
        columnSummaryId={selectedView?.column_summary}
      />
    ),
    TIMELINE: <Timeline data={data} origin={origin} entityType={entityType} />,
    CALENDAR: <Calendar entityType={entityType} data={data} onRowClick={onRowClick} origin={origin} />,
    FORMS: <Forms selectedEntity={selectedEntity} origin={origin} />,
  };
};
