import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Col, Row } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { AcademyGroupedByLevel } from '@aduvi/types/academy';

import { useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

import { groupAcademiesByLevel } from '../helper';

export const Academies = () => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { data: academies, isFetching: loading } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const groupedByLevel: AcademyGroupedByLevel = useMemo(() => groupAcademiesByLevel(academies?.data), [academies?.data]);

  return (
    <Row className='w-full pt-30 pl-30 pr-30' gutter={[20, 20]} justify={'start'}>
      {groupedByLevel &&
        Object.keys(groupedByLevel)?.map((level) => (
          <Col key={level} xs={24} md={12} lg={6}>
            <Card
              loading={loading}
              key={level}
              title={`Level ${level} `}
              style={{ height: 300, borderRadius: '8px', boxShadow: ' rgba(149, 157, 165, 0.2) 0px 4px 16px' }}>
              {groupedByLevel[Number(level)].map((academy) => (
                <Row key={academy?.id} className='mb-5 cursor-pointer'>
                  <Col key={academy?.id} onClick={() => navigate(`/academy/${academy?.id}`)}>
                    {academy?.name}
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        ))}
    </Row>
  );
};
