import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arraySwap, rectSwappingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, Col, Modal, Row, Spin } from 'antd';
import './Dashboard.scss';

import { useActiveTheme, useBusiness, useUser } from '@aduvi/hooks';

import { editUserDashboardLayout, getUserDashboardLayout, removeWidget, reorderWidgets } from 'store/features/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { DragModal } from './DragModal/DragModal';
import { WIDGET_DATA, WIDGETS, WidgetType } from './data';

const SortableWidget = ({ widget, isDraggable }: { widget: WidgetType; isDraggable: boolean }) => {
  const dispatch = useAppDispatch();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: widget.id,
    disabled: !isDraggable,
  });

  const style = {
    transition,
    position: 'relative',
    zIndex: isDragging ? 999 : undefined,
    transform: transform && CSS.Transform.toString({ x: transform?.x, y: transform?.y, scaleX: 1, scaleY: transform?.scaleY }),
    height: '100%',
    opacity: isDragging ? 1 : undefined,
    scaleX: '1 !important',
  };

  return (
    <Col xs={{ span: 24 }} lg={{ span: widget.columns * 6 }} style={style as React.CSSProperties} className={isDragging ? 'dragging' : ''}>
      {isDraggable && widget.index && (
        <Button
          type='primary'
          danger
          className='delete-widget-button'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(removeWidget(widget.id));
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}>
          <DeleteOutlined className='icon' />
        </Button>
      )}
      <div ref={setNodeRef} {...attributes} {...(isDraggable ? listeners : {})} className='widget'>
        {WIDGETS[widget.index]}
      </div>
    </Col>
  );
};

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const currentUser = useUser();
  const activeTheme = useActiveTheme();

  const { t: translate } = useTranslation();

  const { dashboard, layout } = useAppSelector((state) => state.common);

  const [searchParams] = useSearchParams();

  const [modalVisible, setModalVisible] = useState(false);
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const showWelcomeMessage = searchParams.get('welcome') === 'true';
  const activeIndex = dashboard.data?.findIndex((item) => item.id === activeId);

  const onCloseWelcomeModal = () => {
    setWelcomeModalVisible(false);
    searchParams.delete('welcome');
    window.history.replaceState({}, '', window.location.pathname);
  };

  const onCancelDrop = ({ active, over }: DragEndEvent) => {
    return active.id === over?.id;
  };

  const onDragEnd = ({ over }: DragEndEvent) => {
    setActiveId(null);
    if (!dashboard.data || activeIndex === undefined) return;
    if (over) {
      const overIndex = dashboard.data?.findIndex((item) => item.id === over.id);
      if (activeIndex !== overIndex) {
        dispatch(reorderWidgets(arraySwap(dashboard.data, activeIndex, overIndex)));
        return;
      }
    }
  };

  const handleSaveDashboardLayout = () => {
    if (!selectedBusiness?.id || !currentUser?.id || !dashboard.data) return;
    dispatch(
      editUserDashboardLayout({
        businessId: selectedBusiness?.id,
        userId: currentUser?.id,
        body: {
          dashboard_layout: dashboard.data,
        },
      }),
    );
    setModalVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !currentUser?.id) return;
    dispatch(
      getUserDashboardLayout({
        businessId: selectedBusiness.id,
        userId: currentUser.id,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        if (!data || data.length === 0) dispatch(reorderWidgets(WIDGET_DATA));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, currentUser?.id]);

  const onDragStart = ({ active }: DragStartEvent) => {
    if (!active) {
      return;
    }

    setActiveId(active.id);
  };

  useEffect(() => {
    setWelcomeModalVisible(showWelcomeMessage);
  }, [showWelcomeMessage]);

  useEffect(() => {
    if (!dashboard.data) dispatch(reorderWidgets(WIDGET_DATA));
  }, []);

  return (
    <Spin spinning={dashboard.loading}>
      <Col className='dashboard-wrapper' style={{ background: `linear-gradient(0deg, ${activeTheme?.bodyBg} 30%,${activeTheme?.dashboardBg} 100%)` }}>
        {modalVisible ? (
          <Button className='mb-10 add-widget-button' onClick={handleSaveDashboardLayout}>
            {translate('dashboard.save')}
          </Button>
        ) : (
          <SettingOutlined className='mb-10 fs-24 add-widget-button icon absolute' onClick={() => setModalVisible((prev) => !prev)} />
        )}
        <Row gutter={[16, 16]} className='dashboard-grid'>
          <DndContext onDragEnd={onDragEnd} onDragStart={onDragStart} cancelDrop={onCancelDrop} onDragCancel={() => {}}>
            <SortableContext items={dashboard.data || []} strategy={rectSwappingStrategy}>
              {dashboard.data?.map((item) => <SortableWidget key={item.id} widget={item} isDraggable={modalVisible} />)}
            </SortableContext>
          </DndContext>
        </Row>
        {modalVisible && <DragModal />}
      </Col>

      <Modal
        open={welcomeModalVisible && !!layout?.welcome_url}
        width={'fit-content'}
        footer={(_, { OkBtn }) => <OkBtn />}
        onOk={onCloseWelcomeModal}
        onCancel={onCloseWelcomeModal}>
        <div dangerouslySetInnerHTML={{ __html: `${layout?.welcome_url}` }} />
      </Modal>
    </Spin>
  );
};
