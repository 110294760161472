import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Popover, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import '../../ShiftScheduler.scss';

import { ETravelType, EWageStatus, EWageTypeShift } from '@aduvi/types/shift-scheduler';

interface ShiftDetailsModalProps {
  isVisible: boolean;
  form: FormInstance;
  index: number;
  remove: (index: number | number[]) => void;
  onClose: () => void;
}

export const WagesPopover = ({ isVisible, onClose, form, index, remove }: ShiftDetailsModalProps) => {
  const { t: translate } = useTranslation();

  const { TextArea } = Input;

  Form.useWatch('shifts', form);

  const popoverContent = (
    <div style={{ width: 200 }}>
      <Typography.Text>{translate('settings.shiftScheduler.status')}</Typography.Text>
      <Form.Item name={['shifts', index, 'status']} rules={[{ required: true }]}>
        <Select
          placeholder='Status'
          className='w-full mb-10'
          size='small'
          options={[
            { label: translate('settings.shiftScheduler.confirmed'), value: EWageStatus.CONFIRMED },
            { label: translate('settings.shiftScheduler.declined'), value: EWageStatus.DECLINED },
          ]}
          defaultValue={form.getFieldValue(['shifts', index, 'status'])}
          onChange={(value) => {
            form.setFieldValue(['shifts', index, 'status'], value);
          }}
        />
      </Form.Item>

      <Typography.Text>{translate('settings.shiftScheduler.wages.wage')}</Typography.Text>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item className='m-0' name={['shifts', index, 'wage_amount']} rules={[{ required: true }]}>
            <Input
              addonBefore='$'
              size='small'
              placeholder='Amount'
              min={0}
              defaultValue={form.getFieldValue(['shifts', index, 'wage_amount'])}
              onChange={(e) => {
                form.setFieldValue(['shifts', index, 'wage_amount'], e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className='m-0' name={['shifts', index, 'wage_type']} rules={[{ required: true }]}>
            <Select
              placeholder='Wage Type'
              className='w-full'
              size='small'
              options={[
                { label: translate('settings.shiftScheduler.wages.perHour'), value: EWageTypeShift.PER_HOUR },
                { label: translate('settings.shiftScheduler.wages.perDay'), value: EWageTypeShift.PER_DAY },
              ]}
              defaultValue={form.getFieldValue(['shifts', index, 'wage_type'])} // Bind to form value
              onChange={(value) => {
                form.setFieldValue(['shifts', index, 'wage_type'], value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Typography.Text style={{ color: '#1890ff' }}>5 Hours</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text style={{ color: '#1890ff' }}>$250.00</Typography.Text>
        </Col>
      </Row>

      <Typography.Text>{translate('settings.shiftScheduler.wages.travel')}</Typography.Text>
      <Row gutter={8} className='mb-5'>
        <Col span={12}>
          <Form.Item className='m-0' name={['shifts', index, 'travel_amount']} rules={[{ required: true }]}>
            <Input
              onChange={(e) => {
                form.setFieldValue(['shifts', index, 'travel_amount'], e.target.value);
              }}
              addonBefore='$'
              size='small'
              placeholder='Amount'
              min={0}
              defaultValue={form.getFieldValue(['shifts', index, 'travel_amount'])}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item className='m-0' name={['shifts', index, 'travel_type']} rules={[{ required: true }]}>
            <Select
              placeholder='Travel Type'
              className='w-full'
              size='small'
              options={[
                { label: translate('settings.shiftScheduler.wages.perHour'), value: ETravelType.PER_HOUR },
                { label: translate('settings.shiftScheduler.wages.perMile'), value: ETravelType.PER_MILE },
              ]}
              onChange={(value) => {
                form.setFieldValue(['shifts', index, 'travel_type'], value);
              }}
              defaultValue={form.getFieldValue(['shifts', index, 'travel_type'])}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Typography.Text style={{ color: '#1890ff' }}>40 miles</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text style={{ color: '#1890ff' }}>$40.00</Typography.Text>
        </Col>
      </Row>

      <Typography.Text>{translate('settings.shiftScheduler.notes')}</Typography.Text>
      <Form.Item name={['shifts', index, 'notes']}>
        <TextArea
          rows={3}
          className='mb-10'
          placeholder='Notes'
          defaultValue={form.getFieldValue(['shifts', index, 'notes'])}
          onChange={(e) => {
            form.setFieldValue(['shifts', index, 'notes'], e.target.value);
          }}
        />
      </Form.Item>

      <Button type='link' icon={<DeleteOutlined />} style={{ color: '#000', display: 'block' }} onClick={() => remove(index)}>
        {translate('settings.shiftScheduler.delete')}
      </Button>
    </div>
  );

  return (
    <Popover
      overlayClassName='custom-popover box-shadow'
      content={popoverContent}
      trigger='click'
      open={isVisible}
      onOpenChange={(visible) => !visible && onClose()}
      placement='bottom'
    />
  );
};
