import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppstoreOutlined, FolderFilled, FolderOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { FileCard } from 'views/files/@components/file-card/FileCard';

import { Breadcrumb, Col, Empty, Radio, Row, Spin } from 'antd';
import './FileManagement.scss';

import { Button } from '@aduvi/components/Button/Button';
import { useBusinessId } from '@aduvi/hooks';
import { IFile, IFolder } from '@aduvi/types/file';

import { getFilesByFolderId, setSelectedFolder } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  readOnly?: boolean;
  onSelectionChange?: (files: IFile[]) => void;
  filteredFiles?: IFile[];
}

export const FileManagement = ({ readOnly, onSelectionChange, filteredFiles }: IProps) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const { t: translate } = useTranslation();

  const [viewType, setViewType] = useState('grid');
  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [breadcrumbPath, setBreadcrumbPath] = useState<IFolder[]>([]);

  const { selectedFolder, files, sharedFolders, folders } = useAppSelector((state) => state.file);

  const toggleFileSelection = (file: IFile) => {
    setSelectedFiles((prevState) => {
      let newState = [];
      const exists = prevState.some((item) => item.id === file.id);

      if (exists) {
        newState = prevState.filter((item) => item.id !== file.id);
      } else {
        newState = [...prevState, file];
      }

      onSelectionChange?.(newState);
      return newState;
    });
  };

  const findParentFolders = (data: IFolder[], folderName: string): IFolder[] => {
    const result: IFolder[] = [];

    const searchFolders = (folders: IFolder[], parents: IFolder[]): boolean => {
      for (const folder of folders) {
        if (folder.id === folderName) {
          result.push(...parents, folder);
          return true;
        }

        if (searchFolders(folder.subfolders || [], [...parents, folder])) {
          return true;
        }
      }
      return false;
    };

    searchFolders(data, []);
    return result;
  };

  const handleFolderSelect = (folder: IFolder) => {
    if (!folder?.id) return;
    dispatch(setSelectedFolder(folder));
  };

  const subFolders = useMemo(() => {
    if (selectedFolder) return selectedFolder?.subfolders;
    if (sharedFolders?.active) return sharedFolders?.data;

    return folders?.data;
  }, [selectedFolder, sharedFolders.data]);

  useEffect(() => {
    if (!businessId || !selectedFolder) return;

    dispatch(getFilesByFolderId({ businessId, folderId: selectedFolder.id }));
  }, [businessId, selectedFolder]);

  useEffect(() => {
    if (!selectedFolder && sharedFolders?.active) return setBreadcrumbPath([{ name: translate('file.sharedWithMe') }] as IFolder[]);
    if (!selectedFolder || !folders?.data?.length) return;

    if (selectedFolder?.parent_id) {
      setBreadcrumbPath([...findParentFolders(folders?.data, selectedFolder?.parent_id), selectedFolder]);
    } else {
      setBreadcrumbPath([selectedFolder]);
    }
  }, [selectedFolder]);

  return (
    <Spin spinning={files.loading}>
      <Row wrap={false}>
        <Col span={24} className='file-wrapper' style={{ height: readOnly ? 'unset' : '100vh' }}>
          <Row className='w-full p-20 ' align='middle'>
            <Col style={{ gap: '5px', alignItems: 'center' }} lg={20}>
              {selectedFolder && (
                <Row className='w-full pl-20 pb-10' align={'middle'}>
                  <Col>
                    <Breadcrumb style={{ cursor: 'pointer' }}>
                      {breadcrumbPath.map((folder, index) => (
                        <Breadcrumb.Item key={index} onClick={() => handleFolderSelect(folder)}>
                          <FolderOutlined /> {folder?.name}
                        </Breadcrumb.Item>
                      ))}
                    </Breadcrumb>
                  </Col>
                </Row>
              )}
            </Col>
            <Col lg={4} className='flex-grow-1' style={{ justifyContent: 'end', display: 'flex' }}>
              <Radio.Group className='flex-center mb-10' onChange={(e) => setViewType(e.target.value)}>
                <Radio.Button style={{ textAlign: 'center' }} value='grid'>
                  <AppstoreOutlined className='fs-16' />
                </Radio.Button>
                <Radio.Button style={{ textAlign: 'center' }} value='list'>
                  <UnorderedListOutlined className='fs-16' />
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          <Row className='w-full px-20 pb-20' align={'middle'}>
            <Col>
              {subFolders?.map((folder) => (
                <Button
                  key={folder.id}
                  onClick={() => dispatch(setSelectedFolder(folder))}
                  className='folder-button'
                  icon={<FolderFilled style={{ fontSize: '16px', color: '#FFD591' }} />}>
                  {folder?.name}
                </Button>
              ))}
            </Col>
          </Row>

          {selectedFolder && (
            <Row gutter={[24, 0]} style={{ flexDirection: viewType === 'grid' ? 'row' : 'column' }} className='w-full ml-0'>
              {!files.loading && !files?.data?.length ? (
                <Row className='w-full' justify='center'>
                  <Empty />
                </Row>
              ) : (
                <></>
              )}

              {(filteredFiles || files?.data)?.map((file, index) => (
                <Col
                  className='mb-15 flex-grow-1'
                  key={index}
                  xs={24}
                  md={24}
                  sm={24}
                  lg={viewType === 'list' ? 24 : 8}
                  xl={viewType === 'list' ? 24 : 6}
                  xxl={viewType === 'list' ? 24 : 6}>
                  <FileCard
                    file={file}
                    viewType={viewType}
                    isSelected={selectedFiles.some((item) => item.id === file.id)}
                    toggleSelect={() => toggleFileSelection(file)}
                    readOnly={!!readOnly}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};
