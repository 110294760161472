import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import { ICoupon, IDiscountSettings, IGetCouponsPayload } from '@aduvi/types/discount';
export const getDiscountSettings = async (business_id: string): Promise<IResponse<IDiscountSettings[]>> => {
  return await axios.get(`/businesses/${business_id}/discount-settings`);
};
export const createDiscountSettings = async (
  payload: Omit<IDiscountSettings, 'id'> & { business_id: string },
): Promise<IResponse<IDiscountSettings>> => {
  return await axios.post(`/businesses/${payload.business_id}/discount-settings`, payload);
};
export const editDiscountSettings = async (payload: IDiscountSettings & { business_id: string }): Promise<IResponse<IDiscountSettings>> => {
  return await axios.put(`/businesses/${payload.business_id}/discount-settings/${payload.id}`, payload);
};
export const createCoupon = async (payload: Omit<ICoupon, 'id'>, business_id: string): Promise<IResponse<ICoupon>> => {
  return await axios.post(`/businesses/${business_id}/coupons`, payload);
};
export const getCoupons = async (payload: IGetCouponsPayload): Promise<IPaginatedResponse<ICoupon>> => {
  return await axios.get(`/businesses/${payload.business_id}/coupons`, { params: payload.params });
};
export const editCoupon = async (payload: ICoupon, business_id: string, coupon_id: string): Promise<IResponse<ICoupon>> => {
  return await axios.put(`/businesses/${business_id}/coupons/${coupon_id}`, payload);
};
export const deleteCoupon = async (business_id: string, coupon_id: string, cupon_name: string): Promise<void> =>
  axios.delete(`/businesses/${business_id}/coupons/${coupon_id}?title=${cupon_name}`);
export const cloneCoupon = async (businessId: string, coupon_id: string): Promise<IResponse<ICoupon>> => {
  return await axios.post(`/businesses/${businessId}/coupons/${coupon_id}/duplicate`);
};
