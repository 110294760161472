export const PushoverNotificationIcon = () => {
  return (
    <svg width='14' height='17' viewBox='0 0 14 17' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ fontSize: '18px' }}>
      <path
        d='M6.27313 2.52699L9.64245 2.08366L6.3618 9.48728C6.93813 9.44295 7.50329 9.24721 8.05731 8.9001C8.61161 8.55267 9.12163 8.11671 9.58726 7.59227C10.0526 7.06751 10.4552 6.48381 10.7951 5.84111C11.135 5.19815 11.3788 4.56631 11.5266 3.94565C11.6153 3.56144 11.656 3.19198 11.6487 2.83732C11.6412 2.48265 11.5488 2.17232 11.3714 1.90632C11.1941 1.64033 10.9244 1.42603 10.5621 1.26349C10.2002 1.10095 9.70895 1.01966 9.08828 1.01966C8.36416 1.01966 7.65108 1.13787 6.94899 1.37433C6.24721 1.61078 5.59696 1.95441 4.99833 2.4053C4.39997 2.85587 3.87915 3.4174 3.43582 4.08995C2.99248 4.76219 2.67478 5.52684 2.48265 6.38397C2.40878 6.66476 2.36445 6.89004 2.34965 7.05982C2.33486 7.22991 2.33124 7.37029 2.3388 7.48098C2.34604 7.59195 2.36065 7.677 2.38268 7.73613C2.40498 7.79525 2.42353 7.85433 2.43832 7.91346C1.68466 7.91346 1.13411 7.76191 0.786686 7.45882C0.439576 7.15604 0.354665 6.6278 0.531997 5.87414C0.709329 5.09093 1.11208 4.34464 1.7403 3.63531C2.3682 2.92599 3.11431 2.30157 3.97867 1.76201C4.8433 1.22278 5.78909 0.794377 6.81599 0.476807C7.8432 0.158921 8.84445 0 9.81978 0C10.6769 0 11.3972 0.121826 11.9808 0.365522C12.5647 0.60949 13.0191 0.930995 13.3443 1.32999C13.6694 1.72899 13.8725 2.19449 13.9536 2.72649C14.035 3.25848 14.0093 3.81265 13.8763 4.38898C13.7137 5.08351 13.407 5.78185 12.9561 6.48394C12.5056 7.18572 11.9404 7.82117 11.2606 8.39027C10.5808 8.95904 9.80123 9.42078 8.92181 9.77545C8.0427 10.1301 7.10809 10.3074 6.11797 10.3074H6.00713L3.19198 16.6249H0L6.27313 2.52699Z'
        fill='#B37FEB'
      />
    </svg>
  );
};
