import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Col, Divider, Dropdown, Form, FormInstance, Input, Row, Switch, Typography } from 'antd';
import './OrderSummary.scss';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { ORDER_PAYMENTS, ORDER_PRODUCTS } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const OrderSummary = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [showPayments, setShowPayments] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const title = useWidgetTitle(
    'clientPortal.orderSummary.title',
    'order_summary',
    selectedClientPortal?.client_portal_settings?.order_summary?.title,
    form,
  );

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              open={dropdownVisible}
              onOpenChange={(isOpen) => {
                setDropdownVisible(isOpen);
              }}
              destroyPopupOnHide
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item initialValue={'Order Summary'} labelCol={{ span: 24 }} name={['client_portal_settings', 'order_summary', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' name={['client_portal_settings', 'order_summary', 'half_width']}>
                      <Switch
                        size='small'
                        onChange={() => {}}
                        defaultValue={selectedClientPortal?.client_portal_settings?.order_summary?.half_width}
                        style={{ margin: 6 }}
                      />
                    </Form.Item>
                    <Typography.Text> {translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' initialValue={true} name={['client_portal_settings', 'order_summary', 'show_payments']}>
                      <Switch size='small' onChange={() => setShowPayments((prev) => !prev)} defaultChecked style={{ margin: 6 }} />
                    </Form.Item>
                    <Typography.Text>{translate('clientPortal.orderSummary.showPayments')}</Typography.Text>
                  </Row>
                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.ORDER_SUMMARY, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='client-portal-order-summary-wrapper pb-30'>
      {ORDER_PRODUCTS.map((product) => (
        <Row key={product.id} justify={'space-between'} className=''>
          <Col>
            {product.quantity} x {product.name}
          </Col>
          <Col className='text-right'>
            <PriceDisplay price={product.price} />
          </Col>
        </Row>
      ))}

      <Divider className=' m-10 invoice-divider' />
      <div className='invoice-total'>
        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('checkout.subtotal')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={700} />
          </Col>
        </Row>
        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('checkout.fee')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={50} />
          </Col>
        </Row>
        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('checkout.discountAmount')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={200} />
          </Col>
        </Row>
        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('checkout.total')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={550} />
          </Col>
        </Row>
        {showPayments && (
          <>
            <Divider className='m-10 invoice-divider' />
            <Typography.Paragraph type='secondary'>{translate('clientPortal.orderSummary.payments')}</Typography.Paragraph>
            {ORDER_PAYMENTS.map((payment) => (
              <Typography.Link key={payment.id}>
                <Row justify='space-between'>
                  <Col span={14} className='payment-title'>
                    {payment.name}
                  </Col>
                  <Col xs={10} sm={4} className='text-right'>
                    <PriceDisplay price={payment.amount} />
                  </Col>
                </Row>
              </Typography.Link>
            ))}
          </>
        )}

        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('clientPortal.orderSummary.totalPaid')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={250} />
          </Col>
        </Row>
        <Divider className='m-10 invoice-divider' />

        <Row justify={{ xs: 'space-between', sm: 'end' }}>
          <Col span={10} className='mr-20 text-right fw-600'>
            {translate('clientPortal.orderSummary.remainingBalance')}
          </Col>
          <Col xs={10} sm={4} className='fw-600 text-right'>
            <PriceDisplay price={300} />
          </Col>
        </Row>
      </div>
    </Card>
  );
};
