import { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export const CustomTableCell = ({ style, ...restProps }: IProps) => {
  return (
    <div {...restProps} className='custom-table-cell' style={{ ...(style || {}), borderRadius: 8, padding: '3px 5px', display: 'inline-block' }}>
      {restProps.children}
    </div>
  );
};
