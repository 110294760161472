import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { SelectOrder } from '@aduvi/components/SelectOrder/SelectOrder';
import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EOrderType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

const inputEntityFields = [
  EFieldDataValueType.FIELD_DATA_EMAILS,
  EFieldDataValueType.FIELD_DATA_LONG_TEXTS,
  EFieldDataValueType.FIELD_DATA_NUMBERS,
  EFieldDataValueType.FIELD_DATA_PHONES,
  EFieldDataValueType.FIELD_DATA_TEXTS,
  EFieldDataValueType.FIELD_DATA_URLS,
];

export const Kanban = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const defaultNumberOptions = fields
    .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_NUMBERS)
    ?.map((field) => ({ label: field?.title, value: field?.id }));

  const inputManagementFields = fields
    .filter((item) => inputEntityFields.includes(item.value_type))
    ?.map((field) => ({ label: field?.title, value: field?.id }));

  const statusFieldOptions = fields.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LISTS);

  useEffect(() => {
    form.setFieldsValue({
      status_field: form.getFieldValue('status_field') || statusFieldOptions?.[0]?.id,
      default_date_values: form.getFieldValue('default_date_values') || inputManagementFields?.[0]?.value,
      default_sort_order: form.getFieldValue('default_sort_order') || EOrderType.ASC,
    });
  }, []);

  return (
    <Col>
      <Form.Item name='status_field' label={translate('leads.kanban.columnsField')}>
        <Select
          options={statusFieldOptions.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
          placeholder={translate('leads.jobStatus')}
        />
      </Form.Item>
      {defaultNumberOptions?.length > 0 && (
        <Form.Item name='column_summary' label={translate('leads.kanban.columnSummary')}>
          <Select allowClear options={defaultNumberOptions} placeholder={translate('leads.kanban.eventBudgetKanban')} />
        </Form.Item>
      )}
      <label>{translate('leads.defaultSort')}</label>
      <Row className='w-full mt-10' justify={'space-between'}>
        <Form.Item name='default_date_values' style={{ width: '49%' }} initialValue={''}>
          <Select options={fields.map((field) => ({ label: field?.title, value: field?.id }))} placeholder={translate('leads.eventDate')} />
        </Form.Item>
        <Form.Item name='default_sort_order' style={{ width: '49%' }}>
          <SelectOrder placeholder={translate('leads.ascending')} />
        </Form.Item>
      </Row>
    </Col>
  );
};
