import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Button, Card, Carousel, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';
import { EEntityType, IEntityWithFields } from '@aduvi/types/entity';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getEntities } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [isMobileView, setIsMobileView] = useState(false);
  const { t: translate } = useTranslation();

  const {
    entities: { data: events, loading },
    entityTypes: { data: entityTypesData },
  } = useAppSelector((state) => state.entity);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  const eventEntityTypeId = entityTypesData[EEntityType.EVENT]?.id;

  const displayedEvents = useMemo(() => {
    if (!events) return [];
    if (limitResults === 'show_all') {
      return events;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return events.slice(0, resultsPerPage);
    }
    return events;
  }, [events, limitResults, resultsPerPage]);

  const eventFields = ['Picture', 'Title', 'Date', 'Price', 'Description', 'Button'];
  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || eventFields;

  const entitySettings = (
    <div>
      <Typography.Text type='secondary'>{translate('widgets.priceDisplay')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'price_display']}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'price_display'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'price_display'], value);
          }}>
          <Select.Option value='From'>{translate('widgets.from')}</Select.Option>
          <Select.Option value='Range'>{translate('widgets.range')}</Select.Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonAction')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'button_action']}>
        <Select value='Buy Ticket'>
          <Select.Option value='Buy Ticket'>{translate('widgets.buyTicket')}</Select.Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonText')}</Typography.Text>
      <Form.Item name={['widget_style', 0, 'button_text']}>
        <Input
          value={form.getFieldValue(['widget_style', 0, 'button_text'])}
          onChange={(e) => {
            form.setFieldValue(['widget_style', 0, 'button_text'], e.target.value);
          }}
        />
      </Form.Item>

      <Form.Item name={['widget_style', 0, 'entity_type_event_id']} hidden={true} />
    </div>
  );

  const extractEventFields = (event: IEntityWithFields) => {
    const image = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_IMAGES)?.field_data?.[0]?.url;
    const title = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value;
    const date = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)?.field_data?.[0]?.value;
    const description = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LONG_TEXTS)?.field_data?.[0]?.value;

    return { image, title, date, description };
  };

  const getPriceDisplay = (event: IEntityWithFields) => {
    const tickets = event.tickets || [];

    if (tickets.length === 0) return null;

    const prices = tickets.map((ticket) => parseFloat(ticket.price));

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    if (form.getFieldValue(['widget_style', 0, 'price_display']) === 'From') {
      return `From $${minPrice}`;
    } else if (form.getFieldValue(['widget_style', 0, 'price_display']) === 'Range') {
      return `$${minPrice} - $${maxPrice}`;
    }
    return null;
  };

  const handleButtonClick = (event: IEntityWithFields) => {
    window.open(`/buy-ticket?eventId=${event.id}`, '_blank');
  };

  const onGetEvents = useCallback(() => {
    if (!selectedBusiness?.id || !eventEntityTypeId) return;
    dispatch(
      getEntities({
        businessId: selectedBusiness.id,
        entityTypeId: eventEntityTypeId,
      }),
    );
  }, [selectedBusiness?.id, eventEntityTypeId]);

  useEffect(() => {
    form.setFieldValue(['widget_style', 0, 'entity_type_event_id'], eventEntityTypeId || '');
    onGetEvents();
  }, [onGetEvents]);

  return (
    <Spin spinning={loading}>
      <Row className='event-design-tab-wrapper' style={backgroundStyle}>
        <Col span={14}>
          <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
            {selectedLayout === EWidgetLayout.CARDS ? (
              <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
                {displayedEvents?.map((event, index) => {
                  const { image, title, date, description } = extractEventFields(event);
                  return (
                    <div key={index} className='events-card'>
                      {visibleFields.includes('Picture') && image && <img alt={''} src={image} width={100} height={100} className='mb-10' />}
                      {visibleFields.includes('Title') && title && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {title}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Date') && date && (
                        <Typography.Text
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {date}
                        </Typography.Text>
                      )}
                      {visibleFields.includes('Price') && (
                        <Typography.Paragraph
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {getPriceDisplay(event)}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Description') && description && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {description}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Button') && (
                        <Button
                          type='primary'
                          onClick={() => handleButtonClick(event)}
                          style={{
                            background: form.getFieldValue(['widget_style', 0, 'button_background']),
                            color: form.getFieldValue(['widget_style', 0, 'button_font']),
                          }}>
                          {form.getFieldValue(['widget_style', 0, 'button_text']) || translate('widgets.buyTickets')}
                        </Button>
                      )}
                    </div>
                  );
                })}
              </Carousel>
            ) : selectedLayout === EWidgetLayout.COLUMNS ? (
              <Row gutter={[20, 20]}>
                {displayedEvents?.map((event, index) => {
                  const { image, title, date, description } = extractEventFields(event);
                  return (
                    <Col
                      key={index}
                      xs={24}
                      sm={12}
                      md={8}
                      lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan}
                      className='events-card'>
                      {visibleFields.includes('Picture') && image && <img alt={''} src={image} width={100} height={100} className='mb-10' />}
                      {visibleFields.includes('Title') && title && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {title}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Date') && date && (
                        <Typography.Text
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {date}
                        </Typography.Text>
                      )}
                      {visibleFields.includes('Price') && (
                        <Typography.Paragraph
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {getPriceDisplay(event)}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Description') && description && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {description}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Button') && (
                        <Button
                          type='primary'
                          onClick={() => handleButtonClick(event)}
                          style={{
                            background: form.getFieldValue(['widget_style', 0, 'button_background']),
                            color: form.getFieldValue(['widget_style', 0, 'button_font']),
                          }}>
                          {form.getFieldValue(['widget_style', 0, 'button_text']) || translate('widgets.buyTickets')}
                        </Button>
                      )}
                    </Col>
                  );
                })}
              </Row>
            ) : (
              displayedEvents?.map((event, index) => {
                const { image, title, date, description } = extractEventFields(event);
                return (
                  <Row key={index} gutter={[20, 20]} align='middle' className='events-card-row'>
                    {visibleFields.includes('Picture') && image && (
                      <Col xs={24} sm={6} className='event-image'>
                        <img alt={''} src={image} width={100} height={100} className='mb-10' />
                      </Col>
                    )}
                    <Col xs={24} sm={18} className='event-details'>
                      {visibleFields.includes('Title') && title && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {title}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Date') && date && (
                        <Typography.Text
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {date}
                        </Typography.Text>
                      )}
                      {visibleFields.includes('Price') && (
                        <Typography.Paragraph
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {getPriceDisplay(event)}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Description') && description && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {description}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Button') && (
                        <Button
                          type='primary'
                          onClick={() => handleButtonClick(event)}
                          style={{
                            background: form.getFieldValue(['widget_style', 0, 'button_background']),
                            color: form.getFieldValue(['widget_style', 0, 'button_font']),
                          }}>
                          {form.getFieldValue(['widget_style', 0, 'button_text']) || translate('widgets.buyTickets')}
                        </Button>
                      )}
                    </Col>
                  </Row>
                );
              })
            )}
          </Card>
        </Col>
        <Col span={1} className='icons-container'>
          <RightSidebar
            form={form}
            onMobileView={() => setIsMobileView((prevState) => !prevState)}
            isMobileView={isMobileView}
            fields={eventFields}
            entitySettings={entitySettings}
          />
        </Col>
      </Row>
    </Spin>
  );
};
