import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Button, Col, Form, Input, Modal, Row, Table, TimePicker, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { ColumnsType } from 'antd/es/table';
import './Agenda.scss';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { useBusiness } from '@aduvi/hooks';
import { IAgenda } from '@aduvi/types/event';

import { createAgenda, deleteAgenda, setSelectedAgenda, updateAgenda } from 'store/features/events-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IGroupedAgendas {
  date: string;
  items: IAgenda[];
}
export const Agenda = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateTime, setDateTime] = useState({
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    time: dayjs(new Date()).format('HH:mm:ss'),
  });
  const { selectedEvent, selectedAgenda } = useAppSelector((state) => state.events);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    dispatch(setSelectedAgenda(undefined));
    form.resetFields();
    setIsModalOpen(false);
  };

  const onSubmit = () => {
    if (!selectedAgenda) return onCreateAgenda();
    onEditAgenda();
  };
  const onDeleteAgenda = () => {
    if (!selectedBusiness || !selectedAgenda || !selectedEvent) return;
    dispatch(
      deleteAgenda({
        business_id: selectedBusiness.id,
        business_event_id: selectedEvent.id,
        agenda_id: selectedAgenda.id,
      }),
    ).then(() => {
      closeModal();
    });
  };
  const onCreateAgenda = () => {
    if (!selectedBusiness?.id || !selectedEvent?.id) return;
    form.setFieldValue('date_time', `${dateTime.date} ${dateTime.time}`);
    dispatch(
      createAgenda({
        business_event_id: selectedEvent?.id,
        business_id: selectedBusiness?.id,
        body: {
          ...form.getFieldsValue(),
        },
      }),
    ).then(() => {
      closeModal();
    });
  };

  const onEditAgenda = () => {
    if (!selectedBusiness?.id || !selectedEvent?.id || !selectedAgenda) return;
    form.setFieldValue('date_time', `${dateTime.date} ${dateTime.time}`);
    dispatch(
      updateAgenda({
        business_event_id: selectedEvent?.id,
        business_id: selectedBusiness?.id,
        body: {
          ...selectedAgenda,
          ...form.getFieldsValue(),
        },
      }),
    ).then(() => {
      closeModal();
    });
  };

  const columns = useTableColumns(setIsModalOpen);
  const groupedData: IGroupedAgendas[] = useMemo(() => {
    return (selectedEvent?.agendas ?? [])
      .reduce<IGroupedAgendas[]>((acc, item) => {
        const date = item.date_time.split(' ')[0];
        const existingEntry = acc.find((entry) => entry.date === date);
        if (existingEntry) {
          existingEntry.items.push(item);
        } else {
          acc.push({ date, items: [item] });
        }
        return acc;
      }, [])
      .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
      .map((item) => ({ date: item.date, items: item.items.sort((a, b) => (a.date_time < b.date_time ? -1 : a.date_time > b.date_time ? 1 : 0)) }));
  }, [selectedEvent?.agendas]);

  useEffect(() => {
    if (!selectedAgenda) return;

    form.setFieldsValue(selectedAgenda);
    setDateTime({
      date: dayjs(selectedAgenda.date_time).format('YYYY-MM-DD'),
      time: dayjs(selectedAgenda.date_time).format('HH:mm:ss'),
    });
  }, [selectedAgenda]);

  return (
    <Col span={24} className='agenda-form-wrapper'>
      <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
        {groupedData?.map((day) => (
          <div key={day.date}>
            <Typography.Text key={day.date} type='secondary'>
              {dayjs(day.date).format('Do MMMM, YYYY')}
            </Typography.Text>
            <Table
              size='small'
              className='agenda-table w-full'
              rowKey='id'
              loading={false}
              columns={columns}
              pagination={false}
              dataSource={day.items}
            />
          </div>
        ))}

        <Row>
          <Button onClick={showModal} icon={<PlusOutlined />}>
            {translate('assets.eventsDrawer.agenda.addAgenda')}
          </Button>
        </Row>
        <Modal
          title={translate('assets.eventsDrawer.agenda.addAgenda')}
          open={isModalOpen}
          onOk={onSubmit}
          onCancel={closeModal}
          destroyOnClose={true}
          style={{ borderRadius: '10px' }}
          className='event-agenda-modal'
          footer={[
            selectedAgenda && (
              <Button key='back' style={{ marginRight: 'auto' }} onClick={onDeleteAgenda}>
                {translate('assets.eventsDrawer.agenda.removeItem')}
              </Button>
            ),
            <Button key='submit' style={{ marginLeft: 'auto' }} type='primary' onClick={onSubmit}>
              {translate('assets.eventsDrawer.agenda.save')}
            </Button>,
          ]}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Row wrap={true}>
              <Col span={24} className='mt-15'>
                <Form.Item
                  name='title'
                  label={translate('assets.eventsDrawer.agenda.agendaTitle')}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: translate('assets.eventsDrawer.agenda.validation.agendaTitle') }]}>
                  <Input placeholder={translate('assets.eventsDrawer.agenda.placeholder.agendaTitle')} />
                </Form.Item>
                <Form.Item className='mb-0' name='date_time' />

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={translate('assets.eventsDrawer.agenda.dateTime')}
                      labelCol={{ span: 24 }}
                      rules={[{ required: true, message: translate('assets.eventsDrawer.agenda.validation.date') }]}>
                      <DatePicker
                        defaultValue={selectedAgenda ? dayjs(selectedAgenda?.date_time) : dayjs(new Date())}
                        style={{ width: '100%' }}
                        onChange={(e) => setDateTime((prev) => ({ ...prev, date: dayjs(e).format('YYYY-MM-DD') }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ alignSelf: 'flex-end' }}>
                    <Form.Item labelCol={{ span: 24 }} rules={[{ required: true, message: translate('assets.eventsDrawer.agenda.validation.time') }]}>
                      <TimePicker
                        defaultValue={selectedAgenda ? dayjs(selectedAgenda?.date_time) : dayjs(new Date())}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setDateTime((prev) => ({ ...prev, time: dayjs(e).format('HH:mm:ss') }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label={translate('assets.eventsDrawer.agenda.image')} name='image' labelCol={{ span: 24 }}>
                  <UploadImage name='image' onUploadChange={(value) => form.setFieldValue('image', value?.url)} />
                </Form.Item>

                <Form.Item
                  name='details'
                  label={translate('assets.eventsDrawer.agenda.details')}
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: translate('assets.eventsDrawer.agenda.validation.details') }]}>
                  <TextArea
                    name='descrioption'
                    placeholder={translate('assets.eventsDrawer.agenda.placeholder.details')}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </Col>
  );
};

const useTableColumns = (setShowAgendaModal: (prop: boolean) => void): ColumnsType<IAgenda> => {
  const dispatch = useAppDispatch();
  return [
    {
      title: 'Time',
      dataIndex: 'date_time',
      width: 50,
      render: (record) => {
        return <span>{dayjs(record).format('ha')}</span>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },

    {
      title: ' ',
      render: (_, record) => (
        <Row className='w-full' justify='end'>
          <EllipsisOutlined
            onClick={() => {
              dispatch(setSelectedAgenda(record));
              setShowAgendaModal(true);
            }}
          />
        </Row>
      ),
    },
  ];
};
