import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Col, Row } from 'antd';

import { initialClientPortalSettings, READONLY_COLUMNS } from '@aduvi/constants/client-portal-constants';

import { useAppSelector } from 'store/hooks';

export const AllComponents = () => {
  const { jobId } = useParams();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const clientPortalSetting = useMemo(
    () => selectedClientPortal?.client_portal_settings || initialClientPortalSettings?.client_portal_settings,
    [selectedClientPortal?.client_portal_settings],
  );

  return (
    <Row>
      <Col span={22}>
        <Row gutter={[16, 16]}>
          {(clientPortalSetting?.layout?.filter((item) => item?.visible) || clientPortalSetting.layout).map((item) => {
            if (item?.index === 'order_list' && jobId) return null;
            if (item?.index === 'job_list' && !jobId) return null;
            return (
              <Col
                key={item?.id}
                xs={{ span: 24 }}
                lg={{
                  span:
                    !Object.prototype.hasOwnProperty.call(clientPortalSetting?.[item?.index], 'half_width') ||
                    clientPortalSetting?.[item?.index]?.half_width
                      ? 12
                      : 24,
                }}>
                <Col>{READONLY_COLUMNS[item?.index]}</Col>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
