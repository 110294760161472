import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Form, FormInstance, Input, Radio, Row, Select } from 'antd';
import './QuantityStock.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { SelectUser } from '@aduvi/components/SelectUser/SelectUser';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { useActiveTheme } from '@aduvi/hooks';
import { EDigitalType, EProductType, IProductSettings } from '@aduvi/types/products';

import { useAppSelector } from 'store/hooks';

const initialProductSettings: IProductSettings = {
  productType: EProductType.RENTABLE,
  allowCustomerToChooseQuantityRentable: false,
  allowCustomerToChooseQuantityConsumable: false,
  notificationStockThreshold: false,
};

export const QuantityStock = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const activeTheme = useActiveTheme();

  const [productSettings, setProductSettings] = useState<IProductSettings>(initialProductSettings);
  const [digitalType, setDigitalType] = useState<EDigitalType>(EDigitalType.URL);

  const { selectedProduct } = useAppSelector((state) => state.products);

  const handleSettingChange = (name: keyof IProductSettings, value: boolean | EProductType) => {
    setProductSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  useEffect(() => {
    setProductSettings({
      productType: form.getFieldValue('product_type') || EProductType.RENTABLE,
      allowCustomerToChooseQuantityRentable: !!form.getFieldValue('allow_custom_quantity'),
      allowCustomerToChooseQuantityConsumable: !!form.getFieldValue('allow_custom_quantity'),
      notificationStockThreshold: !!form.getFieldValue('low_stock_notification'),
    });
    setDigitalType(form.getFieldValue('digital_type') || EDigitalType.URL);
  }, [form]);

  return (
    <Col span={24} className='mt-15 w-full quantity-stock-wrapper'>
      <Form.Item label='Product Type' name='product_type' initialValue={productSettings.productType}>
        <Radio.Group
          className='w-full'
          style={{ display: 'flex', flexDirection: 'row' }}
          onChange={(e) => handleSettingChange('productType', e.target.value as EProductType)}
          value={productSettings.productType}>
          <Radio.Button className='radio-button-RCD' value={EProductType.RENTABLE}>
            <Paragraph
              className={productSettings.productType === EProductType.RENTABLE ? 'product-type-title-active' : 'product-type-title '}
              style={productSettings.productType === EProductType.RENTABLE ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.rentable')}
            </Paragraph>
            <Paragraph
              className={productSettings.productType === EProductType.RENTABLE ? 'product-type-active' : 'product-type-description'}
              style={productSettings.productType === EProductType.RENTABLE ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.rentableParagraph')}
            </Paragraph>
          </Radio.Button>
          <Radio.Button className='radio-button-RCD' value={EProductType.CONSUMABLE}>
            <Paragraph
              className={productSettings.productType === EProductType.CONSUMABLE ? 'product-type-title-active' : 'product-type-title '}
              style={productSettings.productType === EProductType.CONSUMABLE ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.consumable')}
            </Paragraph>
            <Paragraph
              className={productSettings.productType === EProductType.CONSUMABLE ? 'product-type-active' : 'product-type-description'}
              style={productSettings.productType === EProductType.CONSUMABLE ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.consumableParagraph')}
            </Paragraph>
          </Radio.Button>
          <Radio.Button className='radio-button-RCD' value={EProductType.DIGITAL}>
            <Paragraph
              className={productSettings.productType === EProductType.DIGITAL ? 'product-type-title-active' : 'product-type-title '}
              style={productSettings.productType === EProductType.DIGITAL ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.digital')}
            </Paragraph>
            <Paragraph
              className={productSettings.productType === EProductType.DIGITAL ? 'product-type-active' : 'product-type-description'}
              style={productSettings.productType === EProductType.DIGITAL ? { color: activeTheme?.productTypeBg } : {}}>
              {translate('assets.productsQuantityStock.digitalParagraph')}
            </Paragraph>
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      {productSettings.productType === EProductType.RENTABLE && (
        <>
          <Form.Item name='quantity_available' label={translate('assets.productsQuantityStock.quantityAvailable')}>
            <Input placeholder={translate('assets.productsQuantityStock.quantityAvailablePlaceholder')} />
          </Form.Item>

          <Form.Item name='allow_custom_quantity' valuePropName='checked'>
            <Checkbox className='mb-15' onChange={(e) => handleSettingChange('allowCustomerToChooseQuantityRentable', e.target.checked)}>
              {translate('assets.productsQuantityStock.allowCustomerToChooseQuantity')}
            </Checkbox>
          </Form.Item>

          {productSettings.allowCustomerToChooseQuantityRentable && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='min_quantity'
                  rules={[{ required: true, message: translate('assets.productsDetails.quantity') }]}
                  label={translate('assets.productsQuantityStock.minimumQuantity')}>
                  <Input placeholder={translate('assets.productsQuantityStock.minimumQuantityPlaceholder')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='max_quantity'
                  rules={[{ required: true, message: translate('assets.productsDetails.quantity') }]}
                  label={translate('assets.productsQuantityStock.maximumQuantity')}>
                  <Input placeholder={translate('assets.productsQuantityStock.quantityAvailablePlaceholder')} />
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
      {productSettings.productType === EProductType.CONSUMABLE && (
        <>
          <Form.Item name='remaining_stock' label={translate('assets.productsQuantityStock.remainingStock')}>
            <Input placeholder={translate('assets.productsQuantityStock.remainingStockPlaceholder')} />
          </Form.Item>
          <Form.Item name='allow_custom_quantity' valuePropName='checked'>
            <Checkbox className='mb-15' onChange={(e) => handleSettingChange('allowCustomerToChooseQuantityConsumable', e.target.checked)}>
              {translate('assets.productsQuantityStock.allowCustomerToChooseQuantity')}
            </Checkbox>
          </Form.Item>

          {productSettings.allowCustomerToChooseQuantityConsumable && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='min_quantity'
                  rules={[{ required: true, message: translate('assets.productsDetails.quantity') }]}
                  label={translate('assets.productsQuantityStock.minimumQuantity')}>
                  <Input placeholder={translate('assets.productsQuantityStock.minimumQuantityPlaceholder')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='max_quantity'
                  rules={[{ required: true, message: translate('assets.productsDetails.quantity') }]}
                  label={translate('assets.productsQuantityStock.maximumQuantity')}>
                  <Input placeholder={translate('assets.productsQuantityStock.quantityAvailablePlaceholder')} />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Form.Item name='low_stock_notification' valuePropName='checked'>
              <Checkbox className='mb-15' onChange={(e) => handleSettingChange('notificationStockThreshold', e.target.checked)}>
                {translate('assets.productsQuantityStock.sendNotificationWhenStockReachesThreshold')}
              </Checkbox>
            </Form.Item>
          </Row>

          {productSettings.notificationStockThreshold && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='stock_threshold'
                  rules={[{ required: true, message: translate('assets.productsDetails.stockThreshold') }]}
                  label={translate('assets.productsQuantityStock.stockThreshold')}>
                  <Input placeholder={translate('assets.productsQuantityStock.stockThresholdPlaceholder')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={translate('assets.productsQuantityStock.notificationRecipients')} name='notification_recipients'>
                  <SelectUser mode='multiple' />
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}

      {productSettings.productType === EProductType.DIGITAL && (
        <>
          <Form.Item name='digital_type' initialValue={digitalType} label={translate('assets.productsQuantityStock.type')}>
            <Select
              onChange={(value: EDigitalType) => setDigitalType(value)}
              defaultValue={digitalType}
              className='w-full'
              options={[
                { value: EDigitalType.URL, label: 'URL' },
                { value: EDigitalType.FILE, label: 'File' },
              ]}
            />
          </Form.Item>

          {digitalType === EDigitalType.URL && (
            <Form.Item name='digital_url' label={translate('assets.productsQuantityStock.url')}>
              <Input placeholder={translate('assets.productsQuantityStock.urlPlaceholder')} />
            </Form.Item>
          )}
          {digitalType === EDigitalType.FILE && (
            <Form.Item label={translate('assets.productsQuantityStock.file')} name='digital_url'>
              <UploadImage
                name='digital_url'
                onUploadChange={(value) => form.setFieldValue('digital_url', value?.url)}
                url={selectedProduct?.digital_url}
              />
            </Form.Item>
          )}

          <Form.Item name='max_redemptions' label={translate('assets.productsQuantityStock.maxRedemptions')}>
            <Input placeholder={translate('assets.productsQuantityStock.maxRedemptionsPlaceholder')} />
          </Form.Item>
        </>
      )}
    </Col>
  );
};
