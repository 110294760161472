import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance, Input, Row, Select, Typography } from 'antd';
import { Color } from 'antd/es/color-picker';

import { ColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import Tiptap from '@aduvi/Tiptap/Tiptap';

import { useAppSelector } from 'store/hooks';

export enum ETicketDesign {
  A4_QUARTER = 'A4_QUARTER',
}

export const Settings = ({ form }: { form: FormInstance; expanded: boolean }) => {
  const { t: translate } = useTranslation();
  const { selectedEvent } = useAppSelector((state) => state.events);

  const [ticketDesign, setTicketDesign] = useState<ETicketDesign>(ETicketDesign.A4_QUARTER);

  const handleColorComplete = (color: Color | string) => {
    return typeof color === 'string' ? color : color?.toHexString();
  };

  return (
    <Row wrap={true}>
      <Col span={24} className='mt-15'>
        <Form.Item
          name={['settings', 'ticket_design']}
          label={translate('assets.eventsDrawer.settings.ticketDesign')}
          labelCol={{ span: 24 }}
          initialValue={ETicketDesign[ticketDesign]}>
          <Select
            className='w-full'
            onChange={(value) => {
              setTicketDesign(value);
              form.setFieldValue(['settings', 'ticket_design'], value);
            }}
            options={[
              {
                label: translate('assets.eventsDrawer.settings.a4_quarter'),
                value: ETicketDesign.A4_QUARTER,
              },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={8} className='mt-15'>
        <Form.Item
          name={['settings', 'ticket_color', 'attendees']}
          label={translate('assets.eventsDrawer.settings.attendees')}
          labelCol={{ span: 24 }}
          getValueFromEvent={handleColorComplete}>
          <ColorPicker />
        </Form.Item>
      </Col>
      <Col span={8} className='mt-15'>
        <Form.Item
          name={['settings', 'ticket_color', 'exhibitors']}
          label={translate('assets.eventsDrawer.settings.exhibitors')}
          labelCol={{ span: 24 }}
          getValueFromEvent={handleColorComplete}>
          <ColorPicker />
        </Form.Item>
      </Col>
      <Col span={8} className='mt-15'>
        <Form.Item
          name={['settings', 'ticket_color', 'staff']}
          label={translate('assets.eventsDrawer.settings.staff')}
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: translate('assets.eventsDrawer.exhibitors.validation.name') }]}
          initialValue={ticketDesign}
          getValueFromEvent={handleColorComplete}>
          <ColorPicker />
        </Form.Item>
      </Col>

      <Form.Item label={translate('assets.eventsDrawer.settings.reverse_image')} name={['settings', 'reversed_image']}>
        <Typography.Paragraph type='secondary' className='note'>
          {translate('assets.eventsDrawer.settings.reverse_image_note')}
        </Typography.Paragraph>

        <UploadImage
          name={'reversed_image'}
          onUploadChange={(value) => form.setFieldValue(['settings', 'reversed_image'], value?.url)}
          url={selectedEvent?.settings?.reversed_image}
        />
      </Form.Item>
      <Form.Item
        className='mb-20 w-full'
        label={translate('settings.details.termsAndConditionsLink')}
        name={['settings', 'terms_and_conditions_link']}>
        <Input placeholder={translate('settings.details.termsAndConditionsLinkPlaceholder')} />
      </Form.Item>
      <Form.Item label={translate('assets.eventsDrawer.settings.email_description')} className='w-full' name={['settings', 'email_description']}>
        <Tiptap
          content={form.getFieldValue('email_content')}
          onUpdate={(value) => form.setFieldValue(['settings', 'email_description'], value.getHTML())}
          placeholder={translate('assets.eventsDrawer.settings.add_email_description')}
        />
      </Form.Item>
    </Row>
  );
};
