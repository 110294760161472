import { PERMISSIONS } from '@aduvi/constants';

export const createEntityPermission: Record<string, string> = {
  JOB: PERMISSIONS.JOBS.CREATE,
  CONTACT: PERMISSIONS.CONTACTS.CREATE,
  ORDER: PERMISSIONS.ORDERS.CREATE,
  VENUE: PERMISSIONS.VENUES.CREATE,
};

export const editEntityPermission: Record<string, string> = {
  JOB: PERMISSIONS.JOBS.EDIT,
  CONTACT: PERMISSIONS.CONTACTS.EDIT,
  ORDER: PERMISSIONS.ORDERS.EDIT,
  VENUE: PERMISSIONS.VENUES.EDIT,
};

export const deleteEntityPermission: Record<string, string> = {
  JOB: PERMISSIONS.JOBS.DELETE,
  CONTACT: PERMISSIONS.CONTACTS.DELETE,
  ORDER: PERMISSIONS.ORDERS.DELETE,
  VENUE: PERMISSIONS.VENUES.DELETE,
};

export const createEntityFieldsPermission: Record<string, string> = {
  JOB: PERMISSIONS.JOB_FIELDS.CREATE,
  CONTACT: PERMISSIONS.CONTACT_FIELDS.CREATE,
  ORDER: PERMISSIONS.ORDER_FIELDS.CREATE,
  EVENT: PERMISSIONS.EVENT_FIELDS.CREATE,
  EXHIBITOR: PERMISSIONS.EXHIBITOR_FIELDS.CREATE,
  VENUE: PERMISSIONS.VENUE_FIELDS.CREATE,
};

export const editEntityFieldsPermission: Record<string, string> = {
  JOB: PERMISSIONS.JOB_FIELDS.EDIT,
  CONTACT: PERMISSIONS.CONTACT_FIELDS.EDIT,
  ORDER: PERMISSIONS.ORDER_FIELDS.EDIT,
  EVENT: PERMISSIONS.EVENT_FIELDS.EDIT,
  EXHIBITOR: PERMISSIONS.EXHIBITOR_FIELDS.EDIT,
  VENUE: PERMISSIONS.VENUE_FIELDS.EDIT,
};
