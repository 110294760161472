import React from 'react';

import { Select, SelectProps } from 'antd';

import { EOrderType } from '@aduvi/types';

export const SelectOrder = (props: SelectProps) => {
  const options = Object.values(EOrderType)?.map((value) => {
    return {
      label: value,
      value,
    };
  });

  return <Select {...props} options={options} />;
};
