import { useCallback, useEffect } from 'react';

import { Col, Row } from 'antd';

import { ActivityList } from '@aduvi/components/ActivityList/ActivityList';
import { useUser } from '@aduvi/hooks/user';
import { EActivityType } from '@aduvi/types/audit';
import { EOrderType } from '@aduvi/types/common';

import { getBusinessUserActivities } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Activity = () => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const { userActivities, loading } = useAppSelector((state) => state.user);

  const onGetUserActivities = useCallback(
    (page = 1, size = 10, order = EOrderType.DESC) => {
      if (!user?.id) return;
      dispatch(
        getBusinessUserActivities({
          user_id: user?.id,
          params: {
            page,
            size,
            order,
          },
        }),
      );
    },
    [user?.id],
  );

  useEffect(() => {
    onGetUserActivities(1, 10);
  }, []);

  return (
    <Row className='profile-activity-wrapper'>
      <Col span={24}>
        <ActivityList activityType={EActivityType.USER} activities={userActivities} loading={loading} onGetActivities={onGetUserActivities} />
      </Col>
    </Row>
  );
};
