import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export const useRelativeTime = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage] = useState(i18n.language);

  useEffect(() => {
    dayjs.updateLocale(currentLanguage, {
      relativeTime: {
        future: t('dayjs.relativeTime.future'),
        past: t('dayjs.relativeTime.past'),
        s: t('dayjs.relativeTime.s'),
        m: t('dayjs.relativeTime.m'),
        mm: t('dayjs.relativeTime.mm'),
        h: t('dayjs.relativeTime.h'),
        hh: t('dayjs.relativeTime.hh'),
        d: t('dayjs.relativeTime.d'),
        dd: t('dayjs.relativeTime.dd'),
        M: t('dayjs.relativeTime.M'),
        MM: t('dayjs.relativeTime.MM'),
        y: t('dayjs.relativeTime.y'),
        yy: t('dayjs.relativeTime.yy'),
      },
    });
  }, [currentLanguage, t]);

  return (date: string): string => {
    return dayjs(date).fromNow();
  };
};
