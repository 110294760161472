export const dummy_templates = [
  {
    id: '1',
    image: 'https://img.freepik.com/premium-photo/white-mock-up-blank-template-design-wall-gray-background_25920-1708.jpg', // 1920x1080
    title: 'Beautiful Mountain',
    description: 'A breathtaking view of mountains under the clear sky.',
    template_type: 'Custom Template',
  },
  {
    id: '2',
    image:
      'https://images.unsplash.com/photo-1553969420-fb915228af51?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // 1920x1080
    title: 'Serene Lake',
    description: 'A peaceful lake surrounded by trees during autumn.',
    template_type: 'System Template',
  },
  {
    id: '3',
    image:
      'https://images.unsplash.com/photo-1553901753-215db344677a?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', // 1920x1080
    title: 'Sunset Beach',
    description: 'A stunning view of a sunset over the ocean.',
    template_type: 'Custom Template',
  },
  {
    id: '4',
    image: 'https://wallpapercave.com/wp/wp1920921.jpg', // 1920x1080
    title: 'Snowy Peaks',
    description: 'Majestic snow-covered mountains under a clear blue sky.',
    template_type: 'System Template',
  },
];
