import { useTranslation } from 'react-i18next';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Row } from 'antd';
import './Edge.scss';

import { useAppSelector } from 'store/hooks';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  onEdgeClick,
  onDuplicateNodeDrop,
}: EdgeProps & {
  onEdgeClick: (data: EdgeProps) => void;
  onDuplicateNodeDrop: (duplicateNodeId: string, data: { id: string; sourceX: number; sourceY: number; targetX: number; targetY: number }) => void;
}) {
  const { t: translate } = useTranslation();
  const { duplicateNodeId } = useAppSelector((state) => state.automation);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
          className='nodrag nopan custom-edge'>
          {duplicateNodeId ? (
            <Row
              className='card example-5 cursor-pointer'
              justify={'center'}
              align={'middle'}
              onClick={() => onDuplicateNodeDrop(duplicateNodeId, { id, sourceX, sourceY, targetX, targetY })}>
              <svg height='100%' width='100%' xmlns='http://www.w3.org/2000/svg'>
                <rect rx='8' ry='8' className='line' height='100%' width='100%' strokeLinejoin='round' />
              </svg>
              <div className='inner p-15'>{translate('automations.clickToDrop')}</div>
            </Row>
          ) : (
            <Button
              type='primary'
              icon={<PlusOutlined />}
              size='small'
              style={{ borderRadius: '10px' }}
              onClick={() => {
                onEdgeClick({
                  id,
                  sourceX,
                  sourceY,
                  targetX,
                  targetY,
                  sourcePosition,
                  targetPosition,
                  markerEnd,
                  source: '',
                  target: '',
                });
              }}
            />
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
