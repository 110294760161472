import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined } from '@ant-design/icons';

import { Card, Col, Dropdown, Row, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';

import { useAppSelector } from 'store/hooks';

export const Forms = () => {
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { selectedEvent } = useAppSelector((state) => state.events);

  const redirectUrl = useMemo(() => {
    const brand = selectedBusiness?.brandings?.find((business) => business?.name === 'Event');
    return brand?.vanity_domain || brand?.system_domain;
  }, [selectedBusiness]);

  const onGoToForm = () => {
    window.open(`${redirectUrl}/event/${selectedEvent?.slug}`, '_blank');
  };

  const onCopyUrl = () => {
    navigator.clipboard.writeText(`${redirectUrl}/event/${selectedEvent?.slug}`);
  };

  return (
    <div className='event-form-wrapper'>
      <Row>
        <Col span={12}>
          <Card cover={<img alt='image' width={'80%'} height={'80%'} src={require('assets/images/form_cover.png')} />}>
            <Card.Meta
              title={translate('assets.eventsDrawer.forms.bookingForm')}
              description={
                <Row justify='space-between' align='middle'>
                  <Typography.Paragraph>
                    {translate('assets.eventsDrawer.forms.purchaseTitcket', { event: selectedEvent?.name })}
                  </Typography.Paragraph>
                  <Typography.Paragraph type='secondary'>{translate('assets.eventsDrawer.forms.createOrder')}</Typography.Paragraph>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: translate('assets.eventsDrawer.forms.goToForm'),
                          onClick: onGoToForm,
                        },
                        {
                          key: '2',
                          label: translate('assets.eventsDrawer.forms.copyFormURL'),
                          onClick: onCopyUrl,
                        },
                      ],
                    }}
                    trigger={['click']}
                    overlayStyle={{ width: '160px', borderRadius: '8px' }}>
                    <a onClick={(e) => e.preventDefault()}>
                      <EllipsisOutlined className='fs-20' />
                    </a>
                  </Dropdown>
                </Row>
              }
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
