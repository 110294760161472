import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EllipsisOutlined, FileOutlined, RightOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Divider, Dropdown, Form, FormInstance, Input, Row, Switch, Typography } from 'antd';
import './ArtworkList.scss';

import { ARTWORKS } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType, EFileOptions } from '@aduvi/types/client-portal';

import { useAppSelector } from 'store/hooks';

import { Artwork } from './Artwork';

export const ArtworkList = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [fileOptions, setFileOptions] = useState({
    download: true,
    comments: true,
  });
  const title = useWidgetTitle('clientPortal.artwork.title', 'artwork', selectedClientPortal?.client_portal_settings?.artwork?.title, form);

  const handleFileOptions = (value: boolean, field: EFileOptions) => {
    setFileOptions((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              destroyPopupOnHide
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item className='mb-0' initialValue={title} labelCol={{ span: 24 }} name={['client_portal_settings', 'artwork', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' name={['client_portal_settings', 'artwork', 'half_width']}>
                      <Switch size='small' defaultValue={selectedClientPortal?.client_portal_settings?.artwork?.half_width} style={{ margin: 6 }} />
                    </Form.Item>
                    <Typography.Text>{translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal'>
                        <Row gutter={16} justify={'start'} align={'middle'}>
                          <Form.Item initialValue={true} className='mb-0' name={['client_portal_settings', 'artwork', 'file_options', 'download']}>
                            <Switch size='small' onChange={(e) => handleFileOptions(e, EFileOptions.DOWNLOAD)} defaultChecked style={{ margin: 6 }} />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.artwork.download')}</Typography.Text>
                        </Row>
                        <Row gutter={16} justify={'start'} align={'middle'}>
                          <Form.Item initialValue={true} className='mb-0' name={['client_portal_settings', 'artwork', 'file_options', 'comment']}>
                            <Switch size='small' onChange={(e) => handleFileOptions(e, EFileOptions.COMMENTS)} defaultChecked style={{ margin: 6 }} />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.artwork.comment')}</Typography.Text>
                        </Row>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <FileOutlined /> {translate('clientPortal.options.fileOptions')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Row>
                    <Button
                      type='text'
                      className='w-full flex align-center'
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.ARTWORK, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined onClick={() => setDropdownVisible((prev) => !prev)} />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='client-portal-artwork-wrapper'>
      {ARTWORKS.map((artwork) => (
        <div key={artwork.id}>
          <Artwork artwork={artwork} fileOptions={fileOptions} />
        </div>
      ))}
    </Card>
  );
};
