import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { AxiosError } from 'axios';

import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IEntityField, IResponse } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const fieldsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEntityFields: builder.query<IResponse<IEntityField[]>, { businessId?: string; entityTypeId?: string }>({
      query: ({ businessId, entityTypeId }) => ({
        url: `/businesses/${businessId}/entity-types/${entityTypeId}/fields`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
    }),

    getEntityFieldsByMultipleIds: builder.query<
      IResponse<{ entity_id: string; entity_name: string; custom_fields: IEntityField[] }[]>,
      {
        businessId: string;
        entityTypeIds: {
          entity_type_id: string;
          entity_name: string;
        }[];
      }
    >({
      async queryFn(
        { businessId, entityTypeIds },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ): Promise<QueryReturnValue<IResponse<{ entity_id: string; entity_name: string; custom_fields: IEntityField[] }[]>, AxiosError<unknown>>> {
        try {
          const results = await Promise.all(
            entityTypeIds.map((entityType) => fetchWithBQ({ url: `/businesses/${businessId}/entity-types/${entityType.entity_type_id}/fields` })),
          );

          const errorResult = results.find((result) => result.error) as QueryReturnValue<unknown, AxiosError> | undefined;
          if (errorResult) {
            return { error: errorResult?.error as AxiosError };
          }

          const combinedData = results.map((result, index) => {
            const entityType = entityTypeIds[index];
            const fields = (result?.data as IResponse<IEntityField[]>)?.data;
            return {
              entity_id: entityType?.entity_type_id,
              entity_name: entityType?.entity_name,
              custom_fields: fields?.filter((field) => !field?.reference_entity_type),
            };
          });

          return { data: { data: combinedData } };
        } catch (error) {
          return { error: error as AxiosError };
        }
      },
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
    }),
  }),
});

// Export both hooks
export const { useGetEntityFieldsQuery, useGetEntityFieldsByMultipleIdsQuery } = fieldsApiSlice;
