import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const Tasks = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes.data.TASK} personalizedViewOrigin={EPersonalizedViewOrigin.TASKS} />;
};
