import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import i18n from 'translation/i18n';

import { Col, Row, Tabs, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import './Profile.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { useActiveTheme } from '@aduvi/hooks';
import { useUser } from '@aduvi/hooks/user';

import { setUserData } from 'store/features/auth-slice';
import { uploadImage } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

import { Activity, Details, Password, Security } from './@components';

const tabItems = [
  {
    key: '/profile/details',
    label: i18n.t('profile.tabs.details'),
    children: <Details />,
  },
  {
    key: '/profile/password',
    label: i18n.t('profile.tabs.password'),
    children: <Password />,
  },
  {
    key: '/profile/security',
    label: i18n.t('profile.tabs.security'),
    children: <Security />,
  },
  {
    key: '/profile/activity',
    label: i18n.t('profile.tabs.activity'),
    children: <Activity />,
  },
];

export const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const activeTheme = useActiveTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const tabKey = useMemo(() => {
    return location.pathname ?? '/profile/details';
  }, [location.pathname]);

  const beforeUpload = (file: RcFile) => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadImage(formData))
      .unwrap()
      .then((res) => {
        dispatch(setUserData({ ...user, profile_picture: res?.data.file_name }));
      })
      ?.catch(() => {});
    return false;
  };

  useEffect(() => {
    if (user?.profile_picture) {
      setFileList([{ uid: '-1', name: 'profile_picture.png', url: user?.profile_picture, fileName: user?.profile_picture }]);
    }
  }, []);

  useEffect(() => {
    dispatch(setUserData({ ...user, profile_picture: fileList?.[0]?.fileName ?? null }));
  }, [fileList]);

  return (
    <Col
      className='profile-wrapper w-full h-full'
      style={{ background: `linear-gradient(0deg, rgba(255, 255, 255, 1) 65%,${activeTheme?.menuBg} 100%)` }}>
      <Row className='user-picture-container'>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }}>
          <Row className='user-picture-items' justify='center'>
            <Upload
              className='text-center'
              listType='picture-circle'
              showUploadList={{ showPreviewIcon: false }}
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
              beforeUpload={beforeUpload}>
              {fileList.length === 0 && <PlusOutlined />}
            </Upload>
          </Row>
          <Row justify='center'>
            <Paragraph className='fs-26 mt-10'>{user?.first_name ? `${user?.first_name} ${user?.last_name}` : `${user?.email}`}</Paragraph>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 7 }}>
          <Tabs defaultActiveKey={tabKey} items={tabItems} className='w-full' onChange={(path) => navigate(path)} />
        </Col>
      </Row>
    </Col>
  );
};
