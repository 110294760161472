import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Modal, Row, Spin } from 'antd';
import { FormInstance } from 'antd/lib';

import { useBusiness } from '@aduvi/hooks';
import { IEntityField } from '@aduvi/types/fields';
import { toCapitalize } from '@aduvi/utils/helper';

import * as FieldsService from 'store/services/fields.service';

import { CustomField } from '../CustomField/CustomField';

interface IProps {
  creating: boolean;
  entityTypeId: string;
  form: FormInstance;
  entityTitle: string | undefined;
  createNewEntity: (fields: IEntityField[]) => void;
  setIsModalVisible: (value: React.SetStateAction<boolean>) => void;
}

export const CreateInlineEntityModal = ({ entityTitle, creating, form, entityTypeId, setIsModalVisible, createNewEntity }: IProps) => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const [fields, setFields] = useState<IEntityField[] | undefined>();

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypeId) return;

    FieldsService.getEntityFields(selectedBusiness.id, entityTypeId)
      .then((res) => setFields(res.data))
      .catch(() => {});
  }, [entityTypeId, selectedBusiness?.id]);

  return (
    <Modal
      title={`${translate('inlineEntity.create')} ${toCapitalize(entityTitle)}`}
      open={true}
      width={700}
      centered
      zIndex={1000000}
      bodyStyle={{ maxHeight: 500, minHeight: 450, overflowY: 'auto', paddingRight: 10 }}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <>
          <Divider className='mt-0' />
          <Button key='submit' loading={creating} disabled={creating} type='primary' htmlType='submit' onClick={() => createNewEntity(fields || [])}>
            {translate('inlineEntity.save')}
          </Button>
        </>,
      ]}>
      {/*TODO Change spin  once the rtk query will be implemented*/}
      <Spin spinning={!fields?.length}>
        <Form form={form} layout='vertical'>
          <Row>
            <Col span={24} className='mt-15'>
              <Form.Item name='custom_fields'>
                {fields?.map((item, index) => <CustomField field={item} form={form} key={index} index={index} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
