import React, { useMemo, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Card, Carousel, Col, Grid, Row, Select, Spin } from 'antd';
import type { CarouselRef } from 'antd/es/carousel';

import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { chunkArray, generateQuantityOptions } from '@aduvi/utils/helper';

interface IProps {
  products?: IPaginatedResponse<IProduct>;
  selectedProducts: string[];
  onProductSelect: (id: string) => void;
}

export const CarouselDisplayType = ({ products, selectedProducts, onProductSelect }: IProps) => {
  const screens = Grid.useBreakpoint();
  const carouselRef = React.useRef<CarouselRef>(null);

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (value: number, productId: string) => {
    if (value === 0) {
      onProductSelect(productId);
      setQuantity(1);
      return;
    }
    setQuantity(value);
  };

  const PrevArrow = () => (
    <Button
      type='text'
      icon={<LeftOutlined />}
      style={{
        position: 'absolute',
        top: '50%',
        left: 0,
        zIndex: 10,
        transform: 'translateY(-50%)',
      }}
      onClick={() => carouselRef.current?.prev()}
    />
  );

  const NextArrow = () => (
    <Button
      type='text'
      icon={<RightOutlined />}
      style={{
        position: 'absolute',
        top: '50%',
        right: 0,
        zIndex: 10,
        transform: 'translateY(-50%)',
      }}
      onClick={() => carouselRef.current?.next()}
    />
  );

  const chunkedArray = useMemo(() => {
    if (screens.xxl) {
      return chunkArray(products?.data ? products?.data : [], 3);
    }
    if (screens.xl) {
      return chunkArray(products?.data ? products?.data : [], 2);
    }
    return chunkArray(products?.data ? products?.data : [], 1);
  }, [screens, products?.data]);

  return (
    <Spin spinning={!products}>
      <Row justify={'space-between'}>
        <Col span={1}>
          <PrevArrow />
        </Col>
        <Col span={20}>
          <Carousel ref={carouselRef} dots={false} infinite={false} slidesToShow={1} slidesToScroll={1}>
            {chunkedArray.map((item, index) => (
              <Row key={index} className='w-full flex ' gutter={[18, 18]}>
                {item.map((item) => (
                  <Col xs={24} sm={24} xl={12} xxl={8} key={index}>
                    <Card
                      hoverable
                      style={{
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textAlign: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      }}
                      cover={<img alt='product' height={200} src={item.image} />}>
                      <h2>{item.name}</h2>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: item.description! }} />
                      </p>

                      <div className='title price-tag mb-10'>
                        ${selectedProducts.includes(item.id) ? Number(item.price || item.upsell_price) * quantity : item.price}
                      </div>
                      <Row justify={'space-around'}>
                        <Button
                          type='primary'
                          shape='round'
                          onClick={() => onProductSelect(item.id)}
                          style={{ backgroundColor: selectedProducts.includes(item.id) ? '#52c41a' : '#000' }}>
                          {selectedProducts.includes(item.id) ? 'Added' : 'Add To Cart'}
                        </Button>
                        {selectedProducts.includes(item.id) ? (
                          <Select defaultValue={1} onChange={(value) => handleQuantityChange(value, item.id)} style={{ width: 60 }}>
                            {generateQuantityOptions(Number(item.quantity_available), item.min_quantity, item.max_quantity).map((qty) => (
                              <Select.Option key={qty} value={qty}>
                                {qty}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : null}
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            ))}
          </Carousel>
        </Col>
        <Col span={1}>
          <NextArrow />
        </Col>
      </Row>
    </Spin>
  );
};
