import { useProject } from 'views/projects/hooks/useProject';

import { Timeline as TimelineComponent } from '@aduvi/components/PersonalizedViewComponents/PredefinedViews/Timeline/Timeline';
import { IEntityWithFields } from '@aduvi/types/entity';
import { EPersonalizedViewOrigin } from '@aduvi/types/personalized-views';

export const Timeline = ({ origin }: { origin: EPersonalizedViewOrigin }) => {
  const { jobFields } = useProject();

  return <TimelineComponent data={jobFields as unknown as IEntityWithFields[]} origin={origin} />;
};
