import React, { useState } from 'react';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Col, Input, Row } from 'antd';

import { EPersonalizedView, ICustomFieldViewStyle } from '@aduvi/types';

const EDITABLE_HEADERS = [EPersonalizedView.TABLE];

interface IProps {
  id: string;
  children: React.ReactNode;
  column?: ICustomFieldViewStyle;
  updateColumns?: (column: ICustomFieldViewStyle) => void;
  viewType?: EPersonalizedView;
}

export const DraggableTableViewColumn = ({ id, children, updateColumns, column, viewType }: IProps) => {
  const [editable, setEditable] = useState(true);
  const [columnName, setColumnName] = useState(column?.name || '');

  const { attributes, listeners, setNodeRef, transform, isDragging, transition, isOver } = useSortable({
    id,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleEditColumns = (value: string, column?: ICustomFieldViewStyle) => {
    if (!updateColumns || !column) return;
    column.name = value;
    updateColumns({ ...column, name: value });
    setEditable(true);
  };

  return (
    <>
      {viewType && EDITABLE_HEADERS.includes(viewType) ? (
        editable ? (
          <Row align={'middle'} justify='space-between' gutter={10}>
            <Col
              onClick={(e) => {
                e.stopPropagation();
              }}
              ref={setNodeRef}
              {...attributes}
              {...listeners}
              style={{
                ...style,
                cursor: 'grab',
                paddingLeft: 10,
                borderLeft: isDragging || isOver ? '2px solid #70b6fc' : 'none',
                position: 'relative',
                zIndex: 5,
                rotate: isDragging ? '5deg' : '0deg',
              }}>
              {children}
            </Col>
            <Col>
              <EditOutlined style={{ display: isDragging ? 'none' : 'block' }} onClick={() => setEditable(false)} />
            </Col>
          </Row>
        ) : (
          <Input
            autoFocus
            variant='borderless'
            defaultValue={column?.name}
            suffix={<CheckOutlined />}
            onPressEnter={() => handleEditColumns(columnName || '', column)}
            onBlur={(event) => handleEditColumns(event?.target?.value || '', column)}
            onChange={({ target }) => setColumnName(target?.value)}
          />
        )
      ) : (
        <Row align={'middle'} justify='space-between' gutter={10}>
          <Col
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
              ...style,
              cursor: 'grab',
              paddingLeft: 10,
              borderLeft: isDragging || isOver ? '2px solid #70b6fc' : 'none',
              position: 'relative',
              zIndex: 5,
              rotate: isDragging ? '5deg' : '0deg',
            }}>
            {children}
          </Col>
        </Row>
      )}
    </>
  );
};
