import { IColumnType } from '@aduvi/constants/client-portal-constants';

import { EAuthorized } from './auth';
import { IBranding } from './business';
import { ELoadingState } from './common';
import { IEntityWithFields } from './entity';
import { IQuote } from './quote';

export enum EFileOptions {
  DOWNLOAD = 'download',
  COMMENTS = 'comments',
}

export enum ESuggestedAmounts {
  DEPOSIT = 'deposit',
  HALF_REMAINING = 'half_remaining',
  ALL_REMAINING = 'all_remaining',
  CUSTOM = 'custom',
}

export interface IClientPortalSettings {
  login_settings: {
    description: string;
    login_style: {
      primary_font_color: string;
      secondary_font_color: string;
      form_background: string;
      font_family: string;
      background_color?: string;
    }[];
    login_form_type: 'email_magicLink' | 'email_password';
  };

  client_portal_settings: {
    layout: IColumnType[];
    description: string;
    portal_layout: 1 | 2 | 3;
    portal_style: {
      primary_font_color: string;
      secondary_font_color: string;
      form_background: string;
      font_family: string;
      background_color?: string;
    }[];
    artwork: {
      half_width: boolean;
      title: string;
      file_options: {
        download: boolean;
        comment: boolean;
      };
    };
    assigned_staff: {
      half_width: boolean;
      title: string;
      contact_details: {
        phone: true;
        email: true;
      };
    };
    custom_blocks: {
      half_width: boolean;
      title: string;
      block_content: string;
    };

    make_payment: {
      title: string;
      half_width: boolean;
      suggested_amounts: {
        deposit: boolean;
        half_remaining: boolean;
        all_remaining: boolean;
        custom: boolean;
        minimal_amount: number | null;
      };
      visibility: {
        require_contract: boolean;
        date_logic: string;
      };
    };
    order_list: {
      title: string;
      half_width: true;
      hide_empty_fields: true;
      visible_fields: {
        [key: string]: boolean;
      };
      editable_fields: {
        [key: string]: boolean;
      };
    };
    job_list: {
      title: string;
      half_width: true;
      hide_empty_fields: true;
      visible_fields: {
        [key: string]: boolean;
      };
      editable_fields: {
        [key: string]: boolean;
      };
    };
    review: {
      title: string;
      half_width: true;
      links: { url: string }[];
    };

    upsells: {
      title: string;
      half_width: true;
    };

    order_summary: {
      half_width: boolean;
      title: string;
      show_payments: boolean;
    };
  };
}

export interface ISendMagicLinkResponse {
  status: string;
}

export interface IClientPortal {
  id: string;
  name: string;
  login_settings: IClientPortalSettings['login_settings'];
  client_portal_settings: IClientPortalSettings['client_portal_settings'];
  install_cp_app: boolean;
  business_id: string;
  brand_id: string;
  brand: IBranding;
}

export interface ICreateClientPortalPayload {
  business_id: string;
  name: string;
  login_settings: IClientPortalSettings['login_settings'];
  client_portal_settings: IClientPortalSettings['client_portal_settings'];
  install_cp_app: boolean;
}

export interface IClientPortalState {
  clientPortals?: IClientPortal[];
  selectedClientPortal?: IClientPortal;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
}

export interface IClientPortalAuthState {
  authActionState: ELoadingState;
  token: string | null;
  refreshToken: string | null;
  isAuthorized: EAuthorized;
  loginState: ELoadingState;
  resetPasswordState: ELoadingState;
  forgetPasswordState: ELoadingState;
  validateTokenState: ELoadingState;
  sendMagicLinkState: ELoadingState;
  user: {
    user?: IEntityWithFields;
    jobs: {
      loading: boolean;
      data?: IEntityWithFields[];
      selectedJob?: IEntityWithFields;
    };
    orders: {
      loading: boolean;
      data?: IEntityWithFields[];
      selectedOrder?: IEntityWithFields;
    };
    quotes: {
      loading: boolean;
      data?: IQuote[];
      selectedQuote?: IQuote;
    };
    loading: boolean;
    updating: boolean;
    resettingPassword: boolean;
  };
}

export enum EClientPortalComponentType {
  ARTWORK = 'artwork',
  ASSIGNED_STAFF = 'assigned_staff',
  CUSTOM_BLOCKS = 'custom_blocks',
  MAKE_PAYMENT = 'make_payment',
  ORDER_LIST = 'order_list',
  JOB_LIST = 'job_list',
  REVIEW = 'review',
  UPSELLS = 'upsells',
  ORDER_SUMMARY = 'order_summary',
}

export enum EClientPortalAuthComponent {
  LOGIN = 'login',
  LOGGED_USER = 'logged-user',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  MAGIC_LINK_LOGIN = 'magic-link/login',
}
export enum EPortalComponent {
  ALL = 'all-components',
  FILES = 'files',
  INVOICE = 'invoice',
  INVOICES = 'invoices',
}
export interface IClientJobs {
  data: {
    jobs: IEntityWithFields[];
  };
}
export interface IClientOrders {
  data: {
    orders: IEntityWithFields[];
  };
}
export interface IClientOrder extends IEntityWithFields {
  quotes: IQuote[];
}
export interface IClientJob extends IEntityWithFields {
  quotes: IQuote[];
}
