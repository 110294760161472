import { IPaginatedResponse, IPaginationParams } from './common';
import { TransformedLimitVisibility } from './discount';
import { IEntityType } from './entity';
import { ITaxRate } from './tax';

export enum EProductType {
  RENTABLE = 'RENTABLE',
  CONSUMABLE = 'CONSUMABLE',
  DIGITAL = 'DIGITAL',
}

export enum EDrawerTabs {
  DETAILS = 'details',
  QUANTITY_STOCK = 'quantityStock',
  PRICING = 'pricing',
  VARIATIONS = 'variations',
  LIMIT_VISIBILITY = 'limitVisibility',
}

export enum EDigitalType {
  URL = 'URL',
  FILE = 'FILE',
}

export interface IVariationsType {
  variation_name: string;
  override_price: boolean;
  override_stock: boolean;
  options: { name: string; price: string; stock: string; sku: string; image: string }[];
}

export interface IProductTaxRate {
  id: string;
  entity_id: string;
  entity_type: IEntityType;
  tax_rate_id: string;
  business_id: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  tax_rate: ITaxRate;
}

export interface IProduct {
  name: string;
  business_product_category_id: string;
  image?: string;
  description?: string;
  sku?: string;
  product_type: EProductType;
  quantity_available?: number;
  allow_custom_quantity?: boolean;
  min_quantity?: number;
  max_quantity?: number;
  remaining_stock?: number;
  low_stock_notification?: boolean;
  stock_threshold?: number;
  digital_type?: EDigitalType;
  digital_url?: string;
  max_redemptions?: number;
  is_on_sale?: boolean;
  price?: number;
  upsell?: boolean;
  upsell_price?: number;
  upsell_price_type?: string;
  upsell_price_unit?: string;
  sale_end_date?: string;
  price_type?: string;
  price_unit?: string;
  business_id: string;
  id: string;
  updated_at?: string;
  created_at?: string;
  deleted_at?: string;
  product_variations?: IVariationsType[];
  tax_rates?: IProductTaxRate[];
}

export interface ICreateProductPayload {
  name: string;
  business_id: string;
  business_product_category_id: string;
  tax_rate_ids: string[];
  image?: string;
  description?: string;
  sku?: string;
  product_type: EProductType;
  quantity_available?: number;
  allow_custom_quantity?: boolean;
  max_quantity?: number;
  min_quantity?: number;
  remaining_stock?: number;
  low_stock_notification?: boolean;
  stock_threshold?: number;
  digital_type?: EDigitalType;
  digital_url?: string;
  max_redemptions?: number;
  is_on_sale?: boolean;
  price?: number;
  price_type?: string;
  price_unit?: string;
  upsell?: boolean;
  upsell_price?: number;
  upsell_price_type?: string;
  upsell_price_unit?: string;
  sale_end_date?: string;
  variations?: IVariationsType[];
  limit_visibility: TransformedLimitVisibility;
}

export interface IEditProductPayload {
  business_id: string;
  product_id: string;
  body: ICreateProductPayload;
}

export interface IGetProductsPayload {
  business_id: string;
  params: IPaginationParams;
}

export interface IGetProductByIdPayload {
  business_id?: string;
  product_id?: string;
}

export interface IProductCategory {
  id: string;
  name: string;
  business_id: string;
}

export interface IDeleteProductPayload {
  business_id: string;
  product_id: string;
  product_name: string;
}

export interface IProductState {
  products?: IPaginatedResponse<IProduct>;
  productCategories: {
    data: IProductCategory[];
    creating: boolean;
    loading: boolean;
  };

  selectedProduct?: IProduct;
  loadingProduct: boolean;
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export interface IProductSettings {
  productType: EProductType;
  allowCustomerToChooseQuantityRentable: boolean;
  allowCustomerToChooseQuantityConsumable: boolean;
  notificationStockThreshold: boolean;
}
