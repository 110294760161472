import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { HomeOutlined, ScheduleOutlined, ShopOutlined, ShoppingOutlined, TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import { Col, Row } from 'antd';
import './SearchPopoverMenu.scss';

import { HighlightText } from '@aduvi/components/HighlightedText/HighLightedText';
import { Paragraph } from '@aduvi/components/Paragraph';
import { useBusiness } from '@aduvi/hooks';
import { ESearchApps, ISearchResult, ISearchResultMapper } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const searchResultMapper: { [key: string]: ISearchResultMapper } = {
  users: {
    title: 'Users',
    props: ['first_name', 'last_name', 'email'],
    icon: <UsergroupAddOutlined />,
    redirectUri: `/users`,
    uniqueIdentifierKey: 'email',
  },
  services: {
    title: 'Services',
    props: ['name'],
    icon: <ShopOutlined />,
    redirectUri: `/assets`,
    params: { scope: 'services' },
    uniqueIdentifierKey: 'name',
  },
  products: {
    title: 'Products',
    props: ['name'],
    icon: <ShopOutlined />,
    redirectUri: `/assets`,
    params: { scope: 'products' },
    uniqueIdentifierKey: 'name',
  },
  business_job_fields: {
    title: 'Job Management Fields',
    props: ['name'],
    icon: <ScheduleOutlined />,
    redirectUri: `/settings/job-management`,
    uniqueIdentifierKey: 'name',
  },
  business_contact_fields: {
    title: 'Contact Fields',
    props: ['name'],
    icon: <TeamOutlined />,
    redirectUri: `/settings/contact-management`,
    uniqueIdentifierKey: 'name',
  },
  business_venue_fields: {
    title: 'Venue Fields',
    props: ['name'],
    icon: <HomeOutlined />,
    redirectUri: `/settings/venue-management`,
    uniqueIdentifierKey: 'name',
  },
  business_event_fields: {
    title: 'Event Fields',
    props: ['name'],
    icon: <ShoppingOutlined />,
    redirectUri: `/settings/events-management`,
    uniqueIdentifierKey: 'name',
  },
  business_exhibitor_fields: {
    title: 'Exhibitor Fields',
    props: ['name'],
    icon: <ShoppingOutlined />,
    redirectUri: `/settings/events-management`,
    params: { scope: 'exhibitors' },
    uniqueIdentifierKey: 'name',
  },
  business_order_fields: {
    title: 'Order Fields',
    props: ['name'],
    icon: <ShoppingOutlined />,
    redirectUri: `/settings/orders-management`,
    uniqueIdentifierKey: 'name',
  },
};

export const SearchResult = ({ debouncedSearchKey, selectedCategory }: { debouncedSearchKey?: string; selectedCategory: string }) => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const { search } = useAppSelector((state) => state.common);

  const onSearchResultClicked = (item: ISearchResult, searchResultMapperItem: ISearchResultMapper) => {
    if (!selectedBusiness?.id) return;
    const redirectUri = searchResultMapperItem.redirectUri;
    navigate(
      `${redirectUri}?${createSearchParams({
        search: item?.[searchResultMapperItem?.uniqueIdentifierKey ?? 'name'] || debouncedSearchKey,
        ...searchResultMapperItem?.params,
      })}`,
    );
  };

  return (
    <>
      {search?.data &&
        Object.entries(search?.data)?.map(([key, values], index) => {
          if (selectedCategory !== ESearchApps.EVERYTHING && selectedCategory !== key) {
            return <></>;
          }
          return values?.length > 0 ? (
            <Row key={index} className='mt-20'>
              <Col>
                <Paragraph className='search-module mb-5'>
                  {searchResultMapper[key].icon} {searchResultMapper[key].title}
                </Paragraph>
                {values?.map((item: ISearchResult, idx: number) => {
                  const str = searchResultMapper[key]?.props?.map((prop) => item[prop as keyof ISearchResult]).join(' ') || '';
                  return (
                    <Col key={idx} className='ml-20 cursor-pointer' onClick={() => onSearchResultClicked(item, searchResultMapper?.[key])}>
                      <HighlightText text={str} highlight={debouncedSearchKey} />
                    </Col>
                  );
                })}
              </Col>
            </Row>
          ) : (
            <React.Fragment key={index}></React.Fragment>
          );
        })}
    </>
  );
};
