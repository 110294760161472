import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Card, Row, Space, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';

import { useBusiness } from '@aduvi/hooks';

import { useGetAcademyAchievementsQuery, useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

export const Lesson = () => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const { id } = useParams();

  const { data: academies, isFetching: loading } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const { data: academyAchievement } = useGetAcademyAchievementsQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const selectedAcademy = useMemo(() => {
    return academies?.data?.find((item) => item?.id === id);
  }, [id, academies?.data?.length]);

  let newIndex: number | undefined;
  const currentIndex = academies?.data.findIndex((academy) => academy.id === selectedAcademy?.id);

  newIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex ? currentIndex + 1 : currentIndex;

  const onNavigateToNextLesson = () => {
    if (!academies) return;
    const nextAcademy = newIndex && newIndex !== null ? academies?.data?.[newIndex] : null;

    navigate(`/academy/${nextAcademy?.id}`);
  };

  return selectedAcademy ? (
    <Row className='pt-30 pl-30'>
      <Spin spinning={loading}>
        <Card className='academy-video-card' style={{ width: '600px', height: '680px', borderRadius: ' 2px 0px 0px 0px', border: '1px 0px 0px 0px' }}>
          <iframe width='100%' height='350px' className='mb-10' src={selectedAcademy?.video_url} title='YouTube video' allowFullScreen></iframe>

          <Meta
            title={selectedAcademy?.name}
            description={
              <Row>
                <Space direction='vertical' className='w-full'>
                  <Row style={{ maxHeight: '200px', minHeight: '200px', overflowY: 'auto' }}>
                    <div dangerouslySetInnerHTML={selectedAcademy && { __html: selectedAcademy?.description }} />
                  </Row>
                  <Row justify={'center'} style={{ position: 'absolute', bottom: '20px' }}>
                    {academyAchievement?.data?.some((academy) => academy?.academy_id === selectedAcademy?.id && academy?.passed) ? (
                      newIndex && !academies?.data?.[newIndex] ? (
                        <></>
                      ) : (
                        <Button style={{ width: '550px' }} type='primary' onClick={onNavigateToNextLesson}>
                          {translate('academy.goToTheNextLesson')}
                        </Button>
                      )
                    ) : (
                      <Button style={{ width: '550px' }} type='primary' onClick={() => navigate('./quiz')}>
                        {translate('academy.startTheQuiz')}
                      </Button>
                    )}
                  </Row>
                </Space>
              </Row>
            }
          />
        </Card>
      </Spin>
    </Row>
  ) : (
    <></>
  );
};
