import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { Avatar, Button, Col, Image, Input, Modal, Radio, RadioChangeEvent, Row, Spin, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EAvailabilityEntity, EUnitableType } from '@aduvi/types';
import { convertDatesToRanges, convertRangesToDates } from '@aduvi/utils/helper';

import { createSingleEntityBlackoutDates, getEntitiesBlackoutDates } from 'store/features/availability-slice';
import { getServices } from 'store/features/services-slice';
import { getBusinessUsers } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetBusinessUsersQuery } from 'store/rtk-slice/user-slice';

const AllEntitiesAvailableCalendar = ({
  open,
  setOpen,
  setMainModalOpen,
  entityType,
  includeEntityTabs = false,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setMainModalOpen: (open: boolean) => void;
  entityType: EAvailabilityEntity | undefined;
  includeEntityTabs?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { services } = useAppSelector((state) => state.services);
  const { allUnitsBlackoutDates, loading } = useAppSelector((state) => state.availability);
  const { data: users } = useGetBusinessUsersQuery(
    { business_id: selectedBusiness?.id as string, params: { page: 1, size: 50 } },
    { skip: !selectedBusiness?.id },
  );
  const [entityTypeValue, setEntityTypeValue] = useState(EAvailabilityEntity.SERVICE_UNIT);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
  const [initialUnavailableRanges, setInitialUnavailableRanges] = useState<{ [unitId: string]: { start: string; end: string }[] }>({});
  const [unavailableDates, setUnavailableDates] = useState<{ [unitId: string]: Dayjs[] }>({});
  // State for selected dates per unit during drag
  const [selectedDates, setSelectedDates] = useState<{ [unitId: string]: Dayjs[] }>({});
  const [dragStartDate, setDragStartDate] = useState<Dayjs | null>(null);
  const [addingDates, setAddingDates] = useState<boolean>(true);
  const [isDragging, setIsDragging] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  // State for dates to add and delete per unit
  const [datesToAdd, setDatesToAdd] = useState<{ [unitId: string]: { start: string; end: string }[] }>({});
  const [datesToDelete, setDatesToDelete] = useState<{ [unitId: string]: { start: string; end: string }[] }>({});

  // Fetch initial unavailable date ranges per unit from backend
  const handleMouseDown = (date: Dayjs, unitId: string) => {
    setIsDragging(true);
    setDragStartDate(date);
    const unitUnavailableDates = unavailableDates[unitId] || [];
    if (unitUnavailableDates.find((ud) => ud?.isSame(date, 'day'))) {
      setAddingDates(false);
    } else {
      setAddingDates(true);
    }
    setSelectedDates({
      [unitId]: [date],
    });
  };

  const handleMouseEnter = (date: Dayjs, unitId: string) => {
    if (isDragging && dragStartDate) {
      const datesInRange = getDatesInRectangle(dragStartDate, date);
      setSelectedDates((prevSelectedDates) => {
        const newSelectedDates = { ...prevSelectedDates };
        newSelectedDates[unitId] = datesInRange;
        return newSelectedDates;
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartDate(null);
    if (Object.keys(selectedDates)?.length > 0) {
      setConfirmModalOpen(true);
    }
  };

  const handleConfirmationModalOk = () => {
    setConfirmModalOpen(false);
    setUnavailableDates((prev) => {
      const newUnavailableDates = { ...prev };
      Object.entries(selectedDates)?.forEach(([unitId, dates]) => {
        const unitUnavailableDates = prev[unitId] || [];
        let newUnitUnavailableDates = [...unitUnavailableDates];
        if (addingDates) {
          dates?.forEach((date) => {
            if (!newUnitUnavailableDates?.find((d) => d?.isSame(date, 'day'))) {
              newUnitUnavailableDates.push(date);
            }
          });
        } else {
          newUnitUnavailableDates = newUnitUnavailableDates?.filter((date) => !dates?.find((selectedDate) => selectedDate?.isSame(date, 'day')));
        }
        newUnavailableDates[unitId] = newUnitUnavailableDates;
        // Detect changes for this unit
        detectChangesForUnit(unitId, newUnitUnavailableDates);
      });
      return newUnavailableDates;
    });
    setSelectedDates({});
  };

  const handleConfirmationModalCancel = () => {
    setConfirmModalOpen(false);
    setSelectedDates({});
  };

  const detectChangesForUnit = (unitId: string, newUnavailableDates: Dayjs[]) => {
    const initialRanges = initialUnavailableRanges[unitId] || [];
    const currentRanges = convertDatesToRanges(newUnavailableDates);
    const rangesToDeleteForUnit: { start: string; end: string }[] = [];
    const rangesToAddForUnit: { start: string; end: string }[] = [];
    const rangesAreEqual = (range1: { start: string; end: string }, range2: { start: string; end: string }) => {
      return range1?.start === range2?.start && range1?.end === range2?.end;
    };
    // Identify ranges to delete
    initialRanges?.forEach((initialRange) => {
      const existsInCurrentRanges = currentRanges?.some((currentRange) => rangesAreEqual(currentRange, initialRange));
      if (!existsInCurrentRanges) {
        rangesToDeleteForUnit.push(initialRange);
      }
    });
    // Identify ranges to add
    currentRanges?.forEach((currentRange) => {
      const existsInInitialRanges = initialRanges?.some((initialRange) => rangesAreEqual(currentRange, initialRange));
      if (!existsInInitialRanges) {
        rangesToAddForUnit.push(currentRange);
      }
    });
    setDatesToAdd((prev) => ({
      ...prev,
      [unitId]: rangesToAddForUnit,
    }));
    setDatesToDelete((prev) => ({
      ...prev,
      [unitId]: rangesToDeleteForUnit,
    }));
  };

  const getDatesInRectangle = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
    const dates = [];
    const start = startDate?.isBefore(endDate) ? startDate : endDate;
    const end = startDate?.isAfter(endDate) ? startDate : endDate;
    let currentDate = start?.startOf('day');
    while (currentDate?.isBefore(end?.endOf('day')) || currentDate?.isSame(end, 'day')) {
      dates.push(currentDate);
      currentDate = currentDate?.add(1, 'day');
    }
    return dates;
  };

  useEffect(() => {
    if (open) {
      if (!selectedBusiness?.id) return;

      setInitialUnavailableRanges({});
      setUnavailableDates({});
      setSelectedDates({});
      setDatesToAdd({});
      setDatesToDelete({});
      if (includeEntityTabs) {
        dispatch(
          getEntitiesBlackoutDates({
            entity_type: entityTypeValue,
            business_id: selectedBusiness.id,
            start_date: selectedMonth?.startOf('month')?.format('YYYY-MM-DD'),
            end_date: selectedMonth?.endOf('month')?.format('YYYY-MM-DD'),
          }),
        );
      } else {
        if (!entityType) return;
        dispatch(
          getEntitiesBlackoutDates({
            entity_type: entityType,
            business_id: selectedBusiness.id,
            start_date: selectedMonth?.startOf('month')?.format('YYYY-MM-DD'),
            end_date: selectedMonth?.endOf('month')?.format('YYYY-MM-DD'),
          }),
        );
      }
    }
  }, [open, selectedMonth, entityType, selectedBusiness?.id, dispatch, includeEntityTabs, entityTypeValue]);

  useEffect(() => {
    if (allUnitsBlackoutDates) {
      const initialDates: { [unitId: string]: Dayjs[] } = {};
      const initialRanges: { [unitId: string]: { start: string; end: string }[] } = {};

      allUnitsBlackoutDates?.forEach((item) => {
        const unitId = item?.unitable_id;
        const ranges = [
          {
            start: dayjs(item?.start_date)?.format('YYYY-MM-DD'),
            end: dayjs(item?.end_date)?.format('YYYY-MM-DD'),
          },
        ];
        initialRanges[unitId] = initialRanges[unitId] ? [...initialRanges[unitId], ...ranges] : ranges;
        const dates = convertRangesToDates(ranges);
        initialDates[unitId] = initialDates[unitId] ? [...initialDates[unitId], ...dates] : dates;
      });

      setInitialUnavailableRanges(initialRanges);

      setUnavailableDates(initialDates);
    } else {
      setInitialUnavailableRanges({});
      setUnavailableDates({});
    }
  }, [allUnitsBlackoutDates]);

  useEffect(() => {
    if (!open) {
      setInitialUnavailableRanges({});
      setUnavailableDates({});
      setSelectedDates({});
      setDatesToAdd({});
      setDatesToDelete({});
      setDragStartDate(null);
      setIsDragging(false);
      setAddingDates(true);
      setConfirmModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isDragging) {
        handleMouseUp();
      }
    };
    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [isDragging]);

  // Function to save changes per unit
  const handleSave = () => {
    if (!selectedBusiness?.id) return;
    const datesToDeleteArray: { unitable_id: string; start_date: string; end_date: string }[] = [];
    const unitsArray: { unitable_type: EUnitableType; unitable_id: string; start_date: string; end_date: string }[] = [];

    const entityTypeMap = {
      USER: EUnitableType.USER,
      SERVICE_UNIT: EUnitableType.SERVICE_UNIT,
      PRODUCT: EUnitableType.PRODUCT,
    };

    Object.keys(unavailableDates)?.forEach((unitId) => {
      const datesToAddForUnit = datesToAdd[unitId] || [];
      const datesToDeleteForUnit = datesToDelete[unitId] || [];
      datesToDeleteForUnit?.forEach((range) => {
        datesToDeleteArray.push({
          unitable_id: unitId,
          start_date: range?.start,
          end_date: range?.end,
        });
      });
      datesToAddForUnit?.forEach((range) => {
        unitsArray.push({
          unitable_type: entityTypeMap[entityTypeValue],
          unitable_id: unitId,
          start_date: range?.start,
          end_date: range?.end,
        });
      });
    });

    dispatch(
      createSingleEntityBlackoutDates({
        business_id: selectedBusiness.id,
        body: {
          dates_to_delete: datesToDeleteArray,
          units: unitsArray,
        },
      }),
    )?.then(() => {
      // Update initial states to current states
      const newInitialRanges: { [unitId: string]: { start: string; end: string }[] } = {};
      Object.keys(unavailableDates)?.forEach((unitId) => {
        newInitialRanges[unitId] = convertDatesToRanges(unavailableDates[unitId] || []);
      });
      setInitialUnavailableRanges(newInitialRanges);
      setOpen(false);
      setMainModalOpen(false);
    });
  };

  const options = [
    { label: translate('availability.service'), value: EAvailabilityEntity.SERVICE_UNIT },
    { label: translate('availability.user'), value: EAvailabilityEntity.USER },
  ];
  const onEntityTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setEntityTypeValue(value);
  };

  const onGetServices = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id) return;
      dispatch(getServices({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id],
  );
  const onGetBusinessUsers = useCallback(
    (page = 1, size = 50) => {
      if (!selectedBusiness?.id) return;
      dispatch(
        getBusinessUsers({
          business_id: selectedBusiness.id,
          params: {
            page,
            size,
          },
        }),
      );
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    onGetServices(1, 50);
    onGetBusinessUsers(1, 50);
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (entityType) setEntityTypeValue(entityType);
  }, [entityType]);
  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={1200}
        style={{ overflow: 'auto' }}
        className='availability-modal'
        footer={
          <Row justify='end'>
            <Button type='primary' onClick={handleSave}>
              {translate('availability.save')}
            </Button>
          </Row>
        }
        destroyOnClose>
        <Radio.Group className='mb-10' options={options} onChange={onEntityTypeChange} value={entityTypeValue} optionType='button' />
        <Row align='middle' className='mb-10'>
          <Button type='default' icon={<span>&#8249;</span>} onClick={() => setSelectedMonth(selectedMonth?.subtract(1, 'month'))} />
          <Button type='default'> {selectedMonth?.format('MMMM YYYY')} </Button>
          <Button type='default' icon={<span>&#8250;</span>} onClick={() => setSelectedMonth(selectedMonth?.add(1, 'month'))} />
        </Row>
        <Spin spinning={loading}>
          {[entityType, entityTypeValue].includes(EAvailabilityEntity.SERVICE_UNIT) &&
            services?.data?.map(
              (service) =>
                service?.units?.map((unit) => (
                  <Row key={unit?.id} className='mb-10' align='middle'>
                    <Col span={4}>
                      <Row gutter={5} align='middle'>
                        <Col>
                          <Image src={service?.image} width={40} height={40} />
                        </Col>
                        <Col>
                          <Typography.Text>{unit?.name}</Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      span={20}
                      className='flex-grow single-unit-calendar '
                      onWheel={(e) => {
                        const container = e?.currentTarget;
                        container.scrollLeft += e?.deltaY;
                      }}>
                      <Row
                        wrap={false}
                        className='single-unit-calendar '
                        gutter={3}
                        onWheel={(e) => {
                          const container = e?.currentTarget;
                          container.scrollLeft += e?.deltaY;
                        }}>
                        {Array.from({ length: selectedMonth?.daysInMonth() }, (_, i) => {
                          const value = selectedMonth?.startOf('month')?.add(i, 'day');
                          const unitUnavailableDates = unavailableDates[unit?.id] || [];
                          const isBooked = unitUnavailableDates?.some((date) => date?.isSame(value, 'day'));
                          const isSelected = selectedDates[unit?.id]?.some((date) => date?.isSame(value, 'day')) ?? false;
                          return (
                            <Col key={i}>
                              <div
                                onMouseDown={() => handleMouseDown(value, unit?.id)}
                                onMouseEnter={() => handleMouseEnter(value, unit?.id)}
                                className='availability-calendar-cell'
                                style={{
                                  backgroundColor: isSelected ? '#85a5ff' : isBooked ? '#FF4D4F' : '#E6F7FF',
                                  color: isBooked ? 'white' : '#1890FF',
                                }}>
                                {value?.date()}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                )),
            )}
          {[entityType, entityTypeValue].includes(EAvailabilityEntity.USER) &&
            users?.data?.map((user) => (
              <Row key={user?.id} className='mb-10' align='middle'>
                <Col span={4}>
                  <Row gutter={5} align='middle'>
                    <Col>
                      <Avatar src={user?.profile_picture ? user?.profile_picture : undefined} size={40}>
                        {user?.first_name?.charAt(0)?.toUpperCase() ?? user?.email?.charAt(0)?.toUpperCase()}
                      </Avatar>
                    </Col>
                    <Col>
                      <Typography.Text>{user?.first_name}</Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={20}
                  className='flex-grow single-unit-calendar '
                  onWheel={(e) => {
                    const container = e?.currentTarget;
                    container.scrollLeft += e?.deltaY;
                  }}>
                  <Row
                    wrap={false}
                    className='single-unit-calendar '
                    gutter={3}
                    onWheel={(e) => {
                      const container = e?.currentTarget;
                      container.scrollLeft += e?.deltaY;
                    }}>
                    {Array.from({ length: selectedMonth?.daysInMonth() }, (_, i) => {
                      const value = selectedMonth?.startOf('month')?.add(i, 'day');
                      const unitUnavailableDates = unavailableDates[user?.id] || [];
                      const isBooked = unitUnavailableDates?.some((date) => date?.isSame(value, 'day'));
                      const isSelected = selectedDates[user?.id]?.some((date) => date?.isSame(value, 'day')) ?? false;
                      return (
                        <Col key={i}>
                          <div
                            onMouseDown={() => handleMouseDown(value, user?.id)}
                            onMouseEnter={() => handleMouseEnter(value, user?.id)}
                            className='availability-calendar-cell'
                            style={{
                              backgroundColor: isSelected ? '#85a5ff' : isBooked ? '#FF4D4F' : '#E6F7FF',
                              color: isBooked ? 'white' : '#1890FF',
                            }}>
                            {value?.date()}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            ))}
        </Spin>
      </Modal>
      <Modal open={confirmModalOpen} onCancel={handleConfirmationModalCancel} onOk={handleConfirmationModalOk} destroyOnClose={true}>
        <Typography.Title level={5}>{translate('availability.confirmDates')}</Typography.Title>
        <Typography.Paragraph>
          {!addingDates ? `${translate('availability.removeDates')}` : `${translate('availability.addDates')}`}
        </Typography.Paragraph>
        <Input.TextArea
          className='removed-dates'
          disabled
          value={Object.entries(selectedDates)
            ?.flatMap(
              ([, dates]) =>
                convertDatesToRanges(dates)?.map(
                  (range) => ` ${dayjs(range?.start)?.format('MMMM D, YYYY')} - ${dayjs(range?.end)?.format('MMMM D, YYYY')}`,
                ),
            )
            ?.join('\n')}
          style={{
            backgroundColor: !addingDates ? '#fff2f0' : '#f6ffed',
            color: !addingDates ? '#ff4d4f' : '#52c41a',
          }}
          rows={10}
        />
      </Modal>
    </>
  );
};

export default AllEntitiesAvailableCalendar;
