import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { Card, Col, Row, Select, Spin, Typography } from 'antd';
import './Statistics.scss';

import { PERIOD_OPTIONS, useStatistics } from './useGetStatistics';

export const Statistics = () => {
  const { t: translate } = useTranslation();
  const { stats, isLoading, period, setPeriod } = useStatistics();

  return (
    <div className='statistics-widget px-15 '>
      <Select
        value={period}
        onChange={setPeriod}
        rootClassName='period-select'
        className='mb-15 period-select'
        style={{ width: 200, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        {PERIOD_OPTIONS.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {translate(option.label)}
          </Select.Option>
        ))}
      </Select>

      <Row
        gutter={[16, 16]}
        wrap={false}
        className='block-container'
        onWheel={(e) => {
          const container = e?.currentTarget;
          container.scrollLeft += e?.deltaY;
        }}>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.leads')}
            value={stats?.number_of_leads?.current_period_count?.toString() || '0'}
            change={Number(stats?.number_of_leads?.growth_percentage)}
            previousPeriod={stats?.number_of_leads?.previous_period_count || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.conversion')}
            value={`${stats?.conversion_rate?.current_period_percentage}%` || '0%'}
            change={Number(stats?.conversion_rate?.growth_percentage)}
            previousPeriod={stats?.conversion_rate?.previous_period_percentage || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            loading={isLoading}
            title={translate('dashboard.widgets.statistics.customers')}
            value={stats?.number_of_customers?.current_period_count?.toString() || '0'}
            change={Number(stats?.number_of_customers?.growth_percentage)}
            previousPeriod={stats?.number_of_customers?.previous_period_count || 0}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.transactions')}
            value={stats?.average_transactions_per_customer?.current_period_avg?.toString() || '0'}
            change={Number(stats?.average_transactions_per_customer?.growth_percentage)}
            previousPeriod={stats?.average_transactions_per_customer?.previous_period_avg || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.saleValue')}
            value='1000'
            change={30}
            previousPeriod={300}
            loading={isLoading}
          />
        </Col>

        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.revenue')}
            value='1000'
            change={10}
            previousPeriod={300}
            loading={isLoading}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.margin')}
            value='1000'
            change={10}
            previousPeriod={300}
            loading={isLoading}
          />
        </Col>
        <Col span={3}>
          <StatisticsBlock
            title={translate('dashboard.widgets.statistics.profit')}
            value='1000'
            change={10}
            previousPeriod={300}
            loading={isLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

const StatisticsBlock = ({
  title,
  value,
  change,
  previousPeriod,
  loading,
}: {
  title: string;
  value: string;
  change: number;
  previousPeriod: number;
  loading: boolean;
}) => {
  return (
    <Spin spinning={loading} wrapperClassName='statistics-spinner'>
      <Card style={{ height: '100%', borderRadius: 20, backgroundColor: change === 0 ? undefined : change > 0 ? '#F0FDF4' : '#FEF3F2' }}>
        <Typography.Paragraph className='mb-0' type='secondary'>
          {title}
        </Typography.Paragraph>
        <Typography.Paragraph className='mb-0 mt-0 aduvi-title'>{value}</Typography.Paragraph>
        {change === 0 ? (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} />
        ) : change > 0 ? (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} type='success' />
        ) : (
          <StatisticsCardInfo previousPeriod={previousPeriod} change={change} type='danger' />
        )}
      </Card>
    </Spin>
  );
};

const StatisticsCardInfo = ({ previousPeriod, change, type }: { previousPeriod: number; change: number; type?: 'success' | 'danger' }) => {
  return (
    <Typography.Paragraph type={type ? type : 'secondary'} className='mb-0 flex items-center'>
      {previousPeriod}
      <Typography.Text type={type} className='ml-10'>
        {type ? type === 'success' ? <CaretUpOutlined className='fs-16' /> : <CaretDownOutlined className='fs-16' /> : null} {change}%
      </Typography.Text>
    </Typography.Paragraph>
  );
};
