import axios from '@aduvi/config/axios';
import {
  IAcceptInvitationPayload,
  IAcceptInvitationResponse,
  IChangePasswordPayload,
  ICurrentUser,
  IForgotPasswordPayload,
  IForgotPasswordResponse,
  ILoginPayload,
  ILoginResponse,
  IRegisterPayload,
  IRegisterResponse,
  IResetPasswordPayload,
  IResponse,
  IUser,
  IValidateAndResetPasswordResponse,
  IValidateTokenPayload,
  IVerifyEmailPayload,
} from '@aduvi/types';

export const login = async (body: ILoginPayload): Promise<IResponse<ILoginResponse>> =>
  axios.post('/auth/login', body, { params: { auth_type: body.auth_type } });

export const register = async (body: IRegisterPayload): Promise<IResponse<IRegisterResponse>> => axios.post('/auth/register', body);

export const forgetPassword = async (body: IForgotPasswordPayload): Promise<IResponse<IForgotPasswordResponse>> =>
  axios.post('/auth/forget-password', body);

export const validateToken = async (body: IValidateTokenPayload): Promise<IResponse<IValidateAndResetPasswordResponse>> =>
  axios.post('auth/validate-token', body);

export const resetPassword = async (body: IResetPasswordPayload): Promise<IResponse<IValidateAndResetPasswordResponse>> =>
  axios.post('auth/reset-password', body);

export const getUserData = (): Promise<IResponse<ICurrentUser>> => {
  return axios.get(`/me`);
};

export const updateUserData = (userId: string, payload: IUser): Promise<IResponse<ICurrentUser>> => {
  return axios.put(`/users/${userId}`, payload);
};

export const updateUser2Fa = (userId: string, otp?: string): Promise<IResponse<ICurrentUser>> => {
  return axios.put(`/users/${userId}/toggle-2fa`, { otp });
};

export const resendVerificationCode = async (email: string): Promise<void> => {
  return axios.post(`/auth/send-otp`, { email });
};

export const changePassword = (userId: string, body: IChangePasswordPayload): Promise<IResponse<IUser>> => {
  return axios.put(`/users/${userId}/change-password`, body);
};

export const verifyEmail = async (payload: IVerifyEmailPayload): Promise<void> => axios.post('auth/verify-email', payload);

export const resendVerificationEmail = async (email: string): Promise<void> => axios.post('auth/send-verification-email', { email });

export const acceptInvitation = async (payload: IAcceptInvitationPayload): Promise<IResponse<IAcceptInvitationResponse>> =>
  axios.post('/users/accept-invitation', payload);
