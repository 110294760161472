import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EllipsisOutlined, QuestionCircleOutlined, SendOutlined, SettingOutlined } from '@ant-design/icons';

import { Button, Col, Dropdown, MenuProps, Popconfirm, Row, Spin, Table } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';

import { deleteProposal, getProposals } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Proposals = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const {
    proposals: { proposals, loading },
  } = useAppSelector((state) => state.proposals);

  const tableData = proposals.map((item) => ({
    id: item.id,
    pageTitle: item.page_title,
    entityType: item.entity?.entity_type?.name,
    entity: item?.entity?.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS && item.core)?.field_data[0]?.value,
    contact: 'guxim.selmani@technoperia.com',
    status: 'Sent',
  }));

  const dropdownItems = (id: string): MenuProps['items'] => [
    {
      key: '1',
      icon: <SettingOutlined />,
      label: translate('file.edit'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        // dispatch(setCurrentTemplateState({ html: '', mainCss: '', sectionCss: '' }));
        navigate(`/proposal-builder?proposalId=${id}`);
      },
    },

    {
      key: '2',
      icon: <SendOutlined />,
      label: 'Send',
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        // if (!selectedBusiness?.id || !id) return;
        // dispatch(cloneBuilderTemplates({ businessId: selectedBusiness?.id, templateId: id }));
      },
    },
    {
      key: '3',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('buttons.delete')}
          description={translate('common.deleteConfirmation')}
          icon={<QuestionCircleOutlined />}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
          onConfirm={(e) => {
            e?.stopPropagation();
            // e?.preventDefault();'
            if (!selectedBusiness?.id || !id) return;
            dispatch(deleteProposal({ businessId: selectedBusiness?.id, proposalId: id }));
          }}>
          {translate('file.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
      // disabled: !useUserPermissionCheck(PERMISSIONS.FILES.DELETE),
    },
  ];

  const columns = [
    {
      title: 'Title',
      dataIndex: 'pageTitle',
      key: 'pageTitle',
    },
    {
      title: 'Entity Type',
      dataIndex: 'entityType',
      key: 'entityType',
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
      key: 'entityId',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: ' ',
      render: (_: string, data: { id: string }) => (
        <Row className='w-full' justify='end'>
          <Dropdown menu={{ items: dropdownItems(data.id) }} trigger={['click']}>
            <Button
              onClick={(e) => {
                e?.stopPropagation();
              }}
              icon={<EllipsisOutlined />}
              type='text'
            />
          </Dropdown>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getProposals(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  return (
    <Spin spinning={loading}>
      <Col className={`lead-table-wrapper expand-table  w-full mt-20`}>
        <Table
          rowClassName='cursor-pointer'
          scroll={{ x: 'calc(400px + 50%)' }}
          pagination={false}
          columns={columns}
          dataSource={tableData}
          onRow={() => ({})}
        />
      </Col>
    </Spin>
  );
};
