import React from 'react';

import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';

export interface ITitleProps extends TitleProps, React.RefAttributes<HTMLElement> {}

export const Title = (props: ITitleProps) => {
  return <Typography.Title {...props}>{props.children}</Typography.Title>;
};
