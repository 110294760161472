import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Typography } from 'antd';

import Tiptap from '@aduvi/Tiptap/Tiptap';

export const ThankYouPageEditor = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Typography.Text type='secondary'>{translate('forms.forms.thankYouPage')}</Typography.Text>
      <Form.Item name={'thank_you_page_message'} label={translate('forms.forms.thankYouPageMessage')} className='mt-15'>
        <Tiptap
          content={form.getFieldValue('thank_you_page_message')}
          placeholder={translate('forms.forms.addAMessage')}
          onUpdate={(value) => form.setFieldValue('thank_you_page_message', value.getHTML())}
        />
      </Form.Item>
    </>
  );
};
