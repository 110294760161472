import {
  ApiOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  CalendarOutlined,
  CarOutlined,
  ClockCircleOutlined,
  CodeOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FormatPainterOutlined,
  FundProjectionScreenOutlined,
  FunnelPlotOutlined,
  GiftOutlined,
  GlobalOutlined,
  HomeOutlined,
  LinkOutlined,
  NotificationOutlined,
  PieChartOutlined,
  ProfileOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RocketOutlined,
  SafetyCertificateOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  WechatOutlined,
  WindowsOutlined,
} from '@ant-design/icons';

import { INavigationConfig } from '@aduvi/types/constants';

import { PERMISSIONS } from './common';

export const navigationConfig: INavigationConfig[] = [
  {
    key: 'dashboard',
    path: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardOutlined />,
  },
  {
    key: 'job',
    path: 'leads',
    label: 'Leads',
    icon: <FunnelPlotOutlined />,
    permission: PERMISSIONS.JOBS.VIEW,
  },
  {
    key: 'job,booking',
    path: 'bookings',
    label: 'Bookings',
    icon: <ReadOutlined />,
    permission: PERMISSIONS.JOBS.VIEW,
  },
  {
    key: 'contact',
    path: 'contacts',
    label: 'Contacts',
    icon: <TeamOutlined />,
    permission: PERMISSIONS.CONTACTS.VIEW,
  },
  {
    key: 'order',
    path: 'orders',
    label: 'Orders',
    icon: <ScheduleOutlined />,
    permission: PERMISSIONS.ORDERS.VIEW,
  },
  {
    key: 'venue',
    path: 'venues',
    label: 'Venues',
    icon: <EnvironmentOutlined />,
    permission: PERMISSIONS.VENUES.VIEW,
  },
  {
    key: 'service,product,event',
    path: 'store/',
    label: 'Store',
    icon: <ShopOutlined />,
    permission: [PERMISSIONS.SERVICES.VIEW, PERMISSIONS.PRODUCTS.VIEW, PERMISSIONS.EVENTS.VIEW],
  },
  {
    key: 'report',
    path: 'reports',
    label: 'Reports',
    icon: <PieChartOutlined />,
    permission: PERMISSIONS.REPORTS.VIEW,
  },
  {
    key: 'task',
    path: 'tasks',
    label: 'Tasks',
    icon: <ProjectOutlined style={{ rotate: '270deg' }} />,
    permission: PERMISSIONS.TASKS.VIEW,
  },
  {
    key: 'automation',
    path: 'automations',
    label: 'Automations',
    icon: <DeploymentUnitOutlined />,
    permission: PERMISSIONS.AUTOMATIONS.VIEW,
  },
  {
    key: 'project',
    path: 'project',
    label: 'Project',
    icon: <FundProjectionScreenOutlined />,
    permission: PERMISSIONS.PROJECT_FIELDS.VIEW,
  },
  {
    key: 'proposal',
    path: 'proposals',
    label: 'Proposals',
    icon: <FileTextOutlined />,
    // permission: PERMISSIONS.PROPOSALS.VIEW,
  },
];

export const bottomNavigationConfig: INavigationConfig[] = [
  {
    key: 'support',
    path: 'support',
    label: 'Support',
    icon: <QuestionCircleOutlined />,
  },
  {
    key: 'conversations',
    path: 'conversation',
    label: 'Conversation',
    icon: <WechatOutlined />,
    permission: PERMISSIONS.BUSINESS.CONVERSATION,
  },
  {
    key: 'search',
    path: 'search',
    label: 'Search',
    icon: <SearchOutlined style={{ rotate: '90deg' }} />,
    permission: PERMISSIONS.BUSINESS.SEARCH,
  },
  {
    key: 'calendar',
    path: 'calendar',
    label: 'Calendar',
    icon: <CalendarOutlined />,
    permission: PERMISSIONS.BUSINESS.CALENDAR,
  },
];

export const brandItemsConfig: INavigationConfig[] = [
  {
    key: 'settings/details',
    path: 'settings/details',
    label: 'Business Details',
    icon: <ShopOutlined />,
  },
  {
    key: 'settings/locale',
    path: 'settings/locale',
    label: 'Locale',
    icon: <ClockCircleOutlined />,
    permission: PERMISSIONS.BUSINESS.VIEW,
  },
  {
    key: 'settings/translations',
    path: 'settings/translations',
    label: 'Translations',
    icon: <GlobalOutlined />,
    permission: PERMISSIONS.BUSINESS.VIEW,
  },
  {
    key: 'settings/locations',
    path: 'settings/locations',
    label: 'Locations',
    icon: <EnvironmentOutlined />,
    permission: PERMISSIONS.LOCATIONS.VIEW,
  },
  {
    key: 'settings/branding',
    path: 'settings/branding',
    label: 'Branding',
    icon: <FormatPainterOutlined />,
    permission: PERMISSIONS.BRANDINGS.VIEW,
  },
  {
    key: 'settings/security',
    path: 'settings/security',
    label: 'Security',
    icon: <SafetyCertificateOutlined />,
    permission: PERMISSIONS.BUSINESS.VIEW,
  },
  {
    key: 'settings/trash',
    path: 'settings/trash',
    label: 'Trash',
    icon: <DeleteOutlined />,
    permission: PERMISSIONS.BUSINESS.VIEW,
  },
];

export const appSettingsItemsConfig: INavigationConfig[] = [
  {
    key: 'invoices',
    path: 'settings/invoices',
    label: 'Invoices',
    icon: <FileDoneOutlined />,
  },
  {
    key: 'settings/shift-scheduler',
    path: 'settings/shift-scheduler',
    label: 'Shift Scheduler',
    icon: <ClockCircleOutlined />,
  },
  {
    key: 'job',
    path: 'settings/job-management',
    label: 'Job Management',
    icon: <ScheduleOutlined />,
    permission: PERMISSIONS.JOB_FIELDS.VIEW,
  },
  {
    key: 'order',
    path: 'settings/orders-management',
    label: 'Orders',
    permission: PERMISSIONS.ORDER_FIELDS.VIEW,
    icon: <ShoppingOutlined />,
  },
  {
    key: 'contact',
    path: 'settings/contact-management',
    label: 'Contacts',
    icon: <TeamOutlined />,
    permission: PERMISSIONS.CONTACT_FIELDS.VIEW,
  },
  {
    key: 'venue',
    path: 'settings/venue-management',
    label: 'Venues',
    permission: PERMISSIONS.VENUE_FIELDS.VIEW,
    icon: <HomeOutlined />,
  },

  {
    key: 'task',
    path: 'settings/tasks-management',
    label: 'Tasks',
    icon: <ProfileOutlined />,
    permission: PERMISSIONS.TASKS.VIEW,
  },
  {
    key: 'project',
    path: 'settings/projects-management',
    label: 'Projects',
    permission: PERMISSIONS.PROJECT_FIELDS.VIEW,
    icon: <FundProjectionScreenOutlined />,
  },
  {
    key: 'event',
    path: 'settings/events-management',
    label: 'Event Ticketing',
    permission: PERMISSIONS.EVENT_FIELDS.VIEW,
    icon: <ShoppingOutlined />,
  },
  {
    key: 'tax',
    path: 'settings/tax',
    label: 'Tax',
    icon: <BankOutlined />,
    permission: PERMISSIONS.TAX.VIEW,
  },
  {
    key: 'payments',
    path: 'settings/payments',
    label: 'Payments',
    icon: <CreditCardOutlined />,
    permission: PERMISSIONS.PAYMENT_SETTING.VIEW,
  },
  {
    key: 'connected_accounts',
    path: 'settings/connected-accounts',
    label: 'Connected Accounts',
    icon: <ApiOutlined />,
  },
  {
    key: 'travel_fees',
    path: 'settings/travel-fees',
    label: 'Travel Fees',
    icon: <CarOutlined />,
  },
  {
    key: 'discounts_coupons',
    path: 'settings/discounts',
    label: 'Discounts & Coupons',
    icon: <ShoppingOutlined />,
    permission: [PERMISSIONS.DISCOUNTS.VIEW, PERMISSIONS.COUPONS.VIEW],
  },
  {
    key: 'contract',
    path: 'settings/contracts',
    label: 'Contracts',
    icon: <ContainerOutlined />,
    permission: PERMISSIONS.CONTRACTS.VIEW,
  },
  {
    key: 'menu_settings',
    path: 'settings/menu-settings',
    label: 'Menu',
    icon: <UnorderedListOutlined />,
    permission: PERMISSIONS.MENU_SETTING.VIEW,
  },
  {
    key: 'client_portal',
    path: 'settings/client-portal',
    label: 'Client Portal',
    icon: <DashboardOutlined />,
    permission: PERMISSIONS.CLIENT_PORTAL.VIEW,
  },
  {
    key: 'files',
    path: 'files',
    label: 'Files',
    icon: <FolderOpenOutlined />,
    permission: PERMISSIONS.FILES.VIEW,
  },
  {
    key: 'proposals',
    path: 'settings/proposals',
    label: 'Proposals',
    icon: <NotificationOutlined />,
    permission: PERMISSIONS.PROPOSALS.VIEW,
  },
  {
    key: 'settings/affiliates',
    path: 'settings/affiliates',
    label: 'Affiliates',
    icon: <UserOutlined />,
    permission: PERMISSIONS.AFFILIATES.VIEW,
  },
  {
    key: 'reps',
    path: 'settings/reps',
    label: 'Reps',
    icon: <LinkOutlined />,
    permission: PERMISSIONS.REPS.VIEW,
  },
];

export const billingsItemsConfig: INavigationConfig[] = [
  {
    key: 'billing/subscription',
    path: 'billing/subscription',
    label: 'subscription',
    permission: PERMISSIONS.BUSINESS_PLANS.VIEW,
    icon: <SolutionOutlined />,
  },
  {
    key: 'billing/invoice-settings',
    path: 'billing/invoice-settings',
    label: 'invoiceSettings',
    permission: PERMISSIONS.BILLING_DETAILS.VIEW,
    icon: <SettingOutlined />,
  },
  {
    key: 'billing/invoices',
    path: 'billing/invoices',
    label: 'invoices',
    icon: <FileDoneOutlined />,
  },
  {
    key: 'billing/payment-methods',
    path: 'billing/payment-methods',
    label: 'paymentMethods',
    icon: <CreditCardOutlined />,
  },
  {
    key: 'billing/billing-contacts',
    path: 'billing/billing-contacts',
    label: 'billingContacts',
    permission: PERMISSIONS.BILLING_DETAILS.VIEW,
    icon: <TeamOutlined />,
  },
];

export const profileExploreMenu: (Omit<INavigationConfig, 'key'> & { key?: string })[] = [
  {
    path: 'roadmap',
    label: 'userProfileMenu.roadmap',
    icon: <RocketOutlined />,
  },
  {
    path: 'rewards',
    label: 'userProfileMenu.rewards',
    icon: <GiftOutlined />,
  },
  {
    path: 'widgets',
    label: 'userProfileMenu.widgets',
    icon: <AppstoreAddOutlined />,
  },
  {
    path: 'developers',
    label: 'userProfileMenu.developers',
    icon: <CodeOutlined />,
  },
];

export const profileAccountMenu: (Omit<INavigationConfig, 'key'> & { key?: string })[] = [
  {
    path: 'profile/details',
    label: 'userProfileMenu.profile',
    icon: <UserOutlined />,
  },
  {
    path: 'apps',
    label: 'userProfileMenu.apps',
    icon: <WindowsOutlined />,
  },
  {
    path: 'billing/subscription',
    label: 'userProfileMenu.billing',
    icon: <CreditCardOutlined />,
    permission: PERMISSIONS.BILLING_DETAILS.VIEW,
  },
  {
    key: 'team_user',
    path: 'users',
    label: 'userProfileMenu.users',
    icon: <UsergroupAddOutlined />,
    permission: PERMISSIONS.USERS.VIEW,
  },
];
