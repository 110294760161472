import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';

import { Col, Row, Typography } from 'antd';
import './Map.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { Constants } from '@aduvi/constants';
import { EFieldDataValueType, ICustomFieldViewStyle, IGeocodeResult, IMapViewProps, IMarker } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const mapContainerStyle = {
  width: '100vw',
  height: '80vh',
  borderRadius: '5px',
};

const mapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];

export const Map = ({ data, addressFieldId }: IMapViewProps) => {
  const { t: translate } = useTranslation();
  const { selectedView } = useAppSelector((state) => state.personalizedViews);

  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [activeMarker, setActiveMarker] = useState<IMarker | null>(null);
  const [userLocation, setUserLocation] = useState<IGeocodeResult>({ lat: 0, lng: 0 });
  const [initialCenter, setInitialCenter] = useState<IGeocodeResult | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  useEffect(() => {
    const getUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const location = { lat: position.coords.latitude, lng: position.coords.longitude };
          setUserLocation(location);
          if (!initialCenter) {
            setInitialCenter(location);
          }
        });
      }
    };
    getUserLocation();
  }, []);

  const offsetForOverlappingMarkers = (markersToProcess: IMarker[]) => {
    const processedMarkers: IMarker[] = [];
    markersToProcess?.forEach((newMarker) => {
      let finalLatLng = { lat: newMarker.lat, lng: newMarker.lng };
      processedMarkers?.forEach((existingMarker) => {
        if (finalLatLng?.lat === existingMarker?.lat && finalLatLng?.lng === existingMarker?.lng) {
          const newLat = finalLatLng.lat + (Math.random() - 0.5) / 1500;
          const newLng = finalLatLng.lng + (Math.random() - 0.5) / 1500;
          finalLatLng = { lat: newLat, lng: newLng };
        }
      });
      processedMarkers.push({ ...newMarker, lat: finalLatLng.lat, lng: finalLatLng.lng });
    });
    return processedMarkers;
  };

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();
    const geocodeAddress = (address: string): Promise<IGeocodeResult | null> => {
      return new Promise((resolve) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK' && results && results?.[0]) {
            resolve({
              lat: results?.[0].geometry.location.lat(),
              lng: results?.[0].geometry.location.lng(),
            });
          } else {
            resolve(null);
          }
        });
      });
    };

    const getMarkers = async () => {
      let markerData = await Promise.all(
        data?.map(async (entity) => {
          const addressField = entity?.custom_fields.find(
            (field) => field?.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES && field?.id === addressFieldId,
          );

          if (addressField) {
            const address = addressField?.field_data
              ?.map((option) => option?.street_one + ', ' + option.city + ', ' + ', ' + option.zip_code + option.country)
              .join(', ');

            const geocodeResult = await geocodeAddress(address);

            if (geocodeResult) {
              const photoUrl = await fetchPlaceImage(geocodeResult?.lat, geocodeResult?.lng, address || '').catch(() => null);
              return { ...geocodeResult, address, entity, photoUrl } as IMarker;
            }
          }
          return null;
        }),
      );

      const filteredMarkerData: IMarker[] = markerData.filter((marker): marker is IMarker => marker !== null);
      const processedMarkers = offsetForOverlappingMarkers(filteredMarkerData);

      setMarkers(processedMarkers);
    };

    if (isLoaded && window.google) {
      getMarkers();
    }
  }, [data, isLoaded, addressFieldId]);

  const viewColumns = useMemo(() => {
    if (!selectedView) return;
    return (JSON.parse(selectedView.style) as ICustomFieldViewStyle[]).map((item) => ({
      id: item?.field_id?.[0],
      style: item?.style,
    }));
  }, [selectedView, selectedView?.style]);

  const getStreetViewImageUrl = ({ lat, lng }: IGeocodeResult, size = '400x300') => {
    return `https://maps.googleapis.com/maps/api/streetview?size=${size}&location=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  };

  const fetchPlaceImage = async (lat: number, lng: number, addressName: string) => {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.nearbySearch(
        {
          location: { lat, lng },
          radius: 5,
        },
        async (results, status) => {
          if (results && status === google.maps.places.PlacesServiceStatus.OK) {
            if (results[0]?.types?.includes('route')) {
              resolve(getStreetViewImageUrl({ lat, lng }));
            } else {
              const filteredResults = results?.filter((result) => result?.name?.includes(addressName) || result?.vicinity?.includes(addressName));
              if (filteredResults.length > 0 && filteredResults[0].place_id) {
                service.getDetails(
                  {
                    placeId: filteredResults[0]?.place_id,
                    fields: ['photos'],
                  },
                  (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && place?.photos && place?.photos?.length > 0) {
                      const photoUrl = place?.photos[0]?.getUrl({ maxWidth: 400, maxHeight: 300 });
                      resolve(photoUrl);
                    }
                    resolve(null);
                  },
                );
              } else {
                reject('No matching place found');
              }
            }
          } else {
            reject('Nearby search failed');
          }
        },
      );
    });
  };

  if (loadError) {
    return (
      <Row>
        <Typography.Text>{translate('maps.error')}</Typography.Text>
      </Row>
    );
  }

  if (!isLoaded) {
    return (
      <Row>
        <Typography.Text>{translate('maps.loading')}</Typography.Text>
      </Row>
    );
  }

  const handleMarkerClick = (marker: IMarker) => {
    setActiveMarker(marker);
  };

  return (
    <Row className='map-view-wrapper'>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={initialCenter || { lng: userLocation.lng, lat: userLocation.lat }}
        options={{ styles: mapStyles }}>
        <MarkerClusterer>
          {(clusterer) => (
            <>
              {markers.map((marker, index) => (
                <Marker
                  key={index}
                  position={{ lat: marker?.lat, lng: marker?.lng }}
                  icon={Constants.Links.CustomMarkerIcon}
                  clusterer={clusterer}
                  onClick={() => handleMarkerClick(marker)}>
                  {activeMarker === marker && marker?.address && (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <React.Fragment>
                        <Row wrap={false} className='info-card'>
                          {marker?.photoUrl ? (
                            <Col>
                              <img src={marker?.photoUrl} alt='Place' className='location-image mr-10' />
                            </Col>
                          ) : (
                            <></>
                          )}

                          <Col span={24}>
                            {viewColumns?.map((viewColumn) => {
                              const fields = marker.entity.custom_fields.find((customField) => customField.id === viewColumn.id);
                              if (!viewColumn.id || !fields) return <></>;
                              return (
                                <Col key={fields?.id} style={{ ...viewColumn.style, width: '100%', padding: '5px 2px' }}>
                                  <CustomFieldValue field={fields} />
                                </Col>
                              );
                            })}
                          </Col>
                        </Row>
                      </React.Fragment>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )}
        </MarkerClusterer>
      </GoogleMap>
    </Row>
  );
};
