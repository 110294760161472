import { Avatar, Col, Popover, Row, Typography } from 'antd';
import '../../ShiftScheduler.scss';

import { ISelectedUser } from '@aduvi/types/shift-scheduler';

import { useAppSelector } from 'store/hooks';

interface AvatarsPopoverProps {
  isVisible: boolean;
  onClose: () => void;
  onSelect: (user: ISelectedUser) => void;
}

export const AvatarsPopover = ({ isVisible, onClose, onSelect }: AvatarsPopoverProps) => {
  const { users } = useAppSelector((state) => state.user);

  const popoverContent = (
    <div style={{ width: 200 }}>
      {users?.data?.map((user) => (
        <Row
          key={user?.id}
          align='middle'
          className='mb-10'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onClose();
            onSelect(user);
          }}>
          <Col span={5}>
            <Avatar src={user?.profile_picture} size='default'>
              {!user?.profile_picture && user?.first_name?.charAt(0)?.toUpperCase()}
            </Avatar>
          </Col>
          <Col span={10}>
            <Typography.Text className='fs-14'>
              {user?.first_name} {user?.last_name}
            </Typography.Text>
          </Col>
          <Col span={8}>
            <Typography.Text className='fs-12' style={{ textTransform: 'uppercase', color: 'orange' }}>
              {user?.role}
            </Typography.Text>
          </Col>
        </Row>
      ))}
    </div>
  );

  return (
    <Popover
      overlayClassName='custom-popover box-shadow'
      content={popoverContent}
      trigger='click'
      open={isVisible}
      onOpenChange={(visible) => !visible && onClose()}
      placement='bottom'></Popover>
  );
};
