import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import './EntityHeaderActions.scss';

import { Button } from '@aduvi/components/Button/Button';
import { SearchInput } from '@aduvi/components/SearchInput/SearchInput';
import { createEntityPermission } from '@aduvi/constants/entity-type';
import { EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

import { EntityHeaderActionsDropdown } from './EntityHeaderActionsDropDown';

interface IProps {
  entityType?: IEntityType;
  personalizedViewOrigin: EPersonalizedViewOrigin;
  onCreateForm: () => void;
  onCreateEntity: () => void;
  onConfigureView: () => void;
  onEditRowLayout: () => void;
}

export const EntityHeaderActions = ({
  entityType,
  personalizedViewOrigin,
  onCreateEntity,
  onConfigureView,
  onEditRowLayout,
  onCreateForm,
}: IProps) => {
  const { t: translate } = useTranslation();

  const { selectedView } = useAppSelector((state) => state.personalizedViews);

  return (
    <Row justify={'end'} gutter={10} className='header-actions-wrapper'>
      <Col>{selectedView?.view_display !== EPersonalizedView.ADDRESS_BOOK && <SearchInput placeholder='Search' />}</Col>

      <Col>
        {selectedView?.view_display === EPersonalizedView.FORMS ? (
          <Button disabledButton className='mr-10' type='primary' onClick={onCreateForm}>
            {translate('leads.leadsHeaderAction.addForm')}
          </Button>
        ) : (
          <Button
            disabledButton
            requiredPermission={entityType && createEntityPermission[entityType?.name]}
            className='mr-10'
            type='primary'
            onClick={onCreateEntity}>
            {translate(`entity.leadsHeaderActions.addButton.${personalizedViewOrigin}`)}
          </Button>
        )}

        <EntityHeaderActionsDropdown
          entityType={entityType}
          onConfigureView={onConfigureView}
          onEditRowLayout={onEditRowLayout}
          personalizedViewOrigin={personalizedViewOrigin}
        />
      </Col>
    </Row>
  );
};
