import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { ICreateTicketPayload, IEditTicketPayload, ISendMessagePayload, ITicket, ITicketState } from '@aduvi/types/ticket';

import * as TicketService from './../services/ticket.service';

const initialState: ITicketState = {
  tickets: [],
  chat: [],
  selectedTicket: undefined,
  creating: false,
  loading: false,
  updating: false,
  sending: false,
};

export const createTicket = createAsyncThunk('ticket/create-ticket', async (params: ICreateTicketPayload, { rejectWithValue }) => {
  try {
    return await TicketService.createTicket(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAllTickets = createAsyncThunk('ticket/get-tickets', async (business_id: string, { rejectWithValue }) => {
  try {
    return await TicketService.getAllTickets(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTicket = createAsyncThunk('ticket/update-ticket', async (params: IEditTicketPayload, { rejectWithValue }) => {
  try {
    return await TicketService.updateTicket(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const sendSupportMessage = createAsyncThunk('ticket/sent-message', async (params: ISendMessagePayload, { rejectWithValue }) => {
  try {
    return await TicketService.sendSupportMessage(params);
  } catch (err) {
    rejectWithValue(err);
  }
});

export const getSupportMessages = createAsyncThunk(
  'ticket/get-message',
  async (params: { business_id: string; ticket_id: string }, { rejectWithValue }) => {
    try {
      return await TicketService.getSupportMessages(params.business_id, params.ticket_id);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const reOpenTicket = createAsyncThunk('ticket/re-open', async (params: { business_id: string; ticket_id: string }, { rejectWithValue }) => {
  try {
    return await TicketService.reOpenTicket(params.business_id, params.ticket_id);
  } catch (err) {
    rejectWithValue(err);
  }
});

export const rateTicket = createAsyncThunk(
  'ticket/rate-ticket',
  async (params: { business_id: string; ticket_id: string; rate: number }, { rejectWithValue }) => {
    try {
      return await TicketService.rateTicket(params.business_id, params.ticket_id, params.rate);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setSelectedTicket(state, { payload }: PayloadAction<ITicket | undefined>) {
      state.selectedTicket = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTicket.pending, (state) => {
        state.creating = true;
      })
      .addCase(createTicket.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.tickets = [payload.data, ...state.tickets];
      })
      .addCase(createTicket.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getAllTickets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTickets.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tickets = orderBy(payload.data, 'created_at', 'desc');
      })
      .addCase(getAllTickets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateTicket.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateTicket.fulfilled, (state, { meta }) => {
        state.updating = false;
        state.tickets = state.tickets.map((item) => (item?.id === meta?.arg?.ticket_id ? { ...item, priority: meta?.arg?.body.priority } : item));
      })
      .addCase(updateTicket.rejected, (state) => {
        state.updating = false;
      })
      .addCase(sendSupportMessage.pending, (state) => {
        state.sending = true;
      })
      .addCase(sendSupportMessage.fulfilled, (state, { payload }) => {
        state.sending = false;
        if (payload) state.chat = [...state.chat, payload.data];
      })
      .addCase(sendSupportMessage.rejected, (state) => {
        state.sending = false;
      })
      .addCase(getSupportMessages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSupportMessages.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.chat = payload?.data || [];
      })
      .addCase(getSupportMessages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(reOpenTicket.pending, (state) => {
        state.updating = true;
      })
      .addCase(reOpenTicket.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.tickets = state.tickets.map((ticket) => (ticket.id === payload?.data.id ? { ...ticket, status: payload.data.status } : ticket));
        if (payload && state.selectedTicket) state.selectedTicket = { ...state.selectedTicket, status: payload?.data?.status };
      })
      .addCase(reOpenTicket.rejected, (state) => {
        state.updating = false;
      })
      .addCase(rateTicket.pending, (state) => {
        state.updating = true;
      })
      .addCase(rateTicket.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.tickets = state.tickets.map((ticket) => (ticket.id === payload?.data.id ? { ...ticket, rating: payload.data.rating } : ticket));
        if (payload && state.selectedTicket) state.selectedTicket = { ...state.selectedTicket, rating: payload?.data?.rating };
      })
      .addCase(rateTicket.rejected, (state) => {
        state.updating = false;
      });
  },
});

export const ticketReducer = ticketSlice.reducer;

export const { setSelectedTicket } = ticketSlice.actions;
