import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { ICreateManagementFieldPayload, IManagementField, IReorderManagementFieldsPayload, IUpdateManagementFieldPayload } from '@aduvi/types';
import {
  IAgenda,
  ICreateAgendaPayload,
  ICreateEventPayload,
  ICreateExhibitorPayload,
  IDeleteAgendaPayload,
  IDeleteExhibitorPayload,
  IEvent,
  IEventsState,
  IExhibitor,
  IGetEventsPayload,
  IUpdateEventPayload,
} from '@aduvi/types/event';

import * as EventServices from 'store/services/event.service';

const initialState: IEventsState = {
  events: undefined,
  selectedEvent: undefined,
  selectedAgenda: undefined,
  selectedExhibitor: undefined,
  eventCustomFields: {
    eventFields: [],
    loading: false,
    creating: false,
    updating: false,
    selectedEventField: undefined,
  },
  exhibitorCustomFields: {
    exhibitorFields: [],
    loading: false,
    creating: false,
    updating: false,
    selectedExhibitorField: undefined,
  },
  agendas: [],
  exhibitors: [],
  loadingEvent: false,
  loading: false,
  creating: false,
  updating: false,
};

export const getEvents = createAsyncThunk('events/get-events', async (params: IGetEventsPayload, { rejectWithValue }) => {
  try {
    return await EventServices.getEvents(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEvent = createAsyncThunk('events/create-events', async (params: ICreateEventPayload, { rejectWithValue }) => {
  try {
    return await EventServices.createEvent(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEvent = createAsyncThunk('events/update-events', async (params: IUpdateEventPayload, { rejectWithValue }) => {
  try {
    return await EventServices.updateEvent(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEventById = createAsyncThunk(
  'events/get-event-by-id',
  async (params: { business_id: string; event_id: string }, { rejectWithValue }) => {
    try {
      return await EventServices.getEventById(params.business_id, params.event_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const createAgenda = createAsyncThunk('agenda/create', async (params: ICreateAgendaPayload, { rejectWithValue }) => {
  try {
    return await EventServices.createAgenda(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateAgenda = createAsyncThunk('events/update-agenda', async (params: ICreateAgendaPayload, { rejectWithValue }) => {
  try {
    return await EventServices.updateAgenda(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteAgenda = createAsyncThunk('events/delete-agenda', async (params: IDeleteAgendaPayload, { rejectWithValue }) => {
  try {
    return await EventServices.deleteAgenda(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createExhibitor = createAsyncThunk('exhibitor/create', async (params: ICreateExhibitorPayload, { rejectWithValue }) => {
  try {
    return await EventServices.createExhibitor(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateExhibitor = createAsyncThunk('exhibitor/update', async (params: ICreateExhibitorPayload, { rejectWithValue }) => {
  try {
    return await EventServices.updateExhibitor(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteExhibitor = createAsyncThunk('exhibitor/delete', async (params: IDeleteExhibitorPayload, { rejectWithValue }) => {
  try {
    return await EventServices.deleteExhibitor(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteEvent = createAsyncThunk('events/delete', async (params: { business_id: string; event_id: string }, { rejectWithValue }) => {
  try {
    return await EventServices.deleteEvent(params.business_id, params.event_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createEventFields = createAsyncThunk(
  'business/create-event-custom-fields',
  async (params: ICreateManagementFieldPayload, { rejectWithValue }) => {
    try {
      return await EventServices.createEventFields(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateEventField = createAsyncThunk(
  'business/update-event-fields',
  async (params: IUpdateManagementFieldPayload, { rejectWithValue }) => {
    try {
      return await EventServices.updateEventField(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getEventFields = createAsyncThunk('business/get-event-fields', async (businessId: string, { rejectWithValue }) => {
  try {
    return await EventServices.getEventFields(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const reorderEventFields = createAsyncThunk(
  'business/reorder-event-fields',
  async (params: IReorderManagementFieldsPayload, { rejectWithValue }) => {
    try {
      return await EventServices.reorderEventFields(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createExhibitorFields = createAsyncThunk(
  'business/create-exhibitor-fields',
  async (params: ICreateManagementFieldPayload, { rejectWithValue }) => {
    try {
      return await EventServices.createExhibitorFields(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateExhibitorField = createAsyncThunk(
  'business/update-exhibitor-fields',
  async (params: IUpdateManagementFieldPayload, { rejectWithValue }) => {
    try {
      return await EventServices.updateExhibitorField(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getExhibitorFields = createAsyncThunk('business/get-exhibitor-fields', async (businessId: string, { rejectWithValue }) => {
  try {
    return await EventServices.getExhibitorFields(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const reorderExhibitorFields = createAsyncThunk(
  'business/reorder-exhibitor-fields',
  async (params: IReorderManagementFieldsPayload, { rejectWithValue }) => {
    try {
      return await EventServices.reorderExhibitorFields(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEventField: (state, { payload }: PayloadAction<IManagementField | undefined>) => {
      state.eventCustomFields.selectedEventField = payload;
    },
    setSelectedExhibitorField: (state, { payload }: PayloadAction<IManagementField | undefined>) => {
      state.exhibitorCustomFields.selectedExhibitorField = payload;
    },
    setSelectedEvent: (state, action: PayloadAction<Partial<IEvent> | undefined>) => {
      state.selectedEvent = action.payload as IEvent;
    },
    setSelectedAgenda: (state, action: PayloadAction<Partial<IAgenda> | undefined>) => {
      state.selectedAgenda = action.payload as IAgenda;
    },
    setSelectedExhibitor: (state, action: PayloadAction<Partial<IExhibitor> | undefined>) => {
      state.selectedExhibitor = action.payload as IExhibitor;
    },
    createEventReducer: (state, action: PayloadAction<IEvent>) => {
      if (state.events) state.events.data = [...state.events.data, { ...action.payload, tickets: [] }];
    },
    deleteEventReducer: (state, action: PayloadAction<string>) => {
      if (state.events) state.events.data = state.events?.data.filter((item) => item.id !== action.payload);
    },
    editEventReducer: (state, action: PayloadAction<IEvent>) => {
      if (state.events)
        state.events.data = state?.events?.data?.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEvents.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.events = payload;
      })
      .addCase(getEvents.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createEvent.pending, (state) => {
        state.creating = true;
      })
      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.events) state.events.data = [...state.events.data, { ...payload.data, tickets: [] }];
      })
      .addCase(createEvent.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateEvent.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateEvent.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.events?.data)
          state.events.data = state?.events?.data?.map((item) => {
            return item.id === payload.data.id ? payload.data : item;
          });
        if (state.selectedEvent) {
          state.selectedEvent = payload.data;
        }
      })
      .addCase(updateEvent.rejected, (state) => {
        state.updating = false;
      })
      .addCase(getEventById.pending, (state) => {
        state.loadingEvent = true;
      })
      .addCase(getEventById.fulfilled, (state, { payload }) => {
        state.loadingEvent = false;
        state.selectedEvent = payload.data;
      })
      .addCase(getEventById.rejected, (state) => {
        state.loadingEvent = false;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteEvent.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.events) state.events.data = state.events?.data.filter((item) => item.id !== meta.arg.event_id);
      })
      .addCase(createAgenda.pending, (state) => {
        state.creating = true;
      })
      .addCase(createAgenda.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.selectedEvent?.agendas.push(payload.data);
      })
      .addCase(createAgenda.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateAgenda.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateAgenda.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.events && state.selectedEvent)
          state.events.data = state.events.data.map((item) => {
            if (item.id === state.selectedEvent?.id)
              return {
                ...item,
                agendas: item.agendas.map((agenda) => (agenda.id === payload.data.id ? payload.data : agenda)),
              };
            return item;
          });
        if (state.selectedEvent) {
          const updatedAgendas = state.selectedEvent.agendas.filter((agenda) => agenda.id !== payload.data.id);
          state.selectedEvent.agendas = [...updatedAgendas, payload.data];
        }
      })
      .addCase(updateAgenda.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteAgenda.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAgenda.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (!state.selectedEvent) return;
        state.selectedEvent.agendas = state.selectedEvent?.agendas.filter((agenda) => agenda.id !== meta.arg.agenda_id);
      })
      .addCase(deleteAgenda.rejected, (state) => {
        state.updating = false;
      })
      .addCase(createExhibitor.pending, (state) => {
        state.creating = true;
      })
      .addCase(createExhibitor.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.selectedEvent?.exhibitors.push(payload.data);
      })
      .addCase(createExhibitor.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateExhibitor.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateExhibitor.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.events?.data && state.selectedEvent)
          state.events.data = state.events.data.map((item) => {
            if (item.id === state.selectedEvent?.id)
              return {
                ...item,
                exhibitors: item?.exhibitors?.map((exhibitor) => (exhibitor.id === payload.data.id ? payload.data : exhibitor)),
              };
            return item;
          });
        if (state.selectedEvent) {
          const updateExhibitors = state.selectedEvent.exhibitors.filter((exhibitor) => exhibitor.id !== payload.data.id);
          state.selectedEvent.exhibitors = [...updateExhibitors, payload.data];
        }
      })
      .addCase(updateExhibitor.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteExhibitor.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExhibitor.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (!state.selectedEvent) return;
        state.selectedEvent.exhibitors = state.selectedEvent?.exhibitors.filter((exhibitor) => exhibitor.id !== meta.arg.exhibitor_id);
      })
      .addCase(deleteExhibitor.rejected, (state) => {
        state.updating = false;
      })
      .addCase(getEventFields.pending, (state) => {
        state.eventCustomFields.loading = true;
      })
      .addCase(getEventFields.fulfilled, (state, { payload }) => {
        state.eventCustomFields.loading = false;
        state.eventCustomFields.eventFields = orderBy(payload.data, 'order_number', ['asc']);
      })
      .addCase(getEventFields.rejected, (state) => {
        state.eventCustomFields.loading = false;
      })
      .addCase(createEventFields.pending, (state) => {
        state.eventCustomFields.creating = true;
      })
      .addCase(createEventFields.fulfilled, (state, { payload }) => {
        state.eventCustomFields.creating = false;
        state.eventCustomFields.eventFields = [...state.eventCustomFields.eventFields, payload.data];
      })
      .addCase(createEventFields.rejected, (state) => {
        state.eventCustomFields.creating = false;
      })
      .addCase(updateEventField.pending, (state) => {
        state.eventCustomFields.updating = true;
      })
      .addCase(updateEventField.fulfilled, (state, { payload: { data } }) => {
        state.eventCustomFields.updating = false;
        state.eventCustomFields.eventFields = state.eventCustomFields.eventFields?.map((field) => (field.id === data?.id ? data : field));
      })
      .addCase(updateEventField.rejected, (state) => {
        state.eventCustomFields.updating = false;
      })

      .addCase(reorderEventFields.pending, (state, { meta }) => {
        state.eventCustomFields.loading = true;
        state.eventCustomFields.eventFields = orderBy(
          state.eventCustomFields.eventFields.map((item) => ({
            ...item,
            order_number: Number(meta.arg.body.fields.find((field) => field.id === item.id)?.order_number),
          })),
          'order_number',
          ['asc'],
        );
      })
      .addCase(reorderEventFields.rejected, (state) => {
        state.eventCustomFields.loading = false;
      })
      .addCase(reorderEventFields.fulfilled, (state, { payload }) => {
        state.eventCustomFields.loading = false;
        state.eventCustomFields.eventFields = orderBy(payload.data, 'order_number', ['asc']);
      })
      .addCase(getExhibitorFields.pending, (state) => {
        state.exhibitorCustomFields.loading = true;
      })
      .addCase(getExhibitorFields.fulfilled, (state, { payload }) => {
        state.exhibitorCustomFields.loading = false;
        state.exhibitorCustomFields.exhibitorFields = orderBy(payload.data, 'order_number', ['asc']);
      })
      .addCase(getExhibitorFields.rejected, (state) => {
        state.exhibitorCustomFields.loading = false;
      })
      .addCase(createExhibitorFields.pending, (state) => {
        state.exhibitorCustomFields.creating = true;
      })
      .addCase(createExhibitorFields.fulfilled, (state, { payload }) => {
        state.exhibitorCustomFields.creating = false;
        state.exhibitorCustomFields.exhibitorFields = [...state.exhibitorCustomFields.exhibitorFields, payload.data];
      })
      .addCase(createExhibitorFields.rejected, (state) => {
        state.exhibitorCustomFields.creating = false;
      })
      .addCase(updateExhibitorField.pending, (state) => {
        state.exhibitorCustomFields.updating = true;
      })
      .addCase(updateExhibitorField.fulfilled, (state, { payload: { data } }) => {
        state.exhibitorCustomFields.updating = false;
        state.exhibitorCustomFields.exhibitorFields = state.exhibitorCustomFields.exhibitorFields?.map((field) =>
          field.id === data?.id ? data : field,
        );
      })
      .addCase(updateExhibitorField.rejected, (state) => {
        state.exhibitorCustomFields.updating = false;
      })

      .addCase(reorderExhibitorFields.pending, (state, { meta }) => {
        state.exhibitorCustomFields.loading = true;
        state.exhibitorCustomFields.exhibitorFields = orderBy(
          state.exhibitorCustomFields.exhibitorFields.map((item) => ({
            ...item,
            order_number: Number(meta.arg.body.fields.find((field) => field.id === item.id)?.order_number),
          })),
          'order_number',
          ['asc'],
        );
      })
      .addCase(reorderExhibitorFields.rejected, (state) => {
        state.exhibitorCustomFields.loading = false;
      })
      .addCase(reorderExhibitorFields.fulfilled, (state, { payload }) => {
        state.exhibitorCustomFields.loading = false;
        state.exhibitorCustomFields.exhibitorFields = orderBy(payload.data, 'order_number', ['asc']);
      });
  },
});

export const eventsReducer = eventsSlice.reducer;
export const { setSelectedEvent, setSelectedEventField, setSelectedExhibitorField, setSelectedAgenda, setSelectedExhibitor } = eventsSlice.actions;
