import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Select, Switch, Typography } from 'antd';

import { SelectBrand } from '@aduvi/components/Entity/components/SelectBrand/SelectBrand';
import { LEAD_STATUS_OPTIONS } from '@aduvi/constants';

export const FormSettings = () => {
  const { t: translate } = useTranslation();
  const [isLeadStatusVisible, setLeadStatusVisible] = useState(false);

  return (
    <>
      <Typography.Text type='secondary'>{translate('forms.forms.formSettings')}</Typography.Text>
      <Row justify={'space-between'} className='mt-15'>
        <Typography.Text className='mt-5'>{translate('forms.forms.saveIncomplete')}</Typography.Text>
        <Form.Item name='form_data_lead' valuePropName='checked'>
          <Switch onChange={(checked) => setLeadStatusVisible(checked)} />
        </Form.Item>
      </Row>
      {isLeadStatusVisible && (
        <Row justify='end' className='mt-0'>
          <Col>
            <Form.Item name='lead_status' label={translate('forms.forms.setLeadStatusTo')}>
              <Select options={LEAD_STATUS_OPTIONS} style={{ width: '200px' }} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item name='brand_id' label={translate('forms.forms.brand')}>
        <SelectBrand className='w-full' />
      </Form.Item>
    </>
  );
};
