import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  EPaymentGateways,
  IConnectPaymentGatewaySettingsState,
  IPaymentGatewaySetting,
  IUpdatePaymentGatewaySettings,
} from '@aduvi/types/payments-method';

import * as PaymentGatewaySettings from 'store/services/payment-gateway-settings.service';

const initialState: IConnectPaymentGatewaySettingsState = {
  data: [],
  connecting: false,
  disconnecting: false,
  loading: false,
  editing: false,
  paymentIntent: {
    loading: false,
    client_secret: '',
  },
};

export const getAllPaymentGatewaySettings = createAsyncThunk(
  'settings/get-all-payment-gateway-settings',
  async (businessId: string, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.getAllPaymentGatewaySettings(businessId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const connectPaymentGatewaySettings = createAsyncThunk(
  'settings/connect-payment-gateway-settings',
  async (payload: IPaymentGatewaySetting, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.connectPaymentGatewaySettings(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const disconnectPaymentGatewaySettings = createAsyncThunk(
  'settings/disconnect-payment-gateway-settings',
  async (params: { businessId: string; id: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.disconnectPaymentGatewaySettings(params.businessId, params.id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePaymentGatewaySettings = createAsyncThunk(
  'settings/update-payment-gateway-settings',
  async (params: { businessId: string; data: IUpdatePaymentGatewaySettings }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.updatePaymentGatewaySettings(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const stripeAuthorizationCodeCallback = createAsyncThunk(
  'stripe/authorization-code-callback',
  async (params: { business_id: string; code: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.stripeAuthorizationCodeCallback(params.business_id, params.code);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const squareAuthorizationCodeCallback = createAsyncThunk(
  'square/authorization-code-callback',
  async (params: { business_id: string; code: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.squareAuthorizationCodeCallback(params.business_id, params.code);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const squareCreatePayment = createAsyncThunk(
  'square/authorization-code-callback',
  async (params: { business_id: string; amount: number; sourceId: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.squareCreatePayment(params.business_id, params.amount, params.sourceId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const stripePaymentIntent = createAsyncThunk(
  'stripe/payment-intent',
  async (params: { business_id: string; amount: number }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.stripePaymentIntent(params.business_id, params.amount);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const paymentTransaction = createAsyncThunk(
  'stripe/payment-transaction',
  async (payload: { business_id: string; quote_id: string; payment_id: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.paymentTransaction(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const paypalCallback = createAsyncThunk('paypal/callback', async (params: { business_id: string }, { rejectWithValue }) => {
  try {
    return await PaymentGatewaySettings.paypalCallback(params.business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const paypalCreateOrder = createAsyncThunk(
  'paypal/create-order',
  async (params: { business_id: string; amount: number }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.paypalCreateOrder(params.business_id, params.amount);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const paypalCompleteOrder = createAsyncThunk(
  'paypal/complete-order',
  async (params: { business_id: string; order_id: string }, { rejectWithValue }) => {
    try {
      return await PaymentGatewaySettings.paypalCompleteOrder(params.business_id, params.order_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const paymentGatewaySettingsSlice = createSlice({
  name: 'payment-gateway-settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPaymentGatewaySettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPaymentGatewaySettings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.data;
      })
      .addCase(getAllPaymentGatewaySettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(connectPaymentGatewaySettings.pending, (state) => {
        state.connecting = true;
      })
      .addCase(stripePaymentIntent.pending, (state) => {
        state.paymentIntent.loading = true;
      })
      .addCase(stripePaymentIntent.fulfilled, (state, { payload }) => {
        state.paymentIntent.loading = false;
        state.paymentIntent.client_secret = payload.data.client_secret;
      })
      .addCase(stripePaymentIntent.rejected, (state) => {
        state.paymentIntent.loading = false;
      })
      .addCase(connectPaymentGatewaySettings.fulfilled, (state, { payload }) => {
        state.connecting = false;
        state.data = [...state.data, payload.data.payment_setting || payload.data];

        if ([EPaymentGateways.STRIPE, EPaymentGateways.SQUARE].includes(payload.data?.payment_setting?.name)) {
          window.location.replace(payload.data.onboarding_url as string);
        }
      })
      .addCase(connectPaymentGatewaySettings.rejected, (state) => {
        state.connecting = false;
      })
      .addCase(disconnectPaymentGatewaySettings.pending, (state) => {
        state.disconnecting = true;
      })
      .addCase(disconnectPaymentGatewaySettings.fulfilled, (state, { payload, meta }) => {
        state.disconnecting = false;
        state.data = state.data.map((item) => (item.id === meta.arg.id ? payload.data : item));
      })
      .addCase(disconnectPaymentGatewaySettings.rejected, (state) => {
        state.disconnecting = false;
      })
      .addCase(updatePaymentGatewaySettings.pending, (state) => {
        state.editing = true;
      })
      .addCase(updatePaymentGatewaySettings.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.data = state.data.map((item) => (item.id === payload.data.id ? payload.data : item));
      })
      .addCase(updatePaymentGatewaySettings.rejected, (state) => {
        state.editing = false;
      })
      .addCase(paypalCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(paypalCallback.fulfilled, (state, { payload }) => {
        state.data = [...state.data, payload.data];
        state.loading = false;
      })
      .addCase(paypalCallback.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const paymentReducer = paymentGatewaySettingsSlice.reducer;
