import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Button, Col, Form, Row, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './FormsBuilder.scss';

import { Design } from './tabs/design/Design';

export const FormsBuilder = () => {
  const [form] = useForm();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('1');

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: translate('widgets.tabs.design'),
    },
    {
      key: '2',
      label: translate('widgets.tabs.configure'),
    },
    {
      key: '3',
      label: translate('widgets.tabs.embed'),
    },
  ];

  const onActiveTabChange = (tabKey: string) => {
    setSelectedTab(tabKey);
  };

  const activeTab: Record<string, ReactNode> = {
    '1': <Design form={form} />,
    // '2': (
    //   <Col className='flex-center mt-30'>
    //     <Configure form={form} />
    //   </Col>
    // ),
    // '3': (
    //   <Col className='flex-center mt-30'>
    //     <Embed form={form} />
    //   </Col>
    // ),
  };

  return (
    <Form form={form} layout='vertical' requiredMark={false}>
      <Col span={24} className='widget-forms-wrapper'>
        <Col span={24} className='header'>
          <Row justify={'space-between'} className='p-15'>
            <Breadcrumb
              className='ml-10'
              items={[
                { title: translate('widgets.title'), onClick: () => navigate(`/widgets/forms`), className: 'cursor-pointer' },
                { title: translate('widgets.forms'), onClick: () => navigate(`/widgets/forms-configure`), className: 'cursor-pointer' },
              ]}
            />
            <Row justify={'space-around'}>
              <Button onClick={/* TO DO - Implement function*/ () => {}} type='primary'>
                {translate('buttons.save')}
              </Button>
            </Row>
          </Row>
          <Col>
            <Tabs defaultActiveKey='1' activeKey={selectedTab} className='flex-center' items={tabs} onChange={onActiveTabChange} />
          </Col>
        </Col>
        <Col span={24}>{activeTab[selectedTab]}</Col>
      </Col>
    </Form>
  );
};
