import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import axios from '@aduvi/config/axios';
import { SLICE_TAG_TYPES } from '@aduvi/constants';

const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url,
        method,
        data,
        params,
      });
      return { data: result };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error,
      };
    }
  };

export const apiSlice = createApi({
  reducerPath: 'api', // optional
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(SLICE_TAG_TYPES),
  endpoints: () => ({}),
  keepUnusedDataFor: 300,
});
