import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'views/assets/events/@components/map/Map';

import { Col, Form, Input, InputRef, Row } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

interface IAddressFieldProps {
  fieldId: string;
  index: number;
  form: FormInstance;
}

interface IUserLocationProps {
  lat?: number;
  lng?: number;
}

export const AddressField = ({ index, fieldId, form }: IAddressFieldProps) => {
  const { t: translate } = useTranslation();
  const nameInputRef = useRef<InputRef>(null);
  const [eventDestination, setEventDestination] = useState<IUserLocationProps>({ lat: undefined, lng: undefined });
  const [route, setRoute] = useState<string>('');

  useEffect(() => {
    if (window.google && nameInputRef.current?.input) {
      const autoComplete = new window.google.maps.places.Autocomplete(nameInputRef.current.input);
      const geocoder = new window.google.maps.Geocoder();

      const setAutocompletePlace = (place: google.maps.places.PlaceResult) => {
        if (!place) return;

        const province = place.address_components?.find((item) => item.types.includes('administrative_area_level_1'))?.long_name;
        const zip_code = place.address_components?.find((item) => item.types.includes('postal_code'))?.long_name;
        const country = place.formatted_address?.split(',').pop();
        const city = place.address_components?.find((item) => item.types.includes('locality'))?.long_name;
        const street_one = place.address_components?.find((item) => item.types.includes('route'))?.long_name;

        const currentStreetOne = form.getFieldValue(['custom_fields', index, fieldId, 'street_one']);
        const currentStreetTwo = form.getFieldValue(['custom_fields', index, fieldId, 'street_two']);

        if ((place.name && currentStreetOne !== place.name) || currentStreetTwo !== place.formatted_address) {
          form.setFieldValue(['custom_fields', index, fieldId], {
            street_one: place.name || street_one,
            street_two: place.formatted_address,
            city,
            country,
            province,
            zip_code,
          });

          setEventDestination({ lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() });
          setRoute(place.formatted_address!);
        }
      };

      const initialAddress = form.getFieldValue(['custom_fields', index, fieldId, 'street_two']);
      if (initialAddress) {
        geocoder.geocode({ address: initialAddress }, (results, status) => {
          if (status === 'OK' && results && results[0]) {
            setAutocompletePlace(results[0]);
            setEventDestination({ lat: results[0].geometry?.location?.lat(), lng: results[0].geometry?.location?.lng() });
            setRoute(results[0].formatted_address!);
          }
        });
      }

      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        setEventDestination({ lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() });
        setRoute(place.formatted_address!);
        setAutocompletePlace(place);
      });
    }
  }, [form, index, fieldId, form.getFieldValue(['custom_fields', index, fieldId, 'street_two'])]);

  return (
    <Col className='input-column' span={24}>
      <Row gutter={16} align={'middle'} style={{ height: '80%' }}>
        <Col span={12}>
          <Form.Item name={['custom_fields', index, fieldId, 'street_one']} className='mb-10'>
            <Input ref={nameInputRef} placeholder={translate('components.customFieldsDrawer.address.name')} />
          </Form.Item>
          <Form.Item name={['custom_fields', index, fieldId, 'street_two']} className='mb-10'>
            <Input placeholder={translate('components.customFieldsDrawer.address.streetTwo')} />
          </Form.Item>
          <Form.Item name={['custom_fields', index, fieldId, 'city']} className='mb-10'>
            <Input placeholder={translate('components.customFieldsDrawer.address.city')} />
          </Form.Item>
          <Form.Item name={['custom_fields', index, fieldId, 'country']} className='mb-10'>
            <Input placeholder={translate('components.customFieldsDrawer.address.country')} />
          </Form.Item>
          <Form.Item name={['custom_fields', index, fieldId, 'province']} className='mb-10'>
            <Input placeholder={translate('components.customFieldsDrawer.address.province')} />
          </Form.Item>
          <Form.Item name={['custom_fields', index, fieldId, 'zip_code']} className='mb-10'>
            <Input placeholder={translate('components.customFieldsDrawer.address.zipCode')} />
          </Form.Item>
        </Col>
        <Col span={12} className='h-full'>
          <Map eventDestination={eventDestination} route={route} />
        </Col>
      </Row>
    </Col>
  );
};
