import {
  EManagementFieldListType,
  EManagementFieldType,
  EPersonalizedViewOrigin,
  IEntityCustomFields,
  IEntityWithCustomFields,
  IFormDataToPayload,
  IManagementField,
  IPersonalizedView,
  IUpdateCustomFieldPayload,
} from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

class CustomAndManagementFieldsAdjuster {
  private readonly origin: EPersonalizedViewOrigin;
  private managementFields: IManagementField[] = [];

  constructor(origin: EPersonalizedViewOrigin) {
    this.origin = origin;

    this.initializeManagementFields();
  }

  mapFormDataToPayload(customFields: { [key: string]: string | string[] }[], entityCustomFields?: IEntityCustomFields[]) {
    return customFields
      ?.filter((item) => item && Object.keys(item).length)
      ?.map((field) => {
        const [key, value] = Object.entries(field)[0];
        return {
          id: entityCustomFields?.find((field) => field?.field?.id === key)?.id || null,
          business_field_id: key,
          ...this.getEntityValue(this.managementFields?.find((item: IManagementField) => item.id === key)?.field_type, value),
        };
      })
      ?.filter((item) => this.filterNullableFields(item as IUpdateCustomFieldPayload));
  }

  mapFieldsToForm(data: IEntityWithCustomFields, managementFields?: IManagementField[]) {
    const _managementFields = managementFields || this.managementFields;
    return _managementFields?.map((managementField) => {
      const customField = [...(data?.custom_fields || [])]?.find((field) => field.field.id === managementField?.id);

      return { [managementField.id]: this.getFormFieldValue(data, managementField, customField) };
    });
  }

  pickGlobalManagementFields(pickedFieldTypes: EManagementFieldType[]) {
    const result: IManagementField[] = [];

    // This is implemented in this manner because we need to order according picketFieldTypes
    pickedFieldTypes.forEach((picketField) => {
      this.managementFields.forEach((field) => {
        if (picketField === field.field_type && field.is_global) result.push(field);
      });
    });

    return result;
  }

  omitGlobalManagementFields(omittedFields: EManagementFieldType[]) {
    return this.managementFields.filter((field) => field.is_global).filter((field) => !omittedFields?.includes(field.field_type));
  }

  getAllNonGlobalFields() {
    return this.managementFields.filter((field) => !field.is_global);
  }

  public globalEmailValue(customFields: IFormDataToPayload[]) {
    const managementFieldId = this.managementFields?.find((field) => field.is_global && field.field_type === EManagementFieldType.EMAIL)?.id;
    return customFields?.find((field) => field?.business_field_id === managementFieldId)?.value;
  }

  private initializeManagementFields() {
    if (this.origin === EPersonalizedViewOrigin.EVENTS) {
      const { eventFields: data } = useAppSelector((state) => state.events.eventCustomFields);
      this.managementFields = [...data];
    }
  }

  private getFormFieldValue(data: IEntityWithCustomFields, managementField: IManagementField, customField: IEntityCustomFields | undefined) {
    if (managementField.field_type === EManagementFieldType.USERS && data.users) {
      return data.users.map((item) => item.id);
    }
    if (managementField.field_type === EManagementFieldType.ADDRESS) {
      return managementField.options.map((item) => {
        return { [item.id]: customField?.selected_options.find((option) => option.id === item.id)?.additional_data.custom_value || undefined };
      });
    }
    return customField?.selected_options?.length && customField.selected_options?.length > 0
      ? customField?.selected_options.map((option) => option?.id)
      : customField?.value;
  }

  private getEntityValue(fieldType: EManagementFieldType | undefined, value: string | string[]) {
    if (fieldType === EManagementFieldType.USERS)
      return {
        users: Array.isArray(value) ? value : [value],
      };
    if (fieldType === EManagementFieldType.CONTACT)
      return {
        contacts: Array.isArray(value) ? value : [value],
      };
    if (fieldType === EManagementFieldType.VENUE)
      return {
        venues: Array.isArray(value) ? value : [value],
      };
    if (fieldType === EManagementFieldType.ADDRESS)
      return {
        address_options: Array.isArray(value)
          ? value
              .map((item) => {
                if (!item) return undefined;
                const [key, value] = Object.entries(item)[0];
                return {
                  selected_option_id: key,
                  custom_value: value,
                };
              })
              .filter((item) => item?.custom_value)
          : undefined,
      };

    return {
      selected_options: Array.isArray(value) ? value : undefined,
      value: (Array.isArray(value) ? null : value?.toString()) || null,
    };
  }

  public onEntityPipelineDragEnd(hoveredPipelineFilterId: string, entity: IEntityWithCustomFields, selectedView: IPersonalizedView | undefined) {
    const data = this.mapFieldsToForm(entity, [...this.omitGlobalManagementFields([]), ...this.getAllNonGlobalFields()]);

    const isAllowedMultiple =
      [...entity.custom_fields].find((item) => item.business_field_id === selectedView?.status_field)?.field.list_type ===
      EManagementFieldListType.MULTIPLE_CHOICE;

    const customFields = data.map((item) => {
      if (item[String(selectedView?.status_field)]) {
        const pipelineStatusesIds = [...(item[String(selectedView?.status_field)] as Array<string>), hoveredPipelineFilterId];
        return { [String(selectedView?.status_field)]: isAllowedMultiple ? Array.from(new Set(pipelineStatusesIds)) : [hoveredPipelineFilterId] };
      }
      return item;
    });

    const customFieldsReformed = this.mapFormDataToPayload(customFields as { [key: string]: string | string[] }[], entity.custom_fields);

    return customFieldsReformed;
  }

  private filterNullableFields(item: IUpdateCustomFieldPayload) {
    return (
      item?.value !== undefined ||
      item?.selected_options?.length ||
      item.users?.filter((item) => item).length ||
      item.venues?.filter((item) => item).length ||
      item.contacts?.filter((item) => item).length ||
      item.teams?.filter((item) => item).length ||
      item.address_options?.filter((item) => item).length
    );
  }
}

export default CustomAndManagementFieldsAdjuster;
