import { Select, SelectProps } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';

import { useGetBusinessTeamsQuery } from 'store/rtk-slice/user-slice';

export const SelectTeam = (props: SelectProps) => {
  const selectedBusiness = useBusiness();

  const hasPermissionToViewTeams = useUserPermissionCheck(PERMISSIONS.TEAMS.VIEW);

  const { data, isLoading } = useGetBusinessTeamsQuery(
    { business_id: selectedBusiness?.id as string },
    { skip: !selectedBusiness?.id || !hasPermissionToViewTeams },
  );

  return (
    <Select
      {...props}
      showSearch
      disabled={isLoading}
      loading={isLoading}
      filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
      {data?.data?.map((team, index) => (
        <Select.Option key={index} value={team.id}>
          {team.name}
        </Select.Option>
      ))}
    </Select>
  );
};
