import { Row, Skeleton } from 'antd';

export const Header = () => {
  return (
    <>
      <Row align={'middle'} className='w-full p-20' justify={'space-between'} style={{ backgroundColor: '#f0f0f0' }}>
        <Skeleton.Avatar active={true} shape={'square'} />
        <Row align='middle' justify={'space-between'} style={{ gap: '12px' }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton.Avatar key={index} active shape={'circle'} />
          ))}
        </Row>
      </Row>
    </>
  );
};
