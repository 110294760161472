import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { IInvoiceSettingsPayload, IInvoiceSettingsResponse } from '@aduvi/types/invoice';

export const getInvoice = async (business_id: string): Promise<IResponse<IInvoiceSettingsResponse>> => {
  return await axios.get(`/businesses/${business_id}/invoice/settings`);
};

export const createInvoiceSettings = async (payload: IInvoiceSettingsPayload): Promise<IResponse<IInvoiceSettingsResponse>> => {
  return await axios.post(`/businesses/${payload.business_id}/invoice/settings`, payload);
};

export const updateInvoiceSettings = async (
  payload: IInvoiceSettingsPayload,
  invoiceSettingsId: string,
): Promise<IResponse<IInvoiceSettingsResponse>> => {
  return await axios.put(`/businesses/${payload.business_id}/invoice/settings/${invoiceSettingsId}`, payload);
};
