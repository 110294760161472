import { Alert } from 'antd';
import Link from 'antd/es/typography/Link';

interface IPRops {
  message?: string;
  description: string;
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
  linkText?: string;
}

export const TroubleshooterAlert = ({ message, description, linkText, type }: IPRops) => {
  return (
    <Alert
      className='alert-content mb-10'
      message={message}
      description={
        <span>
          {description} &nbsp;
          <Link href=''>{linkText}</Link>
        </span>
      }
      type={type}
      showIcon
    />
  );
};
