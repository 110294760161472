import { IApp } from './common';

export enum EPaymentPeriod {
  ANNUAL = 'annually',
  MONTHLY = 'monthly',
}

export interface IBillingDetails {
  id: string;
  first_name: string;
  last_name: string;
  company_name: string;
  address_name: string;
  business_user_id: string;
  city_name: string;
  vat_id: string;
  country_id: string;
}

export interface IChangePlanResponse {
  id: string;
  business_id: string;
  partner_pricing_id: string;
  previous_partner_pricing_id: string;
  status: string;
  updated_at: string;
  created_at: string;
  plan: IPricing;
}

export interface IBillingState {
  selectedPlan: {
    data?: IPricing;
    businessId?: string;
  };
  billingDetails?: IBillingDetails;
  cards?: ICardsData[];
  businessCards?: ICardsData[];
  default_card?: string;
  defaultBusinessCard?: string;
  loadingCards: boolean;
  creatingCard: boolean;
  deletingCard: boolean;
  editingCard: boolean;
  loading: boolean;
  creating: boolean;
  editing: boolean;
}

export interface IPricing {
  id: string;
  is_active: boolean;
  plan_name: string;
  base_price_monthly: number;
  base_price_yearly: number;
  included_users: number;
  price_per_additional_user_monthly: number;
  price_per_additional_user_yearly: number;
  card_transaction_fee: number;
  remove_branding: boolean;
  apps: IApp[];
}

export interface ICreateCardPayload {
  user_id: string;
  business_id: string;
  stripe_token: string;
}

export interface ICardsData {
  id: string;
  billing_details: {
    address: {
      city: string;
      country: string;
      line1: string;
      line2: string;
      postal_code: string;
      state: string;
    };
    email: string;
    name: string;
    phone: string;
  };
  card: {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    last4: string;
  };
}

export interface ICards {
  default_card: string;
  cards: {
    data: ICardsData[];
  };
}

export interface ICreateBusinessCardPayload {
  business_id: string;
  stripe_token: string | null;
}
