import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Row, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useCountdownTimer } from '@aduvi/hooks/useCountDownTimer';
import { ELoadingState } from '@aduvi/types';

import { resendVerificationCode, updateUser2Fa } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Security = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { t: translate } = useTranslation();
  const { timer, setTimer } = useCountdownTimer(60);

  const [show2FaVerificationInput, setShow2FaVerificationInput] = useState(false);
  const [allowResendVerificationCode, setAllowResendVerificationCode] = useState(true);

  const { user, resendVerificationCodeState } = useAppSelector((state) => state.auth);

  const onSave = ({ otp }: { otp?: string }) => {
    if (!user?.user?.id) return;
    dispatch(updateUser2Fa({ id: user?.user?.id, otp }))
      .unwrap()
      .then((res) => {
        setShow2FaVerificationInput(!res?.data);
        form.resetFields();
      })
      .catch(() => {});
  };

  const onResendVerificationCode = () => {
    if (user?.user?.email) {
      dispatch(resendVerificationCode({ email: user.user.email }))
        .unwrap()
        .then(() => {
          setTimer(60);
          setAllowResendVerificationCode(false);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (timer === 0) {
      setAllowResendVerificationCode(true);
    }
  }, [show2FaVerificationInput, timer]);

  return (
    <Form layout='vertical' requiredMark={false} form={form} onFinish={onSave}>
      <Form.Item name='is_2fa_enabled' label={translate('profile.security.enableAuthentication')}>
        <Switch loading={user?.updating} checked={user?.user?.is_2fa_enabled} onChange={() => onSave({})} />
      </Form.Item>

      {show2FaVerificationInput && (
        <>
          <Form.Item
            className='mb-5'
            name='otp'
            label={translate('profile.security.verificationCodeLabel')}
            rules={[{ required: true, message: translate('profile.security.verificationCodeValidation') }]}>
            <Input className='verification-input' />
          </Form.Item>

          {!user?.updating && (
            <Row justify='start'>
              {allowResendVerificationCode ? (
                <a onClick={onResendVerificationCode} className='cursor-pointer'>
                  {resendVerificationCodeState === ELoadingState.LOADING
                    ? translate('profile.security.resending')
                    : translate('profile.security.resendVerificationCode')}
                </a>
              ) : (
                <a style={{ cursor: 'not-allowed' }}>{translate('profile.security.resendVerificationCodeWithTimer', { timer })}</a>
              )}
            </Row>
          )}

          <Row justify='center'>
            <Button loading={user?.updating} disabled={user?.updating} htmlType='submit' type='primary' className='text-center'>
              {translate('profile.security.save')}
            </Button>
          </Row>
        </>
      )}
    </Form>
  );
};
