import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { DragEndEvent } from '@dnd-kit/core';
import { closestCenter, DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Col, Row, Skeleton, Space, Tabs } from 'antd';
import './PersonalizedViewTabs.scss';

import { Title } from '@aduvi/components/Title';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EPersonalizedView, EPersonalizedViewOrigin, ICustomFieldViewStyle } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { resetEntities } from 'store/features/entity-slice';
import {
  getPersonalizedView,
  reorderPersonalizedView,
  resetState,
  setSelectedPersonalizedView,
  setShowPersonalizedViewDrawer,
} from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { UpsertPersonalizedViewDrawer } from './UpsertPersonalizedViewDrawer/UpsertPersonalizedViewDrawer';
import { TabsDropDown } from './TabsDropDown';

interface IProps {
  origin: EPersonalizedViewOrigin;
  style: ICustomFieldViewStyle[];
  entityType?: IEntityType;
}
interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-node-key': string;
  cursor: string;
}

const DraggableTabNode = ({ cursor, ...props }: DraggableTabPaneProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props['data-node-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor,
  };

  return React.cloneElement(props.children as React.ReactElement, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};

export const PersonalizedViewTabs = ({ entityType, origin, style }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

  const [newViewType, setNewViewType] = useState('');
  const [cursor, setCursor] = useState('pointer');

  const { personalizedViews, selectedViewId, showDrawer, loading } = useAppSelector((state) => state.personalizedViews);
  const [searchParams, setSearchParams] = useSearchParams();

  const hasPermissionToViewPersonalizedViews = useUserPermissionCheck(PERMISSIONS.PERSONALIZED_VIEWS.VIEW);

  const viewId = searchParams.get('viewId');
  const formView = useMemo(
    () => personalizedViews?.find((view) => view.view_display === EPersonalizedView.FORMS),
    [viewId, personalizedViews?.length],
  );
  const selectedView = useMemo(() => personalizedViews?.find((view) => view.id === viewId), [viewId, personalizedViews?.length]);

  const onDataDragEnd = ({ active, over }: DragEndEvent) => {
    setCursor('pointer');

    if (!selectedBusiness?.id) return;
    if (active.id !== over?.id) {
      const activeIndex = personalizedViews?.findIndex((view) => view?.id === active?.id);
      const overIndex = personalizedViews?.findIndex((view) => view?.id === over?.id);
      const updatedData = arrayMove(personalizedViews, activeIndex, overIndex).map((item, index) => ({
        id: item?.id,
        weight: index,
      }));
      dispatch(reorderPersonalizedView({ business_id: selectedBusiness?.id, personalized_views: updatedData, origin }));
    }
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !hasPermissionToViewPersonalizedViews) return;
    dispatch(getPersonalizedView({ businessId: selectedBusiness?.id, origin }));

    return () => {
      dispatch(resetState());
    };
  }, [selectedBusiness?.id, origin]);

  useEffect(() => {
    if (!viewId && selectedViewId) {
      setSearchParams({ viewId: selectedViewId });
    }
  }, [selectedViewId]);

  useEffect(() => {
    if (!viewId || personalizedViews?.length === 0) return;
    const targetView = selectedView || (viewId === 'form' && formView);
    if (targetView) {
      dispatch(setSelectedPersonalizedView(targetView?.id));
      if (!selectedView) {
        setSearchParams({ viewId: targetView?.id });
      }
    }
  }, [viewId, personalizedViews?.length]);

  return (
    <Row align='middle' className='w-full personalized-view-wrapper'>
      <Row align='middle' className='tabs-mobile-view w-full' gutter={8}>
        <Col>
          <Title className='aduvi-title mb-0'>{translate(`personalizedView.${origin}`)}</Title>
        </Col>
        {loading ? (
          <Space className='ml-5' style={{ height: '55px' }}>
            <Skeleton.Button active={true} size={'default'} shape={'default'} />
            <Skeleton.Input active={true} size={'default'} />
            <Skeleton.Button active={true} size={'default'} shape={'default'} />
          </Space>
        ) : (
          <Col md={12} className='personalized-view-tabs-wrapper'>
            <Tabs
              hideAdd
              className='personalized-view-tabs'
              type='editable-card'
              activeKey={selectedViewId}
              items={personalizedViews?.map(({ name, id }) => ({ label: name, key: id, closable: false }))}
              onChange={(e) => {
                dispatch(setSelectedPersonalizedView(e));
                dispatch(resetEntities());
                setSearchParams({ viewId: e });
              }}
              tabBarExtraContent={<TabsDropDown entityType={entityType} setNewViewType={setNewViewType} origin={origin} />}
              renderTabBar={(tabBarProps, DefaultTabBar) => (
                <DndContext sensors={[sensor]} onDragEnd={onDataDragEnd} collisionDetection={closestCenter} onDragStart={() => setCursor('move')}>
                  <SortableContext items={personalizedViews?.map(({ id }) => id)} strategy={horizontalListSortingStrategy}>
                    <DefaultTabBar {...tabBarProps}>
                      {(node) => (
                        <DraggableTabNode {...node.props} key={node.key} cursor={cursor}>
                          {node}
                        </DraggableTabNode>
                      )}
                    </DefaultTabBar>
                  </SortableContext>
                </DndContext>
              )}
            />
          </Col>
        )}
      </Row>
      {showDrawer && newViewType !== EPersonalizedView.FORMS && (
        <UpsertPersonalizedViewDrawer
          open={showDrawer}
          entityType={entityType}
          origin={origin}
          isNew={!!newViewType}
          newViewType={newViewType as EPersonalizedView}
          style={style}
          onClose={() => {
            dispatch(setShowPersonalizedViewDrawer(false));
            setNewViewType('');
          }}
        />
      )}
    </Row>
  );
};
