import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Drawer, Form, Space, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { ContentBoxEditor } from '@aduvi/components/ContentBoxEditor/ContentBoxEditor';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';

import { editProposal, getBuilderBlocks, getProposalById, setCurrentProposalState, setSelectedProposal } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Activity } from './tabs/Activity';
import { Configure } from './tabs/Configure';

export const ProposalEditorModal = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const {
    proposals: { currentProposalState, selectedProposal, loading },
    builder_blocks: { loading: loadingBuilderBlocks },
  } = useAppSelector((state) => state.proposals);

  const [showDrawer, setShowDrawer] = useState(false);
  const [settingsTabKey, setSettingsTabKey] = useState('configure');

  const tabItems = [
    {
      key: 'configure',
      label: 'Configure',
      children: <Configure />,
    },
    {
      key: 'activity',
      label: 'Activity',
      children: <Activity />,
    },
  ];

  const onSave = (html: string) => {
    if (!selectedBusiness?.id) return;

    if (selectedProposal?.id) {
      dispatch(
        editProposal({
          proposalId: selectedProposal?.id,
          payload: {
            accepted: false,
            accepted_date: '',
            builder_template_id: selectedProposal?.builder_template_id,
            business_id: selectedBusiness?.id,
            file_reference: {
              file_name: '',
              file_url: html,
            },
            page_title: form.getFieldValue('page_title'),
            entity_type_id: selectedProposal?.entity_type_id,
            entity_id: selectedProposal?.entity_id,
          },
        }),
      );
      return;
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const proposalId = queryParams.get('proposalId');

  useEffect(() => {
    if (!selectedBusiness?.id || !proposalId) return;
    dispatch(getProposalById({ businessId: selectedBusiness?.id, proposalId }))
      .unwrap()
      .then((response) => {
        form.setFieldsValue({
          ...response.data,
        });
      })
      .catch(() => {});
  }, [location, selectedBusiness?.id]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getBuilderBlocks({ business_id: selectedBusiness?.id }))
      .unwrap()
      .then((data) => localStorage.setItem('my_builder_blocks', JSON.stringify(data.data)));
  }, [dispatch, selectedBusiness?.id]);

  if (loading || loadingBuilderBlocks || Boolean(proposalId && !selectedProposal?.file_reference?.file_url))
    return <Spin spinning className='w-full mt-30' size='large' />;

  return (
    <>
      <ContentBoxEditor
        onBack={() => {
          dispatch(setCurrentProposalState({ html: '', mainCss: '', sectionCss: '', templateId: '' }));
          dispatch(setSelectedProposal(undefined));
          navigate(-1);
        }}
        onSave={onSave}
        value={currentProposalState?.html || selectedProposal?.file_reference.file_url}
        onTabChange={setSettingsTabKey}
        onDrawerOpen={() => setShowDrawer(true)}
        isBuilderBlock={false}
        hideActivity={false}
      />
      <Drawer
        title={'Settings'}
        open={showDrawer}
        width={720}
        styles={{ body: { ...DRAWER_BODY_STYLE, background: '#F9FCFF' } }}
        onClose={() => setShowDrawer(false)}
        extra={
          <Space>
            <Button icon={<CloseOutlined />} type='text' onClick={() => setShowDrawer(false)} />
          </Space>
        }>
        <Spin spinning={false}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Tabs activeKey={settingsTabKey} items={tabItems} onChange={setSettingsTabKey} />
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};
