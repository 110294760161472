import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';

import { Col, Row } from 'antd';
import './Message.scss';

export const Message = ({ field }: { field: string }) => {
  const { t: translate } = useTranslation();

  return (
    <Row className='message-wrapper'>
      <Col span={1}>
        <CheckCircleFilled className='check-icon' />
      </Col>
      <Col span={23} className='fs-14'>
        {translate('components.customFieldsDrawer.globalFieldMessage', {
          field: translate(`components.customFieldsDrawer.type.${field?.toUpperCase()}`),
        })}
      </Col>
    </Row>
  );
};
