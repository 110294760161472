import dayjs from 'dayjs';

import { IAddressField, IDateTimeField } from './entity';
import { IEvent, IEventCustomField, IExhibitor, IExhibitorCustomField } from './event';
import { IUploadFile } from './fields';
import { IManagementField } from './management-fields';

export enum Entities {
  VENUES = 'venues',
  ORDERS = 'orders',
  JOBS = 'jobs',
  CONTACTS = 'contacts',
  EVENTS = 'events',
  EXHIBITORS = 'exhibitors',
}

export enum EDefaultDateValue {
  NOW = 'NOW',
  NOW_1_DAY = 'NOW_1_DAY',
  NOW_2_DAYS = 'NOW_2_DAYS',
  NOW_3_DAYS = 'NOW_3_DAYS',
  NOW_4_DAYS = 'NOW_4_DAYS',
  NOW_5_DAYS = 'NOW_5_DAYS',
  NOW_6_DAYS = 'NOW_6_DAYS',
  NOW_1_WEEK = 'NOW_1_WEEK',
  NOW_2_WEEKS = 'NOW_2_WEEKS',
  NOW_3_WEEKS = 'NOW_3_WEEKS',
}

export interface ICustomField {
  id: string;
  value: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  selected_options: ICustomFieldSelectedOptions[];
  business_field_id: string;
  field: Omit<IManagementField, 'options'>;
}

export interface ICustomFieldSelectedOptions {
  id: string;
  name: string;
  order_number: number;
  is_default: boolean;
  option_type: string;
  color: string;
  is_archived: boolean;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  pivot: {
    custom_field_id: string;
    field_option_id: string;
    created_at: string;
    updated_at: string;
  };
  additional_data: {
    custom_field_id: string;
    field_option_id: string;
    custom_value: string;
    created_at: string;
    updated_at: string;
  };
}

export interface ICustomFieldOption {
  color: string;
  created_at: string;
  deleted_at: string;
  id: string;
  is_archived: boolean;
  is_default: boolean;
  name: string;
  option_type: string;
  order_number: number;
  updated_at: string;
  value: string;
}

export interface IUpsertCustomField {
  id: string | null;
  value: string;
  selected_options?: Array<string>;
}

export interface IUpdateCustomFieldPayload {
  id: string;
  teams?: string[];
  users?: string[];
  contacts?: string[];
  venues?: string[];
  selected_options?: string[];
  address_options?: {
    selected_option_id: string;
  }[];
  value?: string;
}

export type IEntityCustomFields = IEventCustomField | IExhibitorCustomField;

export type IEntityWithCustomFields = IEvent | IExhibitor;

export enum EFontWeight {
  BOLD = 'bold',
  NORMAL = 'normal',
}

export enum EFontStyle {
  ITALIC = 'italic',
  NORMAL = 'normal',
}

export enum EColor {
  BLACK = 'black',
}

export enum ETextAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}
export enum EListFormat {
  COMMA = 'comma',
  NUMBERED = 'numbered',
  BULLET = 'bullet',
  BRACKETS = 'brackets',
}

export enum ENumberFormat {
  PLAIN = 'plain',
  COMMA = 'comma',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export interface IBaseCustomFieldStyle {
  id: string;
  field_id?: string[];
  name?: string;
  style: {
    fontSize: number;
    fontWeight: EFontWeight;
    fontStyle: EFontStyle;
    color: string;
    background: string;
    dateFormat: string;
    numberFormat: string;
    listFormat: string;
    maxLength: number;
    alignment: ETextAlignment;
  };
}

export interface ICustomFieldSpecificStyle {
  [key: string]: IBaseCustomFieldStyle;
}

export interface ICustomFieldViewStyle extends IBaseCustomFieldStyle {}

export interface IFormDataToPayload {
  id: string;
  business_field_id: string;
  value: string;
  address_options?: {
    selected_option_id: string;
  }[];
  selected_options?: string[];
  contacts?: string[];
  users?: string[];
  venues?: string[];
}

export interface IFileField {
  file: IUploadFile;
}

export type ICustomFieldValue = string | number | dayjs.Dayjs | IAddressField | string[] | IUploadFile | IDateTimeField;

export interface IKeyValue {
  [key: string]: ICustomFieldValue;
}

export interface IKeyValueEditedEntityCustomFields {
  [key: string]: string | number;
}
