import { useEffect, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useParams } from 'react-router-dom';
import { EnvironmentOutlined } from '@ant-design/icons';
import { countryToAlpha2 } from 'country-to-iso';

import { Card, Carousel, Col, Row, Typography } from 'antd';
import './BusinessEmbed.scss';

import { ILocation } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getLocations } from 'store/features/business-slice';
import { getCountries } from 'store/features/common-slice';
import { getPublicWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const BusinessEmbed = () => {
  const dispatch = useAppDispatch();
  const { widgetId } = useParams();

  const { selectedWidget } = useAppSelector((state) => state.widget);
  const { locations } = useAppSelector((state) => state.business);
  const { countries } = useAppSelector((state) => state.common.countries);
  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const visibleFields = selectedWidget?.widget_style[0]?.visible_fields || [];
  const selectedLayout = selectedWidget?.widget_style?.[0]?.layout || EWidgetLayout.COLUMNS;

  const columnCount = selectedWidget?.widget_style?.[0]?.columns || 3;

  const limitResults = selectedWidget?.widget_style?.[0]?.limit_results || 'show_all';
  const resultsPerPage = selectedWidget?.widget_style?.[0]?.results_per_page || 2;

  const cardNavigationType = selectedWidget?.widget_style?.[0]?.cards || EWidgetCards.MANUAL;
  const iconType = selectedWidget?.widget_style?.[0]?.icon_type || 'Pin';

  const columnSpan = useMemo(() => {
    switch (columnCount) {
      case 2:
        return 12;
      case 3:
        return 8;
      case 4:
        return 6;
      case 5:
        return [5, 4];
      default:
        return 8;
    }
  }, [columnCount]);

  const getIcon = (location: ILocation) => {
    if (iconType === 'Pin') {
      return <EnvironmentOutlined style={{ fontSize: '32px' }} />;
    } else if (iconType === 'Flag') {
      const country = countries?.find((c) => c.id === location.country_id);
      const countryCode = countryToAlpha2(country ? country.name : '');

      if (countryCode) {
        return (
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
              fontSize: '7em',
              lineHeight: '7em',
            }}
          />
        );
      } else {
        return <EnvironmentOutlined style={{ fontSize: '32px' }} />;
      }
    }
    return null;
  };

  const displayedLocations = useMemo(() => {
    if (limitResults === 'show_all') {
      return locations?.data;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return locations?.data?.slice(0, resultsPerPage);
    }
    return locations?.data;
  }, [locations?.data, limitResults, resultsPerPage]);

  useEffect(() => {
    if (!urlBusinessId) return;
    dispatch(getCountries());
    dispatch(getLocations(urlBusinessId));
  }, []);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Row className='business-design-tab-wrapper'>
      <Col span={24}>
        <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === 'animated'}>
              {displayedLocations?.map((location, index) => {
                const country = countries?.find((c) => c.id === location.country_id);

                return (
                  <div key={index} className='location-card'>
                    {getIcon(location)}
                    {visibleFields.includes('Name') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Address') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.address}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('State') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.state}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Post Code') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.post_code}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Country') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {country?.name}
                      </Typography.Paragraph>
                    )}
                  </div>
                );
              })}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]} justify='center'>
              {displayedLocations?.map((location, index) => {
                const country = countries?.find((c) => c.id === location.country_id);
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan}>
                    <div className='location-card'>
                      {getIcon(location)}
                      {visibleFields.includes('Name') && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          {location.name}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Address') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          {location.address}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('State') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          {location.state}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Post Code') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          {location.post_code}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Country') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          {country?.name}
                        </Typography.Paragraph>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            displayedLocations?.map((location, index) => {
              const country = countries?.find((c) => c.id === location.country_id);

              return (
                <Row key={index} gutter={[20, 20]} align='middle' className='location-card-row'>
                  <Col xs={24} sm={6} className='location-image'>
                    {getIcon(location)}
                  </Col>
                  <Col xs={24} sm={18}>
                    {visibleFields.includes('Name') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Address') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.address}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('State') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.state}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Post Code') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {location.post_code}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Country') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {country?.name}
                      </Typography.Paragraph>
                    )}
                  </Col>
                </Row>
              );
            })
          )}
        </Card>
      </Col>
    </Row>
  );
};
