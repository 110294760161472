import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EllipsisOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { Button, Card, Col, Dropdown, MenuProps, Popconfirm, Row, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';

import { useBusiness } from '@aduvi/hooks';

import { deleteBuilderBlock, getBuilderBlocks, setSelectedBuilderBlock } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { dummy_templates } from '../Templates/helper';

export const BuilderBlocks = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const {
    builder_blocks: { data },
  } = useAppSelector((state) => state.proposals);

  const dropdownItems = (id: string): MenuProps['items'] => [
    {
      key: '1',
      icon: <SettingOutlined />,
      label: translate('file.edit'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        dispatch(setSelectedBuilderBlock(undefined));
        navigate(`/block-builder?blockId=${id}`);
      },
    },
    {
      key: '3',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('buttons.delete')}
          description={translate('common.deleteConfirmation')}
          icon={<QuestionCircleOutlined />}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedBusiness?.id) return;
            dispatch(deleteBuilderBlock({ businessId: selectedBusiness?.id, blockId: id }));
          }}>
          {translate('file.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e?.domEvent?.stopPropagation(),
    },
  ];

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getBuilderBlocks({ business_id: selectedBusiness?.id }));
  }, [dispatch, selectedBusiness?.id]);

  return (
    <Spin spinning={false}>
      <Row gutter={[18, 18]} className='mt-30'>
        {data.map((item) => (
          <Col key={item.id} span={6} style={{ borderRadius: '5px' }}>
            <Card
              hoverable
              style={{ borderRadius: '5px' }}
              cover={
                <img
                  alt='example'
                  src={item.file_reference?.image ?? dummy_templates[0].image}
                  style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                />
              }
              actions={[
                <Dropdown menu={{ items: dropdownItems(item.id) }} trigger={['click']} key={'edit'}>
                  <Button
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                    icon={<EllipsisOutlined />}
                    type='text'
                  />
                </Dropdown>,
              ]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(setSelectedBuilderBlock(undefined));
                navigate(`/block-builder?blockId=${item.id}`);
              }}>
              <Meta title={item.block_name} />
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
