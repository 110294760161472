import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Select } from 'antd';

import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EFieldsVisibility, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';

export const StatusSelection = ({ entityType, origin }: { form: FormInstance; entityType?: IEntityType; origin: EPersonalizedViewOrigin }) => {
  const { t: translate } = useTranslation();
  const { entityTypes } = useAppSelector((state) => state.entity);

  const { fields } = useManagementFields({ entityType, origin });

  const statusFieldOptions = fields.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LISTS);

  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState(statusFieldOptions?.[0]?.id);

  const defaultStatusOptions = useMemo(() => {
    const selectedOption = statusFieldOptions.find((item) => item.id === selectedCustomFieldId);

    return selectedOption?.list_values
      ?.filter((item) => {
        if (entityTypes?.data?.JOB?.splittable) {
          if (
            selectedOption?.list_values?.some(
              (item) => [EFieldsVisibility.CONFIRMED, EFieldsVisibility.UNCONFIRMED]?.includes(item?.value_type as EFieldsVisibility),
            )
          ) {
            return [EPersonalizedViewOrigin.BOOKINGS]?.includes(EPersonalizedViewOrigin.BOOKINGS)
              ? item?.value_type === EFieldsVisibility.CONFIRMED
              : item?.value_type === EFieldsVisibility.UNCONFIRMED;
          }
        }

        return !item.is_archived;
      })
      .map((option) => ({ label: option?.value, value: option?.id }));
  }, [selectedCustomFieldId]);

  return (
    <>
      <Form.Item name='status_field' label={translate('automations.selectStatusField')}>
        <Select
          options={statusFieldOptions.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
          onChange={(value) => {
            setSelectedCustomFieldId(value);
          }}
          placeholder={translate('automations.selectStatus')}
        />
      </Form.Item>
      <Form.Item name='status_selection' label={translate('automations.selectStatus')}>
        <Select options={defaultStatusOptions} placeholder={translate('automations.selectStatus')} />
      </Form.Item>
    </>
  );
};
