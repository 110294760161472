import { IChatMessage } from './common';
import { IPartnerUser } from './user';

export interface IChannel {
  id: string;
  name: string;
  type: EChannelType;
  users: {
    id: string;
    email: string;
    partner_users: IPartnerUser[];
  }[];
  business_id: string;
}

export interface IAssignUserResponse {
  business_channel_user: {
    business_channel_id: string;
    user_id: string;
    id: string;
  };
  partnerUser: IPartnerUser;
}

export interface ICreateChannelPayload {
  business_id: string;
  name: string;
  type: EChannelType;
  user_id?: string;
}

export interface IConversationState {
  channels?: IChannel[];
  teams?: IChannel[];
  individualChannelsMap?: { [key: string]: IChannel };
  selectedChannel?: IChannel;
  selectedMessage?: IChatMessage;

  messages: {
    data: IChatMessage[];
    creating: boolean;
    loading: boolean;
    removing: boolean;
  };
  loading: boolean;
  creating: boolean;
  updating: boolean;
}

export enum EChannelType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  TEAM_GROUP = 'TEAM_GROUP',
}

export enum EPusherEvents {
  MESSAGE_SENT = 'message-sent',
  USER_ADDED_TO_CHANNEL = 'user-added-to-channel',
  CHANNEL_CREATED = 'channel-created',
  CHANNEL_EDITED = 'channel-edited',
  CHANNEL_DELETED = 'channel-deleted',
  MESSAGE_DELETED = 'message-deleted',
}
