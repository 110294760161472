import { ETicketStatus } from '@aduvi/types/ticket';

export const getStatusStyles = (status: string) => {
  switch (status) {
    case ETicketStatus.OPEN:
      return { backgroundColor: '#E6F7FF', color: '#1890FF' };
    case ETicketStatus.RESOLVED:
      return { backgroundColor: '#F6FFED', color: '#52C41A' };
    default:
      return { backgroundColor: '#FFFBE6', color: '#FAAD14' };
  }
};

export const extractFileNameFromUrl = (url: string) => {
  const parts = url?.split('/uploads/');
  if (parts?.length === 2) {
    return parts[1];
  }
  return '';
};
