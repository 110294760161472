import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  FolderOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { DataNode } from 'rc-tree/lib/interface';

import type { CollapseProps, MenuProps } from 'antd';
import { Col, Collapse, Dropdown, Input, Popconfirm, Row, Space, Spin, Tree, Typography } from 'antd';
import './FileMenu.scss';

import { Button } from '@aduvi/components/Button/Button';
import { useActiveTheme, useBusinessId, useDebounce, useIsMobile } from '@aduvi/hooks';
import { IFolder } from '@aduvi/types/file';
import { findFolderById, generateTreeData } from '@aduvi/utils/files-helper';

import { deleteFolder, getFolders, getSharedFiles, getSharedFolders, setSelectedFile, setSelectedFolder } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CreateFolderModal } from '../create-folder-modal/CreateFolderModal';
import { UpsertFileDrawer } from '../file-drawer/UpsertFileDrawer';
import { GetMoreStorage } from '../get-more-storage/GetMoreStorage';
import { ShareModal } from '../share-modal/ShareModal';

interface IProps {
  onClose?: () => void;
  readOnly: boolean;
  onSearch?: (searchTerm: string) => void;
  filteredFolders?: IFolder[];
}

export const FileMenu = ({ onClose, readOnly, onSearch, filteredFolders }: IProps) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const isMobile = useIsMobile();
  const activeTheme = useActiveTheme();

  const { t: translate } = useTranslation();
  const { folders, selectedFolder, sharedFolders } = useAppSelector((state) => state.file);

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showFileDrawer, setShowFileDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [showShareModal, setShowShareModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchKey = useDebounce(searchTerm);

  const folderTreeData = useMemo(() => {
    return filteredFolders ? generateTreeData(filteredFolders) : generateTreeData(folders?.data ?? []);
  }, [filteredFolders, folders?.data]);

  const handleSelectFolder = (selectedKeys: React.Key[]) => {
    const [folderId] = selectedKeys;

    if (selectedFolder?.id === folderId) {
      dispatch(setSelectedFolder(undefined));
      return;
    }

    if (!folders.data) return;
    const clickedFolder = findFolderById(folders.data, folderId) ?? undefined;

    dispatch(setSelectedFolder(clickedFolder));
  };

  const handleDeleteFolder = useCallback(() => {
    if (selectedFolder && businessId) {
      dispatch(deleteFolder({ businessId, folderId: selectedFolder.id, folderName: selectedFolder?.name }))
        .unwrap()
        .then(() => dispatch(setSelectedFolder(undefined)))
        .catch(() => {});
    }
  }, [selectedFolder, businessId]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const insertDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <FolderAddOutlined />,
      label: translate('file.createFolder'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        setEditMode(false);
        setShowCreateFolderModal(true);
      },
    },
    {
      key: '1',
      icon: <FileAddOutlined />,
      disabled: !selectedFolder,
      label: translate('file.uploadFile'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        dispatch(setSelectedFile(undefined));
        setShowFileDrawer(true);
      },
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('file.edit'),
      onClick: () => {
        setEditMode(true);
        setShowCreateFolderModal(true);
      },
    },
    {
      key: '1',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('file.delete')}
          description={translate('common.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={() => {
            handleDeleteFolder();
          }}>
          {translate('file.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
    },
    {
      key: '2',
      icon: <ShareAltOutlined />,
      label: translate('file.share'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        setShowShareModal(true);
      },
    },
  ];

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row justify={'space-between'}>
          <Col>
            <Typography.Text type='secondary'>{translate('file.directories')}</Typography.Text>
          </Col>
          <Col>
            {!readOnly && (
              <Row>
                <Dropdown trigger={['click']} menu={{ items: insertDropdownItems }}>
                  <PlusOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Dropdown>
              </Row>
            )}
          </Col>
        </Row>
      ),
      children: (
        <Space direction='vertical' className='w-full'>
          {folderTreeData?.length === 0 ? (
            <Typography.Text className='fs-12' type='secondary'>
              {translate('file.noDirectories')}
            </Typography.Text>
          ) : (
            <Tree
              key={expandedKeys?.length?.toString()}
              showLine
              expandedKeys={expandedKeys}
              defaultSelectedKeys={[`${selectedFolder?.id}`]}
              onSelect={handleSelectFolder}
              selectedKeys={[selectedFolder?.id as string]}
              treeData={folderTreeData}
              onExpand={(exp, info) => {
                if (!info?.expanded) {
                  const filteredExpandedItems = exp?.filter((key) => key !== info?.node?.key);
                  setExpandedKeys(filteredExpandedItems);
                } else {
                  setExpandedKeys(exp);
                }
              }}
              titleRender={(nodeData: DataNode) => (
                <Row className='w-full h-full' justify='space-between' align='middle' wrap={false}>
                  <FolderOutlined className='mr-5' />
                  <Typography.Text className='row-label mb-0'>{nodeData?.title?.toString()}</Typography.Text>

                  <Row
                    wrap={false}
                    onClick={(e) => {
                      e?.stopPropagation();
                      if (!folders?.data) return;
                      dispatch(setSelectedFolder(findFolderById(folders.data, nodeData?.key)));
                    }}>
                    <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                      <Button style={{ width: '32px' }} icon={<EllipsisOutlined />} type='text' />
                    </Dropdown>
                  </Row>
                </Row>
              )}
            />
          )}

          <Button
            icon={<UsergroupAddOutlined />}
            shape='round'
            style={{ backgroundColor: activeTheme?.buttonBg, color: activeTheme?.buttonText }}
            type={sharedFolders?.active ? 'primary' : 'default'}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setSelectedFolder(undefined));
              dispatch(getSharedFolders(businessId));
              dispatch(getSharedFiles(businessId));
            }}>
            {translate('file.sharedWithMe')}
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!businessId) return;
    dispatch(getFolders(businessId));
  }, [businessId]);

  useEffect(() => {
    if (!selectedFolder) return setExpandedKeys([]);

    const keys: string[] = [];

    keys?.push(selectedFolder?.id);

    if (selectedFolder?.parent_id) {
      keys?.push(selectedFolder?.parent_id);
    }

    setExpandedKeys((prevState) => [...prevState, ...keys]);
  }, [selectedFolder]);

  useEffect(() => {
    onSearch?.(debouncedSearchKey);
  }, [debouncedSearchKey, onSearch]);

  return (
    <Spin spinning={folders.loading || folders.updating}>
      <Col className='file-menu-wrapper' style={{ padding: readOnly ? '10px' : '' }}>
        <Row justify='space-between'>
          {!readOnly && <Typography.Title className='fw-500 fs-24'>{translate('file.title')}</Typography.Title>}
          {isMobile && <Button icon={<CloseOutlined />} type='link' onClick={onClose} />}
          <Input.Search className='file-search' placeholder={translate('file.inputPlaceHolder')} enterButton onChange={onSearchChange} />
        </Row>

        <Row className='mt-25 w-full'>
          <Collapse key={businessId} defaultActiveKey={[1]} ghost items={items} className='menu-collapse-wrapper' />

          {!readOnly && <GetMoreStorage />}
        </Row>
      </Col>

      {showCreateFolderModal ? <CreateFolderModal onClose={() => setShowCreateFolderModal(false)} editMode={editMode} /> : <></>}
      {showFileDrawer ? <UpsertFileDrawer open={showFileDrawer} onClose={() => setShowFileDrawer(false)} /> : <></>}
      {showShareModal ? <ShareModal onClose={() => setShowShareModal(false)} isFile={false} /> : <></>}
    </Spin>
  );
};
