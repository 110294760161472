import React from 'react';

import { ITitleProps, Title } from '@aduvi/components/Title';
import { useActiveTheme } from '@aduvi/hooks';

export const PageTitle = (props: ITitleProps) => {
  const activeTheme = useActiveTheme();

  const titleStyle: React.CSSProperties = {
    color: activeTheme?.bodyHeadings,
    ...props.style,
  };

  return (
    <Title {...props} style={titleStyle}>
      {props.children}
    </Title>
  );
};
