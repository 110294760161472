import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICreateRoadmapCommentPayload,
  ICreateUpdateRoadmapPayload,
  IDeleteRoadmapCommentPayload,
  IRoadmap,
  IRoadmapComment,
  IRoadmapsState,
  IUpdateRoadmapCommentPayload,
  IUpdateRoadmapVotePayload,
} from '@aduvi/types/roadmap';

import * as RoadmapsServices from 'store/services/roadmap.service';

const initialState: IRoadmapsState = {
  roadmaps: [],
  categories: [],
  loading: false,
  creating: false,
  editing: false,
  selectedRoadmap: undefined,
  selectedRoadmapComment: undefined,
};

export const getRoadmaps = createAsyncThunk('roadmaps/get', async (_, { rejectWithValue }) => {
  try {
    return await RoadmapsServices.getRoadmaps();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createRoadmaps = createAsyncThunk('roadmaps/create', async (params: ICreateUpdateRoadmapPayload, { rejectWithValue }) => {
  try {
    return await RoadmapsServices.createRoadmaps(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateRoadmaps = createAsyncThunk(
  'roadmaps/update',
  async (params: ICreateUpdateRoadmapPayload & { id: string }, { rejectWithValue }) => {
    try {
      return await RoadmapsServices.updateRoadmaps(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getRoadmapCategories = createAsyncThunk('roadmapCategories/get', async (_, { rejectWithValue }) => {
  try {
    return await RoadmapsServices.getRoadmapCategories();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteRoadmap = createAsyncThunk('roadmap/delete', async (params: { id: string }, { rejectWithValue }) => {
  try {
    return await RoadmapsServices.deleteRoadmap(params.id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createRoadmapComment = createAsyncThunk(
  'roadmaps/create-roadmap-comment',
  async (params: ICreateRoadmapCommentPayload, { rejectWithValue }) => {
    try {
      return await RoadmapsServices.createRoadmapComment(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteRoadmapComment = createAsyncThunk(
  'roadmap/delete-roadmap-comment',
  async (params: IDeleteRoadmapCommentPayload, { rejectWithValue }) => {
    try {
      return await RoadmapsServices.deleteRoadmapComment(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRoadmapComment = createAsyncThunk(
  'roadmaps/update-roadmap-comment',
  async (params: IUpdateRoadmapCommentPayload, { rejectWithValue }) => {
    try {
      return await RoadmapsServices.updateRoadmapComment(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRoadmapVote = createAsyncThunk('roadmaps/update-roadmap-vote', async (params: IUpdateRoadmapVotePayload, { rejectWithValue }) => {
  try {
    return await RoadmapsServices.updateRoadmapVote(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const roadmapsSlice = createSlice({
  name: 'roadmaps',
  initialState,
  reducers: {
    setSelectedRoadmap: (state, { payload }: PayloadAction<IRoadmap | undefined>) => {
      state.selectedRoadmap = payload;
    },
    setSelectedRoadmapComment: (state, { payload }: PayloadAction<IRoadmapComment | undefined>) => {
      state.selectedRoadmapComment = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRoadmaps.pending, (state) => {
        state.creating = true;
      })
      .addCase(createRoadmaps.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.roadmaps) state.roadmaps = [...state.roadmaps, payload.data];
      })
      .addCase(createRoadmaps.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getRoadmapCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoadmapCategories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categories = payload.data;
      })
      .addCase(getRoadmapCategories.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRoadmaps.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoadmaps.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.roadmaps = payload.data;
      })
      .addCase(getRoadmaps.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateRoadmaps.pending, (state) => {
        state.editing = true;
      })
      .addCase(updateRoadmaps.fulfilled, (state, { payload }) => {
        state.editing = false;
        state.roadmaps = state.roadmaps?.map((item) => (item.id === payload.data.id ? payload.data : item));
      })
      .addCase(updateRoadmaps.rejected, (state) => {
        state.editing = false;
      })
      .addCase(deleteRoadmap.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoadmap.fulfilled, (state) => {
        state.loading = false;
        if (state.roadmaps) {
          state.roadmaps = state.roadmaps.filter((roadmap) => roadmap.id !== state.selectedRoadmap?.id);
        }
      })
      .addCase(deleteRoadmap.rejected, (state) => {
        state.loading = false;
      })

      .addCase(createRoadmapComment.pending, (state) => {
        state.creating = true;
      })
      .addCase(createRoadmapComment.fulfilled, (state, { payload, meta }) => {
        state.creating = false;
        if (!state.selectedRoadmap) return;
        state.selectedRoadmap.comments = [...state.selectedRoadmap.comments, payload.data];
        state.roadmaps = state.roadmaps.map((item) =>
          item.id === meta.arg.roadmap_id ? { ...item, comments: [...item.comments, payload.data] } : item,
        );
      })
      .addCase(createRoadmapComment.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateRoadmapComment.pending, (state) => {
        state.editing = true;
      })
      .addCase(updateRoadmapComment.fulfilled, (state, { payload, meta }) => {
        state.editing = false;
        if (!state.selectedRoadmap) return;
        state.selectedRoadmap.comments = state.selectedRoadmap.comments?.map((item) => (item.id === payload.data.id ? payload.data : item));
        state.roadmaps = state.roadmaps.map((item) =>
          item.id === meta.arg.roadmap_id
            ? { ...item, comments: item.comments.map((comment) => (comment.id === payload.data.id ? payload.data : comment)) }
            : item,
        );
      })
      .addCase(updateRoadmapComment.rejected, (state) => {
        state.editing = false;
      })
      .addCase(deleteRoadmapComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoadmapComment.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.selectedRoadmap && state.selectedRoadmap.comments) {
          state.selectedRoadmap.comments = state.selectedRoadmap.comments.filter((comment) => comment.id !== state.selectedRoadmapComment?.id);
          state.roadmaps = state.roadmaps.map((item) =>
            item.id === meta.arg.roadmap_id
              ? {
                  ...item,
                  comments: item.comments.filter((comment) => comment.id !== meta.arg.comment_id),
                }
              : item,
          );
        }
      })
      .addCase(deleteRoadmapComment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateRoadmapVote.pending, (state) => {
        state.editing = true;
      })
      .addCase(updateRoadmapVote.fulfilled, (state, { payload, meta }) => {
        state.editing = false;
        state.roadmaps = state.roadmaps.map((item) => (item.id === meta.arg.roadmap_id ? payload.data.roadmap : item));
      })
      .addCase(updateRoadmapVote.rejected, (state) => {
        state.editing = false;
      });
  },
});

export const roadmapReducer = roadmapsSlice.reducer;
export const { setSelectedRoadmap, setSelectedRoadmapComment } = roadmapsSlice.actions;
