export const ActiveCampaignIcon = () => {
  return (
    <svg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg' style={{ fontSize: '18px' }}>
      <path
        d='M9.86353 8.46582L0.641044 14.361C0.213777 14.6352 0 15.0806 0 15.5262V17L11.181 9.93958C11.6795 9.59686 12 9.04837 12 8.46582C12 7.88313 11.7152 7.33464 11.181 6.99192L0 0V1.37092C0 1.85086 0.249311 2.29633 0.641044 2.5363L9.86353 8.46582Z'
        fill='#B37FEB'
      />
      <path
        d='M4.48152 8.80082C5 9.06639 5.66676 9.06639 6.18524 8.80082L7 8.35803L0.925932 5.08166C0.555589 4.87499 0 5.08166 0 5.4653V6.43936L3.14814 8.12191L4.48152 8.80082Z'
        fill='#B37FEB'
      />
    </svg>
  );
};
