import { useTranslation } from 'react-i18next';
import { CodeOutlined, CopyOutlined, DeleteOutlined, EllipsisOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { Card as AntCard, Dropdown, Popconfirm, Row, Typography } from 'antd';

interface ICardProps {
  id: string;
  title: string;
  description?: string;
  action?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  entityTypeId?: string;
  backgroundMode?: 'colour' | 'image';
  isDeletable?: boolean;
  onConfigure?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
  onClick?: () => void;
}

export const WidgetCard = ({
  action,
  id,
  description,
  title,
  backgroundColor,
  backgroundMode,
  backgroundImage,
  onConfigure,
  onDuplicate,
  onDelete,
  onClick,
}: ICardProps) => {
  const { t: translate } = useTranslation();

  const background = {
    background: backgroundMode === 'colour' ? backgroundColor : backgroundImage ? `url(${backgroundImage})  center/cover no-repeat` : backgroundColor,
  };

  return (
    <>
      <AntCard
        onClick={onClick}
        key={id}
        hoverable
        cover={
          <Row justify='center' align='bottom' className='w-full flex' style={{ height: 180, ...background }}>
            <img alt='image' width={'80%'} height={'80%'} src={require('assets/images/contract-cover.png')} />
          </Row>
        }>
        <AntCard.Meta
          title={title}
          description={
            <>
              <Typography.Text>{<div dangerouslySetInnerHTML={{ __html: description || '' }} />}</Typography.Text>
              <Row justify='space-between' align='middle' className='mt-15' onClick={(e) => e.stopPropagation()}>
                <Typography.Paragraph type='secondary'>{action}</Typography.Paragraph>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        icon: <SettingOutlined />,
                        label: translate('widgets.configure'),
                        onClick: onConfigure,
                      },
                      {
                        key: '2',
                        icon: <CodeOutlined />,
                        label: translate('widgets.embedWidget'),
                        onClick: onDuplicate,
                      },
                      {
                        key: '3',
                        icon: <CopyOutlined />,
                        label: translate('widgets.duplicate'),
                        onClick: onDuplicate,
                      },
                      {
                        key: '4',
                        icon: <DeleteOutlined />,
                        label: (
                          <Popconfirm
                            title={translate('buttons.delete')}
                            description={translate('common.deleteConfirmation')}
                            icon={<QuestionCircleOutlined />}
                            onCancel={(e) => {
                              e?.stopPropagation();
                            }}
                            onConfirm={(e) => {
                              e?.stopPropagation();
                              onDelete?.();
                            }}>
                            {translate('leads.delete')}
                          </Popconfirm>
                        ),
                        onClick: (e) => e?.domEvent?.stopPropagation(),
                      },
                    ],
                  }}
                  trigger={['click']}
                  overlayStyle={{ borderRadius: '8px' }}>
                  <EllipsisOutlined className='fs-20' />
                </Dropdown>
              </Row>
            </>
          }
        />
      </AntCard>
    </>
  );
};
