import { AcademyGroupedByLevel, IAcademy } from '@aduvi/types/academy';

export const groupAcademiesByLevel = (academies: IAcademy[] | undefined): AcademyGroupedByLevel => {
  if (!academies) return {};

  return academies.reduce((acc: AcademyGroupedByLevel, item: IAcademy) => {
    if (!acc[item.level]) {
      acc[item.level] = [];
    }
    acc[item.level].push(item);
    return acc;
  }, {});
};
