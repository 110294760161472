import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITrash, ITrashInitialState } from '@aduvi/types/trash';

import * as TrashService from 'store/services/trash.service';

const initialState: ITrashInitialState = {
  data: undefined,
  selectedTrash: undefined,
  loading: false,
};

export const getTrashItems = createAsyncThunk(
  'trash/get-trash-items',
  async (payload: { businessId: string; page: number; size: number }, { rejectWithValue }) => {
    try {
      return await TrashService.getTrashItems(payload.businessId, payload.page, payload.size);
    } catch (err) {
      rejectWithValue(err);
    }
  },
);

export const restoreTrash = createAsyncThunk('trash/restore-trash', async (payload: { businessId: string; trashId: string }, { rejectWithValue }) => {
  try {
    return await TrashService.restoreTrash(payload.businessId, payload.trashId);
  } catch (err) {
    rejectWithValue(err);
  }
});

export const trashSlice = createSlice({
  name: 'trash',
  initialState,
  reducers: {
    setSelectedTrash(state, { payload }: PayloadAction<ITrash | undefined>) {
      state.selectedTrash = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrashItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrashItems.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getTrashItems.rejected, (state) => {
        state.loading = false;
      })
      .addCase(restoreTrash.pending, (state) => {
        state.loading = true;
      })
      .addCase(restoreTrash.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.data?.data) {
          state.data.data = state.data?.data.filter((item) => item?.id !== meta.arg?.trashId);
        }
      })
      .addCase(restoreTrash.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const trashReducer = trashSlice.reducer;

export const { setSelectedTrash } = trashSlice.actions;
