import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import './ProductEmbed.scss';

import { useBusiness } from '@aduvi/hooks';
import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getPublicProducts } from 'store/features/form-slice';
import { getPublicWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWidgetStyles } from '../../hooks/useWidgetStyles';

export const ProductEmbed = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { widgetId } = useParams();

  const { selectedWidget } = useAppSelector((state) => state.widget);
  const [products, setProducts] = useState<IPaginatedResponse<IProduct> | undefined>(undefined);

  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const memoizedWidgetFields = useMemo(() => {
    return {
      productCategory: selectedWidget?.widget_style?.[0]?.product_category,
      sortProducts: selectedWidget?.widget_style?.[0]?.sort_products,
      buttonActionForm: selectedWidget?.widget_style?.[0]?.button_action_form,
      buttonText: selectedWidget?.widget_style?.[0]?.button_text,
    };
  }, [selectedWidget]);

  const { productCategory, sortProducts, buttonActionForm, buttonText } = memoizedWidgetFields;

  const { visibleFields, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useWidgetStyles(selectedWidget);

  const displayedProducts = useMemo(() => {
    let filteredProducts = [...(products?.data || [])];

    const selectedCategory = productCategory;
    if (selectedCategory && selectedCategory !== 'all') {
      filteredProducts = filteredProducts.filter((product) => product.business_product_category_id === selectedCategory);
    }

    const sortOption = sortProducts;
    if (sortOption === 'Price') {
      filteredProducts = filteredProducts.sort((a, b) => {
        const priceA = a.price ?? 0;
        const priceB = b.price ?? 0;
        return priceA - priceB;
      });
    } else if (sortOption === 'Name') {
      filteredProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (limitResults === 'show_all') {
      return filteredProducts;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return filteredProducts.slice(0, resultsPerPage);
    }
    return filteredProducts;
  }, [products?.data, productCategory, sortProducts, limitResults, resultsPerPage]);

  const handleButtonClick = (product: IProduct) => {
    const action = buttonActionForm;

    if (action === 'Add to booking') {
      window.open(`/client-portal/login?addProductToBooking=${product.id}`, '_blank');
    } else if (action === 'Create order') {
      window.open(`/create-order?productId=${product.id}`, '_blank');
    }
  };

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicProducts(String(selectedBusiness?.id || urlBusinessId)))
      .unwrap()
      .then((data) => setProducts(data));
  }, [widgetId]);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Row className='products-design-tab-wrapper'>
      <Col span={24}>
        <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {displayedProducts?.map((product, index) => (
                <div key={index} className='product-card'>
                  {visibleFields.includes('Picture') && <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />}
                  {visibleFields.includes('Title') && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {product.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      ${product.price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={() => handleButtonClick(product)}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </div>
              ))}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]}>
              {displayedProducts?.map((product, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                  <div className='product-card'>
                    {visibleFields.includes('Picture') && <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {product.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        ${product.price}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(product)}
                        style={{
                          background: selectedWidget?.widget_style?.[0]?.button_background,
                          color: selectedWidget?.widget_style?.[0]?.button_font,
                        }}>
                        {buttonText}
                      </Button>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            // For ROWS layout
            displayedProducts?.map((product, index) => (
              <Row key={index} gutter={[20, 20]} align='middle' className='products-card-row'>
                {visibleFields.includes('Picture') && (
                  <Col xs={24} sm={6} className='product-image'>
                    <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />
                  </Col>
                )}
                <Col xs={24} sm={18} className='product-details'>
                  {visibleFields.includes('Title') && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {product.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      ${product.price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={() => handleButtonClick(product)}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </Col>
              </Row>
            ))
          )}
        </Card>
      </Col>
    </Row>
  );
};
