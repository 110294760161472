import React from 'react';
import * as Icons from '@ant-design/icons';

import { ADUVI_DEFAULT_ACTIVE_THEME } from '@aduvi/constants';
import { useActiveTheme } from '@aduvi/hooks';

interface DisplayIconProps {
  iconName: string;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const CustomIcon: React.FC<DisplayIconProps> = ({ iconName = 'UsergroupAddOutlined' }) => {
  const activeTheme = useActiveTheme();

  return React.createElement((Icons as any)[iconName], { style: { color: activeTheme?.menuText ?? ADUVI_DEFAULT_ACTIVE_THEME.menuText } });
};
