import { v4 as uuidv4 } from 'uuid';

import { FormInstance, MenuProps } from 'antd';
import './Build.scss';

import { EReferenceEntityType, IEntityField } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';
import { EFormFormat, IFieldInstance } from '@aduvi/types/form';
import { toCapitalize } from '@aduvi/utils/helper';

export interface IBookingFormFieldProps {
  field_id: string;
  field_instance: IFieldInstance;
  isPageBreak: boolean;
  field: IEntityField | undefined;
}

const initialFieldInstance = {
  default_value: '',
  description: '',
  display_settings: {
    fontSize: 14,
    bold: false,
    italic: false,
    textColor: '',
    backgroundTextColor: 'transparent',
  },
  half_width: false,
  hidden: false,
  multiple: false,
  place_holder: '',
  required: false,
};

export const bookingFormDropdownItems = (
  index: number,
  fields: IBookingFormFieldProps[],
  entityFields: IEntityField[],
  form: FormInstance,
  entityTypeHasQuotes: boolean,
  entityTypes: IEntityType[],
  onAddField: (element: IBookingFormFieldProps, index: number) => void,
  onCreateNewFieldDrawer: (index: number) => void,
  onCreateAsset: (assetType: IEntityType, index: number) => void,
): MenuProps['items'] => {
  const fieldEntityFields = entityFields.filter(
    (item) => ![EReferenceEntityType.PRODUCT, EReferenceEntityType.SERVICE, EReferenceEntityType].includes(item.reference_entity_type),
  );

  return [
    {
      key: '1',
      type: 'group',
      label: 'Fields',
      children: fieldEntityFields
        ?.filter((item) => !item?.hidden)
        .map((item) => {
          return {
            key: item.id,
            label: item.title,
            onClick: () =>
              onAddField(
                {
                  field: item,
                  field_id: item.id,
                  field_instance: {
                    ...initialFieldInstance,
                    title: item.title,
                    weight: index,
                  },
                  isPageBreak: false,
                },
                index,
              ),
            disabled: fields.some((field) => item.id === field.field_id),
          };
        }),
    },
    {
      key: '2',
      type: 'group',
      label: 'Store',
      children:
        entityTypeHasQuotes && entityTypes.filter((item) => item.name === 'PRODUCT' || item.name === 'SERVICE')
          ? entityTypes
              .filter((item) => item.name === 'PRODUCT' || item.name === 'SERVICE')
              ?.map((item) => ({
                key: uuidv4(),
                label: toCapitalize(item.name),
                onClick: () => onCreateAsset(item, index),
              }))
          : [],
    },
    {
      key: '3',
      label: 'Extra',
      type: 'group',
      children: [
        {
          key: '3-1',
          label: 'Add New Field',
          onClick: () => onCreateNewFieldDrawer(index),
        },
        {
          key: '3-2',
          label: 'Page Break',
          onClick: () =>
            onAddField(
              {
                field: undefined,
                field_id: Math.random().toString(),
                field_instance: {
                  ...initialFieldInstance,
                  title: 'Divider',
                  weight: index,
                },
                isPageBreak: true,
              },
              index,
            ),
          disabled: form.getFieldValue(['form_style', 0, 'format']) !== EFormFormat.MULTI_PAGE_OPTIONAL,
        },
      ],
    },
  ];
};
