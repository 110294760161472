import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { IBillingDetails, ICards, IChangePlanResponse } from '@aduvi/types/billing';

export const getBillingDetails = async (business_id: string): Promise<IResponse<IBillingDetails>> => {
  return await axios.get(`/businesses/${business_id}/billing/details`);
};

export const createBillingDetails = async (payload: Omit<IBillingDetails, 'id'> & { business_id: string }): Promise<IResponse<IBillingDetails>> => {
  return await axios.post(`/businesses/${payload.business_id}/billing/details`, payload);
};

export const editBillingDetails = async (payload: IBillingDetails & { business_id: string }): Promise<IResponse<IBillingDetails>> => {
  return await axios.put(`/businesses/${payload.business_id}/billing/details/${payload.id}`, payload);
};

export const changeBusinessPlan = async (payload: { business_id: string; partner_pricing_id: string }): Promise<IResponse<IChangePlanResponse>> => {
  return await axios.put(`/businesses/${payload.business_id}/billing/plans`, { partner_pricing_id: payload.partner_pricing_id });
};

export const getActiveBusinessPlan = async (business_id: string): Promise<IResponse<IChangePlanResponse>> => {
  return await axios.get(`/businesses/${business_id}/billing/plans`);
};

export const getCards = async (user_id: string, business_id: string): Promise<IResponse<ICards>> => {
  return await axios.get(`/users/${user_id}/businesses/${business_id}/cards`);
};

export const createCard = async (user_id: string, business_id: string, stripe_token: string): Promise<void> => {
  return await axios.post(`/users/${user_id}/businesses/${business_id}/cards`, { stripe_token });
};

export const deleteCard = async (user_id: string, business_id: string, card_id: string): Promise<void> => {
  return await axios.delete(`/users/${user_id}/businesses/${business_id}/cards/${card_id}`);
};

export const setDefaultCard = async (user_id: string, business_id: string, card_id: string): Promise<void> => {
  return await axios.put(`/users/${user_id}/businesses/${business_id}/cards/${card_id}/default`);
};

export const getBusinessCards = async (business_id: string) => {
  return await axios.get(`/businesses/${business_id}/billing/cards`);
};

export const createBusinessCard = async (business_id: string, stripe_token: string | null): Promise<IResponse<{ client_secret: string }>> => {
  return await axios.post(`/businesses/${business_id}/billing/cards`, { stripe_token });
};

export const setDefaultBusinessCard = async (business_id: string, card_id: string): Promise<void> => {
  return await axios.put(`/businesses/${business_id}/billing/cards/${card_id}/default`);
};

export const deleteBusinessCard = async (business_id: string, card_id: string): Promise<void> => {
  return await axios.delete(`/businesses/${business_id}/billing/cards/${card_id}`);
};
