import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPaginatedResponse } from '@aduvi/types';
import {
  IAutomation,
  IAutomationHistory,
  IAutomationMention,
  IAutomationState,
  IGetAutomationHistoryPayload,
  IUpsertAutomation,
  IUpsertAutomationNodesPayload,
} from '@aduvi/types/automation';

import * as AutomationService from 'store/services/automation.service';

const initialState: IAutomationState = {
  automations: undefined,
  loading: false,
  creating: false,
  loadingAutomation: false,
  loadingAutomationHistory: false,
  loadingAutomationQueue: false,
  updating: false,
  selectedAutomation: undefined,
  selectedAutomationHistory: undefined,
  selectedAutomationQueue: undefined,
  duplicateNodeId: undefined,
  lastNodesId: [],
  mentions: [],
  connections: {
    data: [],
    loading: false,
  },
};

export const getAutomations = createAsyncThunk('automations/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await AutomationService.getAutomations(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAutomation = createAsyncThunk(
  'automations/get-by-id',
  async (params: { businessId: string; automationId: string }, { rejectWithValue }) => {
    try {
      return await AutomationService.getAutomation(params.businessId, params.automationId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAutomationHistory = createAsyncThunk(
  'automations/get-history-by-id',
  async (params: IGetAutomationHistoryPayload, { rejectWithValue }) => {
    try {
      return await AutomationService.getAutomationHistory(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAutomationQueue = createAsyncThunk(
  'automations/get-queue-by-id',
  async (params: IGetAutomationHistoryPayload, { rejectWithValue }) => {
    try {
      return await AutomationService.getAutomationQueue(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createAutomation = createAsyncThunk('automations/create', async (params: IUpsertAutomation, { rejectWithValue }) => {
  try {
    return await AutomationService.createAutomation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const duplicateAutomation = createAsyncThunk(
  'automations/duplicate',
  async (params: { businessId: string; automationId: string }, { rejectWithValue }) => {
    try {
      return await AutomationService.duplicateAutomation(params.businessId, params.automationId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editAutomation = createAsyncThunk('automations/edit', async (params: IUpsertAutomation, { rejectWithValue }) => {
  try {
    return await AutomationService.editAutomation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteAutomation = createAsyncThunk(
  'automations/delete',
  async (params: { businessId: string; automationId: string }, { rejectWithValue }) => {
    try {
      return await AutomationService.deleteAutomation(params.businessId, params.automationId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createAutomationNodes = createAsyncThunk(
  'automations/create-nodes',
  async (params: IUpsertAutomationNodesPayload, { rejectWithValue }) => {
    try {
      return await AutomationService.createAutomationNodes(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAllConnections = createAsyncThunk('automations/connections', async (businessId: string, { rejectWithValue }) => {
  try {
    return await AutomationService.getAllConnections(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const automationSlice = createSlice({
  name: 'automation',
  initialState,
  reducers: {
    setSelectedAutomation: (state, action: PayloadAction<IAutomation | undefined>) => {
      state.selectedAutomation = action.payload;
    },
    setSelectedAutomationHistory: (state, action: PayloadAction<IPaginatedResponse<IAutomationHistory> | undefined>) => {
      state.selectedAutomationHistory = action.payload;
    },
    setSelectedAutomationQueue: (state, action: PayloadAction<IPaginatedResponse<IAutomationHistory> | undefined>) => {
      state.selectedAutomationQueue = action.payload;
    },
    setDuplicateNodeId: (state, action: PayloadAction<string | undefined>) => {
      state.duplicateNodeId = action.payload;
    },
    setLastNodesId: (state, action: PayloadAction<string[]>) => {
      state.lastNodesId = action.payload;
    },
    setAutomationMentions: (state, action: PayloadAction<IAutomationMention[]>) => {
      state.mentions = Array.from(new Set(action.payload.map((obj) => JSON.stringify(obj)))).map((str) => JSON.parse(str));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAutomations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAutomations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.automations = payload;
      })
      .addCase(getAutomations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAutomation.pending, (state) => {
        state.loadingAutomation = true;
      })
      .addCase(getAutomation.fulfilled, (state, { payload }) => {
        state.loadingAutomation = false;
        state.selectedAutomation = payload.data;
      })
      .addCase(getAutomation.rejected, (state) => {
        state.loadingAutomation = false;
      })
      .addCase(getAutomationHistory.pending, (state) => {
        state.loadingAutomationHistory = true;
      })
      .addCase(getAutomationHistory.fulfilled, (state, { payload }) => {
        state.loadingAutomationHistory = false;
        state.selectedAutomationHistory = payload;
      })
      .addCase(getAutomationHistory.rejected, (state) => {
        state.loadingAutomationHistory = false;
      })
      .addCase(getAutomationQueue.pending, (state) => {
        state.loadingAutomationQueue = true;
      })
      .addCase(getAutomationQueue.fulfilled, (state, { payload }) => {
        state.loadingAutomationQueue = false;
        state.selectedAutomationQueue = payload;
      })
      .addCase(getAutomationQueue.rejected, (state) => {
        state.loadingAutomationQueue = false;
      })
      .addCase(createAutomation.pending, (state) => {
        state.creating = true;
      })
      .addCase(createAutomation.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.automations) state.automations = { ...state.automations, data: [...state.automations?.data, payload.data] };
      })
      .addCase(createAutomation.rejected, (state) => {
        state.creating = false;
      })
      .addCase(deleteAutomation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAutomation.fulfilled, (state, { meta: { arg } }) => {
        state.loading = false;
        if (state.automations)
          state.automations = { ...state.automations, data: state.automations.data.filter((item) => item.id !== arg.automationId) };
      })
      .addCase(deleteAutomation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(duplicateAutomation.pending, (state) => {
        state.loading = true;
      })
      .addCase(duplicateAutomation.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.automations) state.automations = { ...state.automations, data: [...state.automations?.data, payload.data] };
      })
      .addCase(duplicateAutomation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(editAutomation.pending, (state) => {
        state.updating = true;
      })
      .addCase(editAutomation.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.automations)
          state.automations = {
            ...state.automations,
            data: state.automations.data.map((item) => (item.id === payload.data.id ? payload.data : item)),
          };
      })
      .addCase(editAutomation.rejected, (state) => {
        state.updating = false;
      })
      .addCase(createAutomationNodes.pending, (state) => {
        state.updating = true;
      })
      .addCase(createAutomationNodes.fulfilled, (state) => {
        state.updating = false;
      })
      .addCase(createAutomationNodes.rejected, (state) => {
        state.updating = false;
      })
      .addCase(getAllConnections.pending, (state) => {
        state.connections.loading = true;
      })
      .addCase(getAllConnections.fulfilled, (state, { payload }) => {
        state.connections.loading = false;
        state.connections.data = payload.data;
      })
      .addCase(getAllConnections.rejected, (state) => {
        state.connections.loading = false;
      });
  },
});

export const automationReducer = automationSlice.reducer;
export const {
  setSelectedAutomation,
  setSelectedAutomationHistory,
  setSelectedAutomationQueue,
  setDuplicateNodeId,
  setLastNodesId,
  setAutomationMentions,
} = automationSlice.actions;
