import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICreateShiftSettingsPayload,
  IReorderPositionPayload,
  IShiftPayload,
  IShiftSchedulerSettings,
  IShiftSchedulerState,
  IUpdateShiftSettingsPayload,
} from '@aduvi/types/shift-scheduler';

import * as ShiftSchedulerServices from 'store/services/shift-scheduler.service';

const initialState: IShiftSchedulerState = {
  settings: undefined,
  shifts: [],
  loading: false,
  creating: false,
  updating: false,
  visiblePopovers: {},
};

export const getShiftSchedulerSettings = createAsyncThunk(
  'shift-scheduler/get-shift-scheduler-settings',
  async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.getShiftSchedulerSettings(businessId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createShiftSchedulerSettings = createAsyncThunk(
  'shift-scheduler/create-shift-scheduler-settings',
  async (params: ICreateShiftSettingsPayload, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.createShiftSchedulerSettings(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateShiftSchedulerSettings = createAsyncThunk(
  'shift-scheduler/update-shift-scheduler-settings',
  async (params: IUpdateShiftSettingsPayload, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.updateShiftSchedulerSettings(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const deleteShiftSchedulerSettings = createAsyncThunk(
  'shift-scheduler/delete-shift-scheduler-settings',
  async ({ businessId, settingId }: { businessId: string; settingId: string }, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.deleteShiftSchedulerSettings(businessId, settingId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const upsertShift = createAsyncThunk('shift-scheduler/upsert-shift', async (params: IShiftPayload, { rejectWithValue }) => {
  try {
    return await ShiftSchedulerServices.upsertShift(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const reorderPositions = createAsyncThunk('shift-scheduler/re-order', async (params: IReorderPositionPayload, { rejectWithValue }) => {
  try {
    return await ShiftSchedulerServices.reorderPositions(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteSettingsShiftPosition = createAsyncThunk(
  'shift-scheduler/delete-position',
  async (params: { businessId: string; positionId: string }, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.deleteSettingsShiftPosition(params.businessId, params.positionId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteSettingsShiftWage = createAsyncThunk(
  'shift-scheduler/delete-wage',
  async (params: { businessId: string; wageId: string }, { rejectWithValue }) => {
    try {
      return await ShiftSchedulerServices.deleteSettingsShiftWage(params.businessId, params.wageId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const shiftSchedulerSlice = createSlice({
  name: 'shift-scheduler',
  initialState,
  reducers: {
    setPopoverIndex(state, action: PayloadAction<{ popoverType: string; index: number | undefined }>) {
      state.visiblePopovers[action.payload.popoverType] = action.payload.index;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShiftSchedulerSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShiftSchedulerSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getShiftSchedulerSettings.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.settings = payload.data;
      })
      .addCase(createShiftSchedulerSettings.pending, (state) => {
        state.creating = true;
      })
      .addCase(createShiftSchedulerSettings.rejected, (state) => {
        state.creating = false;
      })
      .addCase(createShiftSchedulerSettings.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.settings = payload.data;
      })
      .addCase(updateShiftSchedulerSettings.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateShiftSchedulerSettings.rejected, (state) => {
        state.updating = false;
      })
      .addCase(updateShiftSchedulerSettings.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.settings = payload.data;
      })
      .addCase(deleteShiftSchedulerSettings.fulfilled, (state, { meta }) => {
        const settingId = meta.arg.settingId;
        if (state.settings && state.settings.id === settingId) {
          state.settings = {} as IShiftSchedulerSettings;
        }
      })
      .addCase(upsertShift.pending, (state) => {
        state.creating = true;
        state.updating = true;
      })
      .addCase(upsertShift.rejected, (state) => {
        state.creating = false;
        state.updating = false;
      })
      .addCase(upsertShift.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.updating = false;
        state.shifts = payload.data;
      });
  },
});

export const shiftSchedulerReducer = shiftSchedulerSlice.reducer;
export const { setPopoverIndex } = shiftSchedulerSlice.actions;
