import { Content } from './@components/Content';
import { Footer } from './@components/Footer';
import { Header } from './@components/Header';

export const AppLayoutLoadingMobile = () => {
  return (
    <>
      <Header />
      <Content
        style={{
          minHeight: 'calc(100vh - 140px)',
          maxHeight: 'calc(100vh - 140px)',
          backgroundColor: 'white',
        }}></Content>
      <Footer />
    </>
  );
};
