import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IInvoiceSettingsPayload, IInvoiceState } from '@aduvi/types/invoice';

import * as InvoiceSettings from 'store/services/invoice.service';

const initialState: IInvoiceState = {
  invoice: undefined,
  loading: false,
};

export const fetchInvoiceSettings = createAsyncThunk('invoice/get-invoice', async (businessId: string, { rejectWithValue }) => {
  try {
    return await InvoiceSettings.getInvoice(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createInvoiceSettings = createAsyncThunk(
  'invoice-settings/create',
  async ({ payload }: { payload: IInvoiceSettingsPayload }, { rejectWithValue }) => {
    try {
      return await InvoiceSettings.createInvoiceSettings(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateInvoiceSettings = createAsyncThunk(
  'invoice/update',
  async ({ invoiceSettingsId, payload }: { invoiceSettingsId: string; payload: IInvoiceSettingsPayload }, { rejectWithValue }) => {
    try {
      return await InvoiceSettings.updateInvoiceSettings(payload, invoiceSettingsId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceSettings: (state, { payload }) => {
      state.invoice = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoiceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.invoice = action.payload.data;
      })
      .addCase(fetchInvoiceSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createInvoiceSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInvoiceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.invoice = action.payload.data;
      })
      .addCase(createInvoiceSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateInvoiceSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInvoiceSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.invoice = action.payload.data;
      })
      .addCase(updateInvoiceSettings.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const InvoiceReducer = invoiceSlice.reducer;
