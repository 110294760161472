import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { useBusinessId } from '@aduvi/hooks';

import { createFolder, updateFolder } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  onClose: () => void;
  editMode: boolean;
}

export const CreateFolderModal = ({ onClose, editMode }: IProps) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { folders, selectedFolder } = useAppSelector((state) => state.file);

  const handleSubmitFolder = useCallback(() => {
    if (!businessId) return;
    const { name } = form.getFieldsValue();
    const parentId = selectedFolder ? (editMode ? selectedFolder.parent_id : selectedFolder.id) : undefined;

    const payload = { name, parent_id: parentId };

    if (selectedFolder && editMode) {
      dispatch(updateFolder({ businessId, folderId: selectedFolder.id, payload }))
        .unwrap()
        .then(() => onClose())
        .catch(() => {});
    } else {
      dispatch(createFolder({ businessId, payload }))
        .unwrap()
        .then(() => onClose())
        .catch(() => {});
    }
  }, [businessId, form, selectedFolder, editMode]);

  useEffect(() => {
    if (!selectedFolder?.id || !editMode) return form.resetFields();

    form.setFieldsValue(selectedFolder);
  }, [selectedFolder?.id, businessId]);

  return (
    <Modal
      open={true}
      title={editMode ? `${translate('file.edit')} ${selectedFolder?.name}` : translate('file.createFolder')}
      onOk={handleSubmitFolder}
      onCancel={onClose}
      destroyOnClose={true}
      footer={[
        <Button disabled={folders.creating || folders.updating} key='back' onClick={onClose}>
          {translate('file.cancel')}
        </Button>,
        <Button loading={folders.creating || folders.updating} key='submit' type='primary' onClick={handleSubmitFolder}>
          {editMode ? translate('file.edit') : translate('file.create')}
        </Button>,
      ]}>
      <Form form={form} layout='vertical'>
        <Form.Item name='name' label={translate('file.folderName')} rules={[{ required: true, message: translate('file.folderNameRequired') }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
