import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import {
  ICreatePackagePayload,
  ICreateServicePayload,
  IDeletePackagePayload,
  IGetPackageByIdPayload,
  IGetServicesPayload,
  IPackage,
  IReorderPackagesPayload,
  IReorderServicesPayload,
  IService,
  IServicesState,
  IUpdatePackagePayload,
  IUpdateServicePayload,
} from '@aduvi/types/services';

import { apiSlice } from 'store/api/apiSlice';
import * as Services from 'store/services/services.service';

const initialState: IServicesState = {
  services: undefined,
  selectedService: undefined,
  selectedPackage: undefined,
  loadingService: false,
  loadingPackage: false,
  loading: false,
  creating: false,
  updating: false,
};

export const getServices = createAsyncThunk('services/get-services', async (params: IGetServicesPayload, { rejectWithValue }) => {
  try {
    return await Services.getServices(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createService = createAsyncThunk('services/create-services', async (params: ICreateServicePayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.SERVICE }]));
    return await Services.createService(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateService = createAsyncThunk('services/update-services', async (params: IUpdateServicePayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.SERVICE }]));
    return await Services.updateService(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createPackage = createAsyncThunk('packages/create-package', async (params: ICreatePackagePayload, { rejectWithValue }) => {
  try {
    return await Services.createPackage(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePackage = createAsyncThunk('packages/update-package', async (params: IUpdatePackagePayload, { rejectWithValue }) => {
  try {
    return await Services.updatePackage(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getPackageById = createAsyncThunk('packages/get-package-by-id', async (params: IGetPackageByIdPayload, { rejectWithValue }) => {
  try {
    return await Services.getPackageById(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deletePackage = createAsyncThunk('packages/delete-package', async (params: IDeletePackagePayload, { rejectWithValue }) => {
  try {
    return await Services.deletePackage(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const archiveService = createAsyncThunk(
  'services/archive',
  async (params: { business_id: string; service_id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.SERVICE }]));
      return await Services.archiveService(params.business_id, params.service_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const orderServices = createAsyncThunk('services/order-services', async (params: IReorderServicesPayload, { rejectWithValue }) => {
  try {
    return await Services.orderServices(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const orderPackages = createAsyncThunk('packages/order-packages', async (params: IReorderPackagesPayload, { rejectWithValue }) => {
  try {
    return await Services.orderPackages(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const cloneService = createAsyncThunk(
  'services/clone-service',
  async (params: { businessId: string; serviceId: string }, { rejectWithValue }) => {
    try {
      return await Services.cloneService(params.businessId, params.serviceId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clonePackage = createAsyncThunk(
  'packages/clone-package',
  async (params: { businessId: string; serviceId: string; packageId: string }, { rejectWithValue }) => {
    try {
      return await Services.clonePackage(params.businessId, params.serviceId, params.packageId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetServicesState: () => initialState,
    setSelectedService: (state, action: PayloadAction<Partial<IService> | undefined>) => {
      state.selectedService = action.payload as IService;
    },
    setSelectedPackage: (state, action: PayloadAction<Partial<IPackage> | undefined>) => {
      state.selectedPackage = action.payload as IPackage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServices.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.from === state?.services?.from) state.services = undefined;

        state.services = {
          ...payload,
          data: [...(state.services?.data ?? []), ...(payload?.data ?? [])]
            ?.sort((a, b) => a.order_number - b.order_number)
            .map((item) => {
              return {
                ...item,
                packages: orderBy(item.packages, 'order_number'),
              };
            }),
        };
      })
      .addCase(getServices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createService.pending, (state) => {
        state.creating = true;
      })
      .addCase(createService.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.services) state.services.data = [...state.services.data, { ...payload.data, packages: [] }];
      })
      .addCase(createService.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateService.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateService.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.services)
          state.services.data = state?.services?.data?.map((item) => {
            return item.id === payload.data.id ? payload.data : item;
          });
      })
      .addCase(updateService.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deletePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePackage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deletePackage.fulfilled, (state, { meta }) => {
        state.loading = false;

        if (state.services)
          state.services.data = state.services?.data.map((item) => {
            if (item.id === meta.arg.service_id) {
              return { ...item, packages: item.packages.filter((singlePackage) => singlePackage.id !== meta.arg.package_id) };
            }
            return item;
          });
      })
      .addCase(archiveService.pending, (state) => {
        state.loading = true;
      })
      .addCase(archiveService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(archiveService.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.services) state.services.data = state.services?.data.filter((item) => item.id !== meta.arg.service_id);
      })
      .addCase(createPackage.pending, (state) => {
        state.creating = true;
      })
      .addCase(createPackage.rejected, (state) => {
        state.creating = false;
      })
      .addCase(createPackage.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.services)
          state.services.data = state.services.data.map((item) => {
            return item.id === payload.data.business_service_id ? { ...item, packages: [...item.packages, payload.data] } : item;
          });
      })
      .addCase(updatePackage.pending, (state) => {
        state.updating = true;
      })
      .addCase(updatePackage.rejected, (state) => {
        state.updating = false;
      })
      .addCase(updatePackage.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.services)
          state.services.data = state.services.data.map((item) => {
            if (item.id === payload.data.business_service_id)
              return {
                ...item,
                packages: item.packages.map((itemPackage) => {
                  return itemPackage.id === payload.data.id ? payload.data : itemPackage;
                }),
              };
            return item;
          });
      })
      .addCase(getPackageById.pending, (state) => {
        state.loadingPackage = true;
      })
      .addCase(getPackageById.rejected, (state) => {
        state.loadingPackage = false;
      })
      .addCase(getPackageById.fulfilled, (state, { payload }) => {
        state.loadingPackage = false;
        state.selectedPackage = payload.data;
      })
      .addCase(orderPackages.pending, (state, { meta }) => {
        const updatedPackages: IPackage[] = meta.arg.body.packages;
        state.loading = true;
        if (state.services) {
          state.services.data = state.services.data.map((item) => {
            return item.id === meta.arg.service_id ? { ...item, packages: orderBy(updatedPackages, 'order_number') } : item;
          });
        }
      })
      .addCase(orderPackages.rejected, (state) => {
        state.loading = false;
      })
      .addCase(orderPackages.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(orderServices.pending, (state, { meta }) => {
        state.loading = true;
        if (state.services) {
          state.services.data = state.services.data
            .map((service) => {
              const updatedService = meta.arg.body.services.find((item2) => item2.id === service.id);
              if (updatedService) {
                return { ...service, order_number: updatedService.order_number };
              }
              return service;
            })
            .sort((a, b) => a.order_number - b.order_number);
        }
      })
      .addCase(orderServices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(orderServices.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(cloneService.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneService.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cloneService.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.services)
          state.services = {
            ...state.services,
            data: [...state.services.data, payload.data],
          };
      })
      .addCase(clonePackage.pending, (state) => {
        state.loading = true;
      })
      .addCase(clonePackage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(clonePackage.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.services)
          state.services = {
            ...state.services,
            data: state.services.data.map((item) => {
              return item?.id === payload.data?.business_service_id ? { ...item, packages: [...(item?.packages || []), payload.data] } : item;
            }),
          };
      });
  },
});

export const servicesReducer = servicesSlice.reducer;
export const { resetServicesState, setSelectedService, setSelectedPackage } = servicesSlice.actions;
