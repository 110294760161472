import i18n from 'translation/i18n';

import { notification } from 'antd';

import { INotificationPayload } from '../types';

export class Notification {
  public static success(payload: INotificationPayload, fallbackTranslationKey = '') {
    notification.success({
      message: payload?.title || i18n.t('common.success'),
      description: i18n.t(fallbackTranslationKey) || payload?.description,
      duration: 5,
      placement: 'bottom',
    });
  }

  public static error(payload: INotificationPayload, fallbackTranslationKey = '') {
    notification.error({
      message: payload?.title || i18n.t('Error'),
      description: Array.isArray(payload?.description) ? payload?.description.join(', ') : payload?.description || i18n.t(fallbackTranslationKey),
      duration: 5,
      placement: 'bottom',
    });
  }

  public static info(payload: INotificationPayload, fallbackTranslationKey = '') {
    notification.info({
      message: payload?.title || i18n.t('Error'),
      description: Array.isArray(payload?.description) ? payload?.description.join(', ') : payload?.description || i18n.t(fallbackTranslationKey),
      duration: 5,
      placement: 'bottom',
    });
  }
}
