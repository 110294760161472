import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Avatar, Col, Rate, Row, Tag } from 'antd';
import Link from 'antd/es/typography/Link';

import { EditableSelect } from '@aduvi/components/EditableSelect/EditableSelect';
import { DEFAULT_CELL_STYLE } from '@aduvi/constants';
import { useBusinessCurrency } from '@aduvi/hooks';
import {
  EFieldDataValueType,
  EListFormat,
  EPersonalizedViewOrigin,
  EReferenceEntityType,
  ETextAlignment,
  ICustomFieldSpecificStyle,
  ICustomFieldViewStyle,
  IEntityField,
} from '@aduvi/types';
import { ICustomField, IEntityType, IEntityWithFields } from '@aduvi/types/entity';
import { EListValueType } from '@aduvi/types/fields';

import { setSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch } from 'store/hooks';

import { TaskWarningIcon } from '../../Entity/components/drawer-tabs/tasks/@components/helper';
import { applyNumberFormat, formatListItem, formatWithOrdinal, trimText } from '../helper';

interface ICustomFieldProps {
  field?: ICustomField;
  record?: IEntityWithFields;
  managementFields?: IEntityField[];
  origin?: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  column?: ICustomFieldViewStyle;
  fieldId?: string;
  style?: ICustomFieldSpecificStyle;
}

export const CustomFieldValue = ({ field, style, record, managementFields, origin, entityType, column, fieldId }: ICustomFieldProps) => {
  const dispatch = useAppDispatch();
  const businessCurrency = useBusinessCurrency();
  const { t: translate } = useTranslation();

  const fieldData = field?.field_data?.[0];

  const useCustomField = useMemo(() => {
    const address = [fieldData?.street_one, fieldData?.city, fieldData?.country, fieldData?.province, fieldData?.zip_code].filter((item) => item);

    return {
      [EFieldDataValueType.FIELD_DATA_ADDRESSES]: (
        <span>
          {address.length ? <EnvironmentOutlined /> : <></>}
          <span className='ml-5'>{address.map((item) => item).join(', ')}</span>
        </span>
      ),

      [EFieldDataValueType.FIELD_DATA_EMAILS]: fieldData?.value ? (
        <Link onClick={(e) => e.stopPropagation()} href={`mailto:${fieldData?.value}`}>
          {fieldData?.value}
        </Link>
      ) : (
        <></>
      ),
      [EFieldDataValueType.FIELD_DATA_FILES]: fieldData?.url ? (
        <Link target='_blank' onClick={(e) => e.stopPropagation()} href={`${fieldData.url}`}>
          {translate('personalizedView.viewFile')}
        </Link>
      ) : (
        <></>
      ),

      [EFieldDataValueType.FIELD_DATA_IMAGES]: fieldData?.url ? <Avatar size='large' src={fieldData?.url} /> : <></>,
      [EFieldDataValueType.FIELD_DATA_LISTS]: (() => {
        const format = style?.listFormat?.toString() ?? DEFAULT_CELL_STYLE.listFormat;

        const formattedList =
          field?.field_data?.map((option, index) => {
            const value = String(option?.value);
            const formattedValue = formatListItem(value, index, format);

            return { formattedValue, color: option?.color };
          }) ?? [];

        if (origin === EPersonalizedViewOrigin.TASKS) {
          return field?.field_data?.map((option, index) =>
            field?.title === EListValueType.STATUS || field?.title === EListValueType.PRIORITY ? (
              <div
                key={index}
                className='list-options-wrapper'
                onClick={(e) => {
                  dispatch(setSelectedEntity(record));
                  e.stopPropagation();
                }}>
                <EditableSelect
                  field={field}
                  managementFields={managementFields}
                  option={field?.field_data?.[index]}
                  record={record}
                  entityType={entityType}
                />
              </div>
            ) : (
              <Tag key={index} style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}>
                {format === EListFormat.NUMBERED || format === EListFormat.BULLET
                  ? `${formatListItem(option?.value.toString(), index, format)}`
                  : formattedList.map((item) => item.formattedValue).join(', ')}
              </Tag>
            ),
          );
        }

        return (
          <div style={format === EListFormat.NUMBERED || format === EListFormat.BULLET ? { display: 'flex', flexDirection: 'column' } : {}}>
            {formattedList.map((item, index) => (
              <Tag
                key={index}
                style={{ whiteSpace: 'pre-line', lineBreak: 'anywhere' }}
                color={format === EListFormat.BULLET || format === EListFormat.NUMBERED ? undefined : item?.color}>
                {item.formattedValue}
              </Tag>
            ))}
          </div>
        );
      })(),

      [EFieldDataValueType.FIELD_DATA_STAR_RATINGS]: fieldData?.value ? <Rate value={Number(fieldData?.value)} disabled /> : <></>,

      [EFieldDataValueType.FIELD_DATA_PHONES]: fieldData?.value ? (
        <Link onClick={(e) => e.stopPropagation()} href={`tel:${fieldData?.value}`}>
          +{fieldData?.value}
        </Link>
      ) : (
        <></>
      ),
      [EFieldDataValueType.FIELD_DATA_LONG_TEXTS]: (() => {
        const maxLength = typeof style?.maxLength === 'number' ? style?.maxLength : DEFAULT_CELL_STYLE.maxLength;
        const textValue = fieldData?.value ?? '';

        const trimmedText = trimText(String(textValue), maxLength);

        return <span dangerouslySetInnerHTML={{ __html: trimmedText }} />;
      })(),
      [EFieldDataValueType.FIELD_DATA_TEXTS]: (() => {
        const parsedValue = parseFloat(fieldData?.value as string);

        if (!isNaN(parsedValue)) {
          const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;
          const format = style?.numberFormat?.toString() ?? DEFAULT_CELL_STYLE.numberFormat;

          return (
            <span>
              {applyNumberFormat(parsedValue, format, alignment as ETextAlignment, {
                code: businessCurrency?.code as string,
                format: businessCurrency?.format as string,
              })}
            </span>
          );
        }
        return <span>{fieldData?.value}</span>;
      })(),

      [EFieldDataValueType.FIELD_DATA_TIMES]: fieldData?.value ? (
        <Row>
          <ClockCircleOutlined />
          <span className='ml-5'>{fieldData?.value}</span>
        </Row>
      ) : (
        <></>
      ),
      [EFieldDataValueType.FIELD_DATA_DATE_TIMES]: fieldData?.value ? (
        origin === EPersonalizedViewOrigin.TASKS ? (
          <Row style={{ gap: '3px' }}>
            <TaskWarningIcon dueDate={dayjs(fieldData.value)} record={record} />
          </Row>
        ) : (
          <Row>
            <CalendarOutlined />
            <span className='ml-5'>
              {formatWithOrdinal(fieldData?.value?.toString(), style?.dateFormat?.toString() ?? DEFAULT_CELL_STYLE.dateFormat?.toString())}
            </span>
          </Row>
        )
      ) : (
        <></>
      ),
      [EFieldDataValueType.FIELD_DATA_URLS]: fieldData?.value ? (
        <Link onClick={(e) => e.stopPropagation()} href={`${fieldData?.value}`} target='_blank'>
          {fieldData?.value}
        </Link>
      ) : (
        <></>
      ),
      [EFieldDataValueType.FIELD_DATA_NUMBERS]: (() => {
        const parsedValue = parseFloat(fieldData?.value as string);

        if (!isNaN(parsedValue)) {
          const alignment = style?.alignment ?? DEFAULT_CELL_STYLE.alignment;
          const format = style?.numberFormat?.toString() ?? DEFAULT_CELL_STYLE.numberFormat;
          const formatedValue = applyNumberFormat(parsedValue, format, alignment as ETextAlignment, {
            code: businessCurrency?.code as string,
            format: businessCurrency?.format as string,
          });
          return <span>{formatedValue}</span>;
        }
      })(),
      [EFieldDataValueType.FIELD_DATA_SEQUENTIAL_IDS]: (
        <>
          <span>{fieldData?.value}</span>
        </>
      ),
      [EFieldDataValueType.FIELD_DATA_SONGS]: <></>,
      [EFieldDataValueType.FIELD_DATA_SIGNATURES]: <></>,
      [EFieldDataValueType.FIELD_DATA_REFERENCE]: (
        <span>
          {field?.field_data?.map((item, index) =>
            [EReferenceEntityType.TEAM_USER]?.includes(field?.reference_entity_type) && item?.profile_picture ? (
              <Row key={item.id}>
                {
                  <Row>
                    <Avatar src={item.profile_picture} />
                  </Row>
                }
              </Row>
            ) : (
              <Row key={item.id}>
                {index + 1 + ') '}
                {item.name
                  ? item.name
                  : item.custom_fields?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value ??
                    item.custom_fields?.find((item) => item?.field_data?.[0]?.value)?.field_data?.[0]?.value ??
                    item?.first_name + ' ' + item?.last_name}
              </Row>
            ),
          )}
        </span>
      ),
    };
  }, [field, fieldId, column, fieldData?.value, record, style]);

  if (!field) return <></>;

  return <Col className='custom-field-value'>{useCustomField[field.value_type]}</Col>;
};
