import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';

import { EShoppingBasketPosition } from '@aduvi/constants';

import { IBranding } from './business';
import { IContract } from './contract';
import { IUpsertEntity } from './entity';
import { ICreateEntityFieldPayload, IEntityField } from './fields';

export enum EFontFamily {
  POPPINS = 'Poppins',
  ROBOTO = 'Roboto',
  RUBIK = 'Rubik',
  LATO = 'Lato',
  WORK_SANS = 'Work Sans',
  OPEN_SANS = 'Open Sans',
  Oswald = 'Oswald',
}

export enum EFormFormat {
  SINGLE_PAGE = 1,
  MULTI_PAGE_OPTIONAL = 2,
  MULTI_PAGE_INDIVIDUAL = 3,
}

export enum EFormLayout {
  BACKGROUND_FULL_CONTENT_LEFT = 1,
  BACKGROUND_FULL_CONTENT_CENTER = 2,
  BACKGROUND_FULL_CONTENT_RIGHT = 3,
  CONTENT_LEFT_BACKGROUND_RIGHT = 4,
  CONTENT_FULL_NO_BACKGROUND = 5,
  CONTENT_RIGHT_BACKGROUND_LEFT = 6,
}
export enum ERedirectTo {
  THANK_YOU_PAGE = 'thank_you_page',
  CLIENT_PORTAL = 'client_portal',
  APPOINTMENT_BOOKING = 'appointment_booking',
  EXTERNAL_URL = 'external_url',
}

export enum EAfterFormSubmitType {
  SIGN_CONTRACT = 'sign_contract',
  PAYMENT = 'payment',
  SET_STATUS_TO = 'set_status_to',
  REDIRECT = 'redirect_to',
}

export enum EFormType {
  LEADS_FORM = 'LEADS_FORM',
  BOOKINGS_FORM = 'BOOKINGS_FORM',
  CONTACTS_FORM = 'CONTACTS_FORM',
}

export enum EFormProductDisplayType {
  GRID = 'GRID',
  ROWS = 'ROWS',
  CAROUSEL = 'CAROUSEL',
  SLIDESHOW = 'SLIDESHOW',
  BACKDROPS = 'BACKDROPS',
  DROPDOWN = 'DROPDOWN',
}

export interface IFormSettings {
  name: string;
  thank_you_page_message: string;
  form_url: string;
  brand_id: string;
  brand?: IBranding;
  embed_widget_code: string;
  embed_widget_remove_styling: boolean;
  embed_form_code: string;
  form_submit_automation: {
    title: string;
    type: EAfterFormSubmitType;
    value: string;
    url: string;
    redirect_type?: ERedirectTo;
  }[];
  contract_template?: IContract;
  contract_custom_fields?: IEntityField[];
  embed_form_remove_styling: boolean;
  form_data_lead: boolean;
  style: {
    form_style: {
      background_color: string;
      background_image: string;
      background_mode: 'colour' | 'image';
      form_background: string;
      font_family: string;
      format: EFormFormat;
      primary_font_color: string;
      secondary_font_color: string;
      layout: EFormLayout;
      progress_bar: boolean;
      shopping_basket: boolean;
      logo: string;
      shopping_basket_position: EShoppingBasketPosition;
    }[];
    page_breaks: {
      field_id: string;
      field_instance: IFieldInstance;
      isPageBreak: boolean;
      field: IEntityField;
    }[];
  };
}

export interface IEntityFormFieldInstance extends IFieldInstance {
  conditionals: unknown;
  created_at: string;
  deleted_at: string;
  field: IEntityField;
}

export interface IEntityForm extends IFormSettings {
  id: string;
  business_id: string;
  entity_type_id: string;
  field_instances: IEntityFormFieldInstance[];
}

export interface ICreateEntityFormPayload extends Omit<IEntityForm, 'id' | 'field_instances'> {}

export interface IDeleteEntityFormPayload {
  form_id: string;
  business_id: string;
  entity_type_id: string;
  form_name?: string;
}

export interface ICloneEntityFormPayload extends IDeleteEntityFormPayload {}

export interface IGetFormByIdPayload extends IDeleteEntityFormPayload {}

export interface IEntityFormState {
  entityForms?: IEntityForm[];
  selectedEntityForm?: IEntityForm;
  loading: boolean;
  creating: boolean;
  updating: boolean;
  deleting: boolean;
  cloning: boolean;
  loadingEntity: boolean;
  creatingEntity: boolean;
  fields: IBookingFormFieldProps[];
}

export interface IFieldInstance {
  id?: string;
  title: string;
  description: string;
  weight: number;
  default_value: string;
  place_holder: string;
  required: boolean;
  multiple: boolean;
  hidden: boolean;
  half_width: boolean;
  field?: IEntityField;
  display_settings: {
    fontSize: number;
    bold: boolean;
    italic: boolean;
    textColor: string;
    backgroundTextColor: string;
    custom_fields?: IEntityField[];
    product_display_type?: EFormProductDisplayType;
    product_category_id?: string;
  };
}

export interface IUpsertFormCustomFields {
  business_id: string;
  entity_type_id: string;
  form_id: string;
  custom_fields: {
    field_id?: string;
    field_base_data?: ICreateEntityFieldPayload['body'];
    field_instance: IFieldInstance;
  }[];
}

export interface ICreateEntityByFormUrl {
  formUrl: string;
  body: {
    custom_fields: IUpsertEntity['custom_fields'];
    package_ids?: string[];
    product_ids?: string[];
    rep_link_id?: string;
  };
}

export interface IAfterFormSubmitTableProps {
  title: string;
  type: EAfterFormSubmitType;
  value?: string;
  redirect_type?: ERedirectTo;
  url?: string;
}

export interface ISignEntityContractPayload {
  formUrl: string;
  entityId: string;
  body: {
    field_id: string;
    custom_fields: {
      field_id: string;
      signature?: {
        signature: string;
        signer_id: string;
        signer_type: string;
        ip: string;
      };
      entity_references?: string[];
    }[];
  };
}
