import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Button, Form, Input } from 'antd';

import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';
import { ELoadingState } from '@aduvi/types';

import { forgetPassword } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { forgetPasswordState } = useAppSelector((state) => state.auth);

  const onFinishHandler = ({ email }: { email: string }) => {
    dispatch(forgetPassword({ email }))
      .unwrap()
      .then(() => {
        navigate('/auth/login');
      })
      .catch(() => {});
  };

  return (
    <Form name='forgot' className='mt-20' initialValues={{ remember: true }} onFinish={onFinishHandler}>
      <Title className='text-center' level={2}>
        {translate('auth.forgotPassword.forgotPassword')}
      </Title>
      <Paragraph className='text-center color-gray-5'>{translate('auth.forgotPassword.forgotPasswordDescription')}</Paragraph>
      <Form.Item name='email' rules={[{ required: true, message: translate('auth.forgotPassword.emailValidation') }]}>
        <Input placeholder={translate('auth.forgotPassword.emailPlaceholder')} />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='w-full'
          disabled={forgetPasswordState === ELoadingState.LOADING}
          loading={forgetPasswordState === ELoadingState.LOADING}>
          {translate('auth.forgotPassword.submitYourEmail')}
        </Button>
      </Form.Item>
      <Paragraph className='text-center cursor-pointer color-gray-5' onClick={() => navigate('/auth/login')}>
        <ArrowLeftOutlined /> {translate('auth.forgotPassword.backToLogin')}
      </Paragraph>
    </Form>
  );
};

export default ForgotPassword;
