import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IGetProductsPayload, IPaginatedResponse, IProduct } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<IPaginatedResponse<IProduct>, IGetProductsPayload>({
      query: ({ business_id, params }) => ({
        url: `/businesses/${business_id}/products`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.PRODUCT }],
    }),
  }),
});

export const { useGetProductsQuery } = productApiSlice;
