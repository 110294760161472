import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';

import { Form, Row } from 'antd';
import { Divider, Input, Select, SelectProps, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { useBusiness } from '@aduvi/hooks';

import { createBuilderCategory } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SelectTemplateCategory = ({ ...props }: SelectProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const {
    templates: { loading, templateCategories },
  } = useAppSelector((state) => state.proposals);

  const addItem = () => {
    if (!selectedBusiness?.id) return;

    form
      .validateFields()
      .then(({ name }) => {
        dispatch(createBuilderCategory({ businessId: selectedBusiness?.id, body: { name } }))
          .unwrap()
          .then(() => {
            form.resetFields();
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <Select
      {...props}
      loading={loading}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Form form={form}>
            <Divider style={{ margin: 0 }} />
            <Row align='middle'>
              <Space style={{ padding: '0 4px 4px' }}>
                <Form.Item
                  className='mt-20'
                  name='name'
                  rules={[{ required: true, message: translate('components.selectProductCategory.nameValidation') }]}>
                  <Input placeholder={translate('assets.productsDrawer.enterItem')} />
                </Form.Item>
                <Button loading={loading} disabled={loading} icon={<PlusOutlined />} onClick={addItem}>
                  {translate('assets.productsDrawer.addItem')}
                </Button>
              </Space>
            </Row>
          </Form>
        </>
      )}
      options={templateCategories?.map((item) => ({ label: item.name, value: item.id }))}
    />
  );
};
