import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICreateManualPaymentPayload, IManualPaymentState } from '@aduvi/types/manual-payment';

import * as ManualPaymentOptionService from 'store/services/manual-payment-option.service';

const initialState: IManualPaymentState = {
  manualPaymentOptions: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
};

export const createManualPaymentOption = createAsyncThunk(
  'manual-payment-option/create',
  async (payload: { business_id: string; title: string }, { rejectWithValue }) => {
    try {
      return await ManualPaymentOptionService.createManualPaymentOption(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateManualPaymentOption = createAsyncThunk(
  'manual-payment-option/update',
  async (payload: { business_id: string; id: string; title: string }, { rejectWithValue }) => {
    try {
      return await ManualPaymentOptionService.updateManualPaymentOption(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getManualPaymentOptionById = createAsyncThunk(
  'manual-payment-option/getById',
  async (payload: { business_id: string; id: string }, { rejectWithValue }) => {
    try {
      return await ManualPaymentOptionService.getManualPaymentOptionById(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAllManualPaymentOptions = createAsyncThunk('manual-payment-option/getAll', async (business_id: string, { rejectWithValue }) => {
  try {
    return await ManualPaymentOptionService.getAllManualPaymentOptions(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteManualPaymentOption = createAsyncThunk(
  'manual-payment-option/delete',
  async (payload: { business_id: string; id: string }, { rejectWithValue }) => {
    try {
      return await ManualPaymentOptionService.deleteManualPaymentOption(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createManualPayment = createAsyncThunk(
  'manual-payment/create',
  async (payload: { business_id: string; quoteId: string; body: ICreateManualPaymentPayload }, { rejectWithValue }) => {
    try {
      return await ManualPaymentOptionService.createManualPayment(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const manualPaymentSlice = createSlice({
  name: 'manualPayments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createManualPaymentOption.pending, (state) => {
        state.creating = true;
      })
      .addCase(createManualPaymentOption.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.manualPaymentOptions.push(payload.data);
      })
      .addCase(createManualPaymentOption.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateManualPaymentOption.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateManualPaymentOption.fulfilled, (state, { payload }) => {
        state.updating = false;
        const index = state.manualPaymentOptions.findIndex((option) => option.id === payload.data.id);
        if (index !== -1) state.manualPaymentOptions[index] = payload.data;
      })
      .addCase(updateManualPaymentOption.rejected, (state) => {
        state.updating = false;
      })
      .addCase(getAllManualPaymentOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllManualPaymentOptions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.manualPaymentOptions = payload.data;
      })
      .addCase(getAllManualPaymentOptions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteManualPaymentOption.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deleteManualPaymentOption.fulfilled, (state, { meta }) => {
        state.deleting = false;
        state.manualPaymentOptions = state.manualPaymentOptions.filter((option) => option.id !== meta.arg.id);
      })
      .addCase(deleteManualPaymentOption.rejected, (state) => {
        state.deleting = false;
      });
  },
});

export const manualPaymentReducer = manualPaymentSlice.reducer;
