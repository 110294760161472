import Color from 'color';

import { ADUVI_DEFAULT_ACTIVE_THEME } from '@aduvi/constants';
import { IActiveTheme, IBusiness, ICurrentUser, ITheme } from '@aduvi/types';

import store from 'store/store';

export class ThemeAdapter {
  private user: ICurrentUser;
  private selectedBusiness: IBusiness;
  private selectedTheme?: ITheme;

  constructor(_user: ICurrentUser, _selectedBusiness: IBusiness, _selectedTheme?: ITheme) {
    this.user = _user;
    this.selectedBusiness = _selectedBusiness;
    this.selectedTheme = _selectedTheme || this.getSelectedTheme();
  }

  get buttonBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get buttonBgHover(): string {
    return Color(this.buttonBg).lighten(0.2).hex();
  }

  get buttonBgActive(): string {
    return Color(this.buttonBg).darken(0.2).hex();
  }

  get buttonText(): string {
    return this.selectedTheme?.button_style.button_text || ADUVI_DEFAULT_ACTIVE_THEME.buttonText;
  }

  get bodyBg(): string {
    return this.selectedTheme?.body_style.body_bg || ADUVI_DEFAULT_ACTIVE_THEME.bodyBg;
  }

  get bodyText(): string {
    return this.selectedTheme?.body_style.body_text || ADUVI_DEFAULT_ACTIVE_THEME.bodyText;
  }

  get bodyHeadings(): string {
    return this.selectedTheme?.body_style.body_headings || ADUVI_DEFAULT_ACTIVE_THEME.bodyHeadings;
  }

  get bodyRowBg(): string {
    return this.selectedTheme?.body_style.body_row_bg || ADUVI_DEFAULT_ACTIVE_THEME.bodyRowBg;
  }

  get bodyRowText(): string {
    return this.selectedTheme?.body_style.body_row_text || ADUVI_DEFAULT_ACTIVE_THEME.bodyRowText;
  }

  get menuBg(): string {
    return this.selectedTheme?.menu_style.menu_bg || ADUVI_DEFAULT_ACTIVE_THEME.menuBg;
  }

  get menuActiveBg(): string {
    return this.selectedTheme?.menu_style.menu_active_bg || ADUVI_DEFAULT_ACTIVE_THEME.menuActiveBg;
  }

  get menuText(): string {
    return this.selectedTheme?.menu_style.menu_text || ADUVI_DEFAULT_ACTIVE_THEME.menuText;
  }

  get menuShadow(): string {
    return this.selectedTheme?.menu_style.menu_shadow || ADUVI_DEFAULT_ACTIVE_THEME.menuShadow;
  }

  get logo(): string {
    return this.selectedTheme?.logo ? this.selectedTheme?.logo : ADUVI_DEFAULT_ACTIVE_THEME.logo;
  }

  get menuActiveText(): string {
    return this.selectedTheme?.menu_style.menu_active_text || ADUVI_DEFAULT_ACTIVE_THEME.menuActiveText;
  }

  get menuTextItem(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get globalFieldText(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get switchBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get switchBgHover(): string {
    return Color(this.buttonBg).lighten(0.2).hex();
  }

  get calendarBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get radioBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get radioBgHover(): string {
    return Color(this.buttonBg).lighten(0.2).hex();
  }

  get tabBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get tabBgHover(): string {
    return Color(this.buttonBg).lighten(0.2).hex();
  }

  get checkboxBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get checkboxBgHover(): string {
    return Color(this.buttonBg).lighten(0.2).hex();
  }

  get titleText(): string {
    return this.selectedTheme?.menu_style.menu_text || ADUVI_DEFAULT_ACTIVE_THEME.menuText;
  }

  get spinBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get productTypeBg(): string {
    return this.selectedTheme?.button_style.button_bg || ADUVI_DEFAULT_ACTIVE_THEME.buttonBg;
  }

  get icon(): string {
    return this.selectedTheme?.icon ? this.selectedTheme?.icon : ADUVI_DEFAULT_ACTIVE_THEME.icon;
  }

  get dashboardBg(): string {
    return this.getColorByTime();
  }

  get darkModeEnabled(): boolean {
    return !!this.selectedTheme?.dark_mode_enabled;
  }

  public getActiveTheme(): IActiveTheme {
    return {
      activeThemeId: this?.selectedTheme?.id,
      buttonBg: this.buttonBg,
      buttonBgActive: this.buttonBgActive,
      buttonBgHover: this.buttonBgHover,
      buttonText: this.buttonText,
      bodyBg: !this.darkModeEnabled ? this.bodyBg : undefined,
      bodyText: !this.darkModeEnabled ? this.bodyText : undefined,
      bodyHeadings: !this.darkModeEnabled ? this.bodyHeadings : undefined,
      bodyRowBg: !this.darkModeEnabled ? this.bodyRowBg : undefined,
      bodyRowText: !this.darkModeEnabled ? this.bodyRowText : undefined,
      menuBg: !this.darkModeEnabled ? this.menuBg : undefined,
      menuText: !this.darkModeEnabled ? this.menuText : undefined,
      menuActiveBg: !this.darkModeEnabled ? this.menuActiveBg : undefined,
      menuActiveText: !this.darkModeEnabled ? this.menuActiveText : undefined,
      menuShadow: !this.darkModeEnabled ? this.menuShadow : undefined,
      menuItemText: !this.darkModeEnabled ? this.buttonBg : undefined,
      globalFieldText: !this.darkModeEnabled ? this.buttonBg : undefined,
      switchBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      switchBgHover: this.buttonBgHover,
      calendarBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      radioBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      radioBgHover: this.buttonBgHover,
      tabBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      tabBgHover: this.buttonBgHover,
      checkboxBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      checkboxBgHover: this.buttonBgHover,
      spinBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      productTypeBg: !this.darkModeEnabled ? this.buttonBg : undefined,
      dashboardBg: this.dashboardBg,
      darkModeEnabled: this.darkModeEnabled,
      titleText: !this.darkModeEnabled ? this.bodyText : undefined,
      logo: this.logo,
      icon: this.icon,
    };
  }

  private getSelectedTheme(): ITheme | undefined {
    const themes = store.getState().common.themes;
    const selectedThemeId = this.user?.businesses?.find((business) => business.id === this.selectedBusiness?.id)?.users?.partner_theme_id;
    return themes?.find((theme) => theme?.id === selectedThemeId);
  }

  private getColorByTime(): string {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 12) {
      return this.selectedTheme?.dashboard.dashboard_morning || ADUVI_DEFAULT_ACTIVE_THEME.dashboardMorning; // Morning
    } else if (currentHour >= 12 && currentHour < 18) {
      return this.selectedTheme?.dashboard?.dashboard_afternoon || ADUVI_DEFAULT_ACTIVE_THEME.dashboardAfternoon; // Afternoon
    }

    return this.selectedTheme?.dashboard?.dashboard_night || ADUVI_DEFAULT_ACTIVE_THEME.dashboardNight; // Evening
  }
}
