import React from 'react';

import { Row, Spin, Switch, Typography } from 'antd';

import { useGetContactManagementFields } from '@aduvi/hooks/useGetContactManagementFields';
import { IEntityField } from '@aduvi/types';

import { IBookingFormFieldProps } from '../helper';

interface IProps {
  selectedField: IBookingFormFieldProps | undefined;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const ContactManagementFieldsSettings = ({ selectedField, setFields }: IProps) => {
  const { contactCustomFields, isLoading } = useGetContactManagementFields();

  const updateFields =
    (customField: IEntityField, checked: boolean) =>
    (prev: IBookingFormFieldProps[]): IBookingFormFieldProps[] => {
      return prev.map((item) => {
        if (item.field_instance && item.field_instance.display_settings) {
          const customFields = item.field_instance.display_settings.custom_fields;

          const updatedCustomFields = checked
            ? Array.isArray(customFields)
              ? [...customFields.filter((field): field is IEntityField => !!field), customField]
              : [customField]
            : customFields?.filter((field): field is IEntityField => !!field && field.id !== customField.id);

          return {
            ...item,
            field_instance: {
              ...item.field_instance,
              display_settings: {
                ...item.field_instance.display_settings,
                custom_fields: updatedCustomFields,
              },
            },
          };
        }
        return item;
      });
    };

  const onFieldToggle = (checked: boolean, customField: IEntityField | undefined) => {
    if (!customField) return;
    setFields((prevState) => updateFields(customField, checked)(prevState));
  };

  return (
    <Spin spinning={isLoading}>
      <Typography.Paragraph type='secondary'>Fields</Typography.Paragraph>
      {contactCustomFields?.data?.map((item, index) => (
        <Row key={item.id} className={`${index === 0 ? '' : 'mt-10'} pr-20`} align={'middle'}>
          <Switch
            size='small'
            checked={selectedField?.field_instance?.display_settings?.custom_fields?.some((field) => field.id === item.id)}
            onChange={(checked) => onFieldToggle(checked, item)}
          />
          <span className='ml-15'>{item.title}</span>
        </Row>
      ))}
    </Spin>
  );
};
