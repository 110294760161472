import { ICustomField } from './entity';
import { IUser } from './user';

export interface IRepPayload {
  entity_type: EEntityType;
  commission_type: ECommissionType;
  entity_id: string;
  commission: number;
  link_destination: string;
  rep_balance: number;
}

export interface ICustomData {
  custom_fields: ICustomField[];
}

export interface IRepResponse {
  available_balance?: string;
  businessRepLink: IRepData;
  clicks?: number;
  conversion_rate?: number;
  conversions?: number;
  first_click?: string | null;
  last_click?: string | null;
  paid_balance?: number;
  pending_balance?: number;
}

export interface IRepData {
  id: string;
  entity_type: EEntityType;
  entity_id: string;
  commission_type: string;
  commission: number;
  link_id: string;
  link_destination: string;
  rep_balance: number;
  business_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  entity_data: IUser | ICustomData;
}

export interface IRepState {
  reps: IRepResponse[];
  selectedRep: IRepData | null;
  loading: boolean;
  creating: boolean;
  withdrawalModal: {
    isOpen: boolean;
    repLinkId: string;
    availableAmount: number;
    creating?: boolean;
  };
  withdrawals: {
    loading: boolean;
    data: IWithdrawal[];
  };
  settings: {
    data: IRepSettings | null;
    loading: boolean;
    updating: boolean;
  };
}

export interface IRepSettings {
  id: string | null;
  default_link_destination: string;
  minimum_withdrawal_amount: number;
  cancellation_policy: ECancellationPolicyType;
  referral_expiration: EReferralExpiration;
}

export interface IWithdrawal {
  id: string;
  amount: string;
  payment_method: EPaymentMethod;
  details: string;
  is_paid: boolean;
  business_rep_link_id: string;
  created_at: Date;
}

export interface IWithdrawalPayload {
  id: string | null;
  payment_method: EPaymentMethod;
  details: string;
  business_rep_link_id: string;
  routing: string;
  account: string;
  paypal_email: string;
  description: string;
}

export enum EEntityType {
  USER = 'USER',
  VENUE = 'VENUE',
  CONTACT = 'CONTACT',
}

export enum ECommissionType {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
}

export enum ECancellationPolicyType {
  NO_REWARD = 'NO_REWARD',
  REWARD = 'REWARD',
}

export enum EReferralExpiration {
  ONE_DAY = 1,
  ONE_WEEK = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
  ONE_YEAR = 365,
}

export enum EPaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  PAYPAL = 'PAYPAL',
}
