import { IPaginationParams } from './common';

export enum EAuditTypes {
  REGISTERED = 'registered',
  LOGIN = 'login',
  LOGOUT = 'logout',
  PASSWORD_RESET = 'password_reset',
  TWO_FACTOR_AUTHENTICATION = 'two_factor_authentication',
  ACCOUNT_VERIFIED = 'account_verified',
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  SOFT_DELETED = 'soft_deleted',
  RESTORED = 'restored',
}
export enum EActivityType {
  BUSINESS = 'BUSINESS',
  USER = 'USER',
}
export interface IAudit {
  id: string;
  user_type: string;
  user_id: string;
  event: EAuditTypes;
  auditable_id: string;
  auditable_type: string;
  old_values: string | null;
  new_values: string | null;
  url: string;
  ip_address: string;
  user_agent: string;
  tags: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  user_email?: string;
  user_first_name?: string;
  user_last_name?: string;
  user_profile_picture?: string;
}

export interface IActivity {
  id: string;
  model: string;
  fields: string;
  oldValues: string;
  newValues: string;
  event: EAuditTypes;
  user: {
    profilePicture?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  userId: string;
  createdAt: string;
}

export interface IGetUserActivitiesPayload {
  user_id: string;
  params: IPaginationParams;
}

export interface IGetBusinessActivitiesPayload {
  business_id: string;
  params: IPaginationParams;
}

export interface IActivityDifference {
  path: string;
  oldValue: string;
  newValue: string;
}

export interface IActivityTextProps {
  model: string;
  fields: string;
  event: EAuditTypes;
  newValues?: string;
  oldValues?: string;
  user?: string;
  activityType: EActivityType;
}

export const UserActivityTypes = {
  [EAuditTypes.REGISTERED]: 'profile.activity.registered',
  [EAuditTypes.LOGIN]: 'profile.activity.login',
  [EAuditTypes.LOGOUT]: 'profile.activity.logout',
  [EAuditTypes.PASSWORD_RESET]: 'profile.activity.password_reset',
  [EAuditTypes.TWO_FACTOR_AUTHENTICATION]: 'profile.activity.two_factor_authentication',
  [EAuditTypes.ACCOUNT_VERIFIED]: 'profile.activity.account_verified',
  [EAuditTypes.CREATED]: 'profile.activity.created',
  [EAuditTypes.UPDATED]: 'profile.activity.updated',
  [EAuditTypes.DELETED]: 'profile.activity.deleted',
  [EAuditTypes.SOFT_DELETED]: 'profile.activity.soft_deleted',
  [EAuditTypes.RESTORED]: 'profile.activity.restored',
};

export const BusinessActivityTypes = {
  [EAuditTypes.REGISTERED]: 'settings.security.activity.registered',
  [EAuditTypes.LOGIN]: 'settings.security.activity.login',
  [EAuditTypes.LOGOUT]: 'settings.security.activity.logout',
  [EAuditTypes.PASSWORD_RESET]: 'settings.security.activity.password_reset',
  [EAuditTypes.TWO_FACTOR_AUTHENTICATION]: 'settings.security.activity.two_factor_authentication',
  [EAuditTypes.ACCOUNT_VERIFIED]: 'settings.security.activity.account_verified',
  [EAuditTypes.CREATED]: 'settings.security.activity.created',
  [EAuditTypes.UPDATED]: 'settings.security.activity.updated',
  [EAuditTypes.DELETED]: 'settings.security.activity.deleted',
  [EAuditTypes.SOFT_DELETED]: 'settings.security.activity.soft_deleted',
  [EAuditTypes.RESTORED]: 'settings.security.activity.restored',
};
