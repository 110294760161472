import { UIEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Select } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IProduct } from '@aduvi/types';

import { getProducts, resetProductState } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  addProduct: (item: IProduct) => void;
  existingProductIds: string[];
}

export const ProductsList = ({ addProduct, existingProductIds }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { products, loading } = useAppSelector((state) => state.products);
  const hasPermissionToViewProducts = useUserPermissionCheck(PERMISSIONS.PRODUCTS.VIEW);

  const handleScrollProducts = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;

      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (!products?.current_page || !products.next_page_url) return;
        onGetProducts(products?.current_page + 1, 10);
      }
    },
    [products],
  );

  const onGetProducts = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id || !hasPermissionToViewProducts) return;
      dispatch(getProducts({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id],
  );

  useEffect(() => {
    dispatch(resetProductState());
    onGetProducts(1, 10);
  }, [selectedBusiness?.id]);

  return (
    <Select
      bordered={false}
      style={{ color: 'red' }}
      value={translate('quotes.addProduct')}
      suffixIcon={null}
      loading={loading}
      className='w-full mt-10 mb-10'
      onPopupScroll={handleScrollProducts}>
      {products?.data
        .filter((item) => !existingProductIds?.includes(item.id))
        .map((item, index) => (
          <Select.Option key={item?.id} style={{ borderBottom: index !== products.data.length - 1 ? '1px dashed #5194D870' : '0px' }}>
            <Row justify={'space-between'} onClick={() => addProduct(item)}>
              <Col>{item.name}</Col>
              <Col>${item.price}</Col>
            </Row>
          </Select.Option>
        ))}
    </Select>
  );
};
