import { useState } from 'react';

import { Col, Tabs, TabsProps } from 'antd';
import './ContactCard.scss';

import { ContactCard } from '@aduvi/components/ContactCard/ContactCard';
import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { useAppSelector } from 'store/hooks';
import { useGetContactEntitiesQuery } from 'store/rtk-slice/entity-slice';

import { ContactEntities } from './tabs/ContactEntities';

enum EConactEntityTypes {
  LEADS = 'LEADS',
  BOOKINGS = 'BOOKINGS',
  FILES = 'FILES',
  ACTIVITY = 'ACTIVITY',
}

export const ContactDetails = ({ contact }: { contact: IEntityWithFields }) => {
  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const nameField = contact?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_TEXTS);
  const imageField = contact?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_IMAGES);
  const phoneField = contact?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_PHONES);
  const emailField = contact?.custom_fields?.find((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_EMAILS);

  const [selectedTab, setSelectedTab] = useState<string>(EConactEntityTypes.LEADS);

  const { data: leads, isFetching: loadingLeads } = useGetContactEntitiesQuery(
    {
      businessId: selectedBusiness?.id,
      entityId: contact.id,
      entityTypeId: contact.entity_type_id,
      referencedEntityTypeId: entityTypes.data.JOB?.id,
      status: 'UNCONFIRMED',
    },
    { skip: !selectedBusiness?.id || !entityTypes.data.JOB?.id || selectedTab !== EConactEntityTypes.LEADS },
  );

  const { data: bookings, isFetching: loadingBookings } = useGetContactEntitiesQuery(
    {
      businessId: selectedBusiness?.id,
      entityId: contact.id,
      entityTypeId: contact.entity_type_id,
      referencedEntityTypeId: entityTypes.data.JOB?.id,
      status: 'CONFIRMED',
    },
    { skip: !selectedBusiness?.id || !entityTypes.data.JOB?.id || selectedTab !== EConactEntityTypes.BOOKINGS },
  );

  const existingContact = {
    name: nameField?.field_data?.[0]?.value?.toString() || '',
    image: imageField?.field_data?.[0]?.url?.toString() || '',
    phone: phoneField?.field_data?.[0]?.value?.toString() || '',
    email: emailField?.field_data?.[0]?.value?.toString() || '',
  };

  const items: TabsProps['items'] = [
    {
      key: EConactEntityTypes.LEADS,
      label: leads?.data?.length ? `Leads (${leads?.data?.length || 0})` : 'Leads',
      children: <ContactEntities entities={leads?.data} loading={loadingLeads} />,
    },
    {
      key: EConactEntityTypes.BOOKINGS,
      label: bookings?.data?.length ? `Bookings (${bookings?.data?.length || 0})` : 'Bookings',
      children: <ContactEntities entities={bookings?.data} loading={loadingBookings} />,
    },
    {
      key: EConactEntityTypes.FILES,
      label: `Files`,
      children: <></>,
    },
    {
      key: EConactEntityTypes.ACTIVITY,
      label: `Activity`,
      children: <></>,
    },
  ];

  return (
    <Col key={contact.id}>
      <ContactCard contact={contact} existingContact={existingContact} />
      <Tabs items={items} activeKey={selectedTab} onChange={setSelectedTab} className='mt-15' />
    </Col>
  );
};
