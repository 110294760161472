import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Button, Col, Row, Table, Typography } from 'antd';
import './Invoice.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';

import { getBusinessPublic } from 'store/features/business-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { usePackageColumns } from './hooks/usePackageColumns';
import { useProductColumns } from './hooks/useProductColumns';

const { Title, Text } = Typography;

export const Invoice = () => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const { quotes, user } = useAppSelector((state) => state.clientPortalAuth.user);
  const { selectedBusiness } = useAppSelector((state) => state.business);

  const invoiceRef = useRef<HTMLDivElement>(null);

  const packageColumns = usePackageColumns();
  const productColumns = useProductColumns();

  const products = useMemo(
    () =>
      quotes.selectedQuote?.line_items
        .filter((lineItem) => lineItem?.product !== null)
        .map((item) => ({
          key: item?.product_id,
          product: item?.product?.name,
          qty: item?.quantity,
          price: item?.price,
          tax: item?.tax_rates,
          total: Number(item?.quantity) * Number(item?.price),
        })),
    [quotes.selectedQuote],
  );

  const packages = useMemo(
    () =>
      quotes.selectedQuote?.line_items
        .filter((lineItem) => lineItem.package !== null)
        .map((item) => ({
          key: item?.package_id,
          package: item?.package?.name,
          qty: item?.quantity,
          price: item?.price,
          tax: item?.tax_rates,
          total: Number(item?.quantity) * Number(item?.price),
        })),
    [quotes.selectedQuote],
  );

  const handleDownload = () => {
    if (invoiceRef.current) {
      html2canvas(invoiceRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('invoice.pdf');
      });
    }
  };

  useEffect(() => {
    dispatch(getBusinessPublic());
  }, []);

  return (
    <Row justify={'center'} align={'top'} className='mb-30'>
      <Col span={18}>
        <Row ref={invoiceRef} className='invoice-container'>
          <Col span={24}>
            <Row justify={'space-between'} className='invoice-header mb-30'>
              <Col>
                <Title level={3}>{translate('clientPortal.invoice.invoice')}</Title>
                <Text>{`${translate('clientPortal.invoice.invoiceNumber')}: 251483`}</Text>
                <br />
                <Text>{`${translate('clientPortal.invoice.issueDate')}: ${dayjs(quotes.selectedQuote?.created_at).format(
                  selectedBusiness?.date_format || 'DD/MM/YYYY',
                )}`}</Text>
                <br />
                <Text>{`${translate('clientPortal.invoice.dueDate')}: ${dayjs(quotes.selectedQuote?.created_at)
                  .add(1, 'month')
                  .format(selectedBusiness?.date_format || 'DD/MM/YYYY')}`}</Text>
              </Col>
              <Col className='logo'>LOGO</Col>
            </Row>
            <Row gutter={200} className='mb-30'>
              <Col>
                <Title level={5}>{selectedBusiness?.name}</Title>
                <br />
                {selectedBusiness?.phone_number && <Text>{selectedBusiness?.phone_number}</Text>}
                <br />
                {selectedBusiness?.email && <Text>{selectedBusiness?.email}</Text>}
              </Col>
              <Col>
                <Title level={5}>{translate('clientPortal.invoice.billTo')}</Title>
                <Text>{user?.custom_fields?.find((field) => field?.title === 'Name')?.field_data?.[0]?.value}</Text>
                <br />
                {user?.custom_fields?.find((field) => field?.title === 'Address') ? (
                  <Typography.Paragraph>
                    <CustomFieldValue field={user?.custom_fields?.find((field) => field?.title === 'Address')} />{' '}
                  </Typography.Paragraph>
                ) : (
                  ''
                )}

                <Text>{user?.custom_fields?.find((field) => field?.title === 'Email')?.field_data?.[0]?.value}</Text>
              </Col>
            </Row>

            {packages && packages?.length > 0 && (
              <Table
                className='invoice-table'
                dataSource={packages}
                columns={packageColumns}
                pagination={false}
                showHeader={packages?.length && packages?.length > 0 ? true : false}
              />
            )}
            {products && products?.length > 0 && (
              <Table
                className='invoice-table'
                dataSource={products}
                columns={productColumns}
                showHeader={products?.length && products?.length > 0 ? true : false}
                pagination={false}
              />
            )}
            <Table
              className='invoice-table summary-table'
              dataSource={[]}
              columns={[]}
              pagination={false}
              summary={() => (
                <>
                  <Table.Summary.Row className='fw-bold'>
                    <Table.Summary.Cell index={0}>{translate('clientPortal.invoice.subTotal')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {<PriceDisplay price={Number(quotes?.selectedQuote?.sub_total)} currencySign={selectedBusiness?.currency?.format} />}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} className='fw-bold'>
                      {translate('clientPortal.invoice.tax')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} className='fw-bold'>
                      {
                        <PriceDisplay
                          price={Number(quotes?.selectedQuote?.total) - Number(quotes?.selectedQuote?.sub_total)}
                          currencySign={selectedBusiness?.currency?.format}
                        />
                      }
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell className='fw-bold' index={0}>
                      {translate('clientPortal.invoice.total')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell className='fw-bold' index={1}>
                      {<PriceDisplay price={Number(quotes?.selectedQuote?.total)} currencySign={selectedBusiness?.currency?.format} />}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )}
            />
          </Col>
        </Row>
        <Button type='primary' onClick={handleDownload}>
          {translate('clientPortal.invoice.download')}
        </Button>
      </Col>
    </Row>
  );
};
