import axios from '@aduvi/config/axios';
import { IChatMessage, ICreateMessagePayload, IResponse, ISupportChat, ISupportChatMessage } from '@aduvi/types';

export const createSupportChat = async (payload: ISupportChat): Promise<IResponse<ISupportChatMessage>> => {
  return await axios.post(`/businesses/${payload.business_id}/support-chat`, payload);
};

export const getSupportChats = async (businessId: string): Promise<IResponse<ISupportChat[]>> => {
  return await axios.get(`/businesses/${businessId}/support-chat`);
};

export const getSupportChatInfo = async (businessId: string, supportChatId: string): Promise<IResponse<ISupportChatMessage>> => {
  return await axios.get(`/businesses/${businessId}/support-chat/${supportChatId}`);
};

export const createSupportChatMessage = async (
  businessId: string,
  supportChatId: string,
  payload: ICreateMessagePayload,
): Promise<IResponse<IChatMessage>> => {
  return await axios.post(`/businesses/${businessId}/support-chat/${supportChatId}/messages`, payload);
};
