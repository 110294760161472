import { ReactNode } from 'react';
import {
  ArrowRightOutlined,
  BranchesOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  FacebookOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FilterOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  MailOutlined,
  MessageOutlined,
  MobileOutlined,
  PhoneOutlined,
  PictureOutlined,
  ProjectOutlined,
  ScheduleOutlined,
  UserDeleteOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { EAutomationNodeAction, ENodeType } from '@aduvi/types/automation';
import { toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

import { ActiveCampaignIcon } from '../assets/ActiveCampaignIcon';
import { GoogleAnalyticsIcon } from '../assets/GoogleAnalyticsIcon';
import { MailChimpIcon } from '../assets/MailChimpIcon';
import { MailerliteIcon } from '../assets/MailerliteIcon';
import { PlantATreeIcon } from '../assets/PlantATreeIcon';
import { PushoverNotificationIcon } from '../assets/PushoverNotificationIcon';
import { WebhookIcon } from '../assets/WebhookIcon';
import {
  ARTWORK_TRIGGERS,
  BACKDROP_TRIGGERS,
  BOOKING_TRIGGERS,
  CONTACT_TRIGGERS,
  CONTRACT_TRIGGERS,
  CONVERSATION_TRIGGERS,
  EVENT_TRIGGERS,
  FORM_TRIGGERS,
  INVOICE_TRIGGERS,
  LEAD_TRIGGERS,
  ORDER_TRIGGERS,
  PAYMENT_TRIGGERS,
  PROPOSAL_TRIGGERS,
  REPEATING_TRIGGERS,
  REPS_TRIGGERS,
  STARTSCREEN_TRIGGERS,
  TASK_TRIGGERS,
  TEMPLATE_TRIGGERS,
  USER_TRIGGERS,
  VENUES_TRIGGERS,
} from '../nodes/constants';

export const getDefaultnodeIcon: Record<ENodeType, ReactNode> = {
  ACTION: <PhoneOutlined style={{ fontSize: '18px' }} />,
  LOGIC: <BranchesOutlined style={{ fontSize: '18px' }} />,
  TRIGGER: <CalendarOutlined style={{ fontSize: '18px' }} />,
  ACTION_INTERNAL: '',
  BRANCH: '',
};

export const getNodeIcon: Record<EAutomationNodeAction, ReactNode> = {
  // LEAD
  [EAutomationNodeAction.LEAD_CREATE]: <FilterOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.LEAD_UPDATE]: <FilterOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.LEAD_DELETE]: <FilterOutlined style={{ fontSize: '18px' }} />,
  // CONTACT
  [EAutomationNodeAction.CONTACT_CREATE]: <ContactsOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.CONTACT_UPDATE]: <ContactsOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.CONTACT_DELETE]: <ContactsOutlined style={{ fontSize: '18px' }} />,
  // USER
  [EAutomationNodeAction.USER_CREATE]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_UPDATE]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_DELETE]: <UserOutlined style={{ fontSize: '18px' }} />,
  // VENUE
  [EAutomationNodeAction.VENUE_CREATE]: <EnvironmentOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.VENUE_UPDATE]: <EnvironmentOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.VENUE_DELETE]: <EnvironmentOutlined style={{ fontSize: '18px' }} />,
  // TASK
  [EAutomationNodeAction.TASK_CREATE]: <ProjectOutlined style={{ fontSize: '18px', transform: 'rotate(-90deg)' }} />,
  [EAutomationNodeAction.TASK_UPDATE]: <ProjectOutlined style={{ fontSize: '18px', transform: 'rotate(-90deg)' }} />,
  [EAutomationNodeAction.TASK_DELETE]: <ProjectOutlined style={{ fontSize: '18px', transform: 'rotate(-90deg)' }} />,
  // PROJECT
  [EAutomationNodeAction.PROJECT_CREATE]: <FundProjectionScreenOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROJECT_UPDATE]: <FundProjectionScreenOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROJECT_DELETE]: <FundProjectionScreenOutlined style={{ fontSize: '18px' }} />,
  // BOOKING
  [EAutomationNodeAction.LEAD_CONVERTS_TO_BOOKING]: undefined,
  [EAutomationNodeAction.BOOKING_CREATE]: undefined,
  [EAutomationNodeAction.BOOKING_UPDATE]: undefined,
  [EAutomationNodeAction.BOOKING_DELETE]: undefined,
  [EAutomationNodeAction.BOOKING_FORM_IS_SUBMITTED]: undefined,
  [EAutomationNodeAction.BOOKING_FORM_IS_ABANDONED]: undefined,
  [EAutomationNodeAction.BOOKING_STATUS_IS_CHANGED]: undefined,
  [EAutomationNodeAction.BEFORE_BOOKING_EVENT_DATE_STARTS]: undefined,
  [EAutomationNodeAction.BOOKING_DATE_STARTS]: undefined,
  [EAutomationNodeAction.BOOKING_DATE_ENDS]: undefined,
  [EAutomationNodeAction.PAYMENT_SUCCESS]: undefined,
  [EAutomationNodeAction.PAYMENT_FAIL]: undefined,
  [EAutomationNodeAction.GRATUITY_IS_ADDED]: undefined,
  [EAutomationNodeAction.INVOICE_CREATE]: undefined,
  [EAutomationNodeAction.INVOICE_DELETE]: undefined,
  [EAutomationNodeAction.INVOICE_IS_PAID]: undefined,
  [EAutomationNodeAction.INVOICE_IS_DUE]: undefined,
  [EAutomationNodeAction.INVOICE_IS_OVERDUE]: undefined,
  [EAutomationNodeAction.TASK_IS_COMPLETED]: undefined,
  [EAutomationNodeAction.TASK_IS_DUE]: undefined,
  [EAutomationNodeAction.TASK_IS_OVERDUE]: undefined,
  [EAutomationNodeAction.CONTRACT_IS_VIEWED]: undefined,
  [EAutomationNodeAction.CONTRACT_IS_SIGNED]: undefined,
  [EAutomationNodeAction.CONTRACT_IS_DELETED]: undefined,
  [EAutomationNodeAction.LEAD_FORM_IS_SUBMITTED]: undefined,
  [EAutomationNodeAction.LEAD_FORM_IS_ABANDONED]: undefined,
  [EAutomationNodeAction.LEAD_STATUS_IS_CHANGED]: undefined,
  [EAutomationNodeAction.BEFORE_LEAD_EVENT_DATE_STARTS]: undefined,
  [EAutomationNodeAction.LEAD_EVENT_DATE_STARTS]: undefined,
  [EAutomationNodeAction.LEAD_EVENT_DATE_ENDS]: undefined,
  [EAutomationNodeAction.LEAD_DATE_FIELD_DUE]: undefined,
  [EAutomationNodeAction.UPSELL_IS_CLAIMED]: undefined,
  [EAutomationNodeAction.CONTACT_LOGGED_IN]: undefined,
  [EAutomationNodeAction.CONTACT_REGISTERED]: undefined,
  [EAutomationNodeAction.USER_REQUESTS_SHIFT]: undefined,
  [EAutomationNodeAction.USER_DECLINES_SHIFT]: undefined,
  [EAutomationNodeAction.PAYMENT_FAILED]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_ACCEPTS_SHIFT]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_ASSIGNED_TO_SHIFT]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_IS_REMOVED_FROM_SHIFT]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_CHANGES]: undefined,
  [EAutomationNodeAction.ENTITY_FIELD_UPDATED]: undefined,
  [EAutomationNodeAction.IF_ELSE]: <BranchesOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.SPLIT]: <UserDeleteOutlined style={{ fontSize: '18px', transform: 'scaleX(-1)' }} />,
  [EAutomationNodeAction.GO_TO]: <ArrowRightOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.FORMAT_NUMBER]: <UserDeleteOutlined style={{ fontSize: '18px', transform: 'scaleX(-1)' }} />,
  [EAutomationNodeAction.FORMAT_DATE]: <UserDeleteOutlined style={{ fontSize: '18px', transform: 'scaleX(-1)' }} />,
  [EAutomationNodeAction.CHAT_GPT_ACTION]: <UserDeleteOutlined style={{ fontSize: '18px', transform: 'scaleX(-1)' }} />,
  [EAutomationNodeAction.WAIT]: <ClockCircleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.SEND_EMAIL]: <MailOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.SEND_SMS]: <MobileOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.SEND_WHATSAPP_MESSAGE]: <WhatsAppOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.WEBHOOK]: <WebhookIcon />,
  [EAutomationNodeAction.PUSHOVER_NOTIFICATION]: <PushoverNotificationIcon />,
  [EAutomationNodeAction.MAILCHIMP]: <MailChimpIcon />,
  [EAutomationNodeAction.ACTIVE_CAMPAIGN]: <ActiveCampaignIcon />,
  [EAutomationNodeAction.MAILER_LITE]: <MailerliteIcon />,
  [EAutomationNodeAction.GOOGLE_ANALYTICS]: <GoogleAnalyticsIcon />,
  [EAutomationNodeAction.FACEBOOK_PIXEL]: <FacebookOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PLANT_TREE]: <PlantATreeIcon />,
  [EAutomationNodeAction.PHONE_CALL]: undefined,
  [EAutomationNodeAction.VOICE_MAIL]: undefined,
  [EAutomationNodeAction.CHANGE_FIELD_DATA]: undefined,
  [EAutomationNodeAction.SEND_CONVERSATION_MESSAGE]: undefined,
  [EAutomationNodeAction.CREATE_TASK]: undefined,
  [EAutomationNodeAction.CREATE_PROPOSAL]: undefined,
  [EAutomationNodeAction.ELSE_IF_BRANCH]: undefined,
  [EAutomationNodeAction.IF_BRANCH]: undefined,
  [EAutomationNodeAction.ELSE_BRANCH]: undefined,

  [EAutomationNodeAction.INVOICE_UPDATE]: undefined,
  [EAutomationNodeAction.CONTRACT_CREATE]: undefined,
  [EAutomationNodeAction.CONTRACT_UPDATE]: undefined,
  [EAutomationNodeAction.CONTRACT_DELETE]: undefined,
  [EAutomationNodeAction.PAYMENT_CREATE]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PAYMENT_UPDATE]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PAYMENT_DELETE]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PAYMENT_MANUAL_CREATE]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PAYMENT_REFUNDED]: <CreditCardOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.LEAD_STATUS_CHANGED]: <FilterOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.LEAD_CONVERTED]: <FilterOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.LEAD_CONVERSION_FAILED]: <FilterOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.BOOKING_STATUS_CHANGED]: undefined,
  [EAutomationNodeAction.BOOKING_STARTED]: undefined,
  [EAutomationNodeAction.BOOKING_ENDED]: undefined,
  [EAutomationNodeAction.ARTWORK_CREATE]: <PictureOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ARTWORK_UPDATE]: <PictureOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ARTWORK_COMMENT]: <PictureOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ARTWORK_ACCEPTED]: <PictureOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROPOSAL_VIEWED]: <FileTextOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROPOSAL_CREATE]: <FileTextOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROPOSAL_ACCEPTED]: <FileTextOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROPOSAL_ACCEPTANCE_FAILED]: <FileTextOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.PROPOSAL_SENT]: <FileTextOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.TASK_COMPLETED]: <ProjectOutlined style={{ fontSize: '18px', transform: 'rotate(-90deg)' }} />,
  [EAutomationNodeAction.TASK_DEADLINE_DUE]: <ProjectOutlined style={{ fontSize: '18px', transform: 'rotate(-90deg)' }} />,

  [EAutomationNodeAction.INVOICE_DUE]: <FilePdfOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_ASSIGNED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_REMOVED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_ACCEPTED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_DECLINED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_STARTED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.USER_SHIFT_ENDED]: <UserOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.REP_WITHDRAWAL_REQUEST]: undefined,
  [EAutomationNodeAction.REP_WITHDRAWAL_PAID]: undefined,
  [EAutomationNodeAction.CONTRACT_SIGNED]: undefined,
  [EAutomationNodeAction.FORM_SUBMITTED]: <FormOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.FORM_ABANDONED]: <FormOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.FORM_SUBMISSION_UPDATED]: <FormOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.FORM_WIDGET_SUBMITTED]: <FormOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.EVENT_STARTED]: undefined,
  [EAutomationNodeAction.EVENT_ENDED]: undefined,
  [EAutomationNodeAction.TEMPLATE_CHOSEN]: <FileSyncOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.TEMPLATE_CHOICE_DELETED]: <FileSyncOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.BACKDROP_CHOSEN]: <FileDoneOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.BACKDROP_CHOICE_DELETED]: <FileDoneOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.STARTSCREEN_CHOSEN]: <FundProjectionScreenOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.STARTSCREEN_CHOICE_DELETED]: <FundProjectionScreenOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ORDER_CREATE]: <ScheduleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ORDER_UPDATE]: <ScheduleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ORDER_DELETE]: <ScheduleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.ORDER_STATUS_CHANGED]: <ScheduleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.MESSAGE_RECEIVED]: <MessageOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.DAILY]: <ClockCircleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.WEEKLY]: <ClockCircleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.MONTHLY]: <ClockCircleOutlined style={{ fontSize: '18px' }} />,
  [EAutomationNodeAction.YEARLY]: <ClockCircleOutlined style={{ fontSize: '18px' }} />,
};

export const useGetTriggers = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  const createTrigger = (action: EAutomationNodeAction, triggerable_id?: string, triggerable_type?: string) => ({
    id: uuidv4(),
    action,
    triggerable_type: triggerable_type || undefined,
    title: toHumanReadable(action),
    triggerable_id: triggerable_id || undefined,
    node_type: ENodeType.TRIGGER,
  });

  // Helper function to map triggers with common config
  const mapTriggers = (triggers: EAutomationNodeAction[], triggerable_id?: string, triggerable_type?: string) =>
    triggers.map((trigger) => createTrigger(trigger, triggerable_type, triggerable_id));

  const leadTriggers = mapTriggers(LEAD_TRIGGERS, 'LEADS', entityTypes?.data?.JOB?.id);
  const bookingTriggers = mapTriggers(BOOKING_TRIGGERS, 'BOOKINGS', entityTypes?.data?.JOB?.id);
  const formTriggers = mapTriggers(FORM_TRIGGERS, 'FORMS', undefined);
  const artworkTriggers = mapTriggers(ARTWORK_TRIGGERS, 'ARTWORK', ETriggerTypes.ARTWORK);
  const proposalTriggers = mapTriggers(PROPOSAL_TRIGGERS, 'PROPOSAL', ETriggerTypes.PROPOSAL);
  const taskTriggers = mapTriggers(TASK_TRIGGERS, entityTypes?.data?.TASK?.name, entityTypes?.data?.TASK?.id);
  const paymentTriggers = mapTriggers(PAYMENT_TRIGGERS, 'PAYMENT', ETriggerTypes.PAYMENT);
  const invoiceTriggers = mapTriggers(INVOICE_TRIGGERS, 'INVOICE', ETriggerTypes.INVOICE);
  const contactTriggers = mapTriggers(CONTACT_TRIGGERS, entityTypes?.data?.CONTACT?.name, entityTypes?.data?.CONTACT?.id);
  const repsTriggers = mapTriggers(REPS_TRIGGERS, 'REPS', ETriggerTypes.REP_WITHDRAWAL);
  const contractTriggers = mapTriggers(CONTRACT_TRIGGERS, 'CONTRACT', ETriggerTypes.CONTRACT);
  const templateTriggers = mapTriggers(TEMPLATE_TRIGGERS, 'TEMPLATE', ETriggerTypes.TEMPLATE);
  const backdropTriggers = mapTriggers(BACKDROP_TRIGGERS, 'BACKDROP', ETriggerTypes.BACKDROP);
  const startScreenTriggers = mapTriggers(STARTSCREEN_TRIGGERS, 'STARTSCREEN', ETriggerTypes.STARTSCREEN);
  const orderTriggers = mapTriggers(ORDER_TRIGGERS, entityTypes?.data?.ORDER?.name, entityTypes?.data?.ORDER?.id);
  const conversationTriggers = mapTriggers(CONVERSATION_TRIGGERS, 'Conversation', ETriggerTypes.MESSAGE);
  const repeatingTriggers = mapTriggers(REPEATING_TRIGGERS, 'Repeating', undefined);
  const userTriggers = mapTriggers(USER_TRIGGERS, entityTypes?.data?.TEAM_USER?.name, entityTypes?.data?.TEAM_USER?.id);
  const venueTriggers = mapTriggers(VENUES_TRIGGERS, entityTypes?.data?.VENUE?.name, entityTypes?.data?.VENUE?.id);
  const eventTriggers = mapTriggers(EVENT_TRIGGERS, entityTypes?.data?.EVENT?.name, entityTypes?.data?.EVENT?.id);

  return {
    leadTriggers,
    bookingTriggers,
    formTriggers,
    artworkTriggers,
    proposalTriggers,
    taskTriggers,
    paymentTriggers,
    invoiceTriggers,
    contactTriggers,
    userTriggers,
    venueTriggers,
    eventTriggers,
    templateTriggers,
    backdropTriggers,
    startScreenTriggers,
    orderTriggers,
    conversationTriggers,
    repeatingTriggers,
    repsTriggers,
    contractTriggers,
  };
};

export const useLogics = () => {
  const logics = [
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'If / Else',
      triggerable_id: undefined,
      action: EAutomationNodeAction.IF_ELSE,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Split',
      triggerable_id: undefined,
      action: EAutomationNodeAction.SPLIT,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Go To',
      triggerable_id: undefined,
      action: EAutomationNodeAction.GO_TO,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Format Number',
      triggerable_id: undefined,
      action: EAutomationNodeAction.FORMAT_NUMBER,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Format Date',
      triggerable_id: undefined,
      action: EAutomationNodeAction.FORMAT_DATE,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Chat GPT',
      triggerable_id: undefined,
      action: EAutomationNodeAction.CHAT_GPT_ACTION,
      node_type: ENodeType.LOGIC,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: 'Wait',
      triggerable_id: undefined,
      action: EAutomationNodeAction.WAIT,
      node_type: ENodeType.LOGIC,
    },
  ];

  return { logics };
};

export const useActions = () => {
  const actions = [
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Email`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.SEND_EMAIL,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `SMS`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.SEND_SMS,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `WhatsApp`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.SEND_WHATSAPP_MESSAGE,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Webhook`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.WEBHOOK,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Pushover`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.PUSHOVER_NOTIFICATION,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Mailchimp`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.MAILCHIMP,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Active Campaign`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.ACTIVE_CAMPAIGN,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Mailerlite`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.MAILER_LITE,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Google Analytics`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.GOOGLE_ANALYTICS,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Facebook Pixel`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.FACEBOOK_PIXEL,
      node_type: ENodeType.ACTION,
    },
    {
      id: uuidv4(),
      triggerable_type: undefined,
      title: `Plant a Tree`,
      triggerable_id: undefined,
      action: EAutomationNodeAction.PLANT_TREE,
      node_type: ENodeType.ACTION,
    },
  ];

  return { actions };
};

enum ETriggerTypes {
  LEAD = 'LEAD',
  BOOKING = 'BOOKING',
  CONTACT = 'CONTACT',
  USER = 'USER',
  VENUE = 'VENUE',
  TASK = 'TASK',
  PROJECT = 'PROJECT',
  FORM = 'FORM',
  INVOICE = 'INVOICE',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  REP_WITHDRAWAL = 'REP_WITHDRAWAL',
  ORDER = 'ORDER',
  MESSAGE = 'MESSAGE',
  ARTWORK = 'ARTWORK',
  PROPOSAL = 'PROPOSAL',
  TEMPLATE = 'TEMPLATE',
  BACKDROP = 'BACKDROP',
  STARTSCREEN = 'STARTSCREEN',
}
