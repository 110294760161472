import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import { IAudit, IGetBusinessActivitiesPayload } from '@aduvi/types/audit';
import {
  IBranding,
  IBusiness,
  IBusinessTeam,
  ICreateBusinessPayload,
  ICreateBusinessResponse,
  ICreateBusinessTeamPayload,
  ICreateUpdateBusinessTeamResponse,
  IDeleteBusinessTeamPayload,
  IEditBusinessPayload,
  IMenuSettingsPayload,
  IUpdateBusinessTeamPayload,
} from '@aduvi/types/business';
import { ICreateLocationPayload, IDeleteLocationPayload, IEditLocationPayload, ILocation } from '@aduvi/types/location';

export const createBusiness = async (payload: ICreateBusinessPayload): Promise<IResponse<ICreateBusinessResponse>> => {
  return await axios.post('/businesses', payload);
};

export const getBusinesses = async (): Promise<IBusiness[]> => {
  return await axios.get('/businesses');
};

export const editBusiness = async (payload: IEditBusinessPayload): Promise<IResponse<IBusiness>> => {
  return await axios.put(`/businesses/${payload.id}`, payload);
};

export const getBusinessTeams = async (businessId: string): Promise<IResponse<IBusinessTeam[]>> => {
  return await axios.get(`/businesses/${businessId}/teams`);
};

export const createBusinessTeam = async (payload: ICreateBusinessTeamPayload): Promise<IResponse<ICreateUpdateBusinessTeamResponse>> => {
  return await axios.post(`/businesses/${payload.business_id}/teams`, payload.body);
};

export const deleteBusinessTeam = async (payload: IDeleteBusinessTeamPayload): Promise<void> => {
  return await axios.delete(`/businesses/${payload.business_id}/teams/${payload.team_id}?title=${payload.team_name}`);
};

export const updateBusinessTeam = async (payload: IUpdateBusinessTeamPayload): Promise<IResponse<ICreateUpdateBusinessTeamResponse>> => {
  return await axios.put(`/businesses/${payload.business_id}/teams/${payload.team_id}`, payload.body);
};

export const getBusinessTeam = async (businessId: string, teamId: string): Promise<IResponse<IBusinessTeam>> => {
  return await axios.get(`/businesses/${businessId}/teams/${teamId}`);
};

export const createBranding = async (business_id: string, body: IBranding): Promise<IResponse<IBranding>> => {
  return await axios.post(`/businesses/${business_id}/brandings`, body);
};

export const editBranding = async (business_id: string, branding_id: string, body: IBranding): Promise<IResponse<IBranding>> => {
  return await axios.put(`/businesses/${business_id}/brandings/${branding_id}`, body);
};

export const deleteBranding = async (business_id: string, branding_id: string, branding_name: string): Promise<void> => {
  return await axios.delete(`/businesses/${business_id}/brandings/${branding_id}?title=${branding_name}`);
};

export const getBusinessActivities = async (payload: IGetBusinessActivitiesPayload): Promise<IPaginatedResponse<IAudit>> => {
  return await axios.get(`/businesses/${payload.business_id}/activities?order=${payload.params.order}`, { params: payload.params });
};

export const getLocations = async (businessId: string): Promise<IResponse<ILocation[]>> => {
  return await axios.get(`/businesses/${businessId}/locations`);
};

export const createLocation = async (payload: ICreateLocationPayload): Promise<IResponse<ILocation>> => {
  return await axios.post(`/businesses/${payload.businessId}/locations`, payload.body);
};

export const editLocation = async (payload: IEditLocationPayload): Promise<IResponse<ILocation>> => {
  return await axios.put(`/businesses/${payload.businessId}/locations/${payload.locationId}`, payload.body);
};

export const deleteLocation = async (payload: IDeleteLocationPayload): Promise<void> => {
  return await axios.delete(`/businesses/${payload.businessId}/locations/${payload.locationId}?title=${payload.locationName}`);
};

export const upsertMenuSettings = async (payload: IMenuSettingsPayload): Promise<IResponse<IBusiness>> => {
  return await axios.put(`/businesses/${payload.businessId}/menu/settings`, { menu_settings: payload.menu_settings });
};

export const editBusinessSurchargeAmount = async (payload: { business_id: string; payment_surcharge_amount: string }): Promise<void> => {
  return await axios.put(`/businesses/${payload.business_id}/payment/surcharge-amount`, payload);
};

export const getBusinessPublic = async (): Promise<IResponse<IBusiness>> => {
  return await axios.get(`/businesses`);
};
