import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Input } from 'antd';

import Tiptap from '@aduvi/Tiptap/Tiptap';

export const SendConversationMessage = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Form.Item name={'subject'} label={translate('automations.subject')} className='mt-20'>
        <Input />
      </Form.Item>
      <Form.Item name={'message'} label={translate('automations.message')}>
        <Tiptap content={form.getFieldValue('message')} onUpdate={(value) => form.setFieldValue('message', value.getHTML())} />
      </Form.Item>
    </>
  );
};
