import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchPopoverMenu } from 'views/search/SearchPopoverMenu';
import { SupportModal } from 'views/support/SuportModal';

import { Avatar, Badge, ConfigProvider, Image, Layout, Menu, MenuProps } from 'antd';
import './Sider.scss';

import { CustomIcon } from '@aduvi/components/CustomIcon/CustomIcon';
import { UserProfileMenu } from '@aduvi/components/UserProfileMenu/UserProfileMenu';
import { Constants } from '@aduvi/constants';
import { bottomNavigationConfig, navigationConfig } from '@aduvi/constants/navigation-config';
import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { useIsBusinessOwner, useSelectedPlan, useUser, useUserBusinessPermissions } from '@aduvi/hooks';
import { IMenuSetting } from '@aduvi/types';
import { getMenuItem, hasUserPermission, isAppIncludedInPlan } from '@aduvi/utils/helper';

export const Sider = () => {
  const user = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTheme = useActiveTheme();
  const selectedBusiness = useBusiness();
  const isBusinessOwner = useIsBusinessOwner();
  const userBusinessPermissions = useUserBusinessPermissions();
  const { selectedPlan, planApps } = useSelectedPlan();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [searchPopoverVisible, setSearchPopoverVisible] = useState(false);
  const [supportModalVisible, setSupportModalVisible] = useState(false);

  const [selectedNavigationPath, setSelectedNavigationPath] = useState('');
  const [selectedBottomNavigationPath, setSelectedBottomNavigationPath] = useState('');

  const mapTreeDataToNavigationConfig = (menuSettings: IMenuSetting[]) => {
    if (!menuSettings) return [];
    return menuSettings
      .filter((item) => {
        const navConfigItem = navigationConfig.find((nc) => nc.key === item.key);
        const isItemVisible =
          navConfigItem &&
          hasUserPermission(isBusinessOwner, userBusinessPermissions, navConfigItem.permission) &&
          isAppIncludedInPlan(selectedPlan?.data, planApps, navConfigItem?.key);

        return isItemVisible || item.key.startsWith(Constants.Links.MenuExternalLink);
      })
      .map((item) => {
        const navConfigItem = navigationConfig.find((nc) => nc.key === item.key);
        const newItem = { ...navConfigItem, ...item };

        if (newItem.children) {
          newItem.children = newItem.children.map((child) => {
            if (child?.key?.startsWith(Constants.Links.MenuExternalLink)) {
              return child;
            } else {
              const childNavConfigItem = navigationConfig.find((nc) => nc.key === child.key);
              return { ...childNavConfigItem, ...child };
            }
          });
        }

        return newItem;
      });
  };

  const onExternalLinkClick = (url?: string, openTab?: string) => {
    if (!url) return;
    const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
    window.open(formattedUrl, openTab || '_blank');
  };

  const buildMenuItems = (items: IMenuSetting[]) => {
    return items.map((item) => {
      const isExternalLink = item.path && item.key.startsWith(Constants.Links.MenuExternalLink);
      const onClickAction = isExternalLink ? () => onExternalLinkClick(item.path, item.open) : () => onNavigate(item?.path);

      if (item.children && item.children.length > 0) {
        const submenuItems = [
          getMenuItem(
            item.label,
            item.key,
            typeof item.icon === 'string' ? <CustomIcon iconName={item.icon} /> : item.icon,
            undefined,
            undefined,
            onClickAction,
          ),
          ...item.children.map((child) =>
            getMenuItem(
              child.label,
              child.key,
              typeof child.icon === 'string' ? <CustomIcon iconName={child.icon} /> : child.icon,
              undefined,
              undefined,
              child.path && child.key.startsWith(Constants.Links.MenuExternalLink)
                ? () => onExternalLinkClick(child.path, child.open)
                : () => onNavigate(child?.path),
            ),
          ),
        ];

        return getMenuItem(item.label, item.key, typeof item.icon === 'string' ? <CustomIcon iconName={item.icon} /> : item.icon, submenuItems);
      } else {
        return getMenuItem(
          item.label,
          item.key,
          typeof item.icon === 'string' ? <CustomIcon iconName={item.icon} /> : item.icon,
          undefined,
          undefined,
          onClickAction,
        );
      }
    });
  };

  const navigationItems: MenuProps['items'] = useMemo(() => {
    const mappedData: IMenuSetting[] = mapTreeDataToNavigationConfig(
      selectedBusiness?.menu_settings ||
        navigationConfig?.map((config) => ({
          key: config.key,
          label: config?.label || '',
          path: config.path || '',
          permission: config.permission || [],
        })),
    );

    return buildMenuItems(
      mappedData?.filter((item) => {
        return (
          hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission) &&
          isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key)
        );
      }),
    );
  }, [isBusinessOwner, userBusinessPermissions, selectedPlan?.data, planApps, selectedBusiness]);

  const bottomNavigationItems: MenuProps['items'] = useMemo(() => {
    return bottomNavigationConfig
      ?.filter((item) => hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission))
      .map((item) => ({
        key: `/${item.path}`,
        icon: item.icon,
        label: item.label,
        className: item?.className,
        onClick: () => onBottomPress(item?.path),
      }));
  }, [selectedBusiness?.id]);

  const onNavigate = (path: string) => {
    navigate(`/${path}`);
    setSelectedNavigationPath(`/${path}`);
  };

  const onBottomPress = (path: string) => {
    if (path.includes('search')) {
      setSearchPopoverVisible(true);
      return;
    }
    if (path === 'profile') {
      setPopoverVisible(true);
      return;
    }
    if (path.includes('support')) {
      setSupportModalVisible(true);
      return;
    }

    navigate(`/${path}`);
    setSelectedBottomNavigationPath(`/${path}`);
  };

  useEffect(() => {
    const parentPathKey = navigationConfig.find((config) => location?.pathname?.includes(`/${config.path}`))?.key;
    const normalizedPath = parentPathKey ?? location?.pathname.slice(1);
    setSelectedNavigationPath(normalizedPath);
    setSelectedBottomNavigationPath(location?.pathname);
  }, [location?.pathname]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemSelectedBg: activeTheme?.menuActiveBg,
            itemSelectedColor: `${activeTheme?.menuActiveText} !important`,
            itemColor: activeTheme?.menuText,
            popupBg: activeTheme?.menuBg,
          },
        },
      }}>
      <Layout.Sider
        className='aduvi-layout-sider'
        collapsed={true}
        width={200}
        style={{ backgroundColor: activeTheme?.menuBg, boxShadow: `0 2px 8px 0 ${activeTheme?.menuShadow}` }}>
        <div className='flex-center mt-10 mb-10'>
          <Image className='sider-logo' src={activeTheme?.icon} preview={false} onClick={() => navigate('/dashboard')} />
        </div>

        <Menu selectedKeys={[selectedNavigationPath]} items={navigationItems} className='menu-flex-start' style={{ border: 'none' }} />

        <Menu selectedKeys={[selectedBottomNavigationPath]} items={bottomNavigationItems} className='menu-flex-end' style={{ border: 'none' }} />

        <div onClick={() => onBottomPress('profile')} className='flex-center cursor-pointer mt-10 mb-10'>
          <Badge count={0} overflowCount={0}>
            <Avatar src={user?.profile_picture ? user?.profile_picture : undefined} size={40}>
              {user?.first_name?.charAt(0)?.toUpperCase() ?? user?.email?.charAt(0)?.toUpperCase()}
            </Avatar>
          </Badge>
        </div>

        <SupportModal open={supportModalVisible} setSupportModalVisible={setSupportModalVisible} />

        <SearchPopoverMenu onOpenChange={setSearchPopoverVisible} open={searchPopoverVisible} />
        <UserProfileMenu onOpenChange={setPopoverVisible} open={popoverVisible} />
      </Layout.Sider>
    </ConfigProvider>
  );
};
