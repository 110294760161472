import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Col, Empty, Row, Typography } from 'antd';

import { EFieldDataValueType, EReferenceEntityType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

import { FileCard } from './card/FileCard';
import { ContractModal } from './contract-modal/ContractModal';

const extractFileNameFromUrl = (url: string) => {
  const parts = url?.split('/uploads/');
  if (parts?.length === 2) {
    return parts[1];
  }
  return undefined;
};

export const Files = ({ fullscreen = false }: { fullscreen?: boolean }) => {
  const {
    entities: { selectedEntity },
  } = useAppSelector((state) => state.entity);
  const { t: translate } = useTranslation();

  const fileFields = selectedEntity?.custom_fields.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_FILES);
  const contractFields = selectedEntity?.custom_fields?.find(
    (item) => item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type === EReferenceEntityType.CONTRACT,
  );

  const contactField = selectedEntity?.custom_fields?.find(
    (item) => item?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item?.reference_entity_type === EReferenceEntityType.CONTACT,
  )?.field_data?.[0]?.custom_fields;

  const contactInfo =
    contactField?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value ||
    contactField?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_EMAILS)?.field_data?.[0]?.value;

  const signature = contractFields?.field_data?.[0]?.custom_fields?.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_SIGNATURES);
  const contractTemplateData = contractFields?.field_data?.[0]?.custom_fields?.find(
    (item) => item.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE && item.reference_entity_type === EReferenceEntityType.CONTRACT_TEMPLATE,
  )?.field_data?.[0];

  const [openContractModal, setOpenContractModal] = useState(false);

  return (
    <>
      {fileFields?.filter((item) => item.field_data?.[0]?.url).length || signature ? (
        <Row>
          {signature ? (
            <Col span={fullscreen ? 6 : 12}>
              <Card
                onClick={() => setOpenContractModal(true)}
                hoverable
                style={{ width: 240 }}
                cover={<img src={`data:image/png;base64,${signature?.field_data?.[0]?.signature}`} alt='User Signature' />}>
                <Card.Meta
                  title='Contract'
                  description={
                    <>
                      <Typography.Paragraph>
                        {translate('settings.contracts.signer')}: {contactInfo}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        {translate('settings.contracts.signerType')}: {signature?.field_data?.[0]?.signer_type}
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        {translate('settings.contracts.ip')}: {signature?.field_data?.[0]?.ip}
                      </Typography.Paragraph>
                    </>
                  }
                />
              </Card>
            </Col>
          ) : null}
          {fileFields
            ?.filter((item) => item.field_data?.[0]?.url)
            .map((item) => (
              <Col span={fullscreen ? 6 : 12} key={item.id}>
                <FileCard title={extractFileNameFromUrl(item.field_data?.[0]?.url)} tag={item.weight.toString()} color='#FFF1CF' />
              </Col>
            ))}
        </Row>
      ) : (
        <Col>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      )}
      <ContractModal
        open={openContractModal}
        signature={signature?.field_data?.[0]?.signature}
        title={contractTemplateData?.title}
        content={contractTemplateData?.content}
        backgroundColor={contractTemplateData?.contract_style?.[0]?.background_color}
        fontFamily={contractTemplateData?.contract_style?.[0]?.font_family}
        formBackground={contractTemplateData?.contract_style?.[0]?.form_background}
        primaryFontColor={contractTemplateData?.contract_style?.[0]?.primary_font_color}
        summary={contractTemplateData?.summary}
        onClose={() => setOpenContractModal(false)}
      />
    </>
  );
};
