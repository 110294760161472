import { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useDebounce, useUserPermissionCheck } from '@aduvi/hooks';

import { useGetServicesQuery } from 'store/rtk-slice/service-slice';

export const SelectService = (props: SelectProps & { defaultService?: string }) => {
  const selectedBusiness = useBusiness();
  const { defaultService } = props;

  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const debouncedSearchKey = useDebounce(searchKey) || undefined;

  const hasPermissionToViewServices = useUserPermissionCheck(PERMISSIONS.SERVICES.VIEW);

  const { data: services, isFetching } = useGetServicesQuery(
    { business_id: selectedBusiness?.id as string, params: { page: 1, size: 10, search: debouncedSearchKey } },
    { skip: !selectedBusiness?.id || !hasPermissionToViewServices },
  );

  return (
    <Select
      {...props}
      defaultValue={defaultService}
      showSearch
      filterOption={false}
      loading={isFetching}
      onSearch={(term) => setSearchKey(term)}
      options={services?.data?.map((item) => ({ label: item.name, value: item.id }))}
    />
  );
};
