import React, { useState } from 'react';

import { Button, Card, Col, Row, Select, Spin } from 'antd';

import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { generateQuantityOptions } from '@aduvi/utils/helper';

interface IProps {
  products?: IPaginatedResponse<IProduct>;
  selectedProducts: string[];
  onProductSelect: (id: string) => void;
}

export const GridDisplayType = ({ products, onProductSelect, selectedProducts }: IProps) => {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (value: number, productId: string) => {
    if (value === 0) {
      onProductSelect(productId);
      setQuantity(1);
      return;
    }
    setQuantity(value);
  };

  return (
    <Spin spinning={!products}>
      <Row gutter={[18, 18]} className='pr-10 pl-10'>
        {products?.data?.map((item, index) => (
          <Col xs={24} sm={12} xl={8} xxl={6} key={index}>
            <Card
              hoverable
              style={{
                borderRadius: '10px',
                overflow: 'hidden',
                textAlign: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              cover={<img alt='product' height={200} src={item.image} />}>
              <h2>{item.name}</h2>
              <p>
                <div dangerouslySetInnerHTML={{ __html: item.description! }} />
              </p>

              <div className='title price-tag mb-10'>
                ${selectedProducts.includes(item.id) ? Number(item.price || item.upsell_price) * quantity : item.price}
              </div>
              <Row justify={'space-around'}>
                <Button
                  type='primary'
                  shape='round'
                  onClick={() => onProductSelect(item.id)}
                  style={{ backgroundColor: selectedProducts.includes(item.id) ? '#52c41a' : '#000' }}>
                  {selectedProducts.includes(item.id) ? 'Added' : 'Add To Cart'}
                </Button>
                {selectedProducts.includes(item.id) ? (
                  <Select defaultValue={1} onChange={(value) => handleQuantityChange(value, item.id)} style={{ width: 60 }}>
                    {generateQuantityOptions(Number(item.quantity_available), item.min_quantity, item.max_quantity).map((qty) => (
                      <Select.Option key={qty} value={qty}>
                        {qty}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
