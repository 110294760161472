import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import {
  ICreateRoadmapCommentPayload,
  ICreateUpdateRoadmapPayload,
  IDeleteRoadmapCommentPayload,
  IRoadmap,
  IRoadmapCategory,
  IRoadmapComment,
  IRoadmapVote,
  IUpdateRoadmapCommentPayload,
  IUpdateRoadmapVotePayload,
} from '@aduvi/types/roadmap';

export const getRoadmaps = async (): Promise<IResponse<IRoadmap[]>> => {
  return await axios.get(`/roadmaps`);
};

export const createRoadmaps = async (payload: ICreateUpdateRoadmapPayload): Promise<IResponse<IRoadmap>> => {
  return await axios.post(`/roadmaps`, payload.body);
};

export const updateRoadmaps = async (payload: ICreateUpdateRoadmapPayload & { id: string }): Promise<IResponse<IRoadmap>> => {
  return await axios.put(`/roadmaps/${payload.id}`, payload.body);
};

export const deleteRoadmap = async (id: string) => {
  return await axios.delete(`/roadmaps/${id}`);
};

export const getRoadmapCategories = async (): Promise<IResponse<IRoadmapCategory[]>> => {
  return await axios.get(`/roadmap/categories`);
};

export const createRoadmapComment = async (payload: ICreateRoadmapCommentPayload): Promise<IResponse<IRoadmapComment>> => {
  return await axios.post(`/roadmaps/${payload.roadmap_id}/comments`, payload.body);
};

export const updateRoadmapComment = async (payload: IUpdateRoadmapCommentPayload): Promise<IResponse<IRoadmapComment>> => {
  return await axios.put(`/roadmaps/${payload.roadmap_id}/comments/${payload.comment_id}`, payload.body);
};

export const deleteRoadmapComment = async (payload: IDeleteRoadmapCommentPayload) => {
  return await axios.delete(`/roadmaps/${payload.roadmap_id}/comments/${payload.comment_id}`);
};

export const updateRoadmapVote = async (payload: IUpdateRoadmapVotePayload): Promise<IResponse<IRoadmapVote>> => {
  return await axios.put(`/roadmaps/${payload.roadmap_id}/vote`, payload.body);
};
