import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { ICreateTicketPayload, IDeleteTicketPayload, IGetTicketByIdPayload, ITicket, IUpdateTicketPayload } from '@aduvi/types';
import { EEntityType, IBulkSendEmail, IEntityTypeInitialState, IEntityWithFields, IUpsertEntity } from '@aduvi/types/entity';
import { mapEntityTypesToHash } from '@aduvi/utils/helper';

import { apiSlice } from 'store/api/apiSlice';
import * as EntityService from 'store/services/entity.service';

const initialState: IEntityTypeInitialState = {
  entityTypes: {
    data: {
      [EEntityType.JOB]: undefined,
      [EEntityType.EVENT]: undefined,
      [EEntityType.VENUE]: undefined,
      [EEntityType.EXHIBITOR]: undefined,
      [EEntityType.CONTACT]: undefined,
      [EEntityType.ORDER]: undefined,
      [EEntityType.PRODUCT]: undefined,
      [EEntityType.SERVICE]: undefined,
      [EEntityType.TEAM]: undefined,
      [EEntityType.TEAM_USER]: undefined,
      [EEntityType.TASK]: undefined,
      [EEntityType.PROJECT]: undefined,
    },
    entityTypes: [],
    selectedEntityType: undefined,
    loadingEntityType: false,
    loading: false,
    creating: false,
    updating: false,
  },
  entities: {
    data: [],
    selectedEntity: undefined,
    loadingEntity: false,
    creating: false,
    loading: false,
    loadingTicket: false,
    selectedTicket: undefined,
    updating: false,
    currentPage: 1,
    pageSize: 10,
    total: 0,
    to: 0,
    from: 0,
    last_page: 0,
    total_list_values_count: {},
  },
  sendingEmail: false,
};

export const getEntityTypes = createAsyncThunk('entity/get-entity-types', async (businessId: string, { rejectWithValue }) => {
  try {
    return await EntityService.getEntityTypes(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCoreEntityTypes = createAsyncThunk('entity/get-core-entities', async (_, { rejectWithValue }) => {
  try {
    return await EntityService.getCoreEntityTypes();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEntities = createAsyncThunk(
  'entity/get-entities',
  async (
    payload: {
      businessId: string;
      entityTypeId: string;
      params?: {
        status?: string;
        page?: number;
        size?: number;
        view_display?: string;
        field_id?: string;
        display_label_field_id?: string;
        start_date?: string;
        end_date?: string;
        list_value_id?: string;
        paginate?: boolean;
      };
    },
    { rejectWithValue },
  ) => {
    try {
      return await EntityService.getEntities(payload.businessId, payload.entityTypeId, payload?.params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createEntity = createAsyncThunk('entity/create-entity', async (payload: IUpsertEntity, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.ENTITY }, { type: SLICE_TAG_TYPES.PUBLIC_ENTITY }]));
    return await EntityService.createEntity(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editEntity = createAsyncThunk('entity/edit-entity', async (payload: IUpsertEntity, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.ENTITY }, { type: SLICE_TAG_TYPES.PUBLIC_ENTITY }]));
    return await EntityService.editEntity(payload);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEntity = createAsyncThunk(
  'entity/get-entity',
  async (payload: { businessId: string; entityTypeId: string; entityId: string }, { rejectWithValue }) => {
    try {
      return await EntityService.getEntity(payload.businessId, payload.entityTypeId, payload.entityId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteEntity = createAsyncThunk(
  'entity/delete-entity',
  async (payload: { businessId: string; entityTypeId: string; entityId: string; entityTypeName: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.ENTITY }, { type: SLICE_TAG_TYPES.PUBLIC_ENTITY }]));
      return await EntityService.deleteEntity(payload.businessId, payload.entityTypeId, payload.entityId, payload.entityTypeName);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createTicket = createAsyncThunk('tickets/create', async (params: ICreateTicketPayload, { rejectWithValue }) => {
  try {
    return await EntityService.createTicket(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTicket = createAsyncThunk('tickets/update', async (params: IUpdateTicketPayload, { rejectWithValue }) => {
  try {
    return await EntityService.updateTicket(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getTicketById = createAsyncThunk('tickets/get-by-id', async (params: IGetTicketByIdPayload, { rejectWithValue }) => {
  try {
    return await EntityService.getTicketById(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteTicket = createAsyncThunk('tickets/delete', async (params: IDeleteTicketPayload, { rejectWithValue }) => {
  try {
    return await EntityService.deleteTicket(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const cloneTicket = createAsyncThunk(
  'tickets/clone-ticket',
  async (params: { businessId: string; eventId: string; ticketId: string }, { rejectWithValue }) => {
    try {
      return await EntityService.cloneTicket(params.businessId, params.eventId, params.ticketId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const bulkDelete = createAsyncThunk(
  'entity/bulk-delete',
  async (params: { businessId: string; entityTypeId: string; entity_ids: string[]; entityTypeName: string }, { rejectWithValue }) => {
    try {
      return await EntityService.bulkDelete(params.businessId, params.entityTypeId, params.entity_ids, params.entityTypeName);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const bulkDuplicate = createAsyncThunk(
  'entity/bulk-duplicate',
  async (params: { businessId: string; entityTypeId: string; entity_ids: string[] }, { rejectWithValue }) => {
    try {
      return await EntityService.bulkDuplicate(params.businessId, params.entityTypeId, params.entity_ids);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const bulkSendEmail = createAsyncThunk(
  'entity/bulk-send-email',
  async (params: { businessId: string; entityTypeId: string; body: IBulkSendEmail }, { rejectWithValue }) => {
    try {
      return await EntityService.bulkSendEmail(params.businessId, params.entityTypeId, params.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const exportEntities = createAsyncThunk(
  'entity/export-entities',
  async (params: { businessId: string; entityTypeId: string }, { rejectWithValue }) => {
    try {
      return await EntityService.exportEntities(params.businessId, params.entityTypeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.entities.currentPage = action.payload;
    },
    setCurrentPageSize: (state, action: PayloadAction<number>) => {
      state.entities.pageSize = action.payload;
    },
    setSelectedEntity: (state, { payload }: PayloadAction<IEntityWithFields | undefined>) => {
      state.entities.selectedEntity = payload;
    },
    setSelectedTicket: (state, action: PayloadAction<Partial<ITicket> | undefined>) => {
      state.entities.selectedTicket = action.payload as ITicket;
    },
    updateEntityOfSelectedEntity: (state, { payload }) => {
      const updatedCustomFields = state.entities?.selectedEntity?.custom_fields?.map((customField) => {
        if (customField.reference_entity_type_id === payload?.entity_type_id) {
          return {
            ...customField,
            field_data: customField?.field_data?.map((item) => (item?.id === payload?.id ? payload : item)),
          };
        }
        return customField;
      });

      if (updatedCustomFields?.length) {
        state.entities.selectedEntity = { ...state.entities.selectedEntity, custom_fields: updatedCustomFields } as IEntityWithFields;
      }
    },
    resetEntities: (state) => {
      state.entities.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityTypes.pending, (state) => {
        state.entityTypes.loading = true;
      })
      .addCase(getEntityTypes.fulfilled, (state, { payload }) => {
        state.entityTypes.loading = false;
        state.entityTypes.data = mapEntityTypesToHash(payload.data);
        state.entityTypes.entityTypes = payload.data;
      })
      .addCase(getEntityTypes.rejected, (state) => {
        state.entityTypes.loading = false;
      })
      .addCase(getEntities.pending, (state) => {
        state.entities.loading = true;
      })

      .addCase(getEntities.fulfilled, (state, { payload }) => {
        state.entities.loading = false;
        state.entities.data = payload.data.entities;
        state.entities.total = payload.data.total;
        state.entities.total_list_values_count = payload.data.total_list_values_count;
      })
      .addCase(getEntities.rejected, (state) => {
        state.entities.loading = false;
      })
      .addCase(createEntity.pending, (state) => {
        state.entities.creating = true;
      })
      .addCase(createEntity.fulfilled, (state, { payload, meta }) => {
        state.entities.creating = false;
        if (!meta?.arg?.skip_inserting_on_state) {
          state.entities.data.push(payload.data);
        }
      })
      .addCase(createEntity.rejected, (state) => {
        state.entities.creating = false;
      })
      .addCase(getEntity.pending, (state) => {
        state.entities.loadingEntity = true;
      })
      .addCase(getEntity.fulfilled, (state, { payload }) => {
        state.entities.loadingEntity = false;
        state.entities.selectedEntity = payload.data;
      })
      .addCase(getEntity.rejected, (state) => {
        state.entities.loadingEntity = false;
      })
      .addCase(editEntity.pending, (state) => {
        state.entities.updating = true;
      })
      .addCase(editEntity.fulfilled, (state, { payload }) => {
        state.entities.updating = false;
        if (Array.isArray(state.entities.data)) {
          state.entities.data = state.entities.data.map((item) => (item.id === payload.data.id ? payload.data : item));
        }
      })
      .addCase(editEntity.rejected, (state) => {
        state.entities.updating = false;
      })
      .addCase(deleteEntity.pending, (state) => {
        state.entities.loading = true;
      })
      .addCase(deleteEntity.fulfilled, (state, { meta }) => {
        state.entities.loading = false;
        state.entities.data = state.entities.data.filter((item) => item.id !== meta.arg.entityId);
      })
      .addCase(deleteEntity.rejected, (state) => {
        state.entities.loading = false;
      })
      .addCase(deleteTicket.pending, (state) => {
        state.entities.loading = true;
      })
      .addCase(deleteTicket.rejected, (state) => {
        state.entities.loading = false;
      })
      .addCase(deleteTicket.fulfilled, (state, { meta }) => {
        state.entities.loading = false;

        if (state.entities)
          state.entities.data = state.entities?.data.map((item) => {
            if (item.id === meta.arg.event_id) {
              return { ...item, tickets: item.tickets.filter((ticket) => ticket.id !== meta.arg.ticket_id) };
            }
            return item;
          });
      })
      .addCase(createTicket.pending, (state) => {
        state.entities.creating = true;
      })
      .addCase(createTicket.rejected, (state) => {
        state.entities.creating = false;
      })
      .addCase(createTicket.fulfilled, (state, { payload }) => {
        state.entities.creating = false;
        if (state.entities)
          state.entities.data = state.entities.data.map((item) => {
            return item.id === payload.data.business_event_id ? { ...item, tickets: [...item.tickets, payload.data] } : item;
          });
      })
      .addCase(updateTicket.pending, (state) => {
        state.entities.updating = true;
      })
      .addCase(updateTicket.rejected, (state) => {
        state.entities.updating = false;
      })
      .addCase(updateTicket.fulfilled, (state, { payload }) => {
        state.entities.updating = false;
        if (state.entities)
          state.entities.data = state.entities.data.map((item) => {
            if (item.id === payload.data.business_event_id)
              return {
                ...item,
                tickets: item.tickets.map((ticket) => (ticket.id === payload.data.id ? payload.data : ticket)),
              };
            return item;
          });
      })
      .addCase(getTicketById.pending, (state) => {
        state.entities.loadingTicket = true;
      })
      .addCase(getTicketById.rejected, (state) => {
        state.entities.loadingTicket = false;
      })
      .addCase(getTicketById.fulfilled, (state, { payload }) => {
        state.entities.loadingTicket = false;
        state.entities.selectedTicket = payload.data;
      })
      .addCase(cloneTicket.pending, (state) => {
        state.entities.creating = true;
      })
      .addCase(cloneTicket.rejected, (state) => {
        state.entities.creating = false;
      })
      .addCase(cloneTicket.fulfilled, (state, { payload }) => {
        state.entities.creating = false;
        if (state.entities)
          state.entities.data = state.entities.data.map((item) => {
            return item.id === payload.data.business_event_id ? { ...item, tickets: [...item.tickets, payload.data] } : item;
          });
      })
      .addCase(bulkDelete.pending, (state) => {
        state.entities.loading = true;
      })
      .addCase(bulkDelete.fulfilled, (state, { meta }) => {
        state.entities.loading = false;
        state.entities.data = state.entities.data.filter((item) => !meta.arg.entity_ids.includes(item.id));
      })
      .addCase(bulkDelete.rejected, (state) => {
        state.entities.loading = false;
      })
      .addCase(bulkDuplicate.pending, (state) => {
        state.entities.loading = true;
      })
      .addCase(bulkDuplicate.fulfilled, (state, { payload }) => {
        state.entities.loading = false;
        state.entities.data = [...state.entities.data, payload.data];
      })
      .addCase(bulkDuplicate.rejected, (state) => {
        state.entities.loading = false;
      })
      .addCase(bulkSendEmail.pending, (state) => {
        state.sendingEmail = true;
      })
      .addCase(bulkSendEmail.fulfilled, (state) => {
        state.sendingEmail = false;
      })
      .addCase(bulkSendEmail.rejected, (state) => {
        state.sendingEmail = false;
      });
  },
});

export const entityReducer = entitySlice.reducer;
export const { setSelectedEntity, setSelectedTicket, updateEntityOfSelectedEntity, setCurrentPage, resetEntities, setCurrentPageSize } =
  entitySlice.actions;
