import { useTranslation } from 'react-i18next';

import { Col, Divider, Row, Typography } from 'antd';

import { Title } from '@aduvi/components/Title';
import { IQuote } from '@aduvi/types';

export const QuoteSummary = ({ selectedQuote }: { selectedQuote?: IQuote }) => {
  const { t: translate } = useTranslation();

  return (
    <Row justify='end' className='mt-20'>
      <Col span={8}>
        <Row justify={'space-between'}>
          <Title className='mb-0' level={5}>
            {translate('quotes.subtotal')}
          </Title>
          <Typography.Text>${selectedQuote?.sub_total}</Typography.Text>
        </Row>

        <Divider className='my-5' />

        <Row justify='space-between'>
          <Title className='mb-0' level={5}>
            {translate('quotes.salesTax')}
          </Title>
          <Typography.Text>${(Number(selectedQuote?.total ?? 0) - Number(selectedQuote?.sub_total ?? 0)).toFixed(1)}</Typography.Text>
        </Row>

        <Divider className='my-5' />

        <Row justify='space-between'>
          <Title className='mb-0' level={5}>
            {translate('quotes.total')}
          </Title>
          <Typography.Text>${Number(selectedQuote?.total ?? 0)}</Typography.Text>
        </Row>
      </Col>
    </Row>
  );
};
