import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import Fields from 'views/forms/tabs/build/components/Fields';
import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';
import { SignContractModal } from 'views/settings/contracts/@components/sign-contract-modal/SignContractModal';

import { Button, Card, Col, Form, Image, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { mapEntityFieldsToPayload } from '@aduvi/components/Entity/helper';
import { BACKGROUND_IMAGE, LAYOUT_ALIGNMENT } from '@aduvi/constants';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';
import { EAfterFormSubmitType, EFormFormat, EFormLayout, ERedirectTo } from '@aduvi/types/form';
import { getRepLinkIdFromCookies, isValidUrl } from '@aduvi/utils/helper';

import { setSelectedEntity } from 'store/features/entity-slice';
import { createEntityFromFormUrl, getFormByUrl } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { FormatSkeleton } from './FormatSkeleton';

export const FormFiller = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { selectedEntityForm, loadingEntity, creatingEntity } = useAppSelector((state) => state.forms);

  const [fields, setFields] = useState<IBookingFormFieldProps[]>([]);
  const [entityFields, setEntityFields] = useState<IEntityField[]>([]);
  const [pageCounter, setPageCounter] = useState(0);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [isSignatureMode, setIsSignatureMode] = useState(false);

  const layout: EFormLayout | undefined = useMemo(() => {
    return selectedEntityForm?.style?.form_style?.[0]?.layout;
  }, [selectedEntityForm?.style?.form_style?.[0]?.layout]);

  const format: EFormFormat | undefined = useMemo(() => {
    return selectedEntityForm?.style?.form_style?.[0]?.format;
  }, [selectedEntityForm?.style?.form_style?.[0]?.format]);

  const onNavigationButtonClicked = (value: number) => {
    form
      .validateFields()
      .then(() => setPageCounter((prev) => prev + value))
      .catch(() => {});
  };

  const resetForm = () => {
    setPageCounter(0);
    setShowThankYouMessage(false);
    setShowSubmitButton(true);
  };

  const canShowButton =
    ((pageCounter === fields?.filter((item) => item?.isPageBreak).length && format === EFormFormat.MULTI_PAGE_OPTIONAL) ||
      format === EFormFormat.SINGLE_PAGE ||
      (format === EFormFormat.MULTI_PAGE_INDIVIDUAL && pageCounter === fields?.filter((item) => !item?.isPageBreak).length - 1)) &&
    showSubmitButton;

  const contentStyle = useMemo(() => {
    return {
      background:
        selectedEntityForm?.style?.form_style?.[0]?.background_mode === 'colour'
          ? selectedEntityForm?.style?.form_style?.[0]?.background_color || selectedEntityForm?.style?.form_style?.[0]?.background_color
          : isValidUrl(selectedEntityForm?.style?.form_style?.[0]?.background_image as string)
          ? `url(${selectedEntityForm?.style?.form_style?.[0]?.background_image}) center/cover no-repeat`
          : `url(${BACKGROUND_IMAGE[selectedEntityForm?.style?.form_style?.[0]?.background_image as string]}) center/cover no-repeat`,
      minHeight: '100vh',
    };
  }, [selectedEntityForm]);

  const onAddField = (element: IBookingFormFieldProps, index: number, isUpdate?: boolean) => {
    if (element?.field?.hidden) return;
    if (isUpdate) {
      return setFields((prev) => [...prev, element]);
    }
    const updatedArray = [...fields.slice(0, index), element, ...fields.slice(index)].map((item, index) => ({
      ...item,
      field_instance: {
        ...item?.field_instance,
        weight: index,
      },
    }));
    setFields(updatedArray);
  };

  const onFormSubmit = () => {
    const formUrl = location.pathname.split('/forms/')[1];

    form
      .validateFields()
      .then(() => {
        const packageFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.SERVICE);
        const productFields = entityFields?.filter((field) => field?.reference_entity_type === EReferenceEntityType.PRODUCT);

        const filteredCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return ![...packageFields, ...productFields].map((field) => field?.id).includes(key);
        });

        const contactCustomFields = fields.find((item) => item?.field_instance?.field?.reference_entity_type === EReferenceEntityType.CONTACT)
          ?.field_instance?.display_settings?.custom_fields;

        const productCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...productFields].map((field) => field?.id)?.includes(key);
        });

        const packagesCustomFields = form.getFieldValue('custom_fields')?.filter((customFieldObj: { [key: string]: string }) => {
          if (!customFieldObj) return;

          const key = Object.keys(customFieldObj)[0];
          return [...packageFields].map((field) => field?.id)?.includes(key);
        });

        dispatch(
          createEntityFromFormUrl({
            formUrl,
            body: {
              custom_fields: mapEntityFieldsToPayload(filteredCustomFields, entityFields, contactCustomFields),
              product_ids: productCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              package_ids: packagesCustomFields?.map((customFieldObj: { [key: string]: string[] }) => Object.values(customFieldObj)[0]).flat(),
              rep_link_id: getRepLinkIdFromCookies(),
            },
          }),
        )
          .unwrap()
          .then((response) => {
            form.resetFields();
            setPageCounter(0);

            executeActionsAfterSubmit(response?.data);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const executeActionsAfterSubmit = (entity?: IEntityWithFields, skipContract = false) => {
    if (selectedEntityForm) {
      const redirectTo = selectedEntityForm?.form_submit_automation?.find((item) => item.type === EAfterFormSubmitType.REDIRECT);
      const signContract = selectedEntityForm?.form_submit_automation?.find((item) => item.type === EAfterFormSubmitType.SIGN_CONTRACT);

      if (!skipContract) {
        if (
          signContract?.value &&
          entity?.custom_fields.find((item) => item.reference_entity_type === EReferenceEntityType.CONTACT)?.field_data.length
        ) {
          setIsSignatureMode(true);
          dispatch(setSelectedEntity(entity));
        }
      } else {
        if (redirectTo?.redirect_type == ERedirectTo.THANK_YOU_PAGE) {
          setShowThankYouMessage(true);
          setShowSubmitButton(false);
        } else if (redirectTo?.redirect_type && [ERedirectTo.CLIENT_PORTAL, ERedirectTo.EXTERNAL_URL]?.includes(redirectTo?.redirect_type)) {
          window.open(redirectTo?.url, '_self');
        }
      }
    }
  };

  const applyBrandProperties = () => {
    if (selectedEntityForm?.brand?.name) {
      document.title = selectedEntityForm.brand.name;
    }

    if (selectedEntityForm?.brand?.icon) {
      const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      favicon.href = selectedEntityForm.brand.icon;
    }
  };

  useEffect(() => {
    const formUrl = location.pathname.split('/forms/')[1];

    if (!formUrl) return;

    dispatch(getFormByUrl({ formUrl }))
      .unwrap()
      .then(({ data }) => {
        setEntityFields(data.field_instances.map((item) => item?.field));
        form.setFieldsValue(data);
        form.setFieldValue('form_style', data?.style?.form_style);

        data?.style?.page_breaks?.map(({ field_id, field_instance, field, isPageBreak }, index) =>
          onAddField({ field_id, field_instance, field, isPageBreak }, index, true),
        );

        data?.field_instances?.map((item, index) => {
          return onAddField(
            {
              field_id: item?.field.id,
              field: item?.field,
              field_instance: item,
              isPageBreak: false,
            },
            index,
            true,
          );
        });

        return setFields((prev) => sortBy(prev, (field) => field?.field_instance?.weight));
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    applyBrandProperties();
  }, [selectedEntityForm?.brand]);

  if (loadingEntity) return <FormatSkeleton />;

  if (
    layout &&
    [EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT, EFormLayout.CONTENT_FULL_NO_BACKGROUND, EFormLayout.CONTENT_RIGHT_BACKGROUND_LEFT].includes(layout)
  )
    return (
      <Col className={`build-tab-wrapper w-full ${showThankYouMessage ? 'flex-center' : ''}`}>
        <Row
          className={`form-wrapper ${showThankYouMessage ? 'w-full' : ''}`}
          style={{ minHeight: '100vh', flexDirection: layout === EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT ? 'row-reverse' : 'row' }}>
          {layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND && <Col span={12} className='p-10' style={{ ...contentStyle }}></Col>}

          <Col
            span={layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND ? 12 : 24}
            className={`booking-form`}
            style={{ background: form.getFieldValue(['form_style', 0, 'form_background']), borderRadius: 0 }}>
            {layout === EFormLayout.CONTENT_FULL_NO_BACKGROUND && <Row justify={'center'} className='p-10'></Row>}
            <Row className='w-full mt-20' justify={'center'}>
              <Image preview={false} width={120} src={selectedEntityForm?.style?.form_style?.[0]?.logo} style={{ borderRadius: '50%' }} />
            </Row>
            <Form form={form}>
              {showThankYouMessage && selectedEntityForm ? (
                <Card className='mt-10'>
                  <div dangerouslySetInnerHTML={{ __html: selectedEntityForm.thank_you_page_message }} />
                  <Row justify={'center'}>
                    <Col>
                      <Button onClick={resetForm}>{translate('forms.forms.backToForm')}</Button>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Fields
                  readonly
                  form={form}
                  isDragging={false}
                  fields={fields}
                  setIsDragging={() => void 0}
                  onAddField={() => void 0}
                  onCreateNewFieldDrawer={() => void 0}
                  pageCounter={pageCounter}
                  entityTypeHasQuotes={false}
                  setFields={setFields}
                  setPageCounter={setPageCounter}
                  onCreateAsset={() => void 0}
                />
              )}
            </Form>
            <Row justify={'center'} gutter={12} className='mb-20 mt-10'>
              <Col>
                {format !== EFormFormat.SINGLE_PAGE && pageCounter > 0 && showSubmitButton ? (
                  <Button onClick={() => onNavigationButtonClicked(-1)}>{translate('buttons.previous')}</Button>
                ) : null}
              </Col>
              <Col>
                {canShowButton && (
                  <Button type='primary' style={{ width: '120px' }} loading={creatingEntity} onClick={() => onFormSubmit()}>
                    {translate('buttons.submit')}
                  </Button>
                )}
              </Col>
              <Col>
                {format !== EFormFormat.SINGLE_PAGE && !canShowButton && showSubmitButton ? (
                  <Button type='primary' onClick={() => onNavigationButtonClicked(1)}>
                    {translate('buttons.next')}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
        <SignContractModal
          open={isSignatureMode}
          contract_template={selectedEntityForm?.contract_template}
          onClose={() => {
            setIsSignatureMode(false);
            executeActionsAfterSubmit(undefined, true);
          }}
        />
      </Col>
    );

  return (
    <Col
      className={`build-tab-wrapper w-full ${showThankYouMessage ? 'flex-center' : ''}`}
      style={
        layout &&
        [EFormLayout.BACKGROUND_FULL_CONTENT_LEFT, EFormLayout.BACKGROUND_FULL_CONTENT_CENTER, EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT].includes(
          layout,
        )
          ? contentStyle
          : {}
      }>
      {!loadingEntity ? (
        <Row
          className={`mb-20 ${showThankYouMessage ? 'w-full' : ''}`}
          justify={
            layout &&
            [
              EFormLayout.BACKGROUND_FULL_CONTENT_LEFT,
              EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
              EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT,
            ].includes(layout)
              ? LAYOUT_ALIGNMENT[layout]
              : 'center'
          }>
          <Col className={`booking-form mt-30 mb-30`} style={{ background: selectedEntityForm?.style?.form_style?.[0].form_background }}>
            <Row className='w-full mt-20' justify={'center'}>
              <Image preview={false} width={120} src={selectedEntityForm?.style.form_style?.[0].logo} style={{ borderRadius: '50%' }} />
            </Row>
            <Form form={form}>
              <SignContractModal
                open={isSignatureMode}
                contract_template={selectedEntityForm?.contract_template}
                onClose={() => {
                  setIsSignatureMode(false);
                  executeActionsAfterSubmit(undefined, true);
                }}
              />

              {showThankYouMessage && selectedEntityForm ? (
                <Card className='mt-10'>
                  <div className='flex-center' dangerouslySetInnerHTML={{ __html: selectedEntityForm.thank_you_page_message }} />
                  <Row justify={'center'}>
                    <Col>
                      <Button onClick={resetForm}>{translate('forms.forms.backToForm')}</Button>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Fields
                  readonly
                  form={form}
                  isDragging={false}
                  fields={fields}
                  setIsDragging={() => void 0}
                  onAddField={() => void 0}
                  onCreateNewFieldDrawer={() => void 0}
                  pageCounter={pageCounter}
                  entityTypeHasQuotes={false}
                  setFields={setFields}
                  setPageCounter={setPageCounter}
                  onCreateAsset={() => void 0}
                />
              )}
            </Form>
            <Row justify={'center'} gutter={12} className='mb-20 mt-10'>
              <Col>
                {format !== EFormFormat.SINGLE_PAGE && pageCounter > 0 && showSubmitButton ? (
                  <Button onClick={() => onNavigationButtonClicked(-1)}>{translate('buttons.previous')}</Button>
                ) : null}
              </Col>
              <Col>
                {canShowButton && (
                  <Button type='primary' style={{ width: '120px' }} loading={creatingEntity} onClick={() => onFormSubmit()}>
                    {translate('buttons.submit')}
                  </Button>
                )}
              </Col>
              <Col>
                {format !== EFormFormat.SINGLE_PAGE && !canShowButton && showSubmitButton ? (
                  <Button type='primary' onClick={() => onNavigationButtonClicked(1)}>
                    {translate('buttons.next')}
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Col>
  );
};
