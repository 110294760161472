import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { IChangePasswordPayload } from '@aduvi/types';
import { getFormErrors } from '@aduvi/utils/helper';

import { changePassword } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Password = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const { user, resettingPassword } = useAppSelector((state) => state.auth.user);

  const onSave = (values: IChangePasswordPayload) => {
    if (!user?.id) return;
    dispatch(changePassword({ id: user?.id, body: values }))
      .unwrap()
      .then(() => form.resetFields())
      .catch((error) => form.setFields(getFormErrors(error)));
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, [user?.id]);

  return (
    <Form layout='vertical' requiredMark={false} form={form} onFinish={onSave}>
      <Form.Item
        name='old_password'
        label={translate('profile.password.currentPassword')}
        rules={[{ required: true, message: translate('profile.password.currentPasswordValidation') }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name='password'
        label={translate('profile.password.newPassword')}
        rules={[{ required: true, message: translate('profile.password.newPasswordValidation') }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item
        name='password_confirmation'
        label={translate('profile.password.repeatPassword')}
        rules={[{ required: true, message: translate('profile.password.repeatPasswordValidation') }]}>
        <Input.Password />
      </Form.Item>

      <Row justify='center'>
        <Button loading={resettingPassword} disabled={resettingPassword} htmlType='submit' type='primary' className='text-center'>
          {translate('profile.password.save')}
        </Button>
      </Row>
    </Form>
  );
};
