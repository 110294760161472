import React, { useEffect } from 'react';
import { useState } from 'react';
import { PackagesDrawer } from 'views/assets/services/packages-drawer/PackagesDrawer';
import { ServicesDrawer } from 'views/assets/services/services-drawer/ServicesDrawer';

import { Spin } from 'antd';

import { useBusiness, useDebounce } from '@aduvi/hooks';

import { getServices, resetServicesState, setSelectedPackage, setSelectedService } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AssetsType } from '../Assets.hook';

import { ServicesTable } from './services-table/ServicesTable';

export const Services = ({ searchKey, currentTab }: { searchKey: string; currentTab?: AssetsType }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const debouncedSearchKey = useDebounce(searchKey) || undefined;

  const [showServicesDrawer, setShowServicesDrawer] = useState(false);
  const [showPackagesDrawer, setShowPackagesDrawer] = useState(false);

  const { loading } = useAppSelector((state) => state.services);

  const onDrawerClose = () => {
    dispatch(setSelectedService(undefined));
    dispatch(setSelectedPackage(undefined));
    setShowPackagesDrawer(false);
    setShowServicesDrawer(false);
  };

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(resetServicesState());
    dispatch(
      getServices({
        business_id: selectedBusiness?.id,
        params: {
          page: 1,
          size: 50,
          search: debouncedSearchKey,
        },
      }),
    );
  }, [debouncedSearchKey, selectedBusiness?.id]);

  return (
    <Spin spinning={loading}>
      <ServicesTable
        currentTab={currentTab}
        setShowDrawer={setShowServicesDrawer}
        setShowServicesDrawer={setShowServicesDrawer}
        setShowPackagesDrawer={setShowPackagesDrawer}
      />
      {showServicesDrawer && <ServicesDrawer open={showServicesDrawer} onClose={onDrawerClose} />}
      {showPackagesDrawer && <PackagesDrawer open={showPackagesDrawer} onClose={onDrawerClose} />}
    </Spin>
  );
};
