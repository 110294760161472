import { useTranslation } from 'react-i18next';

import { Col, Row, Space } from 'antd';
import './Troubleshooter.scss';

import { TroubleshooterAlert } from './TroubleshooterAlert';

export const Troubleshooter = () => {
  const { t: translate } = useTranslation();
  return (
    <Row className='troubleshooter-wrapper'>
      <Space size={'middle'}>
        <Col>
          <Row className='mb-10 mt-10'>{translate('support.troubleshooter.suggestion')}</Row>

          <TroubleshooterAlert
            type='info'
            description={translate('support.troubleshooter.suggestionAlert1.description')}
            message={translate('support.troubleshooter.suggestionAlert1.title')}
            linkText={translate('support.troubleshooter.suggestionAlert1.linkText')}
          />

          <TroubleshooterAlert
            type='info'
            description={translate('support.troubleshooter.suggestionAlert2.description')}
            message={translate('support.troubleshooter.suggestionAlert2.title')}
          />

          <TroubleshooterAlert
            type='info'
            description={translate('support.troubleshooter.suggestionAlert3.description')}
            message={translate('support.troubleshooter.suggestionAlert3.title')}
            linkText={translate('support.troubleshooter.suggestionAlert1.linkText')}
          />
        </Col>
        <Col>
          <Row className='mb-10 mt-10'>{translate('support.troubleshooter.systemStatus')}</Row>

          <TroubleshooterAlert
            type='success'
            description={translate('support.troubleshooter.systemStatusAlert1.description')}
            message={translate('support.troubleshooter.systemStatusAlert1.title')}
          />

          <Row className='mb-10 mt-15'>{translate('support.troubleshooter.recentLogMessages')}</Row>

          <TroubleshooterAlert type='warning' description={translate('support.troubleshooter.recentLogMessagesAlert1.description')} />

          <TroubleshooterAlert type='warning' description={translate('support.troubleshooter.recentLogMessagesAlert2.description')} />

          <TroubleshooterAlert type='warning' description={translate('support.troubleshooter.recentLogMessagesAlert3.description')} />
        </Col>
      </Space>
    </Row>
  );
};
