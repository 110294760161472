import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import './ServiceEmbed.scss';

import { useBusiness } from '@aduvi/hooks';
import { IPaginatedResponse, IService } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getPublicServices } from 'store/features/form-slice';
import { getPublicWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWidgetStyles } from '../../hooks/useWidgetStyles';

export const ServiceEmbed = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { widgetId } = useParams();

  const { selectedWidget } = useAppSelector((state) => state.widget);
  const [services, setServices] = useState<IPaginatedResponse<IService> | undefined>(undefined);

  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const memoizedWidgetFields = useMemo(() => {
    return {
      priceDisplay: selectedWidget?.widget_style?.[0]?.price_display,
      formName: selectedWidget?.widget_style?.[0]?.form_name,
      buttonActionForm: selectedWidget?.widget_style?.[0]?.button_action_form,
      buttonText: selectedWidget?.widget_style?.[0]?.button_text,
    };
  }, [selectedWidget]);

  const { priceDisplay, formName, buttonActionForm, buttonText } = memoizedWidgetFields;

  const { visibleFields, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useWidgetStyles(selectedWidget);

  const displayedServices = useMemo(() => {
    if (limitResults === 'show_all') {
      return services?.data;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return services?.data.slice(0, resultsPerPage);
    }
    return services?.data;
  }, [limitResults, resultsPerPage, services?.data]);

  const getPriceDisplay = (service: IService) => {
    const packages = service.packages || [];

    const publicPackages = packages.filter((pkg) => pkg.visibility === '1');

    if (publicPackages.length === 0) return null;

    const prices = publicPackages.map((pkg) => parseFloat(pkg.base_price || pkg.flat_price));

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    if (priceDisplay === 'From') {
      return `From $${minPrice}`;
    } else if (priceDisplay === 'Range') {
      return `$${minPrice} - $${maxPrice}`;
    }
    return null;
  };

  const handleButtonClick = () => {
    if (buttonActionForm && formName) {
      window.open(`/fo/${buttonActionForm}/${formName?.toLowerCase()}`, '_blank');
    }
  };

  useEffect(() => {
    if (!selectedBusiness?.id && !urlBusinessId) return;
    dispatch(getPublicServices(String(selectedBusiness?.id || urlBusinessId)))
      .unwrap()
      .then((data) => {
        setServices(data);
      });
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Row className='services-design-tab-wrapper'>
      <Col span={24}>
        <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {displayedServices?.map((service, index) => (
                <div key={index} className='services-card'>
                  {visibleFields.includes('Picture') && <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />}
                  {visibleFields.includes('Title') && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {service.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {getPriceDisplay(service)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </div>
              ))}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]}>
              {displayedServices?.map((service, index) => (
                <Col key={index} xs={24} sm={12} md={8} lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan} className='services-card'>
                  {visibleFields.includes('Picture') && <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />}
                  {visibleFields.includes('Title') && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {service.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {getPriceDisplay(service)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </Col>
              ))}
            </Row>
          ) : (
            displayedServices?.map((service, index) => (
              <Row key={index} gutter={[20, 20]} align='middle' className='services-card-row'>
                {visibleFields.includes('Picture') && (
                  <Col xs={24} sm={6} className='service-image'>
                    <img alt={service.name} src={service.image} width={100} height={100} className='mb-10' />
                  </Col>
                )}
                <Col xs={24} sm={18} className='service-details'>
                  {visibleFields.includes('Title') && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {service.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {getPriceDisplay(service)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && (
                    <Typography.Paragraph
                      type='secondary'
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: service.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </Col>
              </Row>
            ))
          )}
        </Card>
      </Col>
    </Row>
  );
};
