import { CKEditor as Ckeditor } from '@ckeditor/ckeditor5-react';
import {
  Bold,
  ClassicEditor,
  Editor,
  Essentials,
  EventInfo,
  FontColor,
  FontSize,
  Italic,
  Link,
  List,
  Mention,
  Paragraph,
  Strikethrough,
  Undo,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';

import { IAutomationMention } from '@aduvi/types/automation';

interface CKEditorProps {
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  mentions?: IAutomationMention[];
  onReady?: (editor: Editor) => void;
  onChange?: (content: string) => void;
  handleEditorKeyDown?: (e: KeyboardEvent) => void;
}

export const CKEditor = ({ value, mentions, placeholder, disabled, onReady, onChange, handleEditorKeyDown }: CKEditorProps) => {
  const handleEditorChange = (event: EventInfo, editor: Editor) => {
    if (onChange) {
      onChange(editor.getData());
    }
  };

  const handleReady = (editor: Editor) => {
    if (onReady) {
      onReady(editor);
    }
    if (handleEditorKeyDown) {
      editor.editing.view.document.on('keydown', (event, data) => {
        const domEvent = data.domEvent as KeyboardEvent;
        handleEditorKeyDown(domEvent);
      });
    }
  };

  return (
    <Ckeditor
      editor={ClassicEditor}
      config={{
        toolbar: {
          items: ['fontSize', '|', 'bold', 'italic', 'strikethrough', 'fontColor', '|', 'link', '|', 'bulletedList', 'numberedList'],
        },
        fontSize: {
          options: [8, 10, 11, 12, 14, 15, 17, 19, 21],
        },
        placeholder,
        plugins: [Bold, Essentials, Italic, Strikethrough, Mention, Paragraph, Undo, FontSize, FontColor, Link, List],
        licenseKey: process.env.REACT_APP_CKEDITOR_KEY,
        mention: {
          feeds: [
            {
              marker: '{',
              feed: mentions?.length ? mentions.map((item) => ({ id: `{ ${item.name} }`, text: item.name })) : [],
            },
          ],
        },
      }}
      onChange={handleEditorChange}
      data={value ?? ''}
      disabled={disabled}
      onReady={handleReady}
    />
  );
};
