import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Spin } from 'antd';

import { ELoadingState } from '@aduvi/types';

import { loginWithMagicLink } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const MagicLinkLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { businessId, clientPortalId } = useParams();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { loginState } = useAppSelector((state) => state.clientPortalAuth);
  const token = searchParams.get('token');

  useEffect(() => {
    if (!selectedClientPortal) return;

    if (!token || !businessId || !clientPortalId) return navigate(`portal/auth/login`);
    dispatch(
      loginWithMagicLink({
        businessId,
        clientPortalId,
        body: { token: token.split('+').join(' ') },
      }),
    )
      .unwrap()
      .then(() => {
        navigate('../profile');
      })
      .catch(() => {
        navigate('../login');
      });
  }, []);

  return <Spin spinning={loginState === ELoadingState.LOADING} />;
};
