import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, ForkOutlined, RightOutlined, SettingOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, Col, Divider, Form, Input, Popover, Radio, Row, Select, Switch, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import './EditField.scss';

import { TextStyleSettings } from '@aduvi/components/TextStyleSettings/TextStyleSettings';
import { EReferenceEntityType } from '@aduvi/types';
import { IFieldInstance } from '@aduvi/types/form';

import { IBookingFormFieldProps } from '../helper';

import { ContactManagementFieldsSettings } from './ContactManagementFieldsSettings';
import { ProductManagementFieldsSettings } from './ProductManagementFieldsSettings';

interface IProps {
  formSettings: FormInstance;
  children: ReactNode;
  id: string;
  isDragging: boolean;
  fields: IBookingFormFieldProps[];
  index: number;
  readonly?: boolean;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
}

export const EditField = ({ formSettings, children, id, isDragging, fields, index, readonly, setFields }: IProps) => {
  const { t: translate } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const formField = fields?.find((item) => item.field_id === id);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    marginTop: readonly ? '24px' : '',
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields(
      (prev) =>
        prev?.map((item, idx) => {
          if (idx === index)
            return {
              ...item,
              field_instance: {
                ...item.field_instance,
                title: e.target.value,
              },
            };
          return item;
        }),
    );
  };

  const dateSettings = (
    <Row className='flex-direction-column'>
      <Typography.Text type='secondary'>{translate('forms.forms.dateDisplay')}</Typography.Text>
      <Radio.Group className='mt-5'>
        <Radio.Button value='pop_up'>{translate('forms.forms.popUp')}</Radio.Button>
        <Radio.Button value='inline'>{translate('forms.forms.inline')}</Radio.Button>
      </Radio.Group>
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.timeSlotDisplay')}
      </Typography.Text>
      <Radio.Group className='mt-5'>
        <Radio.Button value='manual'>{translate('forms.forms.manual')}</Radio.Button>
        <Radio.Button value='slots'>{translate('forms.forms.slots')}</Radio.Button>
      </Radio.Group>
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.timeIntervals')}
      </Typography.Text>
      <Select size='small' placeholder='15 minutes' />
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.minDate')}
      </Typography.Text>
      <Select size='small' placeholder='Today' />
      <Typography.Text type='secondary' className='mt-10'>
        {translate('forms.forms.maxDate')}
      </Typography.Text>
      <Select size='small' placeholder='+3 Years' />
    </Row>
  );

  const conditionals = (
    <Row className='flex-direction-column'>
      <Row align={'middle'} className='w-full'>
        <Typography.Text type='secondary'>{translate('forms.forms.if')}</Typography.Text>
        <Select placeholder='Event Type' className='ml-10' />
      </Row>
      <Select placeholder='Is' className='mt-10' />
      <Select placeholder='Wedding' className='mt-10' />
      <Radio.Group className='mt-10' defaultValue={'show'}>
        <Radio.Button value='show'>{translate('forms.forms.show')}</Radio.Button>
        <Radio.Button value='hide'>{translate('forms.forms.hide')}</Radio.Button>
      </Radio.Group>
    </Row>
  );

  const content = (
    <Form form={formSettings} layout='vertical' requiredMark={false}>
      <TextStyleSettings
        values={formField?.field_instance?.display_settings}
        onFontChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        fontSize: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBoldChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        bold: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onItalicChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        italic: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        textColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
        onBackgroundTextColorChange={(value) =>
          setFields(
            (prev) =>
              prev?.map((item, idx) => {
                if (idx === index)
                  return {
                    ...item,
                    field_instance: {
                      ...item.field_instance,
                      display_settings: {
                        ...item.field_instance.display_settings,
                        backgroundTextColor: value,
                      } as IFieldInstance['display_settings'],
                    },
                  };
                return item;
              }),
          )
        }
      />
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance?.half_width}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        half_width: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />{' '}
        <span className='ml-10'>{translate('forms.forms.halfWidth')}</span>
      </Row>
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance.required}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        required: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />
        <span className='ml-10'>{translate('forms.forms.required')}</span>
      </Row>
      <Row align={'middle'} className='mb-5'>
        <Switch
          size='small'
          checked={formField?.field_instance.hidden}
          onChange={(value) =>
            setFields(
              (prev) =>
                prev?.map((item, idx) => {
                  if (idx === index)
                    return {
                      ...item,
                      field_instance: {
                        ...item.field_instance,
                        hidden: value,
                      },
                    };
                  return item;
                }),
            )
          }
        />
        <span className='ml-10'>{translate('forms.forms.hideField')}</span>
      </Row>
      <Divider style={{ margin: '12px 0' }} />
      <Typography.Text type='secondary'>{translate('forms.forms.options')}</Typography.Text>
      <Popover
        placement='right'
        content={
          formField?.field?.reference_entity_type === EReferenceEntityType.CONTACT ? (
            <ContactManagementFieldsSettings selectedField={formField} setFields={setFields} />
          ) : formField?.field?.reference_entity_type === EReferenceEntityType.PRODUCT ? (
            <ProductManagementFieldsSettings selectedField={formField} setFields={setFields} />
          ) : (
            dateSettings
          )
        }>
        <Button className='w-full flex align-center p-0' type='text' icon={<UserOutlined />}>
          {translate('forms.forms.settings')} <RightOutlined style={{ marginLeft: 'auto' }} />
        </Button>
      </Popover>
      <Popover placement='right' content={conditionals}>
        <Button className='w-full flex align-center p-0' type='text' icon={<ForkOutlined />}>
          {translate('forms.forms.conditionals')} <RightOutlined style={{ marginLeft: 'auto' }} />
        </Button>
      </Popover>
      <Button
        className='w-full flex align-center p-0'
        type='text'
        icon={<DeleteOutlined />}
        onClick={() => setFields((prev) => prev.filter((_, idx) => idx !== index))}>
        {translate('buttons.delete')}
      </Button>
    </Form>
  );

  const handleMouseEnter = () => {
    if (!readonly) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Col
      span={24}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ ...style }}
      className={`${isHovered || isDragging || isDropdownVisible ? 'pl-30' : 'pl-10 '} edit-field-form-wrapper pr-10`}
      {...attributes}>
      {isHovered || isDragging || isDropdownVisible ? (
        <Button
          ref={setNodeRef}
          {...listeners}
          type='link'
          className='cursor-grab'
          style={{ color: 'black', position: 'absolute', marginLeft: '-30px', marginTop: '50px' }}
          ghost
          icon={<UnorderedListOutlined />}
        />
      ) : null}
      <Row align={'middle'} style={{ flexFlow: 'nowrap' }}>
        <Col span={24} className={isHovered || isDragging || isDropdownVisible ? 'edit-field-wrapper' : ''}>
          <Row align={'middle'}>
            {formField?.field_instance?.required && (
              <Typography.Text className='pl-5' style={{ color: 'red' }}>
                *
              </Typography.Text>
            )}
            <Input
              bordered={false}
              readOnly={readonly}
              placeholder={translate('forms.forms.addALabel')}
              value={formField?.field_instance.title}
              onChange={handleLabelChange}
              style={{
                flex: 1,
                color: formField?.field_instance?.display_settings?.textColor
                  ? formField?.field_instance?.display_settings?.textColor
                  : formSettings.getFieldValue(['form_style', 0, 'primary_font_color']),
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                background: formField?.field_instance?.display_settings?.backgroundTextColor,
                fontSize: formField?.field_instance?.display_settings?.fontSize + 'px',
                fontStyle: formField?.field_instance?.display_settings?.italic ? 'italic' : 'normal',
                fontWeight: formField?.field_instance?.display_settings?.bold ? 'bold' : 'normal',
              }}
            />
          </Row>
          {!formField?.field_instance?.hidden && children}
          {!(readonly && formField?.field_instance?.description) ? (
            <Input
              className='mb-10'
              style={{
                color: formSettings.getFieldValue(['form_style', 0, 'secondary_font_color']),
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                opacity: 0.65,
              }}
              bordered={false}
              readOnly={readonly}
              disabled={readonly}
              value={formField?.field_instance?.description}
              placeholder={isHovered ? translate('forms.forms.addADescription') : ''}
              onChange={(e) =>
                setFields(
                  (prev) =>
                    prev?.map((item) => {
                      if (item.field_id === id) {
                        return {
                          ...item,
                          field_instance: {
                            ...item.field_instance,
                            description: e.target.value,
                          },
                        };
                      }
                      return item;
                    }),
                )
              }
            />
          ) : null}

          {isHovered || isDragging || isDropdownVisible ? (
            <>
              <Divider />
              <Row justify={'end'}>
                <Popover arrow={false} content={content} trigger='click' placement='bottomLeft' onOpenChange={(value) => setIsDropdownVisible(value)}>
                  <Button type='text' ghost icon={<SettingOutlined />} onClick={(e) => e.preventDefault()} />
                </Popover>
              </Row>
            </>
          ) : readonly && formField?.field_instance?.description ? (
            <span
              className='ml-10'
              style={{
                fontFamily: formSettings.getFieldValue(['form_style', 0, 'font_family']),
                color: formSettings.getFieldValue(['form_style', 0, 'secondary_font_color']),
                opacity: 0.65,
              }}>
              {formField?.field_instance?.description}
            </span>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Col>
  );
};
