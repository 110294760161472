import React, { useMemo } from 'react';

import { Form, RowProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FormInstance } from 'antd/lib';

import { EShoppingBasketPosition } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { EEntityFieldListType, EFieldDataValueType, EFieldsVisibility, EReferenceEntityType } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';
import { EFormLayout, IEntityForm } from '@aduvi/types/form';
import { getFormErrors, isValidUrl } from '@aduvi/utils/helper';

import { uploadImage } from 'store/features/common-slice';
import { createEntityField } from 'store/features/fields-slice';
import { useAppDispatch } from 'store/hooks';

import { IBookingFormFieldProps } from './helper';

interface IProps {
  form: FormInstance;
  selectedEntityForm: IEntityForm | undefined;
  entityTypeId: string | undefined;
  entityFieldDrawerStats: {
    open: boolean;
    index: number;
  };
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
  setEntityFieldDrawerStats: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      index: number;
    }>
  >;
}

const backgroundImage: Record<string, string> = {
  'assets/images/theme-1.png': require('assets/images/theme-1.png'),
  'assets/images/theme-2.png': require('assets/images/theme-2.png'),
  'assets/images/theme-3.png': require('assets/images/theme-3.png'),
  'assets/images/theme-4.png': require('assets/images/theme-4.png'),
};

const layoutAlignment: { [key: string]: RowProps['justify'] } = {
  [EFormLayout.BACKGROUND_FULL_CONTENT_LEFT]: 'start',
  [EFormLayout.BACKGROUND_FULL_CONTENT_CENTER]: 'center',
  [EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT]: 'end',
};

const shoppingBasketPosition = {
  [EShoppingBasketPosition.TOP_LEFT]: {
    top: '10px',
    left: '10px',
  },
  [EShoppingBasketPosition.TOP_RIGHT]: {
    top: '10px',
    right: '80px',
  },
  [EShoppingBasketPosition.BOTTOM_LEFT]: {
    bottom: '20px',
    left: '10px',
  },
  [EShoppingBasketPosition.BOTTOM_RIGHT]: {
    bottom: '20px',
    right: '80px',
  },
};

export const useBuild = ({ form, selectedEntityForm, entityTypeId, entityFieldDrawerStats, onAddField, setEntityFieldDrawerStats }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const formStyle = Form.useWatch('form_style', form);

  const beforeUpload = (file: RcFile) => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadImage(formData))
      .unwrap()
      .then((res) => form.setFieldValue(['form_style', 0, 'logo'], res.data.file_name))
      .catch(() => {});
    return false;
  };

  const contentStyle = useMemo(() => {
    return {
      background:
        form.getFieldsValue()?.form_style?.[0]?.background_mode === 'colour'
          ? form.getFieldsValue()?.form_style?.[0]?.background_color || selectedEntityForm?.style?.form_style?.[0]?.background_color
          : isValidUrl(form.getFieldsValue()?.form_style?.[0]?.background_image)
          ? `url(${form.getFieldsValue()?.form_style?.[0]?.background_image}) center/cover no-repeat`
          : `url(${backgroundImage[form.getFieldsValue()?.form_style?.[0]?.background_image]}) center/cover no-repeat`,
    };
  }, [formStyle, selectedEntityForm]);

  const onCreateNewFieldDrawer = (index: number) =>
    setEntityFieldDrawerStats({
      open: true,
      index,
    });

  const onCreateEntityField = (form: FormInstance) => {
    if (!selectedBusiness?.id || !entityTypeId) return;

    form
      .validateFields()
      .then(() => {
        dispatch(
          createEntityField({
            entityTypeId,
            businessId: selectedBusiness?.id,
            body: {
              ...form.getFieldsValue(),
              business_id: selectedBusiness?.id,
              entity_type_id: entityTypeId,
              form_only: !form.getFieldValue('form_only'),
              list_values: form.getFieldValue(['typed_options', 0, 'options']),
            },
          }),
        )
          .unwrap()
          .then(({ data }) => {
            onAddField(
              {
                field_id: data.id,
                field: data,
                field_instance: {
                  default_value: '',
                  description: '',
                  display_settings: {
                    fontSize: 14,
                    bold: false,
                    italic: false,
                    textColor: '',
                    backgroundTextColor: 'transparent',
                  },
                  half_width: false,
                  hidden: false,
                  multiple: false,
                  place_holder: '',
                  required: false,
                  title: data.title,
                  weight: entityFieldDrawerStats.index,
                },
                isPageBreak: false,
              },
              entityFieldDrawerStats.index,
            );
            setEntityFieldDrawerStats({
              open: false,
              index: 0,
            });
          })
          .catch((error) => form.setFields(getFormErrors(error)));
      })
      .catch(() => {});
  };

  const getReferenceEntityType: Record<string, EReferenceEntityType> = {
    PRODUCT: EReferenceEntityType.PRODUCT,
    SERVICE: EReferenceEntityType.SERVICE,
  };

  const onCreateAsset = (assetType: IEntityType, index: number) => {
    if (!selectedBusiness?.id || !entityTypeId) return;

    dispatch(
      createEntityField({
        businessId: selectedBusiness?.id,
        entityTypeId,
        body: {
          business_id: selectedBusiness?.id,
          entity_type_id: entityTypeId,
          form_only: true,
          title: assetType.name,
          value_type: EFieldDataValueType.FIELD_DATA_REFERENCE,
          reference_entity_type: getReferenceEntityType[assetType.name],
          reference_entity_type_id: assetType.id,
          visibility: EFieldsVisibility.ALL,
          multiple: false,
          list_type: EEntityFieldListType.LIST,
        },
      }),
    )
      .unwrap()
      .then(({ data }) => {
        onAddField(
          {
            field_id: data.id,
            field: data,
            field_instance: {
              default_value: '',
              description: '',
              display_settings: {
                fontSize: 14,
                bold: false,
                italic: false,
                textColor: '',
                backgroundTextColor: 'transparent',
              },
              half_width: false,
              hidden: false,
              multiple: false,
              place_holder: '',
              required: false,
              title: data.title,
              weight: index,
            },
            isPageBreak: false,
          },
          index,
        );
      })
      .catch((error) => form.setFields(getFormErrors(error)));
  };

  return {
    backgroundImage,
    layoutAlignment,
    shoppingBasketPosition,
    contentStyle,
    beforeUpload,
    onCreateNewFieldDrawer,
    onCreateEntityField,
    onCreateAsset,
  };
};
