import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Row } from 'antd';
import '../edges/Edge.scss';

import { EAutomationNodeAction, ENodeType, INode } from '@aduvi/types/automation';

import { setDuplicateNodeId } from 'store/features/automation-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AddNodeDrawer } from './AddNodeDrawer';
export interface CustomNodeData {
  label: string;
  nodeType: ENodeType;
}

const BranchNode = ({ data, id, xPos, yPos }: NodeProps<CustomNodeData>) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const { getEdges, setNodes, setEdges, getNodes } = useReactFlow();

  const { lastNodesId, duplicateNodeId } = useAppSelector((state) => state.automation);

  const [showNodesDrawer, setShowNodesDrawer] = useState(false);

  const onNodeSelect = (data: INode) => {
    setShowNodesDrawer(false);

    const trueBranchID = uuidv4();
    const falseBranchID = uuidv4();

    setNodes((prev) => [
      ...prev,
      {
        id: data.id,
        type: 'custom',
        position: {
          x: xPos - 110,
          y: yPos + 100,
        },
        data: {
          label: data.title,
          nodeType: data.node_type,
          triggerable_type: data.triggerable_type,
          triggerable_id: data.triggerable_id,
          action: data.action,
          payload:
            data.action === EAutomationNodeAction.IF_ELSE
              ? {
                  branches: [
                    {
                      id: trueBranchID,
                      sets: [
                        {
                          conditions: [],
                          condition_set_operator: 'all',
                        },
                      ],
                    },
                  ],
                }
              : data.payload,
        },
      },
      ...(data.action === EAutomationNodeAction.IF_ELSE
        ? [
            {
              id: trueBranchID,
              type: 'branch',
              position: {
                x: xPos - 210,
                y: yPos + 200,
              },
              data: {
                label: 'TRUE',
                nodeType: ENodeType.BRANCH,
                triggerable_type: undefined,
                triggerable_id: undefined,
                action: EAutomationNodeAction.IF_BRANCH,
                payload: undefined,
              },
            },
            {
              id: falseBranchID,
              type: 'branch',
              position: {
                x: xPos + 210,
                y: yPos + 200,
              },
              data: {
                label: 'FALSE',
                nodeType: ENodeType.BRANCH,
                triggerable_type: undefined,
                triggerable_id: undefined,
                action: EAutomationNodeAction.ELSE_BRANCH,
                payload: undefined,
              },
            },
          ]
        : []),
    ]);
    setEdges((prev) => [
      ...prev,
      {
        id: `${id}->${data.id}`,
        source: id,
        target: data.id,
        type: 'custom',
        style: { stroke: '#69C0FF', strokeWidth: 2 },
      },
      ...(data.action === EAutomationNodeAction.IF_ELSE
        ? [
            {
              id: `${data.id}->${trueBranchID}`,
              source: data.id,
              target: trueBranchID,
              type: 'default',
              style: { stroke: '#69C0FF', strokeWidth: 2 },
            },
            {
              id: `${data.id}->${falseBranchID}`,
              source: data.id,
              target: falseBranchID,
              type: 'default',
              style: { stroke: '#69C0FF', strokeWidth: 2 },
            },
          ]
        : []),
    ]);
  };

  const onNodeDuplicateDrop = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const duplicateNode = getNodes()?.find((item) => item?.id === duplicateNodeId);

    const duplicatedNodeData = {
      ...duplicateNode,
      id: uuidv4(),
      position: {
        x: xPos - 100,
        y: yPos + 100,
      },
      data: {
        ...duplicateNode?.data,
      },
    };

    setNodes((prev) => [...prev, duplicatedNodeData]);

    setEdges((prev) => [
      ...prev,
      {
        id: `${id}->${duplicatedNodeData.id}`,
        source: id,
        target: duplicatedNodeData.id,
        type: 'custom',
        style: { stroke: '#69C0FF', strokeWidth: 2 },
      },
    ]);

    dispatch(setDuplicateNodeId(undefined));
    const element = document.querySelector('.react-flow__pane') as HTMLElement;
    if (!element) return;
    element.click();
  };

  return (
    <Col
      style={{
        background: '#69C0FF',
        borderRadius: '10px',
        minWidth: '46px',
      }}>
      <Handle type='target' position={Position.Top} style={{ background: '#555' }} />
      <span className='mt-5 pl-10 pr-10' style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: '#0064C0' }}>
        {data.label}
      </span>
      {lastNodesId.includes(id) ? (
        <div className='nodrag nopan custom-edge'>
          <Col
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 2,
              height: 50,
              background: '#69C0FF',
            }}
          />
          {duplicateNodeId ? (
            <Row
              className='card example-5 cursor-pointer'
              style={{ position: 'absolute', width: '110px', height: '50px', marginLeft: '-27px', marginTop: '20px', zIndex: 9 }}
              justify={'center'}
              align={'middle'}
              onClick={onNodeDuplicateDrop}>
              <svg height='100%' width='100%' xmlns='http://www.w3.org/2000/svg'>
                <rect rx='8' ry='8' className='line' height='100%' width='100%' strokeLinejoin='round' />
              </svg>
              <div className='inner p-15'>{translate('automations.clickToDrop')}</div>
            </Row>
          ) : (
            <Button
              style={{
                position: 'absolute',
                top: 'calc(100% + 5px)',
                left: '50%',
                transform: 'translate(-50%, 100%)',
                zIndex: 1,
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
              type='primary'
              size='small'
              icon={<PlusOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShowNodesDrawer(true);
              }}
            />
          )}
          {!getEdges().find((item) => item.source === id) ? (
            <div
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%) translateY(100%)',
                width: 2,
                height: 50,
                background: '#C9C9C9',
              }}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
                style={{
                  width: '45px',
                  background: '#D9D9D9',
                  height: '17px',
                  borderRadius: '10px',
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%) translateY(-50%)',
                  color: '#858585',
                  textAlign: 'center',
                  fontSize: '10px',
                  alignContent: 'center',
                }}>
                {translate('automations.end')}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
      <Handle type='source' position={Position.Bottom} style={{ background: '#555' }} />
      <AddNodeDrawer
        open={showNodesDrawer}
        canBeLastNode
        nodesLength={getNodes().length}
        onClose={() => setShowNodesDrawer(false)}
        onNodeSelect={onNodeSelect}
      />
    </Col>
  );
};

export default BranchNode;
