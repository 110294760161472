import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';

import { getEntities } from 'store/features/entity-slice';
import { createProposal, getBuilderTemplateById } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CreateProposalModal = ({ open, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const selectedBusiness = useBusiness();

  const {
    proposals: { currentProposalState },
    templates: { selectedTemplate },
  } = useAppSelector((state) => state.proposals);

  const {
    entities: { data, loading, loadingEntity },
  } = useAppSelector((state) => state.entity);

  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        if (!selectedBusiness?.id || !selectedTemplate?.id || !selectedTemplate?.entity_type?.id) return;
        dispatch(
          createProposal({
            accepted: false,
            accepted_date: '',
            builder_template_id: currentProposalState?.templateId,
            business_id: selectedBusiness?.id,
            file_reference: {
              file_name: '',
              file_url: currentProposalState?.html,
            },
            page_title: form.getFieldValue('page_title'),
            entity_type_id: selectedTemplate?.entity_type?.id,
            entity_id: form.getFieldValue('entity_id'),
          }),
        )
          .unwrap()
          .then((res) => navigate(`/proposal-builder?proposalId=${res.data?.id}`))
          .catch(() => {});
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!selectedBusiness?.id || !currentProposalState?.templateId) return;

    dispatch(getBuilderTemplateById({ businessId: selectedBusiness?.id, builderTemplateId: currentProposalState?.templateId }))
      .unwrap()
      .then((response) => {
        dispatch(
          getEntities({
            businessId: selectedBusiness?.id,
            entityTypeId: response?.data.entity_type?.id,
            params: undefined,
          }),
        );
      });
  }, [selectedBusiness?.id, currentProposalState]);

  return (
    <Modal open={open} title='Create proposal' okText='Create' onClose={onClose} onOk={onSubmit} onCancel={onClose}>
      <Form form={form} requiredMark={false} layout='vertical' className='mt-30'>
        <Form.Item name={'page_title'} label='Page title' rules={[{ required: true, message: 'Field is required!' }]}>
          <Input />
        </Form.Item>

        <Form.Item name={'entity_id'} label='Entity' rules={[{ required: true, message: 'Field is required!' }]}>
          <Select
            loading={loading || loadingEntity}
            options={data.map((item) => ({
              label: item?.custom_fields.find(
                (item) =>
                  (item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS && item.core) || item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS,
              )?.field_data[0]?.value,
              value: item?.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
