import { useAppLoad, useResetScroll } from '@aduvi/hooks';
import { useClientPortalLoad } from '@aduvi/hooks/useClientPortalLoad';
import { EAuthorized } from '@aduvi/types';

import { setAuthorization } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AppLayoutLoading } from '../../components/AppLayoutLoading/AppLayoutLoading';

import { AuthorizedClientPortalRoutes } from './authorized-routes/AuthorizedClientPortalRoutes';
import { NonAuthorizedClientPortalRoutes } from './non-authorized-routes/NonAuthorizedClientPortalRoutes';

export const ClientPortalRouter = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized: isAuthorizedClient, user: clientAuth } = useAppSelector((state) => state.clientPortalAuth);
  const { isAuthorized: isAuthorizedUser, user: userAuth } = useAppSelector((state) => state.auth);
  useAppLoad();
  useClientPortalLoad();
  useResetScroll();

  if (isAuthorizedUser && userAuth.user) {
    dispatch(setAuthorization(EAuthorized.AUTHORIZED));
    return <AuthorizedClientPortalRoutes />;
  }

  if (!clientAuth.loading && isAuthorizedClient === EAuthorized.AUTHORIZED && clientAuth.user) {
    return <AuthorizedClientPortalRoutes />;
  } else if (!clientAuth.loading && isAuthorizedClient === EAuthorized.UNAUTHORIZED) {
    return <NonAuthorizedClientPortalRoutes />;
  } else {
    return <AppLayoutLoading />;
  }
};
