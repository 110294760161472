import { CSSProperties } from 'react';

import { Divider, Spin } from 'antd';

import { useAppSelector } from 'store/hooks';

import { ChatCard } from './ChatCard';

export const ChatModal = ({ style }: { style: CSSProperties }) => {
  const { chat, loading, selectedTicket } = useAppSelector((state) => state.ticket);

  return (
    <>
      <Divider className='mt-10' />
      <div style={style} className='p-5'>
        <Spin spinning={loading} style={{ marginTop: '40px' }}>
          <ChatCard selectedTicket={selectedTicket} />
          {chat
            .filter((message) => message)
            .map((message, index) => (
              <ChatCard message={message} key={index} />
            ))}
        </Spin>
      </div>
    </>
  );
};
