import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginOutlined, WhatsAppOutlined } from '@ant-design/icons';

import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { EAutomationNodeAction } from '@aduvi/types/automation';

import { useAppSelector } from 'store/hooks';

export const WhatsApp = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  Form.useWatch('connection_id', form);

  const {
    connections: { loading, data },
  } = useAppSelector((state) => state.automation);

  const options = useMemo(() => data.filter((item) => item.type === EAutomationNodeAction.SEND_WHATSAPP_MESSAGE), [data]);

  return (
    <>
      <Row style={{ boxShadow: '0px 2px 8px 0px #5194D826', borderRadius: 5 }}>
        <Col span={2} style={{ background: '#43CD66', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} className='flex-center'>
          <WhatsAppOutlined style={{ color: 'white', fontSize: '17px' }} />
        </Col>
        <Col span={22} className='p-15 pb-5'>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Typography.Text>{translate('automations.account')}</Typography.Text>
            </Col>
            <Col>
              <Button type='link' block>
                {translate('automations.connectNew')} <LoginOutlined />
              </Button>
            </Col>
          </Row>
          <Form.Item name={'connection_id'} className='mt-5'>
            <Select
              className='w-full'
              loading={loading}
              options={options.map((item) => ({ label: item.provider + ' (' + item?.meta_data?.twilio_whatsapp_number + ')', value: item.id }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'to'} label={translate('automations.to')} className='mt-20'>
        <Input placeholder={translate('automations.typeOrInsert')} />
      </Form.Item>
      <Form.Item name={'message'} label={translate('automations.message')}>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name={'attachments'} label={translate('automations.attachments')}>
        <UploadImage name={'photo'} onUploadChange={() => void 0} text={translate('automations.upload')} />
      </Form.Item>
    </>
  );
};
