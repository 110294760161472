import React from 'react';
import { useLocation } from 'react-router-dom';

import { Layout as ANTD_LAYOUT } from 'antd';

import { useActiveTheme } from '@aduvi/hooks';
import { useIsMobile } from '@aduvi/hooks/useIsMobile';

import { Content, Footer, Header, Sider } from './@components';

export const Layout = ({ children }: { children: React.JSX.Element }) => {
  const isMobile = useIsMobile();
  const activeTheme = useActiveTheme();
  const location = useLocation();

  // Helper function to check the query parameter
  const showVersion = new URLSearchParams(location.search).get('showVersion') === 'true';

  return (
    <ANTD_LAYOUT>
      {showVersion && (
        <div
          style={{
            position: 'fixed',
            bottom: 20, // Distance from the bottom
            right: 20, // Distance from the right
            backgroundColor: '#fff', // Optional: background color
            borderColor: '#ddd', // Optional: border color
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Optional: shadow
          }}>
          {process.env.REACT_APP_BUILD_NUMBER}
        </div>
      )}
      <ANTD_LAYOUT.Content>
        <ANTD_LAYOUT>
          {isMobile ? (
            <>
              <Header />
              <Content
                style={{
                  minHeight: 'calc(100vh - 140px)',
                  maxHeight: 'calc(100vh - 140px)',
                  backgroundColor: activeTheme?.bodyBg,
                }}>
                {children}
              </Content>
              <Footer />
            </>
          ) : (
            <>
              <Sider />
              <Content style={{ backgroundColor: activeTheme?.bodyBg }}>{children}</Content>
            </>
          )}
        </ANTD_LAYOUT>
      </ANTD_LAYOUT.Content>
    </ANTD_LAYOUT>
  );
};
