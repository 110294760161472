import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Empty, Row, Space, Typography } from 'antd';
import './ChatHistory.scss';

import { useBusinessId } from '@aduvi/hooks';
import { ISupportChat } from '@aduvi/types';

import { createSupportChat, getSupportChats, setSelectedSupportChat } from 'store/features/support-chat-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ChatCard } from '../chat-card/ChatCard';

export const ChatHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const businessId = useBusinessId();
  const { supportChats, loading: loadingChats, selectedSupportChat } = useAppSelector((state) => state.supportChat);

  const generateChatName = () => {
    const currentDate = new Date();
    return `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
  };

  const handleChatSelect = (chat: ISupportChat) => {
    dispatch(setSelectedSupportChat(chat));
  };

  const handleCreateNewChat = () => {
    if (businessId) {
      const chatName = generateChatName();

      dispatch(createSupportChat({ payload: { business_id: businessId, name: chatName } })).unwrap();
    }
  };

  const renderChatCards = () => {
    if (loadingChats) {
      return (
        <Typography.Text type='secondary' className='fs-12'>
          {translate('liveChat.loadingChats')}
        </Typography.Text>
      );
    }

    if (!supportChats?.length) {
      return <Empty description={translate('liveChat.noChats')} />;
    }

    return supportChats.map((chat) => (
      <ChatCard key={chat.id} name={chat.name} isActive={selectedSupportChat?.id === chat.id} onPress={() => handleChatSelect(chat)} />
    ));
  };

  useEffect(() => {
    if (businessId) {
      dispatch(getSupportChats({ businessId }));
    }
  }, [businessId, dispatch]);

  return (
    <Row className='pt-20 pr-10 chat-history'>
      <Space direction='vertical' className='w-full'>
        <Button type='primary' className='mb-10' onClick={handleCreateNewChat}>
          {translate('liveChat.newChat')}
        </Button>

        <Typography.Text className='fs-16'>{translate('liveChat.existingChats')}</Typography.Text>
        {renderChatCards()}
      </Space>
    </Row>
  );
};
