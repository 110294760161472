import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Card, Form, Input, Spin, Typography } from 'antd';
import './ResetPassword.scss';

import { Button } from '@aduvi/components/Button/Button';
import { ELoadingState } from '@aduvi/types';

import { resetPassword, validateToken } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t: translate } = useTranslation();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { validateTokenState, resetPasswordState } = useAppSelector((state) => state.clientPortalAuth);
  const [email, token] = [searchParams.get('email'), searchParams.get('token')];

  const onFinishHandler = (value: { password: string; password_confirmation: string }) => {
    if (!value.password || !value.password_confirmation || !email || !token || !selectedClientPortal) return;

    dispatch(
      resetPassword({
        businessId: selectedClientPortal?.business_id,
        clientPortalId: selectedClientPortal?.id,
        body: {
          email,
          token,
          password: value.password,
          password_confirmation: value.password_confirmation,
        },
      }),
    )
      .unwrap()
      .then(() => {
        navigate(`../login`);
      })
      .catch(() => {
        navigate(`../forgot-password`);
      });
  };

  useEffect(() => {
    if (!selectedClientPortal) return;

    if (!email || !token) return navigate(`portal/auth/forgot-password`);

    dispatch(
      validateToken({
        businessId: selectedClientPortal?.business_id,
        clientPortalId: selectedClientPortal.id,
        body: { email, token },
      }),
    )
      .unwrap()
      .then(() => {})
      .catch(() => {
        navigate(`portal/auth/forgot-password`);
      });
  }, [location, selectedClientPortal]);

  return (
    <Spin tip={translate('auth.resetPassword.verifyingRequest')} spinning={validateTokenState === ELoadingState.LOADING}>
      <Card className='password-reset-card '>
        <Typography.Title className='title text-center' level={2}>
          {translate('clientPortal.portal.logo')}
        </Typography.Title>
        <Typography.Title className='title text-center' level={4}>
          {translate('auth.resetPassword.passwordReset')}
        </Typography.Title>
        <Typography.Paragraph type='secondary' className='title text-center'>
          {translate('auth.resetPassword.enterNewPasswordAndRepeatIt')}
        </Typography.Paragraph>

        <Form name='reset' className='mt-20' initialValues={{}} onFinish={onFinishHandler} disabled={validateTokenState === ELoadingState.LOADING}>
          <Form.Item
            name='password'
            rules={[{ required: true, message: translate('auth.resetPassword.confirmPasswordDontMatch') }]}
            className='mb-20'>
            <Input.Password placeholder={translate('auth.resetPassword.passwordValidation')} />
          </Form.Item>
          <Form.Item
            className='mb-30'
            name='password_confirmation'
            rules={[
              {
                required: true,
                message: translate('auth.resetPassword.confirmPasswordValidation'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translate('auth.resetPassword.confirmPasswordDontMatch')));
                },
              }),
            ]}>
            <Input.Password placeholder={translate('auth.resetPassword.confirmPasswordPlaceholder')} />
          </Form.Item>

          <div className='text-center mt-20'>
            <Button
              loading={validateTokenState === ELoadingState.LOADING || resetPasswordState === ELoadingState.LOADING}
              disabled={validateTokenState === ELoadingState.LOADING || resetPasswordState === ELoadingState.LOADING}
              htmlType='submit'
              className='mb-20 w-full'
              type='primary'>
              {translate('auth.resetPassword.changePassword')}
            </Button>
          </div>
        </Form>
      </Card>
    </Spin>
  );
};
