import { CheckCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Col } from 'antd';

export const StatusDone = ({ dueDate }: { dueDate: dayjs.Dayjs }) => {
  return (
    <>
      <CheckCircleFilled style={{ color: 'green' }} />
      <Col style={{ textDecoration: 'line-through' }}>{dueDate.format('DD/MM')}</Col>
    </>
  );
};
