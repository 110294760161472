import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Drawer, Form, Space, Spin, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { ContentBoxEditor } from '@aduvi/components/ContentBoxEditor/ContentBoxEditor';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';

import { editBuilderTemplates, getBuilderBlocks, getBuilderTemplateById, setSelectedTemplate } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Configure } from './tabs/Configure';

export const TemplateEditorModal = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const {
    templates: { selectedTemplate, loading },
    builder_blocks: { loading: loadingBuilderBlocks },
  } = useAppSelector((state) => state.proposals);

  const [showDrawer, setShowDrawer] = useState(false);
  const [settingsTabKey, setSettingsTabKey] = useState('configure');

  const tabItems = [
    {
      key: 'configure',
      label: 'Configure',
      children: <Configure />,
    },
  ];

  const onSave = (html: string, thumbnail: string) => {
    if (!selectedBusiness?.id) return;

    if (selectedTemplate?.id) {
      dispatch(
        editBuilderTemplates({
          templateId: selectedTemplate?.id,
          body: {
            business_id: selectedBusiness?.id,
            body: {
              ...form.getFieldsValue(),
              name: form.getFieldValue('name'),
              description: form.getFieldValue('description'),
              builder_category_ids: form.getFieldValue('builder_category_ids'),
              type: 'PROPOSAL',
              screenshot: {
                image_url: thumbnail,
              },
              page_title: form.getFieldValue('page_title'),
              file_reference: {
                file_name: 'file_reference.pdf',
                file_url: html, // to be changed
              },
            },
          },
        }),
      );
      return;
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const templateId = queryParams.get('templateId');

  useEffect(() => {
    dispatch(setSelectedTemplate(undefined));
    if (!selectedBusiness?.id || !templateId) return;
    dispatch(getBuilderTemplateById({ businessId: selectedBusiness?.id, builderTemplateId: templateId }))
      .unwrap()
      .then((response) => {
        form.setFieldsValue({
          ...response.data,
          brand_id: response.data.brand_id,
          builder_category_ids: response.data?.categories?.map((item) => item.id),
        });
      })
      .catch(() => {
        navigate(-1);
      });
  }, [location, selectedBusiness?.id]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;

    dispatch(getBuilderBlocks({ business_id: selectedBusiness?.id }))
      .unwrap()
      .then((data) => localStorage.setItem('my_builder_blocks', JSON.stringify(data.data)));
  }, [dispatch, selectedBusiness?.id]);

  if (loading || loadingBuilderBlocks || Boolean(templateId && !selectedTemplate?.file_reference?.file_url))
    return <Spin spinning className='w-full mt-30' size='large' />;

  return (
    <>
      <ContentBoxEditor
        onBack={() => {
          dispatch(setSelectedTemplate(undefined));
          navigate(-1);
        }}
        onSave={onSave}
        value={selectedTemplate?.file_reference?.file_url}
        onTabChange={setSettingsTabKey}
        onDrawerOpen={() => setShowDrawer(true)}
        isBuilderBlock={false}
        hideActivity
      />
      <Drawer
        title={'Settings'}
        open={showDrawer}
        width={720}
        styles={{ body: { ...DRAWER_BODY_STYLE, background: '#F9FCFF' } }}
        onClose={() => setShowDrawer(false)}
        extra={
          <Space>
            <Button icon={<CloseOutlined />} type='text' onClick={() => setShowDrawer(false)} />
          </Space>
        }>
        <Spin spinning={false}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <Tabs activeKey={settingsTabKey} items={tabItems} onChange={setSettingsTabKey} />
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};
