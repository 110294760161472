import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined, EyeOutlined, MessageOutlined } from '@ant-design/icons';

import { Button, Card, Col, Form, Image, Input, Modal, Row, Tooltip, Typography } from 'antd';

export interface IArtwork {
  id: number;
  url: string;
  name: string;
}

interface IComment {
  id: number;
  x: number;
  y: number;
  text: string;
}

interface IProps {
  artwork: IArtwork;
  fileOptions: { download: boolean; comments: boolean };
}
export const Artwork = ({ artwork, fileOptions }: IProps) => {
  const { t: translate } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [commentMode, setCommentMode] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const [selectedComment, setSelectedComment] = useState<undefined | IComment>(undefined);

  const [comments, setComments] = useState<{ x: number; y: number; text: string; id: number }[]>([]);

  const handleComment = () => {
    setCommentMode(true);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setCommentMode(false);
    setIsCommenting(false);
    setSelectedComment(undefined);
  };

  const handleAddComment = (x: number, y: number, text: string, id: number) => {
    setComments([...comments, { x, y, text, id }]);
  };
  return (
    <>
      <Row gutter={16} key={artwork.id} className='mb-10'>
        <Col span={5}>
          <Image preview={false} src={artwork.url} />
        </Col>

        <Col span={19}>
          <Typography.Title level={4}>{artwork.name}</Typography.Title>
          <Row gutter={16}>
            <Col span={8}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(true);
                }}
                className='p-0'
                type='link'
                icon={<EyeOutlined />}>
                {translate('clientPortal.artwork.view')}
              </Button>
            </Col>
            {fileOptions.download && (
              <Col span={8}>
                <Button className='p-0' type='link' icon={<DownloadOutlined />} onClick={() => {}}>
                  {translate('clientPortal.artwork.download')}
                </Button>
              </Col>
            )}
            {fileOptions.comments && (
              <Col span={8}>
                <Button className='p-0' type='link' icon={<MessageOutlined />} onClick={handleComment}>
                  {translate('clientPortal.artwork.comment')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Modal
        width={800}
        className='p-0'
        title={
          <>
            <Row justify={'space-between'} className='pr-20'>
              <Col>
                <Typography.Title className='mb-0' level={5}>
                  {artwork.name}
                </Typography.Title>
              </Col>
              <Col className='mr-30'>
                <Row gutter={16}>
                  {fileOptions.download && (
                    <Col>
                      <Button className='p-0' type='link' icon={<DownloadOutlined />} onClick={() => {}}>
                        {translate('clientPortal.artwork.download')}
                      </Button>
                    </Col>
                  )}
                  {fileOptions.comments && (
                    <Col>
                      <Button className='p-0' type='link' icon={<MessageOutlined />} onClick={handleComment}>
                        {translate('clientPortal.artwork.comment')}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        }
        wrapClassName='artwork-modal-wrapper'
        styles={{ header: { padding: 0 }, body: { padding: 0 } }}
        open={isModalOpen}
        footer={null}
        onCancel={onModalClose}>
        <div className='image-wrapper'>
          <Row wrap={false} gutter={16}>
            <Col style={{ flexGrow: 1 }}>
              <Image
                style={{ cursor: isCommenting ? 'crosshair' : 'default' }}
                preview={false}
                src={artwork?.url}
                width={'100%'}
                onClick={(e) => {
                  if (commentMode && isCommenting) {
                    const rect = e.currentTarget.getBoundingClientRect();
                    const offsetX = e.clientX - rect.left;
                    const offsetY = e.clientY - rect.top;
                    const newComment: IComment = {
                      id: new Date().valueOf(),
                      text: 'New Comment',
                      x: offsetX,
                      y: offsetY,
                    };
                    handleAddComment(offsetX, offsetY, newComment.text, newComment.id);
                    setIsCommenting(false);
                  }
                }}
              />

              {comments.map((comment) => (
                <Tooltip
                  placement='right'
                  title={
                    <Typography.Paragraph className='mb-0' style={{ color: 'black' }}>
                      {comment.text}
                    </Typography.Paragraph>
                  }
                  key={comment.id}
                  trigger={['click']}
                  color={'white'}>
                  <span
                    className='comment-dot'
                    style={{
                      top: comment.y,
                      left: comment.x,
                      cursor: 'pointer',
                      border: selectedComment?.id === comment.id ? '6px solid lightblue' : '5px solid white',
                    }}
                    onClick={() => setSelectedComment(comment)}></span>
                </Tooltip>
              ))}
            </Col>
            {commentMode && (
              <Col span={5} className='comment-column'>
                <Row className='comments-wrapper'>
                  <Col className='comments-container'>
                    {comments.map((comment) => (
                      <Card
                        onClick={() => setSelectedComment(comment)}
                        style={{ borderColor: selectedComment?.id === comment.id ? '#1890FF' : '#f0f0f0' }}
                        key={comment.id}
                        className='comment-card'>
                        <Typography.Text type='secondary' className='comment-owner'>
                          {translate('clientPortal.artwork.comment', { user: 'Chris' })}
                        </Typography.Text>
                        <Typography.Paragraph className='mb-0'>{comment.text}</Typography.Paragraph>
                      </Card>
                    ))}
                  </Col>

                  <Col className='comments-footer' style={{ alignSelf: 'end' }}>
                    {selectedComment && (
                      <Row>
                        <Col>
                          <Form.Item label={'Edit Comment'}>
                            <Input
                              value={selectedComment?.text}
                              onChange={(e) => {
                                let updatedComments = [...comments];
                                const selectedCommentIndex = comments.findIndex((comment) => comment.id === selectedComment.id);

                                updatedComments[selectedCommentIndex].text = e.target.value;
                                setComments(updatedComments);
                              }}
                              onBlur={() => {
                                setComments((prev) => prev.filter((comment) => comment.text));
                                setSelectedComment(undefined);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {!isCommenting && (
                      <Button
                        className='add-comment-button'
                        onClick={() => {
                          setSelectedComment(undefined);
                          setIsCommenting(true);
                        }}>
                        + {translate('clientPortal.artwork.addComment')}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </>
  );
};
