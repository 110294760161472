import React from 'react';

import { Card, Row, Typography } from 'antd';

import { useActiveTheme } from '@aduvi/hooks';

interface IProps {
  name: string;
  isActive: boolean;
  onPress: () => void;
}

export const ChatCard: React.FC<IProps> = ({ name, isActive, onPress }) => {
  const activeTheme = useActiveTheme();

  const cardStyle = isActive
    ? {
        width: '100%',
        border: `1px solid ${activeTheme?.buttonBgActive}`,
      }
    : {
        width: '100%',
        border: '1px solid #e8e8e8',
      };

  const textStyle = isActive ? { color: activeTheme?.buttonBgActive } : { color: 'inherit' };

  return (
    <Card size='small' onClick={onPress} style={cardStyle} hoverable>
      <Row style={{ flexDirection: 'column' }}>
        <Typography.Text style={textStyle}>{name}</Typography.Text>
      </Row>
    </Card>
  );
};
