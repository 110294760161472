import { useEffect, useMemo, useState } from 'react';
import { MenuUnfoldOutlined } from '@ant-design/icons';

import { Button, Col, Row } from 'antd';

import { CommonSubMenu } from '@aduvi/components/CommonSubMenu/CommonSubMenu';
import { useBusinessId, useIsMobile } from '@aduvi/hooks';
import { IFile } from '@aduvi/types/file';

import { getAllFiles } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { FileManagement } from './@components/file-management/FileManagement';
import { FileMenu } from './@components/file-menu/FileMenu';
// TODO: Implement filtering in backend, for now its getting all files in order to make search work
const Files = ({ readOnly = false, onSelectionChange }: { readOnly?: boolean; onSelectionChange?: (files: IFile[]) => void }) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const isMobile = useIsMobile();

  const [showFileMenu, setShowFileMenu] = useState(true);

  const [searchTerm, setSearchTerm] = useState('');

  const showFile = isMobile && showFileMenu;

  const { folders, files, allFiles } = useAppSelector((state) => state.file);

  if (showFile) return <FileMenu onClose={() => setShowFileMenu(false)} readOnly={readOnly} onSearch={setSearchTerm} />;

  const filteredFiles = useMemo(() => {
    if (!searchTerm.trim()) return undefined;
    return allFiles?.filter((file) => {
      return (
        file?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        file?.description?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        folders?.data
          ?.find((folder) => folder?.id === file?.folder_id)
          ?.name?.toLowerCase()
          .includes(searchTerm?.toLowerCase())
      );
    });
  }, [allFiles, searchTerm, folders?.data]);

  const filteredFolders = useMemo(() => {
    if (!filteredFiles) return undefined;
    return folders?.data?.filter((folder) => filteredFiles?.some((file) => file?.folder_id === folder?.id));
  }, [filteredFiles, folders?.data]);

  useEffect(() => {
    dispatch(getAllFiles(businessId));
  }, [businessId, files.data]);

  return (
    <Row className={readOnly ? '' : 'h-full'}>
      {!isMobile && (
        <CommonSubMenu style={{ borderRight: readOnly ? '1px solid #e7e9f5' : undefined, boxShadow: readOnly ? 'none' : undefined }}>
          <FileMenu readOnly={readOnly} onSearch={setSearchTerm} filteredFolders={filteredFolders} />
        </CommonSubMenu>
      )}

      {isMobile && <Button className='mb-15' icon={<MenuUnfoldOutlined className='fs-24' />} type='text' onClick={() => setShowFileMenu(true)} />}

      <Col xs={24} md={17} xl={19} xxl={20}>
        <FileManagement filteredFiles={filteredFiles} readOnly={readOnly} onSelectionChange={onSelectionChange} />
      </Col>
    </Row>
  );
};

export default Files;
