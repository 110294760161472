import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToForm } from '@aduvi/components/Entity/helper';
import { useBusiness } from '@aduvi/hooks';
import { IKeyValueEditedEntityCustomFields } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';
import { EFieldDataValueType, IEntityField } from '@aduvi/types/fields';
import { EListValueType } from '@aduvi/types/fields';

import { editEntity, updateEntityOfSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { prorityOptionColors, statusOptionColors } from './@components/helper';

interface IProps {
  entity: IFieldData;
  managementFields: IEntityField[] | undefined;
  entityTypeId?: string;
  loading: boolean;
}

export const useTask = ({ entity, managementFields, entityTypeId, loading }: IProps) => {
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const [updatedFieldIds, setUpdatedFieldIds] = useState<string[]>([]);

  const [customField, setCustomField] = useState(managementFields && mapEntityFieldsToForm(entity, managementFields));

  const { entities } = useAppSelector((state) => state.entity);

  const taskTitle = entity?.custom_fields?.map((field) =>
    field.core && field.value_type === EFieldDataValueType.FIELD_DATA_TEXTS ? field.field_data.map((data) => data.value) : '',
  );

  const taskDeadline = entity?.custom_fields?.flatMap((field) =>
    field.core && field.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES ? field.field_data.flatMap((data) => data.value) : '',
  );

  const userAvatar = entity?.custom_fields
    .filter((field) => field.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE)
    .flatMap((field) => field?.field_data?.flatMap((user) => user?.profile_picture))
    .toString();

  const userName = entity?.custom_fields
    .filter((field) => field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE)
    .flatMap((field) => field?.field_data.flatMap((user) => user?.first_name?.[0]));

  const taskStatus = entity?.custom_fields?.map((field) =>
    field?.core && field?.title === EListValueType.STATUS && field.value_type === EFieldDataValueType.FIELD_DATA_LISTS
      ? field?.field_data?.map((data, index) => (
          <Select
            key={index}
            loading={loading || (entities.updating && updatedFieldIds.includes(field?.id))}
            disabled={entities.updating || loading}
            className={data?.color && statusOptionColors[data?.color?.trim()]}
            style={{ width: 115, height: 26, borderRadius: 5 }}
            defaultValue={data.value}
            optionLabelProp='label'
            options={managementFields
              ?.filter((field) => field.value_type === EFieldDataValueType.FIELD_DATA_LISTS && field.title === EListValueType.STATUS)
              ?.flatMap((field) =>
                field.list_values.map((value) => {
                  return {
                    label: value.value,
                    value: value.id,
                  };
                }),
              )}
            onChange={(value) => {
              const changedCustomFields = customField?.map((fields) => {
                if (fields?.[field.id]) return { [field.id]: [value] };
                return fields;
              });
              setUpdatedFieldIds((prev) => [...prev, field?.id]);

              updateEntity(changedCustomFields as IKeyValueEditedEntityCustomFields[], field?.id);
            }}
          />
        ))
      : '',
  );

  const taskPriority = entity.custom_fields.map((field) =>
    field.core && field.title === EListValueType.PRIORITY && field.value_type === EFieldDataValueType.FIELD_DATA_LISTS ? (
      field.field_data.map((data, index) => (
        <Select
          key={index}
          loading={loading || (entities.updating && updatedFieldIds.includes(field?.id))}
          disabled={entities.updating || loading}
          className={data?.color && prorityOptionColors[data?.color?.trim()]}
          style={{ width: 115, height: 26, borderRadius: 5 }}
          defaultValue={data.value}
          optionLabelProp='label'
          options={managementFields
            ?.filter((field) => field.value_type === EFieldDataValueType.FIELD_DATA_LISTS && field.title === EListValueType.PRIORITY)
            .flatMap((field) =>
              field.list_values.map((value) => {
                return {
                  label: value.value,
                  value: value.id,
                };
              }),
            )}
          onChange={(value) => {
            const changedCustomFields = customField?.map((fields) => {
              if (fields?.[field.id]) return { [field.id]: [value] };
              return fields;
            });

            setUpdatedFieldIds((prev) => [...prev, field?.id]);

            updateEntity(changedCustomFields as IKeyValueEditedEntityCustomFields[], field?.id);
          }}
        />
      ))
    ) : (
      <></>
    ),
  );

  const updateEntity = (field: IKeyValueEditedEntityCustomFields[], fieldId: string) => {
    if (!selectedBusiness?.id || !entityTypeId || !entity?.id || !managementFields) return;

    dispatch(
      editEntity({
        id: entity.id,
        business_id: selectedBusiness.id,
        entity_type_id: entityTypeId,
        custom_fields: mapEditedEntityFieldsToPayload(field, managementFields, entity),
      }),
    )
      .unwrap()
      .then(({ data }) => {
        dispatch(updateEntityOfSelectedEntity(data));
        setUpdatedFieldIds((prev) => prev.filter((id) => id !== fieldId));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!managementFields) return;
    setCustomField(mapEntityFieldsToForm(entity, managementFields));
  }, [entity, managementFields]);

  return {
    taskTitle,
    taskDeadline,
    taskStatus,
    taskPriority,
    userAvatar,
    entity,
    userName,
    updateEntity,
    dispatch,
  };
};
