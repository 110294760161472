import { useMemo } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Drawer, Space, Tabs } from 'antd';
import './AddNodeDrawer.scss';

import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { INode } from '@aduvi/types/automation';

import { useActions, useGetTriggers, useLogics } from '../constants/helper';

import { SelectNode } from './SelectNode';

interface IProps {
  open: boolean;
  canBeLastNode: boolean;
  nodesLength: number;
  onClose: () => void;
  onNodeSelect: (data: INode) => void;
}

export const AddNodeDrawer = ({ open, canBeLastNode, nodesLength, onClose, onNodeSelect }: IProps) => {
  const {
    leadTriggers,
    bookingTriggers,
    formTriggers,
    artworkTriggers,
    templateTriggers,
    backdropTriggers,
    startScreenTriggers,
    orderTriggers,
    conversationTriggers,
    repeatingTriggers,
    venueTriggers,
    eventTriggers,
    userTriggers,
    contactTriggers,
    invoiceTriggers,
    paymentTriggers,
    proposalTriggers,
    taskTriggers,
    repsTriggers,
    contractTriggers,
  } = useGetTriggers();
  const { actions } = useActions();
  const { logics } = useLogics();

  const defaultActiveTabKey = useMemo(() => (canBeLastNode || nodesLength > 0 ? 'logic' : 'triggers'), [open, nodesLength, canBeLastNode]);

  return (
    <Drawer
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      key={nodesLength}
      title={'Select Node'}
      className='add-node-drawer-wrapper'
      open={open}
      width={720}
      styles={{ body: { ...DRAWER_BODY_STYLE, background: '#F9FCFF' } }}
      onClose={onClose}
      extra={
        <Space>
          <Button icon={<CloseOutlined />} type='text' onClick={onClose} />
        </Space>
      }>
      <Tabs
        defaultActiveKey={defaultActiveTabKey}
        items={[
          {
            key: 'triggers',
            label: 'Triggers',
            children: (
              <SelectNode
                key={'triggers'}
                canBeLastNode={canBeLastNode}
                data={[
                  leadTriggers,
                  bookingTriggers,
                  formTriggers,
                  artworkTriggers,
                  templateTriggers,
                  backdropTriggers,
                  startScreenTriggers,
                  orderTriggers,
                  conversationTriggers,
                  repeatingTriggers,
                  venueTriggers,
                  eventTriggers,
                  userTriggers,
                  contactTriggers,
                  invoiceTriggers,
                  paymentTriggers,
                  proposalTriggers,
                  taskTriggers,
                  repsTriggers,
                  contractTriggers,
                ]}
                onNodeSelect={onNodeSelect}
              />
            ),
            disabled: canBeLastNode || nodesLength > 0,
            hide: nodesLength,
          },
          {
            key: 'logic',
            label: 'Logic',
            children: <SelectNode key={'logic'} data={[logics]} canBeLastNode={canBeLastNode} onNodeSelect={onNodeSelect} />,
            disabled: nodesLength === 0,
            hide: !nodesLength,
          },
          {
            key: 'action',
            label: 'Action',
            children: <SelectNode key={'action'} data={[actions]} canBeLastNode={canBeLastNode} onNodeSelect={onNodeSelect} />,
            disabled: !canBeLastNode,
            hide: !nodesLength,
          },
        ].filter((item) => !item.hide)}
      />
    </Drawer>
  );
};
