import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Empty, Row, Spin } from 'antd';

import { Card } from '@aduvi/components/Card/Card';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';
import { EFormType } from '@aduvi/types/form';
import { isValidUrl } from '@aduvi/utils/helper';

import { cloneEntityForm, deleteEntityForm, getFormsByEntity, setEntityForms, setSelectedEntityForm } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AddFormModal } from './modal/AddFormModal';

const backgroundImage: Record<string, string> = {
  'assets/images/theme-1.png': require('assets/images/theme-1.png'),
  'assets/images/theme-2.png': require('assets/images/theme-2.png'),
  'assets/images/theme-3.png': require('assets/images/theme-3.png'),
  'assets/images/theme-4.png': require('assets/images/theme-4.png'),
};

export const Forms = ({ selectedEntity, origin }: { selectedEntity?: IEntityType; origin: EPersonalizedViewOrigin }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedBusiness = useBusiness();

  const { entityForms, loading } = useAppSelector((state) => state.forms);

  const [showFormModal, setShowFormModal] = useState(false);

  const formType: Record<EPersonalizedViewOrigin, EFormType | string> = {
    BOOKINGS: EFormType.BOOKINGS_FORM,
    [EPersonalizedViewOrigin.LEADS]: EFormType.LEADS_FORM,
    [EPersonalizedViewOrigin.FORMS]: EFormType.CONTACTS_FORM,
    [EPersonalizedViewOrigin.EVENTS]: '',
    [EPersonalizedViewOrigin.ORDERS]: '',
    [EPersonalizedViewOrigin.CONTACTS]: '',
    [EPersonalizedViewOrigin.VENUES]: '',
    [EPersonalizedViewOrigin.EXHIBITORS]: '',
    [EPersonalizedViewOrigin.TASKS]: '',
    [EPersonalizedViewOrigin.PROJECTS]: '',
  };

  const handleEditForm = () => {
    setShowFormModal(true);
  };

  useEffect(() => {
    dispatch(setEntityForms(undefined));
    if (!selectedBusiness?.id || !selectedEntity?.id) return;

    dispatch(getFormsByEntity({ business_id: selectedBusiness.id, entity_type_id: selectedEntity?.id, formType: formType[origin] }));
  }, [selectedEntity, selectedBusiness, origin]);

  return (
    <Spin spinning={loading}>
      <Row className='mt-30' gutter={[22, 22]}>
        {entityForms ? (
          entityForms?.map((item, index) => (
            <Col key={index} span={6}>
              <Card
                backgroundColor={item?.style?.form_style?.[0]?.background_color}
                backgroundImage={
                  isValidUrl(item?.style?.form_style?.[0]?.background_image)
                    ? item?.style?.form_style?.[0]?.background_image
                    : backgroundImage[item?.style?.form_style?.[0]?.background_image]
                }
                backgroundMode={item?.style?.form_style?.[0]?.background_mode}
                title={item?.name}
                id={item?.id}
                key={index}
                onDelete={() =>
                  dispatch(
                    deleteEntityForm({
                      business_id: item?.business_id,
                      entity_type_id: item?.entity_type_id,
                      form_id: item?.id,
                      form_name: item?.name,
                    }),
                  )
                }
                onDuplicate={() =>
                  dispatch(cloneEntityForm({ business_id: item?.business_id, entity_type_id: item?.entity_type_id, form_id: item?.id }))
                }
                onEdit={() => {
                  dispatch(setSelectedEntityForm(item));
                  handleEditForm();
                }}
                onClick={() => {
                  if (!item?.entity_type_id) return;
                  dispatch(setSelectedEntityForm(undefined));
                  navigate(`/${item?.entity_type_id}/forms/${item?.id}`);
                }}
              />
            </Col>
          ))
        ) : (
          <></>
        )}
      </Row>
      {!entityForms?.length && !loading ? (
        <Row justify={'center'}>
          <Empty />
        </Row>
      ) : (
        <></>
      )}

      {showFormModal && <AddFormModal entityType={selectedEntity} formType={formType[origin]} onCancel={() => setShowFormModal(false)} />}
    </Spin>
  );
};
