import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IPermissionState } from '@aduvi/types/permissions';

import * as PermissionService from 'store/services/permission.service';

const initialState: IPermissionState = {
  permissions: [],
  loading: false,
};

export const getPermissions = createAsyncThunk('permissions/get-permissions', async (params: { businessId: string }, { rejectWithValue }) => {
  try {
    return await PermissionService.getPermissions(params?.businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPermissions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.permissions = payload.data;
      })
      .addCase(getPermissions.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const permissionReducer = permissionSlice.reducer;
