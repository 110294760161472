import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled } from '@ant-design/icons';

import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import './ActionFormItems.scss';

import { EDistributionType } from '@aduvi/types/automation';

export const Split = () => {
  const { t: translate } = useTranslation();

  return (
    <span className='action-form-items'>
      <Form.Item name={'distribution'} label={translate('automations.distribution')}>
        <Select
          placeholder={translate('automations.pleaseSelect')}
          options={Object.values(EDistributionType)?.map((value) => ({ label: value, value }))}
        />
      </Form.Item>
      <div className='mt-20' />
      <Form.List
        name='paths'
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 2) {
                return Promise.reject(new Error(translate('automations.atLeast2Passengers')));
              }
            },
          },
        ]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? translate('automations.paths') : ''} required={false} key={field.key}>
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{ background: 'white', padding: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
                  <Col span={16}>
                    <Form.Item name={[index, 'title']}>
                      <Input placeholder={translate('automations.path')} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name={[index, 'percentage']}>
                      <InputNumber addonAfter='%' />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <DeleteFilled onClick={() => remove(field.name)} style={{ color: '#595959' }} />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Row justify={'end'}>
              <Button className='mt-20' type='primary' ghost onClick={() => add()}>
                {translate('automations.addPath')}
              </Button>
            </Row>
          </>
        )}
      </Form.List>
    </span>
  );
};
