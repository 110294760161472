import { useTranslation } from 'react-i18next';

import { Col, Form, Input, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { ETimeUnit } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const Details = ({ form }: { form: FormInstance }) => {
  const { selectedService } = useAppSelector((state) => state.services);
  const { t: translate } = useTranslation();

  const timeUnits = [
    { label: translate('assets.serviceDetails.minutes'), value: ETimeUnit.MINUTES },
    { label: translate('assets.serviceDetails.hours'), value: ETimeUnit.HOURS },
  ];

  return (
    <Col span={24} className='mt-15'>
      <Form.Item
        name='name'
        label={translate('assets.serviceDetails.name')}
        rules={[{ required: true, message: translate('assets.serviceDetails.nameValidation') }]}>
        <Input placeholder={translate('assets.serviceDetails.namePlaceholder')} />
      </Form.Item>
      <Form.Item name='image' label={translate('assets.serviceDetails.image')}>
        <UploadImage name='image' onUploadChange={(value) => form.setFieldValue('image', value?.url)} url={selectedService?.image} />
      </Form.Item>
      <Form.Item label={translate('assets.serviceDetails.description')} name='description'>
        <Tiptap
          content={form.getFieldValue('description')}
          placeholder={translate('assets.serviceDetails.descriptionPlaceholder')}
          onUpdate={(content) => form.setFieldValue('description', content.getHTML())}
        />
      </Form.Item>
      <Row gutter={16}>
        <Form.Item label={translate('assets.serviceDetails.setupTime')}>
          <Row gutter={8} className='mt-5'>
            <Col span={12}>
              <Form.Item name='setup_time_value'>
                <Input type='number' placeholder={translate('assets.serviceDetails.timePlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='setup_time_type'>
                <Select options={timeUnits} placeholder={translate('assets.serviceDetails.timeUnitPlaceholder')} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={translate('assets.serviceDetails.packDownTime')}>
          <Row gutter={8} className='mt-5'>
            <Col span={12}>
              <Form.Item name='pack_down_time_value'>
                <Input type='number' placeholder={translate('assets.serviceDetails.timePlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='pack_down_time_type'>
                <Select options={timeUnits} placeholder={translate('assets.serviceDetails.timeUnitPlaceholder')} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Row>
    </Col>
  );
};
