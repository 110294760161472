import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Spin } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { EWidgetApps, ICreateWidget, IWidget } from '@aduvi/types/widget';

import { createWidget, deleteWidget, getWidgets, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { WidgetCard } from '../@components/WidgetCard/WidgetCard';
import { widgetStyle } from '../constants/constants';

const staticTemplates: IWidget[] = [
  {
    id: '1',
    title: 'Products',
    description: 'Display key information about the products you offer',
    apps: EWidgetApps.PRODUCTS,
    is_template: true,
    widget_style: [
      {
        ...widgetStyle,
        visible_fields: ['Picture', 'Title', 'Price', 'Description', 'Button'],
      },
    ],
  },
];

export const Products = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { widgets, loading, updating, creating } = useAppSelector((state) => state.widget);

  const handleDuplicate = (widget: IWidget) => {
    if (!selectedBusiness?.id) return;

    const duplicatedWidget: ICreateWidget = {
      business_id: selectedBusiness?.id,
      title: `${widget?.title} (Copy)`,
      apps: widget.apps,
      sub_app: widget?.sub_app,
      description: widget?.description,
      widget_style: widget?.widget_style?.map((block) => ({ ...block })),
    };

    dispatch(createWidget(duplicatedWidget));
  };

  const allWidgets = [...staticTemplates, ...widgets];

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getWidgets({ businessId: selectedBusiness.id, app: EWidgetApps.PRODUCTS }));
  }, [selectedBusiness?.id, dispatch]);
  return (
    <Spin spinning={loading || creating || updating}>
      <Row gutter={[24, 24]} className='w-full'>
        {allWidgets?.map((widget, index) => (
          <Col className='mb-15' key={index} span={12} xs={24} sm={12} lg={8}>
            <WidgetCard
              id={widget?.id}
              title={widget?.title}
              description={widget?.description}
              action={widget.is_template ? 'Default' : 'Custom'}
              backgroundColor={widget?.widget_style?.[0]?.background_color}
              backgroundImage={widget?.widget_style?.[0]?.background_image}
              onConfigure={() => {
                if (!widget.is_template) {
                  dispatch(setSelectedWidget(widget));
                  navigate(`/widgets/products-configure/${widget.id}`);
                } else {
                  navigate('/widgets/products-configure');
                }
              }}
              onDuplicate={() => handleDuplicate(widget)}
              onDelete={() => {
                if (!selectedBusiness?.id || widget.is_template) return;
                dispatch(deleteWidget({ business_id: selectedBusiness.id, widget_id: widget?.id }));
              }}
              onClick={() => {
                if (!widget.is_template) {
                  dispatch(setSelectedWidget(widget));
                  navigate(`/widgets/products-configure/${widget.id}`);
                } else {
                  navigate('/widgets/products-configure');
                }
              }}
            />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};
