import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useIsBusinessOwner, useUserBusinessPermissions } from '@aduvi/hooks';

export const PrivateRoute = ({ permission }: { permission: string | string[] }) => {
  const location = useLocation();
  const userBusinessPermissions = useUserBusinessPermissions();
  const isBusinessOwner = useIsBusinessOwner();

  const hasPermission =
    typeof permission === 'string'
      ? userBusinessPermissions?.includes(permission)
      : userBusinessPermissions?.some((userPermission: string) => permission.includes(userPermission));

  return hasPermission || isBusinessOwner ? <Outlet /> : <Navigate to='/test' state={{ from: location }} replace />;
};
