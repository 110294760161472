import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';
import { EEntityType } from '@aduvi/types/entity';

import { getEntity, setSelectedEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useProject = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { projectId } = useParams();
  const {
    entities: { selectedEntity },
    entityTypes,
  } = useAppSelector((state) => state.entity);

  const jobFields = useMemo(
    () =>
      selectedEntity?.custom_fields
        ?.filter(
          (item) =>
            item?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
            item?.reference_entity_type_id === entityTypes?.data?.[EEntityType.JOB]?.id,
        )
        .flatMap((item) => item?.field_data) || [],
    [entityTypes, selectedEntity, selectedBusiness],
  );
  const orderFields = useMemo(
    () =>
      selectedEntity?.custom_fields
        ?.filter(
          (item) =>
            item?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
            item?.reference_entity_type_id === entityTypes?.data?.[EEntityType.ORDER]?.id,
        )
        .flatMap((item) => item?.field_data) || [],
    [entityTypes, selectedEntity, selectedBusiness],
  );

  const contactFields = useMemo(() => {
    const addedContacts = new Set();

    const projectContacts =
      selectedEntity?.custom_fields?.filter(
        (item) =>
          item?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
          item?.reference_entity_type_id === entityTypes?.data?.[EEntityType.CONTACT]?.id,
      ) || [];

    const jobContacts =
      jobFields?.flatMap(
        (item) =>
          item?.custom_fields?.filter(
            (field) =>
              field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
              field?.reference_entity_type_id === entityTypes?.data?.[EEntityType.CONTACT]?.id,
          ) || [],
      ) || [];

    const orderContacts =
      orderFields?.flatMap(
        (item) =>
          item?.custom_fields?.filter(
            (field) =>
              field?.value_type === EFieldDataValueType.FIELD_DATA_REFERENCE &&
              field?.reference_entity_type_id === entityTypes?.data?.[EEntityType.CONTACT]?.id,
          ) || [],
      ) || [];

    const filteredData = [...jobContacts, ...projectContacts, ...orderContacts].filter((item) => {
      for (const field of item?.field_data) {
        if (addedContacts.has(field?.id)) {
          return false;
        }
      }
      item?.field_data?.forEach((field) => addedContacts.add(field?.id));
      return true;
    });

    return filteredData;
  }, [entityTypes, selectedEntity, selectedBusiness, jobFields]);

  useEffect(() => {
    if (!selectedBusiness?.id || !projectId || !entityTypes.data.PROJECT) return;
    dispatch(
      getEntity({
        businessId: selectedBusiness?.id,
        entityId: projectId,
        entityTypeId: entityTypes?.data?.PROJECT?.id,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedEntity(data));
      })
      .catch(() => {});
  }, [selectedBusiness?.id, entityTypes]);

  return { contactFields, jobFields, orderFields };
};
