import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

export const Map = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const addressFieldOptions = fields
    .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES)
    .map((item) => ({
      label: item.title,
      value: item.id,
    }));

  useEffect(() => {
    form.setFieldsValue({
      address_field: addressFieldOptions?.[0]?.value,
    });
  }, []);

  return (
    <Col>
      <Form.Item name='address_field' label={translate('leads.map.displayAddress')}>
        <Select options={addressFieldOptions} placeholder={translate('leads.map.selectAddress')} />
      </Form.Item>
    </Col>
  );
};
