import { useTranslation } from 'react-i18next';

import { Row, Spin } from 'antd';
import './Academy.scss';

import { useBusiness } from '@aduvi/hooks';

import { useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

import { AcademyCarousel } from './@components/academy-carousel/AcademyCarousel';

export const Academy = ({ setSupportModalVisible }: { setSupportModalVisible: (value: React.SetStateAction<boolean>) => void }) => {
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { isFetching: loading } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  return (
    <Spin spinning={loading}>
      <Row
        style={{ maxHeight: '450px', minHeight: '450px', overflowX: 'hidden', marginTop: '10px', overflowY: 'auto' }}
        className='academy-tab-wrapper'>
        <div>
          <AcademyCarousel payment={translate('academy.payment')} setSupportModalVisible={setSupportModalVisible} />
        </div>
      </Row>
    </Spin>
  );
};
