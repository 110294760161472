import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

export const Calendar = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const defaultStatusOptions = fields
    .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
    ?.map((field) => ({ label: field?.title, value: field?.id }));

  const allFieldsOptions = useMemo(
    () =>
      fields.map((field) => ({
        label: field?.title,
        value: field?.id,
      })),
    [fields],
  );

  useEffect(() => {
    form.setFieldsValue({ default_date_values: form.getFieldValue('default_date_values') || defaultStatusOptions?.[0]?.value });
    form.setFieldsValue({ display_label: form.getFieldValue('display_label') || allFieldsOptions?.[0]?.value });
  }, []);

  return (
    <Row className='w-full mt-10'>
      <Form.Item className='w-full' name='default_date_values' label={translate('leads.calendar.date')}>
        <Select placeholder={translate('leads.eventDate')} options={defaultStatusOptions} />
      </Form.Item>

      <Form.Item className='w-full mt-4' name='display_label_field_id' label={translate('leads.calendar.label')}>
        <Select placeholder={translate('leads.selectLabel')} options={allFieldsOptions} />
      </Form.Item>
    </Row>
  );
};
