import axios from '@aduvi/config/axios';
import { IResponse } from '@aduvi/types';
import { ICreateTravelFeesPayload, ITravelFee, IUpdateTravelFeesPayload } from '@aduvi/types/travel-fees';

export const getTravelFee = async (business_id: string): Promise<IResponse<ITravelFee[]>> => {
  return await axios.get(`/businesses/${business_id}/travel-fees`);
};

export const createTravelFee = async (payload: ICreateTravelFeesPayload): Promise<IResponse<ITravelFee>> => {
  return await axios.post(`/businesses/${payload.business_id}/travel-fees`, payload);
};

export const updateTravelFee = async (payload: IUpdateTravelFeesPayload): Promise<IResponse<ITravelFee>> => {
  return await axios.put(`/businesses/${payload.business_id}/travel-fees/${payload.travel_fee_id}`, payload);
};
