import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, Row, Typography } from 'antd';
import './Invoices.scss';

import { IQuote } from '@aduvi/types';

import { setSelectedQuote } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Invoices = () => {
  const { user } = useAppSelector((state) => state.clientPortalAuth);
  const { jobId } = useParams();

  return (
    <Row className='invoices-wrapper' justify={'center'} align={'top'}>
      <Col span={12}>
        {jobId ? (
          <InvoiceSection
            title={String(user?.jobs?.selectedJob?.custom_fields.find((field) => field.title === 'Event Name')?.field_data?.[0]?.value) || ''}
            quotes={user?.quotes?.data || []}
          />
        ) : (
          <InvoiceSection
            title={String(user?.orders?.selectedOrder?.custom_fields?.find((field) => field?.title === 'Order Id')?.field_data?.[0]?.value) || ''}
            quotes={user?.quotes?.data || []}
          />
        )}
      </Col>
    </Row>
  );
};

const InvoiceSection = ({ title, quotes }: { title?: string; quotes: IQuote[] }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  return (
    <Row className='invoice mb-10'>
      <Col span={24}>
        <Typography.Title level={title === 'Event Name' ? 4 : 5}>{title}</Typography.Title>
        {quotes?.length > 0 ? (
          quotes?.map((quote) => (
            <Row key={quote?.id} className=' mb-10' justify='space-between' align='middle'>
              <Col>{quote?.name}</Col>
              <Col>
                <Typography.Link
                  onClick={() => {
                    dispatch(setSelectedQuote(quote));
                    navigate(`./${quote?.id}`);
                  }}>
                  {translate('clientPortal.invoice.viewInvoice')}
                </Typography.Link>
              </Col>
            </Row>
          ))
        ) : (
          <Typography.Paragraph className='fs-10' type='secondary'>
            {translate('clientPortal.noFiles')}
          </Typography.Paragraph>
        )}
      </Col>
    </Row>
  );
};
