import { useTranslation } from 'react-i18next';

import { Avatar, Card, List } from 'antd';

import { ACTIVITY_DATA } from '../../data';

export const Activity = () => {
  const { t } = useTranslation();
  return (
    <Card title={t('dashboard.widgets.activity.latestActivity')} style={{ borderRadius: 15 }}>
      <List
        itemLayout='horizontal'
        dataSource={ACTIVITY_DATA}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
              description={`${index + 1} days ago`}
              title={item.title}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
