export const PresetColorPicker = [
  '#037f4c',
  '#00c875',
  '#9cd326',
  '#cab641',
  '#ffcb00',
  '#ff642e',
  '#ffadad',
  '#ff7575',
  '#e2445c',
  '#bb3354',
  '#ff158a',
  '#ff5ac4',
  '#faa1f1',
  '#a25ddc',
  '#784bd1',
  '#7e3b8a',
  '#401694',
  '#5559df',
  '#225091',
  '#579bfc',
  '#0086c0',
  '#4eccc6',
  '#66ccff',
  '#68a1bd',
  '#9aadbd',
  '#808080',
  '#333333',
  '#7f5347',
  '#d974b0',
  '#ad967a',
  '#a1e3f6',
  '#bd816e',
  '#2b76e5',
  '#175a63',
  '#bda8f9',
  '#a9bee8',
  '#9d99b9',
  '#563e3e',
];

export const ADUVI_DEFAULT_ACTIVE_THEME = {
  buttonBg: '#1890ff',
  buttonText: '#ffffff',
  bodyBg: '#f9fcff',
  bodyText: '#000000e0',
  bodyHeadings: '#1890ff',
  bodyRowBg: '#ffffff',
  bodyRowText: '#000000',

  menuBg: '#ffffff',
  menuText: '#595959',
  menuActiveText: '#1890ff',
  menuActiveBg: '#e6f4ff',
  menuShadow: '#1890ff65',

  dashboardMorning: '#60C1F4',
  dashboardAfternoon: '#F0A763',
  dashboardNight: '#11426F',

  siderIcons: '#00000073',

  logo: require('assets/images/sidebar-logo.png'),
  icon: require('assets/images/sidebar-logo.png'),
};

export enum Colors {
  WHITE = '#fff',
  BLACK = '#000',
  GRAY_8 = '#595959',
  BLUE_6 = '#1890ff',
}

export const ADUVI_DEFAULT_PARTNER_LAYOUT = {
  onboardingPageImg: require('assets/images/static-background.jpg'),
  authPageImage: require('assets/images/static-background.jpg'),
  welcomeText: 'Welcome to ADUVI👋',
  legalName: 'Aduvi',
  logo: require('assets/images/sidebar-logo.png'),
  icon: require('assets/icons/favicon.ico'),
};

export const DRAWER_BODY_STYLE = {
  paddingTop: 0,
  paddingBottom: 80,
};

export const AvatarColors = ['#186df5', '#FF0000', '#00FF00', '#FFA500', '#A020F0'];
