import React from 'react';

import { Layout } from 'antd';
import './Content.scss';

export const Content = ({ children, style }: { children: React.JSX.Element; style?: React.CSSProperties }) => {
  return (
    <Layout.Content className='aduvi-content-layout' style={{ ...style }}>
      {children}
    </Layout.Content>
  );
};
