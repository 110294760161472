import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { ICreateLocationPayload, IDeleteLocationPayload, IEditLocationPayload, ILocation } from '@aduvi/types';
import { IGetBusinessActivitiesPayload } from '@aduvi/types/audit';
import {
  IBranding,
  IBusinessState,
  IBusinessTeam,
  ICreateBusinessPayload,
  ICreateBusinessTeamPayload,
  IDeleteBusinessTeamPayload,
  IEditBusinessPayload,
  IMenuSetting,
  IMenuSettingsPayload,
  IUpdateBusinessTeamPayload,
} from '@aduvi/types/business';
import { transformAuditToActivity } from '@aduvi/utils/helper';
import { Notification } from '@aduvi/utils/notification';

import * as BusinessServices from 'store/services/business.service';

import { apiSlice } from '../api/apiSlice';

const initialState: IBusinessState = {
  businesses: [],
  creating: false,
  editing: false,
  selectedBusiness: undefined,
  businessActivities: undefined,
  loading: false,
  businessTeams: {
    loading: false,
    creating: false,
    updating: false,
    removing: false,
    teams: [],
    selectedTeam: undefined,
  },
  locations: {
    selectedLocation: undefined,
    data: [],
    loading: false,
    creating: false,
    updating: false,
  },
  menuSettings: {
    data: [],
    selectedMenuSetting: undefined,
    creating: false,
    updating: false,
  },
  selectedBranding: undefined,
};

export const createBusiness = createAsyncThunk('business/create', async (params: ICreateBusinessPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.createBusiness(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBusinesses = createAsyncThunk('business/get-all', async (_, { rejectWithValue }) => {
  try {
    return await BusinessServices.getBusinesses();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editBusiness = createAsyncThunk('business/edit', async (params: IEditBusinessPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.editBusiness(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createBusinessTeam = createAsyncThunk(
  'business/create-team',
  async (params: ICreateBusinessTeamPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TEAMS }]));
      return await BusinessServices.createBusinessTeam(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessTeams = createAsyncThunk('business/get-teams', async (params: { businessId: string }, { rejectWithValue }) => {
  try {
    return await BusinessServices.getBusinessTeams(params?.businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBusinessTeam = createAsyncThunk('business/get-team', async (params: { businessId: string; teamId: string }, { rejectWithValue }) => {
  try {
    return await BusinessServices.getBusinessTeam(params?.businessId, params?.teamId);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const deleteBusinessTeam = createAsyncThunk(
  'business/delete-team',
  async (params: IDeleteBusinessTeamPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TEAMS }]));
      return await BusinessServices.deleteBusinessTeam(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateBusinessTeam = createAsyncThunk(
  'business/update-team',
  async (params: IUpdateBusinessTeamPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TEAMS }]));
      return await BusinessServices.updateBusinessTeam(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createBranding = createAsyncThunk(
  'business/create-branding',
  async (params: { businessId: string; body: IBranding }, { rejectWithValue }) => {
    try {
      return await BusinessServices.createBranding(params.businessId, params.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editBranding = createAsyncThunk(
  'business/edit-branding',
  async (params: { businessId: string; brandingId: string; body: IBranding }, { rejectWithValue }) => {
    try {
      return await BusinessServices.editBranding(params.businessId, params.brandingId, params.body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteBranding = createAsyncThunk(
  'business/delete-branding',
  async (params: { business_id: string; branding_id: string; branding_name: string }, { rejectWithValue }) => {
    try {
      return await BusinessServices.deleteBranding(params.business_id, params.branding_id, params.branding_name);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessActivities = createAsyncThunk(
  'business/get-activities',
  async (params: IGetBusinessActivitiesPayload, { rejectWithValue }) => {
    try {
      return await BusinessServices.getBusinessActivities(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getLocations = createAsyncThunk('business/get-locations', async (businessId: string, { rejectWithValue }) => {
  try {
    return await BusinessServices.getLocations(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createLocation = createAsyncThunk('business/create-location', async (params: ICreateLocationPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.createLocation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editLocation = createAsyncThunk('business/edit-location', async (params: IEditLocationPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.editLocation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteLocation = createAsyncThunk('business/delete-location', async (params: IDeleteLocationPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.deleteLocation(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const upsertMenuSettings = createAsyncThunk('business/upsert-menuSettings', async (params: IMenuSettingsPayload, { rejectWithValue }) => {
  try {
    return await BusinessServices.upsertMenuSettings(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const editBusinessSurchargeAmount = createAsyncThunk(
  'surcharge-amount/edit',
  async (params: { business_id: string; payment_surcharge_amount: string }, { rejectWithValue }) => {
    try {
      return await BusinessServices.editBusinessSurchargeAmount(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessPublic = createAsyncThunk('business/get-business-public', async (_, { rejectWithValue }) => {
  try {
    return await BusinessServices.getBusinessPublic();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    resetBusinessState: () => initialState,
    setUserBusinesses: (state, { payload }) => {
      state.businesses = payload;
    },
    setSelectedBusiness: (state, { payload }) => {
      state.selectedBusiness = payload;
    },
    setSelectedBusinessTeam: (state, { payload }: PayloadAction<IBusinessTeam | undefined>) => {
      state.businessTeams.selectedTeam = payload;
    },
    setSelectedBranding: (state, { payload }: PayloadAction<IBranding | undefined>) => {
      state.selectedBranding = payload;
    },
    setSelectedLocation: (state, { payload }: PayloadAction<ILocation | undefined>) => {
      state.locations.selectedLocation = payload;
    },
    setSelectedSetting: (state, { payload }: PayloadAction<IMenuSetting | undefined>) => {
      state.menuSettings.selectedMenuSetting = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBusiness.pending, (state) => {
        state.creating = true;
      })
      .addCase(createBusiness.fulfilled, (state, { payload }) => {
        state.businesses = [...state.businesses, payload.data];
        state.selectedBusiness = payload.data;
        state.creating = false;
      })
      .addCase(createBusiness.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getBusinesses.pending, (state) => {
        state.businesses = [];
      })
      .addCase(getBusinesses.fulfilled, (state, { payload }) => {
        state.businesses = payload;
      })
      .addCase(getBusinesses.rejected, (state) => {
        state.businesses = [];
      })
      .addCase(editBusiness.pending, (state) => {
        state.editing = true;
      })
      .addCase(editBusiness.fulfilled, (state, { payload }) => {
        const index = state.businesses.findIndex((business) => business.id === payload.data.id);

        if (index !== -1) {
          state.businesses[index] = payload.data;

          if (state.selectedBusiness && state.selectedBusiness.id === payload.data.id) {
            state.selectedBusiness = payload.data;
          }
        }
        state.editing = false;
        Notification.success({ title: 'Updated successfully', description: 'Business updated successfully' });
      })
      .addCase(editBusiness.rejected, (state) => {
        state.editing = false;
      })
      .addCase(getBusinessTeams.pending, (state) => {
        state.businessTeams.teams = [];
        state.businessTeams.loading = true;
      })
      .addCase(getBusinessTeams.rejected, (state) => {
        state.businessTeams.loading = false;
      })
      .addCase(getBusinessTeams.fulfilled, (state, { payload }) => {
        state.businessTeams.teams = payload.data;
        state.businessTeams.loading = false;
      })
      .addCase(createBusinessTeam.fulfilled, (state, { payload }) => {
        if (state.businessTeams) {
          state.businessTeams.teams = [...state.businessTeams.teams, payload.data];
        }
        state.businessTeams.creating = false;
      })
      .addCase(createBusinessTeam.rejected, (state) => {
        state.businessTeams.creating = false;
      })
      .addCase(createBusinessTeam.pending, (state) => {
        state.businessTeams.creating = true;
      })
      .addCase(deleteBusinessTeam.fulfilled, (state, { meta }) => {
        if (state.businessTeams.teams) {
          state.businessTeams.teams = state.businessTeams.teams.filter((team) => team.id !== meta.arg.team_id);
        }
        state.businessTeams.removing = false;
      })
      .addCase(deleteBusinessTeam.rejected, (state) => {
        state.businessTeams.removing = false;
      })
      .addCase(deleteBusinessTeam.pending, (state) => {
        state.businessTeams.removing = true;
      })
      .addCase(updateBusinessTeam.pending, (state) => {
        state.businessTeams.updating = true;
      })
      .addCase(updateBusinessTeam.fulfilled, (state, { payload }) => {
        state.businessTeams.updating = false;
        state.businessTeams.selectedTeam = payload.data;
        state.businessTeams.teams = state.businessTeams.teams?.map((team) => (team?.id === payload?.data?.id ? payload?.data : team));
      })
      .addCase(updateBusinessTeam.rejected, (state) => {
        state.businessTeams.updating = false;
      })
      .addCase(getBusinessTeam.pending, (state) => {
        state.businessTeams.loading = true;
      })
      .addCase(getBusinessTeam.fulfilled, (state, { payload }) => {
        state.businessTeams.loading = false;
        businessSlice.caseReducers.setSelectedBusinessTeam(state, { type: 'setSelectedBusinessTeam', payload: payload.data });
      })
      .addCase(getBusinessTeam.rejected, (state) => {
        state.businessTeams.loading = false;
      })
      .addCase(createBranding.pending, (state) => {
        state.creating = true;
      })
      .addCase(createBranding.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.selectedBusiness)
          state.selectedBusiness = {
            ...state.selectedBusiness,
            brandings: [...(state.selectedBusiness?.brandings || []), payload.data],
          };
      })
      .addCase(createBranding.rejected, (state) => {
        state.creating = false;
      })
      .addCase(editBranding.pending, (state) => {
        state.editing = true;
      })
      .addCase(editBranding.fulfilled, (state, { payload }) => {
        state.editing = false;
        if (state.selectedBusiness)
          state.selectedBusiness = {
            ...state.selectedBusiness,
            brandings: state.selectedBusiness.brandings?.map((item) => {
              return item.id === payload.data.id ? payload.data : item;
            }),
          };
      })
      .addCase(editBranding.rejected, (state) => {
        state.editing = false;
      })
      .addCase(deleteBranding.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBranding.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.selectedBusiness)
          state.selectedBusiness = {
            ...state.selectedBusiness,
            brandings: state.selectedBusiness.brandings?.filter((item) => item.id !== meta.arg.branding_id),
          };
      })
      .addCase(deleteBranding.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBusinessActivities.pending, (state) => {
        state.businessActivities = undefined;
        state.loading = true;
      })
      .addCase(getBusinessActivities.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBusinessActivities.fulfilled, (state, { payload }) => {
        state.businessActivities = { ...payload, data: payload.data.map(transformAuditToActivity) };
        state.loading = false;
      })
      .addCase(getLocations.pending, (state) => {
        state.locations.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, { payload }) => {
        state.locations.loading = false;
        state.locations.data = payload.data;
      })
      .addCase(getLocations.rejected, (state) => {
        state.locations.loading = false;
      })
      .addCase(createLocation.pending, (state) => {
        state.locations.creating = true;
      })
      .addCase(createLocation.fulfilled, (state, { payload }) => {
        state.locations.creating = false;
        if (state.locations.data) {
          state.locations.data = [...state.locations.data, payload.data];
        }
        state.locations.selectedLocation = payload.data;
      })
      .addCase(createLocation.rejected, (state) => {
        state.locations.creating = false;
      })
      .addCase(editLocation.pending, (state) => {
        state.locations.updating = true;
      })
      .addCase(editLocation.fulfilled, (state, { payload }) => {
        state.locations.updating = false;
        if (state.locations.data) {
          state.locations.data = state.locations.data.map((location) => (location.id === payload.data.id ? payload.data : location));
        }
        if (state.locations.selectedLocation?.id === payload.data.id) {
          state.locations.selectedLocation = payload.data;
        }
      })
      .addCase(editLocation.rejected, (state) => {
        state.locations.updating = false;
      })
      .addCase(deleteLocation.pending, (state) => {
        state.locations.loading = true;
      })
      .addCase(deleteLocation.fulfilled, (state, { meta }) => {
        state.locations.loading = false;
        if (state.locations.data) {
          state.locations.data = state.locations.data.filter((item) => item.id !== meta.arg.locationId);
        }
      })
      .addCase(deleteLocation.rejected, (state) => {
        state.locations.loading = false;
      })
      .addCase(upsertMenuSettings.pending, (state) => {
        state.menuSettings.creating = true;
        state.menuSettings.updating = true;
      })
      .addCase(upsertMenuSettings.fulfilled, (state, { payload }) => {
        state.menuSettings.creating = false;
        state.menuSettings.updating = false;
        if (state.menuSettings.data) {
          state.menuSettings.data = payload.data.menu_settings;
          state.selectedBusiness!.menu_settings = payload.data.menu_settings;
        }
      })
      .addCase(upsertMenuSettings.rejected, (state) => {
        state.menuSettings.creating = false;
        state.menuSettings.updating = false;
      })
      .addCase(editBusinessSurchargeAmount.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBusinessSurchargeAmount.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state.selectedBusiness)
          state.selectedBusiness = {
            ...state.selectedBusiness,
            payment_surcharge_amount: meta.arg.payment_surcharge_amount,
          };
      })
      .addCase(editBusinessSurchargeAmount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBusinessPublic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessPublic.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.selectedBusiness = payload.data;
      })
      .addCase(getBusinessPublic.rejected, (state) => {
        state.loading = false;
        state.selectedBusiness = undefined;
      });
  },
});

export const businessReducer = businessSlice.reducer;
export const { setUserBusinesses, setSelectedBusiness, setSelectedBusinessTeam, setSelectedBranding, setSelectedLocation, setSelectedSetting } =
  businessSlice.actions;
