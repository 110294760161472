import { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';

import { Button, Card, Col, Input, Row, Select, Spin } from 'antd';
import Meta from 'antd/es/card/Meta';

import { Paragraph } from '@aduvi/components/Paragraph';
import { Title } from '@aduvi/components/Title';

import { setCurrentProposalState } from 'store/features/proposals-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CreateProposalModal } from '../CreateProposalModal';

import { dummyData } from './helper';

interface IProps {
  onProposalClick: () => void;
}

export const Design = ({ onProposalClick }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    templates: { templates, templateCategories, loading },
  } = useAppSelector((state) => state.proposals);

  const [search, setSearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [showCreateProposalModal, setShowCreateProposalModal] = useState(false);

  return (
    <div className='w-full mt-20'>
      <Title level={4} className='flex-center'>
        Choose a Template
      </Title>
      <Row justify={'center'} style={{ columnGap: '16px' }}>
        <Col span={4}>
          <Input suffix={<SearchOutlined />} placeholder='Search templates...' onChange={(e) => setSearch(e.target.value)} />
        </Col>
        <Col span={4}>
          <Select
            placeholder='All Categories'
            className='w-full'
            mode='multiple'
            options={templateCategories?.map((item) => ({ label: item.name, value: item.id }))}
            onChange={(value) => setSelectedCategories(value)}
          />
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Row style={{ paddingLeft: '15%', paddingRight: '15%', columnGap: '20px', rowGap: '20px' }} className='mt-20'>
          {templates
            .filter((item) => item.name.includes(search) || item.description.includes(search))
            .filter((item) => {
              if (selectedCategories.length) {
                return item.categories.some((category) => selectedCategories.includes(category.id));
              }
              return true;
            })
            .map((item) => (
              <Col span={7} key={item.id}>
                <Card hoverable cover={<img alt='example' src={item?.screenshot?.image_url ?? dummyData[0].image} />}>
                  <Meta title={item.name} />
                  <Paragraph className='mt-10'>{item.description}</Paragraph>
                  <Row justify={'space-between'}>
                    <Col span={11}>
                      <Button
                        type='primary'
                        className='w-full'
                        onClick={() => {
                          dispatch(
                            setCurrentProposalState({ templateId: item.id, html: item.file_reference?.file_url, mainCss: '', sectionCss: '' }),
                          );
                          setShowCreateProposalModal(true);
                        }}>
                        Select
                      </Button>
                    </Col>
                    <Col span={11}>
                      <Button className='w-full' onClick={onProposalClick}>
                        Preview
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
        </Row>
      </Spin>
      {showCreateProposalModal ? <CreateProposalModal open onClose={() => setShowCreateProposalModal(false)} /> : null}
    </div>
  );
};
