import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, FolderOutlined } from '@ant-design/icons';
import { DataNode } from 'rc-tree/lib/interface';

import type { CollapseProps } from 'antd';
import { Col, Collapse, Row, Space, Tree, Typography } from 'antd';
import './LibraryMenu.scss';

import { Button } from '@aduvi/components/Button/Button';
import { useBusinessId, useIsMobile } from '@aduvi/hooks';
import { EPictureLibrary } from '@aduvi/types';

import { setSelectedLibrary } from 'store/features/file-slice';
import { useAppDispatch } from 'store/hooks';

export const LibraryMenu = () => {
  const dispatch = useAppDispatch();

  const businessId = useBusinessId();
  const isMobile = useIsMobile();

  const { t: translate } = useTranslation();

  const handleSelectFolder = (selectedKeys: React.Key[]) => {
    const [folderId] = selectedKeys;

    const clickedFolder = folderTreeData?.find((folder) => folder.key === folderId);

    if (clickedFolder) {
      dispatch(setSelectedLibrary(clickedFolder?.title as EPictureLibrary));
    }
  };

  const folderTreeData: DataNode[] = [
    {
      title: EPictureLibrary.UNSPLASH,
      key: '1',
    },
    {
      title: EPictureLibrary.PIXABAY,
      key: '2',
    },
    {
      title: 'Adobe Stock',
      key: '3',
    },
  ];

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Row justify={'space-between'}>
          <Col>
            <Typography.Text type='secondary'>{translate('file.directories')}</Typography.Text>
          </Col>
        </Row>
      ),
      children: (
        <Space direction='vertical' className='w-full'>
          {folderTreeData?.length === 0 ? (
            <Typography.Text className='fs-12' type='secondary'>
              {translate('file.noDirectories')}
            </Typography.Text>
          ) : (
            <Tree
              showLine
              onSelect={handleSelectFolder}
              treeData={folderTreeData}
              titleRender={(nodeData: DataNode) => (
                <Row className='w-full h-full' justify='space-between' align='middle' wrap={false}>
                  <FolderOutlined className='mr-5' />
                  <Typography.Text className='row-label mb-0'>{nodeData?.title?.toString()}</Typography.Text>
                </Row>
              )}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col className='library-menu-wrapper'>
        <Row justify='space-between'>{isMobile && <Button icon={<CloseOutlined />} type='link' />}</Row>

        <Row className='mt-25 w-full'>
          <Collapse key={businessId} defaultActiveKey={[1]} ghost items={items} className='library-menu-collapse-wrapper' />
        </Row>
      </Col>
    </Row>
  );
};
