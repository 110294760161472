import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Col, Row, Typography } from 'antd';

import { BOOKING_LIST } from '@aduvi/constants/client-portal-constants';
import { IEntityWithFields } from '@aduvi/types/entity';

import { setSelectedJob } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const BookingList = ({ readonly = true }: { readonly?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const { user } = useAppSelector((state) => state.clientPortalAuth);

  const navigateToBooking = (booking: IEntityWithFields) => {
    dispatch(setSelectedJob(booking));
    navigate(`../portal/job/${booking.id}`);
  };

  return (
    <div className='client-booking-list-wrapper'>
      {readonly ? (
        <>
          {user?.jobs?.loading && <Typography.Paragraph type='secondary'>{translate('clientPortal.loggedUser.loadingJobs')}</Typography.Paragraph>}
          {user?.jobs?.data &&
            user?.jobs?.data?.length > 0 &&
            !user?.jobs?.loading &&
            user?.jobs?.data?.map((booking, index) => (
              <Row
                justify={'space-between'}
                onClick={() => readonly && navigateToBooking(booking)}
                style={{ cursor: readonly ? 'pointer' : 'auto' }}
                className='booking py-10'
                key={index}>
                <Col>
                  <Typography.Paragraph className='mb-0 fs-14'>
                    {booking?.custom_fields?.find((field) => field?.title === 'Event Name')?.field_data?.[0]?.value}
                  </Typography.Paragraph>
                </Col>
                <Col>
                  <Typography.Paragraph className='mb-0 fs-14'>
                    {dayjs(booking?.custom_fields?.find((field) => field?.title === 'Event Date')?.field_data?.[0]?.value).format('DD/MM/YYYY')}
                  </Typography.Paragraph>
                </Col>
              </Row>
            ))}
          {!user?.jobs?.loading && !user?.jobs?.data && (
            <Typography.Paragraph type='secondary'>{translate('clientPortal.loggedUser.noOrders')}</Typography.Paragraph>
          )}
        </>
      ) : (
        BOOKING_LIST.map((booking) => (
          <Row
            justify={'space-between'}
            onClick={() => readonly && navigate(`/portal`)}
            style={{ cursor: readonly ? 'pointer' : 'auto' }}
            className='booking py-10'
            key={booking.id}>
            <Col>
              <Typography.Paragraph className='mb-0 fs-14'>{booking?.name}</Typography.Paragraph>
              <Typography.Paragraph type='secondary' className='mb-0 fs-11'>
                {booking?.category}
              </Typography.Paragraph>
            </Col>
            <Col>
              <Typography.Paragraph className='mb-0 fs-14'>{dayjs(booking?.date).format('DD/MM/YYYY')}</Typography.Paragraph>
              <Typography.Paragraph type='secondary' className='mb-0 fs-11'>
                {booking?.time}
              </Typography.Paragraph>
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};
