import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Form, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusinessId } from '@aduvi/hooks';
import { ITicket, TicketDrawerTabs } from '@aduvi/types/event';
import { getFormErrors } from '@aduvi/utils/helper';

import { createTicket, getTicketById, updateTicket } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Details } from './details/Details';
import { LimitVisibility } from './limit-visibility/LimitVisibility';
import { Pricing } from './pricing/Pricing';
import { QuantityStock } from './quantity-stock/QuantityStock';
import { Variations } from './variations/Varitations';

const items = (translate: (key: string) => string, form: FormInstance): TabsProps['items'] => [
  {
    key: TicketDrawerTabs.DETAILS,
    label: translate('assets.ticketDrawer.details.title'),
    children: <Details form={form} />,
  },
  {
    key: TicketDrawerTabs.QUANTITY_STOCK,
    label: translate('assets.ticketDrawer.quantityStock.title'),
    children: <QuantityStock form={form} />,
  },
  {
    key: TicketDrawerTabs.PRICING,
    label: translate('assets.ticketDrawer.pricing.title'),
    children: <Pricing form={form} />,
  },
  {
    key: TicketDrawerTabs.VARIATIONS,
    label: translate('assets.ticketDrawer.variations.title'),
    children: <Variations form={form} />,
  },
  {
    key: TicketDrawerTabs.LIMIT_VISIBILITY,
    label: translate('assets.ticketDrawer.limitVisibility.title'),
    children: <LimitVisibility />,
  },
];

export const TicketsDrawer = ({ onClose, open }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(TicketDrawerTabs.DETAILS);

  const {
    entities: { selectedEntity, selectedTicket, creating, updating, loadingTicket },
  } = useAppSelector((state) => state.entity);

  const createSingleTicket = () => {
    if (!selectedEntity?.id || !selectedBusinessId) return;

    form
      .validateFields()
      .then((values: ITicket) => {
        dispatch(
          createTicket({
            business_id: selectedBusinessId,
            event_id: selectedEntity?.id,
            body: {
              ...values,
              variations: values.variations ?? [],
            },
          }),
        )
          .unwrap()
          .then(() => {
            closeDrawer();
          })
          .catch((error) => {
            form.setFields(getFormErrors(error));
          });
      })
      .catch(() => {});
  };

  const updateSingleTicket = () => {
    if (!selectedTicket?.id) return;

    dispatch(
      updateTicket({
        business_id: selectedBusinessId,
        event_id: selectedTicket.business_event_id,
        ticket_id: selectedTicket.id,
        body: {
          ...selectedTicket,
          ...form.getFieldsValue(),
        },
      }),
    )
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const submitFormHandler = () => {
    selectedTicket?.id ? updateSingleTicket() : createSingleTicket();
  };

  const closeDrawer = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (!selectedTicket?.id || !selectedTicket?.business_event_id) return;
    dispatch(
      getTicketById({
        business_id: selectedBusinessId,
        ticket_id: selectedTicket.id,
        event_id: selectedTicket.business_event_id,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        form.setFieldsValue({
          ...data,
          sale_end_date: data.sale_end_date ? dayjs(data.sale_end_date) : undefined,
          coupons: data.coupons.map((item) => item.id),
        });
      });
  }, [selectedTicket?.id, selectedBusinessId]);

  return (
    <Drawer
      title={selectedTicket?.name || translate('assets.ticketDrawer.createTicket')}
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button onClick={closeDrawer} disabled={creating || updating}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            requiredPermission={selectedTicket ? PERMISSIONS.EVENTS.EDIT : PERMISSIONS.EVENTS.CREATE}
            disabledButton
            type='primary'
            onClick={submitFormHandler}
            disabled={creating || updating}
            loading={creating || updating}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingTicket}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Tabs defaultActiveKey={TicketDrawerTabs.DETAILS} activeKey={tab} items={items(translate, form)} onChange={setTab} />
        </Form>
      </Spin>
    </Drawer>
  );
};
