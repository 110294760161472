import { Outlet } from 'react-router-dom';

import { Col, Row } from 'antd';
import './Academy.scss';

import { CommonSubMenu } from '@aduvi/components/CommonSubMenu/CommonSubMenu';

import { AcademyMenu } from './@components/academy-menu/AcademyMenu';

export const Academy = () => {
  return (
    <Row className='h-full'>
      <CommonSubMenu style={{ borderRight: '1px solid #e7e9f5', boxShadow: ' 0px 2px 8px 0px #5194D826' }}>
        <AcademyMenu />
      </CommonSubMenu>

      <Col xs={24} md={17} xl={19} xxl={20}>
        <Outlet />
      </Col>
    </Row>
  );
};
