import { useAppSelector } from 'store/hooks';
import { useGetEntityFieldsQuery } from 'store/rtk-slice/fields-slice';

import { useBusiness } from './business';

export const useGetContactManagementFields = () => {
  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);

  const { data: contactCustomFields, isLoading } = useGetEntityFieldsQuery(
    { businessId: selectedBusiness?.id, entityTypeId: entityTypes?.data?.CONTACT?.id },
    { skip: !entityTypes?.data?.CONTACT?.id || !selectedBusiness?.id },
  );

  return { contactCustomFields, isLoading };
};
