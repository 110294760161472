import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

export const Timeline = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const defaultStatusOptions = fields
    .filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)
    ?.map((field) => ({ label: field?.title, value: field?.id }));

  useEffect(() => {
    form.setFieldsValue({
      start_date: form.getFieldValue('start_date') || defaultStatusOptions?.[0]?.value,
      end_date: form.getFieldValue('end_date') || defaultStatusOptions?.[defaultStatusOptions.length - 1]?.value,
    });
  }, []);

  return (
    <Row className='w-full mt-10'>
      <Form.Item className='w-full' name='start_date' label={translate('leads.startDate')}>
        <Select allowClear placeholder={translate('leads.startDate')} options={defaultStatusOptions} />
      </Form.Item>

      <Form.Item className='w-full' name='end_date' label={translate('leads.endDate')}>
        <Select allowClear placeholder={translate('leads.endDate')} options={defaultStatusOptions} />
      </Form.Item>
    </Row>
  );
};
