import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, EnvironmentOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Button, Dropdown, MenuProps, Popconfirm, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useLongPress, useUserPermissionCheck } from '@aduvi/hooks';
import { ILocation } from '@aduvi/types';

import { deleteLocation, getLocations, setSelectedLocation } from 'store/features/business-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useSettingsLocations = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const [form] = useForm();
  const { handleMouseDown, handleMouseUp, checkLongPress } = useLongPress();

  const [showDrawer, setShowDrawer] = useState(false);

  const columns = useLocationsTableColumns(setShowDrawer);

  const { locations } = useAppSelector((state) => state.business);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getLocations(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  return {
    dispatch,
    selectedBusiness,
    translate,
    handleMouseDown,
    handleMouseUp,
    checkLongPress,
    showDrawer,
    setShowDrawer,
    columns,
    locations,
    form,
  };
};

const useLocationsTableColumns = (setShowDrawer: Dispatch<SetStateAction<boolean>>) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { locations } = useAppSelector((state) => state.business);

  const getLocationDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('settings.locations.edit'),
      onClick: () => setShowDrawer(true),
    },
    {
      key: '1',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('settings.locations.delete')}
          disabled={!useUserPermissionCheck(PERMISSIONS.LOCATIONS.DELETE)}
          description={translate('settings.locations.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedBusiness?.id || !locations.selectedLocation?.id) return;
            dispatch(
              deleteLocation({
                businessId: selectedBusiness?.id,
                locationId: locations.selectedLocation?.id,
                locationName: locations?.selectedLocation?.name,
              }),
            ).unwrap();
          }}>
          {translate('settings.locations.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.LOCATIONS.DELETE),
    },
  ];

  return [
    {
      render: (record: ILocation) => {
        return (
          <Row align={'middle'}>
            <EnvironmentOutlined className='fs-18' />
            <span className='ml-20'>{record.name}</span>
          </Row>
        );
      },
    },
    {
      key: 'action',
      render: (record: ILocation) => {
        return (
          <Row className='w-full' justify='end' onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{ items: getLocationDropdownItems }} trigger={['click']}>
              <Button
                style={{ width: '32px' }}
                onClick={(e) => {
                  e?.stopPropagation();
                  dispatch(setSelectedLocation(record));
                }}
                icon={<EllipsisOutlined />}
                type='text'
              />
            </Dropdown>
          </Row>
        );
      },
    },
  ];
};
