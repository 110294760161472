export const LayoutIconCards = ({ active = false }: { active?: boolean }) => {
  return (
    <svg width='60' height='62' viewBox='0 0 60 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_188068_40607)'>
        <g clipPath='url(#clip0_188068_40607)'>
          <rect width='60' height='60' rx='2' fill='white' />
          <rect x='18.5' y='13.5' width='23' height='33' rx='1.5' stroke='#F1F1F1' />
          <rect x='22' y='16' width='16' height='16' rx='2' fill='#828282' fillOpacity='0.2' />
          <rect x='22' y='35' width='16' height='3' rx='1.5' fill='#828282' fillOpacity='0.15' />
          <rect x='24' y='41' width='12' height='3' rx='1.5' fill='#626262' fillOpacity='0.1' />
          <rect x='44.5' y='13.5' width='23' height='33' rx='1.5' stroke='#F1F1F1' />
          <rect x='48' y='16' width='16' height='16' rx='2' fill='#828282' fillOpacity='0.2' />
          <rect x='48' y='35' width='16' height='3' rx='1.5' fill='#828282' fillOpacity='0.15' />
          <rect x='50' y='41' width='12' height='3' rx='1.5' fill='#626262' fillOpacity='0.1' />
          <path d='M6 30L10.5 27.4019L10.5 32.5981L6 30Z' fill='#D9D9D9' />
        </g>
        <rect x='0.5' y='0.5' width='59' height='59' rx='1.5' stroke={active ? '#1890FF' : '#D9D9D9'} />
      </g>
      <defs>
        <filter id='filter0_d_188068_40607' x='0' y='0' width='60' height='62' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.016 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_188068_40607' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_188068_40607' result='shape' />
        </filter>
        <clipPath id='clip0_188068_40607'>
          <rect width='60' height='60' rx='2' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
