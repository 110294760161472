import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Col, Layout, Modal, Row, Typography } from 'antd';

import Tiptap from '@aduvi/Tiptap/Tiptap';

interface IProps {
  open: boolean;
  title?: string;
  content?: string;
  backgroundColor?: string;
  fontFamily?: string;
  primaryFontColor?: string;
  formBackground?: string;
  signature?: string;
  summary?: string;
  onClose: () => void;
}

export const ContractModal = ({
  open,
  backgroundColor,
  content,
  fontFamily,
  formBackground,
  primaryFontColor,
  signature,
  summary,
  title,
  onClose,
}: IProps) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      title={translate('settings.contracts.contract')}
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
      centered={true}
      styles={{ body: { overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' } }}>
      <Row className={'edit-contract-container m-0'}>
        <Layout>
          <Row style={{ minHeight: '100%', background: backgroundColor }}>
            <Col span={24} className='mt-30 px-30'>
              <Row justify={'space-between'}>
                <Typography.Text>{title}</Typography.Text>
                <Typography.Text>{summary}</Typography.Text>
              </Row>
              <Row className='w-full' justify='center'>
                <Col span={24} className='mt-20'>
                  <Row
                    className='editor-wrapper'
                    onClick={focus}
                    style={{
                      fontFamily,
                      color: primaryFontColor,
                      background: formBackground,
                    }}>
                    <Col>
                      <Tiptap content={content as string} />
                    </Col>

                    <Col>
                      <img src={`data:image/png;base64,${signature}`} alt='User Signature' />
                      <Typography.Text className='signature-acknowledgement'>
                        {translate('settings.contracts.signatureAcknowledgementText')}{' '}
                        <NavLink target='_blank' to='https://en.wikipedia.org/wiki/Electronic_signature' style={{ textDecoration: 'underline' }}>
                          {translate('settings.contracts.here')}
                        </NavLink>
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      </Row>
    </Modal>
  );
};
