import { Col, Row, Skeleton } from 'antd';
import './AppLayoutLoading.scss';

import { useIsMobile } from '@aduvi/hooks';

import { AppLayoutLoadingMobile } from './AppLoadingLayoutLoading';

export const AppLayoutLoading = () => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <AppLayoutLoadingMobile />
  ) : (
    <Row className='app-loading-wrapper'>
      <Col className='sidebar'>
        <Row className='sider-items'>
          <Row className='top-sider-items'>
            {Array.from({ length: 7 }).map((_, index) => (
              <Skeleton.Avatar active key={index} shape='square' size='default' />
            ))}
          </Row>

          <Row className='bottom-sider-items'>
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton.Avatar active key={index} shape='square' size='default' />
            ))}
          </Row>
        </Row>
      </Col>

      <Col className='skeleton-wrapper'>
        <Row className='main-skeleton'>
          <Skeleton active className='layout-skeleton' paragraph={{ rows: 4 }} />
        </Row>
      </Col>
    </Row>
  );
};
