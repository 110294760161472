import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select, Typography } from 'antd';

import { EFieldDataValueType } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const ShiftDate = () => {
  const { t: translate } = useTranslation();
  const { fields } = useAppSelector((state) => state.fields);

  const dateFields = useMemo(() => fields.filter((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES), [fields]);

  return (
    <>
      <label>{translate('components.customFieldsDrawer.dateField')}</label>
      <br />
      <Typography.Text type='secondary' className='mt-5'>
        {translate('components.customFieldsDrawer.dateFieldDescription')}
      </Typography.Text>
      <Form.Item name={['settings', 'controller_date_file_id']} className='mt-5'>
        <Select
          options={dateFields.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
        />
      </Form.Item>
    </>
  );
};
