import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import {
  IBusinessUser,
  IDeleteBusinessUserPayload,
  IExportBusinessUsers,
  IGetBusinessUserByIdPayload,
  IGetBusinessUsersPayload,
  IImportBusinessUsers,
  IInviteBusinessUserPayload,
  IUpdateBusinessUserPayload,
  IUserState,
} from '@aduvi/types';
import { IGetUserActivitiesPayload } from '@aduvi/types/audit';
import { downloadCSVFile, transformAuditToActivity } from '@aduvi/utils/helper';

import { apiSlice } from 'store/api/apiSlice';
import * as UserService from 'store/services/user.service';

const initialState: IUserState = {
  selectedUser: undefined,
  userActivities: undefined,
  users: undefined,
  loadingUser: false,
  loading: false,
  creating: false,
  updating: false,
};

export const getBusinessUsers = createAsyncThunk('user/get-users', async (params: IGetBusinessUsersPayload, { rejectWithValue }) => {
  try {
    return await UserService.getBusinessUsers(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBusinessUserById = createAsyncThunk('user/get-userById', async (params: IGetBusinessUserByIdPayload, { rejectWithValue }) => {
  try {
    return await UserService.getBusinessUserById(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteUser = createAsyncThunk('user/delete-user', async (params: IDeleteBusinessUserPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.USER }]));
    return await UserService.deleteUser(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const inviteBusinessUser = createAsyncThunk('user/invite-user', async (params: IInviteBusinessUserPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.USER }]));
    return await UserService.inviteBusinessUser(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateBusinessUser = createAsyncThunk('user/update-user', async (params: IUpdateBusinessUserPayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.USER }]));
    return await UserService.updateBusinessUser(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const exportBusinessUsers = createAsyncThunk('user/export-users', async (params: IExportBusinessUsers, { rejectWithValue }) => {
  try {
    return await UserService.exportBusinessUsers(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const downloadSampleUsersCSV = createAsyncThunk('user/download-sample-users-CSV', async (_, { rejectWithValue }) => {
  try {
    const data = await UserService.downloadSampleUsersCSV();

    downloadCSVFile(data, 'sample.csv');
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const importBusinessUsers = createAsyncThunk('user/import-users', async (params: IImportBusinessUsers, { rejectWithValue }) => {
  try {
    return await UserService.importBusinessUsers(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBusinessUserActivities = createAsyncThunk('user/get-activities', async (params: IGetUserActivitiesPayload, { rejectWithValue }) => {
  try {
    return await UserService.getBusinessUserActivities(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTheme = createAsyncThunk(
  'user/update-theme',
  async (params: { userId: string; businessId: string; partnerThemeId?: string }, { rejectWithValue }) => {
    try {
      return await UserService.updateTheme(params.userId, params.businessId, params?.partnerThemeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<IBusinessUser | undefined>) => {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBusinessUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.users = payload;
      })
      .addCase(getBusinessUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBusinessUserById.pending, (state) => {
        state.loadingUser = true;
      })
      .addCase(getBusinessUserById.fulfilled, (state, { payload }) => {
        state.loadingUser = false;
        state.selectedUser = payload.data;
      })
      .addCase(getBusinessUserById.rejected, (state) => {
        state.loadingUser = false;
      })
      .addCase(inviteBusinessUser.pending, (state) => {
        state.creating = true;
      })
      .addCase(inviteBusinessUser.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.users) {
          state.users.data = [...state.users.data, payload.data];
        }
      })
      .addCase(inviteBusinessUser.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateBusinessUser.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateBusinessUser.fulfilled, (state, { meta, payload }) => {
        state.updating = false;
        if (state.users) {
          state.users.data = state.users.data?.map((user) => {
            return user?.id === meta?.arg?.userId ? payload?.data : user;
          });
        }

        if (state.selectedUser?.id === payload?.data?.id) {
          state.selectedUser = payload.data;
        }
      })
      .addCase(updateBusinessUser.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        if (state.users) {
          state.users = {
            ...state.users,
            data: state.users.data.filter((user) => user.id !== state.selectedUser?.id),
          };
        }
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(importBusinessUsers.pending, (state) => {
        state.creating = true;
      })
      .addCase(importBusinessUsers.fulfilled, (state, { payload }) => {
        state.creating = false;
        if (state.users) {
          state.users.data = [...state.users.data, ...payload.data];
        }
      })
      .addCase(importBusinessUsers.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getBusinessUserActivities.pending, (state) => {
        state.userActivities = undefined;
        state.loading = true;
      })
      .addCase(getBusinessUserActivities.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getBusinessUserActivities.fulfilled, (state, { payload }) => {
        state.userActivities = { ...payload, data: payload.data.map(transformAuditToActivity) };
        state.loading = false;
      })
      .addCase(updateTheme.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateTheme.fulfilled, (state) => {
        state.updating = false;
      })
      .addCase(updateTheme.rejected, (state) => {
        state.updating = false;
      });
  },
});

export const userReducer = userSlice.reducer;
export const { setSelectedUser } = userSlice.actions;
