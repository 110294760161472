import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Avatar, Button, Col, Form, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib';
import '../ShiftScheduler.scss';

import { useBusiness } from '@aduvi/hooks';
import { EReferenceEntityType, IEntityField } from '@aduvi/types';
import { ISelectedItem, ISelectedUser, IShiftPayload } from '@aduvi/types/shift-scheduler';

import { getProducts } from 'store/features/products-slice';
import { getServices } from 'store/features/services-slice';
import { getShiftSchedulerSettings, setPopoverIndex, upsertShift } from 'store/features/shift-scheduler-slice';
import { getBusinessUsers } from 'store/features/user-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { generateHourMarkers } from '../helper';

import { AvatarsPopover } from './avatars-popover/AvatarsPopover';
import { PositionsPopover } from './positions-popover/PositionsPopover';
import { ShiftSlider } from './shift-slider/ShiftSlider';
import { WagesPopover } from './wages-popover/WagesPopover';

dayjs.extend(utc);

export const ShiftPlanner = ({ entityId, fields, form }: { entityId: string; fields: IEntityField[]; form: FormInstance }) => {
  Form.useWatch('shifts', form);

  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { users } = useAppSelector((state) => state.user);
  const { settings, visiblePopovers } = useAppSelector((state) => state.shiftScheduler);
  const { services } = useAppSelector((state) => state.services);
  const { products } = useAppSelector((state) => state.products);

  const { selectedEntity } = useAppSelector((state) => state.entity.entities);

  const shiftField = selectedEntity?.custom_fields?.find((field) => field?.reference_entity_type === EReferenceEntityType.SHIFT);
  const shiftStartEndDateFieldData = selectedEntity?.custom_fields?.find(
    (item) => item.field_data?.[0]?.field_id === shiftField?.settings?.controller_date_file_id,
  );

  const allowMultipleShifts = fields?.find((field) => field?.reference_entity_type === EReferenceEntityType.SHIFT)?.multiple;
  const fieldID = fields?.find((field) => field?.reference_entity_type === EReferenceEntityType.SHIFT)?.id;

  const assignedElements: ISelectedItem[] = [
    ...(settings?.positions?.map((item) => ({ id: item?.id, name: item?.position, image: '', position: item?.position })) || []),
    ...(services?.data.map((item) => ({ id: item?.id, name: item?.name, image: item?.image })) || []),
    ...(products?.data.map((item) => ({ id: item?.id, name: item?.name, image: item?.image })) || []),
  ];

  const initialStartTime = shiftStartEndDateFieldData?.field_data?.[0]?.value
    ? dayjs(shiftStartEndDateFieldData?.field_data?.[0]?.value).hour() * 60 + dayjs(shiftStartEndDateFieldData?.field_data?.[0]?.value).minute()
    : 9 * 60;

  const initialEndTime = shiftStartEndDateFieldData?.field_data?.[0]?.end_date_time
    ? dayjs(shiftStartEndDateFieldData?.field_data?.[0]?.end_date_time).hour() * 60 +
      dayjs(shiftStartEndDateFieldData?.field_data?.[0]?.end_date_time).minute()
    : 17 * 60;

  const step = settings?.shift_increments ?? 5;

  const [minTime, setMinTime] = useState(initialStartTime);
  const [maxTime, setMaxTime] = useState(initialEndTime);

  const hourMarkers = generateHourMarkers(minTime, maxTime);

  const decreaseMinTime = () => {
    const newMin = Math.max(0, minTime - 60);
    setMinTime(newMin);
  };

  const increaseMaxTime = () => {
    const newMax = Math.min(24 * 60, maxTime + 60);
    setMaxTime(newMax);
  };

  const handlePopoverToggle = (popoverType: string, index: number) => {
    const isVisible = visiblePopovers[popoverType] === index;
    dispatch(setPopoverIndex({ popoverType, index: isVisible ? undefined : index }));
  };

  const upsertShifts = () => {
    const formData = form.getFieldsValue();
    const shifts = formData?.shifts;

    const formattedData = {
      shifts: shifts.map((shift: IShiftPayload) => ({
        id: shift?.id,
        user_id: shift?.user_id,
        assigned_id: shift.assigned_id,
        start_shift: dayjs()
          .startOf('day')
          .add(1, 'hour')
          .add(Number(shift.start_shift) ?? 0, 'minute'),
        end_shift: dayjs()
          .startOf('day')
          .add(1, 'hour')
          .add(Number(shift.end_shift) ?? 0, 'minute'),
        status: shift?.status,
        wage_amount: shift?.wage_amount,
        notes: shift?.notes,
      })),
    };

    if (!selectedBusiness?.id) return;
    dispatch(
      upsertShift({
        business_id: selectedBusiness?.id,
        entity_id: entityId,
        field_id: fieldID,
        ...formattedData,
      }),
    );
  };

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    const params = {
      page: 1,
      size: 10,
    };

    if (!settings) {
      dispatch(getShiftSchedulerSettings({ businessId: selectedBusiness?.id }));
    }

    if (settings?.assign_user_to_products) {
      dispatch(
        getProducts({
          business_id: selectedBusiness?.id,
          params,
        }),
      );
    }

    if (settings?.assign_user_to_services) {
      dispatch(
        getServices({
          business_id: selectedBusiness?.id,
          params,
        }),
      );
    }

    dispatch(
      getBusinessUsers({
        business_id: selectedBusiness?.id,
        params,
      }),
    );
  }, [selectedBusiness?.id, settings?.assign_user_to_services, settings?.assign_user_to_products]);

  useEffect(() => {
    if (shiftField?.field_data && shiftField?.field_data?.length > 0) {
      const formattedShifts = shiftField?.field_data?.map((shift) => {
        return {
          id: shift?.id,
          start_shift: shift?.start_shift
            ? dayjs(shift?.start_shift)
                .utc()
                .hour() *
                60 +
              dayjs(shift?.start_shift).minute()
            : undefined,
          end_shift: shift?.end_shift
            ? dayjs(shift?.end_shift)
                .utc()
                .hour() *
                60 +
              dayjs(shift?.end_shift).minute()
            : undefined,
          user_id: shift?.user_id,
          status: shift?.status,
          notes: shift?.notes,
          wage_amount: shift?.wage_amount,
          wage_type: shift?.wage_type,
          travel_amount: shift?.travel_amount,
          travel_type: shift?.travel_type,
          assigned_id: shift?.assigned_id,
        };
      });

      form.setFieldsValue({ shifts: formattedShifts });

      const shiftMinTime = Math.min(...formattedShifts?.map((item) => Number(item.start_shift)));
      const shiftMaxTime = Math.min(...formattedShifts?.map((item) => Number(item.end_shift)));

      if (shiftMinTime < minTime) setMinTime(shiftMinTime);
      if (shiftMaxTime > maxTime) setMaxTime(shiftMaxTime);
    }
  }, [shiftField?.field_data, form]);

  return (
    <Row className='p-20'>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Title level={5}>{translate('settings.shiftScheduler.shifts')}</Typography.Title>
          </Col>
        </Row>

        <Row justify='start' align='middle'>
          <Col span={3}>
            <Typography.Text className='fs-14' style={{ color: 'orange' }} type='secondary'>
              1 available
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Row>
              <Button onClick={decreaseMinTime} type='link'>
                -
              </Button>
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                {hourMarkers.map((time, index) => (
                  <div
                    key={index}
                    style={{
                      justifyItems: 'center',
                    }}>
                    <Typography.Text className='fs-10' type='secondary'>
                      {time.toLowerCase()}
                    </Typography.Text>
                    <div
                      style={{
                        height: '5px',
                        borderLeft: '1px solid gray',
                      }}
                    />
                  </div>
                ))}
              </div>
              <Button onClick={increaseMaxTime} type='link'>
                +
              </Button>
            </Row>
          </Col>
        </Row>

        <Form.List name='shifts' initialValue={[]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row
                  key={field.key}
                  align='middle'
                  className='box-shadow p-10'
                  style={{
                    background: '#fff',
                    marginBottom: '1px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <Form.Item name={[field.name, 'id']} hidden />
                  <Form.Item name={[field.name, 'start_shift']} hidden />
                  <Form.Item name={[field.name, 'end_shift']} hidden />
                  <Col span={2}>
                    <Form.Item name={[field.name, 'user_id']} hidden />
                    <Form.Item name={[field.name, 'assigned_id']} hidden />
                    <Avatar
                      size='default'
                      icon={
                        users?.data.find((item) => item.id === form.getFieldValue(['shifts', index, 'user_id']))?.profile_picture ? (
                          <img
                            src={users?.data.find((item) => item.id === form.getFieldValue(['shifts', index, 'user_id']))?.profile_picture}
                            style={{ width: '100%', height: '100%' }}
                          />
                        ) : users?.data.find((item) => item.id === form.getFieldValue(['shifts', index, 'user_id']))?.first_name ? (
                          <span>
                            {users?.data
                              .find((item) => item.id === form.getFieldValue(['shifts', index, 'user_id']))
                              ?.first_name.charAt(0)
                              .toUpperCase()}
                          </span>
                        ) : (
                          <PlusOutlined className='fs-10 color-gray-5' />
                        )
                      }
                      style={{ cursor: 'pointer', background: '#f0f0f0' }}
                      onClick={() => handlePopoverToggle('users', index)}
                    />

                    {visiblePopovers['users'] === index && (
                      <AvatarsPopover
                        onSelect={(user: ISelectedUser) => {
                          form.setFieldValue(['shifts', index, 'user_id'], user.id);
                        }}
                        isVisible={true}
                        onClose={() => dispatch(setPopoverIndex({ popoverType: 'users', index: undefined }))}
                      />
                    )}
                  </Col>

                  <Col span={2}>
                    <Avatar
                      size='default'
                      icon={
                        form.getFieldValue(['shifts', index, 'assigned_id']) &&
                        assignedElements?.find((element) => element.id === form.getFieldValue(['shifts', index, 'assigned_id'])) ? (
                          assignedElements.find((element) => element.id === form.getFieldValue(['shifts', index, 'assigned_id']))?.image ? (
                            <img
                              src={services?.data.find((item) => item.id === form.getFieldValue(['shifts', index, 'assigned_id']))?.image}
                              style={{ width: '100%', height: '100%' }}
                            />
                          ) : (
                            <span>
                              {assignedElements
                                ?.find((element) => element?.id === form.getFieldValue(['shifts', index, 'assigned_id']))
                                ?.name?.slice(0, 2)}
                            </span>
                          )
                        ) : (
                          <PlusOutlined className='fs-10 color-gray-5' />
                        )
                      }
                      style={{ cursor: 'pointer', background: '#f0f0f0' }}
                      onClick={() => handlePopoverToggle('positions', index)}
                    />

                    {visiblePopovers['positions'] === index && (
                      <PositionsPopover
                        isVisible={true}
                        onClose={() => dispatch(setPopoverIndex({ popoverType: 'positions', index: undefined }))}
                        onSelect={(position) => {
                          form.setFieldValue(['shifts', index, 'assigned_id'], position.id);
                        }}
                      />
                    )}
                  </Col>

                  <Col span={18}>
                    <ShiftSlider
                      range
                      initialEndTime={form.getFieldValue(['shifts', index, 'start_shift']) ?? initialEndTime}
                      initialStartTime={form.getFieldValue(['shifts', index, 'end_shift']) ?? initialStartTime}
                      maxTime={maxTime}
                      minTime={minTime}
                      step={step}
                      onChange={(values) => {
                        const [startValue, endValue] = values;
                        form.setFieldValue([`shifts`, index, `start_shift`], startValue);
                        form.setFieldValue([`shifts`, index, `end_shift`], endValue);
                      }}
                    />
                  </Col>

                  <Col span={2}>
                    <Button type='text' icon={<EllipsisOutlined />} onClick={() => handlePopoverToggle('wages', index)} />
                    {visiblePopovers['wages'] === index && (
                      <WagesPopover
                        form={form}
                        index={index}
                        isVisible={true}
                        onClose={() => dispatch(setPopoverIndex({ popoverType: 'wages', index: undefined }))}
                        remove={remove}
                      />
                    )}
                  </Col>
                </Row>
              ))}

              <Row justify='space-between'>
                <Col>
                  <Button type='primary' className='mt-20' onClick={upsertShifts}>
                    {translate('settings.shiftScheduler.save')}
                  </Button>
                </Col>
                <Col>
                  <Button type='primary' icon={<PlusOutlined />} className='mt-20' disabled={!allowMultipleShifts} onClick={() => add()}>
                    {translate('settings.shiftScheduler.addShift')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  );
};
