import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Avatar, Badge, Tooltip } from 'antd';

import { IBusinessUser, IPartnerUser } from '@aduvi/types';

interface IProps {
  user: IBusinessUser | IPartnerUser;
}

export const UserAvatar: React.FC<IProps> = ({ user }) => {
  const now = dayjs();
  const adjustedLastActiveTime = dayjs(user?.last_active).add(2, 'hour');

  const diffInMinutes = now.diff(adjustedLastActiveTime, 'minute');
  const isActive = diffInMinutes <= 10;

  return (
    <Badge offset={[0, 20]} dot={isActive} color='green'>
      <Tooltip title={user?.last_active ? `Last active at ${adjustedLastActiveTime.fromNow()}` : 'Unknown'}>
        <Avatar size='small' src={user?.profile_picture} icon={<UserOutlined />} />
      </Tooltip>
    </Badge>
  );
};
