import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleFilled } from '@ant-design/icons';

import { Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { IEntityWithFields } from '@aduvi/types/entity';
import { IEntityField } from '@aduvi/types/fields';
import { toCapitalize } from '@aduvi/utils/helper';

import { createEntity } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { mapEntityFieldsToPayload } from '../Entity/helper';

import { CreateInlineEntityModal } from './CreateInlineEntityModal';

interface IProps {
  entityTypeId: string;
  entityTitle?: string;
  onAutoSelect?: (entity: IEntityWithFields) => void;
}

export const CreateInlineEntity = ({ entityTypeId, entityTitle, onAutoSelect }: IProps) => {
  const activeTheme = useActiveTheme();
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    entities: { creating },
  } = useAppSelector((state) => state.entity);

  const createNewEntity = (fields: IEntityField[]) => {
    if (!selectedBusiness?.id || !entityTypeId || !fields) return;
    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityTypeId,
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), fields),
        skip_inserting_on_state: true,
      }),
    )
      .unwrap()
      .then(({ data }) => {
        onAutoSelect?.(data);
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch(() => {});
  };

  return (
    <>
      <Row style={{ padding: '16px 8px', cursor: 'pointer' }} onClick={() => setIsModalVisible(true)}>
        <PlusCircleFilled style={{ color: `${activeTheme?.menuItemText}` }} className='fs-24 mr-10' />
        <Typography.Text style={{ color: `${activeTheme?.menuItemText}` }}>{`${translate('inlineEntity.createNew')} ${toCapitalize(
          entityTitle,
        )}`}</Typography.Text>
      </Row>

      {isModalVisible && (
        <CreateInlineEntityModal
          form={form}
          entityTypeId={entityTypeId}
          creating={creating}
          entityTitle={entityTitle}
          createNewEntity={createNewEntity}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};
