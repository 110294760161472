import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useProject } from 'views/projects/hooks/useProject';

import { Table } from 'antd';
import './Quotes.scss';

import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { useBusiness } from '@aduvi/hooks';

import { getQuotes } from 'store/features/quote-slice';
import { useAppDispatch } from 'store/hooks';
interface IJobQuotes {
  jobId?: string | number;
  jobName?: string | number;
  jobDate?: string | number;
  total: string;
  subTotal: string;
  tax: number;
}

interface IOrderQuotes {
  orderId?: string | number;
  total: string;
  subTotal: string;
  tax: number;
}

export const Quote = () => {
  const dispatch = useAppDispatch();

  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const [jobQuotes, setJobQuotes] = useState<IJobQuotes[]>([]);
  const [orderQuotes, setOrderQuotes] = useState<IJobQuotes[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const jobTableColumns = useJobsQuotesColumns();
  const orderTableColumns = useOrdersQuotesColumns();

  const { jobFields, orderFields } = useProject();

  const quotesTotals = useMemo(() => {
    const total = [...jobQuotes, ...orderQuotes]?.reduce((sum, quote) => sum + Number(quote.total), 0);
    const subTotal = [...jobQuotes, ...orderQuotes]?.reduce((sum, quote) => sum + Number(quote.subTotal), 0);

    return {
      total: total.toFixed(2),
      subTotal: subTotal.toFixed(2),
    };
  }, [jobQuotes, orderQuotes]);

  useEffect(() => {
    if (!selectedBusiness?.id || !jobFields || jobFields.length === 0) return;

    const fetchJobQuotes = () => {
      setLoading(true);

      const quotePromises = jobFields.map((field) => dispatch(getQuotes({ businessId: selectedBusiness.id, entityId: field.id })).unwrap());
      Promise.all(quotePromises)
        .then((results) => {
          const groupedQuotes: IJobQuotes[] = jobFields.map((field, index) => {
            const quotesForJob = results[index]?.data || [];
            const jobTotal = quotesForJob.reduce((sum, quote) => sum + Number(quote.total), 0);
            const jobSubTotal = quotesForJob.reduce((sum, quote) => sum + Number(quote.sub_total), 0);

            return {
              jobId: field?.custom_fields?.find((item) => item.title === 'Event Id')?.field_data?.[0]?.value,
              jobName: field?.custom_fields?.find((item) => item.title === 'Event Name')?.field_data?.[0]?.value,
              total: Number(jobTotal)?.toFixed(2),
              subTotal: Number(jobSubTotal)?.toFixed(2),
              tax: jobTotal - jobSubTotal,
              jobDate: field?.custom_fields?.find((item) => item?.title === 'Event Date')?.field_data?.[0]?.value,
            };
          });

          setJobQuotes(groupedQuotes);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    };

    const fetchOrderQuotes = () => {
      setLoading(true);

      const quotePromises = orderFields?.map((field) => dispatch(getQuotes({ businessId: selectedBusiness.id, entityId: field.id })).unwrap());

      Promise.all(quotePromises)
        .then((results) => {
          const groupedQuotes: IOrderQuotes[] = orderFields.map((field, index) => {
            const quotesForOrder = results?.[index]?.data || [];
            const orderTotal = quotesForOrder?.reduce((sum, quote) => sum + Number(quote?.total), 0);
            const orderSubTotal = quotesForOrder?.reduce((sum, quote) => sum + Number(quote?.sub_total), 0);

            return {
              orderId: field?.custom_fields?.find((item) => item?.title === 'Order Id')?.field_data?.[0].value,
              total: Number(orderTotal)?.toFixed(2),
              subTotal: Number(orderSubTotal)?.toFixed(2),
              tax: orderTotal - orderSubTotal,
            };
          });

          setOrderQuotes(groupedQuotes);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    };

    fetchJobQuotes();
    fetchOrderQuotes();
  }, [dispatch, selectedBusiness?.id, jobFields, orderFields]);

  return (
    <>
      <Table className='quotes-table' pagination={false} columns={jobTableColumns} dataSource={jobQuotes} loading={loading} />
      <Table className='quotes-table' pagination={false} columns={orderTableColumns} dataSource={orderQuotes} loading={loading} />

      <Table
        className='quotes-table summary'
        dataSource={[]}
        columns={[]}
        pagination={false}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell className='fw-bold' index={0}>
                {translate('projects.quote.subtotal')}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {<PriceDisplay price={Number(quotesTotals.subTotal)} currencySign={selectedBusiness?.currency?.format} />}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} className='fw-bold'>
                {translate('projects.quote.tax')}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {
                  <PriceDisplay
                    price={Number(quotesTotals.total) - Number(quotesTotals.subTotal)}
                    currencySign={selectedBusiness?.currency?.format}
                  />
                }
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell className='fw-bold' index={0}>
                {translate('projects.quote.total')}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {<PriceDisplay price={Number(quotesTotals.total)} currencySign={selectedBusiness?.currency?.format} />}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
    </>
  );
};

export const useJobsQuotesColumns = () => {
  return [
    {
      title: 'Jobs',
      width: 300,
      render: (record: IJobQuotes) => (
        <span className='fw-bold'>
          <RightOutlined size={4} />
          {` #${record.jobId}-${record.jobName}`}
        </span>
      ),
    },
    {
      title: 'Date',
      width: 300,
      render: (record: IJobQuotes) => dayjs(record.jobDate).format('DD/MM/YYYY'),
    },
    {
      title: 'Subtotal',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.subTotal).toFixed(2)} />,
    },
    {
      title: 'Tax',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.tax).toFixed(2)} />,
    },
    {
      title: 'Total',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.total).toFixed(2)} />,
    },
  ];
};

export const useOrdersQuotesColumns = () => {
  return [
    {
      title: 'Orders',
      width: 300,
      render: (record: IOrderQuotes) => (
        <span className='fw-bold'>
          <RightOutlined size={4} />
          {` #${record.orderId}`}
        </span>
      ),
    },
    {
      title: 'Date',
      width: 300,
      render: (record: IJobQuotes) => dayjs(record.jobDate).format('DD/MM/YYYY'),
    },
    {
      title: 'Subtotal',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.subTotal).toFixed(2)} />,
    },
    {
      title: 'Tax',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.tax).toFixed(2)} />,
    },
    {
      title: 'Total',
      width: 300,
      render: (record: IJobQuotes) => <PriceDisplay price={Number(record.total).toFixed(2)} />,
    },
  ];
};
