import { useDroppable } from '@dnd-kit/core';

export const DroppableRow = ({ id, children, type = 'ROW' }: { id: string; children: JSX.Element; type?: 'ROW' | 'CARD' }) => {
  const { isOver, setNodeRef } = useDroppable({ id });

  return (
    <div ref={setNodeRef} style={type === 'ROW' ? { backgroundColor: isOver ? 'rgba(0,0,0,0.5)' : '#ffffff' } : {}}>
      {children}
    </div>
  );
};
