import { useTranslation } from 'react-i18next';
import { BgColorsOutlined, BoldOutlined, FontColorsOutlined, ItalicOutlined } from '@ant-design/icons';

import { Col, Row, Select, Typography } from 'antd';
import './TextStyleSettings.scss';

import { ColorPicker } from '@aduvi/components/ColorPicker/ColorPicker';
import { FONT_SIZES } from '@aduvi/constants';
import { IFieldInstance } from '@aduvi/types/form';
import { determinePickerColor } from '@aduvi/utils/helper';

interface IProps {
  values?: IFieldInstance['display_settings'];
  onFontChange: (value: number) => void;
  onBoldChange: (value: boolean) => void;
  onItalicChange: (value: boolean) => void;
  onTextColorChange: (value: string) => void;
  onBackgroundTextColorChange: (value: string) => void;
}

export const TextStyleSettings = ({ values, onFontChange, onBoldChange, onItalicChange, onTextColorChange, onBackgroundTextColorChange }: IProps) => {
  const { t: translate } = useTranslation();

  return (
    <Col className='text-style-settings'>
      <Row>
        <Typography.Text type='secondary'>{translate('forms.forms.style')}</Typography.Text>
      </Row>
      <Select className='mr-5 mt-5 mb-10' value={values?.fontSize} size='small' onChange={(value) => onFontChange(value)}>
        {FONT_SIZES.map((size) => (
          <Select.Option key={size} value={size}>{`${size}px`}</Select.Option>
        ))}
      </Select>

      <BoldOutlined
        className='button-style'
        style={{ backgroundColor: values?.bold ? 'lightgrey' : 'transparent' }}
        onClick={() => {
          onBoldChange(!values?.bold);
        }}
      />

      <ItalicOutlined
        className='button-style'
        style={{ backgroundColor: values?.italic ? 'lightgrey' : 'transparent' }}
        onClick={() => onItalicChange(!values?.italic)}
      />

      <ColorPicker defaultValue={'black'} onChange={(value) => onTextColorChange(determinePickerColor(value) ?? 'transparent')}>
        <FontColorsOutlined className='button-style' style={{ color: values?.textColor ?? 'black' }} />
      </ColorPicker>
      <ColorPicker defaultValue={'black'} onChange={(value) => onBackgroundTextColorChange(determinePickerColor(value) ?? 'transparent')}>
        <BgColorsOutlined
          className='button-style'
          style={{ color: values?.backgroundTextColor === 'transparent' ? 'black' : values?.backgroundTextColor }}
        />
      </ColorPicker>
    </Col>
  );
};
