import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import { ITrash } from '@aduvi/types/trash';

export const getTrashItems = async (businessId: string, page: number, size: number): Promise<IPaginatedResponse<ITrash>> => {
  return await axios.get(`/businesses/${businessId}/trash`, { params: { page, size } });
};

export const restoreTrash = async (businessId: string, trashId: string): Promise<IResponse<ITrash>> => {
  return await axios.post(`/businesses/${businessId}/trash/${trashId}/restore`);
};
