import React from 'react';
import { DraggableTableViewColumn } from 'views/contacts/edit-row-drawer/DraggableTableViewColumn';

import './EditableKanbanCard.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { EditableCell } from '@aduvi/components/PersonalizedViewComponents/EditableCell/EditableCell';
import { ICustomFieldViewStyle } from '@aduvi/types';
import { ICustomField, IEntityWithFields } from '@aduvi/types/entity';
interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  card?: IEntityWithFields;
  updateColumns: (column: ICustomFieldViewStyle) => void;
  removeColumn: (column: string) => void;

  columns: {
    column: ICustomFieldViewStyle;
    field: ICustomField | undefined;
    record: IEntityWithFields;
  }[];
}

export const EditableKanbanCard = ({ card, columns, updateColumns, removeColumn, ...restProps }: IProps) => {
  return (
    <div id={card?.id} className='editable-kanban-card' style={{ ...restProps?.style }}>
      {columns?.map((column) => (
        <div className='editable-kanban-row' key={column?.column?.id} style={{ ...column?.column?.style }}>
          <EditableCell column={column?.column} updateColumns={updateColumns} removeColumn={removeColumn} className='p-5'>
            <DraggableTableViewColumn id={column?.column?.id} key={column?.column?.id}>
              <div style={{ padding: 5 }}>{<CustomFieldValue field={column?.field} record={column?.record} />}</div>
            </DraggableTableViewColumn>
          </EditableCell>
        </div>
      ))}
    </div>
  );
};
