import { useEffect, useRef } from 'react';

export const WeatherWidget = () => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!widgetRef.current) return;

    const widgetDiv = widgetRef.current;
    widgetDiv.setAttribute('v', '1.3');
    widgetDiv.setAttribute('loc', 'auto');
    widgetDiv.setAttribute(
      'a',
      JSON.stringify({
        t: 'horizontal',
        lang: 'en',
        sl_lpl: 1,
        ids: [],
        font: 'Arial',
        sl_ics: 'one_a',
        sl_sot: 'celsius',
        cl_bkg: 'image',
        cl_font: '#FFFFFF',
        cl_cloud: '#FFFFFF',
        cl_persp: '#81D4FA',
        cl_sun: '#FFC107',
        cl_moon: '#FFC107',
        cl_thund: '#FF5722',
      }),
    );

    const script = document.createElement('script');
    script.src = 'https://app2.weatherwidget.org/js/?id=ww_0c74e9159154f';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div ref={widgetRef} id='ww_0c74e9159154f' style={{ maxWidth: '100% ' }}>
      More forecasts:{' '}
      <a href='https://oneweather.org/london/30_days/' id='ww_0c74e9159154f_u' target='_blank' rel='noopener noreferrer'>
        London weather
      </a>
    </div>
  );
};
