import { useMemo } from 'react';

import { useAppSelector } from 'store/hooks';

import { EFieldsVisibility, EPersonalizedViewOrigin } from '../types';
import { IEntityType } from '../types/entity';

export const useManagementFields = ({ entityType, origin }: { entityType?: IEntityType; origin?: EPersonalizedViewOrigin }) => {
  const { fields, formOnlyFields } = useAppSelector((state) => state.fields);

  const filteredFields = useMemo(() => {
    if (entityType?.splittable) {
      if (origin === EPersonalizedViewOrigin.BOOKINGS) {
        return fields?.filter((field) => {
          return [EFieldsVisibility.CONFIRMED, EFieldsVisibility.ALL]?.includes(field?.visibility);
        });
      }
      if (origin === EPersonalizedViewOrigin.LEADS) {
        return fields?.filter((field) => {
          return [EFieldsVisibility.UNCONFIRMED, EFieldsVisibility.ALL]?.includes(field?.visibility);
        });
      }
    }
    return fields;
  }, [entityType, fields]);

  const filteredFormFields = useMemo(() => {
    if (entityType?.splittable) {
      if (origin === EPersonalizedViewOrigin.BOOKINGS) {
        return formOnlyFields?.filter((field) => {
          return [EFieldsVisibility.CONFIRMED, EFieldsVisibility.ALL]?.includes(field?.visibility);
        });
      }
      if (origin === EPersonalizedViewOrigin.LEADS) {
        return formOnlyFields?.filter((field) => {
          return [EFieldsVisibility.UNCONFIRMED, EFieldsVisibility.ALL]?.includes(field?.visibility);
        });
      }
    }
    return formOnlyFields;
  }, [entityType, formOnlyFields]);

  return { fields: filteredFields, formOnlyFields: filteredFormFields };
};
