import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { Col, Input, Row } from 'antd';
import './LibraryManagement.scss';

import { EPictureLibrary } from '@aduvi/types';
import { IFile } from '@aduvi/types/file';

import { getPixabayPictures, getUnsplashPictures } from 'store/features/file-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const LibraryManagement = ({ onSelectionChange }: { onSelectionChange?: (files: IFile[]) => void }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { unsplashPictures, pixabayPictures, selectedLibrary } = useAppSelector((state) => state.file);

  const onGetImages = (value?: string) => {
    if (value) {
      setSearchTerm(value);
    }

    if (selectedLibrary === EPictureLibrary.UNSPLASH) {
      dispatch(getUnsplashPictures({ page: 1, per_page: 15, query: value?.trim() }));
    } else if (selectedLibrary === EPictureLibrary.PIXABAY) {
      dispatch(getPixabayPictures({ q: value?.trim(), image_type: 'photo', per_page: 15 }));
    }
  };

  const toggleFileSelection = (file: IFile) => {
    setSelectedFiles((prevState) => {
      let newState = [];
      const exists = prevState?.some((item) => item.url === file.url);

      if (exists) {
        newState = prevState.filter((item) => item.url !== file.url);
      } else {
        newState = [...prevState, file];
      }

      onSelectionChange?.(newState);
      return newState;
    });
  };

  const isFileSelected = (url: string) => {
    if (!url) return false;

    return selectedFiles.some((item) => item.url === url);
  };

  useEffect(() => {
    onGetImages();
  }, [selectedLibrary]);

  return (
    <Row wrap={false}>
      <Col span={24} className='library-management-wrapper p-10'>
        <Input.Search
          className='file-search mb-10'
          placeholder={translate('file.searchLibrary', { item: selectedLibrary })}
          enterButton
          onSearch={onGetImages}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
        />

        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter='10px'>
            {selectedLibrary === EPictureLibrary.UNSPLASH &&
              unsplashPictures.pictures.map((picture, index) => (
                <div
                  style={{ background: isFileSelected(picture.urls.full) ? '#e8f0fe' : 'transparent', padding: 10 }}
                  onClick={() => toggleFileSelection({ url: picture.urls.full } as IFile)}
                  key={index}>
                  <img src={picture.urls.small} alt={picture.description} style={{ width: '100%', display: 'block' }} />
                </div>
              ))}

            {selectedLibrary === EPictureLibrary.PIXABAY &&
              pixabayPictures.pictures.map((picture, index) => (
                <div
                  style={{ background: isFileSelected(picture.previewURL) ? '#e8f0fe' : 'transparent', padding: 10 }}
                  onClick={() => toggleFileSelection({ url: picture.previewURL } as IFile)}
                  key={index}>
                  <img src={picture.previewURL} alt={picture.tags} style={{ width: '100%', display: 'block' }} />
                </div>
              ))}
          </Masonry>
        </ResponsiveMasonry>
      </Col>
    </Row>
  );
};
