import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, DragOutlined, PictureOutlined, PlusOutlined } from '@ant-design/icons';

import { Button, Card, Checkbox, Col, Form, FormInstance, Input, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import './Variation.scss';

import { InputPrice } from '@aduvi/components/InputPrice/InputPrice';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { IVariationsType } from '@aduvi/types/products';
import { normalToKebabCase } from '@aduvi/utils/helper';

export const Variations = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const [variationSettings, setVariationSetting] = useState([
    {
      override_stock: false,
      override_price: false,
    },
  ]);

  useEffect(() => {
    const loadedSettings = form
      .getFieldValue('variations')
      .map((item: IVariationsType) => ({ override_price: item.override_price, override_stock: item.override_stock }));
    setVariationSetting(loadedSettings);
  }, []);

  const changeVariationSettings = (e: CheckboxChangeEvent, fieldName: number, field: 'override_stock' | 'override_price') => {
    form.setFieldValue([fieldName, field], e.target.checked);
    const newVariationSettings = [...variationSettings];
    newVariationSettings[fieldName][field] = e.target.checked;
    setVariationSetting(newVariationSettings);
  };

  return (
    <Col span={24} className='product-variation-wrapper'>
      <Form.List name='variations' initialValue={[]}>
        {(fields, { add, remove }) => (
          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
            {fields.map((field) => (
              <div key={field.key}>
                <Row align={'middle'} gutter={32}>
                  <Col span={12}>
                    <Form.Item
                      name={[field.name, 'name']}
                      label={translate('assets.productVariation.variationName')}
                      rules={[{ required: true, message: translate('assets.productVariation.variationNameValidation') }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Row gutter={16}>
                      <Form.Item style={{ marginBottom: 0 }} name={[field.name, 'override_stock']} initialValue={false} valuePropName='checked'>
                        <Checkbox onChange={(e) => changeVariationSettings(e, field.name, 'override_stock')}>
                          {translate('assets.productVariation.overrideStock')}
                        </Checkbox>
                      </Form.Item>
                      <Col span={12}>
                        <Form.Item style={{ marginBottom: 0 }} name={[field.name, 'override_price']} initialValue={false} valuePropName='checked'>
                          <Checkbox onChange={(e) => changeVariationSettings(e, field.name, 'override_price')}>
                            {translate('assets.productVariation.overridePrice')}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Form.Item>
                  <Form.List name={[field.name, 'options']} initialValue={[{ name: '', sku: '', image: '', stock: '', price: '' }]}>
                    {(subFields, subOpt) => (
                      <div style={{ marginBottom: 0, borderBottom: '1px solid rgba(211, 211, 211, 0.596)', paddingBottom: '20px' }}>
                        <Row className='text-center' wrap={false}>
                          <Col span={1}></Col>

                          <Col span={5}>{translate('assets.productVariation.variationOption')}</Col>
                          <Col span={5}>
                            {form.getFieldValue(['variations', field.name, 'override_price']) && translate('assets.productVariation.price')}
                          </Col>
                          <Col span={4}>
                            {form.getFieldValue(['variations', field.name, 'override_stock']) && translate('assets.productVariation.stock')}
                          </Col>
                          <Col span={4}>{translate('assets.productVariation.sku')}</Col>
                          <Col span={4}>{translate('assets.productVariation.image')}</Col>
                        </Row>

                        {subFields.map((subField) => (
                          <Card
                            size='small'
                            bordered={false}
                            key={subField.key}
                            style={{ marginBottom: 0, borderBottom: '1px solid rgba(211, 211, 211, 0.596)' }}>
                            <Space className='w-full' classNames={{ item: 'grow' }}>
                              <DragOutlined />

                              <Form.Item
                                className='form-item grow'
                                name={[subField.name, 'name']}
                                rules={[{ required: true, message: translate('assets.productVariation.optionNameValidation') }]}>
                                <Input
                                  placeholder={translate('assets.productVariation.optionName')}
                                  onChange={(e) =>
                                    form.setFieldValue(['variations', field.name, 'options', subField.name, 'sku'], normalToKebabCase(e.target.value))
                                  }
                                />
                              </Form.Item>
                              {form.getFieldValue(['variations', field.name, 'override_price']) && (
                                <Form.Item
                                  className='form-item'
                                  rules={[{ required: true, message: translate('assets.productVariation.optionPriceValidation') }]}
                                  name={[subField.name, 'price']}>
                                  <InputPrice className='w-full' placeholder={translate('assets.productVariation.optionPrice')} />
                                </Form.Item>
                              )}
                              {form.getFieldValue(['variations', field.name, 'override_stock']) && (
                                <Form.Item
                                  name={[subField.name, 'stock']}
                                  className='form-item'
                                  rules={[{ required: true, message: translate('assets.productVariation.optionStockValidation') }]}>
                                  <Input placeholder='Stock' />
                                </Form.Item>
                              )}
                              <Form.Item
                                name={[subField.name, 'sku']}
                                className='form-item grow'
                                rules={[{ required: true, message: translate('assets.productVariation.optionSKUValidation') }]}>
                                <Input placeholder='SKU' />
                              </Form.Item>
                              <Form.Item
                                name={[subField.name, 'image']}
                                className='form-item'
                                rules={[{ required: true, message: translate('assets.productVariation.optionImageValidation') }]}>
                                <UploadImage
                                  name='image'
                                  onUploadChange={(value) =>
                                    form.setFieldValue(['variations', field.name, 'options', subField.name, 'image'], value?.url)
                                  }
                                  url={form.getFieldValue(['variations', field.name, 'options', subField.name, 'image'])}
                                  text=''
                                  icon={<PictureOutlined />}
                                />
                              </Form.Item>
                              <DeleteFilled
                                onClick={() => {
                                  subOpt.remove(subField.name);
                                }}
                              />
                            </Space>
                          </Card>
                        ))}
                        <Space className='button-row'>
                          <Button
                            danger
                            type='text'
                            onClick={() => {
                              remove(field.name);
                              const newVariationSettings = [...variationSettings];
                              newVariationSettings.splice(field.name, 1);
                              setVariationSetting(newVariationSettings);
                            }}
                            icon={<DeleteFilled />}>
                            {translate('assets.productVariation.deleteVariation')}
                          </Button>
                          <Button onClick={() => subOpt.add()} icon={<PlusOutlined />}>
                            {translate('assets.productVariation.addOption')}
                          </Button>
                        </Space>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </div>
            ))}

            <Space className='button-row single'>
              <Button
                onClick={() => {
                  add();
                  setVariationSetting((prev) => [...prev, { override_price: false, override_stock: false }]);
                }}
                icon={<PlusOutlined />}
                style={{ marginLeft: 'auto' }}>
                {translate('assets.productVariation.addVariation')}
              </Button>
            </Space>
          </div>
        )}
      </Form.List>
    </Col>
  );
};
