import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, QuestionCircleOutlined, TagFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import { Avatar, Col, Dropdown, Image, MenuProps, Popconfirm, Row, Typography } from 'antd';
import './EventsTable.scss';

import { Button } from '@aduvi/components/Button/Button';
import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { EFieldDataValueType } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';
import { ITicket } from '@aduvi/types/event';

import { cloneTicket, deleteTicket, setSelectedEntity, setSelectedTicket } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ResultObject = Record<string, Partial<ITicket>[]>;

export function createObjectFromArray(events: IEntityWithFields[]): ResultObject {
  const result: ResultObject = {};

  for (const event of events) {
    result[event.id] = event?.tickets?.map((item) => {
      return {
        ...item,
        key: item.id,
        visibility: item.visibility === '1' ? 'Public' : 'Private',
      };
    });
  }

  return result;
}

export const useTicketsTableColumns = (setShowTicketsDrawer: (prop: boolean) => void) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const {
    entities: { selectedTicket },
  } = useAppSelector((state) => state.entity);

  const getTicketsDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('userManagement.permissionColumn.edit'),
      onClick: () => setShowTicketsDrawer(true),
    },

    {
      key: '1',
      icon: <CopyOutlined />,
      label: translate('assets.services.clone'),
      onClick: (e) => {
        e.domEvent.stopPropagation();
        if (selectedBusiness?.id && selectedTicket?.id && selectedTicket.business_event_id) {
          dispatch(
            cloneTicket({
              businessId: selectedBusiness.id,
              eventId: selectedTicket.business_event_id,
              ticketId: selectedTicket.id,
            }),
          );
        }
      },
    },
    {
      key: '2',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('assets.productTable.delete')}
          description={translate('assets.services.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          disabled={!useUserPermissionCheck(PERMISSIONS.EVENTS.DELETE)}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedTicket || !selectedBusiness) return;
            dispatch(
              deleteTicket({
                business_id: selectedBusiness?.id,
                ticket_id: selectedTicket?.id,
                event_id: selectedTicket?.business_event_id,
                ticket_name: selectedTicket?.name,
              }),
            );
          }}>
          {translate('assets.productTable.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.EVENTS.DELETE),
    },
  ];

  return [
    {
      title: 'Name',
      width: 300,
      render: (record: ITicket) => (
        <Row align={'middle'}>
          <div className='mr-10'>
            {record.image ? <Image className='ticket-image mr-10' width={50} preview={false} src={record.image} /> : <TagFilled className='mr-20' />}
          </div>
          <Typography.Paragraph className='mb-0'>{record.name}</Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Price',
      render: (record: ITicket) => {
        return record.is_on_sale && dayjs().isBefore(record.sale_end_date) ? (
          <>
            <Typography.Text className='old-price mb-0'>
              <PriceDisplay price={record.price} />
            </Typography.Text>

            <Typography.Text className='mb-0'>
              <PriceDisplay price={record.sale_price} />
              <Typography.Text className='ml-5 sale-tag '>{translate('assets.events.sale')}</Typography.Text>
            </Typography.Text>
          </>
        ) : (
          <Typography.Text>
            <PriceDisplay price={record.price} />
          </Typography.Text>
        );
      },
    },
    {
      title: 'Duration',
      render: (record: ITicket) => `${record.sold_tickets || 0} sold `,
    },
    {
      title: 'Duration',
      dataIndex: 'visibility',
    },
    {
      key: 'action',
      render: (record: ITicket) => {
        return (
          <Row className='w-full' justify='end'>
            <Dropdown menu={{ items: getTicketsDropdownItems }} trigger={['click']} overlayClassName='user-settings'>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedEntity({ id: record.business_event_id } as IEntityWithFields));
                  dispatch(setSelectedTicket({ id: record.id, name: record.name, business_event_id: record.business_event_id }));
                }}
                icon={<EllipsisOutlined />}
                type='text'
              />
            </Dropdown>
          </Row>
        );
      },
    },
    {
      title: 'business_event_id',
      dataIndex: 'business_event_id',
      hidden: true,
    },
  ];
};

export const useEventsTableColumns = (setShowEventsDrawer: (prop: boolean) => void) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    entities: { data, selectedEntity },
  } = useAppSelector((state) => state.entity);

  const getServiceDropdownItems: MenuProps['items'] = [
    {
      key: '0',
      icon: <EditOutlined />,
      label: translate('userManagement.permissionColumn.edit'),
      onClick: () => setShowEventsDrawer(true),
    },

    {
      key: '2',
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title={translate('assets.productTable.delete')}
          description={translate('assets.services.deleteConfirmation')}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          disabled={!useUserPermissionCheck(PERMISSIONS.EVENTS.DELETE)}
          onCancel={(e) => e?.stopPropagation()}
          onConfirm={(e) => {
            e?.stopPropagation();
            if (!selectedEntity?.id || !selectedEntity.business_id) return;
            // dispatch(
            //   deleteEvent({
            //     business_id: selectedEntity?.business_id,
            //     event_id: selectedEntity?.id,
            //   }),
            // );
          }}>
          {translate('assets.productTable.delete')}
        </Popconfirm>
      ),
      onClick: (e) => e.domEvent.stopPropagation(),
      disabled: !useUserPermissionCheck(PERMISSIONS.EVENTS.DELETE),
    },
  ];

  return [
    {
      key: 'sort',
    },
    {
      title: 'Name',
      render: (record: IEntityWithFields) => {
        // const agendaDates = record.agendas?.map((item) => new Date(item.date_time)).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        // const isSameMonth = dayjs(agendaDates?.[0]).month() === dayjs(agendaDates?.[agendaDates.length - 1]).month();

        const image = record.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_IMAGES)?.field_data?.[0]?.url;
        const name = record.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value;
        return (
          <>
            <Row style={{ alignItems: 'center', marginLeft: '-45px' }}>
              <Col>
                {image ? (
                  <Avatar shape='square' size={36} src={image} />
                ) : (
                  <Avatar shape='square' size={36}>
                    {name?.toString().substring(2, 0)}
                  </Avatar>
                )}
              </Col>
              <Col>
                <Row>
                  <span className='ml-15'>{name}</span>
                </Row>
                {/* <Row>
                  {agendaDates &&
                    agendaDates.length > 0 &&
                    (isSameMonth ? (
                      <span className='ml-15'>{`${dayjs(agendaDates[0]).format('Do')}-${dayjs(agendaDates[agendaDates.length - 1]).format(
                        'Do MMMM YYYY',
                      )}`}</span>
                    ) : (
                      <span className='ml-15'>{`${dayjs(agendaDates[0]).format('Do MMMM YYYY')}-${dayjs(agendaDates[agendaDates.length - 1]).format(
                        'Do MMMM YYYY',
                      )}`}</span>
                    ))}
                </Row> */}
              </Col>
            </Row>
          </>
        );
      },
    },

    {
      key: 'action',
      render: (record: IEntityWithFields) => {
        return (
          <Row className='w-full' justify='end' onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{ items: getServiceDropdownItems }} trigger={['click']} overlayClassName='user-settings'>
              <Button
                style={{ width: '32px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedEntity(data.find((item) => item.id === record.id)));
                }}
                icon={<EllipsisOutlined />}
                type='text'
              />
            </Dropdown>
          </Row>
        );
      },
    },
  ];
};
