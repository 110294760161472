import { createRef, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { groupAcademiesByLevel } from 'views/academy/@components/helper';

import { Carousel, Col, Row, Space, Typography } from 'antd';
import type { CarouselRef } from 'antd/lib/carousel';

import { useBusiness } from '@aduvi/hooks';
import { AcademyGroupedByLevel } from '@aduvi/types/academy';

import { useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

import { AcademyCard } from '../academy-card/AcademyCard';

interface IProps {
  payment: string;
  setSupportModalVisible: (value: React.SetStateAction<boolean>) => void;
}

export const AcademyCarousel = ({ payment, setSupportModalVisible }: IProps) => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { data: academies } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const groupedByLevel: AcademyGroupedByLevel | undefined = useMemo(() => groupAcademiesByLevel(academies?.data), [academies?.data]);

  const elementsRef = Object.keys(groupedByLevel).map(() => createRef<CarouselRef>());

  const handleWheel = (e: React.WheelEvent<HTMLDivElement> | WheelEvent, carouselRef: RefObject<CarouselRef>) => {
    const event = e as WheelEvent;

    if (carouselRef.current) {
      if (event.deltaX < 0) {
        carouselRef.current.prev();
      } else if (event.deltaX > 0) {
        carouselRef.current.next();
      }
    }
  };

  return (
    <Col>
      {groupedByLevel &&
        Object.keys(groupedByLevel).map((levelKey, index) => (
          <Col key={levelKey}>
            <Row className='level-text mb-20'>
              <Space>
                <Typography.Text>
                  {translate('academy.level')} {levelKey}
                </Typography.Text>
                <Typography.Text type='secondary'>
                  {'(' + groupedByLevel[parseInt(levelKey)]?.length} {translate('academy.episodes')}
                  {')'}
                </Typography.Text>
                <Typography.Text type='success'>{payment}</Typography.Text>
              </Space>
            </Row>

            <Row className='carousel-wrapper mb-20'>
              <div onWheel={(e) => handleWheel(e, elementsRef[index])}>
                <Carousel arrows slidesToShow={3} draggable ref={elementsRef[index]} infinite={false} className='carousel'>
                  {groupedByLevel[parseInt(levelKey)].map((item) => (
                    <Col
                      key={item.id}
                      onClick={() => {
                        setSupportModalVisible(false);
                        navigate(`/academy/${item.id}`);
                      }}>
                      <AcademyCard
                        title={item.name}
                        description={item.description}
                        image={item.image}
                        academy={item}
                        setSupportModalVisible={setSupportModalVisible}
                      />
                    </Col>
                  ))}
                </Carousel>
              </div>
            </Row>
          </Col>
        ))}
    </Col>
  );
};
