import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';

import { Col, Divider, Dropdown, Input, InputRef, Popover } from 'antd';
import { Typography } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import './SearchPopoverMenu.scss';

import { useActiveTheme, useDebounce } from '@aduvi/hooks';
import { ESearchApps, IGlobalSearchPayload } from '@aduvi/types';
import { toHumanReadable } from '@aduvi/utils/helper';

import { commonSlice, globalSearch } from 'store/features/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { SearchResult } from './SearchResult';

interface SearchMenuProps {
  open: boolean;
  customOverlayStyle?: React.CSSProperties;
  customOverlayInnerStyle?: React.CSSProperties;
  onOpenChange: (open: boolean) => void;
  customPlacement?: TooltipPlacement;
}

export const SearchPopoverMenu: React.FC<SearchMenuProps> = ({
  open,
  onOpenChange,
  customOverlayStyle,
  customOverlayInnerStyle,
  customPlacement,
}) => {
  const dispatch = useAppDispatch();
  const activeTheme = useActiveTheme();
  const searchInputRef = useRef<InputRef>(null);
  const { t: translate } = useTranslation();

  const { selectedBusiness } = useAppSelector((state) => state.business);
  const { search } = useAppSelector((state) => state.common);

  const [searchKey, setSearchKey] = useState('');
  const debouncedSearchKey = useDebounce(searchKey) || undefined;

  const items = Object.values(ESearchApps).map((category) => ({
    key: category,
    label: (
      <Typography.Text className='color-blue-6' style={{ color: activeTheme?.buttonBg }}>
        {toHumanReadable(category)}
      </Typography.Text>
    ),
    onClick: () => setSelectedItem(category),
    title: '',
  }));

  const [selectedItem, setSelectedItem] = useState(items[0].key);

  setTimeout(() => {
    searchInputRef?.current?.focus();
  }, 0);

  useEffect(() => {
    if (!debouncedSearchKey) {
      dispatch(commonSlice.actions.resetSearch());
    }

    if (debouncedSearchKey && debouncedSearchKey?.length > 2 && selectedBusiness?.id) {
      const searchParams: IGlobalSearchPayload = {
        businessId: selectedBusiness?.id,
        params: { search: debouncedSearchKey },
      };

      const allApps = Object.values(ESearchApps).filter((app) => app !== ESearchApps.EVERYTHING);

      searchParams.params.apps = selectedItem;
      if (selectedItem === ESearchApps.EVERYTHING) {
        searchParams.params.apps = allApps.join(',');
      }

      dispatch(globalSearch(searchParams));
    }
  }, [debouncedSearchKey, selectedItem, selectedBusiness?.id]);

  return (
    <Popover
      overlayStyle={{ width: 400, ...customOverlayStyle }}
      placement={customPlacement || 'rightBottom'}
      rootClassName='search-popover'
      overlayInnerStyle={customOverlayInnerStyle}
      trigger='click'
      open={open}
      onOpenChange={onOpenChange}
      arrow={false}
      content={
        <Col className='search-result-content'>
          <Typography.Text>{translate('globalSearch.globalSearch.search')} </Typography.Text>
          <Dropdown menu={{ items }} trigger={['click']}>
            <a style={{ color: activeTheme?.buttonBg }} onClick={(e) => e.preventDefault()}>
              {toHumanReadable(selectedItem)}
              <DownOutlined className='ml-5 downoutlined-icon' />
            </a>
          </Dropdown>

          <Divider className='w-full mt-15 mb-10' />

          <Input.Search
            placeholder={translate('globalSearch.globalSearch.globalSearchPlaceholder')}
            size='middle'
            loading={search.loading}
            className='w-full search-input-icon'
            bordered={false}
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            ref={searchInputRef}
          />
          <Divider className='w-full mt-10' />
          <SearchResult debouncedSearchKey={debouncedSearchKey} selectedCategory={selectedItem} />
        </Col>
      }
    />
  );
};
