import React from 'react';
import { useTranslation } from 'react-i18next';

import { Progress, Row, Typography } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { useActiveTheme } from '@aduvi/hooks';

export const GetMoreStorage = () => {
  const activeTheme = useActiveTheme();
  const { t: translate } = useTranslation();

  return (
    <>
      <Row className='w-full'>
        <Progress className='mt-10' percent={30} showInfo={false} />
        <Typography.Text type='secondary' className='fs-12'>
          15.2GB of 50GB used
        </Typography.Text>
      </Row>
      <Button className='mt-10' style={{ backgroundColor: activeTheme?.buttonBg, color: activeTheme?.buttonText }}>
        {translate('file.getMoreStorage')}
      </Button>
    </>
  );
};
