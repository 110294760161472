import type { Node, NodeTypes } from 'reactflow';

import CustomNode from './CustomNode';

export const initialNodes = [
  // { id: 'a', type: 'custom', position: { x: 0, y: 0 }, data: { label: 'wire', nodeType: ENodeType.TRIGGER } },
  // { id: 'c', type: 'custom', position: { x: 0, y: 200 }, data: { label: 'your ideas', nodeType: ENodeType.LOGIC } },
  // {
  //   id: 'd',
  //   type: 'custom',
  //   position: { x: 0, y: 400 },
  //   data: { label: 'with React Flow', nodeType: ENodeType.ACTION },
  // },
] satisfies Node[];

export const nodeTypes = {
  custom: CustomNode,
} satisfies NodeTypes;
