import originalAxios, { AxiosResponse } from 'axios';
import { WidgetType } from 'views/dashboard/data';

import axios from '@aduvi/config/axios';
import {
  IAppCategory,
  IAvatarResponse,
  ICalendarEventResponse,
  IChat,
  ICountry,
  IEditUserDashboardLayoutPayload,
  IGetMicrosoftCalendarEventsPayload,
  IGlobalSearchPayload,
  IGlobalSearchResponse,
  IInstallApp,
  IInstallAppPayload,
  ILayoutResponse,
  IMessage,
  IResponse,
  IStatistics,
  IUploadImageResponse,
} from '@aduvi/types';
import { ICurrency } from '@aduvi/types';

export const getCountries = (): Promise<IResponse<ICountry[]>> => {
  return axios.get(`/countries`);
};

export const getCurrencies = (): Promise<IResponse<ICurrency[]>> => {
  return axios.get(`/currencies`);
};

export const uploadImage = async (payload: FormData): Promise<IResponse<IUploadImageResponse>> => {
  return axios.post('/common/upload', payload, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const getLayoutByDomain = async (): Promise<IResponse<ILayoutResponse>> => {
  return await axios.get('/common/layouts');
};

export const globalSearch = async (payload: IGlobalSearchPayload): Promise<IResponse<IGlobalSearchResponse>> => {
  return await axios.get(`/businesses/${payload.businessId}/global-search`, { params: payload.params });
};

export const getAppsCategories = async (): Promise<IResponse<IAppCategory[]>> => {
  return await axios.get(`/common/app-categories`);
};

export const installApp = async (payload: IInstallAppPayload): Promise<IResponse<IInstallApp>> => {
  return axios.post(`/businesses/${payload.business_id}/additional-app`, payload);
};

export const sentMessage = async (messages: IMessage[], model: string): Promise<IResponse<IChat>> => {
  const chatAPI = process.env.REACT_APP_CHAT_API_KEY;
  const header = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${chatAPI}`,
    'User-Agent': 'Aduvi',
  };
  return await originalAxios.post('https://api.openai.com/v1/chat/completions', { messages, model }, { headers: header });
};

export const getUserAvatar = async (email: string): Promise<IAvatarResponse> => {
  const payload = {
    username: 'aduvi',
    password: process.env.REACT_APP_AVATAR_API_KEY_PASS,
    email,
  };

  const response: AxiosResponse<IAvatarResponse> = await originalAxios.post('https://avatarapi.com/v2/api.aspx', payload, {
    headers: {
      'Content-Type': 'text/plain',
    },
  });

  return response.data;
};

export const getMicrosoftCalendarEvents = (payload: IGetMicrosoftCalendarEventsPayload): Promise<{ data: ICalendarEventResponse }> => {
  return originalAxios.get('https://graph.microsoft.com/v1.0/me/calendar/events', {
    headers: {
      Authorization: `Bearer ${payload.accessToken}`,
    },
  });
};

export const getUserDashboardLayout = (payload: { userId: string; businessId: string }): Promise<IResponse<WidgetType[]>> => {
  return axios.get(`users/${payload.userId}/businesses/${payload.businessId}/dashboard-layout`);
};

export const editUserDashboardLayout = (payload: IEditUserDashboardLayoutPayload): Promise<IResponse<WidgetType[]>> => {
  return axios.put(`users/${payload.userId}/businesses/${payload.businessId}/dashboard-layout`, payload.body);
};

export const getDashboardStatistics = (payload: { userId: string; businessId: string; period: string }): Promise<IStatistics> => {
  return axios.get(`/users/${payload.userId}/businesses/${payload.businessId}/dashboard-satistic`, { params: { period: payload.period } });
};
