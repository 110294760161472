import axios from '@aduvi/config/axios';
import {
  IBusinessUser,
  IDeleteBusinessUserPayload,
  IExportBusinessUsers,
  IGetBusinessUserByIdPayload,
  IGetBusinessUsersPayload,
  IImportBusinessUsers,
  IInviteBusinessUserPayload,
  IPaginatedResponse,
  IResponse,
  ITheme,
  IUpdateBusinessUserPayload,
  IUser,
} from '@aduvi/types';
import { IAudit, IGetUserActivitiesPayload } from '@aduvi/types/audit';

export const deleteUser = async (payload: IDeleteBusinessUserPayload): Promise<void> =>
  axios.delete(`/businesses/${payload.business_id}/users/${payload.user_id}?title=${payload.user_name}`);

export const editUser = async (body: IUser): Promise<IUser> => axios.put('user/edit-user', body);

export const getBusinessUsers = async (payload: IGetBusinessUsersPayload): Promise<IPaginatedResponse<IBusinessUser>> => {
  return await axios.get(`/businesses/${payload.business_id}/users`, { params: payload.params });
};

export const getBusinessUserById = async (payload: IGetBusinessUserByIdPayload): Promise<IResponse<IBusinessUser>> => {
  return await axios.get(`/businesses/${payload.businessId}/users/${payload.userId}`);
};

export const inviteBusinessUser = async (payload: IInviteBusinessUserPayload): Promise<IResponse<IBusinessUser>> => {
  return await axios.post(`/businesses/${payload.id}/users`, payload.body);
};

export const updateBusinessUser = async (payload: IUpdateBusinessUserPayload): Promise<IResponse<IBusinessUser>> => {
  return await axios.put(`/businesses/${payload.businessId}/users/${payload.userId}`, payload.body);
};

export const exportBusinessUsers = async (payload: IExportBusinessUsers): Promise<string> => {
  return await axios.get(`/businesses/${payload.id}/export`);
};

export const downloadSampleUsersCSV = async (): Promise<string> => {
  return await axios.get(`/common/import/csv-example`);
};

export const importBusinessUsers = async (payload: IImportBusinessUsers): Promise<IResponse<IBusinessUser[]>> => {
  return await axios.post(`/businesses/${payload.businessId}/import`, payload.body, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const getBusinessUserActivities = async (payload: IGetUserActivitiesPayload): Promise<IPaginatedResponse<IAudit>> => {
  return await axios.get(`/user/${payload.user_id}/activities?order=${payload.params.order}`, { params: payload.params });
};

export const updateTheme = async (user_id: string, business_id: string, partner_theme_id?: string): Promise<IResponse<ITheme>> => {
  return await axios.put(`/users/${user_id}/businesses/${business_id}/theme`, { partner_theme_id });
};
