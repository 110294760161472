import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  ICreateSingleEntityBlackoutDatesPayload,
  IGetEntitiesBlackoutDatesPayload,
  IGetSingleEntityBlackoutDatesPayload,
  IGetSingleEntityBlackoutDatesResponse,
} from '@aduvi/types';

import * as AvailabilityService from 'store/services/availability.service';

interface IAvailabilityState {
  singleUnitBlackoutDates?: { start: string; end: string }[];
  allUnitsBlackoutDates?: IGetSingleEntityBlackoutDatesResponse[];
  loading: boolean;
  updating: boolean;
  deleting: boolean;
}

const initialState: IAvailabilityState = {
  singleUnitBlackoutDates: undefined,
  allUnitsBlackoutDates: undefined,
  loading: false,
  updating: false,
  deleting: false,
};

export const getSingleEntityBlackoutDates = createAsyncThunk(
  'availability/get-blackout-dates',
  async (params: IGetSingleEntityBlackoutDatesPayload, { rejectWithValue }) => {
    try {
      return await AvailabilityService.getSingleEntityBlackoutDates(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getEntitiesBlackoutDates = createAsyncThunk(
  'availability/get-entities-blackout-dates',
  async (params: IGetEntitiesBlackoutDatesPayload, { rejectWithValue }) => {
    try {
      return await AvailabilityService.getEntitiesBlackoutDates(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createSingleEntityBlackoutDates = createAsyncThunk(
  'availability/create-blackout-dates',
  async (params: ICreateSingleEntityBlackoutDatesPayload, { rejectWithValue }) => {
    try {
      return await AvailabilityService.createSingleEntityBlackoutDates(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setSingleUnitBlackoutDates: (state, { payload }) => {
      state.singleUnitBlackoutDates = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSingleEntityBlackoutDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleEntityBlackoutDates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleUnitBlackoutDates = payload.data.map((unit) => ({
          start: dayjs(unit.start_date).format('YYYY-MM-DD'),
          end: dayjs(unit.end_date).format('YYYY-MM-DD'),
        }));
      })
      .addCase(getSingleEntityBlackoutDates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getEntitiesBlackoutDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntitiesBlackoutDates.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.allUnitsBlackoutDates = payload.data;
      })
      .addCase(getEntitiesBlackoutDates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createSingleEntityBlackoutDates.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSingleEntityBlackoutDates.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSingleEntityBlackoutDates.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const availabilityReducer = availabilitySlice.reducer;
export const { setSingleUnitBlackoutDates } = availabilitySlice.actions;
