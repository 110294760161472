import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Divider, Form, FormInstance, Input, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import {
  EEntityFieldListType,
  EFieldDataValueType,
  EFieldsVisibility,
  EPersonalizedViewOrigin,
  EReferenceEntityType,
  IEntityField,
  ManagementFields,
} from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

import { Button } from '../Button/Button';
import { Paragraph } from '../Paragraph';

import { ShiftDate } from './default-forms/ShiftDate';
import { Message } from './message/Message';
import { useDefaultFields } from './ManagementFieldsDrawer.hooks';

interface IProps {
  data?: IEntityField;
  title: string;
  showDrawer: boolean;
  loadingAction: boolean;
  showFieldVisibility: boolean;
  permissionToEdit?: string;
  origin?: EPersonalizedViewOrigin;
  onCreate: (form: FormInstance) => void;
  onUpdate: (form: FormInstance) => void;
  onCancel: () => void;
}

export const UpsertDrawer = (props: IProps) => {
  const { t: translate } = useTranslation();
  const [form] = useForm();

  Form.useWatch('value_type', form);

  const { showDrawer, loadingAction, title, data, permissionToEdit, onCreate, onUpdate, onCancel } = props;
  const defaultFields = useDefaultFields(form);
  const { entityTypes } = useAppSelector((state) => state.entity.entityTypes);

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const onSubmit = () => {
    !data?.id ? onCreate(form) : onUpdate(form);
  };

  const getValueType = (data: IEntityField) => {
    if (data?.value_type?.includes('references')) {
      return data?.reference_entity_type_id;
    }

    if (data?.value_type === EFieldDataValueType.FIELD_DATA_LISTS) {
      return data?.list_type || EEntityFieldListType.LIST;
    }

    return data?.value_type;
  };

  const onValueTypeChange = (
    _: string,
    option:
      | { key: string; reference_entity_type_id: string; reference_entity_type: string; value_type: string; list_type: EEntityFieldListType }
      | unknown,
  ) => {
    form.setFieldValue('value_type', (option as { value_type: string })?.value_type);
    form.setFieldValue('reference_entity_type_id', (option as { reference_entity_type_id?: string })?.reference_entity_type_id);
    form.setFieldValue('reference_entity_type', (option as { reference_entity_type?: string })?.reference_entity_type);

    form.setFieldValue('hidden', (option as { is_hidden?: string })?.is_hidden);

    if ((option as { list_type: EEntityFieldListType })?.list_type) {
      form.setFieldValue('list_type', (option as { list_type: EEntityFieldListType })?.list_type);
    }

    if ((option as { key?: string })?.key?.includes('references')) {
      form.setFieldValue('value_type', 'field_data_references');
    }
  };

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({
      ...data,
      value_type_work_only: getValueType(data),
      typed_options: data.list_values.length
        ? [
            {
              name: data.value_type,
              options: data.list_values,
            },
          ]
        : undefined,
    });
  }, [data]);

  return (
    <Drawer
      width={680}
      title={title}
      open={showDrawer}
      onClose={onClose}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={loadingAction} onClick={onClose}>
            {translate('buttons.cancel')}
          </Button>

          <Button
            disabledButton
            type='primary'
            requiredPermission={permissionToEdit}
            loading={loadingAction}
            disabled={loadingAction}
            onClick={onSubmit}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Form form={form} className='mt-20' layout='vertical' requiredMark={false}>
        {data?.core && <Message field={data?.value_type || ''} />}

        <Col span={24}>
          <Form.Item
            name='title'
            label={translate('components.customFieldsDrawer.name')}
            rules={[{ required: true, message: translate('components.customFieldsDrawer.nameValidation') }]}>
            <Input placeholder={translate('components.customFieldsDrawer.namePlaceholder')} disabled={data?.core} />
          </Form.Item>
          <Form.Item name={'reference_entity_type_id'} hidden />
          <Form.Item name={'reference_entity_type'} hidden />
          <Form.Item name={'value_type'} hidden />
          <Form.Item name={'list_type'} hidden />
          <Form.Item name={'hidden'} hidden />

          <Form.Item
            name='value_type_work_only'
            label={translate('components.customFieldsDrawer.typePlaceholder')}
            rules={[{ required: true, message: translate('components.customFieldsDrawer.typeValidation') }]}>
            <Select
              showSearch
              disabled={data?.core}
              onChange={onValueTypeChange}
              optionFilterProp='label'
              options={[
                {
                  label: translate('components.customFieldsDrawer.baseTypes'),
                  options: ManagementFields?.map(
                    (value: { name: string; format?: string; type?: EFieldDataValueType; list_type?: EEntityFieldListType }, index) => {
                      return {
                        key: 'common-fields-' + index,
                        label: translate(`components.customFieldsDrawer.type.${value?.name.toUpperCase()}`),
                        value: value?.name,
                        value_type: value?.type || value?.name,
                        list_type: value?.list_type || undefined,
                      };
                    },
                  ),
                },
                {
                  label: translate('components.customFieldsDrawer.referenceTypes'),
                  options: entityTypes?.map((value, index) => ({
                    key: 'references-' + index,
                    label: translate(`components.customFieldsDrawer.type.${value?.name?.toUpperCase()}`),
                    value: value?.id,
                    value_type: value?.id,
                    reference_entity_type_id: value?.id,
                    reference_entity_type: value?.name,
                    is_hidden: !value?.can_be_referenced,
                  })),
                },
              ]}
              placeholder={translate('components.customFieldsDrawer.typePlaceholder')}
            />
          </Form.Item>

          {props.origin === EPersonalizedViewOrigin.FORMS && (
            <Form.Item initialValue={false} name='form_only' valuePropName='checked'>
              <Checkbox>{translate('components.customFieldsDrawer.managementFieldCheckbox')}</Checkbox>
            </Form.Item>
          )}

          {props?.showFieldVisibility ? (
            <Form.Item
              name='visibility'
              label={translate('components.customFieldsDrawer.fieldVisibility')}
              initialValue={EFieldsVisibility.ALL}
              rules={[{ required: true, message: translate('components.customFieldsDrawer.visibilityValidation') }]}>
              <Select
                disabled={data?.core}
                options={Object.values(EFieldsVisibility)?.map((value) => {
                  return {
                    label: translate(`components.customFieldsDrawer.visibility.${value}`),
                    value,
                  };
                })}
                placeholder={translate('components.customFieldsDrawer.allJobs')}
              />
            </Form.Item>
          ) : (
            <></>
          )}

          <Divider />

          {form.getFieldValue('value_type') ? (
            <Paragraph className='paragraph'>
              {translate(`components.customFieldsDrawer.type.${form.getFieldValue('value_type').toUpperCase()}`)}{' '}
              {translate('components.customFieldsDrawer.settingsTitle')}
            </Paragraph>
          ) : null}
          {form.getFieldValue('value_type') ? (
            form.getFieldValue('reference_entity_type') === EReferenceEntityType.SHIFT ? (
              <ShiftDate />
            ) : (
              defaultFields[form.getFieldValue('value_type')]
            )
          ) : null}
        </Col>
      </Form>
    </Drawer>
  );
};
