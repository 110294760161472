import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Form, Input, Select } from 'antd';

import { Paragraph } from '@aduvi/components/Paragraph';
import { useBusiness } from '@aduvi/hooks';

import { useAppSelector } from 'store/hooks';

import { SelectTemplateCategory } from '../SelectTemplateCategory';

export const Configure = () => {
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { entityTypes } = useAppSelector((state) => state.entity);

  const entityTypesFiltered = [entityTypes.data.JOB, entityTypes.data.ORDER, entityTypes.data.PROJECT];

  return (
    <span className='w-full mt-20'>
      <Paragraph style={{ fontSize: '12px', fontWeight: 400, color: '#9B9B9B' }}>Template settings</Paragraph>
      <Form.Item name={'name'} label='Template name'>
        <Input />
      </Form.Item>
      <Form.Item name={'brand_id'} label='Brand'>
        <Select options={selectedBusiness?.brandings?.map((brand) => ({ value: brand.id, label: brand.name }))} />
      </Form.Item>
      <Form.Item name={'target_entity_type_id'} label='Target entity'>
        <Select
          options={entityTypesFiltered.map((item) => ({ label: translate(`components.customFieldsDrawer.type.${item?.name}`), value: item?.id }))}
        />
      </Form.Item>
      <Form.Item name={'description'} label='Description'>
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name={'builder_category_ids'} label='Category'>
        <SelectTemplateCategory mode='multiple' />
      </Form.Item>
      <Divider />
      <Paragraph style={{ fontSize: '12px', fontWeight: 400, color: '#9B9B9B' }}>Display settings</Paragraph>
      <Form.Item name={'page_title'} label='Page title'>
        <Input />
      </Form.Item>
      <Divider />
    </span>
  );
};
