import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import Tiptap from '@aduvi/Tiptap/Tiptap';
import { IEntityField } from '@aduvi/types';
import { EAutomationNodeAction } from '@aduvi/types/automation';
import { transformDefaultMentionToSpan, transformFieldMentionsToSpan } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

export const ChatGPT = ({ form, entityFields }: { form: FormInstance; entityFields?: IEntityField[] }) => {
  const { t: translate } = useTranslation();
  Form.useWatch(['connection_id', 'prompt_message'], form);

  const {
    connections: { loading, data },
    mentions,
  } = useAppSelector((state) => state.automation);

  const options = useMemo(() => data.filter((item) => item.type === EAutomationNodeAction.CHAT_GPT_ACTION), [data]);

  return (
    <>
      <Form.Item name={'connection_id'} label={translate('automations.account')}>
        <Select
          placeholder={translate('automations.pleaseSelect')}
          loading={loading}
          options={options?.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Form.Item>
      <Form.Item name={['data_source', 'fields']} label={translate('automations.sourceData')}>
        <Select
          mode='multiple'
          placeholder={translate('automations.pleaseSelect')}
          options={entityFields?.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
        />
      </Form.Item>
      <Form.Item name={'prompt_message'} label={translate('automations.prompt')}>
        <Tiptap
          content={transformFieldMentionsToSpan(transformDefaultMentionToSpan(form.getFieldValue('prompt_message')), mentions)}
          onUpdate={(value) => form.setFieldValue('prompt_message', value.getHTML())}
        />
      </Form.Item>
    </>
  );
};
