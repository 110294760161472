import axios from '@aduvi/config/axios';
import {
  ICreateManagementFieldPayload,
  IManagementField,
  IPaginatedResponse,
  IReorderManagementFieldsPayload,
  IResponse,
  IUpdateManagementFieldPayload,
} from '@aduvi/types';
import {
  IAgenda,
  ICreateAgendaPayload,
  ICreateEventPayload,
  ICreateExhibitorPayload,
  IDeleteAgendaPayload,
  IDeleteExhibitorPayload,
  IEvent,
  IExhibitor,
  IGetEventsPayload,
  IUpdateEventPayload,
} from '@aduvi/types/event';

export const getEvents = async (payload: IGetEventsPayload): Promise<IPaginatedResponse<IEvent>> => {
  return await axios.get(`/businesses/${payload.business_id}/events?order=${payload.params.order}`, { params: payload.params });
};

export const createEvent = async (payload: ICreateEventPayload): Promise<IResponse<IEvent>> => {
  return await axios.post(`businesses/${payload.business_id}/events`, payload.body);
};

export const updateEvent = async (payload: IUpdateEventPayload): Promise<IResponse<IEvent>> => {
  return await axios.put(`/businesses/${payload.business_id}/events/${payload.event_id}`, payload.body);
};

export const getEventById = async (businessId: string, eventId: string): Promise<IResponse<IEvent>> => {
  return await axios.get(`/businesses/${businessId}/events/${eventId}`);
};

export const createAgenda = async (payload: ICreateAgendaPayload): Promise<IResponse<IAgenda>> => {
  return await axios.post(`businesses/${payload.business_id}/events/${payload.business_event_id}/agendas`, payload.body);
};

export const updateAgenda = async (payload: ICreateAgendaPayload): Promise<IResponse<IAgenda>> => {
  return await axios.put(`businesses/${payload.business_id}/events/${payload.business_event_id}/agendas/${payload.body.id}`, payload.body);
};

export const deleteAgenda = async (payload: IDeleteAgendaPayload): Promise<void> => {
  return await axios.delete(`businesses/${payload.business_id}/events/${payload.business_event_id}/agendas/${payload.agenda_id}`);
};

export const getAllAgendas = async (payload: ICreateAgendaPayload): Promise<IResponse<IAgenda>> => {
  return await axios.get(`businesses/${payload.business_id}/events/${payload.business_event_id}/agendas`);
};

export const createExhibitor = async (payload: ICreateExhibitorPayload): Promise<IResponse<IExhibitor>> => {
  return await axios.post(`businesses/${payload.business_id}/exhibitors`, payload.body);
};

export const updateExhibitor = async (payload: ICreateExhibitorPayload): Promise<IResponse<IExhibitor>> => {
  return await axios.put(`businesses/${payload.business_id}/exhibitors/${payload.body.id}`, payload.body);
};

export const deleteExhibitor = async (payload: IDeleteExhibitorPayload): Promise<void> => {
  return await axios.delete(`businesses/${payload.business_id}/exhibitors/${payload.exhibitor_id}`);
};

export const deleteEvent = async (businessId: string, eventId: string): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/events/${eventId}`);
};

export const getEventFields = async (businessId: string): Promise<IResponse<IManagementField[]>> => {
  return await axios.get(`/businesses/${businessId}/event-fields?size=50`);
};

export const createEventFields = async (payload: ICreateManagementFieldPayload): Promise<IResponse<IManagementField>> => {
  return await axios.post(`/businesses/${payload.business_id}/event-fields`, payload.body);
};

export const updateEventField = async (payload: IUpdateManagementFieldPayload): Promise<IResponse<IManagementField>> => {
  return await axios.put(`/businesses/${payload.business_id}/event-fields/${payload.id}`, payload.body);
};

export const reorderEventFields = async (payload: IReorderManagementFieldsPayload): Promise<IPaginatedResponse<IManagementField>> => {
  return await axios.put(`/businesses/${payload.business_id}/reorder-event-fields`, payload.body);
};

export const getExhibitorFields = async (businessId: string): Promise<IResponse<IManagementField[]>> => {
  return await axios.get(`/businesses/${businessId}/exhibitor-fields?size=50`);
};

export const createExhibitorFields = async (payload: ICreateManagementFieldPayload): Promise<IResponse<IManagementField>> => {
  return await axios.post(`/businesses/${payload.business_id}/exhibitor-fields`, payload.body);
};

export const updateExhibitorField = async (payload: IUpdateManagementFieldPayload): Promise<IResponse<IManagementField>> => {
  return await axios.put(`/businesses/${payload.business_id}/exhibitor-fields/${payload.id}`, payload.body);
};

export const reorderExhibitorFields = async (payload: IReorderManagementFieldsPayload): Promise<IPaginatedResponse<IManagementField>> => {
  return await axios.put(`/businesses/${payload.business_id}/reorder-exhibitor-fields`, payload.body);
};
