import { useEffect, useState } from 'react';

import { useBusiness, useUser } from '@aduvi/hooks';

import { getDashboardStatistics } from 'store/features/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export enum EPeriodType {
  Today = 'today',
  Last7Days = 'last7 days',
  Last4Weeks = 'last 4 weeks',
  Last3Months = 'last 3 months',
  Last12Months = 'last 12 months',
  MonthToDate = 'month to date',
  QuarterToDate = 'quarter to date',
  YearToDate = 'year to date',
  AllTime = 'all time',
}

export const PERIOD_OPTIONS = [
  { value: 'today', label: 'dashboard.widgets.statistics.today' },
  { value: 'last 7 days', label: 'dashboard.widgets.statistics.last7Days' },
  { value: 'last 4 weeks', label: 'dashboard.widgets.statistics.last4Weeks' },
  { value: 'last 3 months', label: 'dashboard.widgets.statistics.last3Months' },
  { value: 'last 12 months', label: 'dashboard.widgets.statistics.last12Months' },
  { value: 'month to date', label: 'dashboard.widgets.statistics.monthToDate' },
  { value: 'quarter to date', label: 'dashboard.widgets.statistics.quarterToDate' },
  { value: 'year to date', label: 'dashboard.widgets.statistics.yearToDate' },
  { value: 'all time', label: 'dashboard.widgets.statistics.allTime' },
];

export const useStatistics = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const selectedBusiness = useBusiness();

  const [period, setPeriod] = useState<EPeriodType>(EPeriodType.Today);

  const { statistics } = useAppSelector((state) => state.common);

  useEffect(() => {
    if (!selectedBusiness?.id || !user?.id || !period) return;
    dispatch(
      getDashboardStatistics({
        businessId: selectedBusiness.id,
        period,
        userId: user.id,
      }),
    )
      .unwrap()
      .catch(() => {});
  }, [period, selectedBusiness?.id, user?.id]);

  return { stats: statistics.data, isLoading: statistics.loading, period, setPeriod };
};
