import { useEffect, useMemo, useState } from 'react';
import { closestCorners, DndContext, DragEndEvent, DragOverEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { Spin } from 'antd';

import { HorizontalScrollView } from '@aduvi/components/HorizontalScrollView/HorizontalScrollView';
import { useBusiness } from '@aduvi/hooks';
import { EFieldsVisibility, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { getEntities } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { getStatusForEntities } from '../helper';

import Column, { ColumnType } from './components/Column';

interface IProps {
  viewId?: string;
  columnsFieldId?: string;
  origin: EPersonalizedViewOrigin;
  columnSummaryId?: string;
  data: IEntityWithFields[];
  entityType?: IEntityType;
  onKanbanHandleDrag: (id: string, entity: IEntityWithFields, overColumnId: string, columnsFieldId?: string) => void;
}

export const Kanban = ({ viewId, data, origin, columnsFieldId, columnSummaryId, entityType, onKanbanHandleDrag }: IProps) => {
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const selectedBusiness = useBusiness();
  const dispatch = useAppDispatch();

  const { fields } = useAppSelector((state) => state?.fields);

  const findColumn = (unique: string | null) => {
    if (!unique) return null;

    if (columns?.some((column) => column?.id === unique)) {
      return columns?.find((column) => column?.id === unique) ?? null;
    }
    const id = String(unique);
    const itemWithColumnId = columns?.flatMap((column) => {
      const columnId = column?.id;
      return column?.cards?.map((item) => ({ itemId: item?.id, columnId }));
    });
    const columnId = itemWithColumnId?.find((item) => item?.itemId === id)?.columnId;
    return columns?.find((column) => column?.id === columnId) ?? null;
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over, delta } = event;
    const activeId = String(active?.id);
    const overId = over ? String(over?.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);

    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return null;
    }

    setColumns((prevState) => {
      const activeItems = activeColumn?.cards;
      const overItems = overColumn?.cards;
      const activeIndex = activeItems?.findIndex((item) => item?.id === activeId);
      const overIndex = overItems?.findIndex((item) => item?.id === overId);
      const newIndex = () => {
        const putOnBelowLastItem = overIndex === overItems?.length - 1 && delta?.y > 0;
        const modifier = putOnBelowLastItem ? 1 : 0;
        return overIndex >= 0 ? overIndex + modifier : overItems?.length + 1;
      };
      return prevState?.map((column) => {
        if (column?.id === activeColumn?.id) {
          column.cards = activeItems?.filter((item) => item?.id !== activeId);
          return column;
        } else if (column?.id === overColumn?.id) {
          column.cards = [...overItems?.slice(0, newIndex()), activeItems?.[activeIndex], ...overItems?.slice(newIndex(), overItems?.length)];
          return column;
        } else {
          return column;
        }
      });
    });
  };

  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }));

  const columnSummaryField = useMemo(() => {
    return fields?.find((item) => item?.id === columnSummaryId);
  }, [columnSummaryId]);

  const getCardItems = (columnId: string) => {
    const dataOfThisView = data?.filter((item) => {
      const dataView = item?.custom_fields?.find((customField) => customField?.id === columnsFieldId);

      if (dataView) {
        if (dataView?.field_data?.some((i) => i?.id === columnId)) {
          return dataView;
        }
      }
    });

    const columnSummaryValue = dataOfThisView?.reduce((accumulator, currentValue) => {
      const value = currentValue?.custom_fields
        ? [...(currentValue?.custom_fields || [])]?.find((item) => item?.id === columnSummaryField?.id)?.field_data?.[0]?.value || 0
        : 0;

      return accumulator + +value || 0;
    }, 0);

    return { data: dataOfThisView || [], columnSummaryValue };
  };

  const selectedOption = useMemo(() => {
    return fields?.find((item) => item?.id === columnsFieldId);
  }, [fields, columnsFieldId]);

  const formattedData: ColumnType[] = useMemo(() => {
    return (
      selectedOption?.list_values
        ?.filter((item) => {
          if (entityType?.splittable) {
            if (
              selectedOption?.list_values?.some(
                (item) => [EFieldsVisibility.CONFIRMED, EFieldsVisibility.UNCONFIRMED]?.includes(item?.value_type as EFieldsVisibility),
              )
            ) {
              return [EPersonalizedViewOrigin.BOOKINGS]?.includes(origin)
                ? item?.value_type === EFieldsVisibility.CONFIRMED
                : item?.value_type === EFieldsVisibility.UNCONFIRMED;
            }
          }

          return !item?.is_archived;
        })
        ?.map((item) => {
          const { data, columnSummaryValue } = getCardItems(item?.id);

          return {
            id: item?.id,
            title: item?.value,
            color: item?.color,
            cards: data,
            columnSummaryValue,
          };
        }) || []
    );
  }, [data, selectedOption]);

  const recalculateColumnSummary = () => {
    setColumns((currentColumns) => {
      return currentColumns?.map((column) => {
        const { columnSummaryValue } = getCardItems(column?.id);
        return { ...column, columnSummaryValue };
      });
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const activeId = String(active?.id);
    const overId = over ? String(over?.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);

    if (!activeColumn || !overColumn || activeColumn !== overColumn) {
      return null;
    }

    const activeIndex = activeColumn?.cards?.findIndex((i) => i?.id === activeId);
    const overIndex = overColumn?.cards?.findIndex((i) => i?.id === overId);

    setColumns((prevState) => {
      return prevState?.map((column) => {
        if (column?.id === activeColumn?.id) {
          column.cards = arrayMove(overColumn?.cards, activeIndex, overIndex);
        }
        return column;
      });
    });

    const kanbanData = data as unknown as { [key: string]: { entities: IEntityWithFields[] } };

    let entity = null;
    Object.values(kanbanData)?.map((data) => {
      const foundEntity = data.entities?.find((item) => item?.id === activeId);
      if (foundEntity) {
        entity = foundEntity;
      }
    });

    if (!entity) return;

    if (!selectedBusiness?.id) return;

    onKanbanHandleDrag(activeId, entity, overColumn?.id, columnsFieldId);
  };

  useEffect(() => {
    setColumns(formattedData);
  }, [viewId, formattedData?.length, data?.length]);

  useEffect(() => {
    recalculateColumnSummary();
  }, [data]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params: {
            status: getStatusForEntities(origin),
          },
        }),
      );
    }
  }, [selectedBusiness?.id, entityType?.id]);

  return (
    <Spin spinning={false}>
      <DndContext key={viewId} sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
        <HorizontalScrollView>
          {columns?.map(({ id, title, color, cards, columnSummaryValue }, index) => (
            <Column
              managementFields={fields}
              key={index}
              id={id}
              title={title}
              color={color}
              cards={cards}
              columnSummaryField={columnSummaryField}
              columnSummaryValue={columnSummaryValue}
            />
          ))}
        </HorizontalScrollView>
      </DndContext>
    </Spin>
  );
};
