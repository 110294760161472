import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import 'antd/dist/reset.css';

import './translation';

import store from './store/store';
import App from './App';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_LOGIN_CLIENT_ID!,
    redirectUri: process.env.REACT_APP_BASE_URL,
    authority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  },
  cache: {
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID!}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </GoogleOAuthProvider>
  </Provider>,
);
