import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import { IQuote } from '@aduvi/types';

import { cloneQuote, deleteQuote, getQuotes } from 'store/features/quote-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useQuotes = ({ entityId }: { entityId?: string }) => {
  const dispatch = useAppDispatch();

  const { selectedQuote } = useAppSelector((state) => state.quotes);

  const selectedBusiness = useBusiness();

  const [editingQuoteId, setEditingQuoteId] = useState<string>('');

  const dropdownItems = useDropdownItems(selectedBusiness?.id, selectedQuote?.id, selectedQuote, setEditingQuoteId);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityId) return;
    dispatch(getQuotes({ businessId: selectedBusiness?.id, entityId }));
  }, [selectedBusiness?.id, entityId]);

  return { dispatch, dropdownItems, undefined, editingQuoteId, setEditingQuoteId };
};

const useDropdownItems = (
  businessId: string | undefined,
  quoteId: string | undefined,
  selectedQuote: IQuote | undefined,
  setEditingQuoteId: (editingQuoteId: string) => void,
) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const hasPermissionToEditQuote = useUserPermissionCheck(PERMISSIONS.QUOTES.EDIT);
  const hasPermissionToDeleteQuote = useUserPermissionCheck(PERMISSIONS.QUOTES.DELETE);

  return [
    {
      key: '0',
      label: translate('buttons.rename'),
      disabled: !hasPermissionToEditQuote,
      onClick: () => {
        if (!quoteId) return;
        setEditingQuoteId(quoteId);
      },
    },
    {
      key: '1',
      label: translate('buttons.clone'),
      onClick: () => {
        if (!businessId || !quoteId) return;
        dispatch(cloneQuote({ business_id: businessId, quote_id: quoteId }));
      },
    },
    {
      key: '2',
      label: translate('buttons.remove'),
      disabled: !hasPermissionToDeleteQuote,
      onClick: () => {
        if (!businessId || !quoteId) return;
        dispatch(deleteQuote({ business_id: businessId, quote_id: quoteId, quote_name: selectedQuote?.name }));
      },
    },
  ];
};
