import { EPictureLibrary, IPixabayPicture, IUnsplashPicture } from './common';

export enum EFileUploadTabs {
  FILES = 'files',
  LIBRARIES = 'libraries',
  UPLOAD = 'upload',
}

export enum EFilePermissionTypes {
  READ_ONLY = 'READ_ONLY',
  READ_WRITE = 'READ_WRITE',
}

export interface IFolder {
  id: string;
  name: string;
  business_id: string;
  parent_id?: string;
  created_by: string;
  created_at?: string;
  updated_at?: string;
  subfolders?: IFolder[];
}

export interface IUpsertFolderPayload {
  name: string;
  parent_id?: string;
}

export interface IFile {
  id: string;
  name: string;
  description: string;
  file_type: string;
  is_public: boolean;
  path: string;
  url: string;
  size: number;
  business_id: string;
  folder_id: string;
  uploaded_by: string;
  created_at?: string;
  updated_at?: string;
}

export interface IUpdateFilePayload {
  name: string;
  description: string;
  is_public: boolean;
  folder_id: string;
}

export interface IShareFileAndFolderPayload {
  user_ids: string[];
  permission_type: EFilePermissionTypes;
}

export interface IShareFile {
  business_id: string;
  file_id: string;
  user_id: string;
  permission_type: EFilePermissionTypes;
  id: string;
  created_at?: string;
  updated_at?: string;
}

export interface IShareFolder {
  business_id: string;
  folder_id: string;
  user_id: string;
  permission_type: EFilePermissionTypes;
  id: string;
  created_at?: string;
  updated_at?: string;
}

export interface IFileState {
  folders: {
    data?: IFolder[];
    loading: boolean;
    creating: boolean;
    updating: boolean;
    deleting: boolean;
    sharing: boolean;
  };
  files: {
    data?: IFile[];
    loading: boolean;
    creating: boolean;
    updating: boolean;
    deleting: boolean;
    downloading: boolean;
    cloning: boolean;
    sharing: boolean;
  };

  sharedFolders: {
    data?: IFolder[];
    loading: boolean;
    creating: boolean;
    updating: boolean;
    deleting: boolean;
    active: boolean;
  };

  selectedLibrary?: EPictureLibrary;
  unsplashPictures: {
    pictures: IUnsplashPicture[];
    loading: boolean;
  };
  pixabayPictures: {
    pictures: IPixabayPicture[];
    loading: boolean;
  };

  selectedFolder?: IFolder;
  selectedFile?: IFile;
  allFiles?: IFile[];
}

export interface ITreeData {
  title: string;
  key: string;
  icon: JSX.Element;
  children: ITreeData[];
}
