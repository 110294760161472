import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EPictureLibrary, IGetUnsplashPicturesParams, IPixabaySearchParams } from '@aduvi/types';
import { IFile, IFileState, IFolder, IShareFileAndFolderPayload, IUpdateFilePayload, IUpsertFolderPayload } from '@aduvi/types/file';

import * as FileService from 'store/services/file.service';

const initialState: IFileState = {
  folders: {
    data: undefined,
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
    sharing: false,
  },
  files: {
    data: undefined,
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
    downloading: false,
    cloning: false,
    sharing: false,
  },

  sharedFolders: {
    data: undefined,
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
    active: false,
  },

  selectedLibrary: EPictureLibrary.UNSPLASH,
  unsplashPictures: {
    pictures: [],
    loading: false,
  },
  pixabayPictures: {
    pictures: [],
    loading: false,
  },

  selectedFolder: undefined,
  selectedFile: undefined,
  allFiles: undefined,
};

export const createFolder = createAsyncThunk(
  'file/create-folder',
  async ({ businessId, payload }: { businessId: string; payload: IUpsertFolderPayload }, { rejectWithValue }) => {
    try {
      return await FileService.createFolder(businessId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFolders = createAsyncThunk('file/get-folders', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FileService.getFolders(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateFolder = createAsyncThunk(
  'file/update-folder',
  async ({ businessId, folderId, payload }: { businessId: string; folderId: string; payload: IUpsertFolderPayload }, { rejectWithValue }) => {
    try {
      return await FileService.updateFolder(businessId, folderId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteFolder = createAsyncThunk(
  'file/delete-folder',
  async ({ businessId, folderId, folderName }: { businessId: string; folderId: string; folderName: string }, { rejectWithValue }) => {
    try {
      await FileService.deleteFolder(businessId, folderId, folderName);
      return folderId;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createFile = createAsyncThunk(
  'file/create-file',
  async ({ businessId, payload }: { businessId: string; payload: FormData }, { rejectWithValue }) => {
    try {
      return await FileService.createFile(businessId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFilesByFolderId = createAsyncThunk(
  'file/get-files-by-folder-id',
  async ({ businessId, folderId }: { businessId: string; folderId: string }, { rejectWithValue }) => {
    try {
      return await FileService.getFilesByFolderId(businessId, folderId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const downloadFile = createAsyncThunk(
  'file/download-file',
  async ({ businessId, fileId }: { businessId: string; fileId: string }, { rejectWithValue }) => {
    try {
      return await FileService.downloadFile(businessId, fileId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateFile = createAsyncThunk(
  'file/update-file',
  async ({ businessId, fileId, payload }: { businessId: string; fileId: string; payload: IUpdateFilePayload }, { rejectWithValue }) => {
    try {
      return await FileService.updateFile(businessId, fileId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const cloneFile = createAsyncThunk(
  'file/clone-file',
  async ({ businessId, fileId }: { businessId: string; fileId: string }, { rejectWithValue }) => {
    try {
      return await FileService.cloneFile(businessId, fileId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const shareFile = createAsyncThunk(
  'file/share-file',
  async ({ businessId, fileId, payload }: { businessId: string; fileId: string; payload: IShareFileAndFolderPayload }, { rejectWithValue }) => {
    try {
      return await FileService.shareFile(businessId, fileId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const shareFolder = createAsyncThunk(
  'file/share-folder',
  async ({ businessId, folderId, payload }: { businessId: string; folderId: string; payload: IShareFileAndFolderPayload }, { rejectWithValue }) => {
    try {
      return await FileService.shareFolder(businessId, folderId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSharedFolders = createAsyncThunk('file/get-shared-folders', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FileService.getSharedFolders(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSharedFiles = createAsyncThunk('file/get-shared-files', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FileService.getSharedFiles(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteFile = createAsyncThunk(
  'file/delete-file',
  async ({ businessId, fileId, fileName }: { businessId: string; fileId: string; fileName: string }, { rejectWithValue }) => {
    try {
      await FileService.deleteFile(businessId, fileId, fileName);
      return fileId;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUnsplashPictures = createAsyncThunk('unsplash/getPictures', async (params: IGetUnsplashPicturesParams, { rejectWithValue }) => {
  try {
    const response = await FileService.getUnsplashPictures(params);
    return Array.isArray(response?.data) ? response?.data : response?.data?.results;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPixabayPictures = createAsyncThunk('pixabay/getPictures', async (params: IPixabaySearchParams, { rejectWithValue }) => {
  try {
    return await FileService.getPixabayPictures(params);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAllFiles = createAsyncThunk('file/get-all-files', async (businessId: string, { rejectWithValue }) => {
  try {
    return await FileService.getAllFiles(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    setSelectedFolder(state, action: PayloadAction<IFolder | undefined>) {
      state.selectedFolder = action.payload;
    },
    setSelectedFile(state, action: PayloadAction<IFile | undefined>) {
      state.selectedFile = action.payload;
    },
    setSelectedLibrary: (state, { payload }: PayloadAction<EPictureLibrary | undefined>) => {
      state.selectedLibrary = payload;
    },
    setAllFiles: (state, { payload }: PayloadAction<IFile[]>) => {
      state.files.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFolders.pending, (state) => {
        state.sharedFolders.active = false;
        state.folders.loading = true;
      })
      .addCase(getFolders.fulfilled, (state, { payload }) => {
        state.folders.loading = false;
        state.folders.data = payload.data;
        state.selectedFolder = payload.data?.[0] ?? undefined;
      })
      .addCase(getFolders.rejected, (state) => {
        state.folders.loading = false;
      })
      .addCase(createFolder.pending, (state) => {
        state.folders.creating = true;
      })
      .addCase(createFolder.fulfilled, (state, { payload }) => {
        state.folders.creating = false;
        if (!state.folders.data) {
          state.folders.data = [];
        }
        const addFolderToParent = (folders: IFolder[], parentId: string, newFolder: IFolder) => {
          for (const folder of folders) {
            if (folder.id === parentId) {
              if (!folder.subfolders) {
                folder.subfolders = [];
              }
              folder.subfolders.push(newFolder);
              return true;
            }
            if (folder.subfolders && addFolderToParent(folder.subfolders, parentId, newFolder)) {
              return true;
            }
          }
          return false;
        };
        if (payload.data.parent_id) {
          if (!addFolderToParent(state.folders.data, payload.data.parent_id, payload.data)) {
            state.folders.data.push(payload.data);
          }
        } else {
          state.folders.data.push(payload.data);
        }
      })
      .addCase(createFolder.rejected, (state) => {
        state.folders.creating = false;
      })

      .addCase(updateFolder.pending, (state) => {
        state.folders.updating = true;
      })
      .addCase(updateFolder.fulfilled, (state, { payload }) => {
        state.folders.updating = false;
        const recursiveUpdate = (folders: IFolder[], updatedFolder: IFolder) => {
          return folders.map((folder) => {
            if (folder.id === updatedFolder.id) {
              return { ...folder, ...updatedFolder, subfolders: folder.subfolders };
            } else if (folder.subfolders) {
              folder.subfolders = recursiveUpdate(folder.subfolders, updatedFolder);
            }
            return folder;
          });
        };
        if (state.folders.data) {
          state.folders.data = recursiveUpdate(state.folders.data, payload.data);
        }
      })
      .addCase(updateFolder.rejected, (state) => {
        state.folders.updating = false;
      })
      .addCase(deleteFolder.pending, (state) => {
        state.folders.deleting = true;
      })
      .addCase(deleteFolder.fulfilled, (state, { payload }) => {
        state.folders.deleting = false;
        const removeFolderAndSubfolders = (folders: IFolder[], folderId: string) => {
          return folders.filter((folder: IFolder) => {
            if (folder.subfolders) {
              folder.subfolders = removeFolderAndSubfolders(folder.subfolders, folderId);
            }
            return folder.id !== folderId;
          });
        };
        if (state.folders.data) {
          state.folders.data = removeFolderAndSubfolders(state.folders.data, payload);
        }
      })
      .addCase(deleteFolder.rejected, (state) => {
        state.folders.deleting = false;
      })

      .addCase(getFilesByFolderId.pending, (state) => {
        state.sharedFolders.active = false;
        state.files.data = [];
        state.files.loading = true;
      })
      .addCase(getFilesByFolderId.fulfilled, (state, { payload }) => {
        state.files.loading = false;
        state.files.data = payload.data;
      })
      .addCase(getFilesByFolderId.rejected, (state) => {
        state.files.loading = false;
      })
      .addCase(createFile.pending, (state) => {
        state.files.creating = true;
      })
      .addCase(createFile.fulfilled, (state, { payload }) => {
        state.files.creating = false;
        state.files.data = [...(state.files.data || []), payload.data];
        state.allFiles = [...(state.allFiles || []), payload.data];
      })
      .addCase(createFile.rejected, (state) => {
        state.files.creating = false;
      })
      .addCase(cloneFile.pending, (state) => {
        state.files.cloning = true;
      })
      .addCase(cloneFile.fulfilled, (state, { payload }) => {
        state.files.cloning = false;
        state.files.data = [...(state.files.data || []), payload.data];
        state.allFiles = [...(state.allFiles || []), payload.data];
      })
      .addCase(cloneFile.rejected, (state) => {
        state.files.cloning = false;
      })
      .addCase(downloadFile.pending, (state) => {
        state.files.downloading = true;
      })
      .addCase(downloadFile.fulfilled, (state) => {
        state.files.downloading = false;
      })
      .addCase(downloadFile.rejected, (state) => {
        state.files.downloading = false;
      })
      .addCase(updateFile.pending, (state) => {
        state.files.updating = true;
      })
      .addCase(updateFile.fulfilled, (state, { payload }) => {
        state.files.updating = false;
        const index = state.files.data?.findIndex((file) => file.id === payload.data.id);
        if (index !== undefined && state.files.data) {
          state.files.data[index] = payload.data;
        }
        const indexOfAllFiles = state.allFiles?.findIndex((file) => file.id === payload.data.id);
        if (indexOfAllFiles && indexOfAllFiles !== -1 && state.allFiles) {
          state.allFiles[indexOfAllFiles] = payload.data;
        }
      })
      .addCase(updateFile.rejected, (state) => {
        state.files.updating = false;
      })
      .addCase(deleteFile.pending, (state) => {
        state.files.deleting = true;
      })
      .addCase(deleteFile.fulfilled, (state, { payload }) => {
        state.files.deleting = false;
        state.files.data = state.files.data?.filter((file) => file.id !== payload);
        state.allFiles = state.allFiles?.filter((file) => file.id !== payload);
      })
      .addCase(deleteFile.rejected, (state) => {
        state.files.deleting = false;
      })
      .addCase(shareFile.pending, (state) => {
        state.files.sharing = true;
      })
      .addCase(shareFile.fulfilled, (state) => {
        state.files.sharing = false;
      })
      .addCase(shareFile.rejected, (state) => {
        state.files.sharing = false;
      })
      .addCase(shareFolder.pending, (state) => {
        state.folders.sharing = true;
      })
      .addCase(shareFolder.fulfilled, (state) => {
        state.folders.sharing = false;
      })
      .addCase(shareFolder.rejected, (state) => {
        state.folders.sharing = false;
      })
      .addCase(getSharedFolders.pending, (state) => {
        state.files.data = [];
        state.sharedFolders.loading = true;
        state.selectedFolder = undefined;
        state.sharedFolders.active = true;
      })
      .addCase(getSharedFolders.fulfilled, (state, { payload }) => {
        state.sharedFolders.loading = false;
        state.sharedFolders.data = payload.data;
      })
      .addCase(getSharedFolders.rejected, (state) => {
        state.sharedFolders.loading = false;
      })
      .addCase(getSharedFiles.pending, (state) => {
        state.sharedFolders.active = true;
        state.files.data = [];
        state.files.loading = true;
      })
      .addCase(getSharedFiles.fulfilled, (state, { payload }) => {
        state.files.loading = false;
        state.files.data = payload.data;
      })
      .addCase(getSharedFiles.rejected, (state) => {
        state.files.loading = false;
      })
      .addCase(getUnsplashPictures.pending, (state) => {
        state.unsplashPictures.loading = true;
      })
      .addCase(getUnsplashPictures.fulfilled, (state, action) => {
        state.unsplashPictures.loading = false;
        state.unsplashPictures.pictures = action.payload;
      })
      .addCase(getUnsplashPictures.rejected, (state) => {
        state.unsplashPictures.loading = false;
      })
      .addCase(getPixabayPictures.pending, (state) => {
        state.pixabayPictures.loading = true;
      })
      .addCase(getPixabayPictures.fulfilled, (state, action) => {
        state.pixabayPictures.loading = false;
        state.pixabayPictures.pictures = action.payload.hits;
      })
      .addCase(getPixabayPictures.rejected, (state) => {
        state.pixabayPictures.loading = false;
      })
      .addCase(getAllFiles.pending, (state) => {
        state.files.loading = true;
      })
      .addCase(getAllFiles.fulfilled, (state, { payload }) => {
        state.files.loading = false;
        state.allFiles = payload.data;
      })
      .addCase(getAllFiles.rejected, (state) => {
        state.files.loading = false;
      });
  },
});

export const { setSelectedFolder, setSelectedFile, setSelectedLibrary } = fileSlice.actions;
export const fileReducer = fileSlice.reducer;
