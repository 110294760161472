import React from 'react';
import ColorPicker from 'react-best-gradient-color-picker';
import Color from 'color';

import { ColorPicker as AntColorPicker, Row } from 'antd';

import { PresetColorPicker } from '@aduvi/constants/style';

interface IProps {
  onChange?: (value: string) => void;
  size?: string;
  value?: Color | string;
}

export const GradientColorPicker = ({ value, onChange }: IProps) => {
  return (
    <Row>
      <AntColorPicker
        value={value?.toString()}
        panelRender={() => (
          <ColorPicker
            width={230}
            height={160}
            value={value?.toString()}
            onChange={(v) => onChange?.(v)}
            presets={PresetColorPicker}
            hideInputs
            hideGradientControls
          />
        )}
      />
    </Row>
  );
};
