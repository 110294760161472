import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Form, FormInstance, Row, Select } from 'antd';

import { useBusiness } from '@aduvi/hooks';

import i18n from '../../../../translation/i18n';

const RELATIVE_DATE_OPTIONS = [
  { label: i18n.t('components.customFieldsDrawer.relativeDate.now'), value: 'now' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.plus1Year'), value: '+1y' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.plus2Years'), value: '+2y' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.plus5Years'), value: '+5y' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.minus1Year'), value: '-1y' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.minus2Years'), value: '-2y' },
  { label: i18n.t('components.customFieldsDrawer.relativeDate.minus5Years'), value: '-5y' },
];

export const DateSettings = ({ form }: { form: FormInstance }) => {
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item
        label={translate('components.customFieldsDrawer.dateFormat')}
        name={['settings', 'format']}
        initialValue={selectedBusiness?.date_format || 'DD/MM/YYYY'}
        rules={[{ required: true, message: translate('components.customFieldsDrawer.dateFormatValidation') }]}>
        <Select>
          <Select.Option value='MM/DD/YYYY HH:mm:ss'>MM/DD/YYYY HH:mm:ss</Select.Option>
          <Select.Option value='DD/MM/YYYY HH:mm:ss'>DD/MM/YYYY HH:mm:ss</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={translate('components.customFieldsDrawer.minDate')} name={['settings', 'min_date']}>
        <Select options={RELATIVE_DATE_OPTIONS} placeholder={translate('components.customFieldsDrawer.selectMinDate')} />
      </Form.Item>

      <Form.Item label={translate('components.customFieldsDrawer.maxDate')} name={['settings', 'max_date']}>
        <Select options={RELATIVE_DATE_OPTIONS} placeholder={translate('components.customFieldsDrawer.selectMaxDate')} />
      </Form.Item>

      <Form.Item label={translate('components.customFieldsDrawer.dateGranularity')}>
        <Form.Item name={['settings', 'date_granularity']} noStyle initialValue={['year']}>
          <Checkbox.Group
            onChange={(checkedValues) => {
              const units = ['year', 'month', 'day', 'hour', 'minutes', 'seconds'];
              let newCheckedValues = [...checkedValues];

              if (!newCheckedValues.includes('year')) {
                newCheckedValues.push('year');
              }

              units.forEach((unit, index) => {
                if (newCheckedValues.includes(unit)) {
                  const largerUnits = units.slice(0, index);
                  largerUnits.forEach((largerUnit) => {
                    if (!newCheckedValues.includes(largerUnit)) {
                      newCheckedValues.push(largerUnit);
                    }
                  });
                } else {
                  const smallerUnits = units.slice(index + 1);
                  newCheckedValues = newCheckedValues.filter((value) => !smallerUnits.includes(value));
                }
              });
              newCheckedValues = Array.from(new Set(newCheckedValues));
              form.setFieldValue(['settings', 'date_granularity'], newCheckedValues);
            }}>
            <Row gutter={[16, 16]}>
              <Col>
                <Checkbox value='year' disabled>
                  {translate('components.customFieldsDrawer.granularity.year')}
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value='month'>{translate('components.customFieldsDrawer.granularity.month')}</Checkbox>
              </Col>
              <Col>
                <Checkbox value='day'>{translate('components.customFieldsDrawer.granularity.day')}</Checkbox>
              </Col>
              <Col>
                <Checkbox value='hour'>{translate('components.customFieldsDrawer.granularity.hour')}</Checkbox>
              </Col>
              <Col>
                <Checkbox value='minutes'>{translate('components.customFieldsDrawer.granularity.minutes')}</Checkbox>
              </Col>
              <Col>
                <Checkbox value='seconds'>{translate('components.customFieldsDrawer.granularity.seconds')}</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>

      <Form.Item label={translate('components.customFieldsDrawer.dateRange')} name={['settings', 'capture_end_date']} valuePropName='checked'>
        <Checkbox>{translate('components.customFieldsDrawer.captureEndDate')}</Checkbox>
      </Form.Item>
    </>
  );
};
