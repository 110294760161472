import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDocumentationRelation, IDocumentationsState, IGetDocumentationsPayload } from '@aduvi/types/documentation';

import * as DocumentationServices from 'store/services/documentation.service';

const initialState: IDocumentationsState = {
  documentations: undefined,
  selectedDocumentation: undefined,
  loading: false,
};

export const getDocumentations = createAsyncThunk('documentations/get-all', async (params: IGetDocumentationsPayload, { rejectWithValue }) => {
  try {
    return await DocumentationServices.getDocumentations(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const documentationSlice = createSlice({
  name: 'documentations',
  initialState,
  reducers: {
    setSelectedDocumentation: (state, action: PayloadAction<IDocumentationRelation | undefined>) => {
      state.selectedDocumentation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentations.pending, (state) => {
        state.loading = true;
        state.documentations = undefined;
      })
      .addCase(getDocumentations.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.documentations = payload;
      })
      .addCase(getDocumentations.rejected, (state) => {
        state.loading = false;
        state.documentations = undefined;
      });
  },
});

export const documentationReducer = documentationSlice.reducer;
export const { setSelectedDocumentation } = documentationSlice.actions;
