import { EOrderType } from './common';
import { ICustomFieldViewStyle } from './custom-fields';
import { IEntityType, IEntityWithFields } from './entity';

export enum EPersonalizedView {
  PIPELINE = 'PIPELINE',
  TABLE = 'TABLE',
  KANBAN = 'KANBAN',
  TIMELINE = 'TIMELINE',
  CALENDAR = 'CALENDAR',
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  FORMS = 'FORMS',
  MAP = 'MAP',
}

export enum ETableRowOptions {
  MINIMAL = 'MINIMAL',
  REGULAR = 'REGULAR',
  EXPAND = 'EXPAND',
}

export enum EPersonalizedViewOrigin {
  LEADS = 'LEADS',
  FORMS = 'FORMS',
  EVENTS = 'EVENTS',
  ORDERS = 'ORDERS',
  CONTACTS = 'CONTACTS',
  VENUES = 'VENUES',
  BOOKINGS = 'BOOKINGS',
  EXHIBITORS = 'EXHIBITORS',
  TASKS = 'TASKS',
  PROJECTS = 'PROJECTS',
}

export interface IPersonalizedViewsDrawer {
  open: boolean;
  onClose: () => void;
  isNew: boolean;
  newViewType?: EPersonalizedView;
  entityType?: IEntityType;
  origin: EPersonalizedViewOrigin;
  style?: ICustomFieldViewStyle[];
}

export interface IPersonalizedView {
  id: string;
  name: string;
  view_display: EPersonalizedView;
  default_sort_key: string;
  default_sort_order: EOrderType;
  default_date_values: string;
  display_label_field_id: string;
  start_date: string;
  end_date: string;
  style: string;
  origin: EPersonalizedViewOrigin;
  business_id: string;
  created_at: string;
  updated_at: string;
  weight: number;
  deleted_at: string;
  column_summary: string;
  default_status: string;
  status_field: string;
  address_field: string;
  row_height: string;
}

export interface IPersonalizedViewsState {
  personalizedViews: IPersonalizedView[];
  loading: boolean;
  creating: boolean;
  editing: boolean;
  deleting: boolean;
  loadingView: boolean;
  selectedViewId?: string;
  selectedView?: IPersonalizedView;
  hoveredPipelineFilterId?: string;
  showDrawer: boolean;
}

export interface ICreatePersonalizedViewPayload {
  business_id: string;
  body: {
    name: string;
    view_display: EPersonalizedView;
    default_sort_key: string;
    default_sort_order: EOrderType;
    default_date_values: string;
    style: string;
    origin: EPersonalizedViewOrigin;
    status_field?: string;
    column_summary?: string;
    default_status?: string;
    address_field?: string;
    start_date: string;
    end_date: string;
  };
}

export interface IUpdatePersonalizedViewPayload extends ICreatePersonalizedViewPayload {
  personalizedViewId: string;
}

export interface IReorderPersonalizedViewPayload {
  business_id: string;
  origin: EPersonalizedViewOrigin;
  personalized_views: { id: string; weight: number }[];
}

export interface IMapViewProps {
  data: IEntityWithFields[];
  addressFieldId?: string;
}

export interface IGeocodeResult {
  lat: number;
  lng: number;
}

export interface IMarker extends IGeocodeResult {
  address: string;
  entity: IEntityWithFields;
  photoUrl?: string;
}
