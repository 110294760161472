import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, Col, Form, FormInstance, Image, Input, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './Login.scss';

import { Button } from '@aduvi/components/Button/Button';
import { initialClientPortalSettings } from '@aduvi/constants/client-portal-constants';
import { ELoadingState, ILoginResponse } from '@aduvi/types';

import { getClientData, login, sendMagicLink } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const LoginComponent = ({ form, readonly = true }: { form: FormInstance; readonly?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { loginState, sendMagicLinkState } = useAppSelector((state) => state.clientPortalAuth);

  const { businessId, clientPortalId } = useParams();

  const [loginForm] = useForm();
  Form.useWatch(['login_settings', 'login_form_type'], form);

  const showPasswordField = useMemo(() => {
    const loginFormType = form.getFieldValue(['login_settings', 'login_form_type']) || initialClientPortalSettings?.login_settings?.login_form_type;
    if (!readonly) {
      return loginFormType === 'email_password';
    }

    return selectedClientPortal?.login_settings?.login_form_type === 'email_password';
  }, [selectedClientPortal, form.getFieldValue(['login_settings', 'login_form_type'])]);

  const getAccountInformation = (data: ILoginResponse) => {
    if (data?.access_token && businessId) {
      return dispatch(getClientData({ businessId }))
        .unwrap()
        .then(({ data }) => {
          data && navigate(`portal/profile`);
        })
        .catch(() => {});
    }
  };

  const onLogin = ({ email, password }: { email: string; password: string; otp?: string }) => {
    if (!businessId) return;
    dispatch(
      login({
        businessId,
        body: {
          auth_type: undefined,
          email,
          password,
        },
      }),
    )
      .unwrap()
      .then((response) => {
        getAccountInformation?.(response?.data);
      })
      .catch(() => {});
  };

  const onSendMagicLink = ({ email }: { email: string }) => {
    if (!businessId || !clientPortalId) return;
    dispatch(
      sendMagicLink({
        businessId,
        clientPortalId,
        body: {
          email,
        },
      }),
    )
      .unwrap()
      .then(() => loginForm.resetFields())
      .catch(() => {});
  };

  const handleSendMagicLink = () => {
    if (!readonly) return;

    loginForm
      .validateFields()
      .then((values) => {
        onSendMagicLink({ email: values.email });
      })
      .catch(() => {});
  };

  const handleLogin = () => {
    if (!readonly) return;

    loginForm
      .validateFields()
      .then((values) => {
        onLogin({ email: values.email, password: values.password });
      })
      .catch(() => {});
  };

  const description =
    form.getFieldValue(['login_settings', 'description']) ||
    selectedClientPortal?.login_settings?.description ||
    initialClientPortalSettings?.login_settings?.description;

  return (
    <Card className='login-card '>
      {selectedClientPortal?.brand?.logo ? (
        <Row className='w-full mb-10' justify={'center'}>
          <Image preview={false} width={120} src={selectedClientPortal?.brand?.logo} style={{ borderRadius: '50%' }} />
        </Row>
      ) : (
        <Typography.Title className='title text-center' level={2}>
          {translate('clientPortal.portal.logo')}
        </Typography.Title>
      )}

      {readonly ? (
        <p className='text-center'>{description}</p>
      ) : (
        <Form.Item name={['login_settings', 'description']} className='description-input'>
          <Input placeholder={translate('clientPortal.portal.description')} />
        </Form.Item>
      )}

      <Form form={loginForm} name='loginForm'>
        <Form.Item
          className={'mb-10'}
          labelCol={{ span: 24 }}
          label={translate('clientPortal.login.email')}
          name='email'
          rules={[{ required: true, message: translate('auth.login.emailValidation'), type: 'email' }]}>
          <Input placeholder={translate('auth.login.emailPlaceholder')} />
        </Form.Item>

        {showPasswordField && (
          <Row>
            <Col span={24} className={'mb-10'}>
              <Form.Item
                className={'mb-10'}
                labelCol={{ span: 24 }}
                label={translate('clientPortal.login.password')}
                name='password'
                rules={[{ required: true, message: translate('auth.login.passwordValidation') }]}>
                <Input.Password type='password' placeholder={translate('auth.login.passwordPlaceholder')} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <div className='text-center mt-20'>
          <Button
            loading={loginState === ELoadingState.LOADING || sendMagicLinkState === ELoadingState.LOADING}
            disabled={loginState === ELoadingState.LOADING || sendMagicLinkState === ELoadingState.LOADING}
            htmlType='submit'
            className='mb-20'
            type='primary'
            onClick={() => {
              readonly && selectedClientPortal?.login_settings.login_form_type === 'email_password' ? handleLogin() : handleSendMagicLink();
            }}>
            {translate('clientPortal.login.login')}
          </Button>

          <a className='d-block' onClick={() => readonly && navigate(`/cp/${businessId}/${clientPortalId}/auth/forgot-password`)}>
            {translate('clientPortal.login.forgotPassword')}
          </a>
        </div>
      </Form>
    </Card>
  );
};
