import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';

import { Button, Col, Form, Input, Row } from 'antd';
import 'react-phone-input-2/lib/style.css';

import { ELoadingState, IRegisterPayload } from '@aduvi/types';

import { register } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { registerState } = useAppSelector((state) => state.auth);

  const [userCountry, setUserCountry] = useState('');

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setUserCountry(data.country_code.toLowerCase());
      } catch (error) {
        return;
      }
    };

    fetchCountryCode();
  }, []);

  const onSubmit = (values: IRegisterPayload) => {
    dispatch(register(values))
      .unwrap()
      .then(() => {
        navigate('auth/login');
      })
      .catch(() => {});
  };

  return (
    <Form name='register' className='mt-20' onFinish={onSubmit}>
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name='first_name' rules={[{ required: true, message: translate('auth.register.firstNameValidation') }]}>
            <Input placeholder={translate('auth.register.firstNamePlaceholder')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item name='last_name' rules={[{ required: true, message: translate('auth.register.lastNameValidation') }]}>
            <Input placeholder={translate('auth.register.lastNamePlaceholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name='email' rules={[{ required: true, message: translate('auth.register.emailValidation') }]}>
        <Input placeholder={translate('auth.register.emailPlaceholder')} />
      </Form.Item>

      <Form.Item name='password' rules={[{ required: true, message: translate('auth.register.passwordValidation') }]}>
        <Input.Password placeholder={translate('auth.register.passwordPlaceholder')} />
      </Form.Item>
      <Form.Item
        name='password_confirmation'
        rules={[
          { required: true, message: translate('auth.register.passwordPlaceholder') },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(translate('auth.register.confirmPasswordDontMatch')));
            },
          }),
        ]}>
        <Input.Password placeholder={translate('auth.register.confirmPasswordPlaceholder')} />
      </Form.Item>

      <Form.Item name='phone_number'>
        <PhoneInput inputStyle={{ width: '100%', borderRadius: 2 }} country={userCountry || 'gb'} onChange={() => {}} countryCodeEditable={false} />
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='w-full'
          loading={registerState === ELoadingState.LOADING}
          disabled={registerState === ELoadingState.LOADING}>
          {translate('auth.register.register')}
        </Button>
      </Form.Item>
      {/* <Form.Item>
        <Row justify='center'>
          {translate('auth.register.quickRegister')}
          <CustomLogin />
        </Row>
      </Form.Item> */}
    </Form>
  );
};

export default Register;
