import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';

import { Avatar, Col, Divider, Popover, Row, Select } from 'antd';
import './UserProfileMenu.scss';

import { Button } from '@aduvi/components/Button/Button';
import { Paragraph } from '@aduvi/components/Paragraph';
import { useActiveTheme } from '@aduvi/hooks';
import { hasUserPermission, isAppIncludedInPlan } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

import { useUserProfileMenu } from './UserProfileMenu.hooks';

interface IProps {
  open: boolean;
  className?: string;
  customOverlayStyle?: React.CSSProperties;
  customOverlayInnerStyle?: React.CSSProperties;

  onOpenChange: (open: boolean) => void;
}

export const UserProfileMenu: React.FC<IProps> = ({ onOpenChange, open, className, customOverlayStyle, customOverlayInnerStyle }) => {
  const navigate = useNavigate();
  const activeTheme = useActiveTheme();

  const {
    allowedProfileExploreMenu,
    allowedProfileAccountMenu,
    selectedPlan,
    planApps,
    isBusinessOwner,
    userBusinessPermissions,
    onBusinessChange,
    onLogout,
  } = useUserProfileMenu();
  const { t: translate } = useTranslation();

  const { businesses, selectedBusiness } = useAppSelector((state) => state.business);
  const partner = useAppSelector((state) => state.common.layout);

  const onNavigate = (path: string) => {
    navigate(`/${path}`);
    onOpenChange(false);
  };

  const updateManifest = useCallback(
    (name: string, icon: string) => {
      const manifest = {
        short_name: name,
        name,
        icons: [
          {
            src: icon,
            sizes: '64x64 32x32 24x24 16x16',
            type: 'image/x-icon',
          },
          {
            src: icon,
            type: 'image/png',
            sizes: '192x192',
          },
          {
            src: icon,
            type: 'image/png',
            sizes: '512x512',
          },
        ],
        start_url: '.',
        display: 'standalone',
        theme_color: '#000000',
        background_color: '#ffffff',
      };

      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);

      let manifestLink = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;
      if (manifestLink) {
        manifestLink.href = manifestURL;
      } else {
        manifestLink = document.createElement('link');
        manifestLink.rel = 'manifest';
        manifestLink.href = manifestURL;
        document.head.appendChild(manifestLink);
      }
    },
    [partner?.id],
  );

  useEffect(() => {
    if (partner?.id) {
      updateManifest(partner?.trading_name, partner?.icon);
    }
  }, [partner?.id]);

  const content = (
    <Col className={`${className} sidebar-popover-container`}>
      <Row className='m-5' align='middle'>
        <Select
          className='borderless-select w-full'
          optionLabelProp='label'
          value={selectedBusiness?.id}
          onChange={(value) => {
            const selectedBusiness = businesses.find((business) => business.id === value);
            if (selectedBusiness) {
              onBusinessChange(selectedBusiness);
            }
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider className='m-0' />
              <Row className='mt-20'>
                <Button className='w-full' icon={<PlusOutlined />} onClick={() => navigate('/onboarding')}>
                  {translate('userProfileMenu.addBusiness')}
                </Button>
              </Row>
            </>
          )}
          options={businesses.map((business) => ({
            label: (
              <Row align='middle'>
                <Avatar src={activeTheme?.logo || undefined} size={34} className='mr-10' />
                <Paragraph className='mb-0'>{business.name}</Paragraph>
              </Row>
            ),
            value: business.id,
          }))}
        />
      </Row>

      <Divider className='my-10' />
      <Row style={{ height: '200px' }} />
      <Divider className='my-10' />

      <Row>
        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Paragraph className='popover-subtitle' style={{ color: activeTheme?.menuText }}>
            {translate('userProfileMenu.account')}
          </Paragraph>
          {allowedProfileAccountMenu.map((item) => (
            <Button
              key={item.path}
              type='text'
              icon={item.icon}
              onClick={() => onNavigate(item.path)}
              className='text-left'
              requiredPermission={item.permission}>
              {translate(item.label)}
            </Button>
          ))}
        </Col>

        <Col span={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Paragraph className='popover-subtitle' style={{ color: activeTheme?.menuText }}>
            {translate('userProfileMenu.explore')}
          </Paragraph>
          {allowedProfileExploreMenu
            .filter(
              (item) =>
                hasUserPermission(isBusinessOwner, userBusinessPermissions, item?.permission) &&
                isAppIncludedInPlan(selectedPlan?.data, planApps, item?.key),
            )
            .map((item) => (
              <Button
                key={item.path}
                type='text'
                icon={item.icon}
                requiredPermission={item.permission}
                onClick={() => onNavigate(item.path)}
                className='text-left'>
                {translate(item.label)}
              </Button>
            ))}
        </Col>

        <Divider className='my-10' />
        <Row align={'middle'} className='w-full' justify={'space-between'}>
          <Col>
            <Button type='text' icon={<SettingOutlined />} onClick={() => onNavigate('settings/details')} className='text-left'>
              {translate('userProfileMenu.settings')}
            </Button>
          </Col>
          <Col>
            <Button type='text' icon={<LogoutOutlined />} onClick={() => onLogout()} className='text-left'>
              {translate('userProfileMenu.logout')}
            </Button>
          </Col>
        </Row>
      </Row>
    </Col>
  );

  return (
    <Popover
      rootClassName='user-profile-popover'
      placement='rightBottom'
      overlayStyle={{ width: 400, ...customOverlayStyle }}
      overlayInnerStyle={customOverlayInnerStyle}
      trigger='click'
      content={content}
      open={open}
      onOpenChange={onOpenChange}
      arrow={false}
    />
  );
};
