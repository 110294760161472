import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { LimitVisibility } from '@aduvi/components/LimitVisibility/LimitVisibility';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusinessId } from '@aduvi/hooks';
import { EPackagesDrawerTabs, IPackageTaxRate } from '@aduvi/types/services';
import { getFormErrors } from '@aduvi/utils/helper';

import { createPackage, getPackageById, updatePackage } from 'store/features/services-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Details } from './details/Details';
import { Pricing } from './pricing/Pricing';

const items = (translate: (key: string) => string, form: FormInstance): TabsProps['items'] => [
  {
    key: EPackagesDrawerTabs.DETAILS,
    label: translate('assets.packageDrawer.details'),
    children: <Details form={form} />,
  },
  {
    key: EPackagesDrawerTabs.PRICING,
    label: translate('assets.packageDrawer.pricing'),
    children: <Pricing form={form} />,
  },
  {
    key: EPackagesDrawerTabs.LIMIT_VISIBILITY,
    label: translate('assets.packageDrawer.limitVisibility'),
    children: <LimitVisibility form={form} />,
  },
];

export const PackagesDrawer = ({ onClose, open }: { open: boolean; onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const selectedBusinessId = useBusinessId();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EPackagesDrawerTabs.DETAILS);

  const { selectedService, selectedPackage, creating, updating, loadingPackage } = useAppSelector((state) => state.services);

  const createSinglePackage = () => {
    dispatch(
      createPackage({
        business_id: selectedBusinessId,
        service_id: form.getFieldValue('business_service_id'),
        body: {
          ...form.getFieldsValue(),
        },
      }),
    )
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const updateSinglePackage = () => {
    if (!selectedPackage?.id || !selectedService?.id) return;

    dispatch(
      updatePackage({
        business_id: selectedBusinessId,
        service_id: selectedService.id,
        package_id: selectedPackage.id,
        body: {
          ...form.getFieldsValue(),
        },
      }),
    )
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch((error) => {
        form.setFields(getFormErrors(error));
      });
  };

  const submitFormHandler = () => {
    selectedPackage?.id ? updateSinglePackage() : createSinglePackage();
  };

  const closeDrawer = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (!selectedPackage?.id || !selectedPackage?.business_service_id) return;
    dispatch(
      getPackageById({
        business_id: selectedBusinessId,
        package_id: selectedPackage.id,
        service_id: selectedPackage.business_service_id,
      }),
    )
      .unwrap()
      .then((response) => {
        form.setFieldsValue(response?.data);
        form.setFieldValue(
          'products',
          response.data.products.map((item) => item.id),
        );
        form.setFieldValue('tax_rate_ids', response.data.tax_rates?.map((taxRate: IPackageTaxRate) => taxRate.tax_rate_id));
      });
  }, [selectedPackage?.id, selectedBusinessId]);

  return (
    <Drawer
      title={selectedPackage?.name || translate('assets.packageDrawer.createPackage')}
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button onClick={closeDrawer} disabled={creating || updating}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            requiredPermission={PERMISSIONS.SERVICES.CREATE}
            disabledButton
            type='primary'
            onClick={submitFormHandler}
            disabled={creating || updating}
            loading={creating || updating}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingPackage}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Tabs defaultActiveKey={EPackagesDrawerTabs.DETAILS} activeKey={tab} items={items(translate, form)} onChange={setTab} />
        </Form>
      </Spin>
    </Drawer>
  );
};
