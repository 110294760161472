import dayjs, { Dayjs, ManipulateType } from 'dayjs';

export const generateFormat = (granularity: string[]): string => {
  const dateParts: string[] = [];
  const timeParts: string[] = [];

  if (granularity.includes('year')) dateParts.push('YYYY');
  if (granularity.includes('month')) dateParts.push('MM');
  if (granularity.includes('day')) dateParts.push('DD');
  if (granularity.includes('hour')) timeParts.push('HH');
  if (granularity.includes('minutes')) timeParts.push('mm');
  if (granularity.includes('seconds')) timeParts.push('ss');

  const dateFormat = dateParts.join('/');
  const timeFormat = timeParts.join(':');

  return dateFormat && timeFormat ? `${dateFormat} ${timeFormat}` : dateFormat || timeFormat || 'YYYY/MM/DD';
};

export const parseDate = (dateStr: string | undefined): Dayjs | undefined => {
  if (!dateStr) return undefined;
  if (dateStr === 'now') return dayjs();

  const relativeDateMatch = dateStr.match(/^([+-])(\d+)([ymd])$/);
  if (relativeDateMatch) {
    const [, operator, amountStr, unit] = relativeDateMatch;
    const amount = parseInt(amountStr, 10);
    return operator === '+' ? dayjs().add(amount, unit as ManipulateType | undefined) : dayjs().subtract(amount, unit as ManipulateType | undefined);
  }

  return dayjs(dateStr);
};
