import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

import { Button, Col, Divider, Row } from 'antd';
import './EditRowLayoutDrawer.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { DroppableArea } from '@aduvi/components/PersonalizedViewComponents/DraggableFields/droppable-area/DroppableArea';
import { useBusiness } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, ICustomFieldViewStyle, IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';
import { toCapitalize, toHumanReadable } from '@aduvi/utils/helper';

import { editPersonalizedView } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';

import { DraggableColumn } from '../DraggableFields/draggable-column/DraggableColumn';

interface IEditRowLayout {
  origin: EPersonalizedViewOrigin;
  columns?: ICustomFieldViewStyle[];
  data?: IEntityWithFields[];
  managementFields?: IEntityField[];
  onCancel: () => void;
  onClose: () => void;
}

export const EditRowLayoutDrawer = ({ onClose, columns, onCancel, data, managementFields, origin }: IEditRowLayout) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const { selectedViewId, selectedView, editing } = useAppSelector((state) => state.personalizedViews);

  const [isDragging, setIsDragging] = useState(false);

  const referenceEntityIds = managementFields
    ?.filter((field) => field?.reference_entity_type)
    ?.map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities, isLoading } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );

  const onUpdateColumns = () => {
    if (selectedBusiness?.id && selectedViewId && selectedView)
      dispatch(
        editPersonalizedView({
          business_id: selectedBusiness?.id,
          personalizedViewId: selectedViewId,
          body: { ...selectedView, style: JSON.stringify(columns) },
        }),
      )
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch(() => {});
  };

  return (
    <div className='edit-row-layout-drawer'>
      <DroppableArea id='droppableContainer'>
        <>
          <Row align={'middle'} className='header' justify={'space-between'}>
            <Row align='middle'>
              <CloseOutlined className='close-button' style={{ width: '16px', height: '16px', cursor: 'pointer' }} onClick={onCancel} />
              <Paragraph className='title mb-0'>{translate('contacts.editRowLayoutDrawer.addField')}</Paragraph>
              <Paragraph className='description mb-0'>{translate('contacts.editRowLayoutDrawer.dragTheFields')}</Paragraph>
            </Row>

            <Row>
              <Button className='mr-10' onClick={onCancel}>
                {translate('contacts.editRowLayoutDrawer.close')}
              </Button>
              <Button disabled={editing} type='primary' onClick={onUpdateColumns}>
                {translate('contacts.editRowLayoutDrawer.ok')}
              </Button>
            </Row>
          </Row>
          <Divider />
          <div
            style={{ overflowY: isDragging ? 'visible' : 'auto' }}
            className='fields_wrapper'
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}>
            <Col className='content mb-20'>
              <Paragraph className='details color-blue-6'>
                {translate('contacts.editRowLayoutDrawer.details', { origin: toCapitalize(origin) })}
              </Paragraph>

              <Row gutter={[16, 16]}>
                {managementFields &&
                  managementFields
                    ?.filter((field) => !field?.reference_entity_type)
                    ?.map((fieldValue) => {
                      const field = data?.[0]?.custom_fields?.find((item) => item?.id === fieldValue?.id);
                      return <DraggableColumn key={fieldValue?.id} id={fieldValue?.id} title={fieldValue?.title} field={field} record={data?.[0]} />;
                    })}
              </Row>
            </Col>

            {!isLoading &&
              referenceEntities?.data?.map((entity) => (
                <Col className='content mb-20' key={entity?.entity_id}>
                  <Paragraph className='details color-blue-6'>
                    {translate('contacts.editRowLayoutDrawer.details', { origin: toCapitalize(entity?.entity_name) })}
                  </Paragraph>

                  <Row gutter={[16, 16]}>
                    {entity?.custom_fields &&
                      entity?.custom_fields?.map((fieldValue) => {
                        return (
                          <DraggableColumn
                            key={fieldValue?.id}
                            id={fieldValue?.id}
                            title={`${toHumanReadable(entity?.entity_name)} ${fieldValue?.title}`}
                            field={data?.[0]?.custom_fields
                              ?.filter((field) => field?.reference_entity_type_id === entity?.entity_id)?.[0]
                              ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === fieldValue?.id)}
                            record={
                              data?.[0]?.custom_fields?.filter((field) => field?.reference_entity_type_id === entity?.entity_id)?.[0]
                                ?.field_data?.[0] as unknown as IEntityWithFields
                            }
                          />
                        );
                      })}
                  </Row>
                </Col>
              ))}
          </div>
        </>
      </DroppableArea>
    </div>
  );
};
