import dayjs, { isDayjs } from 'dayjs';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface OperatorOptions {
  date: string[];
  number: string[];
  text: string[];
}

const operatorMap: Record<OperatorOptions[keyof OperatorOptions][number], string> = {
  Is: 'equal',
  'Is not': 'notEqual',
  'Is before': 'before',
  'Is after': 'after',
  'Is between': 'between',
  'Is not between': 'notBetween',
  'Is greater than': 'greaterThan',
  'Is less than': 'lessThan',
  'Starts with': 'startsWith',
  'Does not start with': 'startsWith', // negation needed
  'Ends with': 'endsWith',
  'Does not end with': 'endsWith', // negation needed
  Contains: 'contains',
  'Does not contain': 'contains', // negation needed
};

interface Condition {
  entity: string;
  operator: string;
  date?: string;
  value?: string;
}

interface ConditionSet {
  conditions: Condition[];
  condition_set_operator: 'all' | 'any';
  match: 'all' | 'any';
}

interface RuleSet {
  id: string;
  sets: ConditionSet[];
}

// TODO: REFACTORING

const convertCondition = (condition: Condition) => {
  if (!condition) return;
  const fact = condition.entity.replace(/\s+/g, '').toLowerCase() + ':value';
  const operator = operatorMap[condition.operator];
  let value: any = condition.value || condition.date;

  if (condition.operator === 'Is between' || condition.operator === 'Is not between') {
    const [start, end] = value?.split(' and ');
    value = [start, end];
  }

  if (condition?.operator?.startsWith('Does not')) {
    return {
      fact,
      operator,
      value: isDayjs(value) ? dayjs(value).format('DD/MM/YYYY') : value,
      negate: true,
    };
  }

  return {
    fact,
    operator,
    value: isDayjs(value) ? dayjs(value).format('DD/MM/YYYY') : value,
  };
};

const convertConditionSet = (set: ConditionSet) => {
  const conditions = set.conditions.map(convertCondition);
  return set.match === 'all' ? { all: conditions } : { any: conditions };
};

const convertRuleSetToRule = (ruleSet: RuleSet) => {
  const conditions = {
    [ruleSet.sets[0].condition_set_operator]: ruleSet.sets.map((set) => convertConditionSet(set)),
  };

  return {
    conditions,
    event: {
      type: 'ruleMatched',
      params: {
        message: `Rule ${ruleSet.id} conditions have been met`,
      },
    },
  };
};

export const convertRuleSetsToEngineRules = (ruleSets: RuleSet[]) => {
  return ruleSets?.map(convertRuleSetToRule);
};
