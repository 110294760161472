import { useTranslation } from 'react-i18next';
import { AssetsType } from 'views/assets/Assets.hook';
import { StoreEmptyText } from 'views/assets/services/services-table/helper';

import { Row, Table, TablePaginationConfig } from 'antd';
import './EventsTable.scss';

import { Button } from '@aduvi/components/Button/Button';
import { PERMISSIONS } from '@aduvi/constants';
import { useLongPress } from '@aduvi/hooks';
import { IEntityWithFields } from '@aduvi/types/entity';

import { setSelectedEntity, setSelectedTicket } from 'store/features/entity-slice';
import { setSelectedEvent } from 'store/features/events-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { createObjectFromArray, useEventsTableColumns, useTicketsTableColumns } from './helper';

interface IProps {
  currentTab?: AssetsType;
  pagination?: false | TablePaginationConfig | undefined;
  setShowEventsDrawer: (prop: boolean) => void;
  setShowTicketsDrawer: (prop: boolean) => void;
}

export const EventsTable = ({ currentTab, pagination, setShowEventsDrawer, setShowTicketsDrawer }: IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { handleMouseDown, handleMouseUp, checkLongPress } = useLongPress();

  const {
    entities: { data, loading, creating, updating },
  } = useAppSelector((state) => state.entity);

  const eventsColumns = useEventsTableColumns(setShowEventsDrawer);
  const ticketsColumns = useTicketsTableColumns(setShowTicketsDrawer);

  const expandedTicketsTable = (record: IEntityWithFields) => {
    const subDataForRecord = createObjectFromArray(data)[record.id];
    return (
      <div style={{ marginBottom: '40px' }}>
        {subDataForRecord?.length ? (
          <Table
            rowKey='id'
            rowClassName='tickets-table-row'
            showHeader={false}
            pagination={false}
            columns={ticketsColumns.filter((item) => !item.hidden)}
            dataSource={subDataForRecord}
            onRow={(record) => ({
              onMouseDown: handleMouseDown,
              onMouseUp: handleMouseUp,
              onClick: (e) => {
                dispatch(setSelectedTicket({ id: record.business_event_id }));
                if (!checkLongPress()) {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(setSelectedTicket(record));
                  setShowTicketsDrawer(true);
                }
              },
            })}
          />
        ) : (
          <></>
        )}

        <Row justify='end'>
          <Button
            type='primary'
            disabledButton
            requiredPermission={PERMISSIONS.EVENTS.CREATE}
            className='mt-15'
            onClick={() => {
              dispatch(setSelectedEntity(record));
              dispatch(setSelectedTicket(undefined));
              setShowTicketsDrawer(true);
            }}>
            + {translate('assets.events.addTicket')}
          </Button>
        </Row>
      </div>
    );
  };

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <Table
      rowClassName='events-table-row'
      className='events-table'
      loading={loading || creating || updating}
      columns={eventsColumns}
      dataSource={dataWithKey}
      pagination={pagination}
      showHeader={false}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowKeys: dataWithKey.map(({ id }) => id),
        expandedRowRender: expandedTicketsTable,
      }}
      onRow={(record) => ({
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp,
        onClick: (e) => {
          dispatch(setSelectedEvent({ id: record.id }));
          if (!checkLongPress()) {
            e.stopPropagation();
            e.preventDefault();
            dispatch(setSelectedEntity(record));
            setShowEventsDrawer(true);
          }
        },
      })}
      locale={{
        emptyText: <StoreEmptyText currentTab={currentTab} setShowDrawer={() => setShowEventsDrawer(true)} />,
      }}
    />
  );
};
