import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IContract, ICreateContractPayload, IDeleteContractPayload, IGetContractByIdPayload, IResponse, IUpdateContractPayload } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const contractsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContracts: builder.query<IResponse<IContract[]>, string>({
      query: (businessId) => ({ url: `/businesses/${businessId}/contract-templates`, method: 'GET' }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: SLICE_TAG_TYPES.CONTRACTS, id }) as const), { type: SLICE_TAG_TYPES.CONTRACTS, id: 'LIST' }]
          : [{ type: SLICE_TAG_TYPES.CONTRACTS, id: 'LIST' }],
    }),
    getContractById: builder.query<IResponse<IContract>, IGetContractByIdPayload>({
      query: ({ business_id, contract_id }) => ({ url: `/businesses/${business_id}/contract-templates/${contract_id}`, method: 'GET' }),
      providesTags: (result, error, arg) => [{ type: SLICE_TAG_TYPES.CONTRACTS, id: arg.contract_id }],
    }),
    createContract: builder.mutation<IResponse<IContract>, ICreateContractPayload>({
      query: (payload) => ({
        url: `/businesses/${payload.business_id}/contract-templates`,
        method: 'POST',
        data: payload.body,
      }),
      invalidatesTags: [{ type: SLICE_TAG_TYPES.CONTRACTS, id: 'LIST' }],
    }),
    updateContract: builder.mutation<IResponse<IContract>, IUpdateContractPayload>({
      query: (payload) => ({
        url: `/businesses/${payload.business_id}/contract-templates/${payload.contract_id}`,
        method: 'PUT',
        data: payload.body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: SLICE_TAG_TYPES.CONTRACTS, id: arg.contract_id }],
    }),
    deleteContract: builder.mutation<IResponse<null>, IDeleteContractPayload>({
      query: (payload) => ({
        url: `/businesses/${payload.business_id}/contract-templates/${payload.contract_id}?title=${payload.contract_name}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ business_id, contract_id }, { dispatch, queryFulfilled }) {
        if (!business_id) return;
        const patchResult = dispatch(
          contractsApiSlice.util.updateQueryData('getContracts', business_id, (draft) => {
            draft.data = draft.data.filter((contract) => contract.id !== contract_id);
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: SLICE_TAG_TYPES.CONTRACTS, id: arg.contract_id }],
    }),
    duplicateContract: builder.mutation<IResponse<IContract>, { business_id: string; contract_id: string }>({
      query: ({ business_id, contract_id }) => ({
        url: `/businesses/${business_id}/contract-templates/${contract_id}/duplicate`,
        method: 'POST',
      }),
      async onQueryStarted({ business_id, contract_id }, { dispatch, queryFulfilled }) {
        if (!business_id) return;
        const patchResult = dispatch(
          contractsApiSlice.util.updateQueryData('getContracts', business_id, (draft) => {
            const originalContract = draft.data.find((contract) => contract.id === contract_id);
            if (originalContract) {
              const duplicatedContract: IContract = {
                ...originalContract,
                id: 'temp-id-duplicate',
                title: originalContract?.title,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
              };
              draft.data.push(duplicatedContract);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: SLICE_TAG_TYPES.CONTRACTS, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractByIdQuery,
  useCreateContractMutation,
  useUpdateContractMutation,
  useDeleteContractMutation,
  useDuplicateContractMutation,
} = contractsApiSlice;
