import { useCallback, useEffect, useState } from 'react';

import { Spin } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useDebounce, useUserPermissionCheck } from '@aduvi/hooks';
import { EEntityType } from '@aduvi/types/entity';

import { getEntities, setSelectedEntity, setSelectedTicket } from 'store/features/entity-slice';
import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AssetsType } from '../Assets.hook';

import { EventsDrawer } from './events-drawer/EventsDrawer';
import { EventsTable } from './events-table/EventsTable';
import { TicketsDrawer } from './tickets-drawer/TicketsDrawer';

export const Events = ({ searchKey, currentTab }: { searchKey: string; currentTab?: AssetsType }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const debouncedSearchKey = useDebounce(searchKey) || undefined;
  const hasPermissionToViewEventFields = useUserPermissionCheck(PERMISSIONS.EVENT_FIELDS.VIEW);

  const {
    entityTypes: { data },
  } = useAppSelector((state) => state.entity);

  const [showEventsDrawer, setShowEventsDrawer] = useState(false);
  const [showTicketsDrawer, setShowTicketsDrawer] = useState(false);

  const {
    entities: { data: events, loading },
  } = useAppSelector((state) => state.entity);

  const onDrawerClose = () => {
    dispatch(setSelectedEntity(undefined));
    dispatch(setSelectedTicket(undefined));
    setShowEventsDrawer(false);
    setShowTicketsDrawer(false);
  };

  const onGetEvents = useCallback(() => {
    if (!selectedBusiness?.id || !data[EEntityType.EVENT]?.id) return;
    dispatch(
      getEntities({
        businessId: selectedBusiness?.id,
        entityTypeId: data[EEntityType.EVENT]?.id,
      }),
    );
  }, [selectedBusiness?.id, data[EEntityType.EVENT]?.id]);

  useEffect(() => {
    if (!selectedBusiness?.id || !data[EEntityType.EVENT]?.id || !hasPermissionToViewEventFields) return;
    dispatch(getEntityFields({ businessId: selectedBusiness.id, entityTypeId: data[EEntityType.EVENT]?.id }));
  }, [selectedBusiness?.id, data[EEntityType.EVENT]?.id]);

  useEffect(() => {
    onGetEvents();
  }, [debouncedSearchKey, selectedBusiness?.id, data[EEntityType.EVENT]?.id]);

  return (
    <Spin spinning={loading}>
      <EventsTable
        pagination={{
          total: events?.length,
          pageSize: 10,
        }}
        setShowEventsDrawer={setShowEventsDrawer}
        setShowTicketsDrawer={setShowTicketsDrawer}
        currentTab={currentTab}
      />
      {showEventsDrawer && <EventsDrawer open={showEventsDrawer} onClose={onDrawerClose} />}
      {showTicketsDrawer && <TicketsDrawer open={showTicketsDrawer} onClose={onDrawerClose} />}
    </Spin>
  );
};
