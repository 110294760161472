import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

import { Button, Upload, UploadFile } from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/es/upload';
import './UploadImage.scss';

import { Notification } from '@aduvi/utils/notification';

import { uploadImage } from 'store/features/common-slice';
import { useAppDispatch } from 'store/hooks';

import { IUploadFile } from '../../types';
import { Paragraph } from '../Paragraph';

interface IProps {
  name: string | string[];
  onUploadChange: (value: IUploadFile | null) => void;
  url?: string;
  icon?: React.ReactNode;
  text?: string;
  uploadSettings?: {
    allowed_extensions: string[];
    maximum_upload_size: string;
    size_unit: string;
  };

  uploadType?: 'dragger' | 'image';
}

export const ImageUpload = ({
  name,
  onUploadChange,
  url,
  uploadSettings,
  text = 'common.uploadButton',
  icon = <UploadOutlined />,
  uploadType = 'image',
  ...restProps
}: UploadProps & IProps) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const [image, setImage] = useState<UploadFile[]>([]);

  const onImageUpload = (file: RcFile) => {
    if (uploadSettings?.allowed_extensions) {
      if (!uploadSettings.allowed_extensions.some((extension) => file.type?.toLowerCase().endsWith(extension?.toLowerCase()))) {
        return Notification.error({
          title: translate('common.errorWhileUploading'),
          description: translate('common.allowedExtensions', { item: uploadSettings.allowed_extensions.map((item) => item).join(', ') }),
        });
      }
      if (Number(uploadSettings.maximum_upload_size) < file.size / 1024 / 1024) {
        return Notification.error({
          title: translate('common.errorWhileUploading'),
          description: translate('common.allowedSize', { item: uploadSettings.maximum_upload_size + ' ' + uploadSettings.size_unit }),
        });
      }
    }

    const formData = new FormData();
    formData.append('file', file);

    setImage([
      {
        uid: '1',
        name: translate('common.uploading'),
        status: 'uploading',
      },
    ]);
    dispatch(uploadImage(formData))
      .unwrap()
      .then((res) => {
        onUploadChange({ url: res.data.file_name, size: (file.size / 1000000).toString(), extension: file.type.split('/').at(-1)?.toUpperCase() });

        setImage([
          {
            uid: res.data.file_name,
            name: `${name}.png`,
            url: res.data.file_name,
            thumbUrl: res.data.file_name,
          },
        ]);
      })
      .catch(() => {
        setImage([
          {
            uid: '1',
            name: translate('common.errorWhileUploading'),
            status: 'error',
          },
        ]);
      });
    return false;
  };

  const initializeImageAfterUpload = (url?: string) => {
    if (!url) return setImage([]);

    setImage([
      {
        uid: '1',
        name: `${name}.png`,
        status: 'done',
        url,
        thumbUrl: url,
      },
    ]);
  };

  const onFileUploadChange = (event: UploadChangeParam) => {
    if (!event.fileList?.length) {
      setImage([]);
      return onUploadChange(null);
    }
  };

  useEffect(() => {
    initializeImageAfterUpload(url);
  }, [url]);

  return uploadType === 'dragger' ? (
    <Upload.Dragger
      listType='picture'
      className='mt-0 upload-image-wrapper'
      fileList={image}
      {...restProps}
      beforeUpload={(file) => onImageUpload(file)}
      onChange={(event) => onFileUploadChange(event)}>
      <Paragraph className='ant-upload-drag-icon'>
        <InboxOutlined className='fs-28 color-blue-6' />
      </Paragraph>
      <Paragraph className='ant-upload-text'>{translate('components.customFieldsDrawer.fileUploadText')}</Paragraph>
      <Paragraph className='ant-upload-hint'>{translate('components.customFieldsDrawer.fileUploadHint')}</Paragraph>
    </Upload.Dragger>
  ) : (
    <Upload
      listType='picture'
      className='mt-0 upload-image-wrapper'
      fileList={image}
      {...restProps}
      beforeUpload={(file) => onImageUpload(file)}
      onChange={(event) => onFileUploadChange(event)}>
      {restProps.listType === 'picture-circle' ? !image.length && <PlusOutlined /> : !image.length && <Button icon={icon}>{translate(text)}</Button>}
    </Upload>
  );
};
