import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, EyeOutlined, RightOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Divider, Dropdown, Form, FormInstance, Input, Row, Spin, Switch, Typography } from 'antd';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { EClientPortalComponentType } from '@aduvi/types';

import { getClientOrderById } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetEntitiesQuery } from 'store/rtk-slice/entity-slice';

import { EntityRow } from '../EntityRow/EntityRow';

export const OrderList = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { orderId } = useParams();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { user } = useAppSelector((state) => state.clientPortalAuth);
  const { entityTypes } = useAppSelector((state) => state.entity);

  const [columns, setColumns] = useState<typeof entityListData | undefined>(undefined);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const title = useWidgetTitle('clientPortal.orderList.title', 'order_list', selectedClientPortal?.client_portal_settings?.order_list?.title, form);

  const selectedOrder = useMemo(() => {
    if (user?.orders?.selectedOrder) return user.orders.selectedOrder;
    return user?.orders?.data?.[0];
  }, [user?.orders?.selectedOrder]);

  const { data, isLoading } = useGetEntitiesQuery(
    { businessId: selectedClientPortal?.business_id, entityTypeId: entityTypes?.data?.ORDER?.id },
    { skip: !selectedClientPortal?.business_id || !entityTypes?.data?.JOB?.id || readonly },
  );

  const entityListData = useMemo(
    () =>
      readonly
        ? selectedOrder?.custom_fields?.map((field) => ({
            id: field.id,
            column: field.title,
            visible: true,
            editable: true,
            value: <CustomFieldValue field={field} />,
          }))
        : data?.data?.entities?.[0]?.custom_fields?.map((field) => ({
            id: field.id,
            column: field.title,
            visible: true,
            editable: true,
            value: <CustomFieldValue field={field} />,
          })),
    [selectedOrder, data?.data?.entities, readonly],
  );

  useEffect(() => {
    if (!readonly || !selectedClientPortal?.business_id || !orderId) return;

    dispatch(getClientOrderById({ business_id: selectedClientPortal?.business_id, orderId }));
  }, []);

  useEffect(() => {
    if (readonly) return;
    setColumns(entityListData);
  }, [entityListData]);

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'> {title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              destroyPopupOnHide
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item initialValue={title} labelCol={{ span: 24 }} name={['client_portal_settings', 'order_list', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' initialValue={true} name={['client_portal_settings', 'order_list', 'half_width']}>
                      <Switch size='small' defaultValue={selectedClientPortal?.client_portal_settings?.order_list?.half_width} className='m-10' />
                    </Form.Item>
                    <Typography.Text> {translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item
                      className='mb-0'
                      valuePropName='checked'
                      initialValue={true}
                      name={['client_portal_settings', 'order_list', 'hide_empty_fields']}>
                      <Switch size='small' className='m-10' />
                    </Form.Item>
                    <Typography.Text> {translate('clientPortal.orderList.hideEmptyFields')}</Typography.Text>
                  </Row>
                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <div>
                          <Typography.Paragraph className='mb-10' type='secondary'>
                            {translate('clientPortal.orderList.fields')}
                          </Typography.Paragraph>
                          {columns?.map((column, index) => (
                            <Row justify={'space-between'} gutter={16} key={column.id} align={'middle'}>
                              <Form.Item
                                className='mb-0'
                                initialValue={true}
                                name={['client_portal_settings', 'order_list', 'visible_fields', column.id]}>
                                <Switch
                                  size='small'
                                  onChange={() => {
                                    const newColumns = [...columns!];
                                    newColumns[index].visible = !newColumns[index].visible;
                                    setColumns([...newColumns]);
                                  }}
                                  checked={column.visible}
                                  style={{ margin: 6 }}
                                />
                              </Form.Item>
                              <Typography.Text>{column.column}</Typography.Text>
                            </Row>
                          ))}
                        </div>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <EyeOutlined /> {translate('clientPortal.orderList.visibleFields')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <div>
                          <Typography.Paragraph className='mb-10' type='secondary'></Typography.Paragraph>
                          {columns?.map((column, index) => (
                            <Row justify={'space-between'} gutter={16} key={column.id} align={'middle'}>
                              <Form.Item
                                className='mb-0'
                                initialValue={true}
                                name={['client_portal_settings', 'order_list', 'editable_fields', index, column.id]}>
                                <Switch
                                  size='small'
                                  onChange={() => {
                                    const newColumns = [...columns];
                                    newColumns[index].editable = !newColumns[index].editable;
                                    setColumns([...newColumns]);
                                  }}
                                  checked={column.editable}
                                  style={{ margin: 6 }}
                                />
                              </Form.Item>
                              <Typography.Text>{column.column}</Typography.Text>
                            </Row>
                          ))}
                        </div>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <EditOutlined /> {translate('clientPortal.orderList.editableFields')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.ORDER_LIST, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='b-radius-10'>
      <Spin spinning={isLoading || user.orders.loading}>
        {!readonly &&
          data?.data?.entities?.[0]?.custom_fields?.map((field, index) => (
            <React.Fragment key={index}>
              {form?.getFieldValue(['client_portal_settings', 'order_list', 'hide_empty_fields']) ? (
                field.field_data[0]?.value ? (
                  <EntityRow field={field} />
                ) : null
              ) : columns?.find((column) => column.id === field.id)?.visible ? (
                <EntityRow field={field} />
              ) : null}
            </React.Fragment>
          ))}

        {readonly &&
          selectedOrder?.custom_fields?.map((field, index) =>
            selectedClientPortal?.client_portal_settings.order_list.hide_empty_fields ? (
              field.field_data[0]?.value ? (
                <EntityRow key={index} field={field} />
              ) : null
            ) : selectedClientPortal?.client_portal_settings.order_list.visible_fields[field.id] ? (
              <EntityRow key={index} field={field} />
            ) : null,
          )}
      </Spin>
    </Card>
  );
};
