import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Form, FormInstance, Row, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button } from '@aduvi/components/Button/Button';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { LimitVisibility } from '@aduvi/components/LimitVisibility/LimitVisibility';
import { DRAWER_BODY_STYLE, PERMISSIONS } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { EDrawerTabs, ICreateProductPayload, IProductTaxRate } from '@aduvi/types/products';
import { getFormErrors } from '@aduvi/utils/helper';

import { createProduct, editProduct, getProductById, setSelectedProduct } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Details } from './details/Details';
import { Pricing } from './pricing/Pricing';
import { QuantityStock } from './quantity-stock/QuantityStock';
import { Variations } from './variations/Varitations';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const ProductsDrawer = ({ onClose, open }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [form] = useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EDrawerTabs.DETAILS);

  const { selectedProduct, creating, updating, loadingProduct } = useAppSelector((state) => state.products);
  const onCreateProduct = () => {
    form
      .validateFields()
      .then((values: ICreateProductPayload) => {
        const [price_type, price_unit] = (values.price_unit || '').split('.');
        const [upsell_price_type, upsell_price_unit] = (values.upsell_price_unit || '').split('.');
        const limit_visibility = form.getFieldValue('limit_visibility');

        dispatch(
          createProduct({
            ...values,
            price_type: price_type || undefined,
            price_unit: price_unit || undefined,
            upsell_price_type: upsell_price_type || undefined,
            upsell_price_unit: upsell_price_unit || undefined,
            business_id: selectedBusiness?.id!,
            variations: values.variations ?? [],
            limit_visibility,
            tax_rate_ids: values.tax_rate_ids,
          }),
        )
          .unwrap()
          .then(() => {
            closeDrawer();
          })
          .catch((error) => {
            form.setFields(getFormErrors(error));
          });
      })
      .catch(() => {});
  };

  const onEditProduct = () => {
    if (!selectedBusiness?.id || !selectedProduct?.id) return;
    const { price_unit, upsell_price_unit, upsell, tax_rate_ids } = form.getFieldsValue();
    const [edited_price_type, edited_price_unit] = (price_unit || '').split('.');

    const body = {
      ...selectedProduct,
      ...form.getFieldsValue(),
      price_type: edited_price_type || selectedProduct?.price_type,
      price_unit: edited_price_unit || selectedProduct?.price_unit,
      upsell_price_type: upsell ? upsell_price_unit.split('.')[0] : selectedProduct?.upsell_price_type,
      upsell_price_unit: upsell ? upsell_price_unit.split('.')[1] : selectedProduct?.upsell_price_unit,
      tax_rate_ids,
    };
    delete body.tax_rates;

    dispatch(
      editProduct({
        business_id: selectedBusiness?.id,
        product_id: selectedProduct.id,
        body,
      }),
    )
      .unwrap()
      .then(() => {
        closeDrawer();
      })
      .catch((error) => form.setFields(getFormErrors(error)));
  };

  const submitFormHandler = () => {
    selectedProduct?.id ? onEditProduct() : onCreateProduct();
  };

  const closeDrawer = () => {
    form.resetFields();
    setTab(EDrawerTabs.DETAILS);
    dispatch(setSelectedProduct(undefined));
    onClose();
  };

  useEffect(() => {
    if (!selectedProduct?.id) return form.resetFields();

    dispatch(getProductById({ product_id: selectedProduct.id, business_id: selectedBusiness?.id }))
      .unwrap()
      .then(({ data }) => {
        const { price_type, price_unit, upsell_price_unit, upsell_price_type, sale_end_date, upsell, product_variations, tax_rates } = data;
        form.setFieldsValue({
          ...data,
          price_type: undefined,
          price_unit: price_unit ? `${price_type}.${price_unit}` : undefined,
          upsell_price_unit: upsell_price_unit ? `${upsell_price_type}.${upsell_price_unit}` : undefined,
          sale_end_date: sale_end_date ? dayjs(sale_end_date) : undefined,
          upsell: !!upsell,
          variations: product_variations,
          tax_rate_ids: tax_rates?.map((taxRate: IProductTaxRate) => taxRate.tax_rate_id),
        });
      })
      .catch(() => {});
  }, [selectedProduct?.id, selectedBusiness?.id]);

  return (
    <Drawer
      title={translate('assets.productsDrawer.title')}
      open={open}
      width={720}
      onClose={closeDrawer}
      bodyStyle={DRAWER_BODY_STYLE}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            requiredPermission={PERMISSIONS.PRODUCTS.EDIT}
            disabledButton
            type='primary'
            onClick={submitFormHandler}
            loading={creating || updating}
            disabled={loadingProduct || creating || updating}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      {loadingProduct ? (
        <Row className='h-full w-full' justify='center' align='middle'>
          <Spin wrapperClassName='w-full' spinning={true} tip={translate('assets.productsDrawer.fetchingProduct')}>
            <></>
          </Spin>
        </Row>
      ) : (
        <Form form={form} layout='vertical' requiredMark={false}>
          <Tabs defaultActiveKey={tab} activeKey={tab} items={items(translate, form)} onChange={setTab} />
        </Form>
      )}
    </Drawer>
  );
};

const items = (translate: (key: string) => string, form: FormInstance): TabsProps['items'] => {
  return [
    {
      key: EDrawerTabs.DETAILS,
      label: translate('assets.productsDrawer.details'),
      children: <Details form={form} />,
    },
    {
      key: EDrawerTabs.QUANTITY_STOCK,
      label: translate('assets.productsDrawer.quantityStock'),
      children: <QuantityStock form={form} />,
    },
    {
      key: EDrawerTabs.PRICING,
      label: translate('assets.productsDrawer.pricing'),
      children: <Pricing form={form} />,
    },
    {
      key: EDrawerTabs.VARIATIONS,
      label: translate('assets.productsDrawer.variations'),
      children: <Variations form={form} />,
    },
    {
      key: EDrawerTabs.LIMIT_VISIBILITY,
      label: translate('assets.productsDrawer.limitVisibility'),
      children: <LimitVisibility form={form} />,
    },
  ];
};
