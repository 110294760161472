import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { SelectService } from '@aduvi/components/SelectService/SelectService';
import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useUserPermissionCheck } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';

import { getProducts } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const Details = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { products, loading } = useAppSelector((state) => state.products);
  const { selectedPackage, selectedService } = useAppSelector((state) => state.services);

  const [searchProduct, setSearchProduct] = useState('');
  const hasPermissionToViewProducts = useUserPermissionCheck(PERMISSIONS.PRODUCTS.VIEW);

  const onGetProducts = useCallback(
    (page = 1, size = 10) => {
      if (!selectedBusiness?.id || !hasPermissionToViewProducts) return;
      dispatch(getProducts({ business_id: selectedBusiness?.id, params: { page, size } }));
    },
    [selectedBusiness?.id, hasPermissionToViewProducts],
  );

  useEffect(() => {
    form.setFieldValue('business_service_id', selectedService?.id);
  }, [selectedService?.id]);

  useEffect(() => {
    onGetProducts(1, 10);
  }, [selectedBusiness?.id]);

  return (
    <Col span={24} className='mt-15'>
      <Form.Item
        name='name'
        label={translate('assets.packageDetails.name')}
        rules={[{ required: true, message: translate('assets.packageDetails.nameValidation') }]}>
        <Input placeholder={translate('assets.packageDetails.namePlaceholder')} />
      </Form.Item>
      {!selectedPackage?.id && (
        <Form.Item label='Service' name='business_service_id'>
          <SelectService defaultService={selectedService?.id} />
        </Form.Item>
      )}

      <Form.Item label={translate('assets.packageDetails.image')} name='image'>
        <UploadImage name={'image'} onUploadChange={(value) => form.setFieldValue('image', value?.url)} url={form.getFieldValue('image')} />
      </Form.Item>
      <Form.Item label={translate('assets.packageDetails.description')} name={'description'}>
        <Tiptap
          content={form.getFieldValue('description')}
          placeholder={translate('assets.packageDetails.descriptionPlaceholder')}
          onUpdate={(value) => form.setFieldValue('description', value.getHTML())}
        />
      </Form.Item>

      <Form.Item
        name='visibility'
        label={translate('assets.packageDetails.visibility')}
        rules={[{ required: true, message: translate('assets.packageDetails.visibilityValidation') }]}
        initialValue={'1'}>
        <Select
          defaultValue={'1'}
          style={{ width: '100%' }}
          options={[
            { value: '1', label: 'Public' },
            { value: '0', label: 'Private' },
          ]}
        />
      </Form.Item>

      <Form.Item label={translate('assets.packageDetails.includedProducts')} name='products'>
        <Select
          showSearch
          mode='multiple'
          className='w-full'
          loading={loading}
          disabled={loading}
          placeholder={translate('assets.packageDetails.includedProductsPlaceholder')}
          onSearch={setSearchProduct}
          searchValue={searchProduct}
          optionFilterProp='label'
          options={products?.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Form.Item>
    </Col>
  );
};
