import React from 'react';

import { Col, Row, Skeleton } from 'antd';

export const FormatSkeleton = () => {
  return (
    <Row className='w-full flex flex-direction-column' justify='center' align='middle'>
      <Col span={14} className='w-full'>
        <Col span={24} className='w-full'>
          <Skeleton.Input active size='large' className='mt-10 w-full b-radius-10' />
        </Col>
        <Col span={20} className='w-full'>
          <Skeleton.Input size='large' className='mt-10 w-full b-radius-10' />
        </Col>
        <Col span={24} className='w-full'>
          <Skeleton.Input active size='large' className='mt-10 w-full b-radius-10' />
        </Col>
        <Col span={16} className='w-full'>
          <Skeleton.Input size='large' className='mt-10 w-full b-radius-10' />
        </Col>
      </Col>
    </Row>
  );
};
