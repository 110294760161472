import { useEffect, useMemo } from 'react';

import { getActiveBusinessPlan } from 'store/features/billing-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useBusiness = () => {
  const { selectedBusiness } = useAppSelector((state) => state.business);

  return selectedBusiness;
};

export const useBusinessId = (): string => {
  const selectedBusiness = useBusiness();

  if (selectedBusiness) {
    return selectedBusiness?.id;
  }
  return '';
};

export const useBusinessCurrency = () => {
  const selectedBusiness = useBusiness();

  const { currencies } = useAppSelector((state) => state.common.currencies);

  return currencies.find((currency) => currency.id === selectedBusiness?.currency_id);
};

export const useBusinessAdditionalApps = () => {
  const selectedBusiness = useBusiness();
  const { apps } = useAppSelector((state) => state.common);

  const additionalAppIds = selectedBusiness?.business_additional_apps?.map((app) => app.partner_app.app_id) || [];

  return apps.data.filter((app) => additionalAppIds.includes(app.id));
};

export const useSelectedPlan = () => {
  const dispatch = useAppDispatch();
  const businessAdditionalApps = useBusinessAdditionalApps();
  const selectedBusiness = useBusiness();
  const selectedPlan = useAppSelector((state) => state.billing.selectedPlan);

  const planApps = useMemo(() => {
    if (!businessAdditionalApps || !selectedPlan?.data?.apps?.length || !selectedPlan) return [];
    const businessApps = [...(selectedPlan?.data?.apps || []), ...businessAdditionalApps];

    return businessApps.map((app) => app.key);
  }, [selectedPlan?.data?.id, selectedPlan?.data?.apps?.length]);

  useEffect(() => {
    if (!selectedBusiness?.id || selectedPlan?.businessId === selectedBusiness?.id) return;

    dispatch(getActiveBusinessPlan({ business_id: selectedBusiness.id }));
  }, [selectedBusiness?.id]);

  return { selectedPlan, planApps };
};

export const useTerminology = () => {
  const { terminology } = useAppSelector((state) => state.common);

  return terminology;
};
