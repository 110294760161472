/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Edge, Node } from 'reactflow';
import dayjs, { Dayjs } from 'dayjs';
import Cookies from 'js-cookie';
import { IBookingFormFieldProps } from 'views/forms/tabs/build/helper';

import { MenuProps } from 'antd';
import { Color } from 'antd/es/color-picker';

import { ICurrentUser, IEntityWithCustomFields, ILayoutResponse, IMenuSetting, IPricing, IUser } from '@aduvi/types';
import { IActivity, IActivityDifference, IAudit } from '@aduvi/types/audit';
import { IAutomationMention } from '@aduvi/types/automation';
import { EEntityType, IEntityType } from '@aduvi/types/entity';

interface KeyValue {
  key: string;
  value: any;
}

export const arrayToObject = (array: any[], keyField = 'id') =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const objectToArray = (object: any) => Object.values(object || {}).map((value) => value);

export const groupDuplicatesByPropertyName = <T>(array: T[], propertyName: keyof T) => {
  const groupedData: { [key: string]: T[] } = {};

  array.forEach((obj) => {
    const key = obj[propertyName];

    if (!groupedData[key as string]) {
      groupedData[key as string] = [];
    }
    groupedData[key as string].push(obj);
  });

  return groupedData;
};

export const extractQueryParams = <T>(search: string, dry = false): T => {
  if (!search) return {} as T;

  return search
    .replace('?', '')
    .split('&')
    .reduce((data: T, item) => {
      const [k, v] = item.split('=');

      if (!k || !v) return data;

      if (dry) return { ...data, [k]: v };

      if (v.includes(',')) return { ...data, [k]: v.split(',') };

      if (['true', 'false'].includes(v)) return { ...data, [k]: v === 'true' };

      if (v === 'null') return { ...data, [k]: null };

      if (!Number.isNaN(+v)) return { ...data, [k]: +v };

      return { ...data, [k]: v };
    }, {} as T);
};

export const delay = (delayTime = 300): Promise<void> => new Promise((resolve) => setTimeout(resolve, delayTime));

export const parse = <T>(value: string | undefined, def: T): T => {
  if (!value) return def;
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return def;
  }
};

export const searchInObj = (itemObj: any, searchText: string) => {
  for (const prop in itemObj) {
    if (!Object.prototype.hasOwnProperty.call(itemObj, prop)) {
      continue;
    }

    const value = itemObj[prop];

    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    } else if (Array.isArray(value)) {
      if (searchInArray(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
};

export const searchInString = (value: string, searchText: string) => {
  return value.toLowerCase().includes(searchText);
};

export const filterArrayByString = (mainArr: unknown[], searchText: string) => {
  if (searchText === '') {
    return mainArr;
  }
  searchText = searchText.toLowerCase();

  return mainArr.filter((itemObj) => {
    return searchInObj(itemObj, searchText);
  });
};

export const searchInArray = (arr: unknown[], searchText: string) => {
  if (!arr.length) return false;
  for (const value of arr) {
    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
};

export const removeDuplicates = <T>(key: keyof T, arr?: T[]) => {
  if (!arr || !arr.length) return [];
  return arr.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj[key] === o[key])) {
      unique.push(o);
    }
    return unique;
  }, []);
};

export const iterateObject = (array: any, id: string | number) => {
  for (const k in array) {
    const obj = array[k];
    const foundObj = findObjectById(obj, id);
    if (foundObj) {
      return foundObj;
    }
  }
};

export const findObjectById = (root: any, id: string | number) => {
  if (root.id === id) {
    return root;
  }
  if (root.children && root.children.length > 0) {
    for (const k in root.children) {
      if (root.children[k].id === id) {
        return root.children[k];
      } else if (root.children[k].children && root.children[k].children.length > 0) {
        const result: any = findObjectById(root.children[k], id);
        if (result) {
          return result;
        }
      }
    }
  }
};
export const findItemsInArray = (items: any[], key: string, value: string) => {
  return items?.find((item) => item[key] === value);
};

export const removeDuplicatesByMap = (array: any[], key: string) => {
  return [...Array.from(new Map(array.map((arr) => arr?.[key]).map((item) => [item?.[key], item])).values())];
};

export const getOfficeIdFromUrl = (url: string) => {
  const regexp = /\/offices\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/gi;
  const matches = regexp.exec(url);

  return matches && matches.length > 1 ? matches[1] : null;
};

export const toHumanReadable = (str: string) => {
  return str
    ?.replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/(^|\s|\()([a-z])/g, function (_, p1, p2) {
      return p1 + p2.toUpperCase();
    });
};

export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const normalToSnakeCase = (str: string) => {
  return str.replace(/\s/g, '_').toLowerCase();
};

export const normalToKebabCase = (str: string) => {
  return str.replace(/\s/g, '-').toLowerCase();
};

export const snakeCaseToNormal = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.replace('-', ' ').replace('_', ' ');
  });
};

export const toCapitalize = (str: string | undefined) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const randomFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const downloadCSVFile = (data: any, fileName: string) => {
  const blob = new Blob([data]);
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;

  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const getFormErrors = (error: any) => {
  if (!error?.status || error?.status === 500) return [];

  return Object.entries(error?.data || error)?.map(([key, value]) => {
    return { name: key, errors: Array.isArray(value) ? value : [value] };
  });
};

export const hasUserPermission = (isBusinessOwner: boolean, userBusinessPermissions?: string[], permission?: string | string[]) => {
  if (!permission || !permission?.length || isBusinessOwner) return true;
  return typeof permission === 'string'
    ? userBusinessPermissions?.includes(permission)
    : userBusinessPermissions?.some((p: string) => permission?.includes(p));
};

export const isAppIncludedInPlan = (plan?: IPricing, businessApps?: string[], appKey?: string) => {
  if (!appKey || !plan) return true;

  if (appKey?.includes(',')) {
    const appKeys = appKey.split(',');
    return businessApps?.map((i) => i.toLowerCase())?.some((item) => appKeys?.includes(item));
  }

  return businessApps?.map((i) => i.toLowerCase())?.includes(appKey?.toLocaleLowerCase());
};

export const determinePickerColor = (color: string | Color) => {
  if (!color) return undefined;
  return typeof color === 'string' ? color : color.toHexString();
};

export const getPartnerTerminology = (partner: ILayoutResponse) => {
  const terminologyMap = new Map();

  for (const [terminologyKey, terminologyValue] of Object.entries(partner?.terminology?.[0] || [])) {
    for (const [terminologyNouns, nounValue] of Object.entries(terminologyValue?.[0])) {
      terminologyMap.set(`${terminologyKey}_${terminologyNouns}`, nounValue);
    }
  }

  return Object.fromEntries(terminologyMap);
};

export const replaceJobWithTerminology = (text: string, terminology?: { [key: string]: string }, singular?: boolean) => {
  const jobDetectorRegex = /\b[jJ][oO][bB]\b/g;
  let replacement = terminology?.['jobs_plural'] ? terminology?.['jobs_plural'] : 'Job';
  if (singular) {
    replacement = terminology?.['jobs_singular'] ? terminology?.['jobs_singular'] : 'Job';
  }
  return text.replace(jobDetectorRegex, replacement);
};

export const convertNestedJSONtoObject = (inputStr: string): any => {
  try {
    const jsonData = JSON.parse(inputStr);

    if (typeof jsonData === 'object') {
      for (const key in jsonData) {
        if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
          jsonData[key] = convertNestedJSONtoObject(jsonData[key]);
        }
      }
    }

    return jsonData;
  } catch (error) {
    return inputStr;
  }
};

export const compareJSONObjects = (newJSON: any, oldJSON: any, parentKey = ''): { path: string; oldValue: string; newValue: string }[] => {
  const differences: { path: string; oldValue: string; newValue: string }[] = [];
  const newValue = convertNestedJSONtoObject(newJSON);
  const oldValue = convertNestedJSONtoObject(oldJSON);

  for (const key in newValue) {
    const fullPath = parentKey ? `${parentKey}.${key}` : key;

    const readablePath = fullPath

      .replace(/[\\[\].0-9]/g, '-')
      .split('----')
      .join('-');

    if (typeof newValue[key] === 'object' && typeof oldValue[key] === 'object') {
      if (Array.isArray(newValue[key]) && Array.isArray(oldValue[key])) {
        // Compare arrays element by element
        for (let i = 0; i < newValue[key].length; i++) {
          differences.push(...compareJSONObjects(newValue[key][i], oldValue[key][i], `${fullPath}[${i}]`));
        }
      } else {
        differences.push(...compareJSONObjects(newValue[key], oldValue[key], fullPath));
      }
    } else {
      if (newValue[key] !== oldValue[key]) {
        differences.push({
          path: readablePath,
          newValue: newValue[key],
          oldValue: oldValue[key],
        });
      }
    }
  }

  return differences;
};

const transformActivityDifferencesToString = (differences: IActivityDifference[], getValue: (difference: IActivityDifference) => string) => {
  return differences
    .map(getValue)
    .filter((value) => value)
    .join(', ');
};

export const transformAuditToActivity = (activity: IAudit): IActivity => {
  const model = activity.auditable_type.split('\\')[activity.auditable_type.split('\\').length - 1];
  const JSONDifferences = compareJSONObjects(activity.new_values || '', activity.old_values || '');
  const fields = JSONDifferences.map((difference) => difference.path.split('_').join(' ')).join(', ');
  const newValues = transformActivityDifferencesToString(JSONDifferences, (difference) => difference.newValue);
  const oldValues = transformActivityDifferencesToString(JSONDifferences, (difference) => difference.oldValue);
  return {
    model,
    fields,
    newValues,
    oldValues,
    event: activity.event,
    id: activity.id,
    userId: activity.user_id,
    user: {
      profilePicture: activity.user_profile_picture,
      firstName: activity.user_first_name,
      lastName: activity.user_last_name,
      email: activity.user_email,
    },
    createdAt: activity.created_at,
  };
};

export const getBusiness = (user: ICurrentUser | IUser) => {
  const businessPlatformDomain = getPlatformDomain();
  const businesses = user.businesses;
  let business = businesses[0];

  if (businessPlatformDomain) {
    let foundBusiness = null;
    businesses?.forEach((business) => {
      const brand = business?.brandings?.find(
        (brand) => brand?.vanity_domain === businessPlatformDomain || brand?.system_domain === businessPlatformDomain,
      );

      if (brand) {
        foundBusiness = business;
      }
    });
    if (foundBusiness) {
      business = foundBusiness;
    }
  }

  return business;
};

export const setPlatformDomain = (domain?: string) => {
  if (!domain || !process.env.REACT_APP_APP_ENV || process.env.REACT_APP_APP_ENV === 'production') return;

  localStorage.setItem('Origin-Local', domain);
};

export const getPlatformDomain = () => {
  const localOrigin = localStorage.getItem('Origin-Local');
  if (process.env.REACT_APP_APP_ENV !== 'production' && localOrigin) {
    return localOrigin;
  }

  return window.location.origin;
};

export const onEntityKanbanDragEnd = (entity: IEntityWithCustomFields, overColumnId: string, columnsFieldId?: string) => {
  const updatedEntityWithCustomFields = entity.custom_fields.map((field) => {
    if (field.selected_options && Array.isArray(field.selected_options)) {
      if (field.field.id === columnsFieldId) {
        return {
          ...field,
          selected_options: field.field.id === columnsFieldId ? [overColumnId] : field.selected_options,
        };
      }
      return {
        ...field,
        selected_options: field.selected_options.map((option) => option.id),
      };
    }
    return field;
  });
  return updatedEntityWithCustomFields;
};

export const getAllMenuSettingsKeys = (menuSettings: IMenuSetting[]): React.Key[] => {
  let keys: React.Key[] = [];
  menuSettings.forEach((item) => {
    keys.push(item.key);
    if (item.children && item.children.length > 0) {
      keys = [...keys, ...getAllMenuSettingsKeys(item.children)];
    }
  });
  return keys;
};

export const extractDomainName = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;

    let domain = hostname.replace(/^(www\.)/, '');

    const parts = domain.split('.');
    if (parts.length > 2) {
      domain = parts.slice(1).join('.');

      const topLevelDomainParts = 2;
      domain = parts.slice(-topLevelDomainParts - 1, -topLevelDomainParts)[0];
    } else if (parts.length === 2) {
      domain = parts[0];
    }
    return domain;
  } catch (error) {
    return url;
  }
};

type MenuItem = Required<MenuProps>['items'][number];

export const getMenuItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onClick?: () => void,
): MenuItem => {
  return {
    key,
    icon,
    children: children?.length ? children : undefined,
    label,
    type,
    onClick,
  } as MenuItem;
};

export const formatDateTimeForMessages = (createdDate: Date | string) => {
  const currentDate = dayjs();
  const targetDate = dayjs(createdDate);

  if (currentDate.isSame(targetDate, 'day')) {
    return targetDate.format('h:mm A');
  }
  return targetDate.format('MMMM D, YYYY h:mm A');
};

export const mapEntityTypesToHash = (array: IEntityType[]): Record<EEntityType, IEntityType> => {
  const entityMap = array.reduce(
    (acc, entity) => {
      acc[entity.name] = entity;
      return acc;
    },
    {} as Record<EEntityType, IEntityType>,
  );
  return entityMap;
};

export const isValidUrl = (value: string) => {
  const pattern = new RegExp(
    '^https?:\\/\\/' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(value);
};

export const hasConsecutivePageBreaks = (array: IBookingFormFieldProps[]) => {
  for (let i = 0; i < array.length - 1; i++) {
    if (array[i].isPageBreak && array[i + 1].isPageBreak) {
      return true;
    }
  }
  return false;
};

export const checkPositionRelativeToMiddle = (num: number, length: number): number => {
  const isEven = length % 2 === 0;
  const halfIndex = isEven ? length / 2 : Math.floor(length / 2) + 1;

  let difference;
  if (isEven) {
    if (num < halfIndex) {
      difference = Math.ceil(halfIndex - num);
    } else {
      difference = Math.ceil(num - halfIndex);
    }
  } else {
    difference = Math.abs(halfIndex - num);
  }

  const isLowerThanHalfValue = isEven ? (-difference - 1) * 100 : -difference * 100;
  const isLastOddElement = length > 4 && difference >= 2;

  if (difference === 0) {
    return isEven ? 0 : 100;
  } else if (halfIndex > num) {
    return isLastOddElement ? isLowerThanHalfValue - 100 : isLowerThanHalfValue;
  } else {
    return isEven ? difference * 200 : isLastOddElement ? difference * 300 - 100 : difference * 300;
  }
};

export const insertNode = (array: Node[], newObject: Node, sourceId: string, targetId?: string): any[] => {
  const newArray = [...array];

  const targetIndex = targetId ? newArray.findIndex((item) => item.id === targetId) : -1;

  if (targetIndex === -1) {
    newArray.push(newObject);
  } else {
    newArray.splice(targetIndex, 0, newObject);
  }

  return newArray;
};

export const transormArrayToObject = (arr: KeyValue[]): { [key: string]: any } => {
  return arr.reduce(
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {} as { [key: string]: any },
  );
};

export function htmlToText(htmlString: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;
  return tempDiv.textContent || tempDiv.innerText || '';
}

export function transformMentions(htmlString: string) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  const spans = tempDiv.querySelectorAll('span[data-linked-resource-type="automationmentioninfo"]');

  spans.forEach((span) => {
    const dataId = span.getAttribute('data-id');
    const triggerId = span.getAttribute('trigger-id');
    const nodeId = span.getAttribute('node-id');
    const dataLabel = span.getAttribute('data-label');
    if (triggerId) span.replaceWith(`{{${triggerId}:response.fields.${dataId}.value}}`);
    else span.replaceWith(`{{${nodeId}:response.${dataLabel}}}`);
  });

  return tempDiv.innerHTML;
}

export function transformFieldMentionsToSpan(text: string, mentions: IAutomationMention[]) {
  return text?.replace(/\{\{([a-f0-9-]+):response\.(?:fields\.([a-f0-9-]+)\.value|([a-zA-Z]+))\}\}/g, (match, triggerId, fieldId) => {
    const getMention = mentions.find((item) => item.id === fieldId && item.triggerId === triggerId);

    if (getMention)
      return `<span data-linked-resource-type="automationmentioninfo" data-id="${fieldId}" data-label="${getMention.name}" trigger-id="${triggerId}" node-id="" class="mention">${getMention.name}</span>`;

    return match;
  });
}

export function transformDefaultMentionToSpan(text: string) {
  return text?.replace(/\{\{([a-f0-9-]+):response\.([a-zA-Z]+)\}\}/g, (match, nodeId, label) => {
    return `<span data-linked-resource-type="automationmentioninfo" data-id="${label}" data-label="${label}" trigger-id="" node-id="${nodeId}" class="mention">${label}</span>`;
  });
}

export function reorderNodes(nodes: Node[], edges: Edge[]) {
  const result: Node[] = [];
  const idMap: Record<string, Node> = {};
  const branchMap: Record<string, string[]> = {};
  const visited = new Set<string>();

  nodes.forEach((node) => {
    idMap[node.id] = node;
    branchMap[node.id] = [];
  });

  edges.forEach((edge) => {
    if (branchMap[edge.source]) {
      branchMap[edge.source].push(edge.target);
    }
  });

  const addNodeWithBranches = (node: Node) => {
    if (visited.has(node.id)) return;
    visited.add(node.id);

    const branches = branchMap[node.id].reverse();
    branches.forEach((branchId) => {
      const branchNode = idMap[branchId];
      if (branchNode) {
        addNodeWithBranches(branchNode);
      }
    });

    result.unshift(node);
  };

  nodes.forEach((node) => {
    if (!visited.has(node.id)) {
      addNodeWithBranches(node);
    }
  });

  return result;
}

export const getRepLinkIdFromCookies = (): string | undefined => {
  const repLinkId = Cookies.get('repLinkId');
  return repLinkId;
};

export const convertRangesToDates = (ranges: { start: string; end: string }[]): Dayjs[] => {
  if (!ranges.length) return [];
  const allDates: Dayjs[] = [];
  ranges.forEach((range) => {
    const startDate = dayjs(range.start);
    const endDate = dayjs(range.end);
    let currentDate = startDate;
    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      allDates.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
  });
  // Sort dates chronologically
  return allDates.sort((a, b) => a.valueOf() - b.valueOf());
};

export const convertDatesToRanges = (dates: Dayjs[]): { start: string; end: string }[] => {
  if (!dates.length) return [];
  // Sort dates chronologically
  const sortedDates = [...dates].sort((a, b) => a.valueOf() - b.valueOf());
  const ranges: { start: string; end: string }[] = [];
  let currentRange: { start: Dayjs; end: Dayjs } | null = null;

  sortedDates.forEach((date, index) => {
    if (!currentRange) {
      currentRange = { start: date, end: date };
    } else {
      const prevDate = sortedDates[index - 1];
      // Check if dates are consecutive and in same month/year
      if (date.diff(prevDate, 'day') === 1 && date.month() === currentRange.start.month() && date.year() === currentRange.start.year()) {
        currentRange.end = date;
      } else {
        // Push completed range and start new one
        ranges.push({
          start: currentRange.start.format('YYYY-MM-DD'),
          end: currentRange.end.format('YYYY-MM-DD'),
        });
        currentRange = { start: date, end: date };
      }
    }
  });

  // Push the last range
  if (currentRange) {
    ranges.push({
      start: (currentRange as { start: Dayjs; end: Dayjs }).start.format('YYYY-MM-DD'),
      end: (currentRange as { start: Dayjs; end: Dayjs }).end.format('YYYY-MM-DD'),
    });
  }

  return ranges;
};

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export function generateQuantityOptions(
  actualQuantity: number,
  minQuantity: number | null | undefined,
  maxQuantity: number | null | undefined,
): number[] {
  if (minQuantity == null && maxQuantity == null) {
    const upperLimit = Math.min(actualQuantity, 50);
    return Array.from({ length: upperLimit + 1 }, (_, i) => i);
  }

  const lowerLimit = minQuantity != null ? Math.max(minQuantity, 1) : 1;
  const upperLimit = maxQuantity != null ? Math.min(maxQuantity, 50, actualQuantity) : Math.min(50, actualQuantity);

  const range = Array.from({ length: upperLimit - lowerLimit + 1 }, (_, i) => lowerLimit + i);

  return [0, ...range];
}
