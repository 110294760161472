import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';

import { Button, Col, Dropdown, Input, Row, Tabs } from 'antd';
import '../automations/Automations.scss';

import { Title } from '@aduvi/components/Title';
import { useBusiness } from '@aduvi/hooks';

import {
  getBuilderCategories,
  getBuilderTemplates,
  setCurrentTemplateState,
  setSelectedProposal,
  setSelectedTemplate,
} from 'store/features/proposals-slice';
import { useAppDispatch } from 'store/hooks';

import { BuilderBlocks } from './tabs/BuilderBlocks/BuilderBlocks';
import { CreateBuilderBlockModal } from './tabs/BuilderBlocks/CreateBuilderBlockModal';
import { Proposals as TabProposal } from './tabs/Proposals/Proposals';
import { CreateTemplateModal } from './tabs/Templates/CreateTemplateModal';
import { Templates } from './tabs/Templates/Templates';

export const Proposals = () => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const selectedBusiness = useBusiness();

  const [tabKey, setTabKey] = useState('1');
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [showBuilderBlockModal, setShowBuilderBlockModal] = useState(false);

  const proposalsTabs = [
    {
      key: '1',
      label: translate('proposals.proposals'),
    },
    {
      key: '2',
      label: translate('proposals.templates'),
    },
    {
      key: '3',
      label: translate('proposals.builderBlocks'),
    },
  ];

  const tabs: Record<string, JSX.Element> = {
    '1': <TabProposal />,
    '2': <Templates />,
    '3': <BuilderBlocks />,
  };

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getBuilderCategories(selectedBusiness?.id));
    dispatch(getBuilderTemplates(selectedBusiness?.id));
  }, [dispatch, selectedBusiness?.id]);

  return (
    <div className={`leads-wrapper`}>
      <Row className='w-full' align={'middle'} justify={'space-between'}>
        <Col span={12} xs={24} md={12} className='w-full'>
          <Row align='middle' className='w-full' style={{ gap: '8px' }}>
            <Col>
              <Title className='aduvi-title mb-0'>{translate('proposals.proposals')}</Title>
            </Col>
            <Col span={16} className='automations-view-tabs-wrapper ml-10'>
              <Tabs
                hideAdd
                className='automations-view-tabs'
                type='editable-card'
                activeKey={tabKey}
                items={proposalsTabs.map((item) => ({ ...item, closable: false }))}
                size='small'
                onChange={setTabKey}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12} xs={24} md={12} className='w-full'>
          <Row justify={'end'} className='w-full'>
            <Row className='mr-10'>
              <Input.Search
                placeholder={
                  tabKey === '1'
                    ? translate('proposals.searchProposals')
                    : tabKey === '2'
                    ? translate('proposals.searchTemplates')
                    : translate('proposals.searchBlocks')
                }
              />
            </Row>
            <Button
              type='primary'
              onClick={() => {
                dispatch(setCurrentTemplateState({ html: '', mainCss: '', sectionCss: '' }));
                dispatch(setSelectedTemplate(undefined));
                dispatch(setSelectedProposal(undefined));
                if (tabKey === '2') {
                  setShowTemplatesModal(true);
                } else if (tabKey === '1') navigate('/proposals/new');
                else {
                  setShowBuilderBlockModal(true);
                }
              }}
              icon={<PlusOutlined />}
              iconPosition='start'>
              {tabKey === '1'
                ? translate('proposals.createProposal')
                : tabKey === '2'
                ? translate('proposals.createTemplate')
                : translate('proposals.createBlock')}
            </Button>
            <Col className='ml-10'>
              <Dropdown menu={{ items: [] }} trigger={['click']} overlayClassName='user-management-dropdown'>
                <Button icon={<EllipsisOutlined style={{ color: 'gray' }} />} type='link' onClick={(e) => e.preventDefault()} />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='pb-20'>{tabs[tabKey]}</div>
      {showTemplatesModal ? <CreateTemplateModal open onClose={() => setShowTemplatesModal(false)} /> : null}
      {showBuilderBlockModal ? <CreateBuilderBlockModal open onClose={() => setShowBuilderBlockModal(false)} /> : null}
    </div>
  );
};
