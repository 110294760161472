import { useTranslation } from 'react-i18next';

import { Col, Form, FormInstance, Input, InputNumber, Row, Select, Switch } from 'antd';
import './ActionFormItems.scss';

import Tiptap from '@aduvi/Tiptap/Tiptap';

export const CreateTask = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item name={'title'} label={translate('automations.title')}>
        <Input />
      </Form.Item>
      <Form.Item name={'description'} label={translate('automations.description')}>
        <Tiptap content={form.getFieldValue('description')} onUpdate={(content) => form.setFieldValue('description', content.getHTML())} />
      </Form.Item>
      <Form.Item name={'users'} label={translate('automations.user(s)')}>
        <Input placeholder={translate('automations.typeOrInsert')} />
      </Form.Item>
      <Row justify={'space-between'} gutter={18}>
        <Col span={12}>
          <Form.Item name={'priority'} label={translate('automations.priority')}>
            <Select placeholder={translate('automations.normal')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'status'} label={translate('automations.status')}>
            <Select placeholder={translate('automations.none')} />
          </Form.Item>
        </Col>
      </Row>
      <div>{translate('automations.deadline')}</div>
      <Row justify={'space-between'} gutter={18} className='mt-10'>
        <Col span={12}>
          <Form.Item name={['deadline', 'deadline']}>
            <Select placeholder={translate('automations.beforeEventDate')} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['deadline', 'time']}>
            <InputNumber className='w-full' placeholder='3' />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={['deadline', 'range']}>
            <Select placeholder={translate('automations.days')} />
          </Form.Item>
        </Col>
      </Row>
      <span className='action-form-items'>
        <Row justify={'space-between'} gutter={18} className='mt-15' align={'middle'}>
          <Col span={16} className='flex flex-direction-row'>
            <Form.Item name={['halt_workout', 'value']}>
              <Switch />
            </Form.Item>
            <span className='mt-5 ml-15'>{translate('automations.haltTheWorkFlow')}</span>
          </Col>
          <Col span={8}>
            <Form.Item name={['halt_workout', 'action']}>
              <Select className='w-full' placeholder={translate('automations.completed')} />
            </Form.Item>
          </Col>
        </Row>
      </span>
    </>
  );
};
