import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import {
  ICreatePackagePayload,
  ICreateServicePayload,
  IDeletePackagePayload,
  IGetPackageByIdPayload,
  IGetServicesPayload,
  IPackage,
  IReorderPackagesPayload,
  IReorderServicesPayload,
  IService,
  IUpdatePackagePayload,
  IUpdateServicePayload,
} from '@aduvi/types/services';
export const getServices = async (payload: IGetServicesPayload): Promise<IPaginatedResponse<IService>> => {
  return await axios.get(`/businesses/${payload.business_id}/services`, { params: payload.params });
};
export const createService = async (payload: ICreateServicePayload): Promise<IResponse<IService>> => {
  return await axios.post(`businesses/${payload.business_id}/services`, payload.body);
};
export const updateService = async (payload: IUpdateServicePayload): Promise<IResponse<IService>> => {
  return await axios.put(`/businesses/${payload.business_id}/services/${payload.service_id}`, payload.body);
};
export const createPackage = async (payload: ICreatePackagePayload): Promise<IResponse<IPackage>> => {
  return await axios.post(`/businesses/${payload.business_id}/services/${payload.service_id}/package`, payload.body);
};
export const updatePackage = async (payload: IUpdatePackagePayload): Promise<IResponse<IPackage>> => {
  return await axios.put(`/businesses/${payload.business_id}/services/${payload.service_id}/package/${payload.package_id}`, payload.body);
};
export const getPackageById = async (payload: IGetPackageByIdPayload): Promise<IResponse<IPackage>> => {
  return await axios.get(`/businesses/${payload.business_id}/services/${payload.service_id}/package/${payload.package_id}`);
};
export const deletePackage = async (payload: IDeletePackagePayload): Promise<void> => {
  return await axios.delete(
    `/businesses/${payload.business_id}/services/${payload.service_id}/package/${payload.package_id}?title=${payload.package_name}`,
  );
};
export const archiveService = async (businessId: string, serviceId: string): Promise<void> => {
  return await axios.put(`/businesses/${businessId}/services/${serviceId}/archive`);
};
export const orderServices = async (payload: IReorderServicesPayload): Promise<IPaginatedResponse<IService>> => {
  return await axios.put(`/businesses/${payload.business_id}/services/reorder/services`, {
    services: payload?.body?.services?.map((service) => ({ id: service.id, order_number: service.order_number })),
  });
};
export const orderPackages = async (payload: IReorderPackagesPayload): Promise<IPaginatedResponse<IPackage>> => {
  return await axios.put(`/businesses/${payload.business_id}/services/${payload.service_id}/reorder-packages`, {
    packages: payload?.body?.packages?.map((packages) => ({ id: packages.id, order_number: packages.order_number })),
  });
};
export const cloneService = async (businessId: string, serviceId: string): Promise<IResponse<IService>> => {
  return await axios.post(`/businesses/${businessId}/services/${serviceId}/clone`);
};
export const clonePackage = async (businessId: string, serviceId: string, packageId: string): Promise<IResponse<IPackage>> => {
  return await axios.post(`/businesses/${businessId}/services/${serviceId}/package/${packageId}/clone`);
};
