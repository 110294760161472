import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, Col, Modal, Row, Space, Typography } from 'antd';
import './DragModal.scss';

import { addWidget } from 'store/features/common-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { WIDGET_LIST, WIDGETS, WidgetType } from '../data';

export const DragModal = () => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { dashboard } = useAppSelector((state) => state.common);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<number>();

  const handleOk = () => {
    const widget = { ...WIDGET_LIST.find((widget) => widget.index === selectedWidget), id: selectedOrder } as WidgetType;
    dispatch(addWidget(widget));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedWidget('');
  };
  return (
    <>
      <div className='drag-drop-modal'>
        <Button className='mb-10 add-widget-button' onClick={() => setIsModalOpen(true)}>
          {translate('dashboard.addWidget')}
        </Button>
        <Row gutter={[16, 16]}>
          {dashboard.data?.map((widget) => (
            <Col xs={{ span: 24 }} lg={{ span: widget.columns * 6 }} key={widget.id}>
              {!widget.index ? (
                <>
                  <div className='add-widget-wrapper'>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                        setSelectedOrder(widget.id);
                      }}>
                      +
                    </span>
                  </div>
                  <div className='widget' />
                </>
              ) : (
                <div className='empty-widget'></div>
              )}
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        className='widgets-modal'
        width={'70%'}
        title='Dashboard Widgets '
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key='button' type='primary' onClick={handleOk}>
            OK
          </Button>,
        ]}>
        <Row gutter={16} wrap={false}>
          {WIDGET_LIST.map((widget) => (
            <Col xs={{ span: 24 }} lg={{ span: widget.columns * 6 }} key={widget.id}>
              <Card
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedWidget(widget.index);
                }}
                className={`widget-card ${selectedWidget === widget.index && 'selected'}`}
                title={
                  <Space className='card-title'>
                    <Typography.Text>{translate(widget.name)}</Typography.Text>
                    <Typography.Text className='widget-size'>{`${widget.columns} x ${widget.rows} `}</Typography.Text>
                  </Space>
                }
                style={{ borderRadius: 15 }}>
                {WIDGETS[widget.index]}
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};
