import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, FormInstance, Row, UploadFile } from 'antd';
import './Build.scss';

import { UpsertDrawer } from '@aduvi/components/ManagementFields/UpsertDrawer';
import { EShoppingBasketPosition } from '@aduvi/constants';
import { useBusiness, useTerminology } from '@aduvi/hooks';
import { EPersonalizedViewOrigin } from '@aduvi/types';
import { EFormLayout } from '@aduvi/types/form';
import { replaceJobWithTerminology } from '@aduvi/utils/helper';

import { getEntityFields } from 'store/features/fields-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { RightSidebar } from './components/RightSidebar';
import { ShoppingBasket } from './components/ShoppingBasket';
import { Widget } from './components/Widget';
import { BookingFormField } from './BookingFormField';
import { useBuild } from './Build.hook';
import { IBookingFormFieldProps } from './helper';

export const Build = ({
  form,
  entityTypeId,
  fields,
  fileList,
  setFileList,
  setFields,
  onAddField,
}: {
  form: FormInstance;
  entityTypeId?: string;
  fields: IBookingFormFieldProps[];
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setFields: (value: React.SetStateAction<IBookingFormFieldProps[]>) => void;
  onAddField: (element: IBookingFormFieldProps, index: number) => void;
}) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();
  const terminology = useTerminology();

  const { fields: entityFields, creating } = useAppSelector((state) => state.fields);
  const { selectedEntityForm } = useAppSelector((state) => state.forms);

  const [openWidget, setOpenWidget] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [entityFieldDrawerStats, setEntityFieldDrawerStats] = useState({
    open: false,
    index: 0,
  });

  const { contentStyle, shoppingBasketPosition, layoutAlignment, onCreateEntityField, onCreateNewFieldDrawer, beforeUpload, onCreateAsset } =
    useBuild({
      entityFieldDrawerStats,
      entityTypeId,
      form,
      selectedEntityForm,
      onAddField,
      setEntityFieldDrawerStats,
    });

  const layout: EFormLayout = Form.useWatch(['form_style', 0, 'layout'], form);

  const isDraggingStyle = useMemo(() => (isDragging ? { rowGap: '20px', paddingBottom: '40px' } : {}), [isDragging]);

  useEffect(() => {
    form.setFieldValue(['form_style', 0, 'background_mode'], selectedEntityForm?.style?.form_style?.[0]?.background_mode);
    form.setFieldValue(['form_style', 0, 'background_image'], selectedEntityForm?.style?.form_style?.[0]?.background_image);
  }, [selectedEntityForm]);

  useEffect(() => {
    if (selectedBusiness?.id && entityTypeId) {
      dispatch(getEntityFields({ businessId: selectedBusiness.id, entityTypeId }));
    }
  }, [selectedBusiness?.id, entityTypeId]);

  return (
    <Col
      className='build-tab-wrapper'
      style={
        isMobileView
          ? {}
          : [
              EFormLayout.BACKGROUND_FULL_CONTENT_LEFT,
              EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
              EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT,
            ].includes(layout)
          ? contentStyle
          : {}
      }>
      {([EFormLayout.BACKGROUND_FULL_CONTENT_LEFT, EFormLayout.BACKGROUND_FULL_CONTENT_CENTER, EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT].includes(
        layout,
      ) ||
        isMobileView) && (
        <Row justify={'center'} style={{ minHeight: 'calc(100vh - 108px)', position: 'relative' }}>
          {openWidget && !isMobileView ? <Widget onCloseWidget={() => setOpenWidget(false)} /> : null}

          <Col className='form-wrapper' span={isMobileView ? 11 : 22} offset={1} style={isMobileView ? contentStyle : {}}>
            {form.getFieldValue(['form_style', 0, 'shopping_basket']) && isMobileView && (
              <ShoppingBasket
                amount={399}
                style={{
                  borderRadius: '50px',
                  color: form.getFieldValue(['form_style', 0, 'primary_font_color']),
                  fontFamily: form.getFieldValue(['form_style', 0, 'font_family']),
                  ...shoppingBasketPosition[form.getFieldValue(['form_style', 0, 'shopping_basket_position']) as EShoppingBasketPosition],
                }}
                className='shopping-basket'
              />
            )}
            {!isMobileView ? (
              <Row justify={'center'} className='mt-20'>
                {!openWidget ? (
                  <Button type='text' className='enable-widget' onClick={() => setOpenWidget(true)}>
                    {translate('forms.forms.enableWidget')}
                  </Button>
                ) : null}
              </Row>
            ) : null}
            <Row
              className='mb-20'
              justify={
                [
                  EFormLayout.BACKGROUND_FULL_CONTENT_LEFT,
                  EFormLayout.BACKGROUND_FULL_CONTENT_CENTER,
                  EFormLayout.BACKGROUND_FULL_CONTENT_RIGHT,
                ].includes(layout)
                  ? layoutAlignment[layout]
                  : 'center'
              }>
              <Col
                className={`booking-form mt-30 ${isMobileView ? 'w-full mr-20 ml-20 ' : ''}`}
                style={{ ...isDraggingStyle, background: form.getFieldValue(['form_style', 0, 'form_background']) }}>
                <BookingFormField
                  form={form}
                  fields={fields}
                  isDragging={isDragging}
                  entityFields={entityFields}
                  fileList={fileList}
                  entityTypeId={entityTypeId}
                  setFields={setFields}
                  onAddField={onAddField}
                  setFileList={setFileList}
                  beforeUpload={beforeUpload}
                  setIsDragging={setIsDragging}
                  onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                  onCreateAsset={onCreateAsset}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {[EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT, EFormLayout.CONTENT_FULL_NO_BACKGROUND, EFormLayout.CONTENT_RIGHT_BACKGROUND_LEFT].includes(
        layout,
      ) &&
        !isMobileView && (
          <Row
            className='form-wrapper'
            style={{ minHeight: 'calc(100vh - 108px)', flexDirection: layout === EFormLayout.CONTENT_LEFT_BACKGROUND_RIGHT ? 'row-reverse' : 'row' }}>
            {layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND && (
              <Col span={12} className='p-10' style={{ minHeight: 'calc(100vh - 108px)', ...contentStyle }}>
                {!openWidget ? (
                  <Row justify={'center'}>
                    <Button type='text' className='enable-widget' onClick={() => setOpenWidget(true)}>
                      {translate('forms.forms.enableWidget')}
                    </Button>
                  </Row>
                ) : null}
                {openWidget && !isMobileView ? <Widget onCloseWidget={() => setOpenWidget(false)} /> : null}
              </Col>
            )}

            <Col
              span={layout !== EFormLayout.CONTENT_FULL_NO_BACKGROUND ? 12 : 24}
              className={`booking-form ${isMobileView ? 'w-full mr-20 ml-20' : ''}`}
              style={{ ...isDraggingStyle, background: form.getFieldValue(['form_style', 0, 'form_background']), borderRadius: 0 }}>
              {layout === EFormLayout.CONTENT_FULL_NO_BACKGROUND && (
                <Row justify={'center'} className='p-10'>
                  {!openWidget ? (
                    <Row justify={'center'}>
                      <Button type='text' className='enable-widget' onClick={() => setOpenWidget(true)}>
                        {translate('forms.forms.enableWidget')}
                      </Button>
                    </Row>
                  ) : null}
                  {openWidget && !isMobileView ? <Widget onCloseWidget={() => setOpenWidget(false)} /> : null}
                </Row>
              )}
              <BookingFormField
                form={form}
                fields={fields}
                isDragging={isDragging}
                entityFields={entityFields}
                fileList={fileList}
                entityTypeId={entityTypeId}
                setFields={setFields}
                onAddField={onAddField}
                setFileList={setFileList}
                beforeUpload={beforeUpload}
                setIsDragging={setIsDragging}
                onCreateNewFieldDrawer={onCreateNewFieldDrawer}
                onCreateAsset={onCreateAsset}
              />
            </Col>
          </Row>
        )}
      {form.getFieldValue(['form_style', 0, 'shopping_basket']) && !isMobileView && (
        <ShoppingBasket
          amount={399}
          style={{
            borderRadius: '50px',
            color: form.getFieldValue(['form_style', 0, 'primary_font_color']),
            fontFamily: form.getFieldValue(['form_style', 0, 'font_family']),
            ...shoppingBasketPosition[form.getFieldValue(['form_style', 0, 'shopping_basket_position']) as EShoppingBasketPosition],
          }}
          className='shopping-basket'
        />
      )}
      <Col span={1} className='icons-container'>
        <RightSidebar form={form} onMobileView={() => setIsMobileView((prevState) => !prevState)} isMobileView={isMobileView} />
      </Col>
      {entityFieldDrawerStats.open && (
        <UpsertDrawer
          title={replaceJobWithTerminology(translate('settings.menu.createField'), terminology, true)}
          showDrawer={entityFieldDrawerStats.open}
          data={undefined}
          loadingAction={creating}
          permissionToEdit={undefined}
          showFieldVisibility={false}
          origin={EPersonalizedViewOrigin.FORMS}
          onCreate={onCreateEntityField}
          onUpdate={() => void 0}
          onCancel={() => {
            setEntityFieldDrawerStats({
              open: false,
              index: 0,
            });
          }}
        />
      )}
    </Col>
  );
};
