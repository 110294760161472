import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';
import { InputPrice } from '@aduvi/components/InputPrice/InputPrice';

export const Pricing = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();

  const [pricingSettings, setPricingSetting] = useState({
    is_on_sale: false,
  });

  useEffect(() => {
    setPricingSetting({
      is_on_sale: !!form.getFieldValue('is_on_sale'),
    });
  }, []);

  return (
    <Col span={24} className='mt-15 w-full'>
      <Form.Item name='price' label={translate('assets.ticketDrawer.pricing.price')}>
        <InputPrice className='w-full' placeholder={translate('assets.ticketDrawer.pricing.placeholder.price')} />
      </Form.Item>

      <Row>
        <Form.Item className='mb-5' name='is_on_sale' valuePropName='checked' initialValue={form.getFieldValue('is_on_sale')}>
          <Checkbox
            className='mb-15'
            onChange={(e) => {
              setPricingSetting((prev) => ({ ...prev, [e.target.id!]: e.target.checked }));
            }}>
            {translate('assets.ticketDrawer.pricing.sale')}
          </Checkbox>
        </Form.Item>
      </Row>

      {pricingSettings.is_on_sale && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='sale_price' label={translate('assets.ticketDrawer.pricing.salePrice')}>
                <InputPrice className='w-full' placeholder={translate('assets.ticketDrawer.pricing.placeholder.salePrice')} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name='sale_end_date' label={translate('assets.ticketDrawer.pricing.saleEndDate')}>
                <DatePicker className='w-full' />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};
