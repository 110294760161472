export const LayoutIconRows = ({ active = false }: { active?: boolean }) => {
  return (
    <svg width='60' height='62' viewBox='0 0 60 62' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_188068_40770)'>
        <rect width='60' height='60' rx='2' fill='white' />
        <rect x='0.5' y='0.5' width='59' height='59' rx='1.5' stroke={active ? '#1890FF' : '#D9D9D9'} />
        <rect x='24' y='12' width='28' height='5' rx='2' fill='#828282' fillOpacity='0.15' />
        <rect x='5' y='11.5' width='15' height='15' rx='2' fill='#828282' fillOpacity='0.15' />
        <rect x='24' y='20' width='23' height='5' rx='2' fill='#626262' fillOpacity='0.1' />
        <rect x='5' y='29.5' width='50' height='1' rx='0.5' fill='#626262' fillOpacity='0.15' />
        <rect x='24' y='34' width='28' height='5' rx='2' fill='#828282' fillOpacity='0.15' />
        <rect x='5' y='33.5' width='15' height='15' rx='2' fill='#828282' fillOpacity='0.15' />
        <rect x='24' y='42' width='23' height='5' rx='2' fill='#626262' fillOpacity='0.1' />
      </g>
      <defs>
        <filter id='filter0_d_188068_40770' x='0' y='0' width='60' height='62' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='2' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.016 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_188068_40770' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_188068_40770' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
