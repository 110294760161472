import React, { useState } from 'react';

import { Select, SelectProps } from 'antd';

import { PERMISSIONS } from '@aduvi/constants';
import { useBusiness, useDebounce, useUserPermissionCheck } from '@aduvi/hooks';

import { useGetProductsQuery } from 'store/rtk-slice/product-slice';

export const SelectProduct = (props: SelectProps) => {
  const selectedBusiness = useBusiness();

  const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
  const debouncedSearchKey = useDebounce(searchKey) || undefined;

  const hasPermissionToViewProducts = useUserPermissionCheck(PERMISSIONS.PRODUCTS.VIEW);

  const { data: products, isFetching } = useGetProductsQuery(
    { business_id: selectedBusiness?.id as string, params: { page: 1, size: 10, search: debouncedSearchKey } },
    { skip: !selectedBusiness?.id || !hasPermissionToViewProducts },
  );

  return (
    <Select
      {...props}
      showSearch
      loading={isFetching}
      filterOption={false}
      onSearch={(term) => setSearchKey(term)}
      options={products?.data?.map((item) => ({ label: item.name, value: item.id }))}
    />
  );
};
