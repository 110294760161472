import originalAxios, { AxiosResponse } from 'axios';

import axios from '@aduvi/config/axios';
import { IGetUnsplashPicturesParams, IPixabayResponse, IPixabaySearchParams, IResponse, IUnsplashPicture } from '@aduvi/types';
import { IFile, IFolder, IShareFile, IShareFileAndFolderPayload, IShareFolder, IUpdateFilePayload, IUpsertFolderPayload } from '@aduvi/types/file';

export const createFolder = async (businessId: string, payload: IUpsertFolderPayload): Promise<IResponse<IFolder>> => {
  return await axios.post(`/businesses/${businessId}/folders`, payload);
};

export const getFolders = async (businessId: string): Promise<IResponse<IFolder[]>> => {
  return await axios.get(`/businesses/${businessId}/folders`);
};

export const updateFolder = async (businessId: string, folderId: string, payload: IUpsertFolderPayload): Promise<IResponse<IFolder>> => {
  return await axios.put(`/businesses/${businessId}/folders/${folderId}`, payload);
};

export const deleteFolder = async (businessId: string, folderId: string, folderName: string): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/folders/${folderId}?title=${folderName}`);
};

export const createFile = async (businessId: string, payload: FormData): Promise<IResponse<IFile>> => {
  return await axios.post(`/businesses/${businessId}/files`, payload);
};

export const getFilesByFolderId = async (businessId: string, folderId: string): Promise<IResponse<IFile[]>> => {
  return await axios.get(`/businesses/${businessId}/files/folders/${folderId}`);
};

export const downloadFile = async (businessId: string, fileId: string): Promise<Blob> => {
  return await axios.get(`/businesses/${businessId}/files/${fileId}?download=true`, { responseType: 'blob' });
};

export const updateFile = async (businessId: string, fileId: string, payload: IUpdateFilePayload): Promise<IResponse<IFile>> => {
  return await axios.put(`/businesses/${businessId}/files/${fileId}`, payload);
};

export const deleteFile = async (businessId: string, fileId: string, fileName: string): Promise<void> => {
  return await axios.delete(`/businesses/${businessId}/files/${fileId}?title=${fileName}`);
};

export const cloneFile = async (businessId: string, fileId: string): Promise<IResponse<IFile>> => {
  return await axios.post(`/businesses/${businessId}/files/${fileId}/clone`, {});
};

export const shareFile = async (businessId: string, fileId: string, payload: IShareFileAndFolderPayload): Promise<IResponse<IShareFile>> => {
  return await axios.post(`/businesses/${businessId}/files/share/${fileId}`, payload);
};

export const shareFolder = async (businessId: string, folderId: string, payload: IShareFileAndFolderPayload): Promise<IResponse<IShareFolder>> => {
  return await axios.post(`/businesses/${businessId}/folders/share/${folderId}`, payload);
};

export const getSharedFiles = async (businessId: string): Promise<IResponse<IFile[]>> => {
  return await axios.get(`/businesses/${businessId}/files/shared`);
};

export const getSharedFolders = async (businessId: string): Promise<IResponse<IFolder[]>> => {
  return await axios.get(`/businesses/${businessId}/folders/shared`);
};

export const getAllFiles = async (businessId: string): Promise<IResponse<IFile[]>> => {
  return await axios.get(`/businesses/${businessId}/files`);
};

export const getUnsplashPictures = async (
  params?: IGetUnsplashPicturesParams,
): Promise<AxiosResponse<IUnsplashPicture[] | { results: IUnsplashPicture[] }>> => {
  return await originalAxios
    .create({
      baseURL: 'https://api.unsplash.com',
      headers: { Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}` },
    })
    .get(`${params?.query ? '/search/photos' : '/photos'}`, { params });
};

export const getPixabayPictures = async (params: IPixabaySearchParams): Promise<IPixabayResponse> => {
  const queryParams = { ...params, key: process.env.REACT_APP_PIXABAY_KEY };

  const response = await originalAxios.get<IPixabayResponse>('https://pixabay.com/api/', { params: queryParams });
  return response.data;
};
