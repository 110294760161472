import axios from '@aduvi/config/axios';
import { IPaginatedResponse, IResponse } from '@aduvi/types';
import {
  ICreateProductPayload,
  IDeleteProductPayload,
  IEditProductPayload,
  IGetProductByIdPayload,
  IGetProductsPayload,
  IProduct,
  IProductCategory,
} from '@aduvi/types/products';

export const createProduct = async (payload: ICreateProductPayload): Promise<IResponse<IProduct>> => {
  return await axios.post(`/businesses/${payload.business_id}/products`, payload);
};
export const getProducts = async (payload: IGetProductsPayload): Promise<IPaginatedResponse<IProduct>> => {
  return await axios.get(`/businesses/${payload.business_id}/products`, { params: payload.params });
};
export const getProductCategories = async (business_id: string): Promise<IResponse<IProductCategory[]>> => {
  return await axios.get(`/businesses/${business_id}/product-categories`);
};
export const createProductCategory = async (business_id: string, name: string): Promise<IResponse<IProductCategory>> => {
  return await axios.post(`/businesses/${business_id}/product-categories`, { name });
};
export const editProduct = async (payload: IEditProductPayload): Promise<IResponse<IProduct>> => {
  return await axios.put(`/businesses/${payload.business_id}/products/${payload.product_id}`, payload.body);
};
export const getProductById = async (payload: IGetProductByIdPayload): Promise<IResponse<IProduct>> => {
  return await axios.get(`/businesses/${payload.business_id}/products/${payload.product_id}`);
};
export const deleteProduct = async (payload: IDeleteProductPayload): Promise<void> =>
  axios.delete(`/businesses/${payload.business_id}/products/${payload.product_id}?title=${payload.product_name}`);
export const cloneProduct = async (businessId: string, productId: string): Promise<IResponse<IProduct>> => {
  return await axios.post(`/businesses/${businessId}/products/${productId}/clone`);
};
