import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Button, Card, Carousel, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { useBusiness } from '@aduvi/hooks';
import { IProduct } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getProductCategories, getProducts, resetProductState } from 'store/features/products-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const productFields = ['Picture', 'Title', 'Price', 'Description', 'Button'];

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [isMobileView, setIsMobileView] = useState(false);
  const { t: translate } = useTranslation();

  const { products, productCategories, loading } = useAppSelector((state) => state.products);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || [];

  const entitySettings = (
    <div>
      <Typography.Text type='secondary'>{translate('widgets.productCategory')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'product_category']}>
        <Select
          placeholder={translate('widgets.selectProductCategory')}
          value={form.getFieldValue(['widget_style', 0, 'product_category'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'product_category'], value);
          }}>
          <Select.Option key='all' value='all'>
            {translate('widgets.all')}
          </Select.Option>
          {productCategories?.data?.map((category) => (
            <Select.Option key={category.id} value={category.id}>
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.sort')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'sort_products']}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'sort_products'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'sort_products'], value);
          }}>
          <Select.Option value='Price'>{translate('widgets.price')}</Select.Option>
          <Select.Option value='Name'>{translate('widgets.name')}</Select.Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonAction')}</Typography.Text>
      <Form.Item className='mb-10' name={['widget_style', 0, 'button_action']}>
        <Select
          value={form.getFieldValue(['widget_style', 0, 'button_action'])}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'button_action'], value);
          }}>
          <Select.Option value='Add to booking'>{translate('widgets.addToBooking')}</Select.Option>
          <Select.Option value='Create order'>{translate('widgets.createOrder')}</Select.Option>
        </Select>
      </Form.Item>

      <Typography.Text type='secondary'>{translate('widgets.buttonText')}</Typography.Text>
      <Form.Item name={['widget_style', 0, 'button_text']}>
        <Input
          value={form.getFieldValue(['widget_style', 0, 'button_text'])}
          onChange={(e) => {
            form.setFieldValue(['widget_style', 0, 'button_text'], e.target.value);
          }}
        />
      </Form.Item>
    </div>
  );

  const displayedProducts = useMemo(() => {
    let filteredProducts = [...(products?.data || [])];

    const selectedCategory = form.getFieldValue(['widget_style', 0, 'product_category']);
    if (selectedCategory && selectedCategory !== 'all') {
      filteredProducts = filteredProducts.filter((product) => product.business_product_category_id === selectedCategory);
    }

    const sortOption = form.getFieldValue(['widget_style', 0, 'sort_products']);
    if (sortOption === 'Price') {
      filteredProducts = filteredProducts.sort((a, b) => {
        const priceA = a.price ?? 0;
        const priceB = b.price ?? 0;
        return priceA - priceB;
      });
    } else if (sortOption === 'Name') {
      filteredProducts = filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (limitResults === 'show_all') {
      return filteredProducts;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return filteredProducts.slice(0, resultsPerPage);
    }
    return filteredProducts;
  }, [
    products?.data,
    form.getFieldValue(['widget_style', 0, 'product_category']),
    form.getFieldValue(['widget_style', 0, 'sort_products']),
    limitResults,
    resultsPerPage,
  ]);

  const handleButtonClick = (product: IProduct) => {
    const action = form.getFieldValue(['widget_style', 0, 'button_action']);

    if (action === 'Add to booking') {
      window.open(`/client-portal/login?addProductToBooking=${product.id}`, '_blank');
    } else if (action === 'Create order') {
      window.open(`/create-order?productId=${product.id}`, '_blank');
    }
  };

  const onGetProducts = useCallback(() => {
    if (!selectedBusiness?.id) return;
    dispatch(resetProductState());
    dispatch(
      getProducts({
        business_id: selectedBusiness?.id,
        params: {
          page: 1,
          size: 50,
          search: '',
        },
      }),
    );
    dispatch(getProductCategories(selectedBusiness?.id));
  }, [selectedBusiness?.id, dispatch]);

  useEffect(() => {
    onGetProducts();
  }, [selectedBusiness?.id, onGetProducts]);

  return (
    <Spin spinning={loading}>
      <Row className='products-design-tab-wrapper' style={backgroundStyle}>
        <Col span={14}>
          <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
            {selectedLayout === EWidgetLayout.CARDS ? (
              <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
                {displayedProducts?.map((product, index) => (
                  <div key={index} className='product-card'>
                    {visibleFields.includes('Picture') && <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {product.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        ${product.price}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(product)}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </div>
                ))}
              </Carousel>
            ) : selectedLayout === EWidgetLayout.COLUMNS ? (
              <Row gutter={[20, 20]}>
                {displayedProducts?.map((product, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                    <div className='product-card'>
                      {visibleFields.includes('Picture') && <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />}
                      {visibleFields.includes('Title') && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {product.name}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Price') && (
                        <Typography.Paragraph
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          ${product.price}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Description') && (
                        <Typography.Paragraph
                          type='secondary'
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Button') && (
                        <Button
                          type='primary'
                          onClick={() => handleButtonClick(product)}
                          style={{
                            background: form.getFieldValue(['widget_style', 0, 'button_background']),
                            color: form.getFieldValue(['widget_style', 0, 'button_font']),
                          }}>
                          {form.getFieldValue(['widget_style', 0, 'button_text'])}
                        </Button>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            ) : (
              displayedProducts?.map((product, index) => (
                <Row key={index} gutter={[20, 20]} align='middle' className='products-card-row'>
                  {visibleFields.includes('Picture') && (
                    <Col xs={24} sm={6} className='product-image'>
                      <img alt={product.name} src={product.image} width={100} height={100} className='mb-10' />
                    </Col>
                  )}
                  <Col xs={24} sm={18} className='product-details'>
                    {visibleFields.includes('Title') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {product.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        ${product.price}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: product.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(product)}
                        style={{
                          background: form.getFieldValue(['widget_style', 0, 'button_background']),
                          color: form.getFieldValue(['widget_style', 0, 'button_font']),
                        }}>
                        {form.getFieldValue(['widget_style', 0, 'button_text'])}
                      </Button>
                    )}
                  </Col>
                </Row>
              ))
            )}
          </Card>
        </Col>
        <Col span={1} className='icons-container'>
          <RightSidebar
            form={form}
            onMobileView={() => setIsMobileView((prevState) => !prevState)}
            isMobileView={isMobileView}
            fields={productFields}
            entitySettings={entitySettings}
          />
        </Col>
      </Row>
    </Spin>
  );
};
