import { Select, SelectProps } from 'antd';

import { useAppSelector } from 'store/hooks';

export const SelectBrand = (props: SelectProps) => {
  const { selectedBusiness, loading } = useAppSelector((state) => state.business);

  return (
    <Select
      {...props}
      showSearch
      disabled={loading}
      loading={loading}
      filterOption={(input, option) => !!option?.children?.toString().toLowerCase().includes(input.toLowerCase())}>
      {selectedBusiness?.brandings?.map((brand, index) => (
        <Select.Option key={index} value={brand.id}>
          {brand?.name}
        </Select.Option>
      ))}
    </Select>
  );
};
