import dayjs from 'dayjs';

import { DatePicker as ANTDatePicker, DatePickerProps } from 'antd';

import { useBusiness } from '../../hooks';

export const DatePicker = ({ value, defaultValue, ...props }: DatePickerProps) => {
  const selectedBusiness = useBusiness();

  return (
    <ANTDatePicker
      format={selectedBusiness?.date_format || undefined}
      defaultValue={defaultValue && typeof defaultValue === 'string' ? dayjs(defaultValue) : dayjs.isDayjs(defaultValue) ? defaultValue : undefined}
      value={value && typeof value === 'string' ? dayjs(value) : dayjs.isDayjs(value) ? value : undefined}
      {...props}
    />
  );
};
