import { Layout, Skeleton } from 'antd';
import './Content.scss';

export const Content = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <Layout.Content className='aduvi-mobile-skeleton-content-layout' style={{ ...style }}>
      <Skeleton active className='ant-content-skeleton' />;
    </Layout.Content>
  );
};
