import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import {
  ICreateTaxRatePayload,
  ICreateTaxSettingsPayload,
  IDeleteTaxRatePayload,
  ITaxRate,
  ITaxRateState,
  IUpdateTaxRatePayload,
  IUpdateTaxSettingPayload,
} from '@aduvi/types';

import { apiSlice } from 'store/api/apiSlice';
import * as TaxService from 'store/services/tax.service';

const initialState: ITaxRateState = {
  rates: {
    selectedRate: undefined,
    taxRates: [],
    loading: false,
    creating: false,
    updating: false,
  },
  settings: {
    taxSettings: undefined,
    loading: false,
    creating: false,
    updating: false,
  },
};

export const getTaxRates = createAsyncThunk('get-tax-rates', async (business_id: string, { rejectWithValue }) => {
  try {
    return await TaxService.getTaxRates(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const deleteTaxRate = createAsyncThunk('delete-tax-rate', async (params: IDeleteTaxRatePayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TAX }]));
    return await TaxService.deleteTaxRate(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const createTaxRate = createAsyncThunk('create-tax-rate', async (params: ICreateTaxRatePayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TAX }]));
    return await TaxService.createTaxRate(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const updateTaxRate = createAsyncThunk('update-tax-rate', async (params: IUpdateTaxRatePayload, { rejectWithValue, dispatch }) => {
  try {
    dispatch(apiSlice.util.invalidateTags([{ type: SLICE_TAG_TYPES.TAX }]));
    return await TaxService.updateTaxRate(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const getTaxSettings = createAsyncThunk('get-tax-settings', async (business_id: string, { rejectWithValue }) => {
  try {
    return await TaxService.getTaxSettings(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const createTaxSettings = createAsyncThunk('create-tax-settings', async (params: ICreateTaxSettingsPayload, { rejectWithValue }) => {
  try {
    return await TaxService.createTaxSettings(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const updateTaxSettings = createAsyncThunk('update-tax-settings', async (params: IUpdateTaxSettingPayload, { rejectWithValue }) => {
  try {
    return await TaxService.updateTaxSettings(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const taxSlice = createSlice({
  name: 'taxRates',
  initialState,
  reducers: {
    setSelectedTaxRate: (state, action: PayloadAction<ITaxRate | undefined>) => {
      state.rates.selectedRate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxRates.pending, (state) => {
        state.rates.loading = true;
      })
      .addCase(getTaxRates.fulfilled, (state, { payload }) => {
        state.rates.loading = false;
        state.rates.taxRates = payload.data;
      })
      .addCase(getTaxRates.rejected, (state) => {
        state.rates.loading = false;
      })
      .addCase(createTaxRate.pending, (state) => {
        state.rates.creating = true;
      })
      .addCase(createTaxRate.fulfilled, (state, { payload }) => {
        state.rates.creating = false;
        if (state.rates.taxRates) {
          state.rates.taxRates = [...state.rates.taxRates, payload.data];
        }
      })
      .addCase(createTaxRate.rejected, (state) => {
        state.rates.creating = false;
      })
      .addCase(updateTaxRate.pending, (state) => {
        state.rates.updating = true;
      })
      .addCase(updateTaxRate.fulfilled, (state, { payload }) => {
        state.rates.updating = false;
        if (state.rates.taxRates) {
          state.rates.taxRates = state.rates.taxRates.map((taxRate) => (taxRate.id === payload.data.id ? payload.data : taxRate));
        }
        if (state.rates.selectedRate?.id === payload.data.id) {
          state.rates.selectedRate = payload.data;
        }
      })
      .addCase(updateTaxRate.rejected, (state) => {
        state.rates.updating = false;
      })
      .addCase(deleteTaxRate.pending, (state) => {
        state.rates.loading = true;
      })
      .addCase(deleteTaxRate.fulfilled, (state, { meta }) => {
        state.rates.loading = false;
        if (state.rates.taxRates) {
          state.rates.taxRates = state.rates.taxRates.filter((item) => item.id !== meta.arg.tax_rate_id);
        }
      })
      .addCase(deleteTaxRate.rejected, (state) => {
        state.rates.loading = false;
      })
      .addCase(getTaxSettings.pending, (state) => {
        state.settings.loading = true;
      })
      .addCase(getTaxSettings.fulfilled, (state, { payload }) => {
        state.settings.loading = false;
        state.settings.taxSettings = payload.data;
      })
      .addCase(getTaxSettings.rejected, (state) => {
        state.settings.loading = false;
      })
      .addCase(createTaxSettings.pending, (state) => {
        state.settings.creating = true;
      })
      .addCase(createTaxSettings.fulfilled, (state, { payload }) => {
        state.settings.creating = false;
        if (state.settings.taxSettings) {
          state.settings.taxSettings = { ...state.settings.taxSettings, ...payload.data };
        }
      })
      .addCase(createTaxSettings.rejected, (state) => {
        state.settings.creating = false;
      })
      .addCase(updateTaxSettings.pending, (state) => {
        state.settings.updating = true;
      })
      .addCase(updateTaxSettings.fulfilled, (state, { payload }) => {
        state.settings.updating = false;
        if (state.settings.taxSettings?.id === payload.data.id) {
          state.settings.taxSettings = payload.data;
        }
      })
      .addCase(updateTaxSettings.rejected, (state) => {
        state.settings.updating = false;
      });
  },
});

export const taxReducer = taxSlice.reducer;
export const { setSelectedTaxRate } = taxSlice.actions;
