import { useNavigate } from 'react-router-dom';

import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusiness } from '@aduvi/hooks';

import { createBuilderBlock } from 'store/features/proposals-slice';
import { useAppDispatch } from 'store/hooks';

import { SelectTemplateCategory } from '../Templates/SelectTemplateCategory';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const CreateBuilderBlockModal = ({ open, onClose }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const selectedBusiness = useBusiness();

  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        if (!selectedBusiness?.id) return;
        dispatch(
          createBuilderBlock({
            business_id: selectedBusiness?.id,
            body: {
              block_name: form.getFieldValue('block_name'),
              builder_category_ids: form.getFieldValue('builder_category_ids'),
              file_reference: {
                image: '',
                type: 'html',
                content: `<div class="is-section is-section-100 is-box type-system-ui last-box min-height-60 scroll-target initial-div-class">
    <div class="is-overlay">

    </div>
    <div class="is-container v2 size-18 leading-14 is-content-1300">
        <div class="row">
            <div class="column">
                <h1 class="leading-11 size-88 text-center">Start building...</h1>
            </div>
        </div>
    </div>
</div>
<link data-name="contentstyle" data-class="type-system-ui" href="assets/styles/type-system-ui.css" rel="stylesheet">`,
              },
            },
          }),
        )
          .unwrap()
          .then((res) => navigate(`/block-builder?blockId=${res.data?.id}`))
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <Modal open={open} title='Create block' okText='Create' onClose={onClose} onOk={onSubmit} onCancel={onClose}>
      <Form form={form} requiredMark={false} layout='vertical' className='mt-30'>
        <Form.Item name={'block_name'} label='Page title' rules={[{ required: true, message: 'Field is required!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name={'builder_category_ids'} label='Category' rules={[{ required: true, message: 'Field is required!' }]}>
          <SelectTemplateCategory mode='multiple' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
