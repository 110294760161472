import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib';

import { useManagementFields } from '@aduvi/hooks/useManagementFields';
import { EFieldDataValueType, EOrderType, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType } from '@aduvi/types/entity';

const inputEntityFields = [
  EFieldDataValueType.FIELD_DATA_EMAILS,
  EFieldDataValueType.FIELD_DATA_LONG_TEXTS,
  EFieldDataValueType.FIELD_DATA_NUMBERS,
  EFieldDataValueType.FIELD_DATA_PHONES,
  EFieldDataValueType.FIELD_DATA_TEXTS,
  EFieldDataValueType.FIELD_DATA_URLS,
];

const AddressBook = ({ form, entityType, origin }: { form: FormInstance; origin: EPersonalizedViewOrigin; entityType?: IEntityType }) => {
  const { t: translate } = useTranslation();

  const { fields } = useManagementFields({ entityType, origin });

  const inputFields = fields
    .filter((item) => inputEntityFields.includes(item.value_type))
    ?.map((managementField) => ({ label: managementField?.title, value: managementField?.id }));

  useEffect(() => {
    form.setFieldsValue({
      default_date_values: form.getFieldValue('default_date_values') || inputFields?.[0]?.value,
      default_sort_order: form.getFieldValue('default_sort_order') || EOrderType.ASC,
    });
  }, []);

  return (
    <Col className='w-full'>
      <label>{translate('leads.groupBy')}</label>
      <Row className='w-full mt-10' justify={'space-between'}>
        <Form.Item name='default_date_values' style={{ width: '49%' }}>
          <Select options={inputFields} placeholder={translate('leads.firstName')} />
        </Form.Item>
        <Form.Item name='default_sort_order' style={{ width: '49%' }}>
          <Select
            placeholder={translate('leads.ascending')}
            options={Object.values(EOrderType)?.map((value) => {
              return {
                label: value,
                value,
              };
            })}
          />
        </Form.Item>
      </Row>
    </Col>
  );
};

export default AddressBook;
