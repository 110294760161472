import React, { createContext, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Pusher, { Channel } from 'pusher-js';

import { useBusiness } from '@aduvi/hooks';
import { ESupportChatPusherEvents, IChatMessage } from '@aduvi/types';

import { appendMessage } from 'store/features/support-chat-slice';
import { useAppSelector } from 'store/hooks';

interface IProps {
  children: ReactNode;
}

const PusherContext = createContext<Pusher | undefined>(undefined);

export const SupportChatSocketProvider: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const selectedBusiness = useBusiness();
  const { selectedSupportChat } = useAppSelector((state) => state.supportChat);

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
  });

  useEffect(() => {
    let channelSubscriptions: Channel[] = [];

    if (selectedSupportChat?.id) {
      const channel = pusher.subscribe(selectedSupportChat?.id);
      channelSubscriptions.push(channel);

      channel.bind(ESupportChatPusherEvents.MESSAGE_SENT, (data: { message: IChatMessage }) => {
        dispatch(appendMessage(data.message));
      });
    }

    return () => {
      channelSubscriptions.forEach((channel) => {
        channel.unbind_all();
        channel.unsubscribe();
      });
    };
  }, [selectedSupportChat?.id, selectedBusiness?.id]);

  return <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>;
};
