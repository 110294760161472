import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ISupportCallTrackingPayload, ISupportCallTrackingState } from '@aduvi/types/support-call-tracking';

import * as SupportCallTracking from 'store/services/call-tracking.service';

const initialState: ISupportCallTrackingState = {
  loading: false,
};

export const createSupportCalls = createAsyncThunk(
  'support-call-tracking/create-support-calls',
  async ({ payload }: { payload: ISupportCallTrackingPayload }, { rejectWithValue }) => {
    try {
      return await SupportCallTracking.createSupportCall(payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const supportCallTrackingSlice = createSlice({
  name: 'support-call-tracking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSupportCalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSupportCalls.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSupportCalls.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const supportCallTrackingReducer = supportCallTrackingSlice.reducer;
