import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Button, Card, Col, Form, Radio, Row, Space, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './Quiz.scss';

import { useBusiness } from '@aduvi/hooks';

import { setEvaluatedData } from 'store/features/academy-slice';
import { useAppDispatch } from 'store/hooks';
import { useEvaluateQuizMutation, useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

export const Quiz = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { id } = useParams();

  const { data: academies, isFetching: loading } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const [evaluateQuiz, { isLoading: evaluating }] = useEvaluateQuizMutation();

  const selectedAcademy = useMemo(() => {
    return academies?.data.find((item) => item?.id === id);
  }, [id, academies?.data.length]);

  Form.useWatch('answers', form);

  const onSubmitAnswers = () => {
    if (!selectedBusiness?.id || !selectedAcademy?.id) return;
    evaluateQuiz({ businessId: selectedBusiness?.id, academyId: selectedAcademy?.id, answers: form.getFieldValue('answers') })
      .unwrap()
      .then(({ data }) => {
        dispatch(setEvaluatedData(data));
      })
      .then(() => navigate(`/academy/${selectedAcademy?.id}/result`))
      .catch(() => {});
  };

  return (
    <Form form={form} name='answers'>
      <Row className='pt-50 pl-50'>
        <Spin spinning={loading}>
          <Card className='level-card' style={{ width: '600px', height: '600px', borderRadius: ' 2px 0px 0px 0px', border: '1px 0px 0px 0px' }}>
            <Row className='level-quiz-content'>
              <Space direction='vertical'>
                <Typography.Title level={3}>{selectedAcademy?.name}</Typography.Title>
                <Typography.Text type='secondary'>{translate('academy.answerTheQuestions')}</Typography.Text>
                <Row className='mb-10'>
                  <Typography.Text type='secondary'>
                    {translate('academy.needMoreTime')}{' '}
                    <Link to={`/academy/${selectedAcademy?.id}`}> {translate('academy.watchTheVideoAgain')} </Link>
                  </Typography.Text>
                </Row>

                {selectedAcademy?.academy_questions.map((question, index) => (
                  <Col key={question.id} className='mb-20'>
                    <Form.Item hidden name={['answers', index, 'question_id']} initialValue={question.id}></Form.Item>
                    <Typography.Text style={{ color: '#1890FF', display: 'flex' }} className='mb-10'>
                      {question.question}
                    </Typography.Text>
                    <Row style={{ flexDirection: 'column' }}>
                      {question.academy_question_answer_options.map((answer) => (
                        <Form.Item name={['answers', index, 'answer_id']} style={{ height: '15px' }} key={answer.id}>
                          <Radio.Group>
                            <Radio value={answer.id}>{answer.answer}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      ))}
                    </Row>
                  </Col>
                ))}
              </Space>
            </Row>

            <Row className='w-full mb-20' justify={'center'}>
              <Button type='primary' onClick={onSubmitAnswers} loading={evaluating} style={{ position: 'absolute', bottom: 15, width: '500px' }}>
                {translate('academy.submitAnswers')}
              </Button>
            </Row>
          </Card>
        </Spin>
      </Row>
    </Form>
  );
};
