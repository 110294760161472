import { useEffect, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { EnvironmentOutlined } from '@ant-design/icons';
import { countryToAlpha2 } from 'country-to-iso';
import { useDesign } from 'views/widgets/@components/design-hook/Design.hook';
import { RightSidebar } from 'views/widgets/@components/right-sidebar/RightSidebar';

import { Card, Carousel, Col, Form, Row, Select, Typography } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import './Design.scss';

import { useBusiness } from '@aduvi/hooks';
import { ILocation } from '@aduvi/types';
import { EWidgetLayout } from '@aduvi/types/widget';

import { getLocations } from 'store/features/business-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const businessFields = ['Name', 'Address', 'State', 'Post Code', 'Country'];

export const Design = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const [isMobileView, setIsMobileView] = useState(false);
  const { t: translate } = useTranslation();

  const { locations } = useAppSelector((state) => state.business);
  const { countries } = useAppSelector((state) => state.common.countries);

  const { widgetBackgroundStyle, backgroundStyle, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useDesign({
    form,
  });

  const visibleFields = Form.useWatch(['widget_style', 0, 'visible_fields'], form) || businessFields;

  const iconType = form.getFieldValue(['widget_style', 0, 'icon_type']) || 'Pin';

  const getIcon = (location: ILocation) => {
    if (iconType === 'Pin') {
      return <EnvironmentOutlined style={{ fontSize: '32px' }} />;
    } else if (iconType === 'Flag') {
      const country = countries?.find((c) => c.id === location.country_id);
      const countryCode = countryToAlpha2(country ? country.name : '');

      if (countryCode) {
        return (
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
              fontSize: '7em',
              lineHeight: '7em',
            }}
          />
        );
      } else {
        return <EnvironmentOutlined style={{ fontSize: '32px' }} />;
      }
    }
    return null;
  };

  const entitySettings = (
    <div>
      <Typography.Text type='secondary' className='mb-10'>
        {translate('widgets.icon')}
      </Typography.Text>
      <Form.Item name={['widget_style', 0, 'icon_type']}>
        <Select
          value={iconType}
          onChange={(value) => {
            form.setFieldValue(['widget_style', 0, 'icon_type'], value);
          }}>
          <Select.Option value='Pin'>{translate('widgets.pin')}</Select.Option>
          <Select.Option value='Flag'>{translate('widgets.flag')}</Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const displayedLocations = useMemo(() => {
    if (limitResults === 'show_all') {
      return locations?.data;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return locations?.data?.slice(0, resultsPerPage);
    }
    return locations?.data;
  }, [locations?.data, limitResults, resultsPerPage]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getLocations(selectedBusiness?.id));
  }, [selectedBusiness?.id, dispatch]);

  return (
    <Row className='business-design-tab-wrapper' style={backgroundStyle}>
      <Col span={14}>
        <Card style={{ borderStyle: 'dashed', ...widgetBackgroundStyle }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === 'animated'}>
              {displayedLocations?.map((location, index) => {
                const country = countries?.find((c) => c.id === location.country_id);

                return (
                  <div key={index} className='location-card'>
                    {getIcon(location)}
                    {visibleFields.includes('Name') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Address') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.address}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('State') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.state}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Post Code') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.post_code}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Country') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {country?.name}
                      </Typography.Paragraph>
                    )}
                  </div>
                );
              })}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]} justify='center'>
              {displayedLocations?.map((location, index) => {
                const country = countries?.find((c) => c.id === location.country_id);
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan}>
                    <div className='location-card'>
                      {getIcon(location)}
                      {visibleFields.includes('Name') && (
                        <Typography.Title
                          level={4}
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {location.name}
                        </Typography.Title>
                      )}
                      {visibleFields.includes('Address') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {location.address}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('State') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {location.state}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Post Code') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {location.post_code}
                        </Typography.Paragraph>
                      )}
                      {visibleFields.includes('Country') && (
                        <Typography.Paragraph
                          style={{
                            fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                            color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                          }}>
                          {country?.name}
                        </Typography.Paragraph>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            displayedLocations?.map((location, index) => {
              const country = countries?.find((c) => c.id === location.country_id);

              return (
                <Row key={index} gutter={[20, 20]} align='middle' className='location-card-row'>
                  <Col xs={24} sm={6} className='location-image'>
                    {getIcon(location)}
                  </Col>
                  <Col xs={24} sm={18}>
                    {visibleFields.includes('Name') && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Address') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.address}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('State') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.state}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Post Code') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {location.post_code}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Country') && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: form.getFieldValue(['widget_style', 0, 'font_family']),
                          color: form.getFieldValue(['widget_style', 0, 'primary_font_color']),
                        }}>
                        {country?.name}
                      </Typography.Paragraph>
                    )}
                  </Col>
                </Row>
              );
            })
          )}
        </Card>
      </Col>
      <Col span={1} className='icons-container'>
        <RightSidebar
          form={form}
          onMobileView={() => setIsMobileView((prevState) => !prevState)}
          isMobileView={isMobileView}
          fields={businessFields}
          entitySettings={entitySettings}
        />
      </Col>
    </Row>
  );
};
