import { EWidgetLayout, IWidget } from '@aduvi/types/widget';

export const widgetStyle: IWidget['widget_style'][0] = {
  background_color: '#ffffff',
  background_image: '',
  background_mode: 'colour',
  widget_background: '#ffffff',
  font_family: 'Poppins',
  primary_font_color: '#000000',
  secondary_font_color: '#000000',
  layout: EWidgetLayout.ROWS,
  button_text: 'Book Now',
  visible_fields: ['Picture', 'Title', 'Price', 'Description', 'Button'],
};

export const agendas = [
  {
    title: 'How to double your revenue in 3 months',
    presenter: 'Chris Collins',
    time: 'Monday 15th January 2024 | 10:00am - 11:00am',
    description: 'Learn how to make changes to 5 key areas in your business to boost your revenue and double your business within 3 months',
    image: 'https://via.placeholder.com/100x100.png?text=Chris+Collins',
  },
  {
    title: 'How to use greenscreen effectively',
    presenter: 'John Smith',
    time: 'Monday 15th January 2024 | 11:00am - 12:00pm',
    description: 'Learn how to make changes to 5 key areas in your business to boost your revenue and double your business within 3 months',
    image: 'https://via.placeholder.com/100x100.png?text=John+Smith',
  },
  {
    title: 'Digital Marketing 101',
    presenter: 'Jane Doe',
    time: 'Monday 15th January 2024 | 12:00pm - 1:00pm',
    description: 'Learn how to make changes to 5 key areas in your business to boost your revenue and double your business within 3 months',
    image: 'https://via.placeholder.com/100x100.png?text=Jane+Doe',
  },
];
