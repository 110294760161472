import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Card, Carousel, Col, Image, Row, Select, Spin } from 'antd';
import type { CarouselRef } from 'antd/es/carousel';

import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { generateQuantityOptions } from '@aduvi/utils/helper';

interface IProps {
  products?: IPaginatedResponse<IProduct>;
  selectedProducts: string[];
  isSlideshow: boolean;
  onProductSelect: (id: string) => void;
}

export const SlideshowDisplayType = ({ products, selectedProducts, isSlideshow, onProductSelect }: IProps) => {
  const [page, setPage] = useState(0);
  const carouselRef = React.useRef<CarouselRef>(null);

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (value: number, productId: string) => {
    if (value === 0) {
      onProductSelect(productId);
      setQuantity(1);
      return;
    }
    setQuantity(value);
  };

  const PrevArrow = () => (
    <Button
      type='text'
      icon={<LeftOutlined />}
      style={{
        position: 'absolute',
        top: '50%',
        left: 0,
        zIndex: 10,
        transform: 'translateY(-50%)',
      }}
      onClick={() => {
        if (page !== 0) {
          setPage((prev) => --prev);
        }
        carouselRef.current?.prev();
      }}
    />
  );

  const NextArrow = () => (
    <Button
      type='text'
      icon={<RightOutlined />}
      style={{
        position: 'absolute',
        top: '50%',
        right: 0,
        zIndex: 10,
        transform: 'translateY(-50%)',
      }}
      onClick={() => {
        if (products?.data && page !== products?.data?.length - 1) {
          setPage((prev) => ++prev);
        }
        carouselRef.current?.next();
      }}
    />
  );

  return (
    <Spin spinning={!products}>
      <Row justify={'center'} className='w-full'>
        {isSlideshow ? (
          <div style={{ position: 'relative', width: '60%', height: '300px', marginBottom: '20px' }}>
            <img
              src={products?.data?.[page]?.image}
              alt='Background'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                boxShadow: 'rgba(0, 0, 0, 0.4) 2px 4px 8px, rgba(0, 0, 0, 0.3) 2px 15px 26px -6px, rgba(0, 0, 0, 0.2) 3px -6px 2px inset',
                top: 0,
                left: 0,
              }}
            />
            <img
              src={'https://www.pngplay.com/wp-content/uploads/7/Happy-Person-Transparent-Images.png'}
              alt='Overlay'
              style={{
                width: '50%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: '20%',
                left: 0,
              }}
            />
          </div>
        ) : (
          <Row justify={'center'} className='w-full mt-10 mb-10 mr-20'>
            <Image
              src={products?.data?.[page]?.image}
              preview={false}
              width={'60%'}
              height={300}
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.4) 2px 4px 8px, rgba(0, 0, 0, 0.3) 2px 15px 26px -6px, rgba(0, 0, 0, 0.2) 3px -6px 2px inset',
              }}
            />
          </Row>
        )}
      </Row>
      <Row justify={'space-between'}>
        <Col span={1}>
          <PrevArrow />
        </Col>
        <Col span={20}>
          <Carousel ref={carouselRef} dots={false} infinite={false} slidesToShow={1} slidesToScroll={1} effect='fade'>
            {products?.data.map((item, index) => (
              <Row key={index} className='w-full flex ' gutter={[18, 18]} justify={'start'}>
                {index === 0 ? (
                  <Col span={7} />
                ) : (
                  <Col xs={24} sm={24} xl={12} xxl={8} key={index}>
                    <Card
                      hoverable
                      style={{
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textAlign: 'center',
                      }}
                      cover={<img alt='product' height={200} src={products?.data[index - 1].image} />}>
                      <h2>{products?.data[index - 1].name}</h2>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: products?.data[index - 1].description! }} />
                      </p>

                      <div className='title price-tag mb-10'>
                        $
                        {selectedProducts.includes(products?.data[index - 1].id)
                          ? Number(products?.data[index - 1].price || products?.data[index - 1].upsell_price) * quantity
                          : products?.data[index - 1].price}
                      </div>
                      <Row justify={'space-around'}>
                        <Button
                          type='primary'
                          shape='round'
                          onClick={() => onProductSelect(products?.data[index - 1].id)}
                          style={{ backgroundColor: selectedProducts.includes(products?.data[index - 1].id) ? '#52c41a' : '#000' }}>
                          {selectedProducts.includes(products?.data[index - 1].id) ? 'Added' : 'Add To Cart'}
                        </Button>
                        {selectedProducts.includes(products?.data[index - 1].id) ? (
                          <Select
                            defaultValue={1}
                            onChange={(value) => handleQuantityChange(value, products?.data[index - 1].id)}
                            style={{ width: 60 }}>
                            {generateQuantityOptions(
                              Number(products?.data[index - 1].quantity_available),
                              products?.data[index - 1].min_quantity,
                              products?.data[index - 1].max_quantity,
                            ).map((qty) => (
                              <Select.Option key={qty} value={qty}>
                                {qty}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : null}
                      </Row>
                    </Card>
                  </Col>
                )}
                <Col xs={24} sm={24} xl={12} xxl={8} key={index}>
                  <Card
                    hoverable
                    style={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                      textAlign: 'center',
                      border: '2px solid lightgrey',
                    }}
                    cover={<img alt='product' height={200} src={item.image} />}>
                    <h2>{item.name}</h2>
                    <p>
                      <div dangerouslySetInnerHTML={{ __html: item.description! }} />
                    </p>

                    <div className='title price-tag mb-10'>
                      ${selectedProducts.includes(item.id) ? Number(item.price || item.upsell_price) * quantity : item.price}
                    </div>
                    <Row justify={'space-around'}>
                      <Button
                        type='primary'
                        shape='round'
                        onClick={() => onProductSelect(item.id)}
                        style={{ backgroundColor: selectedProducts.includes(item.id) ? '#52c41a' : '#000' }}>
                        {selectedProducts.includes(item.id) ? 'Added' : 'Add To Cart'}
                      </Button>
                      {selectedProducts.includes(item.id) ? (
                        <Select defaultValue={1} onChange={(value) => handleQuantityChange(value, item.id)} style={{ width: 60 }}>
                          {generateQuantityOptions(Number(item.quantity_available), item.min_quantity, item.max_quantity).map((qty) => (
                            <Select.Option key={qty} value={qty}>
                              {qty}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : null}
                    </Row>
                  </Card>
                </Col>
                {index === products.data.length - 1 ? (
                  <Col span={7} />
                ) : (
                  <Col xs={24} sm={24} xl={12} xxl={8} key={index}>
                    <Card
                      hoverable
                      style={{
                        borderRadius: '10px',
                        overflow: 'hidden',
                        textAlign: 'center',
                      }}
                      cover={<img alt='product' height={200} src={products?.data[index + 1].image} />}>
                      <h2>{products?.data[index + 1].name}</h2>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: products?.data[index + 1].description! }} />
                      </p>

                      <div className='title price-tag mb-10'>
                        $
                        {selectedProducts.includes(products?.data[index + 1].id)
                          ? Number(products?.data[index + 1].price || products?.data[index + 1].upsell_price) * quantity
                          : products?.data[index + 1].price}
                      </div>
                      <Row justify={'space-around'}>
                        <Button
                          type='primary'
                          shape='round'
                          onClick={() => onProductSelect(products?.data[index + 1].id)}
                          style={{ backgroundColor: selectedProducts.includes(products?.data[index + 1].id) ? '#52c41a' : '#000' }}>
                          {selectedProducts.includes(products?.data[index + 1].id) ? 'Added' : 'Add To Cart'}
                        </Button>
                        {selectedProducts.includes(products?.data[index + 1].id) ? (
                          <Select
                            defaultValue={1}
                            onChange={(value) => handleQuantityChange(value, products?.data[index + 1].id)}
                            style={{ width: 60 }}>
                            {generateQuantityOptions(
                              Number(products?.data[index + 1].quantity_available),
                              products?.data[index + 1].min_quantity,
                              products?.data[index + 1].max_quantity,
                            ).map((qty) => (
                              <Select.Option key={qty} value={qty}>
                                {qty}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : null}
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
            ))}
          </Carousel>
        </Col>
        <Col span={1}>
          <NextArrow />
        </Col>
      </Row>
    </Spin>
  );
};
