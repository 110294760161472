import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { PercentageOutlined } from '@ant-design/icons';

import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { useBusinessId } from '@aduvi/hooks';
import { getFormErrors } from '@aduvi/utils/helper';

import { createTaxRate, setSelectedTaxRate } from 'store/features/tax-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const TaxModal = ({ setOpenTaxModal }: { setOpenTaxModal: (value: SetStateAction<boolean>) => void }) => {
  const dispatch = useAppDispatch();
  const businessId = useBusinessId();
  const { t: translate } = useTranslation();
  const [form] = useForm();

  const {
    rates: { creating },
  } = useAppSelector((state) => state.tax);

  const onCreate = () => {
    if (!businessId) return;
    form
      .validateFields()
      .then(() => {
        dispatch(
          createTaxRate({
            business_id: businessId,
            body: form.getFieldsValue(),
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(setSelectedTaxRate(undefined));
            setOpenTaxModal(false);
          })
          .catch((error) => form.setFields(getFormErrors(error)));
      })
      .catch(() => {});
  };

  return (
    <Modal
      title={translate('quotes.addTaxRate')}
      open={true}
      width={700}
      centered
      onCancel={() => setOpenTaxModal(false)}
      footer={[
        <>
          <Divider className='mt-0' />
          <Button key='submit' type='primary' htmlType='submit' loading={creating} onClick={onCreate}>
            {translate('buttons.save')}
          </Button>
        </>,
      ]}>
      <Form form={form} layout='vertical'>
        <Row>
          <Col span={24} className='mt-15'>
            <Form.Item
              name='tax_name'
              label={translate('settings.tax.name')}
              rules={[{ required: true, message: translate('settings.tax.nameValidation') }]}>
              <Input placeholder={translate('settings.tax.namePlaceholder')} />
            </Form.Item>

            <Form.Item
              name='tax_percentage'
              label={translate('settings.tax.rate')}
              rules={[{ required: true, message: translate('settings.tax.rateValidation') }]}>
              <Input placeholder={translate('settings.tax.ratePlaceholder')} addonAfter={<PercentageOutlined />} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
