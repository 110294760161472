import { useTranslation } from 'react-i18next';
import { SelectProductCategory } from 'views/assets/products/products-drawer/SelectProductCategory/SelectProductCategory';

import { Col, Form, FormInstance, Input } from 'antd';

import { UploadImage } from '@aduvi/components/UploadImage/UploadImage';
import { useBusiness } from '@aduvi/hooks';
import Tiptap from '@aduvi/Tiptap/Tiptap';
import { normalToKebabCase } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

export const Details = ({ form }: { form: FormInstance }) => {
  const { t: translate } = useTranslation();
  const selectedBusiness = useBusiness();

  const { selectedProduct } = useAppSelector((state) => state.products);

  return (
    <Col span={24} className='mt-15'>
      <Form.Item
        name='name'
        label={translate('assets.productsDetails.name')}
        rules={[{ required: true, message: translate('assets.productsDetails.nameValidation') }]}>
        <Input
          placeholder={translate('assets.productsDetails.namePlaceholder')}
          onChange={(e) => form.setFieldValue('sku', normalToKebabCase(e.target.value))}
        />
      </Form.Item>

      <Form.Item name='business_product_category_id' label={translate('assets.productsDetails.category')}>
        <SelectProductCategory business_id={selectedBusiness?.id} />
      </Form.Item>

      <Form.Item label={translate('assets.productsDetails.image')} name='image'>
        <UploadImage name='image' onUploadChange={(value) => form.setFieldValue('image', value?.url)} url={selectedProduct?.image} />
      </Form.Item>

      <Form.Item name='description' label={translate('assets.productsDetails.description')}>
        <Tiptap
          content={form.getFieldValue('description')}
          placeholder={translate('assets.productsDetails.descriptionPlaceholder')}
          onUpdate={(content) => form.setFieldValue('description', content.getHTML())}
        />
      </Form.Item>

      <Form.Item
        name='sku'
        label={translate('assets.productsDetails.productSKU')}
        rules={[{ required: true, message: translate('assets.productsDetails.productSKUValidation') }]}>
        <Input />
      </Form.Item>
    </Col>
  );
};
