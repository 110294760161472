import { Active, Over } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { DEFAULT_DATE_FORMAT } from '@aduvi/constants';
import { DEFAULT_CELL_STYLE } from '@aduvi/constants/draggable-fields';
import {
  EDefaultDateValue,
  EManagementFieldType,
  IBusiness,
  ICustomFieldViewStyle,
  IEntityCustomFields,
  IEntityField,
  IManagementField,
} from '@aduvi/types';
/* eslint-disable  @typescript-eslint/no-explicit-any */

interface IReorderManagementFields {
  active: Active;
  over: Over | null;
  managementFields: IManagementField[];
}

export const reorderedCustomFields = ({
  active,
  over,
  managementFields,
}: IReorderManagementFields): { id: string; order_number: number }[] | void => {
  if (active.id === over?.id) return;

  const isGlobalField = managementFields.find((item) => item.id === active.id)?.is_global;
  const globalFields = managementFields.filter((item) => item.is_global);
  if (isGlobalField) return;

  const activeIndex = managementFields.findIndex((item) => item.id === active.id) as number;
  const overIndex = managementFields?.findIndex((item) => item.id === over?.id) as number;

  if (activeIndex < 0 || overIndex < globalFields.length) return;

  return arrayMove(managementFields, activeIndex, overIndex).map((item, index) => ({
    id: item.id,
    order_number: index + 1,
  }));
};

export const getDefaultDateValue = (value: EDefaultDateValue) => {
  switch (value) {
    case EDefaultDateValue.NOW:
      return dayjs();
    case EDefaultDateValue.NOW_1_DAY:
      return dayjs().add(1, 'day');
    case EDefaultDateValue.NOW_2_DAYS:
      return dayjs().add(2, 'days');
    case EDefaultDateValue.NOW_3_DAYS:
      return dayjs().add(3, 'days');
    case EDefaultDateValue.NOW_4_DAYS:
      return dayjs().add(4, 'days');
    case EDefaultDateValue.NOW_5_DAYS:
      return dayjs().add(5, 'days');
    case EDefaultDateValue.NOW_6_DAYS:
      return dayjs().add(6, 'days');
    case EDefaultDateValue.NOW_1_WEEK:
      return dayjs().add(1, 'week');
    case EDefaultDateValue.NOW_2_WEEKS:
      return dayjs().add(2, 'weeks');
    case EDefaultDateValue.NOW_3_WEEKS:
      return dayjs().add(3, 'weeks');

    default:
      return dayjs();
  }
};

export const getDefaultPersonalizedViewColumns = (fields: IEntityField[]) => {
  if (!fields || fields.length === 0) return [];

  const coreFields = fields.filter(({ core }) => core);

  return coreFields.map(({ id, title }) => ({
    id: uuidv4(),
    field_id: [id],
    name: title,
    style: DEFAULT_CELL_STYLE,
  }));
};

export const onDragEndCustomFields = (over: Over | null, active: Active, columns: ICustomFieldViewStyle[], fields?: IEntityField[]) => {
  if (!String(over?.id)?.includes('column_')) {
    const activeIndex = columns.findIndex((item) => item?.id === active?.id);
    const overIndex = columns.findIndex((item) => item?.id === over?.id);
    columns = arrayMove(columns, activeIndex, overIndex);

    if (activeIndex === -1) {
      if (columns?.map((column) => column?.id)?.includes(active?.id?.toString())) return;
      const columnsLeft = columns.slice(0, overIndex);
      const columnsRight = columns.slice(overIndex);
      const isVerticalField = active?.id?.toString()?.includes('column_');
      columns = [
        ...columnsLeft,
        {
          id: uuidv4(),
          field_id: isVerticalField ? [active?.id?.toString()?.split('_')?.[2]] : [active?.id?.toString()],
          style: DEFAULT_CELL_STYLE,
          name: isVerticalField
            ? fields?.find((field) => field?.id === active?.id?.toString()?.split('_')?.[2])?.title
            : fields?.find((field) => field?.id === active?.id)?.title,
        },
        ...columnsRight,
      ];
    }
    return columns;
  } else {
    const column = columns?.find((column) => column?.id === String(over?.id)?.split('_')?.[1]);
    if (column?.field_id?.includes(String(active?.id))) return;
    column?.field_id?.push(String(active?.id));
  }
};

export const getCustomFieldValue = (field?: IEntityCustomFields, business?: IBusiness) => {
  if (!field) return '';

  if (field?.field?.field_type === EManagementFieldType.OPTIONS_LIST) {
    return field?.selected_options?.map((option) => option.name)?.join(', ');
  }

  if (field.field.field_type === EManagementFieldType.DATE) {
    return dayjs(field.value?.toString()).format(business?.date_format ?? DEFAULT_DATE_FORMAT);
  }

  if (field?.field?.field_type === EManagementFieldType.STATUS) {
    return field.selected_options.length ? field.selected_options.map((option) => option.name).join(',') : field.field.name;
  }

  return field?.value?.toString();
};
