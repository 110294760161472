import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import './PackageEmbed.scss';

import { useBusiness } from '@aduvi/hooks';
import { IPaginatedResponse, IService } from '@aduvi/types';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';
import { toHumanReadable } from '@aduvi/utils/helper';

import { getPublicServices } from 'store/features/form-slice';
import { getPublicWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWidgetStyles } from '../../hooks/useWidgetStyles';

export const PackageEmbed = () => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { widgetId } = useParams();

  const { selectedWidget } = useAppSelector((state) => state.widget);
  const [services, setServices] = useState<IPaginatedResponse<IService> | undefined>(undefined);

  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const memoizedWidgetFields = useMemo(() => {
    return {
      selectedService: selectedWidget?.widget_style?.[0]?.selected_service,
      sortingOption: selectedWidget?.widget_style?.[0]?.sorting_option,
      formName: selectedWidget?.widget_style?.[0]?.form_name,
      buttonActionForm: selectedWidget?.widget_style?.[0]?.button_action_form,
      buttonText: selectedWidget?.widget_style?.[0]?.button_text,
    };
  }, [selectedWidget]);

  const { selectedService, sortingOption, formName, buttonActionForm, buttonText } = memoizedWidgetFields;

  const { visibleFields, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useWidgetStyles(selectedWidget);

  const allPackages = useMemo(() => services?.data.map((service) => service.packages).flat(), [services?.data]);

  const filteredPackages = useMemo(() => {
    if (selectedService === 'All') {
      return allPackages;
    } else {
      return allPackages?.filter((pkg) => pkg.business_service_id === selectedService);
    }
  }, [allPackages, selectedService]);

  const sortedPackages = useMemo(() => {
    if (!filteredPackages) return [];

    const packagesCopy = [...filteredPackages];

    switch (sortingOption) {
      case 'Price':
        packagesCopy.sort((a, b) => parseFloat(a.flat_price || '0') - parseFloat(b.flat_price || '0'));
        break;
      case 'Service':
        packagesCopy.sort((a, b) => {
          const serviceA = services?.data.find((s) => s.id === a.business_service_id)?.name || '';
          const serviceB = services?.data.find((s) => s.id === b.business_service_id)?.name || '';
          return serviceA.localeCompare(serviceB);
        });
        break;
      case 'Title':
      default:
        packagesCopy.sort((a, b) => a.name.localeCompare(b.name));
        break;
    }

    return packagesCopy;
  }, [filteredPackages, sortingOption, services?.data]);

  const displayedPackages = useMemo(() => {
    if (!sortedPackages) return [];

    if (limitResults === 'show_all') {
      return sortedPackages;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return sortedPackages.slice(0, resultsPerPage);
    }
    return sortedPackages;
  }, [sortedPackages, limitResults, resultsPerPage]);

  const handleButtonClick = () => {
    if (buttonActionForm && formName) {
      window.open(`/fo/${buttonActionForm}/${formName?.toLowerCase()}`, '_blank');
    }
  };

  useEffect(() => {
    if (!selectedBusiness?.id && !urlBusinessId) return;
    dispatch(getPublicServices(String(selectedBusiness?.id || urlBusinessId)))
      .unwrap()
      .then((data) => {
        setServices(data);
      });
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Row className='packages-design-tab-wrapper'>
      <Col span={24}>
        <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {displayedPackages?.map((pkg, index) => (
                <div key={index} className='package-card'>
                  {visibleFields.includes('Picture') && pkg.image && (
                    <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />
                  )}
                  {visibleFields.includes('Title') && pkg.name && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {pkg.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && pkg.flat_price && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        fontSize: '18px',
                      }}>
                      ${pkg.flat_price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && pkg.description && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Duration') && pkg.included_duration && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        fontWeight: 'bold',
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                    <div>
                      <Typography.Text
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          fontWeight: 'bold',
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        Included Products:
                      </Typography.Text>
                      <ul>
                        {pkg.products.map((product, idx) => (
                          <li key={idx}>
                            <Typography.Text
                              style={{
                                fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                                color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                              }}>
                              {product.name}
                            </Typography.Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </div>
              ))}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]} justify='center'>
              {displayedPackages?.map((pkg, index) => (
                <Col
                  key={index}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={selectedLayout === EWidgetLayout.COLUMNS ? (Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan) : 24}>
                  <div className='package-card'>
                    {visibleFields.includes('Picture') && pkg.image && (
                      <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />
                    )}
                    {visibleFields.includes('Title') && pkg.name && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {pkg.name}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Price') && pkg.flat_price && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          fontSize: '18px',
                        }}>
                        ${pkg.flat_price}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && pkg.description && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Duration') && pkg.included_duration && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          fontWeight: 'bold',
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                      <div>
                        <Typography.Text
                          style={{
                            fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                            fontWeight: 'bold',
                            color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                          }}>
                          Included Products:
                        </Typography.Text>
                        <ul>
                          {pkg.products.map((product, idx) => (
                            <li key={idx}>
                              <Typography.Text
                                style={{
                                  fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                                  color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                                }}>
                                {product.name}
                              </Typography.Text>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={handleButtonClick}
                        style={{
                          background: selectedWidget?.widget_style?.[0]?.button_background,
                          color: selectedWidget?.widget_style?.[0]?.button_font,
                        }}>
                        {buttonText}
                      </Button>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            displayedPackages?.map((pkg, index) => (
              <Row key={index} gutter={[20, 20]} align='middle' className='package-card-row'>
                {visibleFields.includes('Picture') && (
                  <Col xs={24} sm={6} className='package-image'>
                    {pkg.image && <img alt={pkg.name} src={pkg.image} width={100} height={100} className='mb-10' />}
                  </Col>
                )}
                <Col xs={24} sm={18} className='package-details'>
                  {visibleFields.includes('Title') && pkg.name && (
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {pkg.name}
                    </Typography.Title>
                  )}
                  {visibleFields.includes('Price') && pkg.flat_price && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        fontSize: '18px',
                      }}>
                      ${pkg.flat_price}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Description') && pkg.description && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      <div dangerouslySetInnerHTML={{ __html: pkg.description || '' }} />
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Duration') && pkg.included_duration && (
                    <Typography.Paragraph
                      style={{
                        fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                        fontWeight: 'bold',
                        color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                      }}>
                      {pkg.included_duration} {toHumanReadable(pkg.duration_type)}
                    </Typography.Paragraph>
                  )}
                  {visibleFields.includes('Included Products') && pkg.products && pkg.products.length > 0 && (
                    <div>
                      <Typography.Text
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          fontWeight: 'bold',
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        Included Products:
                      </Typography.Text>
                      <ul>
                        {pkg.products.map((product, idx) => (
                          <li key={idx}>
                            <Typography.Text
                              style={{
                                fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                                color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                              }}>
                              {product.name}
                            </Typography.Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {visibleFields.includes('Button') && (
                    <Button
                      type='primary'
                      onClick={handleButtonClick}
                      style={{
                        background: selectedWidget?.widget_style?.[0]?.button_background,
                        color: selectedWidget?.widget_style?.[0]?.button_font,
                      }}>
                      {buttonText}
                    </Button>
                  )}
                </Col>
              </Row>
            ))
          )}
        </Card>
      </Col>
    </Row>
  );
};
