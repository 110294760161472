import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Table } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { IEntityWithFields } from '@aduvi/types/entity';

export const ContactEntities = ({ entities, loading }: { entities?: IEntityWithFields[]; loading: boolean }) => {
  const jobColumns = useEntitiesTableColumns();

  return <Table showHeader={false} pagination={false} columns={jobColumns} loading={loading} dataSource={entities} />;
};

export const useEntitiesTableColumns = () => {
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();

  return [
    {
      title: translate('contacts.name'),
      render: (record: IEntityWithFields) => {
        const name = record?.custom_fields?.find((item) => item?.title === 'Event Name')?.field_data?.[0]?.value;
        return <span className='ml-15'>{name}</span>;
      },
    },
    {
      title: translate('contacts.date'),
      render: (record: IEntityWithFields) => {
        const date = record?.custom_fields?.find((item) => item?.title === 'Event Date')?.field_data?.[0]?.value;
        return <span className='ml-15'>{dayjs(date).format(selectedBusiness?.date_format || 'DD/MM/YYYY')}</span>;
      },
    },
  ];
};
