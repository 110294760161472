import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandOutlined } from '@ant-design/icons';

import { Col, Form, Input, Row, Space, Spin, Tabs, Typography } from 'antd';
import './UpsertJobDrawer.scss';

import { Button } from '@aduvi/components/Button/Button';
import { CustomField } from '@aduvi/components/CustomField/CustomField';
import { Drawer } from '@aduvi/components/Drawer/Drawer';
import { DRAWER_BODY_STYLE } from '@aduvi/constants';
import { createEntityPermission, editEntityPermission } from '@aduvi/constants/entity-type';
import { useActiveTheme, useIsMobile } from '@aduvi/hooks';
import { EPersonalizedViewOrigin, IEntityField } from '@aduvi/types';
import { EntityDrawerTabs, IEntityType } from '@aduvi/types/entity';
import { toHumanReadable } from '@aduvi/utils/helper';

import { createEntity, editEntity, setSelectedEntity } from 'store/features/entity-slice';
import { useAppSelector } from 'store/hooks';

import { mapEditedEntityFieldsToPayload, mapEntityFieldsToPayload } from '../helper';

import { Contacts } from './drawer-tabs/contacts/Contacts';
import { useUpsertEntityDrawer } from './UpsertEntityDrawer.hooks';

interface IProps {
  open: boolean;
  entityType?: IEntityType;
  origin: EPersonalizedViewOrigin;
  fields: IEntityField[];
  formOnlyFields: IEntityField[];
  onClose: () => void;
}

export const UpsertJobDrawer = ({ open, entityType, origin, fields, formOnlyFields, onClose }: IProps) => {
  const { t: translate } = useTranslation();
  const isMobile = useIsMobile();
  const activeTheme = useActiveTheme();

  const { dispatch, tabItems, selectedEntity, creating, updating, loadingEntity, form, selectedBusiness } = useUpsertEntityDrawer({
    origin,
    entityType,
    fields,
    formOnlyFields,
  });
  const { entities } = useAppSelector((state) => state.entity);

  const entityFormFields = useMemo(() => {
    if (!entities?.selectedEntity?.custom_fields) return [];

    return entities?.selectedEntity?.custom_fields?.map((item) => (item?.form_only ? item.id : null))?.filter((item) => item);
  }, [entities?.selectedEntity]);

  const entityManagementFields = useMemo(() => {
    if (!formOnlyFields) return [];

    return formOnlyFields?.filter((field) => entityFormFields?.includes(field?.id));
  }, [entityFormFields, formOnlyFields]);

  const formFields = useMemo(() => [...fields, ...entityManagementFields], [fields, entityManagementFields]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);

  const headerFields = useMemo(
    () => ({
      nameField: formFields.find((field) => field?.title === 'Event Name'),
      nameFieldIndex: formFields.findIndex((field) => field?.title === 'Event Name'),
      statusField: formFields.find((field) => field?.title === 'Status'),
      statusFieldIndex: formFields.findIndex((field) => field?.title === 'Status'),
    }),
    [selectedEntity],
  );
  const closeDrawer = () => {
    form.resetFields();
    dispatch(setSelectedEntity(undefined));
    onClose();
  };

  const createNewEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;

    dispatch(
      createEntity({
        business_id: selectedBusiness?.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields]),
      }),
    )
      .unwrap()
      .then(() => onClose())
      .catch(() => {});
  };

  const updateEntity = () => {
    if (!selectedBusiness?.id || !entityType?.id || !selectedEntity?.id) return;

    dispatch(
      editEntity({
        id: selectedEntity.id,
        business_id: selectedBusiness.id,
        entity_type_id: entityType?.id,
        custom_fields: mapEditedEntityFieldsToPayload(form.getFieldValue('custom_fields'), [...fields, ...formOnlyFields], selectedEntity),
      }),
    )
      .unwrap()
      .then(() => onClose())
      .catch(() => {});
  };

  const submitFormHandler = () => {
    selectedEntity?.id ? updateEntity() : createNewEntity();
  };

  return (
    <Drawer
      className='edit-job-drawer-wrapper'
      title={
        selectedEntity?.id
          ? `Edit ${entityType?.name && toHumanReadable(entityType?.name)}`
          : `Create ${entityType?.name && toHumanReadable(entityType?.name)}`
      }
      open={open}
      width={isExpanded ? '95%' : 720}
      onClose={closeDrawer}
      bodyStyle={{ ...DRAWER_BODY_STYLE, width: '100%' }}
      extra={
        <Space>
          <Button disabled={creating || updating} onClick={closeDrawer}>
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={creating || updating}
            loading={creating || updating}
            type='primary'
            requiredPermission={
              selectedEntity ? entityType && editEntityPermission[entityType?.name] : entityType && createEntityPermission[entityType?.name]
            }
            disabledButton
            onClick={submitFormHandler}>
            {translate('buttons.save')}
          </Button>
        </Space>
      }>
      <Spin spinning={loadingEntity}>
        <Form form={form} layout='vertical' requiredMark={false}>
          <Row
            gutter={16}
            style={{
              backgroundColor: activeTheme?.buttonBg,
              color: activeTheme?.buttonText,
            }}
            className='selected-event-details'
            align='middle'>
            <Col style={{ flexGrow: 1 }}>
              {isEditMode ? (
                <Form.Item name={['custom_fields', headerFields.nameFieldIndex!, headerFields.nameField?.id!]}>
                  <Input
                    className='job-name-input'
                    placeholder={translate('leads.layoutDrawer.jobName')}
                    style={{ color: activeTheme?.buttonText, borderBottom: `1px solid ${activeTheme?.buttonText}` }}
                  />
                </Form.Item>
              ) : (
                <Typography.Title level={3} className='event-title' onClick={() => setIsEditMode(true)}>
                  <CustomField field={headerFields?.nameField!} form={form} index={headerFields.nameFieldIndex} />
                </Typography.Title>
              )}
              <Row>
                <div className='status-select'>
                  <Form.Item
                    name={['custom_fields', headerFields.statusFieldIndex!, headerFields.statusField?.id!]}
                    getValueFromEvent={(value) => [value]}>
                    <CustomField field={headerFields?.statusField!} form={form} index={headerFields.statusFieldIndex} />
                  </Form.Item>
                </div>
              </Row>
            </Col>
            <Col style={{ alignSelf: 'self-start', marginLeft: 'auto' }}>
              {!isMobile && <ExpandOutlined onClick={() => setIsExpanded((prev) => !prev)} />}
            </Col>
          </Row>

          <Row gutter={30}>
            <Col span={isExpanded ? 15 : 24} className='job-tabs'>
              <Tabs defaultActiveKey={EntityDrawerTabs.DETAILS} items={tabItems} />
            </Col>
            {isExpanded && (
              <Col span={6} className='contact-card'>
                <Contacts />
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};
