import { orderBy } from 'lodash';

import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IResponse } from '@aduvi/types';
import { IAcademy, IAcademyAchivement, IEvaluateQuiz, IEvaluateQuizPayload } from '@aduvi/types/academy';

import { apiSlice } from 'store/api/apiSlice';

export const academyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAcademies: builder.query<IResponse<IAcademy[]>, { businessId: string }>({
      query: ({ businessId }) => ({
        url: `/businesses/${businessId}/academy`,
        method: 'GET',
      }),
      transformResponse: (response: IResponse<IAcademy[]>) => {
        const sortedData = orderBy(response.data, ['level', 'weight'], ['asc', 'asc']);
        return { ...response, data: sortedData };
      },
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: SLICE_TAG_TYPES.ACADEMY, id }) as const), { type: SLICE_TAG_TYPES.ACADEMY, id: 'LIST' }]
          : [{ type: SLICE_TAG_TYPES.ACADEMY, id: 'LIST' }],
    }),

    evaluateQuiz: builder.mutation<IResponse<IEvaluateQuiz>, IEvaluateQuizPayload>({
      query: (payload) => ({
        url: `/businesses/${payload.businessId}/academy/${payload.academyId}/evaluate-quiz`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [
        { type: SLICE_TAG_TYPES.ACADEMY, id: 'LIST' },
        { type: SLICE_TAG_TYPES.ACADEMY_ACHIEVEMENTS, id: 'LIST' },
      ],
    }),

    getAcademyAchievements: builder.query<IResponse<IAcademyAchivement[]>, { businessId: string }>({
      query: ({ businessId }) => ({
        url: `/businesses/${businessId}/academy/achievements`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: SLICE_TAG_TYPES.ACADEMY_ACHIEVEMENTS, id }) as const),
              { type: SLICE_TAG_TYPES.ACADEMY_ACHIEVEMENTS, id: 'LIST' },
            ]
          : [{ type: SLICE_TAG_TYPES.ACADEMY_ACHIEVEMENTS, id: 'LIST' }],
    }),
  }),
});

export const { useGetAllAcademiesQuery, useEvaluateQuizMutation, useGetAcademyAchievementsQuery } = academyApiSlice;
