import { useTranslation } from 'react-i18next';
import { DeleteFilled } from '@ant-design/icons';

import { Button, Col, Form, Input, Row } from 'antd';
import './ActionFormItems.scss';

export const GoogleAnalytics = () => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Form.Item name={'api_secret'} label={translate('automations.apiSecret')}>
        <Input placeholder={translate('automations.pasteYourApiSecret')} />
      </Form.Item>
      <Form.Item name={'event_name'} label={translate('automations.eventName')}>
        <Input />
      </Form.Item>
      <span className='action-form-items'>
        <Form.List
          name='event_parameters'
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 2) {
                  return Promise.reject(new Error(translate('automations.eventError')));
                }
              },
            },
          ]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item label={index === 0 ? translate('automations.eventParameteres') : ''} required={false} key={field.key}>
                  <Row
                    justify={'space-between'}
                    align={'middle'}
                    style={{ background: 'white', padding: '10px', boxShadow: '0px 2px 8px 0px #5194D826' }}>
                    <Col span={11}>
                      <Form.Item name={[index, 'key']}>
                        <Input placeholder={translate('automations.key')} />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item name={[index, 'value']}>
                        <Input placeholder={translate('automations.value')} />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <DeleteFilled onClick={() => remove(field.name)} style={{ color: '#595959' }} />
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Row justify={'end'}>
                <Button className='mt-20' type='primary' ghost onClick={() => add()}>
                  {translate('automations.addParameter')}
                </Button>
              </Row>
            </>
          )}
        </Form.List>
      </span>
    </>
  );
};
