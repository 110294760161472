import { EPersonalizedViewOrigin } from '@aduvi/types';

export const getStatusForEntities = (origin: EPersonalizedViewOrigin) => {
  if (origin === EPersonalizedViewOrigin.LEADS) {
    return 'UNCONFIRMED';
  }

  if (origin === EPersonalizedViewOrigin.BOOKINGS) {
    return 'CONFIRMED';
  }
  return undefined;
};
