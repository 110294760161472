import { useTranslation } from 'react-i18next';

import { Col, Row, Typography } from 'antd';

import { toHumanReadable } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

import { getStatusStyles } from '../../helper';

export const TicketHeaderModal = () => {
  const { t: translate } = useTranslation();
  const { selectedTicket } = useAppSelector((state) => state.ticket);

  return (
    <Row align='middle'>
      <Typography.Text style={selectedTicket && getStatusStyles(selectedTicket?.status)}>
        {selectedTicket && toHumanReadable(selectedTicket?.status)}
      </Typography.Text>
      <Col style={{ flex: 1, textAlign: 'center' }}>
        <Typography.Text>
          {translate('support.contact.ticket')} {selectedTicket?.subject}
        </Typography.Text>
      </Col>
    </Row>
  );
};
