import { EAutomationNodeAction } from '@aduvi/types/automation';

export const LEAD_TRIGGERS = [
  EAutomationNodeAction.LEAD_CREATE,
  EAutomationNodeAction.LEAD_UPDATE,
  EAutomationNodeAction.LEAD_DELETE,
  EAutomationNodeAction.LEAD_STATUS_CHANGED,
  EAutomationNodeAction.LEAD_CONVERTED,
  EAutomationNodeAction.LEAD_CONVERSION_FAILED,
];

export const BOOKING_TRIGGERS = [
  EAutomationNodeAction.BOOKING_CREATE,
  EAutomationNodeAction.BOOKING_UPDATE,
  EAutomationNodeAction.BOOKING_DELETE,
  EAutomationNodeAction.BOOKING_STATUS_CHANGED,
  EAutomationNodeAction.BOOKING_STARTED, //scheduled
  EAutomationNodeAction.BOOKING_ENDED, //scheduled
];
export const ARTWORK_TRIGGERS = [
  EAutomationNodeAction.ARTWORK_CREATE,
  EAutomationNodeAction.ARTWORK_UPDATE,
  EAutomationNodeAction.ARTWORK_COMMENT,
  EAutomationNodeAction.ARTWORK_ACCEPTED,
];

export const PROPOSAL_TRIGGERS = [
  EAutomationNodeAction.PROPOSAL_CREATE,
  EAutomationNodeAction.PROPOSAL_VIEWED,
  EAutomationNodeAction.PROPOSAL_ACCEPTED,
  EAutomationNodeAction.PROPOSAL_ACCEPTANCE_FAILED,
  EAutomationNodeAction.PROPOSAL_SENT,
];

export const TASK_TRIGGERS = [
  EAutomationNodeAction.TASK_CREATE,
  EAutomationNodeAction.TASK_UPDATE,
  EAutomationNodeAction.TASK_DELETE,
  EAutomationNodeAction.TASK_DEADLINE_DUE,
  EAutomationNodeAction.TASK_COMPLETED,
];

export const PAYMENT_TRIGGERS = [
  EAutomationNodeAction.PAYMENT_MANUAL_CREATE,
  EAutomationNodeAction.PAYMENT_REFUNDED,
  EAutomationNodeAction.PAYMENT_DELETE,
  EAutomationNodeAction.PAYMENT_SUCCESS,
  EAutomationNodeAction.PAYMENT_FAILED,
];

export const INVOICE_TRIGGERS = [EAutomationNodeAction.INVOICE_DUE];

export const CONTACT_TRIGGERS = [EAutomationNodeAction.CONTACT_CREATE, EAutomationNodeAction.CONTACT_UPDATE, EAutomationNodeAction.CONTACT_DELETE];

export const USER_TRIGGERS = [
  EAutomationNodeAction.USER_CREATE,
  EAutomationNodeAction.USER_UPDATE,
  EAutomationNodeAction.USER_DELETE,
  EAutomationNodeAction.USER_ASSIGNED_TO_SHIFT,
  EAutomationNodeAction.USER_IS_REMOVED_FROM_SHIFT,
  EAutomationNodeAction.USER_SHIFT_ACCEPTED,
  EAutomationNodeAction.USER_SHIFT_DECLINED,
  EAutomationNodeAction.USER_SHIFT_STARTED, //scheduled
  EAutomationNodeAction.USER_SHIFT_ENDED, //scheduled
];
export const FORM_TRIGGERS = [
  EAutomationNodeAction.FORM_SUBMITTED,
  EAutomationNodeAction.FORM_ABANDONED,
  EAutomationNodeAction.FORM_SUBMISSION_UPDATED,
  EAutomationNodeAction.FORM_WIDGET_SUBMITTED,
];
export const REPS_TRIGGERS = [EAutomationNodeAction.REP_WITHDRAWAL_REQUEST, EAutomationNodeAction.REP_WITHDRAWAL_PAID];

export const CONTRACT_TRIGGERS = [
  EAutomationNodeAction.CONTRACT_IS_SIGNED,
  EAutomationNodeAction.CONTRACT_UPDATE,
  EAutomationNodeAction.CONTRACT_DELETE,
  EAutomationNodeAction.CONTRACT_CREATE,
];
export const VENUES_TRIGGERS = [EAutomationNodeAction.VENUE_CREATE, EAutomationNodeAction.VENUE_UPDATE, EAutomationNodeAction.VENUE_DELETE];
export const EVENT_TRIGGERS = [EAutomationNodeAction.EVENT_STARTED, EAutomationNodeAction.EVENT_ENDED];
export const TEMPLATE_TRIGGERS = [EAutomationNodeAction.TEMPLATE_CHOSEN, EAutomationNodeAction.TEMPLATE_CHOICE_DELETED];
export const BACKDROP_TRIGGERS = [EAutomationNodeAction.BACKDROP_CHOSEN, EAutomationNodeAction.BACKDROP_CHOICE_DELETED];
export const STARTSCREEN_TRIGGERS = [EAutomationNodeAction.STARTSCREEN_CHOSEN, EAutomationNodeAction.STARTSCREEN_CHOICE_DELETED];
export const ORDER_TRIGGERS = [
  EAutomationNodeAction.ORDER_CREATE,
  EAutomationNodeAction.ORDER_UPDATE,
  EAutomationNodeAction.ORDER_DELETE,
  EAutomationNodeAction.ORDER_STATUS_CHANGED,
];
export const CONVERSATION_TRIGGERS = [EAutomationNodeAction.MESSAGE_RECEIVED];
export const REPEATING_TRIGGERS = [
  EAutomationNodeAction.DAILY,
  EAutomationNodeAction.WEEKLY,
  EAutomationNodeAction.MONTHLY,
  EAutomationNodeAction.YEARLY,
];

export const SCHEDULED_TRIGGERS = [
  EAutomationNodeAction.EVENT_STARTED,
  EAutomationNodeAction.EVENT_ENDED,
  EAutomationNodeAction.USER_SHIFT_STARTED,
  EAutomationNodeAction.USER_SHIFT_ENDED,
  EAutomationNodeAction.TASK_DEADLINE_DUE,
  EAutomationNodeAction.BOOKING_STARTED,
  EAutomationNodeAction.BOOKING_ENDED,
];
export const STATUS_TRIGGERS = [EAutomationNodeAction.LEAD_STATUS_CHANGED, EAutomationNodeAction.BOOKING_STATUS_CHANGED];
