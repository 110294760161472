import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Row, Select } from 'antd';
import './Widget.scss';

import { DatePicker } from '@aduvi/components/DatePicker/DatePicker';

export const Widget = ({ onCloseWidget }: { onCloseWidget: () => void }) => {
  const { t: translate } = useTranslation();

  return (
    <Col className='w-full widget-wrapper flex-center flex-direction-column'>
      <Col className='mt-20 widget-content flex-center flex-direction-column'>
        <Form.Item label={translate('forms.forms.eventDate')} className='w-full'>
          <DatePicker placeholder={translate('forms.forms.selectDate')} className='w-full' />
        </Form.Item>
        <Form.Item label={translate('forms.forms.eventType')} className='w-full'>
          <Select placeholder={translate('forms.forms.wedding')} />
        </Form.Item>
      </Col>
      <Row className='w-full mb-20'>
        <Col span={8} />
        <Col span={8} className='flex-center'>
          <Button type='primary'>{translate('forms.forms.checkAvailability')}</Button>
        </Col>
        <Col span={8} className='flex-end'>
          <Button type='text' className='enable-widget mr-20' onClick={onCloseWidget}>
            {translate('forms.forms.disableWidget')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
