import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, FormInstance, Select } from 'antd';

import { useBusiness } from '@aduvi/hooks';
import { IResponse } from '@aduvi/types';
import { EFormType, IEntityForm } from '@aduvi/types/form';

import { getFormsByEntity } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const FormSelection = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();

  const selectedBusiness = useBusiness();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const [forms, setForms] = useState<IEntityForm[]>([]);

  const formOptions = useMemo(() => {
    return forms?.map((form) => ({ label: form?.name, value: form?.id }));
  }, [forms]);

  useEffect(() => {
    if (!selectedBusiness?.id || !entityTypes?.data?.JOB?.id) return;
    Promise.all([
      dispatch(getFormsByEntity({ business_id: selectedBusiness.id, entity_type_id: entityTypes?.data?.JOB?.id, formType: EFormType.LEADS_FORM })),
      dispatch(getFormsByEntity({ business_id: selectedBusiness.id, entity_type_id: entityTypes?.data?.JOB?.id, formType: EFormType.BOOKINGS_FORM })),
    ]).then((responses) => {
      const [leadFormsAction, bookingFormsAction] = responses;
      const leadForms = leadFormsAction.payload as IResponse<IEntityForm[]>;
      const bookingForms = bookingFormsAction.payload as IResponse<IEntityForm[]>;

      setForms([...(leadForms.data || []), ...(bookingForms.data || [])]);
    });
  }, [selectedBusiness?.id, entityTypes?.data?.JOB?.id, dispatch]);

  return (
    <Form.Item className='w-full' name='selected_form' label={translate('automations.selectForm')}>
      <Select
        defaultValue={form?.getFieldValue('selected_form') || formOptions?.[0]?.value}
        placeholder={translate('automations.selectForm')}
        options={formOptions}
      />
    </Form.Item>
  );
};
