import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import './AddFormModal.scss';

import { INITIAL_FORM_CONFIG } from '@aduvi/constants';
import { useBusiness } from '@aduvi/hooks';
import { IEntityType } from '@aduvi/types/entity';
import { normalToKebabCase } from '@aduvi/utils/helper';

import { createEntityForm, updateEntityForm } from 'store/features/form-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

interface IProps {
  entityType?: IEntityType;
  formType: string;
  onCancel: () => void;
}
export const AddFormModal = ({ entityType, formType, onCancel }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const [form] = useForm();

  const { creating, updating, selectedEntityForm } = useAppSelector((state) => state.forms);

  const onSubmit = () => {
    if (!selectedBusiness?.id || !entityType?.id) return;
    form
      .validateFields()
      .then(({ name }) => {
        if (!selectedEntityForm) {
          dispatch(
            createEntityForm({
              payload: {
                ...INITIAL_FORM_CONFIG,
                name,
                form_url: normalToKebabCase(name),
                business_id: selectedBusiness?.id,
                entity_type_id: entityType.id,
              },
              form_type: formType,
            }),
          )
            .unwrap()
            .then(({ data }) => {
              form.resetFields();
              onCancel();
              navigate(`/${entityType.id}/forms/${data.id}`);
            })
            .catch(() => {});
        } else {
          dispatch(updateEntityForm({ ...selectedEntityForm, name }))
            ?.unwrap()
            ?.then(() => onCancel())
            .catch(() => {});
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!selectedEntityForm) return;
    form.setFieldsValue(selectedEntityForm);
  });

  return (
    <Modal
      open={true}
      title={translate('forms.forms.createNewForm')}
      onCancel={onCancel}
      className='add-form-wrapper'
      footer={[
        <Button disabled={creating || updating} loading={creating || updating} key='submit' type='primary' onClick={onSubmit}>
          {selectedEntityForm ? translate('buttons.update') : translate('forms.forms.createEdit')}
        </Button>,
      ]}>
      <Divider className='w-full m-0' />
      <Form form={form} className='m-20' layout='vertical' requiredMark={false}>
        <Form.Item name='name' label={translate('forms.forms.formName')} rules={[{ required: true }]}>
          <Input placeholder={translate('forms.forms.formName')} />
        </Form.Item>
      </Form>
      <Divider />
    </Modal>
  );
};
