import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Constants } from '@aduvi/constants';
import { EAuthorized, IForgotPasswordPayload, ILoginPayload, IResetPasswordPayload, IValidateTokenPayload } from '@aduvi/types/auth';
import { IClientPortalAuthState } from '@aduvi/types/client-portal';
import { ELoadingState } from '@aduvi/types/common';

import * as ClientAuthServices from 'store/services/client-auth.service';

const initialState: IClientPortalAuthState = {
  token: null,
  refreshToken: null,
  authActionState: ELoadingState.UNINITIALIZED,
  isAuthorized: EAuthorized.UNINITIALIZED,
  loginState: ELoadingState.UNINITIALIZED,
  forgetPasswordState: ELoadingState.UNINITIALIZED,
  resetPasswordState: ELoadingState.UNINITIALIZED,
  sendMagicLinkState: ELoadingState.UNINITIALIZED,
  validateTokenState: ELoadingState.UNINITIALIZED,

  user: {
    user: undefined,
    jobs: {
      loading: false,
      data: undefined,
      selectedJob: undefined,
    },
    orders: {
      loading: false,
      data: undefined,
      selectedOrder: undefined,
    },
    quotes: {
      loading: false,
      data: undefined,
      selectedQuote: undefined,
    },
    loading: false,
    updating: false,
    resettingPassword: false,
  },
};

export const login = createAsyncThunk('client-auth/login', async (params: { businessId: string; body: ILoginPayload }, { rejectWithValue }) => {
  try {
    return await ClientAuthServices.login(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const forgetPassword = createAsyncThunk(
  'client-auth/forget-password',
  async (params: { businessId: string; clientPortalId: string; body: IForgotPasswordPayload }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.forgetPassword(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const sendMagicLink = createAsyncThunk(
  'client-auth/send-magic-link',
  async (params: { businessId: string; clientPortalId: string; body: { email: string } }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.sendMagicLink(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const loginWithMagicLink = createAsyncThunk(
  'client-auth/login-magic-link',
  async (params: { businessId: string; clientPortalId: string; body: { token: string } }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.loginWithMagicLink(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'client-auth/reset-password',
  async (params: { businessId: string; clientPortalId: string; body: IResetPasswordPayload }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.resetPassword(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const validateToken = createAsyncThunk(
  'client-auth/validate-token',
  async (params: { businessId: string; clientPortalId: string; body: IValidateTokenPayload }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.validateToken(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getClientData = createAsyncThunk('client/get-client-data', async (params: { businessId: string }, { rejectWithValue }) => {
  try {
    const user = await ClientAuthServices.getClientData(params);

    return user;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getBusiness = createAsyncThunk('business/get-business-id', async (_, { rejectWithValue }) => {
  try {
    return await ClientAuthServices.getBusiness();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getClientJobs = createAsyncThunk('client-portal/get-client-jobs', async (business_id: string, { rejectWithValue }) => {
  try {
    return await ClientAuthServices.getClientJobs(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getClientJobById = createAsyncThunk(
  'client-portal/get-client-job',
  async (params: { business_id: string; jobId: string }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.getClientJobById(params.business_id, params.jobId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getClientOrders = createAsyncThunk('client-portal/get-client-orders', async (business_id: string, { rejectWithValue }) => {
  try {
    return await ClientAuthServices.getClientOrders(business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getClientOrderById = createAsyncThunk(
  'client-portal/get-client-order',
  async (params: { business_id: string; orderId: string }, { rejectWithValue }) => {
    try {
      return await ClientAuthServices.getClientOrderById(params.business_id, params.orderId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const clientPortalAuthSlice = createSlice({
  name: 'client-auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    setAuthorization: (state, action) => {
      state.isAuthorized = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.refreshToken = null;
      state.isAuthorized = EAuthorized.UNAUTHORIZED;
      state.authActionState = ELoadingState.UNINITIALIZED;
    },
    setToken: (state, { payload }) => {
      state.isAuthorized = EAuthorized.AUTHORIZED;
      state.token = payload.data.access_token;
      localStorage.setItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN, payload.data.access_token);
    },
    setUserData: (state, { payload }) => {
      state.user.user = payload;
    },
    setSelectedJob: (state, { payload }) => {
      state.user.jobs.selectedJob = payload;
    },
    setSelectedOrder: (state, { payload }) => {
      state.user.orders.selectedOrder = payload;
    },
    setSelectedQuote: (state, { payload }) => {
      state.user.quotes.selectedQuote = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginState = ELoadingState.LOADING;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loginState = ELoadingState.SUCCESS;
        if (payload?.data?.access_token) {
          state.token = payload.data.access_token;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          localStorage.setItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN, payload.data.access_token);
        }
      })
      .addCase(login.rejected, (state) => {
        state.loginState = ELoadingState.FAILED;
      })
      .addCase(forgetPassword.pending, (state) => {
        state.forgetPasswordState = ELoadingState.LOADING;
      })
      .addCase(forgetPassword.fulfilled, (state) => {
        state.forgetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(forgetPassword.rejected, (state) => {
        state.forgetPasswordState = ELoadingState.FAILED;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordState = ELoadingState.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordState = ELoadingState.FAILED;
      })
      .addCase(getClientData.pending, (state) => {
        state.user.loading = true;
      })
      .addCase(validateToken.pending, (state) => {
        state.validateTokenState = ELoadingState.LOADING;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.validateTokenState = ELoadingState.SUCCESS;
      })
      .addCase(validateToken.rejected, (state) => {
        state.validateTokenState = ELoadingState.FAILED;
      })
      .addCase(getClientData.fulfilled, (state, { payload }) => {
        state.user.loading = false;
        state.user.user = payload?.data;
      })
      .addCase(getClientData.rejected, (state) => {
        state.user.loading = false;
      })
      .addCase(getClientJobs.pending, (state) => {
        state.user.jobs.loading = true;
      })
      .addCase(getClientJobs.fulfilled, (state, { payload }) => {
        state.user.jobs.loading = false;
        state.user.jobs.data = payload.data.jobs;
      })
      .addCase(getClientJobs.rejected, (state) => {
        state.user.jobs.loading = false;
        state.user.jobs.data = undefined;
      })
      .addCase(getClientJobById.pending, (state) => {
        state.user.jobs.loading = true;
        state.user.quotes.loading = true;
      })
      .addCase(getClientJobById.fulfilled, (state, { payload }) => {
        state.user.jobs.loading = false;
        state.user.jobs.selectedJob = payload.data;
        state.user.quotes.data = payload.data.quotes;
      })
      .addCase(getClientJobById.rejected, (state) => {
        state.user.jobs.loading = false;
        state.user.quotes.loading = false;
        state.user.jobs.selectedJob = undefined;
        state.user.quotes.data = undefined;
      })
      .addCase(getClientOrders.pending, (state) => {
        state.user.orders.loading = true;
      })
      .addCase(getClientOrders.fulfilled, (state, { payload }) => {
        state.user.orders.loading = false;
        state.user.orders.data = payload.data.orders;
      })
      .addCase(getClientOrders.rejected, (state) => {
        state.user.orders.loading = false;
        state.user.orders.data = undefined;
      })
      .addCase(getClientOrderById.pending, (state) => {
        state.user.orders.loading = true;
        state.user.quotes.loading = false;
      })
      .addCase(getClientOrderById.fulfilled, (state, { payload }) => {
        state.user.jobs.loading = false;
        state.user.orders.selectedOrder = payload.data;
        state.user.quotes.data = payload.data.quotes;
      })
      .addCase(getClientOrderById.rejected, (state) => {
        state.user.jobs.loading = false;
        state.user.orders.selectedOrder = undefined;
        state.user.quotes.data = undefined;
      })
      .addCase(sendMagicLink.pending, (state) => {
        state.sendMagicLinkState = ELoadingState.LOADING;
      })
      .addCase(sendMagicLink.fulfilled, (state) => {
        state.sendMagicLinkState = ELoadingState.SUCCESS;
      })
      .addCase(sendMagicLink.rejected, (state) => {
        state.sendMagicLinkState = ELoadingState.FAILED;
      })
      .addCase(loginWithMagicLink.pending, (state) => {
        state.loginState = ELoadingState.LOADING;
        state.isAuthorized = EAuthorized.UNINITIALIZED;
      })
      .addCase(loginWithMagicLink.fulfilled, (state, { payload }) => {
        state.loginState = ELoadingState.SUCCESS;
        if (payload?.data?.access_token) {
          state.token = payload.data.access_token;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          localStorage.setItem(Constants.LocalStorage.Authorization.ACCESS_TOKEN, payload.data.access_token);
        }
        state.user.user = payload?.data;
      })
      .addCase(loginWithMagicLink.rejected, (state) => {
        state.loginState = ELoadingState.FAILED;
        state.isAuthorized = EAuthorized.UNAUTHORIZED;
      });
  },
});

export const clientPortalAuthReducer = clientPortalAuthSlice.reducer;
export const { resetAuthState, setAuthorization, logout, setToken, setUserData, setSelectedJob, setSelectedOrder, setSelectedQuote } =
  clientPortalAuthSlice.actions;
