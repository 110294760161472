import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Files from 'views/files/Files';

import { Button, Form, Modal, Row, Tabs } from 'antd';
import './MediaLibraryModal.scss';

import { Libraries } from '@aduvi/components/MediaLibraryModal/modal-tabs/Libraries';
import { EFileUploadTabs } from '@aduvi/types/file';

interface MediaLibraryModalProps {
  open: boolean;
  onCancel: () => void;
  creating?: boolean;
  updating?: boolean;
  onSelect: (urls: string[]) => void;
  uploadedImageUrls?: string[];

  uploadComponent: React.ReactElement;
}

export const MediaLibraryModal: React.FC<MediaLibraryModalProps> = ({
  open,
  onCancel,
  onSelect,
  creating,
  updating,
  uploadComponent,
  uploadedImageUrls,
}) => {
  const [form] = Form.useForm();
  const { t: translate } = useTranslation();

  const [tab, setTab] = useState<string>(EFileUploadTabs.UPLOAD);

  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);

  useEffect(() => {
    if (!uploadedImageUrls?.length) return;

    setSelectedUrls(uploadedImageUrls);
  }, [uploadedImageUrls]);

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      destroyOnClose={true}
      width={1400}
      zIndex={10000000}
      className='media-library-wrapper'
      bodyStyle={{ height: 700 }}
      footer={[
        <Button key='submit' type='primary' onClick={() => onSelect(selectedUrls)} loading={creating || updating}>
          {translate('file.select')}
        </Button>,
      ]}>
      <Row className='h-full' style={{ overflow: 'auto' }}>
        <Form className='w-full' form={form} layout='vertical' requiredMark={false}>
          <Tabs
            className='h-full'
            defaultActiveKey={EFileUploadTabs.UPLOAD}
            activeKey={tab}
            onChange={setTab}
            items={[
              {
                style: { minHeight: 650, maxHeight: 654, height: 654 },
                key: EFileUploadTabs.FILES,
                label: translate('file.files'),
                children: (
                  <Files
                    readOnly={true}
                    onSelectionChange={(selectedFiles) => {
                      const urls = selectedFiles?.map((file) => file?.url);
                      setSelectedUrls(urls);
                    }}
                  />
                ),
              },
              {
                style: { minHeight: 650, maxHeight: 654, height: 654 },
                key: EFileUploadTabs.LIBRARIES,
                label: translate('file.libraries'),
                children: (
                  <Libraries
                    onSelectionChange={(selectedFiles) => {
                      const urls = selectedFiles?.map((file) => file?.url);
                      setSelectedUrls(urls);
                    }}
                  />
                ),
              },
              {
                key: EFileUploadTabs.UPLOAD,
                label: translate('file.upload'),
                children: uploadComponent,
              },
            ]}
          />
        </Form>
      </Row>
    </Modal>
  );
};
