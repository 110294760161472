import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderlessTableOutlined, RightOutlined } from '@ant-design/icons';

import { Button, Card, Dropdown, Input, Row } from 'antd';

import { applyNumberFormat } from '@aduvi/components/CustomField/helper';
import { PREDEFINED_NUMBER_FORMATS } from '@aduvi/constants';
import { useBusinessCurrency } from '@aduvi/hooks';
import { ETextAlignment, ICustomFieldViewStyle } from '@aduvi/types';
import { IFieldData } from '@aduvi/types/entity';

export const NumberFormat = ({
  updateStyleForFieldId,
  fieldData,
}: {
  updateStyleForFieldId: (newStyle: Partial<ICustomFieldViewStyle['style']>) => void;
  fieldData?: IFieldData;
}) => {
  const { t: translate } = useTranslation();
  const businessCurrency = useBusinessCurrency();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [alignment, setAlignment] = useState<ETextAlignment.LEFT | ETextAlignment.RIGHT>(ETextAlignment.LEFT);
  const [customValue, setCustomValue] = useState('');

  const handleNumberFormatChange = (format: string) => {
    updateStyleForFieldId({ numberFormat: format });
    setDropdownVisible(false);
  };

  const handleCustomValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCustomValue(newValue);
    updateStyleForFieldId({ numberFormat: newValue });
  };

  const handleAlignmentChange = (newAlignment: ETextAlignment) => {
    setAlignment(newAlignment);
    updateStyleForFieldId({ alignment: newAlignment });
  };
  const parsedValue = parseFloat(fieldData?.value as string);
  return (
    <Dropdown
      overlay={
        <Card className='style-modal'>
          <div className='flex flex-direction-column'>
            {PREDEFINED_NUMBER_FORMATS.map((format) => (
              <Button
                type='text'
                key={format.value}
                className='number-format-item cursor-pointer text-left'
                onClick={() => handleNumberFormatChange(format.value)}>
                {applyNumberFormat(parsedValue, format?.value, alignment as ETextAlignment, {
                  code: businessCurrency?.code as string,
                  format: businessCurrency?.format as string,
                })}
              </Button>
            ))}

            <div className='mt-10 flex' style={{ gap: '4px' }}>
              <Input className='w-half' placeholder='Custom' value={customValue} onChange={handleCustomValueChange} />
              <Button onClick={() => handleAlignmentChange(ETextAlignment.LEFT)} type={alignment === ETextAlignment.LEFT ? 'primary' : 'default'}>
                L
              </Button>
              <Button onClick={() => handleAlignmentChange(ETextAlignment.RIGHT)} type={alignment === ETextAlignment.RIGHT ? 'primary' : 'default'}>
                R
              </Button>
            </div>
          </div>
        </Card>
      }
      trigger={['click']}
      open={dropdownVisible}
      onOpenChange={(visible) => setDropdownVisible(visible)}>
      <Row>
        <Button icon={<BorderlessTableOutlined />} type='text' className='w-full text-left flex align-center'>
          {translate('formatFields.numberFormat')}
          <RightOutlined className='fs-10' style={{ marginLeft: '20px' }} />
        </Button>
      </Row>
    </Dropdown>
  );
};
