import { ISettingsLocale } from '@aduvi/types/constants';

export enum SLICE_TAG_TYPES {
  ENTITY = 'ENTITY',
  PUBLIC_ENTITY = 'PUBLIC_ENTITY',
  USER = 'USER',
  COUPONS = 'COUPONS',
  DISCOUNT_SETTINGS = 'DISCOUNT_SETTINGS',
  CONTRACTS = 'CONTRACTS',
  ACADEMY = 'ACADEMY',
  ACADEMY_ACHIEVEMENTS = 'ACADEMY_ACHIEVEMENTS',
  TEAMS = 'TEAMS',
  TAX = 'TAX',
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
}

export const Constants = {
  LocalStorage: {
    Authorization: {
      ACCESS_TOKEN: 'access_token',
      REFRESH_TOKEN: 'refresh_token',
    },
  },
  Links: {
    CustomMarkerIcon: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
    MenuExternalLink: 'external-link',
  },
  Regex: {
    UUID: /([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/g,
  },
};

export const CURRENCY_SYMBOL_DISPLAY_OPTIONS: ISettingsLocale[] = [
  { value: 'before', label: 'Before' },
  { value: 'after', label: 'After' },
];

export const PERMISSIONS = {
  JOBS: {
    VIEW: 'JOBS.VIEW',
    CREATE: 'JOBS.CREATE',
    EDIT: 'JOBS.EDIT',
    DELETE: 'JOBS.DELETE',
    JOBS: 'JOBS.JOBS',
  },
  USERS: {
    VIEW: 'USERS.VIEW',
    CREATE: 'USERS.CREATE',
    EDIT: 'USERS.EDIT',
    DELETE: 'USERS.DELETE',
    INVITE: 'USERS.INVITE',
    IMPORT: 'USERS.IMPORT',
    EXPORT: 'USERS.EXPORT',
    VIEW_PERMISSIONS: 'USERS.VIEW_PERMISSIONS',
  },
  TEAMS: {
    VIEW: 'TEAMS.VIEW',
    CREATE: 'TEAMS.CREATE',
    EDIT: 'TEAMS.EDIT',
    DELETE: 'TEAMS.DELETE',
  },
  PROPOSALS: {
    VIEW: 'PROPOSALS.VIEW',
    CREATE: 'PROPOSALS.CREATE',
    EDIT: 'PROPOSALS.EDIT',
    DELETE: 'PROPOSALS.DELETE',
  },
  CONTRACTS: {
    VIEW: 'CONTRACTS.VIEW',
    CREATE: 'CONTRACTS.CREATE',
    EDIT: 'CONTRACTS.EDIT',
    DELETE: 'CONTRACTS.DELETE',
  },
  PAYMENT_SETTING: {
    VIEW: 'PAYMENT_SETTING.VIEW',
    CREATE: 'PAYMENT_SETTING.CREATE',
    EDIT: 'PAYMENT_SETTING.EDIT',
    DELETE: 'PAYMENT_SETTING.DELETE',
  },
  FORMS: {
    VIEW: 'FORMS.VIEW',
    CREATE: 'FORMS.CREATE',
    EDIT: 'FORMS.EDIT',
    DELETE: 'FORMS.DELETE',
  },
  TASKS: {
    VIEW: 'TASKS.VIEW',
    CREATE: 'TASKS.CREATE',
    EDIT: 'TASKS.EDIT',
    DELETE: 'TASKS.DELETE',
  },
  REPORTS: {
    VIEW: 'REPORTS.VIEW',
    VIEW_ACTIVITY_LOGS: 'REPORTS.VIEW_ACTIVITY_LOGS',
    EXPORT_DATA: 'REPORTS.EXPORT_DATA',
  },

  TAX: {
    VIEW: 'TAX.VIEW',
    CREATE: 'TAX.CREATE',
    EDIT: 'TAX.EDIT',
    DELETE: 'TAX.DELETE',
  },

  BUSINESS: {
    VIEW: 'BUSINESS.VIEW',
    EDIT: 'BUSINESS.EDIT',
    DELETE: 'BUSINESS.DELETE',
    VIEW_PERMISSIONS: 'BUSINESS.VIEW_PERMISSIONS',
    CALENDAR: 'BUSINESS.CALENDAR',
    SEARCH: 'BUSINESS.SEARCH',
    CONVERSATION: 'BUSINESS.CONVERSATION',
  },
  PRODUCTS: {
    VIEW: 'PRODUCTS.VIEW',
    CREATE: 'PRODUCTS.CREATE',
    EDIT: 'PRODUCTS.EDIT',
    DELETE: 'PRODUCTS.DELETE',
    CATEGORIES: 'PRODUCTS.CATEGORIES',
  },
  SERVICES: {
    VIEW: 'SERVICES.VIEW',
    CREATE: 'SERVICES.CREATE',
    EDIT: 'SERVICES.EDIT',
    DELETE: 'SERVICES.DELETE',
    SERVICES: 'SERVICES.SERVICES',
  },
  LEADS: {
    VIEW: 'LEADS.VIEW',
    CREATE: 'LEADS.CREATE',
    EDIT: 'LEADS.EDIT',
    DELETE: 'LEADS.DELETE',
    LEADS: 'LEADS.LEADS',
  },
  LOCATIONS: {
    VIEW: 'LOCATIONS.VIEW',
    CREATE: 'LOCATIONS.CREATE',
    EDIT: 'LOCATIONS.EDIT',
    DELETE: 'LOCATIONS.DELETE',
  },
  BRANDINGS: {
    VIEW: 'BRANDINGS.VIEW',
    CREATE: 'BRANDINGS.CREATE',
    EDIT: 'BRANDINGS.EDIT',
    DELETE: 'BRANDINGS.DELETE',
    BRANDINGS: 'BRANDINGS.BRANDINGS',
  },
  DISCOUNTS: {
    VIEW: 'DISCOUNTS.VIEW',
    CREATE: 'DISCOUNTS.CREATE',
    EDIT: 'DISCOUNTS.EDIT',
    DELETE: 'DISCOUNTS.DELETE',
  },
  COUPONS: {
    VIEW: 'COUPONS.VIEW',
    CREATE: 'COUPONS.CREATE',
    EDIT: 'COUPONS.EDIT',
    DELETE: 'COUPONS.DELETE',
  },
  BUSINESS_PLANS: {
    VIEW: 'BUSINESS_PLANS.VIEW',
    CREATE: 'BUSINESS_PLANS.CREATE',
    EDIT: 'BUSINESS_PLANS.EDIT',
    DELETE: 'BUSINESS_PLANS.DELETE',
    BUSINESS_PLANS: 'BUSINESS_PLANS.BUSINESS_PLANS',
  },
  BILLING_DETAILS: {
    VIEW: 'BILLING_DETAILS.VIEW',
    CREATE: 'BILLING_DETAILS.CREATE',
    EDIT: 'BILLING_DETAILS.EDIT',
  },
  JOB_FIELDS: {
    VIEW: 'JOB_FIELDS.VIEW',
    CREATE: 'JOB_FIELDS.CREATE',
    EDIT: 'JOB_FIELDS.EDIT',
    DELETE: 'JOB_FIELDS.DELETE',
  },
  VENUE_FIELDS: {
    VIEW: 'VENUE_FIELDS.VIEW',
    CREATE: 'VENUE_FIELDS.CREATE',
    EDIT: 'VENUE_FIELDS.EDIT',
    DELETE: 'VENUE_FIELDS.DELETE',
  },
  VENUES: {
    VIEW: 'VENUES.VIEW',
    CREATE: 'VENUES.CREATE',
    EDIT: 'VENUES.EDIT',
    DELETE: 'VENUES.DELETE',
  },
  CONTACT_FIELDS: {
    VIEW: 'CONTACT_FIELDS.VIEW',
    CREATE: 'CONTACT_FIELDS.CREATE',
    EDIT: 'CONTACT_FIELDS.EDIT',
    DELETE: 'CONTACT_FIELDS.DELETE',
  },
  CONTACTS: {
    VIEW: 'CONTACTS.VIEW',
    CREATE: 'CONTACTS.CREATE',
    EDIT: 'CONTACTS.EDIT',
    DELETE: 'CONTACTS.DELETE',
  },
  ORDERS: {
    VIEW: 'ORDERS.VIEW',
    CREATE: 'ORDERS.CREATE',
    EDIT: 'ORDERS.EDIT',
    DELETE: 'ORDERS.DELETE',
    ORDERS: 'ORDERS.ORDERS',
  },
  ORDER_FIELDS: {
    VIEW: 'ORDER_FIELDS.VIEW',
    CREATE: 'ORDER_FIELDS.CREATE',
    EDIT: 'ORDER_FIELDS.EDIT',
    DELETE: 'ORDER_FIELDS.DELETE',
  },
  EVENTS: {
    VIEW: 'EVENTS.VIEW',
    CREATE: 'EVENTS.CREATE',
    EDIT: 'EVENTS.EDIT',
    DELETE: 'EVENTS.DELETE',
  },
  EVENT_FIELDS: {
    VIEW: 'EVENT_FIELDS.VIEW',
    CREATE: 'EVENT_FIELDS.CREATE',
    EDIT: 'EVENT_FIELDS.EDIT',
    DELETE: 'EVENT_FIELDS.DELETE',
  },
  EXHIBITOR_FIELDS: {
    VIEW: 'EXHIBITOR_FIELDS.VIEW',
    CREATE: 'EXHIBITOR_FIELDS.CREATE',
    EDIT: 'EXHIBITOR_FIELDS.EDIT',
    DELETE: 'EXHIBITOR_FIELDS.DELETE',
  },
  MENU_SETTING: {
    VIEW: 'MENU_SETTING.VIEW',
    CREATE: 'MENU_SETTING.CREATE',
    EDIT: 'MENU_SETTING.EDIT',
    DELETE: 'MENU_SETTING.DELETE',
  },
  EXHIBITORS: {
    VIEW: 'EXHIBITORS.VIEW',
    CREATE: 'EXHIBITORS.CREATE',
    EDIT: 'EXHIBITORS.EDIT',
    DELETE: 'EXHIBITORS.DELETE',
  },
  PERSONALIZED_VIEWS: {
    VIEW: 'PERSONALIZED_VIEWS.VIEW',
    CREATE: 'PERSONALIZED_VIEWS.CREATE',
    EDIT: 'PERSONALIZED_VIEWS.EDIT',
    DELETE: 'PERSONALIZED_VIEWS.DELETE',
  },
  CLIENT_PORTAL: {
    VIEW: 'CLIENT_PORTAL.VIEW',
    CREATE: 'CLIENT_PORTAL.CREATE',
    EDIT: 'CLIENT_PORTAL.EDIT',
    DELETE: 'CLIENT_PORTAL.DELETE',
  },
  FILES: {
    VIEW: 'FILE.VIEW',
    CREATE: 'FILE.CREATE',
    EDIT: 'FILE.EDIT',
    DELETE: 'FILE.DELETE',
  },
  AUTOMATIONS: {
    VIEW: 'AUTOMATIONS.VIEW',
    CREATE: 'AUTOMATIONS.CREATE',
    EDIT: 'AUTOMATIONS.EDIT',
    DELETE: 'AUTOMATIONS.DELETE',
  },
  AFFILIATES: {
    VIEW: 'AFFILIATES.VIEW',
    CREATE: 'AFFILIATES.CREATE',
    EDIT: 'AFFILIATES.EDIT',
    DELETE: 'AFFILIATES.DELETE',
  },
  QUOTES: {
    VIEW: 'QUOTES.VIEW',
    CREATE: 'QUOTES.CREATE',
    EDIT: 'QUOTES.EDIT',
    DELETE: 'QUOTES.DELETE',
  },
  PROJECTS: {
    VIEW: 'PROJECTS.VIEW',
    CREATE: 'PROJECTS.CREATE',
    EDIT: 'PROJECTS.EDIT',
    DELETE: 'PROJECTS.DELETE',
  },
  PROJECT_FIELDS: {
    VIEW: 'PROJECT_FIELDS.VIEW',
    CREATE: 'PROJECT_FIELDS.CREATE',
    EDIT: 'PROJECT_FIELDS.EDIT',
    DELETE: 'PROJECT_FIELDS.DELETE',
  },
  TRAVEL_FEES: {
    VIEW: 'PROJECT_FIELDS.VIEW',
    CREATE: 'PROJECT_FIELDS.CREATE',
    EDIT: 'PROJECT_FIELDS.EDIT',
    DELETE: 'PROJECT_FIELDS.DELETE',
  },
  REPS: {
    VIEW: 'REPS.VIEW',
    CREATE: 'REPS.CREATE',
    EDIT: 'REPS.EDIT',
    DELETE: 'REPS.DELETE',
  },
};

export const ADUVI_DESCRIPTION = `
Aduvi is CRM that supports all business needs.
1. Jobs or Leads or Bookings, you can go to the settings find Job Management, and create custom fields for leads or bookings. After configuring the custom fields, you can go to the Leads/ or bookings and create Lead or Booking with the configured fields.

2. Venues, you can go to the settings find Venue Management, and create custom fields for a venue. After configuring the custom fields, you can go to the Venue and create venue with the configured fields.

3. Contacts, you can go to the settings find Contacts Management, and create custom fields for a contact. After configuring the custom fields, you can go to the Contacts and create contact with the configured fields.

4. Orders, you can go to the settings find Order Management, and create custom fields for a contact. After configuring the custom fields, you can go to the Orders and create order with the configured fields.

5. Events, you can go to the settings find Event Management, and create custom fields for a contact. After configuring the custom fields, you can go to the Assets find Events tab and create event with the configured fields.

IMPORTANT: You will have some questions and try to response according informations provided above.
IMPORTANT: Please try to keep as short as you can imagining that this is a chat conversation.
`;
