import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Empty } from 'antd';
import './ContactsRow.scss';

import { getStatusForEntities } from '@aduvi/components/PersonalizedViewComponents/PredefinedViews/helper';
import { Title } from '@aduvi/components/Title';
import { useActiveTheme, useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EPersonalizedView, EPersonalizedViewOrigin, ICustomFieldViewStyle } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { getEntities } from 'store/features/entity-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { SingleContact } from './SingleContact';

interface IProps {
  data: IEntityWithFields[];
  searchKey: string;
  contactColumns: ICustomFieldViewStyle[];
  onClick: (contact: IEntityWithFields) => void;
  onEdit: (contact: IEntityWithFields) => void;
  onDelete: (contact: IEntityWithFields) => void;
}

export const ContactsRows = ({ data, contactColumns, searchKey, onClick, onEdit, onDelete }: IProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();
  const { entityTypes } = useAppSelector((state) => state.entity);
  const activeTheme = useActiveTheme();
  const groupedContacts: { [key: string]: IEntityWithFields[] } = {};

  if (!searchKey.trim()) {
    for (let i = 65; i <= 90; i++) {
      const letter = String.fromCharCode(i);
      groupedContacts[letter] = [];
    }
  }

  data.forEach((contact) => {
    if (
      !contact.custom_fields?.some(
        (item) =>
          [EFieldDataValueType.FIELD_DATA_TEXTS, EFieldDataValueType.FIELD_DATA_EMAILS, EFieldDataValueType.FIELD_DATA_PHONES].includes(
            item.value_type,
          ) && item?.field_data?.[0]?.value?.toString().toLowerCase().includes(searchKey.toLowerCase()),
      )
    )
      return;
    const firstLetter =
      contact?.custom_fields
        ?.find((field) => field.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)
        ?.field_data?.[0]?.value?.toString()?.[0]
        ?.toUpperCase() || '';
    groupedContacts[firstLetter] = groupedContacts[firstLetter] || [];

    groupedContacts[firstLetter]?.push(contact);
  });

  useEffect(() => {
    if (selectedBusiness?.id && entityTypes?.data?.CONTACT?.id) {
      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityTypes?.data?.CONTACT?.id,
          params: {
            status: getStatusForEntities(EPersonalizedViewOrigin.CONTACTS),
            view_display: EPersonalizedView.ADDRESS_BOOK,
          },
        }),
      );
    }
  }, [selectedBusiness?.id, entityTypes?.data?.CONTACT?.id]);

  return (
    <Col style={{ padding: 0 }} className='contacts-rows-wrapper mt-20'>
      {Object.keys(groupedContacts).map((letter) => (
        <Col style={{ padding: 0 }} key={letter}>
          <Title className='single-letter' style={{ color: activeTheme?.buttonBg }}>
            {letter}
          </Title>
          {groupedContacts[letter].length ? (
            groupedContacts[letter].map((contact) => (
              <SingleContact
                contactColumns={contactColumns}
                key={contact.id}
                contact={contact}
                onClick={onClick}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={translate('contacts.noContacts')} />
          )}
        </Col>
      ))}
    </Col>
  );
};
