import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICoupon, IDiscountSettings, IDiscountsState, IGetCouponsPayload } from '@aduvi/types/discount';

import * as DiscountServices from 'store/services/discount.service';

const initialState: IDiscountsState = {
  coupons: undefined,
  selectedCoupon: undefined,
  discountSettings: undefined,
  loading: false,
  creating: false,
  updating: false,
};

export const getDiscountSettings = createAsyncThunk('discount-settings/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await DiscountServices.getDiscountSettings(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const createDiscountSettings = createAsyncThunk(
  'discount-settings/create',
  async (params: Omit<IDiscountSettings, 'id'> & { business_id: string }, { rejectWithValue }) => {
    try {
      return await DiscountServices.createDiscountSettings(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const editDiscountSettings = createAsyncThunk(
  'discount-settings/edit',
  async (params: IDiscountSettings & { business_id: string }, { rejectWithValue }) => {
    try {
      return await DiscountServices.editDiscountSettings(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const createCoupon = createAsyncThunk('coupons/create', async (params: { payload: ICoupon; business_id: string }, { rejectWithValue }) => {
  try {
    return await DiscountServices.createCoupon(params.payload, params.business_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const getCoupons = createAsyncThunk('coupons/get-all', async (params: IGetCouponsPayload, { rejectWithValue }) => {
  try {
    return await DiscountServices.getCoupons(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const editCoupon = createAsyncThunk(
  'coupons/edit',
  async (params: { payload: ICoupon; business_id: string; coupon_id: string }, { rejectWithValue }) => {
    try {
      return await DiscountServices.editCoupon(params.payload, params.business_id, params.coupon_id);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const deleteCoupon = createAsyncThunk(
  'coupons/delete',
  async (params: { business_id: string; coupon_id: string; cupon_name: string }, { rejectWithValue }) => {
    try {
      return await DiscountServices.deleteCoupon(params.business_id, params.coupon_id, params.cupon_name);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const cloneCoupon = createAsyncThunk('coupons/clone', async (params: { business_id: string; coupon_id: string }, { rejectWithValue }) => {
  try {
    return await DiscountServices.cloneCoupon(params.business_id, params.coupon_id);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    resetCouponsState: (state) => ({ ...initialState, discountSettings: state.discountSettings }),
    setSelectedCoupon: (state, action: PayloadAction<ICoupon | undefined>) => {
      state.selectedCoupon = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDiscountSettings.pending, (state) => {
        state.discountSettings = undefined;
        state.loading = true;
      })
      .addCase(getDiscountSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDiscountSettings.fulfilled, (state, { payload }) => {
        state.discountSettings = payload.data[0];
        state.loading = false;
      })
      .addCase(createDiscountSettings.pending, (state) => {
        state.creating = true;
      })
      .addCase(createDiscountSettings.fulfilled, (state, { payload }) => {
        state.discountSettings = payload.data;
        state.creating = false;
      })
      .addCase(createDiscountSettings.rejected, (state) => {
        state.creating = false;
      })
      .addCase(editDiscountSettings.pending, (state) => {
        state.updating = true;
      })
      .addCase(editDiscountSettings.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.discountSettings = { ...state.discountSettings, ...payload.data };
      })
      .addCase(editDiscountSettings.rejected, (state) => {
        state.updating = false;
      })
      .addCase(createCoupon.pending, (state) => {
        state.creating = true;
      })
      .addCase(createCoupon.fulfilled, (state, { payload }) => {
        if (state.coupons) {
          state.coupons.data = [...state.coupons.data, payload.data];
        }
        state.creating = false;
      })
      .addCase(createCoupon.rejected, (state) => {
        state.creating = false;
      })
      .addCase(getCoupons.pending, (state) => {
        state.loading = true;
        state.coupons = undefined;
      })
      .addCase(getCoupons.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.coupons = payload;
      })
      .addCase(getCoupons.rejected, (state) => {
        state.loading = false;
        state.coupons = undefined;
      })
      .addCase(editCoupon.pending, (state) => {
        state.updating = true;
      })
      .addCase(editCoupon.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.coupons) {
          state.coupons.data = state.coupons?.data.map((item) => {
            return item.id === payload.data.id ? payload.data : item;
          });
        }
      })
      .addCase(editCoupon.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteCoupon.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCoupon.fulfilled, (state, { meta }) => {
        if (state.coupons) {
          state.coupons = {
            ...state.coupons,
            data: state.coupons.data.filter((coupon) => coupon.id !== meta.arg.coupon_id),
          };
        }
        state.loading = false;
      })
      .addCase(deleteCoupon.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cloneCoupon.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneCoupon.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.coupons) {
          state.coupons = {
            ...state.coupons,
            data: [...state.coupons.data, payload.data],
          };
        }
      })
      .addCase(cloneCoupon.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const discountReducer = discountSlice.reducer;
export const { resetCouponsState, setSelectedCoupon } = discountSlice.actions;
