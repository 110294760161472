import React, { useMemo, useState } from 'react';

import { Col, Row, Select, SelectProps } from 'antd';

import { IPaginatedResponse, IProduct } from '@aduvi/types';
import { generateQuantityOptions } from '@aduvi/utils/helper';

interface IProps extends SelectProps {
  products?: IPaginatedResponse<IProduct>;
  selectedProducts: string[];
  onProductSelect: (id: string) => void;
}

export const DropdownDisplayType = ({ products, selectedProducts, onProductSelect, ...props }: IProps) => {
  const [quantity, setQuantity] = useState(1);

  const selectedProductData = useMemo(() => products?.data?.find((item) => item.id === selectedProducts[0]), [selectedProducts]);

  const handleQuantityChange = (value: number, productId: string) => {
    if (value === 0) {
      onProductSelect(productId);
      setQuantity(1);
      return;
    }
    setQuantity(value);
  };

  return (
    <Row className='mr-10' gutter={4}>
      <Col span={selectedProducts.length ? 22 : 24}>
        <Select
          {...props}
          loading={!products}
          filterOption={false}
          value={selectedProducts[0]}
          options={products?.data?.map((item) => ({
            label: item.name + ' - $' + Number(item?.price || item?.upsell_price) * quantity,
            value: item.id,
          }))}
          className='w-full pl-10'
          onChange={(value) => onProductSelect(value)}
        />
      </Col>
      {selectedProducts?.length ? (
        <Col span={2}>
          <Select defaultValue={quantity} onChange={(value) => handleQuantityChange(value, selectedProducts[0])}>
            {generateQuantityOptions(
              Number(selectedProductData?.quantity_available),
              selectedProductData?.min_quantity,
              selectedProductData?.max_quantity,
            ).map((qty) => (
              <Select.Option key={qty} value={qty}>
                {qty}
              </Select.Option>
            ))}
          </Select>
        </Col>
      ) : null}
    </Row>
  );
};
