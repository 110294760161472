import React, { ReactNode } from 'react';

import { Col } from 'antd';
import './CommonSubMenu.scss';

import { useActiveTheme } from '@aduvi/hooks';

export const CommonSubMenu = ({ children, style }: { children: ReactNode; style?: React.CSSProperties }) => {
  const activeTheme = useActiveTheme();

  return (
    <Col
      md={7}
      xl={5}
      xxl={4}
      className='aduvi-common-sub-menu'
      style={{ background: activeTheme?.menuBg, boxShadow: `0 2px 8px 0 ${activeTheme?.menuShadow}`, ...style }}>
      {children}
    </Col>
  );
};
