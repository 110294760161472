import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import {
  ICreateEntityFieldPayload,
  IDeleteEntityFieldPayload,
  IEntityField,
  IEntityFieldInitialState,
  IReorderEntityFieldsPayload,
} from '@aduvi/types';

import * as FieldsService from 'store/services/fields.service';

const initialState: IEntityFieldInitialState = {
  fields: [],
  formOnlyFields: [],
  selectedField: undefined,
  loadingField: false,
  loading: false,
  creating: false,
  updating: false,
};

export const getEntityFields = createAsyncThunk(
  'fields/get-fields',
  async (params: { businessId: string; entityTypeId: string; ignoreFormOnly?: boolean }, { rejectWithValue, dispatch }) => {
    try {
      if (!params.ignoreFormOnly) dispatch(getFormOnlyEntityFields({ businessId: params?.businessId, entityTypeId: params?.entityTypeId }));

      return await FieldsService.getEntityFields(params.businessId, params.entityTypeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getFormOnlyEntityFields = createAsyncThunk(
  'fields/get-form-only-fields',
  async (params: { businessId: string; entityTypeId: string }, { rejectWithValue }) => {
    try {
      return await FieldsService.getFormOnlyEntityFields(params.businessId, params.entityTypeId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createEntityField = createAsyncThunk('fields/create-field', async (params: ICreateEntityFieldPayload, { rejectWithValue }) => {
  try {
    return await FieldsService.createEntityField(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAllEntityFields = createAsyncThunk('fields/create-field', async (params: ICreateEntityFieldPayload, { rejectWithValue }) => {
  try {
    return await FieldsService.createEntityField(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateEntityField = createAsyncThunk(
  'fields/update-field',
  async (
    params: {
      payload: ICreateEntityFieldPayload;
      fieldId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await FieldsService.updateEntityField(params.payload, params.fieldId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const reorderEntityFields = createAsyncThunk(
  'fields/reorder-entity-fields',
  async (params: IReorderEntityFieldsPayload, { rejectWithValue }) => {
    try {
      return await FieldsService.reorderEntityFields(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const deleteEntityField = createAsyncThunk('fields/delete-field', async (params: IDeleteEntityFieldPayload, { rejectWithValue }) => {
  try {
    return await FieldsService.deleteEntityFields(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {
    setSelectedEntityField: (state, { payload }: PayloadAction<IEntityField | undefined>) => {
      state.selectedField = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityFields.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEntityFields.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fields = orderBy(payload.data, 'weight', ['asc']);
      })
      .addCase(getEntityFields.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFormOnlyEntityFields.fulfilled, (state, { payload }) => {
        state.formOnlyFields = orderBy(payload.data, 'weight', ['asc']);
      })
      .addCase(createEntityField.pending, (state) => {
        state.creating = true;
      })
      .addCase(createEntityField.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.fields = [...state.fields, payload.data];
      })
      .addCase(createEntityField.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateEntityField.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateEntityField.fulfilled, (state, { payload }) => {
        state.updating = false;
        state.fields = state.fields.map((item) => (item.id === payload.data.id ? payload.data : item));
      })
      .addCase(updateEntityField.rejected, (state) => {
        state.updating = false;
      })
      .addCase(reorderEntityFields.pending, (state, { meta }) => {
        state.loading = true;
        state.fields = orderBy(
          state.fields.map((item) => ({
            ...item,
            weight: Number(meta.arg.fields.find((field) => field.field_id === item.id)?.weight),
          })),
          'weight',
        );
      })
      .addCase(reorderEntityFields.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fields = orderBy(payload.data, 'weight', ['asc']);
      })
      .addCase(reorderEntityFields.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteEntityField.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteEntityField.fulfilled, (state, { meta }) => {
        state.loading = false;
        state.fields = state.fields.filter((item) => item.id !== meta.arg.fieldId);
      })
      .addCase(deleteEntityField.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const fieldsReducer = fieldsSlice.reducer;
export const { setSelectedEntityField } = fieldsSlice.actions;
