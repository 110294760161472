import { useEffect, useMemo, useState } from 'react';
import { useDroppable } from '@dnd-kit/core';

import { Col, Pagination, PaginationProps, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './Pipeline.scss';

import { Paragraph } from '@aduvi/components/Paragraph';
import { useBusiness } from '@aduvi/hooks';
import { EFieldDataValueType, EFieldsVisibility, EPersonalizedView, EPersonalizedViewOrigin } from '@aduvi/types';
import { IEntityType, IEntityWithFields } from '@aduvi/types/entity';

import { getEntities, setCurrentPage, setCurrentPageSize } from 'store/features/entity-slice';
import { setHoveredPipelineFilterId } from 'store/features/personalized-views-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { DroppableArea } from '../../DraggableFields/droppable-area/DroppableArea';
import { getStatusForEntities } from '../helper';
import { Table } from '../Table/Table';

interface IViewPipelineProps {
  statusFieldId: string;
  data: IEntityWithFields[];
  columns?: ColumnsType<IEntityWithFields>;
  origin: EPersonalizedViewOrigin;
  entityType?: IEntityType;
  onRowClick?: (data: IEntityWithFields) => void;
  setShowEntityDrawer?: (value: React.SetStateAction<boolean>) => void;
}
export const Pipeline = ({ statusFieldId, data, columns, origin, entityType, onRowClick, setShowEntityDrawer }: IViewPipelineProps) => {
  const dispatch = useAppDispatch();
  const selectedBusiness = useBusiness();

  const { fields } = useAppSelector((state) => state.fields);
  const { selectedView } = useAppSelector((state) => state.personalizedViews);
  const { currentPage, pageSize, total, total_list_values_count } = useAppSelector((state) => state.entity.entities);

  const { setNodeRef } = useDroppable({ id: 'pipeline-filter' });

  const optionFields = useMemo(() => {
    return fields?.filter((item) => item?.value_type === EFieldDataValueType.FIELD_DATA_LISTS) || [];
  }, [fields]);
  const selectedOption = optionFields.find((item) => item.id === statusFieldId);
  const [selectedOptionField, setSelectedOptionField] = useState(selectedOption?.list_values?.[0].id);

  const filteredData = useMemo(
    () =>
      Array.isArray(data)
        ? data.filter(
            (item) =>
              item?.custom_fields?.some(
                (value) =>
                  value?.field_data?.some(
                    (option) =>
                      selectedOption?.list_values?.some((optionField) => optionField?.id === option?.id && optionField?.id === selectedOptionField),
                  ),
              ),
          )
        : [],
    [selectedOption, data, selectedOptionField],
  );

  const filteredColumns = [{ key: 'sort' }, ...(columns as ColumnsType)] as ColumnsType<IEntityWithFields>;

  const handlePaginationChange: PaginationProps['onChange'] = (currentPagePagination: number, pageSizePagination: number) => {
    if (selectedView?.view_display === 'PIPELINE') {
      dispatch(setCurrentPage(currentPagePagination));
      dispatch(setCurrentPageSize(pageSizePagination));
    }
  };

  const trackingFields = useMemo(
    () => ({
      selectedOptionField,
      selectedOption,
      selectedBusinessId: selectedBusiness?.id,
      entityTypeId: entityType?.id,
    }),
    [selectedOptionField, selectedOption, selectedBusiness?.id, entityType?.id],
  );

  useEffect(() => {
    if (selectedOption?.list_values?.[0].id) {
      setSelectedOptionField(selectedOption?.list_values?.[0].id);
    }
  }, [selectedOption?.list_values?.[0].id]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
  }, [trackingFields]);

  useEffect(() => {
    if (selectedBusiness?.id && entityType?.id) {
      dispatch(
        getEntities({
          businessId: selectedBusiness?.id,
          entityTypeId: entityType?.id,
          params: {
            status: getStatusForEntities(origin),
            view_display: EPersonalizedView.PIPELINE,
            page: currentPage,
            size: pageSize,
            paginate: true,
            field_id: selectedView?.status_field,
            list_value_id: selectedOptionField,
          },
        }),
      );
    }
  }, [currentPage, pageSize, selectedBusiness?.id, entityType?.id, selectedOptionField]);

  return (
    <Col className='w-full pipeline-wrapper'>
      {selectedOption?.list_values.length ? (
        <Col>
          <Col className='breadcrumb' ref={setNodeRef}>
            {selectedOption?.list_values
              ?.filter((item) => {
                if (entityType?.splittable) {
                  if (
                    selectedOption?.list_values?.some(
                      (item) => [EFieldsVisibility.CONFIRMED, EFieldsVisibility.UNCONFIRMED]?.includes(item?.value_type as EFieldsVisibility),
                    )
                  ) {
                    return [EPersonalizedViewOrigin.BOOKINGS]?.includes(origin)
                      ? item?.value_type === EFieldsVisibility.CONFIRMED
                      : item?.value_type === EFieldsVisibility.UNCONFIRMED;
                  }
                }
                return !item?.is_archived;
              })

              ?.map((item) => {
                return (
                  <a
                    className={item.id === selectedOptionField ? 'active' : ''}
                    key={item.id}
                    onClick={() => setSelectedOptionField(item.id)}
                    onMouseOver={() => dispatch(setHoveredPipelineFilterId(item.id))}
                    onMouseLeave={() => dispatch(setHoveredPipelineFilterId(undefined))}>
                    <Row className='breadcrumb__inner'>
                      <Paragraph className='fs-20 mb-0'>{total_list_values_count[item.id] || 0}</Paragraph>
                      <Paragraph style={{ color: item.color }}>{item.value}</Paragraph>
                    </Row>
                  </a>
                );
              })}
          </Col>
        </Col>
      ) : null}

      <DroppableArea id='droppable'>
        <Table data={filteredData} columns={filteredColumns} origin={origin} onRowClick={onRowClick} setShowEntityDrawer={setShowEntityDrawer} />
      </DroppableArea>
      {selectedView?.view_display === EPersonalizedView.PIPELINE && (
        <Pagination
          size='small'
          style={{ float: 'right' }}
          className='mt-20 py-10'
          onChange={handlePaginationChange}
          total={total}
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
        />
      )}
    </Col>
  );
};
