import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Card, Row, Typography } from 'antd';

import { useBusiness } from '@aduvi/hooks';

import { useAppSelector } from 'store/hooks';
import { useGetAllAcademiesQuery } from 'store/rtk-slice/academy-slice';

export const QuizResult = () => {
  const navigate = useNavigate();
  const selectedBusiness = useBusiness();
  const { t: translate } = useTranslation();

  const { id } = useParams();

  const { evaluatedData } = useAppSelector((state) => state.academy);

  const { data: academies } = useGetAllAcademiesQuery({ businessId: selectedBusiness?.id! }, { skip: !selectedBusiness?.id });

  const selectedAcademy = useMemo(() => {
    return academies?.data.find((item) => item?.id === id);
  }, [id, academies?.data.length]);

  let newIndex: number | undefined;
  const currentIndex = academies?.data.findIndex((academy) => academy.id === selectedAcademy?.id);

  newIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex ? currentIndex + 1 : currentIndex;

  const onNavigateToNextLesson = () => {
    if (!academies) return;
    const nextAcademy = newIndex && newIndex !== null ? academies?.data?.[newIndex] : null;

    navigate(`/academy/${nextAcademy?.id}`);
  };

  useEffect(() => {
    if (!evaluatedData) {
      navigate(`/academy/${id}`);
    }
  }, [evaluatedData]);

  return evaluatedData && evaluatedData?.percentage >= 90 ? (
    <Row className='pt-50 pl-50'>
      <Card style={{ width: '600px', height: '200px' }}>
        <Row style={{ flexDirection: 'column' }}>
          <Typography.Title level={5}>{selectedAcademy?.name}</Typography.Title>
          <Typography.Title level={4} type='success' className='mt-0'>
            {translate('academy.congratulationsText')} {selectedAcademy?.name}
          </Typography.Title>
          <Typography.Text type='secondary'>
            {translate('academy.youScored')} {evaluatedData?.percentage}% {translate('academy.proceedToTheNextLesson')}
          </Typography.Text>
          {newIndex && !academies?.data?.[newIndex] ? (
            <></>
          ) : (
            <Button type='primary' className='mt-10' onClick={onNavigateToNextLesson}>
              {translate('academy.goToTheNextLesson')}
            </Button>
          )}
        </Row>
      </Card>
    </Row>
  ) : (
    <Row className='pt-50 pl-50'>
      <Card style={{ width: '600px', height: '250px' }}>
        <Row style={{ flexDirection: 'column' }}>
          <Typography.Title level={5}>{selectedAcademy?.name}</Typography.Title>
          <Typography.Title level={4} type='danger' className='mt-0'>
            {translate('academy.wrongResultText')}
          </Typography.Title>
          <Typography.Text type='secondary'>
            {translate('academy.youScored')} {evaluatedData?.percentage}% {translate('academy.goBackToTheVideo')}
            {selectedAcademy?.name} {translate('academy.reWatchIt')}
          </Typography.Text>
          <Button type='primary' className='mt-10' onClick={() => navigate(`/academy/${selectedAcademy?.id}`)}>
            {translate('academy.watch')} {selectedAcademy?.name}
          </Button>
        </Row>
      </Card>
    </Row>
  );
};
