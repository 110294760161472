import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, Carousel, Col, Row, Typography } from 'antd';
import './EventEmbed.scss';

import { EFieldDataValueType } from '@aduvi/types';
import { IEntities, IEntityWithFields } from '@aduvi/types/entity';
import { EWidgetCards, EWidgetLayout } from '@aduvi/types/widget';

import { getEntities } from 'store/features/entity-slice';
import { getPublicWidget, setSelectedWidget } from 'store/features/widget-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWidgetStyles } from '../../hooks/useWidgetStyles';

export const EventEmbed = () => {
  const dispatch = useAppDispatch();
  const { widgetId } = useParams();

  const { selectedWidget } = useAppSelector((state) => state.widget);
  const [events, setEvents] = useState<IEntities | undefined>();

  const urlBusinessId = location.pathname.split('/embed/')[1]?.split('/')[0];

  const memoizedWidgetFields = useMemo(() => {
    return {
      priceDisplay: selectedWidget?.widget_style?.[0]?.price_display,
      entityEventId: selectedWidget?.widget_style?.[0]?.entity_type_event_id,
      buttonText: selectedWidget?.widget_style?.[0]?.button_text,
    };
  }, [selectedWidget]);

  const { priceDisplay, entityEventId, buttonText } = memoizedWidgetFields;

  const { visibleFields, selectedLayout, limitResults, resultsPerPage, cardNavigationType, columnSpan } = useWidgetStyles(selectedWidget);

  const displayedEvents = useMemo(() => {
    if (!events) return [];
    if (limitResults === 'show_all') {
      return events.entities;
    } else if (limitResults === 'fixed_number' || limitResults === 'paginated') {
      return events?.entities.slice(0, resultsPerPage);
    }
    return events?.entities;
  }, [events?.entities, limitResults, resultsPerPage]);

  const extractEventFields = (event: IEntityWithFields) => {
    const image = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_IMAGES)?.field_data?.[0]?.url;
    const title = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_TEXTS)?.field_data?.[0]?.value;
    const date = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_DATE_TIMES)?.field_data?.[0]?.value;
    const description = event.custom_fields.find((item) => item.value_type === EFieldDataValueType.FIELD_DATA_LONG_TEXTS)?.field_data?.[0]?.value;

    return { image, title, date, description };
  };

  const getPriceDisplay = (event: IEntityWithFields) => {
    const tickets = event.tickets || [];

    if (tickets.length === 0) return null;

    const prices = tickets.map((ticket) => parseFloat(ticket.price));

    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    if (priceDisplay === 'From') {
      return `From $${minPrice}`;
    } else if (priceDisplay === 'Range') {
      return `$${minPrice} - $${maxPrice}`;
    }
    return null;
  };

  const handleButtonClick = (event: IEntityWithFields) => {
    window.open(`/buy-ticket?eventId=${event.id}`, '_blank');
  };

  const onGetEvents = useCallback(() => {
    if (!urlBusinessId || !entityEventId) return;
    dispatch(
      getEntities({
        businessId: urlBusinessId,
        entityTypeId: entityEventId,
      }),
    )
      .unwrap()
      .then(({ data }) => setEvents(data));
  }, [entityEventId]);

  useEffect(() => {
    onGetEvents();
  }, [onGetEvents]);

  useEffect(() => {
    if (!widgetId || !urlBusinessId) return;
    dispatch(getPublicWidget({ businessId: urlBusinessId, widgetId }))
      .unwrap()
      .then(({ data }) => {
        dispatch(setSelectedWidget(data));
      });
  }, [widgetId]);

  return (
    <Row className='event-design-tab-wrapper'>
      <Col span={24}>
        <Card style={{ background: selectedWidget?.widget_style?.[0]?.widget_background }}>
          {selectedLayout === EWidgetLayout.CARDS ? (
            <Carousel arrows autoplaySpeed={2000} autoplay={cardNavigationType === EWidgetCards.ANIMATED}>
              {displayedEvents?.map((event, index) => {
                const { image, title, date, description } = extractEventFields(event);
                return (
                  <div key={index} className='events-card'>
                    {visibleFields.includes('Picture') && image && <img alt={''} src={image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && title && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {title}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Date') && date && (
                      <Typography.Text
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {date}
                      </Typography.Text>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {getPriceDisplay(event)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && description && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {description}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(event)}
                        style={{
                          background: selectedWidget?.widget_style?.[0]?.button_background,
                          color: selectedWidget?.widget_style?.[0]?.button_font,
                        }}>
                        {buttonText}
                      </Button>
                    )}
                  </div>
                );
              })}
            </Carousel>
          ) : selectedLayout === EWidgetLayout.COLUMNS ? (
            <Row gutter={[20, 20]}>
              {displayedEvents?.map((event, index) => {
                const { image, title, date, description } = extractEventFields(event);
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={Array.isArray(columnSpan) ? columnSpan[index % 2] : columnSpan} className='events-card'>
                    {visibleFields.includes('Picture') && image && <img alt={''} src={image} width={100} height={100} className='mb-10' />}
                    {visibleFields.includes('Title') && title && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {title}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Date') && date && (
                      <Typography.Text
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {date}
                      </Typography.Text>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {getPriceDisplay(event)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && description && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {description}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(event)}
                        style={{
                          background: selectedWidget?.widget_style?.[0]?.button_background,
                          color: selectedWidget?.widget_style?.[0]?.button_font,
                        }}>
                        {buttonText}
                      </Button>
                    )}
                  </Col>
                );
              })}
            </Row>
          ) : (
            displayedEvents?.map((event, index) => {
              const { image, title, date, description } = extractEventFields(event);
              return (
                <Row key={index} gutter={[20, 20]} align='middle' className='events-card-row'>
                  {visibleFields.includes('Picture') && image && (
                    <Col xs={24} sm={6} className='event-image'>
                      <img alt={''} src={image} width={100} height={100} className='mb-10' />
                    </Col>
                  )}
                  <Col xs={24} sm={18} className='event-details'>
                    {visibleFields.includes('Title') && title && (
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {title}
                      </Typography.Title>
                    )}
                    {visibleFields.includes('Date') && date && (
                      <Typography.Text
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {date}
                      </Typography.Text>
                    )}
                    {visibleFields.includes('Price') && (
                      <Typography.Paragraph
                        type='secondary'
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {getPriceDisplay(event)}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Description') && description && (
                      <Typography.Paragraph
                        style={{
                          fontFamily: selectedWidget?.widget_style?.[0]?.font_family,
                          color: selectedWidget?.widget_style?.[0]?.primary_font_color,
                        }}>
                        {description}
                      </Typography.Paragraph>
                    )}
                    {visibleFields.includes('Button') && (
                      <Button
                        type='primary'
                        onClick={() => handleButtonClick(event)}
                        style={{
                          background: selectedWidget?.widget_style?.[0]?.button_background,
                          color: selectedWidget?.widget_style?.[0]?.button_font,
                        }}>
                        {buttonText}
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })
          )}
        </Card>
      </Col>
    </Row>
  );
};
