import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'react-signature-canvas';

import { Col, Form, FormInstance, Row } from 'antd';
import '../text-editor/TextEditor.scss';

import { Button } from '@aduvi/components/Button/Button';

export const Signature = ({ form, isPreviewMode, isSignFromForm }: { form: FormInstance; isPreviewMode: boolean; isSignFromForm?: boolean }) => {
  const { t: translate } = useTranslation();
  const signCanvasRef = useRef<SignaturePad>(null);
  const signCanvasRef2 = useRef<SignaturePad>(null);

  const bothPartiesToSign = useMemo(() => {
    return form.getFieldValue(['contract_style', 0, 'required_sign_from_both_parties']);
  }, [form.getFieldValue(['contract_style', 0, 'required_sign_from_both_parties'])]);

  const saveSignature = (ref: React.RefObject<SignaturePad>, field: string) => {
    if (ref?.current) {
      const base64 = ref?.current?.getTrimmedCanvas()?.toDataURL('image/png')?.split(';base64,')[1];
      form.setFieldsValue({
        contract_style: [
          {
            ...form.getFieldValue(['contract_style', 0]),
            [field]: base64,
          },
        ],
      });
    }
  };

  const loadSignature = (signature: string, ref: React.RefObject<SignaturePad>) => {
    if (ref.current && signature) {
      const canvas = ref.current.getCanvas();
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        requestAnimationFrame(() => {
          context?.drawImage(image, 0, 0);
        });
      };
      image.src = `data:image/png;base64,${signature}`;
    }
  };

  useEffect(() => {
    const primarySignature = form.getFieldValue(['contract_style', 0, 'primary_signature']);
    if (primarySignature && signCanvasRef.current) {
      loadSignature(primarySignature, signCanvasRef);
    }

    if (bothPartiesToSign) {
      const secondarySignature = form.getFieldValue(['contract_style', 0, 'secondary_signature']);
      if (secondarySignature && signCanvasRef2.current) {
        loadSignature(secondarySignature, signCanvasRef2);
      }
    }
  }, [bothPartiesToSign]);

  return (
    <Row gutter={16} className={`flex-space-between ${isPreviewMode ? 'pointer-events-none' : ''}`}>
      <Col span={12} xs={24} md={12}>
        <Form.Item name={['contract_style', 0, 'primary_signature']} className='mb-0'>
          <SignaturePad
            ref={signCanvasRef}
            clearOnResize={false}
            onEnd={() => saveSignature(signCanvasRef, 'primary_signature')}
            canvasProps={{
              width: isSignFromForm ? 317 : undefined,
              height: isSignFromForm ? 120 : undefined,
              className: isPreviewMode ? 'signature-canvas-preview' : 'signature-canvas',
            }}
          />
        </Form.Item>
        {!isPreviewMode && (
          <Row>
            <Button
              type='text'
              onClick={() => {
                signCanvasRef.current?.clear();
                if (isSignFromForm) form.setFieldValue(['contract_style', 0, 'primary_signature'], undefined);
              }}>
              {translate('settings.contracts.editContract.clearSignature')}
            </Button>
          </Row>
        )}
      </Col>

      {bothPartiesToSign && (
        <Col span={12} xs={24} md={12}>
          <SignaturePad
            ref={signCanvasRef2}
            canvasProps={{ className: isPreviewMode ? 'signature-canvas-preview' : 'signature-canvas' }}
            onEnd={() => saveSignature(signCanvasRef2, 'secondary_signature')}
          />
          {!isPreviewMode && (
            <Row>
              <Button type='text' onClick={() => signCanvasRef2.current?.clear()}>
                {translate('settings.contracts.editContract.clearSignature')}
              </Button>
            </Row>
          )}
        </Col>
      )}
    </Row>
  );
};
