import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { MenuUnfoldOutlined } from '@ant-design/icons';

import { Button, Col, Row } from 'antd';
import './Settings.scss';

import { CommonSubMenu } from '@aduvi/components/CommonSubMenu/CommonSubMenu';
import { useIsMobile } from '@aduvi/hooks/useIsMobile';

import { SettingsMenu } from './@components/settings-menu/SettingsMenu';

export const Settings = () => {
  const isMobile = useIsMobile();

  const [showSettingsMenu, setShowSettingsMenu] = useState(true);
  const showSettings = isMobile && showSettingsMenu;

  if (showSettings) return <SettingsMenu onClose={() => setShowSettingsMenu(false)} />;

  return (
    <Row className='settings-management-wrapper'>
      {!isMobile && (
        <CommonSubMenu>
          <SettingsMenu />
        </CommonSubMenu>
      )}

      <Col xs={24} md={17} xl={19} xxl={20} className={isMobile ? 'mobile-settings-details-container' : 'settings-details-container'}>
        {isMobile && (
          <Button
            className='mb-15'
            icon={<MenuUnfoldOutlined style={{ fontSize: '24px' }} />}
            type='text'
            onClick={() => setShowSettingsMenu(true)}
          />
        )}
        <Outlet />
      </Col>
    </Row>
  );
};
