import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreditCardOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, RightOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Button, Card, Col, Divider, Dropdown, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';
import './MakePayment.scss';

import { InputPrice } from '@aduvi/components/InputPrice/InputPrice';
import { PriceDisplay } from '@aduvi/components/PriceDisplay/PriceDisplay';
import { PAYMENT_VISIBILITY_OPTIONS, PRICE } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType, ESuggestedAmounts } from '@aduvi/types/client-portal';

import { useAppSelector } from 'store/hooks';

import { PaymentSuggestionCard } from './PaymentSuggestionCard';

export const MakePayment = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [customAmount, setCustomAmount] = useState<number | null>(null);
  const [minimalAmount, setMinimalAmount] = useState<number | null>(null);

  const [suggestedAmounts, setSuggestedAmounts] = useState({
    deposit: true,
    halfRemaining: true,
    allRemaining: true,
    custom: true,
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const title = useWidgetTitle(
    'clientPortal.makePayment.title',
    'make_payment',
    selectedClientPortal?.client_portal_settings?.make_payment?.title,
    form,
  );

  const handleSuggestedAmountsChange = (value: boolean, field: ESuggestedAmounts) => {
    setSuggestedAmounts((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              destroyPopupOnHide={true}
              className='edit-button'
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item initialValue={title} labelCol={{ span: 24 }} name={['client_portal_settings', 'make_payment', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item initialValue={true} className='mb-0' name={['client_portal_settings', 'make_payment', 'half_width']}>
                      <Switch size='small' defaultChecked style={{ margin: 6 }} />
                    </Form.Item>
                    <Typography.Text> {translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <Row justify={'space-between'} align={'middle'}>
                          <Form.Item className='mb-0' name={['client_portal_settings', 'make_payment', 'suggested_amounts', 'deposit']}>
                            <Switch
                              size='small'
                              onChange={(e) => handleSuggestedAmountsChange(e, ESuggestedAmounts.DEPOSIT)}
                              defaultValue={selectedClientPortal?.client_portal_settings?.make_payment?.half_width}
                              style={{ margin: 6 }}
                            />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.makePayment.deposit')}</Typography.Text>
                        </Row>
                        <Row justify={'space-between'} align={'middle'}>
                          <Form.Item
                            className='mb-0'
                            initialValue={true}
                            name={['client_portal_settings', 'make_payment', 'suggested_amounts', 'half_remaining']}>
                            <Switch
                              size='small'
                              onChange={(e) => handleSuggestedAmountsChange(e, ESuggestedAmounts.HALF_REMAINING)}
                              defaultChecked
                              style={{ margin: 6 }}
                            />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.makePayment.halfRemaining')}</Typography.Text>
                        </Row>
                        <Row justify={'space-between'} align={'middle'}>
                          <Form.Item
                            className='mb-0'
                            initialValue={true}
                            name={['client_portal_settings', 'make_payment', 'suggested_amounts', 'all_remaining']}>
                            <Switch
                              size='small'
                              onChange={(e) => handleSuggestedAmountsChange(e, ESuggestedAmounts.ALL_REMAINING)}
                              defaultChecked
                              style={{ margin: 6 }}
                            />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.makePayment.fullRemaining')}</Typography.Text>
                        </Row>
                        <Row justify={'space-between'} align={'middle'}>
                          <Form.Item
                            className='mb-0'
                            initialValue={true}
                            name={['client_portal_settings', 'make_payment', 'suggested_amounts', 'custom']}>
                            <Switch
                              size='small'
                              onChange={(e) => handleSuggestedAmountsChange(e, ESuggestedAmounts.CUSTOM)}
                              defaultChecked
                              style={{ margin: 6 }}
                            />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.makePayment.custom')}</Typography.Text>
                        </Row>
                        {suggestedAmounts.custom && (
                          <Row justify={'space-between'} align={'middle'}>
                            <Typography.Text>{translate('clientPortal.makePayment.minimalAmount')}</Typography.Text>
                            <Form.Item
                              initialValue={minimalAmount}
                              name={['client_portal_settings', 'make_payment', 'suggested_amounts', 'minimal_amount']}>
                              <Input placeholder='50' className='w-full' type='number' onChange={(e) => setMinimalAmount(Number(e.target.value))} />
                            </Form.Item>
                          </Row>
                        )}
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <CreditCardOutlined /> {translate('clientPortal.makePayment.suggestedAmounts')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <Row justify={'space-between'} align={'middle'}>
                          <Form.Item
                            className='mb-0'
                            initialValue={true}
                            name={['client_portal_settings', 'make_payment', 'visibility', 'require_contract']}>
                            <Switch size='small' defaultChecked style={{ margin: 6 }} />
                          </Form.Item>
                          <Typography.Text>{translate('clientPortal.makePayment.requireContract')}</Typography.Text>
                        </Row>
                        <Typography.Paragraph className='mb-10' type='secondary'>
                          {translate('clientPortal.options.dateLogic')}
                        </Typography.Paragraph>
                        <Form.Item initialValue={true} name={['client_portal_settings', 'make_payment', 'visibility', 'date_logic']}>
                          <Select defaultValue={'always'}>
                            {PAYMENT_VISIBILITY_OPTIONS.map((option) => (
                              <Select.Option value={option.id} key={option.id}>
                                {translate(option.value)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <EditOutlined /> {translate('clientPortal.options.visibility')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>
                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.MAKE_PAYMENT, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='client-portal-make-payment-wrapper'>
      <Typography.Title className='text-center mb-0'>
        <PriceDisplay price={PRICE} />
      </Typography.Title>
      <Typography.Paragraph className='text-center mb-30'> {translate('clientPortal.makePayment.remainingBalance')}</Typography.Paragraph>

      <Typography.Paragraph type='secondary' className='text-center'>
        {translate('clientPortal.makePayment.suggestedAmounts')}
      </Typography.Paragraph>

      <Row justify={'space-between'} gutter={16} className='mb-30'>
        {suggestedAmounts.deposit && <PaymentSuggestionCard amount={PRICE / 4} title={translate('clientPortal.makePayment.halfRemaining')} />}
        {suggestedAmounts.halfRemaining && <PaymentSuggestionCard amount={PRICE / 2} title={translate('clientPortal.makePayment.halfRemaining')} />}
        {suggestedAmounts.allRemaining && <PaymentSuggestionCard amount={PRICE} title={translate('clientPortal.makePayment.halfRemaining')} />}
      </Row>
      {suggestedAmounts.custom && (
        <Row gutter={16}>
          <Col style={{ flexGrow: 1 }}>
            <Form.Item
              validateStatus={
                customAmount !== null && (PRICE < customAmount || (minimalAmount !== null && customAmount < minimalAmount)) ? 'error' : ''
              }
              help={
                customAmount !== null && (PRICE < customAmount || (minimalAmount !== null && customAmount < minimalAmount))
                  ? translate('clientPortal.makePayment.error')
                  : ''
              }>
              <InputPrice className='w-full d-block' value={customAmount} onChange={(value) => setCustomAmount(Number(value))} />
            </Form.Item>
          </Col>
          <Col>
            <Button type='primary' disabled={customAmount !== null && PRICE < customAmount}>
              {translate('clientPortal.makePayment.pay')}
            </Button>
          </Col>
        </Row>
      )}
    </Card>
  );
};
