import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { Col, Typography } from 'antd';
import './DraggableColumn.scss';

import { CustomFieldValue } from '@aduvi/components/CustomField/CustomFieldValue/CustomFieldValue';
import { ICustomField, IEntityWithFields } from '@aduvi/types/entity';

export const DraggableColumn = ({ id, title, field, record }: { id: string; title: string; field?: ICustomField; record?: IEntityWithFields }) => {
  const { attributes, listeners, transform, isDragging, setNodeRef } = useDraggable({ id });

  return (
    <Col span={4}>
      <div
        className={`draggable-column-wrapper  ${isDragging && 'dragged'}`}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
          transform: CSS.Translate.toString(transform),
          cursor: 'grab',
          overflowX: 'auto',
          minHeight: '62px',
          borderRadius: '5px',
          border: '1px dashed #70b6fc',
          backgroundColor: isDragging ? 'rgba(255,255,255,0.6)' : 'transparent',
          rotate: isDragging ? '5deg' : '0deg',
        }}>
        <div>
          <Typography.Paragraph className='fs-12 mb-5 color-gray-5'>{title}</Typography.Paragraph>
          <CustomFieldValue field={field} record={record} />
        </div>
      </div>
    </Col>
  );
};
