import { useMemo } from 'react';

import { useBusiness } from '@aduvi/hooks';
import { ICustomFieldViewStyle, IEntityField } from '@aduvi/types';
import { IEntityWithFields } from '@aduvi/types/entity';

import { useGetEntityFieldsByMultipleIdsQuery } from 'store/rtk-slice/fields-slice';

import { EditableKanbanCard } from '../PredefinedViews/Kanban/components/EditableKanbanCard';

interface IProps {
  data: IEntityWithFields;
  viewColumns: ICustomFieldViewStyle[];
  managementFields: IEntityField[];
  removeColumn: (column: string) => void;
  updateColumns: (column: ICustomFieldViewStyle, reRenderComponent?: boolean) => void;
}

interface IDraggableCardFields {
  columns?: ICustomFieldViewStyle[];
  managementFields: IEntityField[];
  data: IEntityWithFields;
}

export const EditableCard = ({ data, viewColumns, managementFields, updateColumns, removeColumn }: IProps) => {
  const draggableColumns = useDraggableCardFields({
    columns: viewColumns,
    managementFields,
    data,
  });
  return <EditableKanbanCard columns={draggableColumns} updateColumns={updateColumns} removeColumn={removeColumn} />;
};

export const useDraggableCardFields = ({ columns, managementFields, data }: IDraggableCardFields) => {
  const selectedBusiness = useBusiness();

  const referenceEntityIds = managementFields
    ?.filter((field) => field?.reference_entity_type)
    ?.map((field) => ({ entity_type_id: field?.reference_entity_type_id, entity_name: field?.place_holder || field?.reference_entity_type }));

  const { data: referenceEntities } = useGetEntityFieldsByMultipleIdsQuery(
    {
      businessId: selectedBusiness?.id!,
      entityTypeIds: referenceEntityIds!,
    },
    { skip: !selectedBusiness || !referenceEntityIds },
  );
  return useMemo(() => {
    return columns?.length
      ? [
          ...columns?.map((column) => {
            const isReferenceEntity = referenceEntities?.data?.some(
              (entity) => entity?.custom_fields?.some((field) => column?.field_id?.includes(field?.id)),
            );
            const referenceEntity = referenceEntities?.data?.find(
              (entity) => entity?.custom_fields?.find((field) => field?.id === column?.field_id?.[0]),
            );
            const fieldId = column?.field_id?.[0];
            const referenceEntityField = referenceEntities?.data
              ?.find((entity) => entity?.custom_fields?.find((field) => field?.id === fieldId))
              ?.custom_fields?.find((customField) => customField?.id === fieldId);

            return {
              field: isReferenceEntity
                ? data?.custom_fields
                    ?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                    ?.field_data?.[0]?.custom_fields?.find((customField) => customField?.id === referenceEntityField?.id)
                : data?.custom_fields?.find((field) => field?.id === fieldId),
              column,
              record: isReferenceEntity
                ? (data?.custom_fields?.filter((field) => field?.reference_entity_type_id === referenceEntity?.entity_id)?.[0]
                    ?.field_data?.[0] as unknown as IEntityWithFields)
                : data,
            };
          }),
        ]
      : [];
  }, [columns, managementFields, referenceEntities, data]);
};
