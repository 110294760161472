import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Card, Form, Input, Typography } from 'antd';

import { Button } from '@aduvi/components/Button/Button';
import { ELoadingState } from '@aduvi/types';

import { forgetPassword } from 'store/features/client-auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);
  const { forgetPasswordState } = useAppSelector((state) => state.clientPortalAuth);

  const onFinishHandler = ({ email }: { email: string }) => {
    if (!selectedClientPortal) return;
    dispatch(
      forgetPassword({
        businessId: selectedClientPortal?.business_id,
        clientPortalId: selectedClientPortal?.id,
        body: { email },
      }),
    )
      .unwrap()
      .then(() => {
        navigate(`../login`);
      })
      .catch(() => {});
  };

  return (
    <Card style={{ minWidth: 350, maxWidth: 500, borderRadius: 10 }}>
      <Typography.Title className='title text-center' level={2}>
        {translate('clientPortal.portal.logo')}
      </Typography.Title>
      <Typography.Title className='title text-center' level={4}>
        {translate('auth.forgotPassword.forgotPassword')}
      </Typography.Title>
      <Typography.Paragraph type='secondary' className='title text-center'>
        {translate('auth.forgotPassword.forgotPasswordDescription')}
      </Typography.Paragraph>

      <Form name='forgot' initialValues={{ remember: true }} onFinish={onFinishHandler}>
        <Form.Item className={'mb-10'} name='email' rules={[{ required: true, message: translate('auth.login.emailValidation'), type: 'email' }]}>
          <Input placeholder={'Enter your email'} />
        </Form.Item>

        <div className='text-center mt-20'>
          <Button
            loading={forgetPasswordState === ELoadingState.LOADING}
            disabled={forgetPasswordState === ELoadingState.LOADING}
            htmlType='submit'
            className='mb-20 w-full'
            type='primary'>
            {translate('auth.forgotPassword.submitYourEmail')}
          </Button>
        </div>
        <Typography.Paragraph className='text-center cursor-pointer color-gray-5' onClick={() => navigate(`../login`)}>
          <ArrowLeftOutlined /> {translate('auth.forgotPassword.backToLogin')}
        </Typography.Paragraph>
      </Form>
    </Card>
  );
};
