import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WidgetType } from 'views/dashboard/data';

import { PartnerLayoutAdapter } from '@aduvi/patterns/adapter/PartnerLayoutAdapter';
import { IApp, ICommonState, IGetMicrosoftCalendarEventsPayload, IGlobalSearchPayload, IInstallAppPayload, IMessage } from '@aduvi/types/common';
import { getPartnerTerminology } from '@aduvi/utils/helper';

import * as CommonService from 'store/services/common.service';

const initialState: ICommonState = {
  countries: {
    countries: [],
    loading: false,
  },
  currencies: {
    currencies: [],
    loading: false,
  },
  search: {
    loading: false,
    data: undefined,
  },
  apps: {
    data: [],
    selectedApp: undefined,
    installing: false,
  },
  appCategories: {
    data: [],
    loading: false,
    selectedCategory: undefined,
  },
  avatar: {
    data: undefined,
    loading: false,
  },

  partnerLayout: undefined,
  activeTheme: undefined,
  layout: undefined,
  uploading: false,
  terminology: undefined,
  themes: [],
  pricing: [],
  dashboard: {
    data: undefined,
    loading: false,
  },
  statistics: {
    data: undefined,
    loading: false,
  },
  chat: {
    data: [],
    creating: false,
  },
  microsoftCalendarEvents: {
    loading: false,
    data: [],
  },
};

export const getCountries = createAsyncThunk('get-countries', async (_, { rejectWithValue }) => {
  try {
    return await CommonService.getCountries();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCurrencies = createAsyncThunk('common/get-currencies', async (_, { rejectWithValue }) => {
  try {
    return await CommonService.getCurrencies();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const uploadImage = createAsyncThunk('common/upload-image', async (params: FormData, { rejectWithValue }) => {
  try {
    return await CommonService.uploadImage(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getLayoutByDomain = createAsyncThunk('common/get-layout', async (_, { rejectWithValue }) => {
  try {
    return await CommonService.getLayoutByDomain();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const globalSearch = createAsyncThunk('common/global-search', async (params: IGlobalSearchPayload, { rejectWithValue }) => {
  try {
    return await CommonService.globalSearch(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAppsCategories = createAsyncThunk('common/get-apps-categories', async (_, { rejectWithValue }) => {
  try {
    return await CommonService.getAppsCategories();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const installApp = createAsyncThunk('common/install-app', async (params: IInstallAppPayload, { rejectWithValue }) => {
  try {
    return await CommonService.installApp(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const sentMessage = createAsyncThunk('chat/create', async (params: { messages: IMessage[]; model: string }, { rejectWithValue }) => {
  try {
    return await CommonService.sentMessage(params.messages, params.model);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserAvatar = createAsyncThunk('common/get-avatar', async (email: string, { rejectWithValue }) => {
  try {
    return await CommonService.getUserAvatar(email);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getMicrosoftCalendarEvents = createAsyncThunk(
  'microsoft/calendar-events',
  async (params: IGetMicrosoftCalendarEventsPayload, { rejectWithValue }) => {
    try {
      return await CommonService.getMicrosoftCalendarEvents(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const editUserDashboardLayout = createAsyncThunk(
  'common/edit-user-dashboard-layout',
  async (params: { userId: string; businessId: string; body: { dashboard_layout: WidgetType[] } }, { rejectWithValue }) => {
    try {
      return await CommonService.editUserDashboardLayout(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUserDashboardLayout = createAsyncThunk(
  'common/get-user-dashboard-layout',
  async (params: { userId: string; businessId: string }, { rejectWithValue }) => {
    try {
      return await CommonService.getUserDashboardLayout(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDashboardStatistics = createAsyncThunk(
  'common/get-dashboard-statistics',
  async (params: { userId: string; businessId: string; period: string }, { rejectWithValue }) => {
    try {
      return await CommonService.getDashboardStatistics(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    addWidget(state, action: PayloadAction<WidgetType>) {
      if (state.dashboard.data) {
        const selectedOrderIndex = state.dashboard.data?.findIndex((item) => item.id === action.payload.id);
        state.dashboard.data[selectedOrderIndex!] = action.payload;
      }
    },
    reorderWidgets(state, action: PayloadAction<WidgetType[]>) {
      state.dashboard.data = [...action.payload];
    },
    removeWidget(state, action: PayloadAction<number>) {
      state.dashboard.data = state.dashboard.data?.filter((item) => item.id !== action.payload);
    },
    resetSearch(state) {
      state.search.loading = false;
      state.search.data = undefined;
    },
    setActiveTheme: (state, { payload }) => {
      state.activeTheme = payload;
    },
    setSelectedAppCategory: (state, { payload }: PayloadAction<IApp[] | undefined>) => {
      state.appCategories.selectedCategory = payload;
    },
    setSelectedPartnerApp: (state, { payload }: PayloadAction<IApp | undefined>) => {
      state.apps.selectedApp = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.countries.loading = true;
      })
      .addCase(getCountries.fulfilled, (state, { payload }) => {
        state.countries.loading = false;
        state.countries.countries = payload?.data;
      })
      .addCase(getCountries.rejected, (state) => {
        state.countries.loading = false;
      })
      .addCase(getCurrencies.pending, (state) => {
        state.currencies.loading = true;
      })
      .addCase(getCurrencies.fulfilled, (state, { payload }) => {
        state.currencies.loading = false;
        state.currencies.currencies = payload?.data;
      })
      .addCase(getCurrencies.rejected, (state) => {
        state.currencies.loading = false;
      })
      .addCase(uploadImage.pending, (state) => {
        state.uploading = true;
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.uploading = false;
      })
      .addCase(uploadImage.rejected, (state) => {
        state.uploading = false;
      })
      .addCase(getLayoutByDomain.fulfilled, (state, { payload }) => {
        state.layout = payload.data;
        state.themes = payload.data?.themes;
        state.pricing = payload.data.pricing;
        state.terminology = getPartnerTerminology(payload?.data);
        state.apps.data = payload.data.partner_apps;

        const partnerLayout = new PartnerLayoutAdapter(payload.data);
        state.partnerLayout = partnerLayout.getPartnerLayout;
      })
      .addCase(globalSearch.pending, (state) => {
        state.search.loading = true;
      })
      .addCase(globalSearch.fulfilled, (state, { payload }) => {
        state.search.loading = false;
        state.search.data = payload.data;
      })
      .addCase(globalSearch.rejected, (state) => {
        state.search.loading = false;
      })
      .addCase(getAppsCategories.pending, (state) => {
        state.appCategories.loading = true;
      })
      .addCase(getAppsCategories.fulfilled, (state, { payload }) => {
        state.appCategories.loading = false;
        state.appCategories.data = payload.data;
      })
      .addCase(getAppsCategories.rejected, (state) => {
        state.appCategories.loading = false;
      })
      .addCase(installApp.pending, (state) => {
        state.apps.installing = true;
      })
      .addCase(installApp.fulfilled, (state) => {
        state.apps.installing = false;
      })
      .addCase(installApp.rejected, (state) => {
        state.apps.installing = false;
      })
      .addCase(sentMessage.pending, (state, { meta }) => {
        state.chat.creating = true;
        state.chat.data = [...state.chat.data, { type: 'user', message: meta.arg.messages[meta.arg.messages?.length - 1].content }];
      })
      .addCase(sentMessage.fulfilled, (state, { payload }) => {
        state.chat.data = [...state.chat.data, { type: 'bot', message: payload?.data.choices[0].message.content }];
        state.chat.creating = false;
      })
      .addCase(sentMessage.rejected, (state) => {
        state.chat.creating = false;
      })
      .addCase(getUserAvatar.pending, (state) => {
        state.avatar.loading = true;
      })
      .addCase(getUserAvatar.fulfilled, (state, { payload }) => {
        state.avatar.loading = false;
        state.avatar.data = payload;
      })
      .addCase(getUserAvatar.rejected, (state) => {
        state.avatar.loading = false;
      })
      .addCase(getMicrosoftCalendarEvents.pending, (state) => {
        state.microsoftCalendarEvents.loading = true;
      })
      .addCase(getMicrosoftCalendarEvents.fulfilled, (state, { payload }) => {
        state.microsoftCalendarEvents.loading = false;

        state.microsoftCalendarEvents.data = payload?.data?.value?.map((event) => ({
          title: event.subject,
          start: new Date(event.start.dateTime),
          end: new Date(event.end.dateTime),
          bodyPreview: event.bodyPreview,
        }));
      })
      .addCase(getMicrosoftCalendarEvents.rejected, (state) => {
        state.microsoftCalendarEvents.loading = false;
      })
      .addCase(editUserDashboardLayout.pending, (state) => {
        state.dashboard.data = undefined;
        state.dashboard.loading = true;
      })
      .addCase(editUserDashboardLayout.fulfilled, (state, { payload }) => {
        state.dashboard.data = payload.data;
        state.dashboard.loading = false;
      })
      .addCase(editUserDashboardLayout.rejected, (state) => {
        state.dashboard.loading = false;
      })
      .addCase(getUserDashboardLayout.rejected, (state) => {
        state.dashboard.data = undefined;
        state.dashboard.loading = false;
      })
      .addCase(getUserDashboardLayout.fulfilled, (state, { payload }) => {
        state.dashboard.data = payload.data;
        state.dashboard.loading = false;
      })
      .addCase(getUserDashboardLayout.pending, (state) => {
        state.dashboard.data = undefined;
        state.dashboard.loading = true;
      })
      .addCase(getDashboardStatistics.pending, (state) => {
        state.statistics.loading = true;
      })
      .addCase(getDashboardStatistics.fulfilled, (state, { payload }) => {
        state.statistics.loading = false;

        state.statistics.data = payload;
      })
      .addCase(getDashboardStatistics.rejected, (state) => {
        state.statistics.loading = false;
      });
  },
});

export const { setActiveTheme, setSelectedPartnerApp, reorderWidgets, addWidget, removeWidget } = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
