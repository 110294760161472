import { useBusiness, useBusinessCurrency } from '@aduvi/hooks';

export const PriceDisplay = ({ price, currencySign }: { price: string | number; currencySign?: string }) => {
  const selectedBusiness = useBusiness();
  const businessCurrency = useBusinessCurrency();

  return (
    <>
      {currencySign
        ? selectedBusiness?.currency_sign_position === 'after'
          ? `${Number(price)?.toFixed(2)}${currencySign || ''}`
          : `${currencySign || ''}${Number(price)?.toFixed(2)}`
        : selectedBusiness?.currency_sign_position === 'after'
        ? `${Number(price)?.toFixed(2)}${businessCurrency?.format || ''}`
        : `${businessCurrency?.format || ''}${Number(price)?.toFixed(2)}`}
    </>
  );
};
