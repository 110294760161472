import { SLICE_TAG_TYPES } from '@aduvi/constants';
import { KEEP_UNUSED_DATA_FOR } from '@aduvi/constants/rtk-api';
import { IBusinessTeam, IBusinessUser, IGetBusinessUsersPayload, IPaginatedResponse, IResponse } from '@aduvi/types';

import { apiSlice } from '../api/apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessUsers: builder.query<IPaginatedResponse<IBusinessUser>, IGetBusinessUsersPayload>({
      query: ({ business_id, params }) => ({
        url: `/businesses/${business_id}/users`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.USER }],
    }),
    getBusinessTeams: builder.query<IResponse<IBusinessTeam[]>, { business_id?: string }>({
      query: ({ business_id }) => ({
        url: `/businesses/${business_id}/teams`,
        method: 'GET',
      }),
      keepUnusedDataFor: KEEP_UNUSED_DATA_FOR.LONG,
      providesTags: [{ type: SLICE_TAG_TYPES.TEAMS }],
    }),
  }),
});

export const { useGetBusinessUsersQuery, useGetBusinessTeamsQuery } = userApiSlice;
