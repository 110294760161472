import type { Edge } from 'reactflow';

export const initialEdges = [
  // { id: 'a->c', source: 'a', label: 'to the', target: 'c', type: 'custom', style: { stroke: '#69C0FF', strokeWidth: 2 } },
  // // { id: 'b->d', source: 'b', target: 'd' },
  // { id: 'c->d', source: 'c', target: 'd', type: 'custom', style: { stroke: '#69C0FF', strokeWidth: 2 } },
  // { id: 'd', source: 'd', target: '', type: 'custom', style: { stroke: '#69C0FF', strokeWidth: 2 } },
] satisfies Edge[];

// export const edgeTypes = {
//   buttonEdge: ButtonEdge,
// } satisfies EdgeTypes;
