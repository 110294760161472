import { Row, Skeleton } from 'antd';

export const Footer = () => {
  return (
    <Row align='middle' justify={'space-around'} style={{ backgroundColor: '#f0f0f0', height: '50px' }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton.Avatar key={index} active shape={'square'} />
      ))}
    </Row>
  );
};
