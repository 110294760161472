import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { t } from 'i18next';

import { IBusinessUser, IProduct, IService } from '@aduvi/types';
import { IShiftSchedulerSettings } from '@aduvi/types/shift-scheduler';

export const useSegmentOptions = (settings?: IShiftSchedulerSettings, services?: IService[], products?: IProduct[], users?: IBusinessUser[]) => {
  const { t: translate } = useTranslation();

  const segment_options = [
    {
      label: translate('settings.shiftScheduler.positions.positions'),
      options: settings?.positions?.map((value) => ({
        key: value?.id,
        label: value?.position,
        value: value?.id,
        segmentable_type: 'App\\Models\\BusinessShiftSchedulerPosition',
      })),
    },
    {
      label: translate('assets.services.services'),
      options: services?.map((value) => ({
        key: value?.id,
        label: value?.name,
        value: value?.id,
        segmentable_type: 'App\\Models\\BusinessService',
      })),
    },
    {
      label: translate('assets.products.products'),
      options: products?.map((value) => ({
        key: value?.id,
        label: value?.name,
        value: value?.id,
        segmentable_type: 'App\\Models\\BusinessProduct',
      })),
    },
    {
      label: t('userManagement.sideMenu.usersTitle'),
      options: users?.map((value) => ({
        key: value?.id,
        label: `${value?.first_name}`,
        value: value?.id,
        segmentable_type: 'App\\Models\\BusinessUser',
      })),
    },
  ];
  return { segment_options };
};

export const generateHourMarkers = (minTime: number, maxTime: number) => {
  const hourMarkers = [];
  let current = dayjs().startOf('day').add(minTime, 'minute');
  const end = dayjs().startOf('day').add(maxTime, 'minute');

  while (current.isBefore(end) || current.isSame(end)) {
    hourMarkers.push(current.format('hA'));
    current = current.add(1, 'hour');
  }

  return hourMarkers;
};
