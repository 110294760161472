import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';

import { Col, Divider, Image, Row } from 'antd';
import { Tabs } from 'antd';
import './Authentication.scss';

import { usePartnerLayout } from '@aduvi/hooks';

const Authentication = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const partnerLayout = usePartnerLayout();

  const { t: translate } = useTranslation();

  const tabKey = useMemo(() => {
    return location?.pathname ?? '/auth/login';
  }, [location.pathname]);

  return (
    <Row className={'authentication-wrapper'}>
      <Col sm={0} xs={0} md={12}>
        <Image src={partnerLayout?.authImage} preview={false} width='100%' className='aduvi-side-background-image' />
      </Col>
      <Col span={12} sm={24} xs={24} md={12} className={'form-wrapper'}>
        <Image src={partnerLayout?.logo} preview={false} className='logo' />
        {['/auth/login', '/auth/register']?.includes(tabKey) && (
          <Tabs
            defaultActiveKey={tabKey}
            items={[
              {
                key: '/auth/login',
                label: translate('auth.login.login'),
              },
              {
                key: '/auth/register',
                label: translate('auth.login.signUp'),
              },
            ]}
            onChange={navigate}
          />
        )}
        <Row className='w-full'>
          <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }}>
            <Divider />
            <Outlet />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Authentication;
