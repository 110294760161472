import './Bookings.scss';

import { Entity } from '@aduvi/components/Entity/Entity';
import { EPersonalizedViewOrigin } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

const personalizedViewOrigin = EPersonalizedViewOrigin.BOOKINGS;

export const Bookings = () => {
  const { entityTypes } = useAppSelector((state) => state.entity);

  return <Entity entityType={entityTypes.data.JOB} personalizedViewOrigin={personalizedViewOrigin} isConfirmed={true} />;
};
