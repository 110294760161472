export const DesktopIcon = ({ color }: { color: string }) => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_189682_19899)'>
        <path
          d='M13.5 1.18945H0.5C0.223437 1.18945 0 1.41289 0 1.68945V9.43945C0 9.71602 0.223437 9.93945 0.5 9.93945H6.4375V11.6895H3.75C3.6125 11.6895 3.5 11.802 3.5 11.9395V12.6895C3.5 12.7582 3.55625 12.8145 3.625 12.8145H10.375C10.4438 12.8145 10.5 12.7582 10.5 12.6895V11.9395C10.5 11.802 10.3875 11.6895 10.25 11.6895H7.5625V9.93945H13.5C13.7766 9.93945 14 9.71602 14 9.43945V1.68945C14 1.41289 13.7766 1.18945 13.5 1.18945ZM12.875 8.81445H1.125V2.31445H12.875V8.81445Z'
          fill={color}
          fillOpacity='0.85'
        />
      </g>
      <defs>
        <clipPath id='clip0_189682_19899'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
