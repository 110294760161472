import { useEffect, useState } from 'react';

import axios from '@aduvi/config/axios';
import { useBusinessId } from '@aduvi/hooks';
import { EFieldDataValueType, EReferenceEntityType, IEntityField } from '@aduvi/types';

import { useAppSelector } from 'store/hooks';

export const useFetchEntityFields = () => {
  const businessId = useBusinessId();

  const {
    entityTypes: { data },
  } = useAppSelector((state) => state.entity);

  const { fields } = useAppSelector((state) => state.fields);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [neededFields, setNeededFields] = useState<(IEntityField | null)[]>([]);

  useEffect(() => {
    if (!businessId || !data?.JOB?.id) return;

    const fetchFields = async () => {
      setIsLoading(true);

      const uniqueFields = fields
        .filter(
          (e) =>
            ![EReferenceEntityType.JOB, EReferenceEntityType.SERVICE, EReferenceEntityType.PRODUCT].includes(e.reference_entity_type) &&
            e.reference_entity_type_id,
        )
        .reduce((unique: IEntityField[], item) => {
          const isDuplicate = unique.some((u: IEntityField) => u.reference_entity_type === item.reference_entity_type);
          if (!isDuplicate) {
            unique.push(item);
          }
          return unique;
        }, []);

      const referencePromises = uniqueFields.map(async (field) => {
        const { reference_entity_type_id } = field;
        let entityFieldData: IEntityField[] = [];

        try {
          const response = await axios.get(`/businesses/${businessId}/entity-types/${reference_entity_type_id}/fields`);
          entityFieldData = response.data;
        } catch (err) {
          return [];
        }

        return entityFieldData?.filter((subField) => subField.value_type === EFieldDataValueType.FIELD_DATA_ADDRESSES) || [];
      });

      const nestedResults = await Promise.all(referencePromises);
      const allNeededFields = nestedResults.flat();

      setNeededFields(allNeededFields);
      setIsLoading(false);
    };

    fetchFields();
  }, [businessId, data]);

  return { neededFields, isLoading };
};
