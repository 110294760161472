import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EllipsisOutlined, EyeOutlined, RightOutlined, StarOutlined } from '@ant-design/icons';
import { removeWidget, useWidgetTitle } from 'views/settings/client-portal/helper';

import { Avatar, Button, Card, Col, Divider, Dropdown, Form, FormInstance, Input, Row, Select, Switch, Typography } from 'antd';

import { REVIEW_VISIBILITY_OPTIONS } from '@aduvi/constants/client-portal-constants';
import { EClientPortalComponentType } from '@aduvi/types';
import { extractDomainName, toCapitalize } from '@aduvi/utils/helper';

import { useAppSelector } from 'store/hooks';

export const Review = ({ readonly = true, form }: { readonly?: boolean; form?: FormInstance }) => {
  const { t: translate } = useTranslation();
  Form.useWatch('links');
  Form.useWatch(['client_portal_settings', 'review', 'links']);

  const { selectedClientPortal } = useAppSelector((state) => state.clientPortal);

  const [urls, setUrls] = useState<{ url: string }[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const title = useWidgetTitle('clientPortal.review.title', 'review', selectedClientPortal?.client_portal_settings?.review?.title, form);

  useEffect(() => {
    setUrls(selectedClientPortal?.client_portal_settings?.review?.links || form?.getFieldValue(['client_portal_settings', 'review', 'links']));
  }, [selectedClientPortal?.client_portal_settings?.review?.links, form?.getFieldValue(['client_portal_settings', 'review', 'links'])]);

  return (
    <Card
      bordered={false}
      title={
        <Row justify={'space-between'}>
          <Typography.Paragraph className='mb-0'>{title}</Typography.Paragraph>
          {!readonly && (
            <Dropdown
              className='edit-button'
              destroyPopupOnHide
              open={dropdownVisible}
              onOpenChange={(isOpen) => setDropdownVisible(isOpen)}
              overlayClassName='editable-cell-drowpdown-options-wrapper'
              dropdownRender={() => (
                <Card className='style-modal'>
                  <div>
                    <Typography.Paragraph className='mb-10' type='secondary'>
                      {translate('clientPortal.options.blockSettings')}
                    </Typography.Paragraph>
                  </div>
                  <Form.Item initialValue={title} labelCol={{ span: 24 }} name={['client_portal_settings', 'review', 'title']}>
                    <Input />
                  </Form.Item>
                  <Row justify={'space-between'} align={'middle'}>
                    <Form.Item className='mb-0' name={['client_portal_settings', 'review', 'half_width']}>
                      <Switch
                        size='small'
                        onChange={() => {}}
                        defaultValue={selectedClientPortal?.client_portal_settings?.review?.half_width}
                        style={{ margin: 6 }}
                      />
                    </Form.Item>
                    <Typography.Text> {translate('clientPortal.options.halfWidth')}</Typography.Text>
                  </Row>

                  <Divider />

                  <Typography.Paragraph className='mb-10' type='secondary'>
                    {translate('clientPortal.options.options')}
                  </Typography.Paragraph>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <Typography.Paragraph className='mb-10' type='secondary'>
                          {translate('clientPortal.options.dateLogic')}
                        </Typography.Paragraph>
                        <Form.Item>
                          <Select defaultValue={'always'}>
                            {REVIEW_VISIBILITY_OPTIONS.map((option) => (
                              <Select.Option value={option.id} key={option.id}>
                                {option.value ? translate(option.text, { value: option.value }) : translate(option.text)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <EyeOutlined /> {translate('clientPortal.options.visibility')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>

                  <Dropdown
                    className='edit-button'
                    overlayClassName='editable-cell-drowpdown-options-wrapper'
                    placement='bottom'
                    dropdownRender={() => (
                      <Card className='style-modal' style={{ width: 250 }}>
                        <Typography.Paragraph className='mb-10' type='secondary'>
                          {translate('clientPortal.review.links')}
                        </Typography.Paragraph>

                        <Form.List name={['client_portal_settings', 'review', 'links']} initialValue={['']}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <div key={key} className='mb-10'>
                                  <Form.Item
                                    className='mb-10'
                                    name={[name, 'url']}
                                    {...restField}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: translate('clientPortal.review.urlInputPlaceholder'),
                                      },
                                    ]}
                                    noStyle>
                                    <Input
                                      addonAfter={<DeleteOutlined onClick={() => remove(name)} />}
                                      placeholder={translate('clientPortal.review.enterUrl')}
                                    />
                                  </Form.Item>
                                </div>
                              ))}
                              <Form.Item className='text-right'>
                                <Button type='link' onClick={() => add({ url: 'https://' })}>
                                  {`+ ${translate('clientPortal.review.addLink')}`}
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    )}
                    trigger={['click']}>
                    <Button type='text' className='flex-space-between align-center w-full'>
                      <span>
                        <StarOutlined /> {translate('clientPortal.review.links')}
                      </span>
                      <RightOutlined />
                    </Button>
                  </Dropdown>
                  <Row>
                    <Button
                      type='text'
                      danger
                      className='w-full flex align-center'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        removeWidget(EClientPortalComponentType.REVIEW, form);
                        setDropdownVisible(false);
                      }}>
                      {translate('clientPortal.options.remove')}
                    </Button>
                  </Row>
                </Card>
              )}
              trigger={['click']}>
              <Typography.Text>
                <EllipsisOutlined />
              </Typography.Text>
            </Dropdown>
          )}
        </Row>
      }
      className='b-radius-10'>
      <Row gutter={[16, 16]} className='mb-20'>
        {urls?.map(
          (link: { url: string }) =>
            link && (
              <Col span={12} key={link.url}>
                <Typography.Link href={link.url} target='_blank'>
                  <Row gutter={8}>
                    <Col>
                      <Avatar
                        style={{ borderRadius: 5 }}
                        size={50}
                        src={`https://www.google.com/s2/favicons?domain=${link.url}&sz=180`}
                        shape='square'
                      />
                    </Col>
                    <Col>
                      <Typography.Paragraph className='mb-0'>{translate('clientPortal.review.reviewUs')}</Typography.Paragraph>
                      <Typography.Paragraph className='mb-0'>{toCapitalize(extractDomainName(link.url))}</Typography.Paragraph>
                    </Col>
                  </Row>
                </Typography.Link>
              </Col>
            ),
        )}
      </Row>
      <Typography.Paragraph className='text-center' type='secondary'>
        {translate('clientPortal.review.sendFeedback')}
      </Typography.Paragraph>
      <Row gutter={8}>
        <Col style={{ flexGrow: 1 }}>
          <Form.Item>
            <Input></Input>
          </Form.Item>
        </Col>
        <Col>
          <Button type='primary'>{translate('clientPortal.review.send')}</Button>
        </Col>
      </Row>
    </Card>
  );
};
