import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICreateTravelFeesPayload, ITravelFeeState, IUpdateTravelFeesPayload } from '@aduvi/types/travel-fees';

import * as TravelFeesServices from 'store/services/travel-fees.service';

const initialState: ITravelFeeState = {
  travelFee: undefined,
  selectedTravelFee: undefined,
  loading: false,
  creating: false,
  updating: false,
};

export const getTravelFee = createAsyncThunk('travel-fees/get', async (businessId: string, { rejectWithValue }) => {
  try {
    return await TravelFeesServices.getTravelFee(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createTravelFee = createAsyncThunk('travel-fees/create', async (params: ICreateTravelFeesPayload, { rejectWithValue }) => {
  try {
    return await TravelFeesServices.createTravelFee(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateTravelFee = createAsyncThunk('travel-fees/update', async (params: IUpdateTravelFeesPayload, { rejectWithValue }) => {
  try {
    return await TravelFeesServices.updateTravelFee(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const travelFeesSlice = createSlice({
  name: 'travelFees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTravelFee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTravelFee.fulfilled, (state, { payload }) => {
        state.travelFee = payload.data?.[0];
        state.loading = false;
      })
      .addCase(getTravelFee.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createTravelFee.pending, (state) => {
        state.creating = true;
      })
      .addCase(createTravelFee.fulfilled, (state, { payload }) => {
        state.travelFee = payload.data;
        state.creating = false;
      })
      .addCase(createTravelFee.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateTravelFee.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateTravelFee.fulfilled, (state, { payload }) => {
        state.updating = false;
        if (state.travelFee?.id === payload.data.id) {
          state.travelFee = { ...state.travelFee, ...payload.data };
        }
      })
      .addCase(updateTravelFee.rejected, (state) => {
        state.updating = false;
      });
  },
});

export const travelFeesReducer = travelFeesSlice.reducer;
