import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRepData, IRepPayload, IRepSettings, IRepState, IWithdrawalPayload } from '@aduvi/types';

import * as RepService from 'store/services/rep.service';

const initialState: IRepState = {
  reps: [],
  selectedRep: null,
  loading: true,
  creating: false,
  withdrawalModal: {
    isOpen: false,
    repLinkId: '',
    availableAmount: 0,
    creating: false,
  },
  withdrawals: {
    loading: false,
    data: [],
  },
  settings: {
    data: null,
    loading: true,
    updating: false,
  },
};

export const getReps = createAsyncThunk('rep/get-reps', async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
  try {
    return await RepService.getReps(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getRepById = createAsyncThunk(
  'rep/get-rep-by-id',
  async ({ businessId, repLinkId }: { businessId: string; repLinkId: string }, { rejectWithValue }) => {
    try {
      return await RepService.getRepById(businessId, repLinkId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createRep = createAsyncThunk(
  'rep/create-rep',
  async ({ payload, businessId }: { payload: IRepPayload; businessId: string }, { rejectWithValue }) => {
    try {
      return await RepService.createRep(businessId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRep = createAsyncThunk(
  'rep/update-rep',
  async ({ payload, businessId, repLinkId }: { payload: IRepPayload; businessId: string; repLinkId: string }, { rejectWithValue }) => {
    try {
      return await RepService.updateRep(businessId, repLinkId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteRep = createAsyncThunk(
  'rep/delete-rep',
  async ({ businessId, repLinkId }: { businessId: string; repLinkId: string }, { rejectWithValue }) => {
    try {
      return await RepService.deleteRep(businessId, repLinkId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getRepSettings = createAsyncThunk('rep/get-rep-settings', async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
  try {
    return await RepService.getRepSettings(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateRepSettings = createAsyncThunk(
  'rep/update-rep-settings',
  async ({ businessId, payload, method }: { businessId: string; payload: IRepSettings; method: 'put' | 'post' }, { rejectWithValue }) => {
    try {
      return await RepService.updateRepSettings(businessId, payload, method);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const createWithdrawal = createAsyncThunk(
  'rep/create-withdrawal',
  async ({ payload, businessId, repLinkId }: { payload: IWithdrawalPayload; businessId: string; repLinkId: string }, { rejectWithValue }) => {
    try {
      return await RepService.createWithdrawal(businessId, repLinkId, payload);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getWithdrawals = createAsyncThunk('rep/get-withdrawals', async ({ businessId }: { businessId: string }, { rejectWithValue }) => {
  try {
    return await RepService.getWithdrawals(businessId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const markWithdrawalAsPaid = createAsyncThunk(
  'rep/mark-withdrawal-as-paid',
  async ({ businessId, withdrawalId }: { businessId: string; withdrawalId: string }, { rejectWithValue }) => {
    try {
      return await RepService.markWithdrawalAsPaid(businessId, withdrawalId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const repSlice = createSlice({
  name: 'rep',
  initialState,
  reducers: {
    setSelectedRep(state, action: PayloadAction<IRepData | null>) {
      state.selectedRep = action.payload;
    },
    swtWithdrawalData(state, action: PayloadAction<{ isOpen: boolean; repLinkId: string; availableAmount: number }>) {
      state.withdrawalModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReps.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReps.fulfilled, (state, { payload }) => {
        state.reps = payload.data;
        state.loading = false;
      })
      .addCase(getReps.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRepById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRepById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getRepById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createRep.pending, (state) => {
        state.creating = true;
      })
      .addCase(createRep.fulfilled, (state, { payload }) => {
        state.creating = false;
        state.reps = [...(state.reps || []), { businessRepLink: payload.data }];
      })
      .addCase(createRep.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateRep.pending, (state) => {
        state.creating = true;
      })
      .addCase(updateRep.fulfilled, (state, { payload }) => {
        state.creating = false;
        const index = state.reps.findIndex((rep) => rep.businessRepLink.id === payload.data.id);
        state.reps[index].businessRepLink = payload.data;
      })
      .addCase(updateRep.rejected, (state) => {
        state.creating = false;
      })
      .addCase(deleteRep.fulfilled, (state, { meta }) => {
        const index = state.reps.findIndex((rep) => rep.businessRepLink.id === meta.arg.repLinkId);
        state.reps.splice(index, 1);
      })
      .addCase(getRepSettings.pending, (state) => {
        state.settings.loading = true;
      })
      .addCase(getRepSettings.fulfilled, (state, { payload }) => {
        state.settings.data = payload.data;
        state.settings.loading = false;
      })
      .addCase(getRepSettings.rejected, (state) => {
        state.settings.loading = false;
      })
      .addCase(updateRepSettings.pending, (state) => {
        state.settings.updating = true;
      })
      .addCase(updateRepSettings.fulfilled, (state, { payload }) => {
        state.settings.data = payload.data;
        state.settings.updating = false;
      })
      .addCase(updateRepSettings.rejected, (state) => {
        state.settings.updating = false;
      })
      .addCase(createWithdrawal.pending, (state) => {
        state.withdrawalModal.creating = true;
      })
      .addCase(createWithdrawal.fulfilled, (state, { payload }) => {
        state.withdrawalModal.creating = true;
        state.withdrawalModal.isOpen = false;
        state.withdrawals.data = [...(state.withdrawals.data || []), payload.data];
      })
      .addCase(createWithdrawal.rejected, (state) => {
        state.withdrawalModal.creating = false;
      })
      .addCase(getWithdrawals.pending, (state) => {
        state.withdrawals.loading = true;
      })
      .addCase(getWithdrawals.fulfilled, (state, { payload }) => {
        state.withdrawals.loading = false;
        state.withdrawals.data = payload.data;
      })
      .addCase(getWithdrawals.rejected, (state) => {
        state.withdrawals.loading = false;
      })
      .addCase(markWithdrawalAsPaid.pending, (state) => {
        state.withdrawals.loading = true;
      })
      .addCase(markWithdrawalAsPaid.fulfilled, (state, { payload, meta }) => {
        state.withdrawals.loading = false;
        const index = state.withdrawals.data.findIndex((w) => w.id === meta.arg.withdrawalId);
        state.withdrawals.data[index] = payload.data;
      })
      .addCase(markWithdrawalAsPaid.rejected, (state) => {
        state.withdrawals.loading = false;
      });
  },
});

export const repReducer = repSlice.reducer;

export const { setSelectedRep, swtWithdrawalData } = repSlice.actions;
